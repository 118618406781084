import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import CustomSingleSelect from '../../util_components/custom_single_select';
import { toast as NotificationManager } from 'react-toastify';
import Spinner from '../../util_components/spinner';
import moment from 'moment-timezone';
import * as selectors from '../../../redux/selectors';
import * as action_creators from '../../../redux/action_creators';
import urls from '../../../urls';
import { post_api } from '../../../redux/api_funcs';
import { target_audience_names } from '../../../constants';
import { image_error_replace } from '../../../util_functions';

class AddGroupAvailability extends Component {
	state = {
		loading: true,
		max_date: moment(new Date()).add(14, 'days').toDate(),
		session_title: '',
		session_other_title: '',
		session_desc: '',
		session_create_err: false,
		target_audience: 'Beginner',
		session_create_loading: false,
		session_create_success: false,
		start_date: null,
		session_uuid: '',
		link_copied: false,
	};
	inputRef = React.createRef();
	componentDidMount() {
		if (this.props.config_status === 'success') {
			this.set_config_values();
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.config_status === 'loading' && this.props.config_status === 'success') {
			this.set_config_values();
		}
	}

	create_sessions = () => {
		let { start_date, session_title, session_desc, session_duration, session_other_title, target_audience } =
			this.state;
		if (!!start_date && !!session_title && !!session_desc && (session_title !== 'other' || !!session_other_title)) {
			const sel_time = moment(this.state.start_date).format('YYYY-MM-DD HH:mm:ss');
			const iana_timezone = moment.tz.guess(true);
			const epoch_time = moment.tz(sel_time, iana_timezone).valueOf();
			const paylaod = {
				start_time: epoch_time,
				name: session_title === 'other' ? session_other_title : session_title,
				description: session_desc,
				duration: session_duration,
				is_private_group_session: 1,
				target_audience,
			};
			const url = urls.create_group_session;
			this.setState({ session_create_loading: true }, () => {
				post_api(url, paylaod, true)
					.then((response) => {
						let session_uuid = '';
						try {
							session_uuid = response.data.session.session_uuid;
						} catch {}
						NotificationManager.info('Group session created');
						this.setState({ session_create_loading: false, session_create_success: true, session_uuid });
						this.props.load_dashboard_sessions();
					})
					.catch((e) => {
						try {
							NotificationManager.error(e.response.data.message);
						} catch {}
						this.setState({ session_create_loading: false });
					});
			});
		} else {
			this.setState({ session_create_err: true });
		}
	};

	set_config_values = () => {
		const config_values = this.props.config_values;
		const name_desc = config_values.group_session_names;
		const durations = config_values.group_session_durations_for_booking;
		let names = [];
		for (let n of name_desc) {
			names.push({ label: n.name, value: n.name });
		}
		let session_duration;
		let session_durations = [];
		for (let d of durations) {
			session_durations.push({ label: d.display, value: d.duration });
			if (d.is_default === 1) {
				session_duration = d.duration;
			}
		}
		this.setState({
			session_names: names,
			session_descriptions: name_desc,
			loading: false,
			session_durations,
			session_duration,
		});
	};

	handle_time_change = (new_time, component) => {
		this.setState({ [`${component}_time`]: new_time });
	};

	render_inprogress_screen = () => (
		<div className='add-availability-inprogress'>
			<Spinner />
		</div>
	);

	onCommonChange = (e) => {
		const key = e.target.name;
		const value = e.target.value;
		const group_session_descriptions = this.state.session_descriptions;
		this.setState({ [key]: value, session_create_err: false }, () => {
			if (key === 'session_title') {
				const index = group_session_descriptions.findIndex((gs) => value === gs.name);
				if (index !== -1) {
					this.setState({ session_desc: group_session_descriptions[index].desc });
				} else {
					this.setState({ session_desc: '' });
				}
			}
		});
	};

	render_date_picker_one = () => {
		// const st_date = this.state.start_date;

		const CustomInputOne = ({ onClick }) => {
			return (
				<div className='gs-input-date-in' onClick={onClick}>
					<img alt='calander' src='https://images.myyogateacher.com/teacher_web_app/ic_calendar-s.svg' />

					{this.state.start_date === null ? (
						<span>----</span>
					) : (
						<span className='input-date'>
							{moment(this.state.start_date).format('MMM D, YYYY') +
								' at ' +
								moment(this.state.start_date).tz(moment.tz.guess()).format('h:mm A z')}
						</span>
					)}

					<span className='gs-date-arrow' />
				</div>
			);
		};
		// const startDate = this.state.vacation_start_date !== null ? new Date(this.state.vacation_start_date) : new Date();
		// const startDateOne = new Date();
		return (
			<DatePicker
				selected={this.state.start_date}
				onChange={(date) => this.set_start_date(date)}
				customInput={<CustomInputOne />}
				minDate={new Date()}
				maxDate={this.state.max_date}
				showTimeSelect
				dateFormat='MMMM d, yyyy h:mm aa'
			/>
		);
	};
	set_start_date = (date) => this.setState({ start_date: date, session_create_err: false });

	copy_url = () => {
		this.inputRef.current.select();
		document.execCommand('copy');
		this.setState({ link_copied: true });
	};

	render_success_session_created = () => {
		let { start_date, session_title, session_uuid, link_copied } = this.state;
		const session_start_date =
			moment(start_date).format('ddd, MMM D') +
			' at ' +
			moment(start_date).tz(moment.tz.guess()).format('h:mm A z');
		const share_url = `https://myyogateacher.com/free-live-group-classes?sessionUuid=${session_uuid}`;
		const link_copy_style = !link_copied ? { opacity: 0 } : {};
		return (
			<div className='sus-cr-sess-pop'>
				<div className='sus-cr-sess-pop-overlay' onClick={this.props.close_group_session} />
				<div className='sus-cr-sess-pop-body'>
					<img
						onClick={this.props.close_group_session}
						className='act-cr-p-close'
						alt='close'
						src='https://images.myyogateacher.com/icons/ic_white_cross.png'
					/>
					<div className='sus-cr-sess-pop-heading'>
						<div className='yel-ok-act-cr'>
							<img alt='right' src='https://images.myyogateacher.com/icons/right-black.png' />
						</div>
						<h4>Session created</h4>
					</div>

					<div className='sus-cr-sess-info'>
						<div className='sus-cr-teacher-info'>
							<img alt='teacher-pic' src={this.props.profile_photo} onError={image_error_replace} />
							<div className='sus-cr-teacher-info-text'>
								<h4>{session_title}</h4>
								<p className='cr-time'>{session_start_date}</p>
							</div>
						</div>
						{!!session_uuid ? (
							<div className='cr-invite-cont'>
								<span className='cr-invite-flag'>Invite students</span>
								<h4>Invite students to this session</h4>
								<div className='ref-div'>
									<h4>Your personal referral link</h4>
									<div className='reff-input'>
										<input value={share_url} ref={this.inputRef} />
										<button onClick={this.copy_url}>Copy Link</button>
									</div>
									<p className='grp-link-copied-y' style={link_copy_style}>
										Link copied
									</p>
								</div>
								<div className='share-links'>
									<a
										className='fb'
										href={'https://www.facebook.com/sharer.php?u=' + share_url}
										target='_blank'
										rel='noopener noreferrer'
									>
										<img src='https://images.myyogateacher.com/icons/ic_fb_sv.svg' alt='fb' />
										Facebook
									</a>
									<a
										className='tw'
										href={'https://twitter.com/intent/tweet?url=' + share_url}
										target='_blank'
										rel='noopener noreferrer'
									>
										<img src='https://images.myyogateacher.com/icons/ic_twitter_sv.svg' alt='fb' />
										Twitter
									</a>
									<a
										className='em'
										href={'mailto:?&body=' + share_url}
										target='_blank'
										rel='noopener noreferrer'
									>
										<img src='https://images.myyogateacher.com/icons/ic_email@2x.png' alt='fb' />
										Mail
									</a>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	};

	render_body = () => {
		const err_sty = this.state.session_create_err ? { opacity: 1, color: '#ed4d4d' } : { opacity: 0 };
		if (this.state.session_create_loading) {
			return this.render_loading();
		} else if (this.state.session_create_success) {
			return this.render_success_session_created();
		}

		return (
			<div className='create-sess-body'>
				<img
					onClick={this.props.close_group_session}
					className='act-cr-p-close'
					alt='close'
					src='https://images.myyogateacher.com/icons/ic_white_cross.png'
				/>
				<div className='create-sess-body-content'>
					<h4>Create session</h4>
					<div className='grp-f'>
						<CustomSingleSelect
							label='Session Title'
							options={this.state.session_names}
							onChange={this.onCommonChange}
							name='session_title'
							value={this.state.session_title}
							style={{ height: '40px' }}
						/>

						<div className='grp-add-availability-cont'>
							<div className='sel-sess-date'>
								<div className='session-date-picker'>
									{/* <img alt="icon" src="https://images.myyogateacher.com/teacher_web_app/ic_calendar-s.svg" /> */}
									{this.render_date_picker_one()}
								</div>
								<label>Session date</label>
							</div>
							<div className='sel-end-time grp-f'>
								<CustomSingleSelect
									label='Duration'
									options={this.state.session_durations}
									onChange={this.onCommonChange}
									name='session_duration'
									value={this.state.session_duration}
									style={{ height: '40px' }}
								/>
							</div>
						</div>
						<div className='grp-target-au'>
							<CustomSingleSelect
								label='Target Audeince'
								options={target_audience_names}
								onChange={this.onCommonChange}
								name='target_audience'
								value={this.state.target_audience}
								style={{ height: '40px' }}
							/>
						</div>
					</div>

					{this.state.session_title === 'Other' ? (
						<div className='grp-form-group'>
							<input
								placeholder='Enter here'
								value={this.state.session_other_title}
								onChange={this.onCommonChange}
								name='session_other_title'
							/>
							<label>Session other title</label>
						</div>
					) : null}

					<div className='grp-form-group'>
						<textarea
							placeholder='Enter here'
							value={this.state.session_desc}
							onChange={this.onCommonChange}
							name='session_desc'
						/>
						<label>Session description</label>
					</div>
					<p style={err_sty}>All fields are compulsory</p>
				</div>

				<div className='create-sess-body-footer'>
					<button className='btn btn-vio' onClick={this.create_sessions}>
						Create Session
					</button>
				</div>
			</div>
		);
	};

	render_loading = () => {
		return (
			<div className='create-sess-body'>
				<img
					onClick={this.props.close_group_session}
					className='act-cr-p-close'
					alt='close'
					src='https://images.myyogateacher.com/icons/ic_white_cross.png'
				/>
				<div className='create-sess-loading'>
					<Spinner />
				</div>
			</div>
		);
	};

	render() {
		return (
			<div className='create-sess-pop'>
				<div className='create-sess-pop-overlay' onClick={this.props.close_group_session} />
				{this.state.loading ? this.render_loading() : this.render_body()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		add_availability_submit_status: selectors.get_add_availability_submit_status(state),
		config_status: state.loading.config_status,
		config_values: state.home.config_values,
		profile_photo: selectors.teacher_details_all(state).profile_photo,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		set_availabilty_screen_status: (key, payload) =>
			dispatch(action_creators.set_availability_variable(key, payload)),
		add_availability: (payload) => dispatch(action_creators.add_availability(payload)),
		load_dashboard_sessions: () => {
			dispatch(action_creators.load_dashboard_sessions());
		},
	};
};

const AddGroupAvailabilityWithRedux = connect(mapStateToProps, mapDispatchToProps)(AddGroupAvailability);

export default AddGroupAvailabilityWithRedux;
