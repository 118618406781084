import React, { useState } from 'react';
import {
    delete_avalability_slots_data,
    get_replacement_sessions_teacher_slots,
} from '../../../redux/api_functions/availability_api';
import Loader from '../../new_teacher_wesite/components/loader';
import { removeCookie, analytics_reset } from '../../../util_functions';
import { useNavigate } from 'react-router-dom';
import { CloseBox } from '../../../../svg';

const DeleteSlots = (props) => {
    const navigate = useNavigate();

    const [getDeleteSlots, setDeleteSlots] = useState(props.deleteSlotsPopupData);
    const [isLoading, setIsloading] = useState(false);

    const deleteAvailability = (dt) => {
        let payload = {
            ...props.deleteSlotsPopupData,
            end_date: dt === true ? getDeleteSlots?.end_date : getDeleteSlots?.start_date,
        };

        delete_avalability_slots_data(payload)
            .then((response) => {
                setIsloading(true);
                if (response?.data?.status == 'success') {
                    setIsloading(false);
                    props.deleteDataEditSlots(true);
                    window.location.reload()
                }
            })
            .catch((e) => {
                // console.log(JSON.stringify(e));
                if (e?.response?.status === 401) {
                    removeCookie('teacher_token_1');
                    localStorage.removeItem('teacherLocalChannels');
                    analytics_reset();
                    navigate('/login');
                    props.addDataEditSlots(true);
                    setIsloading(false);
                } else if (e?.response?.status === 400) {
                    props.addDataEditSlots(true);
                    setIsloading(false);
                }
            });
    };

    const deleteAllAvailabilityDt = (dt) => {
        let payload;
        if (dt === true) {
            payload = {
                teacher_schedule_cron_uuid: getDeleteSlots.teacher_schedule_cron_uuid,
                start_date: getDeleteSlots.start_date,
                end_date: getDeleteSlots.end_date,
            };
        } else if (dt === false) {
            payload = {
                teacher_schedule_cron_uuid: getDeleteSlots.teacher_schedule_cron_uuid,
                start_date: getDeleteSlots.start_date,
                end_date: getDeleteSlots.start_date,
            };
        }
        setIsloading(true);
        get_replacement_sessions_teacher_slots(payload)
            .then((response) => {
                if (response?.data?.status === 'success') {
                    if (response?.data?.sessions && response?.data?.sessions.length) {
                        props.deleteAllAvailability(getDeleteSlots, payload);
                    } else {
                        deleteAvailability(dt);
                    }
                    setIsloading(false);
                }
            })
            .catch((e) => {
                // console.log(JSON.stringify(e));
                if (e.response.status === 401) {
                    removeCookie('teacher_token_1');
                    localStorage.removeItem('teacherLocalChannels');
                    analytics_reset();
                    navigate('/login');
                    props.addDataEditSlots(true);
                    setIsloading(false);
                } else if (e.response.status === 400) {
                    setIsloading(false);
                }
            });
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className='relative inset-0 z-index-max'>
                    <div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
                        <div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
                            <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
                                <div onClick={props.closeDeletePopup}>
                                    <CloseBox/>
                                </div>
                            </div>
                            <div className='mb-0'>
                                <div className='flex justify-start flex-col items-start'>
                                    <div className='w-full'>
                                        <div className='flex justify-center items-center'>
                                            <div className='text-17 font-semibold'>
                                                {getDeleteSlots.currentDate === true
                                                    ? 'This is single day availability'
                                                    : 'This is repeating Availability'}
                                            </div>
                                        </div>
                                        <div className='flex justify-center items-center mt-4'>
                                            {getDeleteSlots.availability_type === 'single'
                                                ? getDeleteSlots.start_date
                                                : getDeleteSlots.start_date + ' - ' + getDeleteSlots.end_date}
                                        </div>
                                        <div className='flex justify-center items-center'>
                                            <div className='mt-5 flex flex-col justify-center items-center w-4/5 mt-4'>
                                                {getDeleteSlots.availability_type === 'single' && (
                                                    <button
                                                        className='btn btn-danger w-full mb-3'
                                                        onClick={() => deleteAllAvailabilityDt(false)}
                                                    >
                                                        Delete this date {getDeleteSlots.start_date} availability
                                                    </button>
                                                )}
                                                {/* <button className="btn btn-danger w-full" onClick={() => props.deleteAllAvailability(getDeleteSlots)}>Delete all future availabilities</button> */}
                                                {getDeleteSlots.start_date !== getDeleteSlots.end_date ? (
                                                    <button
                                                        className='btn btn-danger w-full'
                                                        onClick={() => deleteAllAvailabilityDt(true)}
                                                    >
                                                        Delete all availabilities
                                                    </button>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DeleteSlots;
