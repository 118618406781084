import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Player, BigPlayButton } from 'video-react';
import moment from 'moment-timezone';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import LoadingScreen from '../../util_components/loading_screen';
import CustomModal from '../../util_components/custom_modal';
import "video-react/dist/video-react.css";
import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class MySessions extends Component {
  state = {
    loading: true,
    session_open: false,
    all_sessions: [],
    session_url: ''
  };

  componentDidMount() {
    if (this.props.my_sessions_status === 'none') {
      this.props.load_my_sessions();
    }
    if (this.props.my_sessions_status === 'success') {
      this.save_sessions();
    }

    sendEventToClarity();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.my_sessions_status === 'loading' && this.props.my_sessions_status === 'success') {
      this.save_sessions();
    }
  }

  save_sessions = () => this.setState({ all_sessions: this.props.my_sessions, loading: false });

  contextMenu = (e) => e.preventDefault();

  close_session = () => this.setState({ session_open: false });

  load_more_sessions = () => this.setState({ loading: true }, () => this.props.load_my_sessions(0));

  open_session = (url) => this.setState({ session_url: url, session_open: true });

  goBack = () => this.props.router.navigate(-1);

  go_to_recap = (item) => {
    this.props.router.navigate('/recap', {
      state: { session_questions: item.session_questions, session_uuid: item.session_uuid }
    });
  };

  render_student_name_link = (type, name, slug) => {
    if (type === 'VIDEO_CONSULT' || type === 'YOGA' || type === 'YOGA_WITH_FRIENDS_SESSION') {
      return (
        <h4
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(`/student-profile/${slug}`);
          }}
        >
          {name}
        </h4>
      );
    } return <h4>{name}</h4>;
  };

  render_item = (item, index) => {
    const show_group_numbers =
      item.session_type === 'GROUP_SESSION' &&
      (item.member_participants !== 0 || item.non_member_participants !== 0);
    let group_text = '';
    let member_text = '';
    if (item.member_participants !== 0 && item.non_member_participants !== 0) {
      member_text = `Members - ${item.member_participants}, Non members - ${item.non_member_participants}`;
    } else if (item.member_participants !== 0) {
      member_text = `Members - ${item.member_participants}`;
    } else {
      member_text = `Non members - ${item.non_member_participants}`;
    }
    if (item.joined_participants !== 0) {
      group_text = `Students Joined - ${item.joined_participants}`;
    }
    if (item.cancelled_participants !== 0) {
      if (group_text !== '') {
        group_text += ', ';
      }
      group_text += `Cancelled - ${item.cancelled_participants}`;
    }
    if (item.no_show_participants !== 0) {
      if (group_text !== '') {
        group_text += ', ';
      }
      group_text += `No Shows - ${item.no_show_participants}`;
    }
    const sess_start_date = item.epoch_start_time ? moment(item.epoch_start_time).format('DD') : '';
    const sess_start_time = item.epoch_start_time
      ? moment(item.epoch_start_time).format('MMM YYYY')
      : '';
    let student_name; let session_icon; let session_name;
    if (item.session_type === 'GROUP_SESSION') {
      student_name = 'Group class';
      session_icon = 'https://images.myyogateacher.com/icons/ic_group_class.png';
      session_name = item.group_session_name;
    } else {
      student_name = item.student_name;
      session_icon = 'https://images.myyogateacher.com/icons/ic_private_session.png';
      session_name = '1-on-1 session';
    }
    const show_to_do = item.session_recap_text.indexOf('2/2') === -1;
    return (
      <div className="yj-cont" key={item.session_uuid}>
        <div className="yj-date">
          <span className="y-date">{sess_start_date}</span>
          <span className="y-month">{sess_start_time}</span>
        </div>
        <div className="yj-info-up">
          <div className="yj-info">
            {/* <h4>{student_name}</h4> */}
            {this.render_student_name_link(item.session_type, student_name, item.student_slug)}
            <div className="his_which_session_div">
              <img src={session_icon} alt="ic" />
              <p>{session_name}</p>
            </div>
            <div className="his_which_session_div">
              <img src="https://images.myyogateacher.com/icons/ic_time.png" alt="ic" />
              <p>{item.start_time}</p>
            </div>
            {show_group_numbers ? (
              <div className="his_which_session_div custom_his_which_session_div">
                <img
                  className="mt-1"
                  src="https://images.myyogateacher.com/icons/ic_info.png"
                  alt="ic"
                />
                <div>
                  <p>{member_text}</p>
                  <p>{group_text}</p>
                </div>
              </div>
            ) : null}
            <div className="yj-video-cont">
              {item.replay_url ? (
                <>
                  <img src="https://images.myyogateacher.com/mysession-video2.png" alt="video" />
                  <button className="v-play-btn" onClick={() => this.open_session(item.replay_url)}>
                    <img
                      className="play"
                      src="https://images.myyogateacher.com/play-sm-icon.png"
                      alt="play"
                    />
                  </button>
                </>
              ) : (
                <>
                  <img
                    src="https://images.myyogateacher.com/video_thumbnail_not_recorded.png"
                    alt="no-rec"
                  />
                  <div className="yj-vid-err">
                    <img
                      className="err"
                      src="https://images.myyogateacher.com/ic_error.png"
                      alt="err"
                    />
                    <span>
                      {item.msg_for_empty_replay_url
                        ? item.msg_for_empty_replay_url
                        : item.video_title}
                    </span>
                  </div>
                </>
              )}
            </div>
            <button className="btn add-roadmap-btn-ms" onClick={() => this.go_to_recap(item)}>
              {show_to_do ? (
                <img
                  alt="to do"
                  className="ms_tag_to_do"
                  src="https://images.myyogateacher.com/icons/tag_to_do.svg"
                />
              ) : null}
              {item.session_recap_text}
              <span>{item.session_recap_sub_text}</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading, session_open, session_url, all_sessions } = this.state;
    return (
      <>
        {loading ? <LoadingScreen /> : null}
        <div className="m-card-body">
          <CustomModal show={session_open} close={this.close_session}>
            <div onContextMenu={this.contextMenu}>
              <Player playsInline autoPlay src={session_url}>
                <BigPlayButton position="center" />
              </Player>
            </div>
          </CustomModal>
          <div className="m-card">
            <div className="m-card-header">
              <div className="m-card-head-wrap">
                <button className="btn btn-mcard-back" onClick={this.goBack}>
                  <img
                    alt="back-arrow"
                    src="https://s3.amazonaws.com/images.myyogateacher.com/icons/back-arrow-white1.svg"
                  />
                </button>
                <h1>My session</h1>
              </div>
            </div>
            <div className="m-card-content">
              {all_sessions.length !== 0 && all_sessions.map(this.render_item)}
            </div>
            {!loading && this.props.more_my_sessions === 0 ? (
              <div className="yj-cont">
                <button className="yj-load-more" onClick={this.load_more_sessions}>
                  Load more...
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    my_sessions_status: selectors.get_my_sessions_status(state),
    my_sessions: selectors.get_my_sessions(state),
    dashboard_loading: selectors.get_dashboard_loading(state),
    dashboard_sessions: selectors.get_sessions(state),
    more_my_sessions: state.home.more_my_sessions
  });

const mapDispatchToProps = (dispatch) => ({
    load_my_sessions: (payload) => {
      dispatch(actions.load_my_sessions(payload));
    },
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    }
  });

const MySessionsWithRedux = connect(mapStateToProps, mapDispatchToProps)(MySessions);
const MySessionsWithRouter = withRouter(MySessionsWithRedux);

export default MySessionsWithRouter;
