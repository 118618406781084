import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../custom_button';
import CustomDropdown from '../custom_dropdown';
import CustomInput from '../custom_input';
import { cancel_reasons } from '../../../constants';
import './cancel_alert.css';

export default class CalcelAlert extends PureComponent {
    
	state = {
		cancel_reason: '',
		other_details: '',
		cancel_error: false
	};
	change_cancel_reason = val => {
		this.setState({
			cancel_reason: val,
			other_details: '',
			cancel_error: false
		});
	};
    

	handleDetails = e => {
		const capitalizedOtherDetails = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
		this.setState({ other_details: capitalizedOtherDetails });
	};

	confirm_cancel = () => {
		if (this.state.cancel_reason === '') {
			this.setState({ cancel_error: true });
		} else {
			this.props.confirmFunc(this.state.cancel_reason, this.state.other_details);
		}
	};
	render() {
		return (
			<div className="relative inset-0 z-50">
				<div className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end">
					<div className="z-50 bg-white zm:rounded-2xl zm:rounded-tl-3xl zm:rounded-tr-3xl zm:rounded-bl-none zm:rounded-br-none md:rounded-lg md:p-40px zm:px-30px zm:py-40px md:w-492px zm:w-full relative">
                		<div className='flex justify-end cursor-pointer absolute top-1 right-1'>
							<div onClick={this.props.cancelFunc}>
								<svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<rect width='34' height='34' rx='17' fill='none' />
									<path
										d='M22 12L12 22'
										stroke='#BFBFBF'
										strokeWidth='1.66667'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M12 12L22 22'
										stroke='#BFBFBF'
										strokeWidth='1.66667'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
                		</div>
						{typeof this.props.header !== 'undefined' ? <div className="cancel-popup-header">Header</div> : null}
            			<div className="font-semibold text-subText md:text-19 zm:text-17 mb-25px" dangerouslySetInnerHTML={{ __html: this.props.bodyText }}></div>
						<div className="flex flex-col">
							<p className='text-mytLabel text-13.5px leading-16px font-medium'>Reason*</p>
							<CustomDropdown
								className='input-date rounded-lg text-15 font-medium shadow-sm flex items-center justify-between formInput border mt-8px w-full'
								value={this.state.cancel_reason}
								handleChange={this.change_cancel_reason}
								drop_down_list={this.props.reasons}
								width='100%'
	 	                        dropDownIcon='https://images.myyogateacher.com/web_images/header/v1/chevron-down-icon.svg'
	                            dropDownContent='w-auto'
	                            dropDownContents='absolute top-60px h-200px overflow-scroll pt-25px pb-13px w-max bg-white justify-center border border-gray-200 shadow-mytboxshadow rounded-lg cursor-pointer px-30px gap-12px'
	                            dropDownValues='class-btn-size text-black font-inter font-semibold text-15.5px leading-19px mb-12px'
							/>
							{' '}
						</div>
						<div className="flex flex-col mt-15px">
							<div className='text-mytLabel text-13.5px leading-16px font-medium'>Enter details</div>
							<textarea
	                          	className='input-date w-full rounded-lg text-15 font-medium shadow-sm flex items-center formInput border pt-10px h-100px resize-none leading-22px mt-8px'
								onChange={this.handleDetails}
								value={this.state.other_details}
								width="400px"
							/>
						</div>
						{this.state.cancel_error ? <div className="text-15.5px leading-19px font-medium text-mytRedText2 mt-15px">* fields are compulsory</div> : null}
						<div>
							<div className="flex items-center justify-between w-full">
								<button className='btn btn-primary cursor-pointer w-full text-17px h-49px mt-40px' onClick={this.confirm_cancel}>Confirm</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CalcelAlert.propTypes = {
	bodyText: PropTypes.string,
	confirmFunc: PropTypes.func,
	cancelFunc: PropTypes.func,
	status: PropTypes.string,
	reasons: PropTypes.array
};

CalcelAlert.defaultProps = {
	bodyText: 'Enter body text',
	confirmFunc: () => null,
	cancelFunc: () => null,
	status: 'none',
	reasons: cancel_reasons
};

// export default CustomAlert;
