import React, { Component } from 'react';
import * as actions from '../../../redux/action_creators';
import { connect } from 'react-redux';
import { getCookie, setCookie } from '../../../util_functions';
import './das_noti.css';
import { withRouter } from '../../../utils/router';

class Notification extends Component {
	constructor(props) {
		super(props);

		this.state = {
			oldNotificationArr: [],
			newNotificationArr: [],
			mobile_device_call: window.innerWidth < 1024,
		};

		//mobile app
		let token = new URLSearchParams(this.props.router.location.search).get('token');
		if (token !== null && token !== undefined && token !== '') {
			setCookie('student_token', token);
		} else {
			if (!this.isUserLoggedIn()) {
				window.ReactNativeWebView &&
					window.ReactNativeWebView.postMessage(
						JSON.stringify({
							status: 'failure',
						})
					);

				//return;
			}
		}
	}

	isUserLoggedIn = () => {
		if (typeof getCookie('student_token') === 'undefined' || getCookie('student_token') === '') {
			return false;
		} else return true;
	};

	onNotificationClick = (index) => {
		if (this.state.newNotificationArr[index].type === 'RECORDING_AVAILABLE') {
			if (window.ReactNativeWebView !== undefined) {
				let obj = { status: 'success' };
				obj.navigate = 'mysessions';
				window.ReactNativeWebView.postMessage(JSON.stringify(obj));
			} else {
				this.props.rotuer.navigate('/sessions');
				this.props.notiClose();
			}
		} else if (this.state.newNotificationArr[index].type === 'FEEDBACK') {
			const token = this.state.newNotificationArr[index].token;
			if (window.ReactNativeWebView !== undefined) {
				let obj = { status: 'success' };

				obj.token = token;
				obj.navigate = 'sessionFeedback';

				window.ReactNativeWebView.postMessage(JSON.stringify(obj));
			} else {
				window.open(`https://deeplinks.myyogateacher.com/yTEBNcJcUY?token=${token}`, '_blank');
				this.props.notiClose();
			}
		} else {
			if (window.ReactNativeWebView !== undefined) {
				let obj = { status: 'success' };
				window.ReactNativeWebView.postMessage(JSON.stringify(obj));
			} else {
				this.props.notiClose();
			}
		}
	};
	componentDidMount() {
		this.props.get_notifications();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.get_notifications_status === 'loading' && this.props.get_notifications_status === 'success') {
			const newNotificationArr = this.props.get_notifications_response.filter((notification) => {
				return !notification.read_status;
			});

			const oldNotificationArr = this.props.get_notifications_response.filter((notification) => {
				return !!notification.read_status;
			});

			const unreadNotifications = this.props.get_notifications_response.filter((n) => n.read_status === 0);

			this.setState({ oldNotificationArr, newNotificationArr }, () => {
				if (unreadNotifications.length > 0) {
					this.props.update_notification_read_status({
						latest_notification_id: newNotificationArr[0].id,
					});
				}
			});
		}
	}

	render_notification_view = () => {
		return (
			<div className={`noti-div ${this.state.mobile_device_call ? 'noti_full' : ''}`}>
				{this.state.mobile_device_call && this.renderHeader()}

				<div className='' onClick={this.props.notiClose} />
				<div className='noti-card md:w-450px zm:w-screen z-50 w-48 shadow-md bg-white rounded-lg border border-gray absolute md:top-3 right-0'>
					{/* <span className="arr-np"></span> */}
					<div className='noti-sc '>
						{/* No New notification */}
						{this.state.newNotificationArr.length === 0 && (
							<div className='no-noti-div'>
								<h2 className='border-b border-mytgray'>{'No New Notifications'}</h2>
							</div>
						)}

						{/* New notification */}
						{this.state.newNotificationArr.length > 0 && (
							<h2>{this.state.newNotificationArr.length} New Notifications</h2>
						)}

						{this.state.newNotificationArr.length > 0 && (
							<div className='noti-in'>
								{this.state.newNotificationArr.map((notification, index) => {
									return (
										<div
											key={index}
											className={`noti-row ${!!notification.read_status ? '' : 'high-lt'}`}
											onClick={() => {
												this.onNotificationClick(index);
											}}
										>
											{notification.type === 'NEW_SESSION' ||
											notification.type === 'SESSION_REPLACEMENT' ? (
												notification.student_profile_photo === '' ? (
													<div className='noti-pic_div'>
														{notification.payload_identifier_caption[0]}
													</div>
												) : (
													<img
														alt='ic'
														className='noti-ic'
														src={notification.student_profile_photo}
													/>
												)
											) : (
												<img alt='ic' className='noti-ic' src={notification.image} />
											)}
											<div className='noti-text'>
												<h4 className='noti-title'>
													{notification.heading}{' '}
													<span className='noti-date'>{notification.from_now}</span>
												</h4>
												<p className='noti-mess'>{notification.message}</p>
												{notification.type === 'NEW_SESSION' ||
												notification.type === 'SESSION_REPLACEMENT' ? (
													<a
														className='noti-link'
														href={`/student-profile/${notification.student_slug}`}
														target='_blank'
														rel='noopener noreferrer'
													>
														{notification.payload_identifier_caption}
													</a>
												) : null}
											</div>
										</div>
									);
								})}
							</div>
						)}
						{/* Old notification */}
						{this.state.oldNotificationArr.length > 0 && (
							<h2 className='border-b border-mytgray' style={{ marginTop: '10px' }}>
								Old Notifications
							</h2>
						)}

						{this.state.oldNotificationArr.length > 0 && (
							<div className='noti-in old-noti'>
								{this.state.oldNotificationArr.map((notification, index) => {
									return (
										<div
											key={index}
											onClick={() => {
												this.props.notiClose();
											}}
											className='noti-row'
										>
											{notification.type === 'NEW_SESSION' ||
											notification.type === 'SESSION_REPLACEMENT' ? (
												notification.student_profile_photo === '' ? (
													<div className='noti-pic_div'>
														{notification.payload_identifier_caption[0]}
													</div>
												) : (
													<img
														alt='ic'
														className='noti-ic'
														src={notification.student_profile_photo}
													/>
												)
											) : (
												<img alt='ic' className='noti-ic' src={notification.image} />
											)}
											<div className='noti-text'>
												<h4 className='noti-title'>
													{notification.heading}{' '}
													<span className='noti-date'>{notification.from_now}</span>
												</h4>

												<p className='noti-mess'>{notification.message}</p>
												{notification.type === 'NEW_SESSION' ||
												notification.type === 'SESSION_REPLACEMENT' ||
												notification.type === 'GENERAL' ? (
													<a
														className='noti-link'
														href={`/student-profile/${notification.student_slug}`}
														target='_blank'
														rel='noopener noreferrer'
													>
														{notification.payload_identifier_caption}
													</a>
												) : null}
											</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	renderHeader() {
		return (
			<div className='noti-mb-header'>
				<h2>Notification</h2>{' '}
				<button
					className='btn-mb-noti-cls'
					onClick={() => {
						if (window.ReactNativeWebView !== undefined && this.state.newNotificationArr.length > 0) {
							this.onNotificationClick(0);
						} else {
							this.props.notiClose();
						}
					}}
				>
					<img alt='close' src='https://images.myyogateacher.com/icons/ic_white_cross.png' />
				</button>
			</div>
		);
	}

	render() {
		return this.render_notification_view();
	}
}

const mapStateToProps = (state) => {
	return {
		get_notifications_status: state.home.notificationStatus,
		update_notification_read_status_response: state.home.update_notification_read_status_response,
		get_notifications_response: state.home.notificationResponse,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		get_notifications: (payload) => {
			dispatch(actions.get_notifications(payload));
		},
		update_notification_read_status: (key, payload) => {
			dispatch(actions.update_notification_read_status(key, payload));
		},
	};
};

const NotificationWithRedux = connect(mapStateToProps, mapDispatchToProps)(Notification);
const NotificationWithRouter = withRouter(NotificationWithRedux);

export default NotificationWithRouter;
