import React, { useRef, useEffect } from 'react';
import { ClosePannel } from '../livekit_assets/svg';

const ParticipantsListPannel = ({ participantsList, onClose }) => {
	let localParticipants = participantsList.localParticipant;
	let localMetaData = localParticipants.metadata
		? JSON.parse(localParticipants.metadata)
		: {
				avatar: 'https://res.cloudinary.com/dgerdfai4/image/upload/w_120,h_120,c_thumb,r_60/v1587531490/ic_default_profile.png',
		  };

	const participantListRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (participantListRef?.current && !participantListRef?.current?.contains(event.target)) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);

    
    const capitalizeWords = (str) => {
        const words = str.split(' ');
        const capitalizedWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        return capitalizedWords.join(' ');
      };

	const renderParticipants = () => {
		return participantsList?.getParticipant?.map((participant) => {
			let metaData = participant.metadata ? JSON.parse(participant.metadata) : { avatar: 'default_avatar_url' };
			return (
				<div key={participant.identity} className='participants-list'>
					<img className='participants-avatar' src={metaData.avatar}></img>
					<div>{capitalizeWords(participant.name)}</div>
				</div>
			);
		});
	};

	return (
		<div ref={participantListRef} className='pannel-wrapper'>
			<div className='pannel-close' onClick={onClose}>
				<ClosePannel />
			</div>
			<div className='pannel-title'>Participants</div>
			<div className='participants-list-wrapper' style={{ marginBottom: '15px' }}>
				{localParticipants.name && (
					<div className='participants-list'>
						<img className='participants-avatar' src={localMetaData.avatar}></img>
						{capitalizeWords(localParticipants.name)}
						<span className=''>(You)</span>
					</div>
				)}
			</div>
			{participantsList?.getParticipant?.length === 0 ? (
				<></>
			) : (
				<div className='participants-list-wrapper'>{renderParticipants()}</div>
			)}
		</div>
	);
};

export default ParticipantsListPannel;
