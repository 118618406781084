import React, { useEffect, useState, useRef } from 'react';
import { CloseButtonIcon } from '../../../../../svg';
import { post_api } from '../../../../redux/api_funcs';
import urls from '../../../../urls';
import { capitalizeFirstLetter } from '../../../../formatString';
import { getNumWithOrdinal} from '../../../../util_functions';
import ChatService from 'myt-chat/src/external/ChatService';
import Custom_loading from '../../../util_components/custom_loading';

export const AttendanceRecap = (props) => {
  const [participants, setParticipants] = useState([]);
  const [participantNavStep, setParticipantNavStep] = useState('trial');
  const [showBanModal, setShowBanModal] = useState(false);
  const [ParticipantOptId, setParticipantOptId] = useState('');
  const [participantOptionsOpen, setParticipantOptionsOpen] = useState(false);
  const [banParticipant, setBanParticipant] = useState(false);
  const [banParticipantData, setBanParticipantData] = useState(false);
    const [checkedBanReasons, setcheckedBanReasons] = useState([]);
    const [selectAlert, setselectAlert] = useState(false);
    const [isAllReasonSelected, setisAllReasonSelected] = useState(false);
    const [banloading, setBanloading] = useState(false);
    const [showAllNonJoined, setShowAllNonJoined] = useState(false);
    const dropdownRef = useRef(null);
    const iconRef = useRef({});

  useEffect(() => {
    fetchSessionData();
  }, []);
  
useEffect(() => {
    const handleClickOutside = (e) => {
      if (participantOptionsOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        const iconElement = iconRef.current[ParticipantOptId];
        if (iconElement && !iconElement.contains(e.target)) {
          setParticipantOptionsOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [participantOptionsOpen, ParticipantOptId]);

  const fetchSessionData = async () => {
    const url = urls.get_session_participants;
    const payload = {
      session_uuid: props.sessionUUID
    };
    try {
      let res = await post_api(url, payload, true);
      if (res.data.status === 'success') {
          setParticipants(res.data.session_details);
          const participants = res.data.session_details.total_participants.filter(participant => participant.label !== "ALL");
          const containsTrial = participants.find(participant => participant.label === "TRIAL");
          const containsMember = participants.find(participant => participant.label === "MEMBER");
          if (!containsTrial && containsMember) {
            setParticipantNavStep("member")
          } else if (containsTrial) {
              setParticipantNavStep("trial")
          }
      }
    } catch (error) {
      console.error('Error fetching session data: ', error);
    }
  };
    

  const closeViewParticiPop = () => {
    props.closeViewParticiPop();
  };

  const renderTabData = () => {
    switch (participantNavStep) {
      case 'trial':
        return showParticipantsTabData('trial');
      case 'member':
        return showParticipantsTabData('member');
      default:
        return showParticipantsTabData('trial');
    }
  };

  const handleOptionsChange = (e) => {
    setParticipantOptionsOpen(!participantOptionsOpen);
    setParticipantOptId(e.currentTarget.id);
      
      if (!participantOptionsOpen) {
        setTimeout(() => {
          if (dropdownRef.current) {
            dropdownRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
        }, 0);
      }
  };

  const handleParticipantNav = (event) => {
    setParticipantNavStep(event.target.name);
  };

  const openProfilePage = (studentSlug) => {
    window.open(`/student-profile/${studentSlug}`);
  };
  const stop_propagation = (e) => {
    e?.stopPropagation();
  };

  const render_banned_participant = () => (
    <div className="py-20 px-6 font-medium flex flex-col items-center">
      <div>
        <img
          src="https://images.myyogateacher.com/check_icon.png"
          alt="confirm"
          className="w-11 h-11 mb-4"
        />
      </div>
      <div className="text-center text-mytgrey-subText">
        {' '}
        {banParticipantData?.student_name} banned for{' '}
            {checkedBanReasons.join(' , ')}
      </div>
    </div>
  );
    
  const handleBanReason = (event) => {
    let newArr = [...checkedBanReasons, event.target.value];
    if (checkedBanReasons.includes(event.target.value)) {
      newArr = newArr.filter((reason) => reason !== event.target.value);
    }
      setcheckedBanReasons(newArr);
      setselectAlert(false);

    if (checkedBanReasons.length !== props.config_values?.student_ban_reasons) {
        setisAllReasonSelected(false)
    }
  };
    
   const handleAllBanReason = (e) => {
    if (e.target.checked) {
      const newArray = props.config_values?.student_ban_reasons;
        setcheckedBanReasons(newArray)
        setisAllReasonSelected(true)
    } else if (
      checkedBanReasons.length !== props.config_values?.student_ban_reasons
    ) {
      setisAllReasonSelected(false)
    } else {
        setcheckedBanReasons(checkedBanReasons);
        setisAllReasonSelected(false);
    }
   };
    
   const onBanParticipant = () => {
    if (checkedBanReasons.length !== 0) {
        setBanloading(true);
        
        const payload = {
            student_uuid: banParticipantData?.student_uuid,
            reason: checkedBanReasons.join(' , ')
        };

        post_api(urls.ban_student, payload, true)
            .then((response) => {
                setBanloading(false);
                setBanParticipant(true);
            })
            .catch((error) => {
                setBanloading(false);
            });
    } else {
        setselectAlert(true);
    }
   };
    const  handleGoBack = () => {
        setShowBanModal(false)
      };

  const render_ban_alert = () => (
    <div className="mt-6 mx-6">
      <div className="p-4 border bg-mytyellow rounded-10 border-none ">
        <p className="h-auto w-auto break-words text-mytyellowtext font-medium leading-snug">
          Banning will prevent {banParticipantData?.student_name} from having
          access to the account. Care team will contact you for further information.
        </p>
      </div>
      <div className="block">
        <h4 className={`mt-8 text-19 text-left  ${selectAlert && 'text-mytRedText'}`}>
          Please select reason to ban {banParticipantData?.student_name}
        </h4>
        {props.config_values?.student_ban_reasons.map((reason, index) => (
        <div className="mt-6 mb-6" id={index}>
        <label className="inline-flex items-center">
            <input
            type="checkbox"
            className="w-5 h-5"
            onChange={handleBanReason}
            checked={checkedBanReasons.indexOf(reason) !== -1}
            value={reason}
            id={index}
            />
            <span className="ml-4 text-sm font-normal">{reason}</span>
        </label>
        </div>
        ))}
        <div className="mt-6 mb-6">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="w-5 h-5 bg-gray-600"
                onChange={handleAllBanReason}
                checked={isAllReasonSelected}
              value="All of the above"
            />
            <span className="ml-4 text-sm font-normal">All of the above</span>
          </label>
        </div>
      </div>
      <div className="flex flex-col">
        <button
          className="w-full btn-danger p-2 mb-3 rounded-lg"
          onClick={onBanParticipant}
        >
          Ban {banParticipantData?.student_name}
        </button>
        <button
          className="w-full btn-white p-2 rounded-lg mb-8"
          onClick={handleGoBack}
        >
          Go back
        </button>
        {banloading && <Custom_loading />}
      </div>
    </div>
  );
    
  const handleBanParticipant = () => {
    setShowBanModal(!showBanModal)
  };

  const render_options = (participants) => (
    ParticipantOptId === participants.student_uuid && participantOptionsOpen ? (
    <div
      className="absolute right-0 top-55px py-25px w-max bg-white flex flex-col justify-center border border-gray-200 shadow-mytboxshadow rounded-lg cursor-pointer px-30px z-50 ml-1 gap-12px"
      style={{ right: 10 }}
      ref={dropdownRef}
      onClick={stop_propagation()}>
      <div
        className="class-btn-size text-black hover:text-red-600 font-inter font-semibold text-15.5px leading-19px"
        onClick={() => openProfilePage(participants.student_slug)}>
        View profile
      </div>
      <div
        className="class-btn-size text-black hover:text-red-600 font-inter font-semibold text-15.5px leading-19px"
        onClick={() => {
          closeViewParticiPop();
          ChatService.openP2PChat(participants.student_uuid);
        }}>
        Chat
      </div>
      <div
        className="class-btn-size text-red-600 font-inter font-semibold text-15.5px leading-19px"
        onClick={() => {
          handleBanParticipant();
           setBanParticipantData(participants)
        }}>
        Ban {participants.student_name}
      </div>
    </div>
  ) : null )
  
  const render_not_joined_participants = (data) => (
    <>
      <div className="bg-gray-100 zm:px-30px md:px-40px py-3.5 flex justify-between">
        <p className="text-black zm:text-12.5px zm:leading-15px md:leading-18px md:text-15px font-medium font-inter opacity-40">
          {data?.not_joined_participants_count} more registered but haven't joined
        </p>
        <span
          className="md:text-15px zm:text-12.5px font-medium font-inter text-mytLitePurpleTextDefaultV1 cursor-pointer"
          onClick={() => {
             setShowAllNonJoined(!showAllNonJoined);
          }}
        >
          {showAllNonJoined? 'Show less ':'Show all'}
        </span>
      </div>
      {showAllNonJoined && show_nonjoined_participants(data)}
    </>
  );
    
  const show_nonjoined_participants = (data) => (
    <ul className="ps_paricipi_ul">
      {data?.not_joined_participants.length !== 0 ? (
        data.not_joined_participants
          .sort((a, b) => {
            if (a.student_name.toLowerCase() < b.student_name.toLowerCase()) return -1;
            if (a.student_name.toLowerCase() > b.student_name.toLowerCase()) return 1;
            return 0;
          })
          .sort((a, b) => b.is_first_session_with_teacher - a.is_first_session_with_teacher)
          .sort((a, b) => b.is_first_session - a.is_first_session)
          .map((participant, index) => (
              <li
                className={`px-8 py-4 ${participant?.is_banned === 1 && 'bg-red-100'}`}
                key={index}
              >
                <div
                  onClick={() =>openProfilePage(participant.student_slug)}
                  className={`ps_partici_pic ${
                    !participant.student_profile_photo ? 'no_image' : ''
                  } ${participant?.is_banned === 1 && 'filter blur-sm'}`}
                  style={{
                    backgroundImage: `url(${participant.student_profile_photo})`
                  }}
                >
                  {!participant.student_profile_photo &&
                  participant.student_name &&
                  participant.student_name.length > 0
                    ? participant.student_name[0]
                    : ''}
                </div>

                {/* participant data */}
                <div className="flex flex-col">
                  <span
                    onClick={() => openProfilePage(participant.student_slug)}
                    className={`ps_partici_name ${
                      participant?.is_banned === 1 && 'filter blur-sm'
                    }`}
                  >
                    {participant.student_name}
                    {!!participant.is_first_session_with_teacher && (
                      <span className="first_session_tag"> (1st session with teacher)</span>
                    )}
                  </span>
                  <span className="px-3 flex items-center font-inter">
                    <p className="text-mytorange-default mr-2 font-normal">
                      {' '}
                      {getNumWithOrdinal(participant?.class_count_with_this_teacher)} {'  '}
                      class
                    </p>
                    <p className="font-light">
                      ({getNumWithOrdinal(participant?.class_count_overall)} overall)
                    </p>
                    <div className="rounded-full h-1.5 w-1.5 bg-gray-300 mr-2 ml-4" />{' '}
                    <p className="font-light">{participant?.student_type}</p>
                  </span>
                </div>

                {/* participant more options */}
                {/* btn for more options or banned users START */}
                {participant?.is_banned === 1 ? (
                  <button
                    className="disabled:opacity-50 text-12px font-normal md:w-78px font-inter disabled-btn-danger absolute right-4 text-xs rounded p-2 cursor-not-allowed flex"
                    disabled
                  >
                    <img
                      src="https://images.myyogateacher.com/ban.png"
                      alt="banned"
                      className="w-4 h-4 inline mr-2"
                    />
                    Banned
                  </button>
                ) : (
                  <div id={participant.student_uuid} onClick={handleOptionsChange}>
                    <img
                      alt="participant options"
                      src="https://images.myyogateacher.com/double_arrow.png"
                      className="absolute right-6 top-6 cursor-pointer"
                      ref={(ref) => {
                        iconRef.current[participant.student_uuid] = ref;
                      }}
                    />
                    {render_options(participant)}
                  </div>
                )}
                {/* btn for more options or banned users ENDS */}
              </li>
            ))
      ) : (
        <div className="py-4 text-center">No data found</div>
      )}
    </ul>
  );

  const showParticipantsTabData = (tab_type) => {
    const participants_key =
      participants?.show_participants_by_join === 0 ? 'participants' : 'joined_participants';
    return (
      <ul className="ps_paricipi_ul">
        {participants.total_participants?.length !== 0 &&
          participants.total_participants?.map((data, index) => (
            <div key={index}>
              {data?.label.toLowerCase() === tab_type.toLowerCase() ? (
                data?.[participants_key].length !== 0 ? (
                  data[participants_key]
                    .sort((a, b) => {
                      if (a.student_name.toLowerCase() < b.student_name.toLowerCase()) return -1;
                      if (a.student_name.toLowerCase() > b.student_name.toLowerCase()) return 1;
                      return 0;
                    })
                    .sort(
                      (a, b) => b.is_first_session_with_teacher - a.is_first_session_with_teacher
                    )
                    .sort((a, b) => b.is_first_session - a.is_first_session)
                    .map((participant, index2) => (
                      <li
                        className={`sm:px-30px zm:px-30px py-12px flex justify-between ${
                          participant?.is_banned === 1 && 'bg-red-100'
                        }`}
                        key={index2}>
                        <div className="flex">
                          {/* Avatar */}
                          <div
                              onClick={() => openProfilePage(participant.student_slug)}
                            className={`h-32px w-32px rounded-full bg-cover ${
                              !participant.student_profile_photo ? 'no_image' : ''
                            } ${participant?.is_banned === 1 && 'filter blur-sm'}`}
                            style={{
                              backgroundImage: `url(${participant.student_profile_photo})`
                            }}>
                            {!participant.student_profile_photo &&
                            participant.student_name &&
                            participant.student_name.length > 0
                              ? participant.student_name[0]
                              : ''}
                          </div>

                          {/* participant data */}
                          <div className="flex flex-col">
                            <span
                              onClick={() => openProfilePage(participant.student_slug)}
                              className={`ml-10px text-16px font-semibold font-inter ${
                                participant?.is_banned === 1 && 'filter blur-sm'
                              }`}>
                              {participant.student_name}
                              {!!participant.is_first_session_with_teacher && (
                                <span className="first_session_tag">
                                  {' '}
                                  (1st session with teacher)
                                </span>
                              )}
                            </span>
                            <span className="ml-10px flex sm:flex-row zm:flex-col">
                              <div className="flex items-center">
                                <p className="text-mytorange-default text-12px mr-2 font-normal">
                                  {' '}
                                  {getNumWithOrdinal(
                                    participant?.class_count_with_this_teacher
                                  )}{' '}
                                  {'  '}class
                                </p>
                                <p className="text-12px font-normal">
                                  ({getNumWithOrdinal(participant?.class_count_overall)} overall)
                                </p>
                              </div>
                              <div className="flex items-center">
                                <div className="rounded-full h-1.5 w-1.5 bg-gray-300 mr-2 sm:ml-4" />{' '}
                                <p className="text-12px font-normal">{participant?.student_type}</p>
                              </div>
                            </span>
                          </div>
                        </div>
                        {/* participant more options */}
                        {/* btn for more options or banned users START */}

                        {participant?.is_banned === 1 ? (
                          <button
                            className="disabled:opacity-50 disabled-btn-danger absolute right-4 text-xs rounded p-2 font-normal cursor-not-allowed flex"
                            disabled>
                            <img
                              src="https://images.myyogateacher.com/ban.png"
                              alt="banned"
                              className="w-4 h-4 inline mr-2"
                            />
                            Banned
                          </button>
                        ) : (
                          <>
                          
                           <div id={participant.student_uuid} onClick={handleOptionsChange}>
                              <img
                                alt="participant options"
                                src="https://images.myyogateacher.com/double_arrow.png"
                                className="absolute right-6 top-6 cursor-pointer"
                                ref={(ref) => {
                                    iconRef.current[participant.student_uuid] = ref;
                                  }}
                              />
                              {participantOptionsOpen ?
                              render_options(participant):''}
                            </div>
                          </>
                        )}
                        {/* btn for more options or banned users ENDS */}
                      </li>
                    ))
                ) : (
                  <div className="py-4 text-center">No data found</div>
                )
              ) : null}
              {data?.label.toLowerCase() === tab_type.toLowerCase() &&
                    participants.show_participants_by_join === 1 &&
                    render_not_joined_participants(data, tab_type)}
            </div>
          ))}
      </ul>
    );
  };
    

  return (
    <div className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-70 flex items-center justify-center">
      <div className="zm:rounded-br-none zm:rounded-bl-none zm:rounded-2xl  zm:w-full sm:w-none sm:w-492px h-710px z-50 bg-white sm:rounded-lg sm:py-40px zm:absolute bottom-0 sm:relative zm:pb-10px zm:pt-40px">
        <div
          className="flex justify-end cursor-pointer absolute top-1 right-1"
          onClick={props.closeViewParticiPop}>
          <CloseButtonIcon />
        </div>
        {showBanModal ? (
          <>
          {banParticipant?render_banned_participant():render_ban_alert()}
          </>
        ) : (
          <div className="ps-v-partici-in">
            <div className="sm:px-30px zm:px-30px pb-17px sm:text-19px font-semibold leading-22px font-inter zm:text-18px">
              {participants?.participants_string}
            </div>
            <div className="sm:px-30px zm:px-30px">
              <ol className="list-reset rounded flex text-sm text-mytgrey-lite font-normal">
                {participants?.participants_type && participants?.participants_type.length !== 0
                  ? participants?.participants_type.map((type, index) => (
                      <>
                        <li className={index === 0 ? 'pr-2' : 'px-2'}>
                          {type.count} {capitalizeFirstLetter(type.label)}
                        </li>
                        {participants?.participants_type.length !== index + 1 ? <li>/</li> : null}
                      </>
                    ))
                  : null}
              </ol>
            </div>
            <div className="bg-white border-b border-primary p-0 md:mt-40px zm:mt-20px">
              <nav
                className="flex justify-start flex-col sm:flex-row"
                onClick={handleParticipantNav}>
                {participants?.total_participants?.length !== 0 &&
                  participants.total_participants?.sort((a, b) => (a.label.toLowerCase() === 'trial' ? -1 : 1))
                  .map((data, index) => (
                    <button
                      id={index}
                      className={
                        data?.label === 'ALL'
                          ? ''
                          : participantNavStep === data?.label.toLowerCase()
                            ? 'text-16px font-normal leading-22px py-2 px-6 block hover:text-mytorange-hover focus:outline-none text-mytorange-default border-b-2 border-mytorange-default uppercase'
                            : 'text-16px font-normal leading-22px text-mytgrey-subText py-2 px-6 block hover:text-mytorange-default focus:outline-none uppercase'
                      }
                      name={data?.label.toLowerCase()}>
                      {data?.label === 'ALL' ? (
                        ''
                      ) : (
                        <>
                          {data?.label} {'  '} ({data?.count})
                        </>
                      )}
                    </button>
                  ))}
              </nav>
            </div>

            <div className="h-72 overflow-y-auto overflow-x-hidden z-50">{renderTabData()}</div>
          </div>
        )}
      </div>
    </div>
  );
};
