import React, { Component } from 'react';
import moment from 'moment';
import LoadingScreen from '../../util_components/loading_screen';
import urls from '../../../urls';
import { post_api } from '../../../redux/api_funcs';
import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class Roadmap extends Component {
  state = {
    loading: true,
    student_slug: '',
    student_uuid: '',
    roadmap: {},
    student_name: 'User'
  };

  componentDidMount() {
    const student_slug = this.props.router.params.studentSlug;
    try {
      const {show_roadmap} = this.props.router.location.state;
      const {student_uuid} = this.props.router.location.state;
      let {profile_photo} = this.props.router.location.state;
      const {student_name} = this.props.router.location.state;

      if (!!show_roadmap && !!student_uuid) {
        profile_photo =
          profile_photo ||
          'https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png';
        this.setState(
          { student_slug, student_uuid, profile_photo, student_name },
          this.load_data()
        );
      } else {
        this.props.router.navigate(`/student-profile/${this.props.router.params.studentSlug}`);
      }
    } catch (e) {
      this.props.router.navigate(`/student-profile/${this.props.router.params.studentSlug}`);
    }

    sendEventToClarity();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const url = urls.get_roadmap_for_student;
      const payload = { student_uuid: this.state.student_uuid };
      post_api(url, payload, true).then((res) => {
        this.setState({ roadmap: res.data.student_roadmap, loading: false });
      });
    });

  go_back = () => this.props.router.navigate(-1);

  render_roadmap_question = (item, index) => (
      <div key={index}>
        <p className="rm_ques">{item.question}</p>
        <div className="rm_ans">
          {typeof item.answer === 'string' ? (
            item.answer
          ) : (
            <ul className="rm_ul rm_ul_goal">
              {item.answer.map((option, index) => (
                <li key={index + option}>{option}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );

  render_single_roadmap = (key, items, index) => {
    const roadmap = this.props.currentRoadMapSession;
    try {
      if (items.length > 0) {
        return (
          <div className="rm-cont-in" key={index + key}>
            <div className="rm-ic">
              <img src="https://images.myyogateacher.com/Teacher-Page/icons/ic_goal_t_yj.svg" />
            </div>
            <div className="rm-ic-text">
              <h2>{key} </h2>
              {items.map(this.render_roadmap_question)}
            </div>
          </div>
        );
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  render_roadmap = () => {
    const { roadmap, loading } = this.state;
    if (!loading) {
      const {display_roadmap} = roadmap;
      const keys = Object.keys(display_roadmap);
      return (
        <>
          {keys.map((key, index) => this.render_single_roadmap(key, display_roadmap[key], index))}
        </>
      );
    }
    return null;
  };

  goToEditRoadMap = () => {
    this.props.router.navigate(`/edit-road-map`, {
      state: { roadmap: this.state.roadmap }
    });
  };

  render() {
    const { loading, profile_photo, roadmap, student_name } = this.state;
    const student_created_date = roadmap.start_date ? roadmap.start_date : new Date();
    return (
      <div className="m-card-body">
        <div className="m-card rm_card">
          {loading ? <LoadingScreen /> : null}
          <div className="m-card-header">
            <div className="m-card-head-wrap" style={{ display: 'flex' }}>
              <button className="btn" onClick={this.go_back} style={{ marginRight: '10px' }}>
                <img
                  alt="back-arrow"
                  src="https://s3.amazonaws.com/images.myyogateacher.com/icons/back-arrow-white1.svg"
                />
              </button>
              <h1>Road Map</h1>
            </div>
          </div>

          <div className="road-map-sub-header">
            <div className="rm_stu_info">
              <div
                className="rm-profile-pic"
                style={{ backgroundImage: `url(${profile_photo})` }}
              />
              <div className="rm_stu_detail">
                <h4>{student_name}</h4>
                <p className="rm_stu_joined_d">
                  {moment(student_created_date).format('D MMM YYYY')}
                </p>
              </div>
            </div>

            <a href="/">
              <img
                className="myt_rm_logo"
                src="https://images.myyogateacher.com/myt-logo-new2.png"
                alt="logo"
              />
            </a>
          </div>

          <div className="rm-card-content">
            <div className="green_rm_head">
              <h1>Roadmap</h1>
              <p>{`For - ${roadmap.no_of_sessions} Sessions`}</p>
            </div>

            {this.render_roadmap()}
          </div>

          <div className="rm-card-footer">
            <button className="btn btn-pri-bd" onClick={this.goToEditRoadMap}>
              Edit roadmap
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const RoadmapWithRouter = withRouter(Roadmap);

export default RoadmapWithRouter;
