import React, { Component } from "react";
import PropTypes from "prop-types";
import { isMobile, isTablet } from "react-device-detect";
import videojs from "video.js";
import "../../../../../node_modules/video.js/dist/video-js.css";
import "./video.css";

export default class CompeleteVideo extends Component {
  state = {
    video_loaded: false,
    is_mute: isMobile || isTablet,
    is_playing: null,
    video_start_time: null,
    first_time_display_play_icon: true,
  };
  componentDidMount() {
    // instantiate Video.js
    this.initiate_player();
  }
  // destroy player on unmount
  componentWillUnmount() {
    this.dispose_palyer();
  }
  dispose_palyer = () => {
    try {
      if (this.player) {
        this.player.dispose();
      }
    } catch {}
  };
  initiate_player = () => {
    const videoJsOptions = {
      autoplay: this.props.auto_play,
      controls: this.props.show_controls,
      controlBar: {
        pictureInPictureToggle: false,
      },
      autoHeight: true,
      muted: isMobile || isTablet,
      poster: this.props.thumbnail,
      sources: [
        {
          src: this.props.src,
          type: this.props.type,
        },
      ],
    };
    if (this.props.video_poster_no_play_icon_first_time === true) {
      this.setState({
        first_time_display_play_icon: false,
      });
    }
    this.player = videojs(this.videoNode, { ...videoJsOptions }, () => {
      this.setState({ video_loaded: true }, () => {
        this.player.on("error", (error) => {
          console.log("video error is ", error);
        });
        if (this.props.auto_play) {
          this.setState(
            { is_playing: true, video_start_time: new Date().getTime() },
            () => {}
          );
        }
        this.player.on("pause", () => {
          this.pause_video();
        });
        this.player.on("play", () => {
          this.play_video();
        });
        this.player.on("ended", () => {
          this.state({ is_playing: false });
        });
      });
    });
  };
  toggle_play = () => {
    if (this.state.video_loaded) {
      if (this.state.is_playing) {
        this.pause_video();
      } else {
        this.play_video();
        this.setState({ first_time_display_play_icon: true });
      }
    }
  };

  pause_video = () => {
    if (this.state.is_playing) {
      try {
        this.player.pause();
        // this.player.controls(false);
        this.setState({ is_playing: false });
      } catch {}
    }
  };
  play_video = (e) => {
    try {
      this.player.play();
      this.player.muted(false);
      if (isMobile || isTablet) {
        this.player.requestFullscreen();
        // this.player.controls(true);
      }
      this.setState({
        is_playing: true,
        is_mute: false,
        video_start_time: new Date().getTime(),
      });
    } catch {}
  };
  unmute_video = (e) => {
    try {
      e.stopPropagation();
      this.player.muted(false);
      this.setState({ is_mute: false });
    } catch {}
  };
  mute_video = (e) => {
    try {
      e.stopPropagation();
      this.player.muted(true);
      this.setState({ is_mute: true });
    } catch {}
  };
  render() {
    const vid_with =
      this.videoNode !== undefined
        ? this.videoNode.getBoundingClientRect().width
        : 0;
    let icon_class = "common-video-icons";
    if (vid_with < 450) {
      icon_class = "common-video-icons-s";
    } else if (vid_with >= 450 && vid_with < 800) {
      icon_class = "common-video-icons-m";
    } else {
      icon_class = "common-video-icons-l";
    }
    let control_class = this.props.show_controls
      ? " common-video-show-controls"
      : "";
    let bar_class =
      this.props.show_controls && this.state.is_playing
        ? " common-video-keep-controls"
        : "";
    return (
      <div className={"complete-video" + bar_class}>
        <div className="common-video-container">
          <div data-vjs-player>
            <video
              ref={(node) => (this.videoNode = node)}
              className={"video-js " + this.props.frame}
            />
          </div>
          <div
            className={
              "common-video-icon-container " + icon_class + control_class
            }
            onClick={this.toggle_play}
          >
            {this.state.video_loaded ? (
              this.state.is_playing ? (
                this.props.show_controls ? null : (
                  <img
                    className="c-common-video-pause-img"
                    src="https://images.myyogateacher.com/icons/ic_pause_cir_b@2x.png"
                  />
                )
              ) : (
                <div>
                  {this.state.first_time_display_play_icon === false ? null : (
                    <img src="https://images.myyogateacher.com/icons/ic_play_new@2x.png" />
                  )}
                </div>
              )
            ) : null}
            {!this.props.show_controls &&
            this.state.video_loaded &&
            this.state.is_playing ? (
              this.state.is_mute ? (
                <img
                  src="https://images.myyogateacher.com/icons/ic_mute_cir@2x.png"
                  className="common-video-mute-img cc-mute-img"
                  onClick={this.unmute_video}
                />
              ) : (
                <img
                  src="https://images.myyogateacher.com/icons/ic_unmute_cir@2x.png"
                  className="common-video-mute-img cc-mute-img"
                  onClick={this.mute_video}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

CompeleteVideo.propTypes = {
  show_controls: PropTypes.bool,
  auto_play: PropTypes.bool,
  open_full_screen: PropTypes.bool,
  initial_mute: PropTypes.bool,
  frame: PropTypes.string,
  event_properties: PropTypes.object,
  video_poster_no_play_icon_first_time: PropTypes.bool,
  show_audio_icons: PropTypes.bool,
};

CompeleteVideo.defaultProps = {
  show_controls: false,
  auto_play: false,
  open_full_screen: true,
  frame: "vjs-16-9",
  event_properties: {},
  show_audio_icons: true,
};
