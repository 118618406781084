import * as t from '../action_types';

var initialStata = {
    class_details_status: 'none',
    class_details_response: {},

    schedule_class_status: 'none',
    schedule_class_response: null,

    student_count_status: 'none',
    student_count_response: null,

    student_profile_status: 'none',
    student_profile_response: null,

    class_duration_status: 'none',
    class_duration_response: null,

    scheduleSessionDataInfoErrMsg: {},
}


const set_class_details_variable = (state, payload) => {
    return {
        ...state,
        [payload.key]: payload.payload,
    };
};

export default function (state = initialStata, action) {
    switch (action.type) {
        case t.set_class_details_variable:
            return set_class_details_variable(state, action.payload);
        default:
            return state;
    }
}