import React from 'react';
import CommunityClasses from './community_classes';
import './dashboard_funcs.css';
import SessionClasses from './session_classes';

const ScheduleModal = (props) => {
	const { data } = props;
	const list = data?.[0] ?? [];
	const dataList = list?.data ?? {};
	if (Object.keys(dataList).length === 0) {
		props.onCloseClick();
	}

	return (
		<>
			<div className='relative inset-0 z-50'>
				<div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'>
					<div
						className={`z-50 bg-white zm:rounded-2xl zm:rounded-tl-3xl zm:rounded-tr-3xl zm:rounded-bl-none zm:rounded-br-none md:rounded-lg md:p-40px zm:px-30px zm:py-40px md:w-562px zm:w-full relative false ${
							data.length > 1 && 'h-3/4'
						} overflow-y-scroll`}
					>
						<div
							class='flex justify-end cursor-pointer absolute top-1 right-1'
							onClick={props.onCloseClick}
						>
							<div class='mt-1 mr-1'>
								<svg
									width='40'
									height='40'
									viewBox='0 0 34 34'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect width='34' height='34' rx='17' fill='none'></rect>
									<path
										d='M22 12L12 22'
										stroke='#BFBFBF'
										strokeWidth='1.66667'
										strokeLinecap='round'
										strokeLinejoin='round'
									></path>
									<path
										d='M12 12L22 22'
										stroke='#BFBFBF'
										strokeWidth='1.66667'
										strokeLinecap='round'
										strokeLinejoin='round'
									></path>
								</svg>
							</div>
						</div>
						{/* <div onClick={props.onCloseClick} className="close_btn">
              <img
                className="close_img"
                src="https://images.myyogateacher.com/icons/ic_white_cross.png"
              />
            </div> */}
						<div className='mb-0'>
							<div className='font-semibold lg:text-19px zm:text-17px leading-22px'>{`${
								data.length
							} Class ${data.length > 1 ? 'requests' : 'request'}`}</div>
							<div className='group_meet_cards'>
								{data.map((d, index) => {
									const { type } = d.data;

									if (type === 'SESSION_REPLACEMENT') {
										return (
											<SessionClasses
												onUserProfileClick={props.onUserProfileClick}
												index={index}
												timezone={props.timezone}
												onAccept={props.onAccept}
												onDecline={props.onDecline}
												data={d.data}
											/>
										);
									} else if (type === 'community_class') {
										return (
											<CommunityClasses
												index={index}
												onAccept={props.onAccept}
												onDecline={props.onDecline}
												data={d.data}
												onRequestorProfileClick={props.onRequestorProfileClick}
											/>
										);
									}
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ScheduleModal;
