import React from 'react';
import PropTypes from 'prop-types';
import './custom_input.css';

const CustomInput = props => (
    <div className={`custom-input-container ${props.inputStyle}`} style={{ width: props.width }}>
        <input
            type={props.type}
            value={props.value == null ? '' : props.value}
            onChange={props.handleChange}
            style={{ height: `${props.inputHeight}` }}
            autoComplete={props.autoComplete}
            name={props.name}
        />
        {typeof props.label !== 'undefined' && props.label !== '' ? <label>{props.label}</label> : null}
    </div>
);

CustomInput.propTypes = {
    width: PropTypes.string,
    type: PropTypes.string,
    inputHeight: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    autoComplete: PropTypes.string
};

CustomInput.defaultProps = {
    width: '200px',
    type: 'text',
    inputHeight: '35px',
    autoComplete: 'off',
    name: 'input'
};

export default CustomInput;
