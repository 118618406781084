import React, { useEffect, useState } from 'react'
import LoadingScreen from '../../components/util_components/loading_screen'
import { get_api, post_api } from '../../redux/api_funcs'
import urls from '../urls/urls'
import Button from './CommonComponents/Button'
import Modal from './CommonComponents/Modal'

function TherapyPlanPopUp(props) {
    const [questions, setquestions] = useState([])
    const [answers, setanswers] = useState([]);
    const [loading, setloading] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const getRoadMapDetails = async () => {
        setloading(true);
        try {
            const url = urls.config;
            let res = await get_api(url, false);
            let questions = res.data.default_backpain_roadmap_questions;
            questions = questions.map((que, index) => {
                return {
                    question: que,
                    roadmap_question_uuid: '',
                    answer:''
                }
            })
            setquestions(questions);
            let answersArr = [];
            answersArr = questions.map((que, index) => {
                return ({
                    question: que.question,
                    answer: ''
                })
            })
            setanswers(answersArr);
            setloading(false);
        } catch (error) {
            setloading(false);

        }
     
    }
    const getRoadMapDetailsForEditing = () => {
        setloading(true);
        let url = urls.get_roadmap_for_student;
        let createRoadmapUrl = urls.create_roadmap;
        post_api(createRoadmapUrl, { student_uuid: props.session_details.student_uuid }, true).then(res => {

            post_api(url, { student_uuid: props.session_details.student_uuid }, true).then(res => {

                let questions = res.data.student_roadmap.roadmap_questions;
                setquestions(questions);
                let answersArr = [];
                answersArr = questions.map((que, index) => {
                    return ({
                        uuid: que.roadmap_question_uuid,
                        answer: que.answer
                    })
                })
                // if (props.prefilledValues) {
                //     props.prefilledValues.forEach((question) => {
                //         let index = answersArr.findIndex((ans) => ans.uuid === question.uuid);
                //         if (index !== -1) {
                //             answersArr[index] = {
                //                 uuid: question.uuid,
                //                 answer: question.answer
                //             }
                //         }
                //     })
                // }
                setanswers(answersArr);
                setloading(false);
            }).catch(err => {
                setloading(false);
            })
        }).catch(err => {

        })

    }
    useEffect(() => {
        if (props.isEditing) {
            getRoadMapDetailsForEditing();
        }
        else {
            getRoadMapDetails();

        }
    }, [])
    const OnInputChange = (uuid, value, index) => {
        if (props.isEditing) {
            let modifiedAns = [...answers];
            modifiedAns[index] =
            {
                uuid,
                answer: value
            }
            setanswers(modifiedAns); 
        }
        else {
            let modifiedAns = [...answers];
            modifiedAns[index] =
            {
                question:questions[index].question,
                answer: value
            }
            setanswers(modifiedAns); 
        }
        
    }
    const submitAnswerDetails = () => {
        if (props.isEditing) {
            let url = urls.save_roadmap_data;
            let payload = {
                student_uuid: props.session_details.student_uuid,
                roadmap_data: JSON.stringify([
                    ...answers
                ])
            }
            post_api(url, payload, true).then(res => {
                setShowConfirmationPopup(true);
            }).catch(err => {
    
            }) 
        }
      
        else {
            let url = urls.create_roadmap;
            let payload = {
                student_uuid: props.session_details.student_uuid,
                roadmap_questions_answers: [
                    ...answers
                ]
            }
            post_api(url, payload, true).then(res => {
                setShowConfirmationPopup(true);
            }).catch(err => {
    
            }) 
        }
    }
    const closeSuccessModal = () => {
        let hasSubmitClose = props.hasOwnProperty('onSubmitClose');
        let hasClose = props.hasOwnProperty('onClose');
        if (hasSubmitClose) {
            props.onSubmitClose();
 
        }
        if (!hasSubmitClose && hasClose) {
            props.onClose();
        }
        setShowConfirmationPopup(false);
    }
    const renderSubmitPopup = (isEditing = false) => {
        return (
            <>
                <div className='relative inset-0 z-50'>
                    <div
                        className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'
                    >
                        <div className='z-50 bg-white zm:rounded-tl-3xl zm:rounded-tr-3xl zm-roundex-lb-none zm:rounded-rb-none md:rounded-lg md:p-10 zm:p-30px md:w-492px zm:w-full relative'>
                            <div className='flex justify-end cursor-pointer absolute top-1 right-1' onClick={() => closeSuccessModal()}>
                                <div className='mt-1 mr-1'>
                                    <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <rect width='34' height='34' rx='17' fill='none' />
                                        <path
                                            d='M22 12L12 22'
                                            stroke='#BFBFBF'
                                            strokeWidth='1.66667'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M12 12L22 22'
                                            stroke='#BFBFBF'
                                            strokeWidth='1.66667'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className='flex flex-col justify-center items-center md:mt-0 zm:mt-9px'>
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="22" fill="#EE731B" />
                                    <path d="M31.4265 14.9285L18.4622 27.8928L12.5693 22" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <div className='text-subText md:text-19 zm:text-17 font-semibold pt-4'>{`Relief plan has been ${isEditing ? 'edited' : 'created'} successfully`}</div>
                                {!isEditing && <p className='text-mytgrey-lite text-14.5 font-normal text-center leading-normal'>You can view or edit it in the client profile
                                </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
    const shouldSubmitBeDisabled = questions?.length !== answers?.length || answers.some((ans) => !ans || ans === undefined || ans.answer === '');
    const isEditing = props?.isEditing || false;
    return (
        showConfirmationPopup ? renderSubmitPopup(isEditing) :
            <Modal childrendiv="overflow-auto h-calc140px" onClose={props.onClose}>
                {loading ? <LoadingScreen />
                    : <div className=' px-10 pb-10 pt-1.5'>
                        <div className='font-semibold text-19px leading-22px text-greyText pb-48px'>{`${isEditing ? 'Edit' : 'Create'} Relief Plan`}</div>
                        {questions?.map((que, index) => {
                            return (
                                <>
                                    <div key={que.roadmap_question_uuid} className='text-13.5 font-medium text-mytLabel mb-4'>{que.question}</div>
                                    <textarea
                                        placeholder='Enter here...'
                                        className='formInput h-100px p-18px text-15px leading-18px font-medium text-mytpurple mb-48px'
                                        value={answers[index]?.answer || ''}
                                        onChange={(e) => OnInputChange(que.roadmap_question_uuid, e.target.value, index)}
                                    >
                                    </textarea>
                                </>
                            )
                        })}
                        <Button
                            btntext="Submit details"
                            classNames="pt-12.5px pb-11.5px pl-18px pr-18px mt-10"
                            onClick={() => shouldSubmitBeDisabled ? null : submitAnswerDetails()}
                            disabled={shouldSubmitBeDisabled}
                            style={{ opacity: shouldSubmitBeDisabled ? '0.3' : '1' }}
                        />
                    </div>}
            </Modal>
    )
}

export default TherapyPlanPopUp;