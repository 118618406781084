const api_staging = 'https://gstg1.myyogateacher.com';
const student_web_staging = 'https://student-testenv1.myyogateacher.com';
const chat_staging = 'samvaad-gstg1.myyogateacher.com';
const hridiya_staging = 'https://hridaya-testenv1.myyogateacher.com';

const api_prod = 'https://gapi.myyogateacher.com';
const student_web_prod = 'https://myyogateacher.com';
const chat_prod = 'samvaad.myyogateacher.com';
const hridiya_prod = 'https://hridaya.myyogateacher.com';
let url_domain = api_staging;
let mytChatUrl = chat_staging;
let student_app_domain = student_web_staging;
let hridiya_domain = hridiya_staging;
switch (import.meta.env.VITE_APP_STAGE) {
  case 'production':
    url_domain = api_prod;
    mytChatUrl = chat_prod;
    student_app_domain = student_web_prod;
    hridiya_domain = hridiya_prod;
    break;
  default:
    break;
}

const urls = (domain, mytChatUrl) => ({
  chat_unread: `https://${mytChatUrl}/v1.0/messages/unread?channel=:CHANNEL_ID`,
  config: `${domain  }/teacher/api/config.json`,
  teacher_sessions_list: `${domain  }/teacher/api/sessions_list`,
  sessions_list_short: `${domain  }/teacher/api/sessions_list_short`,
  sessions_list_v2: `${domain  }/v2/teacher_sessions/session/list`,
  withdrawReplacementRequestV2: `${domain  }/v2/teacher_sessions/replacement/request/:SESSION_UUID`,
  get_availability_list: `${domain  }/teacher/api/teacher_web_app_get_availability`,
  add_availability: `${domain  }/teacher/api/teacher_web_app_add_availability`,
  edit_availability: `${domain  }/teacher/api/teacher_web_app_edit_availability`,
  delete_availability: `${domain  }/teacher/api/delete_teacher_schedule`,

  // TEACHER WEB SLOTS AVAILABILITY

  get_teacher_slots: `${domain  }/teacher/api/get_all_day_wise_availability_v2`,
  add_teacher_slots: `${domain  }/teacher/api/add_availability_v2`,
  update_teacher_slots: `${domain  }/teacher/api/update_availability_v2`,
  delete_teacher_slots: `${domain  }/teacher/api/delete_availability_v2`,
  get_replacement_sessions_teacher_slots: `${domain  }/teacher/api/get_sessions_in_time_range`,
  post_request_repalcement: `${domain  }/v2/teacher_sessions/replacement/request`,
  my_session_v1: `${domain  }/v2/teacher/profile/my_sessions`,
  my_session_data_v1: `${domain  }/v2/teacher/profile/get_sessions_status`,

  // ------------XXXXXXXX----------

  create_roadmap: `${domain  }/teacher/api/create_roadmap`,
  update_session_recap: `${domain  }/teacher/api/update_session_recap`,
  update_roadmap_question: `${domain  }/teacher/api/update_roadmap_question`,
  update_roadmap: `${domain  }/teacher/api/update_roadmap`,
  get_yoga_journey: `${domain  }/teacher/api/get_yoga_journey`,
  teacher_details: `${domain  }/teacher/api/teachers_details`,
  update_profile_basic: `${domain  }/teacher/api/update_basic_details`,
  update_profile_yoga: `${domain  }/teacher/api/update_yoga_details`,
  update_profile_social: `${domain  }/teacher/api/update_social_links`,
  update_profile_other: `${domain  }/teacher/api/update_other_details`,
  update_address_proof: `${domain  }/teacher/api/update_address_proof`,
  update_id_proof: `${domain  }/teacher/api/update_id_proof`,
  update_name_password: `${domain  }/teacher/api/update_name_password`,
  update_photo: `${domain  }/teacher/api/update_photo`,
  update_app_details: `${domain  }/teacher/api/update_app_details`,
  upload_resume: `${domain  }/teacher/api/upload_resume`,
  verify_email_account: `${domain  }/teacher/api/verify_email_account`,
  check_email_verification: `${domain  }/teacher/api/check_email_verification`,
  login_app: `${domain  }/teacher/api/login_app`,
  signup_app: `${domain  }/teacher/api/teacher_signup_from_website`,

  get_zoom_signature: `${domain  }/v2/zoom_services/get_zoom_signature`,
  zoom_join: `${domain  }/v2/meetings/zoom/join`,

  reset_password: `${domain  }/teacher/api/reset_password`,
  verify_rpswrd_tkn: `${domain  }/teacher/api/verify_rpswrd_tkn`,
  clear_email_login: `${domain  }/cms/api/login_app`,
  clear_email_queue: `${domain  }/cms/api/dkdeoemdjrnfmdedjek`,
  update_video: `${domain  }/teacher/api/update_video`,
  get_twilio_token: `${domain  }/teacher/api/get_twilio_access_token_for_teacher`,
  cancel_session: `${domain  }/teacher/api/cancel_session`,
  student_details: `${domain  }/teacher/api/student_details`,
  student_details_slug: `${domain  }/teacher/api/student_details_by_slug`,
  get_session_update_slots: `${domain  }/teacher/api/get_session_time_update_time_slots`,
  schedule_session_with_student: `${domain  }/teacher/api/schedule_session_v1`,
  change_session_time: `${domain  }/teacher/api/update_session_time`,
  join_session: `${domain  }/teacher/api/join_session`,
  start_teacher_session_logs: `${domain  }/teacher/api/start_teacher_session_logs`,
  end_teacher_session_logs: `${domain  }/teacher/api/end_teacher_session_logs`,
  invite_webinar: `${domain  }/teacher/api/invite_webinar`,
  get_students_of_teacher: `${domain  }/teacher/api/get_students_of_teacher`,
  my_sessions: `${domain  }/teacher/api/my_sessions`,
  request_replacement: `${domain  }/teacher/api/request_replacement`,
  request_replacement_v2: `${domain  }/v2/teacher_sessions/replacement/request`,
  getStudents: `${domain  }/teacher/api/students_tab_results_for_teacher_app`,
  create_group_session: `${domain  }/teacher/api/create_group_session`,
  onboard_teacher: `${domain  }/teacher/api/onboard_teacher`,
  update_status: `${domain  }/teacher/api/update_status`,
  students_tab_results_for_teacher_app_v1:
    `${domain  }/teacher/api/students_tab_results_for_teacher_app_v1`,
  create_twilio_channel: `${domain  }/teacher/api/create_twilio_channel`,
  update_twilio_member: `${domain  }/teacher/api/update_twilio_member`,
  get_yoga_journey_by_slug: `${domain  }/teacher/api/get_yoga_journey_by_slug`,
  get_roadmap_for_student: `${domain  }/teacher/api/get_roadmap_for_student`,
  hridiya_session_details: `${hridiya_domain  }/sessiondetails`,
  get_all_workshops: `${domain  }/teacher/api/get_all_workshops`,
  get_all_workshops_wo_token: `${domain  }/teacher/api/get_all_workshops_wo_token`,
  get_workshop_details: `${domain  }/teacher/api/get_workshop_details`,
  get_workshop_details_wo_token: `${domain  }/teacher/api/get_workshop_details_wo_token`,
  mytChatUrl,
  get_notifications: `${domain  }/teacher/api/get_notifications`,
  get_new_notifications_summary: `${domain  }/teacher/api/get_new_notifications_summary`,
  get_session_participants: `${domain  }/teacher/api/get_session_participants`,
  update_notification_read_status: `${domain  }/teacher/api/update_notification_read_status`,
  get_announcements: `${domain  }/teacher/api/get_announcements`,
  read_announcement: `${domain  }/teacher/api/read_announcement`,
  community_proposal_accept: `${domain  }/v2/community/proposals/requests/accept`,
  community_proposal_ignore: `${domain  }/v2/community/proposals/requests/ignore`,

  accept_session_replacement:
    `${domain  }/v2/teacher_sessions/replacement/request/SESSION_UUID/accept`,
  reject_session_replacement:
    `${domain  }/v2/teacher_sessions/replacement/request/SESSION_UUID/reject`,
  get_community_profile: `${domain  }/v2/community/proposals/creator/info`,

  get_proposal_info: `${domain  }/v2/community/proposals/create/info`,
  propose_group_class: `${domain  }/v2/community/proposals/create`,
  is_community_class_proposals_create_allowed: `${domain  }/v2/community/proposals/create/allowed`,
  get_twilio_voice_token: `${domain  }/v2/teacher_consult/twilio/voice/token`,
  impersonate_myt_user_teacher_consultancy:
    `${domain  }/teacher/api/impersonate_myt_user_teacher_consultancy`,
  get_active_teacher_consult_call_status:
    `${domain  }/v2/teacher_consult/consult/:consult_id/active_call`,
  student_impersonation_url: `${student_app_domain  }/dashboard?action=teacher_redirect`,
  ban_student: `${domain  }/teacher/api/ban_student`,
  yoga_consult_slots: `${domain  }/teacher/api/yoga_consult_slots`,
  schedule_yoga_consultation: `${domain  }/teacher/api/schedule_yoga_consultation`,
  client_pain_levels: `${domain  }/teacher/api/client_pain_levels`,
  submit_consultation_form: `${domain  }/teacher/api/submit_consultation_form`,
  schedule_therapy_consultation: `${domain  }/teacher/api/schedule_therapy_consultation`,
  therapy_slots: `${domain  }/teacher/api/therapy_slots`,
  backpain_get_impersonation_token: `${domain  }/teacher/api/get_impersonation_token`,
  backpain_client_profile: `${domain  }/teacher/api/client_profile`,
  // my session api v2
  my_session_cancelled_classes: `${domain  }/v2/teacher/profile/get_sessions_status_cancelled`,
  my_session_replaced_classes: `${domain  }/v2/teacher/profile/get_sessions_replaced`,
  my_session_noshow_classes: `${domain  }/v2/teacher/profile/get_sessions_status_no_show`,
  my_session_question_classes: `${domain  }/v2/teacher/profile/update_session_recap`,

  send_sms: `${domain  }/v2/notifications/twilio/sms`,

  phone_call_reminder: `${domain  }/v2/teacher/reminder/phone_call`,

  livekit_join: `${domain  }/v2/meetings/livekit/join`,

  livekit_ban: `${domain  }/v2/meetings/livekit/banned`,
  livekit_lock_meeting: `${domain  }/v2/meetings/livekit/metadata`,
  livekit_mute_participant: `${domain  }/v2/meetings/livekit/muted`,
  livekit_stop_rec: `${domain  }/v2/meetings/livekit/record`,
  livekit_spotlight: `${domain  }/v2/meetings/livekit/spotlight`,
  livekit_video_mode: `${domain  }/v2/meetings/livekit/video/mode`,
  livekit_upload_image: `${domain  }/v2/meetings/livekit/images/upload`,
  livekit_request_image_capture: `${domain  }/v2/meetings/livekit/images/capture`,
  livekit_upload_video: `${domain  }/v2/meetings/livekit/videos/upload`,
  livekit_video_capture_start: `${domain  }/v2/meetings/livekit/videos/capture/start`,
  livekit_video_capture_stop: `${domain  }/v2/meetings/livekit/videos/capture/stop`,
  livekit_device_mode_switch: `${domain  }/v2/meetings/livekit/device/mode/switch`,
  get_teacher_notification_preferences: `${domain  }/v2/teacher/notification_preferences`,
  update_teacher_notification_preferences:
    `${domain  }/teacher/api/update_teacher_notification_preferences`
});

export default urls(url_domain, mytChatUrl);
