import React from 'react';
import './wlisting.css';

import { connect } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import * as actions from '../../../redux/action_creators';
import LoadingScreen from '../../util_components/loading_screen';
import SimplePop from './simple_pop';
import { getCookie, sendEventToClarity, setCookie } from '../../../util_functions';
import Spinner from '../../util_components/spinner';
import { withRouter } from '../../../utils/router';

class WorkshopListingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      myWorkshops: [],
      workshops: [],
      confirm_loading: false,
      showInterestPop: false,
      loading: true
    };

    this.preview = new URLSearchParams(this.props.router.location.search).get('preview') ? 1 : 0;

    // mobile app
    const token = new URLSearchParams(this.props.router.location.search).get('token');
    if (token !== null && token !== undefined && token !== '') {
      setCookie('student_token', token);
    } else if (!this.isUserLoggedIn()) {
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              status: 'failure'
            })
          );

        // return;
      }
  }

  isUserLoggedIn = () => {
    if (typeof getCookie('teacher_token') === 'undefined' || getCookie('teacher_token') === '') {
      return false;
    } return true;
  };

  componentDidMount() {
    const body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = '#f5f6fa';

    this.props.get_all_workshops({
      all_workshops: this.preview
    });

    sendEventToClarity();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.getAllWorkshopStatus === 'loading' &&
      this.props.getAllWorkshopStatus === 'success'
    ) {
      const workshops = this.props.getAllWorkshopsResponse.filter((workshop) => !workshop.is_enrolled_for_this_workshop);

      const myWorkshops = this.props.getAllWorkshopsResponse.filter((workshop) => !!workshop.is_enrolled_for_this_workshop);

      this.setState({ workshops, myWorkshops, loading: false });
    }

    if (
      prevProps.showInterestInWorkshopStatus === 'loading' &&
      this.props.showInterestInWorkshopStatus === 'success'
    ) {
      this.setState({ confirm_loading: false });
      const params = {
        all_workshops: this.preview
      };
      if (this.isUserLoggedIn()) {
        this.props.get_all_workshops(params);
      } else {
        this.props.get_all_workshops_wo_token({
          all_workshops: this.preview,
          iana_timezone: moment.tz.guess(true)
        });
      }
    }
  }

  showInterestPop = () => {
    this.setState({
      showInterestPop: true
    });
  };

  closeInterestPop = () => {
    this.setState({
      showInterestPop: false
    });
  };

  renderMyWorkshops = () => (
      <div className="w-lp-card ur-worskshops-cont ">
        <h2 className="ur_w_h">My workshops</h2>
        {this.state.myWorkshops.map((workShopItem, index) => {
          const percent = (workShopItem.classes_finished / workShopItem.total_classes) * 100;
          return (
            <div key={index} className="w-lp-item-outer">
              <div className="w-lp-item">
                <div
                  className="w-lp-item-img"
                  style={{
                    backgroundImage: `url(${workShopItem.list_thumbnail}), url("https://s3.amazonaws.com/images.myyogateacher.com/user-listing-image.png")`
                  }}
                 />
                <div className="w-lp-item-detail">
                  <div className="w-lp-item-d-main w-lp-detail-text">
                    <div className="w-lp-detail-text">
                      <div className="w-lp-d-text-head">
                        <h4>{workShopItem.workshop_name}</h4>
                      </div>

                      <p className="w-lp-des">
                        {workShopItem.workshop_description.length > 100 ? (
                          <>
                            {workShopItem.workshop_description.slice(0, 100)}
                            <span>...</span>
                          </>
                        ) : (
                          workShopItem.workshop_description
                        )}
                      </p>

                      {workShopItem.total_classes > 0 && (
                        <div className="w-lp-meter-desk">
                          <span className="sw_class_meter_info">
                            {workShopItem.classes_finished} / {workShopItem.total_classes} classes
                          </span>
                          <div className="meter">
                            <span style={{ width: `${percent}%` }} />
                          </div>
                        </div>
                      )}

                      <div className="w-lp-f-info">
                        <div className="t-text">
                          <span>
                            <b
                              className="w-lp-dp"
                              style={{
                                backgroundImage: `url(${workShopItem.teacher_profile}), url("https://images.myyogateacher.com/profile-pic.png")`
                              }}
                             />

                            {workShopItem.teacher_name}
                          </span>
                        </div>

                        <div className="w-lp-text-btn-desk">
                          <span className="slw-btm-time">
                            <img
                              alt="time"
                              src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                            />
                            {workShopItem.total_hours} hours
                          </span>
                          <button
                            onClick={() => {
                              let path = `/workshops/${  workShopItem.slug}`;

                              if (this.preview) {
                                path = `${path}?preview=${true}`;
                              }

                              this.props.router.navigate(path, {
                                pathname: path,
                                state: { workShopItem }
                              });
                            }}
                            className="sw-btna sw-main-btn slw-btn-vd"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* for mobile view */}
              <div className="mb-lp-price-offer">
                <div className="lp-po-text-up">
                  <div className="w-lp-text-btn-mb">
                    {/* <span className='slw-btm-time'>
											<img alt='time' src='https://images.myyogateacher.com/icons/ic_watch_green.svg' />
											{workShopItem.total_hours} hours
										</span> */}

                    <button
                      onClick={() => {
                        let path = `/workshops/${  workShopItem.slug}`;

                        if (this.preview) {
                          path = `${path}?preview=${true}`;
                        }

                        this.props.router.navigate(path, {
                          state: { workShopItem }
                        });
                      }}
                      className="sw-btna sw-main-btn"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );

  renderWorkshops = () => (
      <div className="w-lp-card">
        {this.state.workshops.map((workShopItem, index) => (
            <div
              key={index}
              className={`w-lp-item-outer ${workShopItem.seats_left === 0 && 'lp-full'}`}
            >
              <div className={`w-lp-item ${workShopItem.seats_left === 0 && 'lp-full'}`}>
                <div
                  className="w-lp-item-img"
                  style={{
                    backgroundImage: `url(${workShopItem.list_thumbnail}), url("https://s3.amazonaws.com/images.myyogateacher.com/user-listing-image.png")`
                  }}
                 />
                <div className="w-lp-item-detail">
                  <div className="w-lp-item-d-main">
                    <div className="w-lp-detail-text">
                      <h4>{workShopItem.workshop_name}</h4>
                      <p className="w-lp-des showit">
                        {workShopItem.workshop_description.length > 100 ? (
                          <>
                            {workShopItem.workshop_description.slice(0, 100)}
                            <span>...</span>
                          </>
                        ) : (
                          workShopItem.workshop_description
                        )}
                      </p>
                      <div className="w-lp-f-info">
                        <div className="t-text">
                          <span>
                            <b
                              className="w-lp-dp"
                              style={{
                                backgroundImage: `url(${workShopItem.teacher_profile}), url("https://images.myyogateacher.com/profile-pic.png")`
                              }}
                             />
                            {workShopItem.teacher_name}
                          </span>
                        </div>

                        <div className="w-lp-text-btn-desk">
                          <span className="slw-btm-time">
                            <img
                              alt="time"
                              src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                            />
                            {workShopItem.total_hours} hours
                          </span>

                          {workShopItem.seats_left === 0 ? (
                            <span className="lw-seat-info full">FULL</span>
                          ) : workShopItem.seats_left > 0 && workShopItem.seats_left < 10 ? (
                            <span className="lw-seat-info">
                              {workShopItem.seats_left} seats left
                            </span>
                          ) : null}
                          {/* {workShopItem.seats_left > 0 ? (
														<span className='lw-seat-info'>{workShopItem.seats_left} seats left</span>
													) : (
														<span className='lw-seat-info full'>{'FULL'}</span>
													)} */}

                          <button
                            onClick={() => {
                              if (workShopItem.seats_left > 0) {
                                let path = `/workshops/${  workShopItem.slug}`;

                                if (this.preview) {
                                  path = `${path}?preview=${true}`;
                                }

                                this.props.router.navigate(path, {
                                  state: { workShopItem }
                                });
                              }
                            }}
                            className="sw-btna sw-main-btn"
                          >
                            {workShopItem.seats_left > 0
                              ? 'View Details'
                              : false
                                ? 'INTERESTED'
                                : 'Show interest'}
                          </button>
                        </div>
                      </div>
                      <span className="mbshow slw-btm-time">
                        <img
                          alt="time"
                          src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                        />
                        {workShopItem.total_hours} hours
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* for mobile view */}
              <div className="mb-lp-price-offer">
                <div className="lp-po-text-up">
                  <div className="w-lp-text-btn-mb">
                    {/* {workShopItem.seats_left > 0 ? (
											<span className='lw-seat-info'>{workShopItem.seats_left} seats left</span>
										) : (
											<span className='lw-seat-info full'>{'FULL'}</span>
										)} */}
                    {workShopItem.seats_left === 0 ? (
                      <span className="lw-seat-info full">FULL</span>
                    ) : workShopItem.seats_left > 0 && workShopItem.seats_left < 10 ? (
                      <span className="lw-seat-info">{workShopItem.seats_left} seats left</span>
                    ) : null}
                    <button
                      onClick={() => {
                        if (workShopItem.seats_left > 0) {
                          let path = `/workshops/${  workShopItem.slug}`;

                          if (this.preview) {
                            path = `${path}?preview=${true}`;
                          }

                          this.props.router.navigate(path, {
                            state: { workShopItem }
                          });
                        }
                      }}
                      className="sw-btna sw-main-btn"
                    >
                      {workShopItem.seats_left > 0 ? 'View Details' : 'Show interest'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );

  render() {
    if (this.state.loading) {
      return <LoadingScreen />;
    }
    return (
      <div
        className={`w-list-page ${window.ReactNativeWebView === undefined ? '' : 'wk_native_view'}`}
      >
        {/* {window.ReactNativeWebView === undefined && <NewHead />} */}
        {this.state.myWorkshops.length > 0 && this.renderMyWorkshops()}
        {this.state.workshops.length > 0 && this.renderWorkshops()}
        {this.state.confirm_loading && (
          <div className="sw-sum-loading-outer">
            <Spinner />
          </div>
        )}
        {this.state.showInterestPop && (
          <SimplePop
            close_simple_pop={this.closeInterestPop}
            sParaText="Thank you for showing your interest for this workshop. We will reach out to you when this is offered again."
            close_bd_btn_txt="Okay"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    teacher_details: state.profile.teacher_details,
    mobile_home_var: state.home.mobile_home_var,
    getAllWorkshopStatus: state.home.getAllWorkshopStatus,
    getAllWorkshopsResponse: state.home.getAllWorkshopsResponse
  });

const mapDispatchToProps = (dispatch) => ({
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    },
    get_all_workshops: (payload) => {
      dispatch(actions.get_all_workshops(payload));
    },
    get_all_workshops_wo_token: (payload) => {
      dispatch(actions.get_all_workshops_wo_token(payload));
    }
  });

const WorkshopListingPageWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkshopListingPage);
const WorkshopListingPageWithRouter = withRouter(WorkshopListingPageWithRedux);

export default WorkshopListingPageWithRouter;
