import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../custom_button';

import './custom_alert.css';

const CustomAlert = props => {
	return (
		<div className="custom-popup">
			<div className="custom-popup-blur">
				<div className="custom-popup-container">
					{typeof props.header !== 'undefined' ? <div className="custom-popup-header">Header</div> : null}
					<div className="custom-popup-body">{props.bodyText}</div>
					<div className="custom-popup-buttons">
						<div className="custom-popup-button col6">
							<CustomButton label="Cancel" component="secondary" onClick={props.cancelFunc} />
						</div>
						<div className="custom-popup-button col6">
							<CustomButton label="Confirm" onClick={props.confirmFunc} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

CustomAlert.propTypes = {
	bodyText: PropTypes.string,
	confirmFunc: PropTypes.func,
	cancelFunc: PropTypes.func,
	status: PropTypes.string
};

CustomAlert.defaultProps = {
	bodyText: 'Enter body text',
	confirmFunc: () => null,
	cancelFunc: () => null,
	status: 'none'
};

export default CustomAlert;
