import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';

import AuthLayer from './auth_layer';
import TeacherHome from './components/teacher/home';
import Notifications from './components/teacher/notifications';
import Welcome from './components/other/welcome';
import ResetPassword from './components/other/reset_password';
import ClearEmail from './components/other/clear_email';
import PageNotFound from './components/other/page_not_found';
import FAQ from './components/other/faq';
import HomePage from './components/home_page';

import StuRoadmap from './components/teacher/roadmap';
import RequestReplacement from './components/teacher/request_replacement';

import EditRoadMap from './components/teacher/roadmap/edit_roadmap';
import AddRoadMap from './components/teacher/yoga_journey/add_roadmap';
import StudentRoadmap from './components/teacher/student_roadmap';
import Singup from './components/teacher/signup';

import Students from './components/other/students';
import StudentProfile from './components/teacher/student_profile';
import YogaJourney from './components/teacher/yoga_journey_1';
import Profile from './components/teacher/profile';
import MySessionNew from './components/teacher/mysession_new';
import Recap from './components/teacher/session_recap';
import Announcements from './components/teacher/announcements';
import SingleWorkshopDetail from './components/teacher/workshop/single_workshop_detail';
import WorkshopListingPage from './components/teacher/workshop/wlisting_page';
import ClassRequests from './components/util_components/ClassRequests';
import Login from './components/new_teacher_wesite/modules/login';
import SignUp from './components/new_teacher_wesite/modules/signup';
import Reset from './components/new_teacher_wesite/modules/reset-password';
import PasswordConfirmation from './components/new_teacher_wesite/modules/password-confirmation';
import TeacherHomePage from './components/new_teacher_wesite/modules/home';
import Career from './components/new_teacher_wesite/modules/career';
import CareerPost from './components/new_teacher_wesite/modules/career-post';
// import SuggestAClass from "./components/new_teacher_wesite/modules/suggestaclass";
import TwilioVoice from './components/TwilioVoice';
import MySessionNewV1 from './components/new_teacher_wesite/modules/my-sessions-v1/my-sessions';
import ContactUs from './components/ContactUs/contactus';
import FollowUpConsult from './new_teacher_design/BackPainTherapy/FollowUpConsult';
import TeacherSlots from './components/teacher-availability/teacher-slots';
// import Availability from './components/teacher/availability';

// new teacher design
import ClassDetails from './new_teacher_design/container/ClassDetails';
import LivekitWrapper from './components/teacher/livekit_conferencing/LivekitWrapper';
import StudentProfileBP from './new_teacher_design/BackPainTherapy/StudentProfileBP';
import TherapySessionBooking from './components/TherapySessionBooking';
import CommunicationPreferences from './components/CommunicationPreferences';
import PhoneCallReminder from './components/phoneCallReminder';

function App() {
  return (
    <div className="main-app">
      <Notifications />
      <RequestReplacement />
      <BrowserRouter>
        <AuthLayer>
          {!window.location.hostname.includes('local') && <TwilioVoice />}
          <ClassRequests />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route exact path="/" element={<TeacherHomePage />} />
            <Route exact path="/class-details/:session_uuid" element={<ClassDetails />} />
            <Route path="/reset-password" element={<Reset />} />
            <Route path="/password-confirmation" element={<PasswordConfirmation />} />
            <Route path="/backpain-client-profile" element={<StudentProfileBP />} />
            <Route exact path="/book-consult" element={<FollowUpConsult />} />
            <Route path="/career" element={<Career />} />
            <Route path="/career-post" element={<CareerPost />} />
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/signup" element={<Singup />} />
            <Route exact path="/faq" element={<FAQ />} />
            <Route exact path="/student_roadmap" element={<StudentRoadmap />} />
            <Route exact path="/welcome" element={<Welcome />} />
            <Route exact path="/home" element={<TeacherHome />} />
            <Route path="/dashboard" element={<Navigate to="/home" />} />
            <Route exact path="/communication_preferences" element={<CommunicationPreferences />} />
            <Route exact path="/phone-call-reminder" element={<PhoneCallReminder />} />

            <Route exact path="/reset_password" element={<ResetPassword />} />
            <Route exact path="/clear_email" element={<ClearEmail />} />
            <Route exact path="/students" element={<Students />} />
            <Route exact path="/yoga-journey/:studentSlug" element={<YogaJourney />} />
            <Route exact path="/my-session" element={<MySessionNew />} />

            <Route exact path="/recap" element={<Recap />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/availability" element={<TeacherSlots />} />

            <Route exact path="/announcements" element={<Announcements />} />
            <Route exact path="/edit-road-map" element={<EditRoadMap />} />
            <Route exact path="/add-road-map/:studentSlug" element={<AddRoadMap />} />
            <Route exact path="/student-profile/:studentSlug" element={<StudentProfile />} />
            <Route path="/road-map/:studentSlug" element={<StuRoadmap />} />
            <Route path="/road-map" element={<StuRoadmap />} />
            <Route exact path="/workshops" element={<WorkshopListingPage />} />
            <Route exact path="/workshops/:id" element={<SingleWorkshopDetail />} />
            <Route exact path="/mysessions" element={<MySessionNewV1 />} />
            <Route exact path="/book-coaching" element={<TherapySessionBooking />} />
            <Route exact path="/community" element={<Navigate to="/chat" replace />} />
            <Route exact path="/contactus" element={<ContactUs />} />
            <Route exact path="/sessions/livekit" element={<LivekitWrapper />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AuthLayer>
      </BrowserRouter>
    </div>
  );
}

export default App;
