import * as t from '../action_types';

export const set_home_variable = (key, payload) => {
  return {
    type: t.set_home_variable,
    payload: {
      key,
      payload,
    },
  };
};

export const getTeacherScheduleList = payload => {
  return {
    type: t.getTeacherList,
    payload,
  };
};

export const handle_error_from_api = (payload) => {
  return {
    type: t.handle_error_from_api,
    payload,
  };
};

export const getTeacherTimeSlots = (payload) => {
  return {
    type: t.get_teacher_time_slots,
    payload,
  };
};

export const get_notifications = payload => {
  return {
    type: t.get_notifications,
    payload,
  };
};

export const update_notification_read_status = payload => {
  return {
    type: t.update_notification_read_status,
    payload,
  };
};

export const update_session_time_duration = payload => {
  return {
    type: t.update_session_time_duration,
    payload,
  };
};

export const cancel_sessions = payload => {
  return {
    type: t.cancel_sessions,
    payload,
  };
};

export const replacement_session = payload => {
  return {
    type: t.replacement_session,
    payload,
  };
};

export const get_subscription_data = payload => {
  return {
    type: t.get_subscription_data,
    payload,
  };
};