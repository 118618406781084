import React from 'react';

const CancelSession = (props) => {
  return (
    <>
      <div className='relative inset-0 z-index-max'>
        <div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
          <div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
            <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
              <div>
                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect width='34' height='34' rx='17' fill='none' />
                  <path
                    d='M22 12L12 22'
                    stroke='#BFBFBF'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12 12L22 22'
                    stroke='#BFBFBF'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
            <div className='mb-0'>
              <div className='flex justify-start flex-col items-start'>
                <div className='w-full'>
                  <div className="flex justify-center items-center flex-col">
                    <div className="text-17 font-semibold text-mytRedText2 mb-3">WARNING!</div>
                    <div className="flex justify-start items-center">
                      <div className="text-17 font-semibold">You have cancelled 7 sessions this month</div>
                    </div>
                    <div className="flex justify-center items-center mt-2 text-center">Cancelling too many sessions is disruptive to students and will negatively impact your payout</div>
                  </div>
                  <div className="flex justify-center items-center mt-5">
                    {/* <button className="btn btn-danger w-full" onClick={props.continueCancellationWarningPopup()}>Continue with cancellation?</button> */}
                    <button className="btn btn-danger w-full" >Continue with cancellation?</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelSession;
