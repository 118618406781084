import React from 'react'

const UploadItem = (props) => {
  const { file, progress } = props.file
  return (
    <div className='bg-mytGrayLightV3 rounded-lg border flex items-center' style={{ width: '175px' }}>
      <div className="p-1 place-items-center grid w-full h-fit">
        <div className="mb-10px text-sm font-medium text-green-700">{progress}% Complete</div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
          <div className="bg-green-600 h-2.5 rounded-full dark:bg-green-500" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  )
}

export default UploadItem