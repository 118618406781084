import React from 'react';
import { CrossIcon } from '../svg';

function Modal(props) {
	return (
		<div className={`${props.mainclass ? props.mainclass : ''} relative inset-0 modalindex`}>
			<div
				className={`${
					props.classNames ? props.classNames : ''
				} fixed h-screen w-screen bg-mytHeaderBg inset-0 z-10 flex justify-center md:items-center zm:items-end`}
				onClick={props.onClose}
			>
				<div
					className={`${
						props.subdivclass ? props.subdivclass : ''
					} md:w-492px zm:w-full overflow-y-scroll scroll-container`}
					style={props.customStyle}
				>
					<div
						className={`${
							props.childrendiv ? props.childrendiv : ''
						} relative z-50 bg-white zm:rounded-tl-3xl zm:rounded-tr-3xl md:rounded-10px flex flex-col overflow-y-auto`}
						style={{
							maxHeight: props.maxHeight ? props.maxHeight : '',
							minHeight: 100,
						}}
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<CrossIconSvg onClick={props.onClose} />
						{props.children}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Modal;

export const CrossIconSvg = (props) => {
	return (
		<div className='self-end pt-3 pr-3 cursor-pointer' {...props}>
			<CrossIcon />
		</div>
	);
};
