import React from 'react';
import { analytics_track, get_time_taken } from '../../../util_functions';
import './home-header-menu.css';

const open_zendesk = () => {
	window.zE(() => {
		window.zE.showIPMWidget();
	});
};

const track_event = (name, start_time) => {
	const options = {
		referrer_component: 'header',
		plan: 'guest',
		time_since_page_load: get_time_taken(start_time)
	};
	analytics_track(`${name} link click`, options);
};
const Header = props => {
	return (
		<div className="home_header_menu">
			<div className="container">
				<div className="home_header_right">
					<ul className="header-nav-right">
						<li>
							<a href="https://myyogateacher.com" onClick={() => track_event('home page', props.start_time)}>
								Home
							</a>
							<a
								href="https://myyogateacher.com/contactus"
								onClick={() => track_event('contact', props.start_time)}
							>
								Contact
							</a>
							<a
								onClick={() => {
									open_zendesk();
									track_event('help chat', props.start_time);
								}}
							>
								<img className="icon" src="https://images.myyogateacher.com/home-page/h_chat1.png" alt="chat_icon" />
								<span>Live Chat</span>
							</a>
							<a href="tel:+91 94141 14756" onClick={() => track_event('phone', props.start_time)}>
								<img className="iconphone" src="https://images.myyogateacher.com/home-page/h_call1.png" alt="call_icon" />
								<span>+91 94141 14756</span>
							</a>
							<a
								href="https://www.facebook.com/myyogateacher.inc"
								target="_blank"
								rel="noopener noreferrer"
								onClick={() => track_event('fb icon', props.start_time)}
							>
								<img src="https://images.myyogateacher.com/home-page/L-FB1.png" alt="fb" />
							</a>
							<a
								href="https://twitter.com/myyogateacher"
								target="_blank"
								rel="noopener noreferrer"
								onClick={() => track_event('twitter icon', props.start_time)}
							>
								<img className="twiiter-icon" src="https://images.myyogateacher.com/home-page/ic_twitter3.png" alt="twitter_icon" />
							</a>
							<a
								href="https://www.instagram.com/myyogateacher"
								target="_blank"
								rel="noopener noreferrer"
								onClick={() => track_event('instagram icon', props.start_time)}
							>
								<img src="https://images.myyogateacher.com/home-page/L-INSTA1.png" alt="insta" />
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
export default Header;
