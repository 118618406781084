import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import * as t from '../action_types';
import moment from 'moment-timezone';
import { toast as NotificationManager } from 'react-toastify';

// import * as selectors from "../selectors";
import * as actions from '../action_creators';
import { post_api } from '../api_funcs';
import urls from '../../urls';
import { get_api, put_api } from '../../new_teacher_design/utils/config';

export function* getTeacherDetails() {
	try {
		yield put(actions.set_home_variable('profile_loading_status', 'loading'));
		const url = urls.teacher_details;
		const payload = {
			iana_timezone: moment.tz.guess(true),
			timezone: new Date().getTimezoneOffset(),
		};

		const result = yield call(post_api, url, payload, true);

		yield put(actions.set_profile_variable('teacher_details', result.data.details));
		let isConsultantTherapist = result?.data?.details?.is_consultant_therapist || 0;
		localStorage.removeItem('is_consultant_therapist');
		localStorage.setItem('is_consultant_therapist', isConsultantTherapist);
		yield put(actions.set_home_variable('profile_loading_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		console.log(error);
		yield put(actions.set_home_variable('profile_loading_status', 'error'));
	}
}

export function* updateProfileBasic(payload) {
	try {
		yield put(actions.set_home_variable('profile_update_status', 'loading'));
		const update_url = urls.update_profile_basic;
		yield call(post_api, update_url, payload, true);
		yield put(actions.get_teacher_details());
		yield put(actions.set_home_variable('profile_update_status', 'success'));
	} catch (e) {
		try {
			if (e.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (err) {}
		console.log('update basic error');
		let err_message = 'Update failed. Please contact care team';
		try {
			err_message = e.response.data.message;
		} catch {}
		yield put(actions.set_home_variable('profile_update_status', 'error'));
		NotificationManager.error(err_message);
	}
}

export function* updateProfileYoga(payload) {
	try {
		yield put(actions.set_home_variable('profile_update_status', 'loading'));
		const update_url = urls.update_profile_yoga;
		yield call(post_api, update_url, payload, true);
		yield put(actions.get_teacher_details());
		yield put(actions.set_home_variable('profile_update_status', 'success'));
	} catch (e) {
		try {
			if (e.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (err) {}
		console.log('update yoga error');
		let err_message = 'Update failed. Please contact care team';
		try {
			err_message = e.response.data.message;
		} catch {}
		yield put(actions.set_home_variable('profile_update_status', 'error'));

		NotificationManager.error(err_message);
	}
}

export function* updateProfileSocial(payload) {
	try {
		const update_url = urls.update_profile_social;
		yield call(post_api, update_url, payload, true);
		yield put(actions.get_teacher_details());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		console.log('update social error');
	}
}

export function* updateProfileOther(payload) {
	try {
		yield put(actions.set_home_variable('profile_update_status', 'loading'));
		const update_url = urls.update_profile_other;
		yield call(post_api, update_url, payload, true);
		yield put(actions.get_teacher_details());
		yield put(actions.set_home_variable('profile_update_status', 'success'));
	} catch (e) {
		try {
			if (e.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (err) {}
		console.log('update other error');
		let err_message = 'Update failed. Please contact care team';
		try {
			err_message = e.response.data.message;
		} catch {}
		yield put(actions.set_home_variable('profile_update_status', 'error'));
		NotificationManager.error(err_message);
	}
}

export function* updateProfileApp(payload) {
	try {
		const update_url = urls.update_app_details;
		yield call(post_api, update_url, payload, true);
		yield put(actions.get_teacher_details());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		console.log('update other error');
	}
}

export function* updateProfileDocs(payload) {
	try {
		const idFormData = new FormData();
		const idUrl = urls.update_id_proof;
		idFormData.append('id_proof_type', payload.id_proof_document);
		idFormData.append('id_proof', payload.id_proof_document_url);
		yield call(post_api, idUrl, idFormData, true, 'formdata');

		const addressFormDate = new FormData();
		const addressUrl = urls.update_address_proof;
		addressFormDate.append('address_proof_type', payload.address_proof_document);
		addressFormDate.append('address_proof', payload.address_proof_document_url);
		yield call(post_api, addressUrl, addressFormDate, true, 'formdata');
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		console.log('update other error');
	}
}

export function* updateProfileDetails(action) {
	switch (action.payload.component_name) {
		case 'basic':
			yield call(updateProfileBasic, action.payload.data);
			break;
		case 'yoga':
			yield call(updateProfileYoga, action.payload.data);
			break;
		case 'social':
			yield call(updateProfileSocial, action.payload.data);
			break;
		case 'other':
			yield call(updateProfileOther, action.payload.data);
			break;
		case 'docs':
			yield call(updateProfileDocs, action.payload.data);
			break;
		case 'app':
			yield call(updateProfileApp, action.payload.data);
			break;
		default:
			break;
	}
}

export function* updateProfileFiles(action) {
	try {
		const form_data = new FormData();
		const url = urls[action.payload.file_component];
		form_data.append(action.payload.key, action.payload.payload);
		yield call(post_api, url, form_data, true, 'formdata');
		yield put(actions.get_teacher_details());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		console.log('file updload error');
	}
}


export function* getTeacherNotificationPreferences(action) {
    
    try {
        const url = urls.get_teacher_notification_preferences;
        var payload = { ...action.payload };
        yield put(actions.set_home_variable('get_teacher_notification_preferences_status', 'loading'));
        const response = yield call(get_api, url, payload, true);
        yield put(actions.set_home_variable('get_teacher_notification_preferences_response', response.data.data));
        yield put(actions.set_home_variable('get_teacher_notification_preferences_status', 'success'));
    } catch (error) {
        const err_payload = {
            error,
            type: action.type,
        };
        yield put(actions.set_home_variable('get_teacher_notification_preferences_status', 'fail'));
        yield put(actions.handle_error_from_api(err_payload));
    }
}

export function* updateTeacherNotificationPreferences(action) {
	try {
		const url = urls.get_teacher_notification_preferences;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('update_teacher_notification_preferences_status', 'loading'));
		const response = yield call(put_api, url, payload, true);
		yield put(actions.set_home_variable('update_teacher_notification_preferences_status', 'success'));
	} catch (error) {
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('update_teacher_notification_preferences_status', 'fail'));
		yield put(actions.handle_error_from_api(err_payload));
	}
}

export default function* availabilitySaga() {
	yield all([
		takeLatest(t.get_teacher_details, getTeacherDetails),
		takeEvery(t.update_profile_details, updateProfileDetails),
		takeEvery(t.update_profile_files, updateProfileFiles),
		takeEvery(t.get_teacher_notification_preferences, getTeacherNotificationPreferences),
		takeEvery(t.update_teacher_notification_preferences, updateTeacherNotificationPreferences),
	]);
}
