import React, { Component } from 'react';
import moment from 'moment';

import CustomInput from '../../util_components/custom_input';
import CustomDropDown from '../../util_components/custom_dropdown';
import CustomDatePicker from '../../util_components/custom_date_picker';
import CustomFileUpload from '../../util_components/custom_file_upload';
import CustomRadioButton from '../../util_components/custom_radio_button';
import { covert_to_pascal_with_spaces } from '../../../util_functions';
import { yoga_teaching_list } from '../../../constants';

export const get_compartment_names = (index) => {
	switch (index) {
		case 0:
			return 'basic';
		case 1:
			return 'yoga';
		// case 2:
		//   return "app";
		// case 3:
		//   return "social";
		// case 3:
		//   return "docs";
		// case 4:
		//   return "bank";
		case 2:
			return 'other';
		default:
			return 'basic';
	}
};

export const render_profile_component_all_details = (details) => {
	return Object.keys(details).map((key, index) => {
		return (
			<div className='profile-basic-detail-values' key={key + index}>
				<div className='profile-basic-detail-index'>{covert_to_pascal_with_spaces(key)}</div>
				<div className='profile-basic-detail-value'>
					{details[key] === '' || typeof details[key] === 'undefined'
						? '- -'
						: covert_to_pascal_with_spaces(details[key])}
				</div>
			</div>
		);
	});
};

export class RenderSocialEdit extends Component {
	render() {
		return (
			<div className='profile-basic-edit-details'>
				<div className='profile-details-edit-text'>
					{Object.keys(this.props.details).map((key, index) => {
						return (
							<div className='profile-basic-edit-custominput' key={key + index}>
								<CustomInput
									label={covert_to_pascal_with_spaces(key)}
									value={this.props.details[key]}
									width={`680px`}
									handleChange={(e) => this.props.handleChange(e.target.value, 'social_details', key)}
								/>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export class RenderAppEdit extends Component {
	render() {
		return (
			<div className='profile-basic-edit-details'>
				<div className='profile-details-edit-text'>
					<div className='profile-basic-edit-custominput'>
						<div className='profile-basic-dropdown-label'>Yoga types you can teach:</div>
						<CustomDropDown
							value={this.props.details.yoga_types_teaching}
							handleChange={(e) => this.props.handleChange(e, 'app_details', 'yoga_types_teaching')}
							multi_select={true}
							drop_down_list={yoga_teaching_list}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export class RenderBasicEdit extends Component {
	render() {
		return (
			<div className='profile-basic-edit-details'>
				<div className='profile-basic-address-line'>
					<div className='profile-basic-address-line-text'>Your personal details</div>
				</div>
				<div className='profile-basic-edit-personal'>
					<div className='profile-basic-edit-text'>
						<div className='profile-basic-edit-custominput'>
							<CustomInput
								label='First Name*'
								value={this.props.profile.first_name}
								handleChange={(e) =>
									this.props.handleChange(e.target.value, 'basic_details', 'first_name')
								}
							/>
						</div>
						<div className='profile-basic-edit-custominput'>
							<CustomInput
								label='Last Name*'
								value={this.props.profile.last_name}
								handleChange={(e) =>
									this.props.handleChange(e.target.value, 'basic_details', 'last_name')
								}
							/>
						</div>
						<div className='profile-basic-edit-custominput'>
							<div className='profile-basic-dropdown-label'>Gender*</div>
							<div className='profile-basic-radio-buttons'>
								<div className='profile-basic-radio-button'>
									<CustomRadioButton
										label='Male'
										onRadioClick={() => this.props.handleChange('Male', 'basic_details', 'gender')}
										selected={this.props.profile.gender.toLowerCase() === 'male'}
									/>
								</div>
								<div className='profile-basic-radio-button'>
									<CustomRadioButton
										label='Female'
										onRadioClick={() =>
											this.props.handleChange('Female', 'basic_details', 'gender')
										}
										selected={this.props.profile.gender.toLowerCase() === 'female'}
									/>
								</div>
							</div>
						</div>
						<div className='profile-basic-edit-custominput'>
							<CustomInput
								label='Phone Number*'
								value={this.props.profile.phone_personal}
								type={'phone'}
								handleChange={(e) =>
									this.props.handleChange(e.target.value, 'basic_details', 'phone_personal')
								}
							/>
						</div>
						<div className='profile-basic-edit-custominput'>
							<div className='profile-basic-dropdown-label'>Marital Status*</div>
							<div className='profile-basic-radio-buttons'>
								<div className='profile-basic-radio-button'>
									<CustomRadioButton
										label='Married'
										onRadioClick={() =>
											this.props.handleChange('Married', 'basic_details', 'marital_status')
										}
										selected={this.props?.profile.marital_status?.toLowerCase() === 'married'}
									/>
								</div>
								<div className='profile-basic-radio-button'>
									<CustomRadioButton
										label='Unmarried'
										onRadioClick={() =>
											this.props.handleChange('Unmarried', 'basic_details', 'marital_status')
										}
										selected={this.props?.profile.marital_status?.toLowerCase() === 'unmarried'}
									/>
								</div>
							</div>
						</div>
						<div className='profile-basic-edit-custominput'>
							<div className='profile-basic-dropdown-label'>Date Of Birth</div>
							<div className='date-of-birth-calender'>
								<CustomDatePicker
									startDate={
										this.props.profile?.date_of_birth === '' ||
										this.props.profile?.date_of_birth === null
											? new Date()
											: moment(this.props.profile?.date_of_birth, 'YYYY-MM-DD')?.toDate()
									}
									handleChange={(e) =>
										this.props.handleChange(
											moment(e)?.format('YYYY-MM-DD'),
											'basic_details',
											'date_of_birth'
										)
									}
									component='profile'
								/>
							</div>
						</div>
					</div>
					<div className='profile-basic-edit-image'>
						<CustomFileUpload
							type='image'
							label={this.props.profile_photo === '' ? 'Set profile picture' : 'Change profile picture'}
							image_url={this.props.profile_photo}
							handleFileChange={(val) =>
								this.props.handleFileChange('profile_photo', 'update_photo', val)
							}
						/>
					</div>
				</div>
				<div className='profile-basic-address-line'>
					<div>Address</div>
					<div className='simple-square' />
					<hr />
				</div>
				<div className='profile-basic-address-edit'>
					<div className='profile-basic-edit-custominput'>
						<CustomInput
							label='Street*'
							value={this.props.profile.street}
							handleChange={(e) => this.props.handleChange(e.target.value, 'basic_details', 'street')}
						/>
					</div>
					<div className='profile-basic-edit-custominput'>
						<CustomInput
							label='City*'
							value={this.props.profile.city}
							handleChange={(e) => this.props.handleChange(e.target.value, 'basic_details', 'city')}
						/>
					</div>
					<div className='profile-basic-edit-custominput'>
						{/* <div className="profile-basic-dropdown-label">State</div> */}
						<CustomInput
							label='state*'
							value={this.props.profile.state}
							handleChange={(e) => this.props.handleChange(e.target.value, 'basic_details', 'state')}
						/>
						{/* <CustomDropDown
							value={this.props.profile.state}
							handleChange={e => this.props.handleChange(e, 'basic_details', 'state')}
							drop_down_list={indian_states}
						/> */}
					</div>
					<div className='profile-basic-edit-custominput'>
						<CustomInput
							label='Zipcode*'
							value={this.props.profile.zipcode}
							handleChange={(e) => this.props.handleChange(e.target.value, 'basic_details', 'zipcode')}
						/>
					</div>
				</div>
			</div>
		);
	}
}
