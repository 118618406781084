import axios from 'axios';
import { eventChannel, END } from 'redux-saga';
import { isMobile, isTablet } from 'react-device-detect';
import { getCookie } from '../util_functions';
import urls from '../new_teacher_design/urls/urls';

export const get_api = (url, token = false) => {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${  getCookie('teacher_token_1')}`;
  }
  return axios({
    method: 'get',
    url,
    headers
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};

export const post_api = (
  url,
  payload,
  token = false,
  type = 'none',
  onUploadProgress,
  apiToken
) => {
  const headers = {};

  if (apiToken) {
    headers.Authorization = `Bearer ${apiToken}`;
  } else if (token) {
    headers.Authorization = `Bearer ${  getCookie('teacher_token_1')}`;
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }
  return axios({
    method: 'post',
    url,
    headers,
    data: payload,
    onUploadProgress
  });
};

export const delete_api = (url, token = false, payload, type = 'none') => {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${  getCookie('teacher_token_1')}`;
  }
  const options = {
    method: 'delete',
    url,
    ...(payload && { data: payload }),
    headers
  };
  return axios(options)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};

export const put_api = (url, payload, token = false, type = 'none') => {
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${  getCookie('teacher_token_1')}`;
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }
  return axios({
    method: 'put',
    url,
    headers,
    data: payload
  })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};

const upload_file_api = (url, payload, onProgress) => {
  const headers = {};
  headers.Authorization = `Bearer ${  getCookie('teacher_token_1')}`;
  headers['content-type'] = 'multipart/form-data';
  if (payload.files.length) {
    const formData = new FormData();
    formData.append('session_uuid', payload.session_uuid);
    for (const file in payload.files) {
      formData.append('files', payload.files[file].file);
    }

    const config = {
      headers,
      onUploadProgress: onProgress
    };

    return axios
      .post(url, formData, config)
      .then((response) => {
        response.data.id = payload.files[0].id;
        return Promise.resolve(response);
      })
      .catch((error) => Promise.reject(error));
  }
};

export const createUploader = (url, payload, token = false) => {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadPromise = upload_file_api(url, payload, (event) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    delete payload.session_uuid;
    payload.files[0].progress = percentCompleted;
    if (percentCompleted == 100) emit(END);
    else emit(payload.files);
  });
  return [uploadPromise, chan];
};

export const track_user_device = async (session_uuid, action) => {
  const url = urls.track_device;

  const payload = {
    session_uuid,
    action,
    device_type: isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop',
    app_platform: 'web_app',
    app_major_version: 'v1',
    app_minor_version: 'v2',
    is_student: false,
    is_teacher: true,
    is_cms_user: false,
    cms_user_uuid: ''
  };

  const res = await post_api(url, payload, true);
  return res;
};
