import React, { useEffect, useRef, useState } from 'react'
import './Carousel.css';
import {
    LeftCircleArrowIcon,
    RightCircleArrowIcon,
    RightCircleArrowFillIcon,
    LeftCircleArrowFillIcon,
} from '../../assets/svg/popup_icons';
import { isSafari } from 'react-device-detect';

const Carousel = (props) => {
    const [isRightClicked, setIsRightClicked] = useState(false)
    const [isLeftClicked, setIsLeftClicked] = useState(false)
    const [currentImage, setCurrentImage] = React.useState(props.selectedCarouselImage);
    const isFirstRender = useRef(true)

    useEffect(() => {
        if (isFirstRender?.current) {
            isFirstRender.current = false; // toggle flag after first render/mounting
            scrollToImage(props.selectedCarouselImage);
            return;
        }
    });

    const refs = props.files.reduce((acc, val, i) => {
        acc[i] = React.createRef();
        return acc;
    }, {});

    const scrollToImage = (i) => {
        setCurrentImage(i);

        isSafari ?
            refs[i]?.current.scrollIntoView(true)
            :
            refs[i].current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
    };

    const totalImages = props.files.length;

    const nextImage = () => {
        setIsRightClicked(true)
        if (currentImage >= totalImages - 1) {
            scrollToImage(0);
            props.changeCarouselIndex(0);
        } else {
            scrollToImage(currentImage + 1);
            props.changeCarouselIndex(currentImage + 1);
        }
    };

    const previousImage = () => {
        setIsLeftClicked(true)
        if (currentImage === 0) {
            scrollToImage(totalImages - 1);
            props.changeCarouselIndex(totalImages - 1);
        } else {
            scrollToImage(currentImage - 1);
            props.changeCarouselIndex(currentImage - 1);
        }
    }; 17

    const arrowStyle =
        'bg-transparent focus:outline-none font-semibold hover:text-white hover:border-transparent absolute text-white text-2xl z-10 h-10 w-10 rounded-full opacity-75 flex items-center justify-center';

    const sliderControl = (isLeft: boolean) => {
        return (
            <button
                onMouseLeave={() => { setIsRightClicked(false), setIsLeftClicked(false) }}
                onMouseOver={() => { isLeft ? setIsLeftClicked(true) : setIsRightClicked(true) }}
                onClick={isLeft ? previousImage : nextImage}
                className={`${arrowStyle} ${isLeft ? 'zm:left-40px md:left-70px xl:left-100px' : 'zm:right-40px md:right-70px xl:right-100px'}`}
                style={{ top: '45%' }}
            >
                <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
                    {isLeft ? isLeftClicked ? <LeftCircleArrowFillIcon /> : <LeftCircleArrowIcon /> : isRightClicked ? <RightCircleArrowFillIcon /> : <RightCircleArrowIcon />}
                </span>
            </button>
        )
    };

    return (
        <div className="flex justify-center w-full md:w-5/6 items-center">
            <div className="w-full h-full">
                <div className="carousel h-full">
                    {sliderControl(true)}
                    {props.files.map((img, i) => {
                        return <div className="w-full flex-shrink-0 flex justify-center items-center rounded-lg bg-mytgrey-carouselTotalBgColor relative" key={img.uuid} ref={refs[i]}>
                            {img.ai_captured && <div className='absolute right-10px top-10px py-3px px-4px bg-black text-white rounded-4px text-10px font-bold'>AI Captured</div>}
                            <img src={img.signed_url} className="object-contain h-full rounded-10px" />
                        </div>
                    })}
                    {sliderControl(false)}
                </div>
            </div>
        </div>
    );
}

export default Carousel