import moment from 'moment-timezone';

import Cookies from 'js-cookie';
import { isUserLoggedIn } from './new_teacher_design/utils/general_funcs';

export const get_start_end_date_time = () => {
  const d = moment();
  if (d.minute() < 25) {
    d.minute(30).seconds(0);
  } else {
    d.add(1, 'h').minute(0).second(0);
  }
  const start_time = d.clone();
  const min_time = d.clone();
  d.add(60, 'm');
  const end_time = d.clone().add(2, 'M');
  const end_date = moment().add(2, 'M');
  return {
    start_time,
    end_time,
    end_date,
    min_time
  };
};

export const covert_to_pascal_with_spaces = (word) =>
  word
    ?.split('_')
    ?.map((item) => {
      const temp = item.split('');
      temp[0] = temp[0].toUpperCase();
      return temp.join('');
    })
    ?.join(' ');

export const getCookie = (cookie_name) => Cookies.get(cookie_name);

export const removeCookie = (cookie_name) => {
  Cookies.remove(cookie_name, { path: '/', domain: '.myyogateacher.com' });
};

export const setCookie = (cookie_name, value, age = 5184000, domain = '.myyogateacher.com') => {
  Cookies.set(cookie_name, value, {
    expires: age / 864e2,
    path: '/',
    domain,
    secure: true,
    sameSite: 'Strict'
  });
};

export const convert_to_pascal = (str) => {
  str = str?.toLowerCase()?.split(' ');
  for (let i = 0; i < str?.length; i++) {
    str[i] = str[i]?.charAt(0)?.toUpperCase() + str[i]?.slice(1);
  }
  return str?.join(' ');
};

export const chunk_array = (array, size) => {
  const chunked_arr = [];
  let index = 0;
  while (index < array?.length) {
    chunked_arr?.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};

export const get_num_with_ordinal = (num) => {
  switch (num) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export const getNumWithOrdinal = (num) => {
  switch (Math.floor(num / 10)) {
    case 0:
      switch (num) {
        case 1:
          return `${num}st`;
        case 2:
          return `${num}nd`;
        case 3:
          return `${num}rd`;
        default:
          return `${num}th`;
      }
    case 1:
      return `${num}th`;
    default:
      switch (num % 10) {
        case 1:
          return `${num}st`;
        case 2:
          return `${num}nd`;
        case 3:
          return `${num}rd`;
        default:
          return `${num}th`;
      }
  }
};

export const secondsToString = (seconds) => {
  const numdays = Math.floor(seconds / 86400);
  const numhours = Math.floor((seconds % 86400) / 3600);
  const numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  const numseconds = ((seconds % 86400) % 3600) % 60;
  if (numdays > 0) {
    if (numdays === 1) {
      return `about ${numdays} day`;
    }
    return `about ${numdays} days`;
  }
  if (numminutes > 9) {
    return `${numhours}:${numminutes}:${numseconds}`;
  }
  return `${numminutes}:${numseconds}`;
};

export const filterYogaGoals = (goals, all_goals) => {
  const new_goals = goals.filter((goal) => all_goals.indexOf(goal) > -1);
  return new_goals;
};

export const image_error_replace = (e) => {
  e.target.onerror = null;
  e.target.src = 'https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png';
};

export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const analytics_track = (name, attr) => {
  try {
    window.analytics.track(name, { ...attr });
    // Mixpanel.track(name, { ...attr });
  } catch {}
};

export const analytics_identify = (id, name, email, first_name) => {
  try {
    window.analytics.identify(id, {
      name,
      email,
      first_name
    });
  } catch (e) {}
};

export const analytics_alias = (id) => {
  try {
    window.analytics.flush();
    window.analytics.alias(id);
  } catch {}
};

export const analytics_reset = () => {
  try {
    window.analytics.reset();
  } catch {}
};

export const get_time_taken = (start_time) =>
  Math.floor((new Date().getTime() - start_time) / 1000);

export const error_console = (text) => console.log(text);

export const custom_console = (text) => console.log(text);

export const get_date_value = (time) => {
  let hrs = Math.floor(time / 3600);
  hrs = hrs < 10 ? `0${hrs}` : `${hrs}`;
  let mins = Math.floor((time % 3600) / 60);
  mins = mins < 10 ? `0${mins}` : `${mins}`;
  let secs = time % 60;
  secs = secs < 10 ? `0${secs}` : `${secs}`;
  return `${hrs}:${mins}:${secs}`;
};

export const check_profile_complete = (details) => {
  try {
    const f_name = details.first_name;
    const l_name = details.last_name;
    const { gender } = details;
    const p_number = details.phone_personal;
    const { goals } = details;
    const yoga_types = details.yoga_types_teaching;
    const { how_did_you_hear_about_us } = details;
    const { video_url } = details;
    const { intro_to_students } = details;

    let check_goals = false;
    let check_types = false;
    if (typeof goals === 'string') {
      check_goals = !!goals;
    } else if (typeof goals === 'object') {
      check_goals = goals?.length > 0;
    }
    if (typeof yoga_types === 'string') {
      check_types = !!yoga_types;
    } else if (typeof yoga_types === 'object') {
      check_types = yoga_types?.length > 0;
    }
    if (
      !!f_name &&
      !!l_name &&
      !!gender &&
      !!p_number &&
      !!how_did_you_hear_about_us &&
      !!video_url &&
      !!intro_to_students &&
      check_goals &&
      check_types
    ) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const isDevMode = () => import.meta.env.DEV;
export const is_production = () => import.meta.env.VITE_APP_STAGE === 'production';

export const sentenceCase = (input, lowercaseBefore) => {
  input = input === undefined || input === null ? '' : input;
  if (lowercaseBefore) {
    input = input.toLowerCase();
  }
  return input
    .toString()
    .replace(/(^|\. *)([a-z])/g, (match, separator, char) => separator + char.toUpperCase());
};

export const isSafari = () => {
  try {
    const isSafariBrowser = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return isSafariBrowser || iOS;
  } catch {
    return false;
  }
};

export const capitalizeFirstLetter = (str) =>
  str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
export const copyTextToClipboardinHttp = (text) => {
  const textArea = document.createElement('textarea');

  //
  // *** This styling is an extra step which is likely not required. ***
  //
  // Why is it here? To ensure:
  // 1. the element is able to have focus and selection.
  // 2. if the element was to flash render it has minimal visual impact.
  // 3. less flakyness with selection and copying which **might** occur if
  //    the textarea element is not visible.
  //
  // The likelihood is the element won't even render, not even a
  // flash, so some of these are just precautions. However in
  // Internet Explorer the element is visible whilst the popup
  // box asking the user for permission for the web page to
  // copy to the clipboard.
  //

  // Place in the top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of the white box if rendered for any reason.
  textArea.style.background = 'transparent';

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log(`Copying text command was ${msg}`);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
};

/**
 * Adjusts timestamp according to the supplied timezone
 * @param {*} timestamp - unix timestamp
 * @param {*} timezone - timezone the original needs to be converted to
 * @returns updated timestamp that is adjusted according to the timezone
 */
export const convertDateWithTimezone = (timestamp, timezone) => {
  const originalTimestamp = moment(timestamp);
  const timestampAccordingToTheTimezone = moment(timestamp).tz(timezone);
  const modifiedDate = moment(timestamp);
  modifiedDate.subtract(
    originalTimestamp.utcOffset() - timestampAccordingToTheTimezone.utcOffset(),
    'minutes'
  );
  return modifiedDate.valueOf();
};

export const numberToOrdinal = (number) => {
  if (number === 0) {
    return 'zeroth';
  }

  const ones = [
    'Zeroth',
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth',
    'Sixth',
    'Seventh',
    'Eighth',
    'Ninth'
  ];
  const teens = [
    'Tenth',
    'Eleventh',
    'Twelfth',
    'Thirteenth',
    'Fourteenth',
    'Fifteenth',
    'Sixteenth',
    'Seventeenth',
    'Eighteenth',
    'Nineteenth'
  ];
  const tens = [
    'Twentieth',
    'Thirtieth',
    'Fortieth',
    'Fiftieth',
    'Sixtieth',
    'Seventieth',
    'Eightieth',
    'Ninetieth'
  ];

  if (number < 10) {
    return ones[number];
  }
  if (number < 20) {
    return teens[number - 10];
  }
  if (number < 100) {
    let ordinal = tens[Math.floor(number / 10) - 2];
    if (number % 10 !== 0) {
      ordinal += `-${ones[number % 10]}`;
    }
    return ordinal;
  }
  return 'Number out of range';
};

export const sendEventToClarity = () => {
  if (!import.meta.env.SSR && window?.clarity && isUserLoggedIn()) {
    window.clarity('identify', getCookie('teacher_uuid'));
  }
  return null;
};
