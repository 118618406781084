// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import '../MytToolTip/ToolTip.css';
import 'react-tooltip/dist/react-tooltip.css';

const MytToolTip = ({ id, customClass, content, position }) => {
	return (
		<>
			<div className={customClass}>
				<ReactTooltip
                    anchorId={id}
					place={position}
					content={content}
					backgroundColor='#1e1229'
					whiteSpace='prewrap'
				/>
			</div>
		</>
	);
};

MytToolTip.propTypes = {
	id: PropTypes.any,
	customClass: PropTypes.any,
	content: PropTypes.any,
	position: PropTypes.any,
};

export default MytToolTip;
