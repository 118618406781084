import * as t from '../action_types';

// different status
// 1. none
// 2. inprogress
// 3. success
// 4. fail

var initialState = {
  show_notif: false,
  notif_type: '',
  notif_text: ''
};

const set_notification_variable = (state, payload) => {
  return {
    ...state,
    show_notif: payload.show_notif,
    notif_type: payload.notif_type,
    notif_text: payload.notif_text
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_notification_variable:
      return set_notification_variable(state, action.payload);
    default:
      return state;
  }
}
