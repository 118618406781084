import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { calendar_months_short } from '../../../constants';

import 'react-datepicker/dist/react-datepicker.css';
import './custom_date_picker.css';

export class CustomInput extends Component {
	render() {
		const month = this.props.value.split('/')[0];
		const day = this.props.value.split('/')[1];
		const year = this.props.value.split('/')[2];
		return (
			<button
				className="datepicker-button"
				onClick={this.props.onClick}
				style={{ height: this.props.title === 'profile' ? '35px' : '48px' }}
			>
				<img
					className="datepicker-button-add"
					src="https://images.myyogateacher.com/teacher_web_app/ic_add_availability.png"
					alt="add"
				/>
				<p>{`${day} ${calendar_months_short[month - 1]}, ${year}`}</p>
				<img
					className="datepicker-button-drop"
					src="https://images.myyogateacher.com/teacher_web_app/ic_dropdown@3x.png"
					alt="drop"
				/>
			</button>
		);
	}
}

class DateP extends Component {
	render() {
		return (
			<div className="date-picker">
				<DatePicker
					customInput={<CustomInput />}
					selected={this.props.startDate}
					onChange={date_selected => this.props.handleChange(date_selected, this.props.component)}
					title={this.props.component}
					showMonthDropdown={this.props.component === 'profile'}
					showYearDropdown={this.props.component === 'profile'}
					minDate={this.props.min_time}
					// placeholderText="Select"
					// popperClassName="custom-datepicker-popper"
				/>
			</div>
		);
	}
}

DateP.propTypes = {
	type: PropTypes.string
};

DateP.defaultProps = {
	type: 'select'
};

export default DateP;
