import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  analytics_track,
  get_time_taken,
  error_console,
} from "../../../util_functions";
import Spinner from "../../util_components/spinner";
import * as selectors from "../../../redux/selectors";
import * as actions from "../../../redux/action_creators";
import "./topsection.css";

class TopSection extends Component {
  state = {
    name: "",
    email: "",
    email_error: false,
    email_error_msg: "",
    password_error: false,
    name_error: false,
    location: "",
    password: "",
    show_signup: false,
    show_forgot: false,
    open_login_modal: false,
    window_width: 0,
    type: "password",
  };
  inputRef = React.createRef();
  componentDidMount() {
    this.setState({ window_width: window.outerWidth });
    window.addEventListener("resize", this.updateDimensions, false);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({ window_width: window.outerWidth });
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.login_status === "loading" &&
      this.props.login_status === "success"
    ) {
      this.track_fn("login success");
    }
    if (
      prevProps.sign_up_status === "loading" &&
      this.props.sign_up_status === "success"
    ) {
      this.track_fn("signup success");
      this.setState({
        name: "",
        email: "",
        location: "",
      });
    }
    if (
      prevProps.forgot_status === "loading" &&
      this.props.forgot_status === "success"
    ) {
      this.track_fn("forgot password success");
      this.setState({
        name: "",
        email: "",
        location: "",
      });
    }
  }
  track_fn = (name) => {
    const options = {
      referrer_component: "home",
      plan: "guest",
      time_since_page_load: get_time_taken(this.props.start_time),
    };
    analytics_track(name, options);
  };
  get_auto_location = () => {
    const apikey = "AIzaSyC2d5FKB7VlPUT_49okaSLafzbmdfVocq8";
    this.track_fn("detect location click");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=true&key=${apikey}`;
        axios(url)
          .then((response) => {
            this.setState({
              location: response.data.results[6].formatted_address,
            });
            this.track_fn("detect location success");
          })
          .catch(() => {
            error_console("gapi geocoding error");
            this.track_fn("detect location failure");
          });
      });
    } else {
      this.track_fn("detect location not supported");
      error_console("Geolocation is not supported by this browser!");
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}_error`]: false,
    });
  };
  toggle_forgot = () => {
    this.setState({ show_forgot: !this.state.show_forgot });
  };
  toggle_login = () => {
    this.setState({ show_signup: !this.state.show_signup });
  };
  toggle_signup = () => {
    this.setState({ show_signup: this.state.show_signup });
  };
  validate_email = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = re.test(String(email).toLowerCase());
    return result;
  };
  log_in = () => {
    if (this.props.login_status !== "loading") {
      const { email, password } = this.state;
      if (email.trim() === "") {
        this.setState({
          email_error: true,
          email_error_msg: "This field cannot be empty",
        });
      } else if (password.trim() === "") {
        this.setState({ password_error: true });
      } else if (!this.validate_email(email)) {
        this.setState({
          email_error: true,
          email_error_msg: "Please enter valid email",
        });
      } else {
        this.track_fn("login submit click");
        this.props.log_in_to_app({
          email,
          password,
        });
      }
    }
  };
  forgot_password = () => {
    if (this.props.forgot_status !== "loading") {
      const { email } = this.state;
      if (email.trim() === "") {
        this.setState({
          email_error: true,
          email_error_msg: "This field cannot be empty",
        });
      } else if (!this.validate_email(email)) {
        this.setState({
          email_error: true,
          email_error_msg: "Please enter valid email",
        });
      } else {
        this.track_fn("forgot password submit click");
        this.props.forgot_password({
          email,
        });
      }
    }
  };
  sign_up = () => {
    if (this.props.sign_up_status !== "loading") {
      const { name, email, location } = this.state;
      if (name.trim() === "") {
        this.setState({ name_error: true });
      } else if (email.trim() === "") {
        this.setState({
          email_error: true,
          email_error_msg: "This field cannot be empty",
        });
      } else if (!this.validate_email(email)) {
        this.setState({
          email_error: true,
          email_error_msg: "Please enter valid email",
        });
      } else {
        this.track_fn("signup submit click");
        let payload = {
          name,
          email,
        };
        if (location.trim() !== "") {
          payload.location = location;
        }
        this.props.sign_up(payload);
      }
    }
  };
  go_back_to_login = () => {
    this.toggle_forgot();
    this.track_fn("switch to login from forgot password");
  };
  render_forgot = () => {
    const btn_classname =
      this.props.forgot_status === "loading" ? "span-close" : "";
    return (
      <div className="row-top">
        <div className="teacher-left-text-container ">
          <a
            alt="close"
            onClick={this.close_login_modal}
            className="teacher-scroll-close close-btn"
          >
            <div className="circleincont">
              <div className="circlein" />
              <img src="https://images.myyogateacher.com/Teacher-Page/close-btn.png" />
            </div>
          </a>
          <form className="teacher-form-signup">
            <div className="tp-signup-container">
              <h4>Reset Password</h4>
              <div className="input-container">
                <label htmlFor="inputEmail" className="sr-only">
                  *Email
                </label>
                <input
                  type="email"
                  id="inputEmail"
                  className="input-form"
                  required=""
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
                {this.state.email_error ? (
                  <div className="input-container-error">
                    <p>{this.state.email_error_msg}</p>
                    <div className="input-container-tr" />
                  </div>
                ) : null}
              </div>
              {this.props.forgot_status === "fail" ? (
                <p className="text-error">{this.props.forgot_error_msg}</p>
              ) : null}

              <a
                type="submit"
                className="signupbtn btn-purple loginbtn"
                onClick={this.forgot_password}
              >
                <span className={btn_classname}>Reset</span>
                {this.props.forgot_status === "loading" ? (
                  <div className="btn-purple-loader">
                    <Spinner
                      circleRadius={16}
                      borderLength={1}
                      smallBorderColor="#4332DF"
                      largeBorderColor="#fff"
                    />
                  </div>
                ) : null}
              </a>
              {this.props.forgot_status === "success" ? (
                <p style={{ color: "#4332DF" }}>
                  Please check your email and follow the link to change your
                  password
                </p>
              ) : null}
            </div>
          </form>
          <div className="form-footer-signin-sec">
            <p>
              Back to <a onClick={this.go_back_to_login}>Login</a>
            </p>
          </div>
        </div>
      </div>
    );
  };
  go_to_signup = () => {
    this.toggle_login();
    this.track_fn("switch to signup from login");
  };
  go_to_forgot = () => {
    this.toggle_forgot();
    this.track_fn("switch to forgot password from login");
  };
  on_login_press = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.log_in();
    }
  };
  handleClickPassword = () => {
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));
  };
  render_login = () => {
    const btn_classname =
      this.props.login_status === "loading" ? "span-close" : "";
    return (
      <div className="row-top">
        <div className="teacher-left-text-container flipper-card flipper-is-flipped">
          <div className="flip-back">
            <div className="flip-inside">
              <a
                alt="close"
                onClick={this.close_login_modal}
                className="teacher-scroll-close close-btn"
              >
                <div className="circleincont">
                  <div className="circlein" />
                  <img src="https://images.myyogateacher.com/Teacher-Page/close-btn.png" />
                </div>
              </a>
              <form className="teacher-form-signup">
                <div className="tp-signup-container">
                  <h4>Teacher Login</h4>
                  <div className="input-container">
                    <label htmlFor="inputEmail" className="sr-only">
                      *Email
                    </label>
                    <input
                      type="email"
                      id="inputEmail"
                      className="input-form"
                      required=""
                      name="email"
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                    {this.state.email_error ? (
                      <div className="input-container-error">
                        <p>{this.state.email_error_msg}</p>
                        <div className="input-container-tr" />
                      </div>
                    ) : null}
                  </div>
                  <div className="input-container">
                    <label htmlFor="inputPassword" className="sr-only">
                      *Password
                    </label>
                    <div className="inp_pass_div">
                      <input
                        type={this.state.type}
                        id="inputPassword"
                        className="input-form mb0"
                        required=""
                        name="password"
                        value={this.state.password}
                        onKeyPress={this.on_login_press}
                        onChange={this.handleChange}
                      />

                      <span
                        className="password_show"
                        onClick={this.handleClickPassword}
                      >
                        {this.state.type === "text" ? (
                          <img src="https://images.myyogateacher.com/icons/eye-hide3.png" />
                        ) : (
                          <img src="https://images.myyogateacher.com/icons/eye-icon3.png" />
                        )}
                      </span>
                    </div>

                    {this.state.password_error ? (
                      <div className="input-container-error">
                        <p>This field cannot be empty</p>
                        <div className="input-container-tr" />
                      </div>
                    ) : null}
                  </div>
                  {this.props.login_status === "fail" ? (
                    <p className="text-error">{this.props.login_error_msg}</p>
                  ) : null}
                  <a
                    type="submit"
                    className="signupbtn btn-purple loginbtn"
                    onClick={this.log_in}
                  >
                    <span className={btn_classname}>Login</span>
                    {this.props.login_status === "loading" ? (
                      <div className="btn-purple-loader">
                        <Spinner
                          circleRadius={16}
                          borderLength={1}
                          smallBorderColor="#4332DF"
                          largeBorderColor="#fff"
                        />
                      </div>
                    ) : null}
                  </a>
                  <p>
                    Forgot password?{" "}
                    <a onClick={this.go_to_forgot}>Click here</a>
                  </p>
                </div>
              </form>
              <div className="form-footer-signin-sec">
                <p>
                  Dont have an account?{" "}
                  <a onClick={this.props.go_to_signup}>Sign up</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  go_to_login = () => {
    this.toggle_login();
    this.track_fn("switch to login from signup");
  };
  render_signup = () => {
    const btn_classname =
      this.props.sign_up_status === "loading" ? "span-close" : "";
    return (
      <div className="row-top">
        <div className="teacher-left-text-container flipper-card">
          <a
            alt="close"
            onClick={this.close_login_modal}
            className="teacher-scroll-close close-btn"
          >
            <div className="circleincont">
              <div className="circlein" />
              <img src="https://images.myyogateacher.com/Teacher-Page/close-btn.png" />
            </div>
          </a>
          <form className="teacher-form-signup">
            <div className="tp-signup-container">
              <h4>Teacher Sign up</h4>
              <div className="input-container">
                <label htmlFor="name" className="sr-only">
                  *Name
                </label>
                <input
                  type="text"
                  id="inputName"
                  className="input-form"
                  autoFocus
                  name="name"
                  ref={this.inputRef}
                  onChange={this.handleChange}
                  value={this.state.name}
                />
                {this.state.name_error ? (
                  <div className="input-container-error">
                    <p>This field cannot be empty</p>
                    <div className="input-container-tr" />
                  </div>
                ) : null}
              </div>
              <div className="input-container">
                <label htmlFor="inputEmail" className="sr-only">
                  *Email
                </label>
                <input
                  type="email"
                  id="inputEmail"
                  className="input-form"
                  required=""
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
                {this.state.email_error ? (
                  <div className="input-container-error">
                    <p>{this.state.email_error_msg}</p>
                    <div className="input-container-tr" />
                  </div>
                ) : null}
              </div>
              <label htmlFor="inputLocation" className="sr-only">
                Location
              </label>
              <input
                type="text"
                id="inputLocation"
                className="input-form mb0"
                required=""
                name="location"
                value={this.state.location}
                onChange={this.handleChange}
              />
              <a
                className="tp-detect-location"
                onClick={this.get_auto_location}
              >
                <img
                  className="location-icon"
                  src="https://images.myyogateacher.com/Teacher-Page/ic_detect_location.png"
                />
                <span>Detect my location</span>
              </a>

              <a
                type="submit"
                className="signupbtn btn-purple"
                onClick={this.sign_up}
              >
                <span className={btn_classname}>Sign up</span>
                {this.props.sign_up_status === "loading" ? (
                  <div className="btn-purple-loader">
                    <Spinner
                      circleRadius={16}
                      borderLength={1}
                      smallBorderColor="#4332DF"
                      largeBorderColor="#fff"
                    />
                  </div>
                ) : null}
              </a>
              {this.props.sign_up_status === "success" ? (
                <p style={{ color: "#4332DF" }}>
                  Thank you for your interest, please check your email for more
                  details.
                </p>
              ) : null}
            </div>
          </form>
          <div className="form-footer-signin-sec">
            <p>
              Alreay have an account? <a onClick={this.go_to_login}>Login</a>
            </p>
          </div>
        </div>
      </div>
    );
  };
  render_top_container = () => {
    if (this.state.window_width > 992 || this.state.open_login_modal) {
      return this.state.show_signup
        ? this.render_signup()
        : this.state.show_forgot
        ? this.render_forgot()
        : this.render_login();
    } else {
      return null;
    }
  };
  open_signup_modal = () => {
    this.setState({ open_login_modal: true });
  };
  open_login_modal = () => {
    this.toggle_login();
    this.setState({ open_login_modal: true });
  };
  close_login_modal = () => {
    this.setState({
      open_login_modal: false,
      show_signup: true,
      show_forgot: false,
    });
  };
  render() {
    
    return (
      <div className="teacher-full-section">
        <div className="fullxtp">
          <img
            src="https://s3.amazonaws.com/images.myyogateacher.com/home-page/Banner-Img.png"
            className="img-bg"
            alt="ban"
          />
          <div className="upper-top-section">
            <div className="top-section">
              <div className="container">
                <div className="header-logo">
                  <a href="https://myyogateacher.com/">
                    <img
                      className="mytlogo"
                      src="https://images.myyogateacher.com/myt-logo-new2.png" alt="logo"
                    />
                  </a>
                </div>
                <div className="toogle-menu-items">
                  <ul>
                    <li>
                      <a>Home</a>
                    </li>
                    <li>
                      <a>Teacher Portal</a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="https://images.myyogateacher.com/home-page/L-FB1.png" alt="fb" />
                      </a>
                    </li>
                  </ul>
                </div>
                {this.render_top_container()}
              </div>
            </div>
          </div>

          <div className="teacher-mobile-text-container">
            <div className="container">
              <div className="tp-m-signup-container">
                <h4>Teacher Sign up / Login</h4>

                <div className="tp-link-container">
                  <a
                    type="submit"
                    className="signupbtn btn-purple"
                    onClick={this.open_signup_modal}
                  >
                    Sign up
                  </a>

                  <span>or</span>

                  <a
                    type="submit"
                    className="tp-logintext"
                    onClick={this.open_login_modal}
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="teach-yoga">
          <div className="container">
            <div className="slider-left-container">
              <div className="slider">
                <div className="quote-slider">
                  <img src="https://images.myyogateacher.com/Teacher-Page/quote.png" />
                </div>

                <div className="slide1">
                  <h2>Teach Yoga to the world from the comfort of your home</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home_component_index: selectors.get_home_component_index(state),
    teacher_token: selectors.get_teacher_token(state),
    onboarding_status: selectors.teacher_details_all(state).status,
    load_application_status: selectors.load_application_status(state),
    sign_up_status: selectors.get_signup_status(state),
    login_status: selectors.get_login_status(state),
    forgot_status: selectors.get_forgot_status(state),
    login_error_msg: selectors.login_error_msg(state),
    forgot_error_msg: selectors.forgot_error_msg(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load_dashboard_sessions: () => {
      dispatch(actions.load_dashboard_sessions());
    },
    get_my_availability: () => {
      dispatch(actions.get_my_availability());
    },
    load_application: () => {
      dispatch(actions.load_application());
    },
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    },
    sign_up: (payload) => {
      dispatch(actions.sign_up(payload));
    },
    log_in_to_app: (index) => dispatch(actions.log_in_to_app(index)),
    forgot_password: (payload) => dispatch(actions.forgot_password(payload)),
  };
};

const TopSectionWithRedux = connect(mapStateToProps, mapDispatchToProps)(TopSection);

export default TopSectionWithRedux;
