import React from 'react';
import { CloseIcon, SelectedItem } from '../../../../../svg';
import './index.css';
// const daysToAdjust = {
//   "37": -1,
//   "38": -7,
//   "39": 1,
//   "40": 7
// };

function cloneDate(date) {
	return new Date(date.valueOf());
}

// function isValidDate(value, format = "mm/dd/yyyy") {
//   let month, day, year;
//   const delimiter = /[^mdy]/.exec(format)[0];
//   const formatParts = format.split(delimiter);
//   const dateParts = value.split(delimiter);

//   for (let i = 0; i < dateParts.length; i++) {
//     if (/m/.test(formatParts[i])) month = dateParts[i];
//     if (/d/.test(formatParts[i])) day = dateParts[i];
//     if (/y/.test(formatParts[i])) year = dateParts[i];
//   }

//   return (
//     month > 0 &&
//     month < 13 &&
//     year &&
//     year.length === 4 &&
//     day > 0 &&
//     day <= new Date(year, month, 0).getDate()
//   );
// }

function getFirstDayofWeek(date) {
	const temp = cloneDate(date);

	const dayOfWeek = temp.getDay();
	const difference = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // Adjust for Sunday start

	temp.setDate(temp.getDate() + difference);

	return temp;
}

function getAllDaysInAWeek(date) {
	const weekStartDate = getFirstDayofWeek(date);
	const days = [];

	for (let i = 0; i < 7; i++) {
		const next = cloneDate(weekStartDate);
		next.setDate(next.getDate() + i);
		days.push(next);
	}

	return days;
}

function WeekHeader() {
	const dayNames = ['Mo', 'Tu', 'We', 'Th', 'Fri', 'Sa', 'Su'];

	return dayNames.map(function (day, index) {
		return (
			<span key={day + index} className='day-header '>
				{day}
			</span>
		);
	});
}

function buildWeeks(date) {
	const tempDate = cloneDate(date);

	tempDate.setDate(1);

	const month = tempDate.getMonth();
	const weekStartDate = getFirstDayofWeek(tempDate);
	const currentDate = cloneDate(weekStartDate);
	const weeks = [cloneDate(currentDate)];

	currentDate.setDate(currentDate.getDate() + 7);

	while (currentDate.getMonth() === month) {
		weeks.push(cloneDate(currentDate));
		currentDate.setDate(currentDate.getDate() + 7);
	}

	return weeks;
}
function fn_DateCompare(DateA, DateB) {
	// this function is good for dates > 01/01/1970

	var a = new Date(DateA);
	var b = new Date(DateB);

	var msDateA = Date.UTC(a.getFullYear(), a.getMonth() + 1, a.getDate());
	var msDateB = Date.UTC(b.getFullYear(), b.getMonth() + 1, b.getDate());

	if (parseFloat(msDateA) < parseFloat(msDateB)) return -1; // lt
	else if (parseFloat(msDateA) == parseFloat(msDateB)) return 0; // eq
	else if (parseFloat(msDateA) > parseFloat(msDateB)) return 1; // gt
	else return null; // error
}
// function isWeekend(date) {
//   const weekday = date.getDay();

//   return weekday === 0 || weekday === 6;
// }
const isSlotAvailable = (day, slots) => {
	return slots.some((slot) => fn_DateCompare(day, slot) === 0);
};
function Day({ day, month, selected, onSelect, available_slots }) {
	let shouldBeDisabled = day.getMonth() !== month || !isSlotAvailable(day, available_slots);
	let className = shouldBeDisabled ? 'disabled' : '';

	if (day.getTime() === selected.getTime()) {
		className = 'selected-day';
	}

	return (
		<span className={'day ' + className} onMouseUp={shouldBeDisabled ? null : onSelect.bind(null, day)}>
			{day.getDate()}
		</span>
	);
}
Day.propTypes = {
	day: Date,
	month: Date,
	selected: Boolean,
	onSelect: Function,
	available_slots: Array,
};
function Week({ weekStart, selected, month, onSelect, available_slots }) {
	return (
		<div className='days-wrapper'>
			{getAllDaysInAWeek(weekStart).map(function (day, i) {
				return (
					<Day
						key={i}
						month={month}
						day={new Date(day)}
						selected={selected}
						onSelect={onSelect}
						available_slots={available_slots}
					/>
				);
			})}
		</div>
	);
}
Week.propTypes = {
	weekStart: Date,
	selected: Boolean,
	month: Date,
	onSelect: Function,
	available_slots: Array,
};
function Weeks({ date, month, selected, onSelect, available_slots }) {
	return (
		<div>
			{buildWeeks(date).map(function (day, i) {
				return (
					<Week
						key={i}
						month={month}
						weekStart={day}
						selected={selected}
						onSelect={onSelect}
						available_slots={available_slots}
					/>
				);
			})}
		</div>
	);
}
Weeks.propTypes = {
	date: Date,
	selected: Boolean,
	month: Date,
	onSelect: Function,
	available_slots: Array,
};
function Calendar({ selectedDate, onMonthChange, onSelect, avaliable_slots }) {
	const [selected, setSelected] = React.useState(selectedDate);

	React.useEffect(() => {
		setSelected(new Date(selectedDate));
	}, [selectedDate]);

	const setDate = (selected) => {
		setSelected(selected);
		onMonthChange();
	};

	const nextMonth = () => {
		const clone = new Date(selected);

		clone.setMonth(selected.getMonth() + 1);
		setDate(clone);
	};

	const previousMonth = () => {
		const clone = new Date(selected);
		clone.setMonth(selected.getMonth() - 1);
		setDate(clone);
	};

	const year = selected.getFullYear();
	const month = selected.toLocaleString('en-us', { month: 'long' });
	const clone = new Date(selected);
	let canGoPreviousMonth = clone.getMonth() - 1 >= new Date().getMonth();
	return (
		<div>
			<div className='month-header-wrapper'>
				<span className='title-wrapper'>
					<span className='month-title'>{month}</span>
					<span className='year-title '>{year}</span>
				</span>
				<span className='arrow right' onClick={nextMonth}>
					&gt;
				</span>
				<span
					className={`arrow right`}
					onClick={canGoPreviousMonth ? previousMonth : null}
					style={{ opacity: canGoPreviousMonth ? '1' : '0.3' }}
				>
					&lt;
				</span>
			</div>
			<div className='day-header-wrapper'>{WeekHeader()}</div>
			<Weeks
				date={selected}
				onSelect={onSelect}
				selected={selectedDate}
				month={selected.getMonth()}
				available_slots={avaliable_slots}
			/>
		</div>
	);
}
Calendar.propTypes = {
	selectedDate: Date,
	onMonthChange: Function,
	onSelect: Function,
	avaliable_slots: Array,
};
export function DatePicker({ onDateChange, isSelected, text, available_slots }) {
	const [isOpen, setIsOpen] = React.useState(false);
	//   const [isFocused, setIsFocused] = React.useState(false);
	const [currentSelected, setCurrentSelected] = React.useState(new Date());
	//   const [tempDate, setTempDate] = React.useState(new Date().toLocaleDateString());
	//   const [invalidDate, setInvalidDate] = React.useState(false);
	let datePicker = React.useRef(null);
	let calender = React.useRef(null);
	React.useEffect(() => {
		document.addEventListener('click', handleWindowMouseDown);
		return () => {
			document.removeEventListener('click', handleWindowMouseDown);
		};
	}, []);

	const handleWindowMouseDown = (event) => {
		if (!(calender.current && datePicker.current)) {
			return;
		}

		const eventIsOutside = !calender.current.contains(event.target) && calender.current !== event.target;
		const eventIsOnPopoverAnchor = datePicker.current.contains(event.target) || datePicker.current === event.target;

		if (eventIsOutside && !eventIsOnPopoverAnchor) {
			setIsOpen(false);
		}
	};

	//   const close = () => {
	//     setIsOpen(false);
	//     setIsFocused(false);
	//   };

	//   const handleKeyDown = (event) => {
	//     switch (event.keyCode) {
	//       case 37:
	//       case 38:
	//       case 39:
	//       case 40:
	//         if (!event.shiftKey) {
	//           return;
	//         }

	//         event.preventDefault();

	//         const newDate = new Date(currentSelected);

	//         newDate.setDate(newDate.getDate() + daysToAdjust[event.keyCode]);

	//         setCurrentSelected(newDate);

	//         return;
	//       case 13:
	//         event.preventDefault();

	//         if (!isOpen) {
	//           setIsOpen(true);
	//         } else {
	//           setIsOpen(false);
	//           setCurrentSelected(currentSelected);
	//           setTempDate(currentSelected.toLocaleDateString());
	//         }
	//         return;
	//       case 9:
	//         if (event.shiftKey || !isOpen) {
	//           return;
	//         }

	//         close();

	//         return;
	//       case 27:
	//         close();

	//         return;
	//     }
	//   };

	//   const handleFocus = () => {
	//     setIsFocused(true);
	//   };

	//   const handleBlur = () => {
	//     setIsFocused(false);
	//   };

	const open = () => {
		if (isOpen) {
			return;
		}

		setIsOpen(true);

		inputRef?.current?.focus();
	};

	const select = (currentSelected) => {
		setIsOpen(false);
		setCurrentSelected(currentSelected);
		// setInvalidDate(false);
		// setTempDate(currentSelected.toLocaleDateString());
		inputRef?.current?.focus();
		if (onDateChange instanceof Function) {
			onDateChange(currentSelected);
		}
	};

	//   const updateDate = (event) => {
	//     const { value } = event.target;
	//     const newState = { tempDate: value, invalidDate: true };

	//     if (isValidDate(value)) {
	//       newState.invalidDate = false;
	//       newState.currentSelected = new Date(value);
	//     }

	//     setTempDate(newState.tempDate);
	//     setInvalidDate(newState.invalidDate);
	//     setCurrentSelected(newState.currentSelected);
	//   };

	const inputRef = React.useRef(null);

	//   const isInvalid = invalidDate ? " is-invalid" : "";
	//   const focusClass =
	//     !invalidDate && (isFocused || isOpen) ? " is-focused" : "";

	return (
		<div className='datepicker-wrapper md:relative w-max' ref={datePicker}>
			<div className='w-fit'>
				<div
					className={`relative rounded-7px bg-mytGrayLightV3 hover:bg-white hover:border hover:border-mytgrey-subBorder hover:shadow-dateshadow w-fit zm:px-10px md:px-12px py-9.5px cursor-pointer h-41px focusClass isInvalid  ${
						isSelected ? 'bg-white border border-borderGray shadow-borderShadow' : ''
					}`}
					onClick={open}
				>
					<p className='zm:text-13.5px md:text-14px leading-22px font-semibold text-mytpurple'>
						{text ? text : 'Today'}
					</p>
					{isSelected ? <div className='absolute -top-5px -right-5px'>{<SelectedItem />}</div> : null}
				</div>
			</div>
			{isOpen && (
				<div className='zm:relative md:absolute zm:z-50 md:z-auto'>
					<div className='zm:fixed md:absolute flex items-end justify-center zm:h-screen zm:w-screen md:h-auto md:w-auto  zm:bg-mytHeaderBg md:bg-transparent zm:inset-0 md:inset-auto z-10 md:top-10px'>
						<div className='zm:w-full md:w-auto relative'>
							<div className='datepicker-calendar' ref={calender}>
								<div
									className='zm:block md:hidden absolute right-20px top-20px cursor-pointer'
									onClick={() => setIsOpen(false)}
								>
									<CloseIcon />
								</div>
								<Calendar
									onSelect={select}
									selectedDate={currentSelected}
									onMonthChange={() => inputRef?.current?.focus()}
									avaliable_slots={available_slots}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
DatePicker.propTypes = {
	onDateChange: Function,
	isSelected: Boolean,
	text: String,
	available_slots: Array,
};
