import React, { useState, useEffect } from 'react';

const TimeSlots = ({ getTimeSlotsValue }) => {

    const [timeSlotsValue, setTimeSlotsValue] = useState([]);

    const getTimeSlots = () => {
        const startTime = new Date();
        startTime.setHours(5, 0, 0);
        const endTime = new Date();
        endTime.setHours(22, 0, 0);

        const timeSlots = [];
        const timeSlotInterval = 30;

        while (startTime <= endTime) {
            const time = startTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
            timeSlots.push(time);
            startTime.setMinutes(startTime.getMinutes() + timeSlotInterval);
        }

        setTimeSlotsValue(timeSlots)
    };

    useEffect(() => {
        getTimeSlots();
    }, []);

    useEffect(() => {
        if (typeof getTimeSlotsValue === 'function') {
            getTimeSlotsValue(timeSlotsValue);
        }
    }, [timeSlotsValue]);

    return (
        <div>
            <ul className='text-mytgrey-lite text-14px font-semibold leading-22px'>
                {timeSlotsValue.map((time, index) => (
                    <li
                        key={time}
                        className='flex items-center px-4px'
                        style={{ height: '41px' }}
                    >
                        {time}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TimeSlots;
