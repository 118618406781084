import { all, put, takeEvery, call, cancelled, take, fork } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { createUploader, post_api, delete_api } from '../../../redux/api_funcs';
import urls from '../../urls/urls';

export function* uploadFiles(action) {
    try {
    yield put(actions.set_upload_file_variable('upload_file_status', 'loading'));
    const upload_image = urls.upload_image;
    const response = yield call(
      createUploader,
      upload_image,
      action.payload,
      true
    )
    const [uploadPromise, chan] = response;
    yield fork(watchOnProgress, chan);
    const result = yield call(() => uploadPromise);
    yield put(actions.successUploadFile(result.data.id));
    yield put(actions.setUploadProgress(result.data.id, 100));
    yield put(actions.set_upload_file_variable('upload_file_response', response.data));
    yield put(actions.set_upload_file_variable('upload_file_status', 'success'));
    yield put(actions.set_upload_file_variable('fileProgress', {}));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    }
    yield put(actions.set_upload_file_variable('upload_file_response', error.response.data));
    yield put(actions.set_upload_file_variable('upload_file_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

function* watchOnProgress(chan) {
  while (true) {
    const data = yield take(chan);
    console.log(data[0].progress);
    yield put(actions.setUploadProgress(data[0].id, data[0].progress));
  }
}

export function* get_uploaded_image(action) {
  try {
    yield put(actions.set_upload_file_variable('get_file_status', 'loading'));
    const get_upload_image = urls.get_upload_image;
    const payload = {
      ...action.payload,
    };
    const response = yield call(
      post_api,
      get_upload_image,
      payload,
      true
    )
    yield put(actions.set_upload_file_variable('get_file_response', response.data));
    yield put(actions.set_upload_file_variable('get_file_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    }
    yield put(actions.set_upload_file_variable('get_file_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* get_all_uploaded_image(action) {
  try {
    yield put(actions.set_upload_file_variable('get_all_file_status', 'loading'));
    const get_upload_image = urls.get_all_upload_image;
    const payload = {
      ...action.payload,
    };
    const response = yield call(
      post_api,
      get_upload_image,
      payload,
      true
    )
    yield put(actions.set_upload_file_variable('get_all_file_response', response.data));
    yield put(actions.set_upload_file_variable('get_all_file_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    }
    yield put(actions.set_upload_file_variable('get_all_file_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* get_customized_image(action) {
  try {
    yield put(actions.set_upload_file_variable('get_customized_image_status', 'loading'));
    const get_upload_image = urls.get_customized_image;
    const payload = {
      ...action.payload,
    };
    const response = yield call(
      post_api,
      get_upload_image,
      payload,
      true
    )
    yield put(actions.set_upload_file_variable('get_customized_image_response', response.data));
    yield put(actions.set_upload_file_variable('get_customized_image_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    }
    yield put(actions.set_upload_file_variable('get_customized_image_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* delete_uploaded_image(action) {
  try {
    yield put(actions.set_upload_file_variable('delete_file_status', 'loading'));
    const delete_uploaded_image = urls.delete_uploaded_image;
    const payload = {
      ...action.payload,
    };
    const response = yield call(
      delete_api,
      delete_uploaded_image,
      true,
      payload,
    )
    yield put(actions.set_upload_file_variable('delete_file_response', response.data));
    yield put(actions.set_upload_file_variable('delete_file_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    }
    yield put(actions.set_upload_file_variable('delete_file_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export default function* uploadFileSaga() {
  yield all([
    takeEvery(t.UPLOAD_FILE, uploadFiles),
    takeEvery(t.GET_UPLOAD_FILE, get_uploaded_image),
    takeEvery(t.GET_ALL_UPLOAD_FILE, get_all_uploaded_image),
    takeEvery(t.GET_CUSTOMIZED_IMAGE, get_customized_image),
    takeEvery(t.DELETE_IMAGE, delete_uploaded_image),
  ])
}