import React from 'react';

import ChatService from 'myt-chat/src/external/ChatService';

export const withChatProps = (Component) => {
  function ComponentWithRouterProp(props) {
    const unread = ChatService.useTotalUnreadCount();
    /* eslint-disable react/jsx-props-no-spreading */
    return <Component {...props} chat={{ totalUnreadCount: unread || 0 }} />;
  }

  return ComponentWithRouterProp;
};
