import * as t from "../action_types";

export default function (state = [], action) {
  switch (action.type) {
    case t.request_replacement_list:
      return {
        ...state,
        replacement_sessions_list: action.payload,
      };

    default:
      return state;
  }
}
