import React, { PureComponent } from 'react';
import { convert_to_pascal } from '../../../util_functions';
import PropTypes from 'prop-types';
import './custom_dropdown.css';

class CustomDropdown extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			openDropdown: false
		};
		this.customDropdownRef = React.createRef();
	}
	componentDidMount() {
		document.addEventListener('mousedown', this.handleMouseClick, false);
	}
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleMouseClick, false);
	}
	handleMouseClick = e => {
		if (!this.customDropdownRef.current.contains(e.target)) {
			this.setState({ openDropdown: false });
		}
	};

	render_single_dropdown = () => (
		<div className={this.props.dropDownContents ? this.props.dropDownContents : "custom-dropdown-values-container"} style={{ width: this.props.width }}>
			{this.props.drop_down_list.map((item, index) => (
				<div
					key={item + index}
					className={this.props.dropDownValues ? this.props.dropDownValues : "custom-dropdown-values"}
					onClick={() => {
						this.props.handleChange(item);
						// this.handleChange(item)
						this.setState({ openDropdown: false });
					}}
				>
					{convert_to_pascal(item)}
				</div>
			))}
		</div>
	);

	onMultipleChange = item => {
		let array = [...this.props.value];
		// if(this.props.value !== ""){
		//   array = this.props.value.split(',');
		// }
		const index = array.indexOf(item);
		if (index < 0) {
			array.push(item);
		} else {
			array.splice(index, 1);
		}
		this.props.handleChange(array);
	};

	render_multiple_dropdown = () => {
		// const val_arr = this.props.value.split(',')
		const val_arr = this.props.value;
		return (
			<div className="custom-multiple-dropdown-values-container">
				{this.props.drop_down_list.map((item, index) => (
					<div
						key={item + index}
						className="custom-multiple-dropdown-values"
						onClick={() => {
							this.onMultipleChange(item);
						}}
					>
						<input type="checkbox" checked={val_arr.includes(item)} readOnly />
						<div className="custom-multiple-text">{convert_to_pascal(item)}</div>
					</div>
				))}
			</div>
		);
	};

	render_dropdown = () =>
		this.state.openDropdown
			? this.props.multi_select
				? this.render_multiple_dropdown()
				: this.render_single_dropdown()
			: null;

	render() {
		const rotate_class = this.state.openDropdown ? 'custom-dropdown-icon-rotate' : null;
		return (
			<div 
				className="custom-dropdown relative" style={{ width: this.props.width }}
				ref={this.customDropdownRef}
			>
				<div
					tabIndex="0"
					className={this.props.className ? this.props.className : 'custom-dropdown-box'}
					onClick={() => this.props.is_disable ? '' : this.setState({ openDropdown: !this.state.openDropdown })}
					style={{ width: this.props.width }}
				>
					<div className={this.props.dropDownContent ? this.props.dropDownContent : "custom-dropdown-text"}>
						{this.props.multi_select
							? this.props.value.length !== 0
								? convert_to_pascal(this.props.value.join(','))
								: `Select`
							: this.props.value !== '' && typeof this.props.value != 'undefined'
							? convert_to_pascal(this.props.value)
							: this.props.is_disable ? '--' : `Select`}
						{}
						{/* {this.state.value !== "" ? this.state.value : `Select an item`} */}
					</div>
					<div className={this.props.dropDownContent ? `${this.props.dropDownContent} ${rotate_class}` : `custom-dropdown-icon ${rotate_class}`}>
						<img src={this.props.dropDownIcon ? this.props.dropDownIcon : "https://images.myyogateacher.com/teacher_web_app/ic_dropdown@2x.png"} alt="icon"/>
					</div>
				</div>
				{this.render_dropdown()}
			</div>
		);
	}
}

CustomDropdown.propTypes = {
	multi_select: PropTypes.bool,
	width: PropTypes.string,
    is_disable: PropTypes.bool
};

CustomDropdown.defaultProps = {
	multi_select: false,
	width: 'null',
    is_disable: false
};

export default CustomDropdown;
