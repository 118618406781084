import React from 'react';

const ClosedCaptions = ({ text, teacher }) => {
	return (
		<>
			{/* <div className='cc-container'>
				{text.length > 0 && <span className='speaker-name shadow-mytboxshadow'>{teacher.first_name} : &nbsp;</span>}
				{text.length > 0 ? <span>{text}</span> : <span>closed captions will appear here</span>}
			</div> */}
		</>
	);
};

export default React.memo(ClosedCaptions);
