import React from 'react';
import PopupWrapper from './PopupWrapper';
import SwitchButton from '../FixedComponent/SwitchButton';
import { connect } from 'react-redux';
import * as actions from '../../Redux/action_creators';
import Loader from '../FixedComponent/Loader';
import DateFilter from '../DatePicker';
import TimePicker from '../TimePicker';
import Duration from '../FixedComponent/Input/Duration';
import RadioButton from '../FixedComponent/Input/RadioButtton';
import moment from 'moment-timezone';
import { getCookie } from '../../utils/cookie_funcs';
import HTMLReactParser from 'html-react-parser';

interface IState {
  loading: boolean;
  isCheckedRepeat: boolean;
  selectedTimeDt: any;
  changeSelectedDate: any;
  changeSelectedDuration: any;
  editClassData: any;
  errorMsg: string;
  subscriptionDataDt: object;
  noCardMsg: string;
}
interface IMapStateToProps {
  editPopupData: any;
  schedule_class_status: any;
  schedule_class_response: any;
  get_subscription_data_status: string;
  scheduleSessionDataInfoErrMsg: string;
  get_subscription_data_response: {};
}

interface IMapDispatchToProps {
  scheduleClass: (payload?: any) => void;
  get_subscription_data: (payload?: any) => void;
  set_home_variable: (key?: any, payload?: any) => void;
  set_class_details_variable: (key?: any, payload?: any) => void;
  closePopup: () => void;
  closeSchedulePopup: () => void;
  closesubscriptionPopupFun: () => void;
  openCreditPopup: (payload?: any) => void;
}

type ScheduleClassAllProps = IMapStateToProps & IMapDispatchToProps;
class DashboardScheduleClassPopup extends React.Component<ScheduleClassAllProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      selectedTimeDt: this.props.editPopupData.epoch_start_time,
      changeSelectedDate: this.props.editPopupData.epoch_start_time,
      changeSelectedDuration: null,
      editClassData: this.props.editPopupData,
      isCheckedRepeat: false,
      errorMsg: '',
      subscriptionDataDt: {},
      noCardMsg: ''
    };
  }

  componentDidMount(): void {
    this.setState({
      isCheckedRepeat: this.state.editClassData.is_recurring ? true : false
    });
    let payload = {
      student_uuid: this.state.editClassData.student_uuid
    };
    this.props.get_subscription_data(payload);
    if (
      this.props.schedule_class_response?.message &&
      this.props.schedule_class_response?.message.length
    ) {
      this.setState({
        errorMsg: this.props.schedule_class_response?.message || ''
      });
    }
    if (this.props.schedule_class_status === 'fail') {
      this.setState({
        errorMsg: this.props.schedule_class_response?.message || ''
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ScheduleClassAllProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (
      prevProps.schedule_class_status === 'loading' &&
      this.props.schedule_class_status === 'fail'
    ) {
      this.setState({
        errorMsg: this.props.schedule_class_response?.message || '',
        loading: false
      });
    }
    if (
      prevProps.get_subscription_data_status === 'loading' &&
      this.props.get_subscription_data_status === 'success'
    ) {
      this.setState({
        subscriptionDataDt: this.props.get_subscription_data_response
      });
    }
    if (
      prevProps.schedule_class_status === 'loading' &&
      this.props.schedule_class_status === 'success'
    ) {
      this.setState({ loading: false });
      this.props.closesubscriptionPopupFun();
    }
  }

  componentWillUnmount(): void {
    this.props.set_class_details_variable('schedule_class_response', null);
  }

  onclickDt = () => {
    this.setState({ isCheckedRepeat: !this.state.isCheckedRepeat });
  };

  closePopupDt = () => {
    this.setState({ errorMsg: '' }, () => {
      this.props?.closePopup();
    });
  };

  onClickDateValue = (dt) => {
    let time = moment(this.state.changeSelectedDate).format('HH:mm:ss');
    let date = moment(dt).format('YYYY-MM-DD');
    let dateTime = new Date(moment(`${date} ${time}`).valueOf());
    this.setState({ selectedTimeDt: dt, errorMsg: '', changeSelectedDate: dateTime });
  };

  onClickTimeValue = (dt) => {
    let time = moment(dt).format('HH:mm:ss');
    let date = moment(this.state.selectedTimeDt).format('YYYY-MM-DD');
    let dateTime = new Date(moment(`${date} ${time}`).valueOf());
    this.setState({ changeSelectedDate: dateTime, errorMsg: '' });
  };

  onClickDurationValue = (dt) => {
    this.setState({ changeSelectedDuration: dt });
  };

  editDataBtn = () => {
    let startTime = moment(this.state.changeSelectedDate).format('HH:mm:ss');
    let startDate = moment(this.state.selectedTimeDt).format('YYYY-MM-DD');
    let epochTime = moment(`${startDate} ${startTime}`).valueOf();
    let subscriptionPayloadData = {
      student_uuid: this.state.editClassData?.student_uuid,
      start_time: epochTime,
      duration: this.state.changeSelectedDuration,
      is_recurring: this.state.isCheckedRepeat === true ? 1 : 0,
      api_version_v2: 1
    };
    let priceForDuration;
    priceForDuration =
      this.state.changeSelectedDuration === 30
        ? this.state.subscriptionDataDt['session_price_30']
        : this.state.changeSelectedDuration === 60
          ? this.state.subscriptionDataDt['session_price']
          : this.state.changeSelectedDuration === 90
            ? this.state.subscriptionDataDt['session_price_90']
            : '';
    let singlePriceForDuration = priceForDuration;
    if (moment(epochTime).isSameOrAfter(moment(moment().subtract('15', 'minutes')))) {
      if (this.state.subscriptionDataDt['is_student_subscribed'] === 1) {
        priceForDuration =
          this.state.isCheckedRepeat === true ? priceForDuration * 4 : priceForDuration;
        if (this.state.subscriptionDataDt['credits_available'] >= priceForDuration) {
          this.setState({ loading: true });
          this.props.scheduleClass(subscriptionPayloadData);
        } else {
          this.payloadFunction(singlePriceForDuration, subscriptionPayloadData);
        }
      } else if (
        this.state.subscriptionDataDt['is_trial'] === 1 ||
        (this.state.subscriptionDataDt['is_student_subscribed'] === 0 &&
          this.state.subscriptionDataDt['is_trial'] === 0)
      ) {
        if (this.state.isCheckedRepeat === true) {
          if (
            this.state.subscriptionDataDt['cards'] &&
            this.state.subscriptionDataDt['cards'].length
          ) {
            this.payloadFunction(singlePriceForDuration, subscriptionPayloadData);
          } else {
            this.setState({
              errorMsg:
                'Student has neither signed up for membership nor has credit card on file.  Please request student to either purchase a membership or add credit card in My Accounts'
            });
          }
        } else {
          if (this.state.subscriptionDataDt['credits_available'] >= priceForDuration) {
            this.setState({ loading: true });
            this.props.scheduleClass(subscriptionPayloadData);
          } else {
            if (
              this.state.subscriptionDataDt['cards'] &&
              this.state.subscriptionDataDt['cards'].length
            ) {
              this.payloadFunction(singlePriceForDuration, subscriptionPayloadData);
            } else {
              let msg =
                this.state.subscriptionDataDt['is_trial'] === 0
                  ? 'Time of this session is after trial period ends for the student. Please request student to purchase membership or to schedule session from student app'
                  : 'Student does not have any free credits remaining and no credit card on file. Please request student to add credit card in My Accounts';
              this.setState({ errorMsg: msg });
            }
          }
        }
      }
    } else {
      this.setState({
        errorMsg: 'Session time needs to be in future. Please recheck and adjust session time.'
      });
    }
  };

  payloadFunction = (durationPrice, subscriptionPayloadData) => {
    let payload = {
      subscriptionDataDt: this.state.subscriptionDataDt,
      durationPrice: durationPrice,
      subscriptionPayload: subscriptionPayloadData,
      studentDetails: this.state.editClassData
    };
    this.props.set_home_variable('scheduleSessionDataInfo', payload);
    this.props.openCreditPopup(payload);
  };

  render() {
    let timePickerData;
    let datePickerData;

    if (Object.keys(this.props.scheduleSessionDataInfoErrMsg).length) {
      timePickerData = {
        inputTypeLabel: 'Start time',
        selectedTime: this.state.editClassData.start_time,
        minDateFun: new Date()
      };
      datePickerData = {
        inputTypeLabel: 'Date',
        data: this.state.editClassData.start_time,
        minDateFun: new Date()
      };
    } else {
      timePickerData = {
        inputTypeLabel: 'Start time',
        selectedTime: this.state.editClassData.epoch_start_time,
        minDateFun: new Date()
      };
      datePickerData = {
        inputTypeLabel: 'Date',
        data: this.state.editClassData.epoch_start_time,
        minDateFun: new Date()
      };
    }

    let student_timezone_value = this.state.subscriptionDataDt['student_timezone'];

    return (
      <>
        {this.state.loading === true ? (
          <Loader />
        ) : (
          <PopupWrapper closePopup={() => this.closePopupDt()}>
            <div className="mb-0">
              <div className="font-semibold text-subText md:text-19 zm:text-17">
                Schedule new 1-on-1 session
              </div>
              <div className="text-14.5px text-xl font-normal font-inter text-mytgrey-lite sm:mt-10px sm:mb-25px zm:mt-10px zm:mb-25px zm:leading-5 md:leading-26px">
                This option should be mindfully used in agreement with the student
              </div>
              <div>
                <div className="flex justify-between zm:gap-15px sm:gap-22px">
                  <div>
                    <DateFilter
                      {...datePickerData}
                      onClickDateValue={(dt) => this.onClickDateValue(dt)}
                    />
                    {/* <p className='text-mytGrayLiteV2 font-medium text-xs pt-2'>Every {moment(this.state.selectedTimeDt).format('dddd    ')} at  {moment(this.state.changeSelectedDate).format('h:mm A')}</p> */}
                  </div>
                  <div>
                    <TimePicker
                      {...timePickerData}
                      onClickTimeValue={(dt) => this.onClickTimeValue(dt)}
                    />
                    {student_timezone_value && student_timezone_value.length && (
                      <p className="md:hidden zm:block text-startTimeTextColor text-12px font-medium font-inter pt-2px mb-25px">
                        Student's local time is{' '}
                        {moment(this.state.selectedTimeDt)
                          ?.tz(student_timezone_value)
                          ?.format('ddd, MMM DD,')}{' '}
                        {moment(this.state.changeSelectedDate)
                          ?.tz(student_timezone_value)
                          ?.format('h:mm A z')}
                      </p>
                    )}
                  </div>
                </div>
                {student_timezone_value && student_timezone_value.length && (
                  <p className="zm:hidden md:block text-startTimeTextColor font-medium mb-5 text-sm pt-2">
                    Student's local time is{' '}
                    {moment(this.state.changeSelectedDate)
                      ?.tz(student_timezone_value)
                      ?.format('ddd, MMM DD,')}{' '}
                    {moment(this.state.changeSelectedDate)
                      ?.tz(student_timezone_value)
                      ?.format('h:mm A z')}
                  </p>
                )}
              </div>
              <div>
                <Duration
                  inputTypeLabel={'Duration'}
                  selectedDuration={this.state.editClassData.duration}
                  onClickDurationValue={(dt) => this.onClickDurationValue(dt)}
                />
              </div>
              <div className="mt-4">
                <div className="bg-mytGrayLightV3 rounded-lg p-15px mb-25px flex items-center justify-between">
                  <div className="flex flex-col items-start justify-center">
                    <p className="text-mytLabel text-15 font-medium">Make it a repeat</p>
                    {this.state.isCheckedRepeat === true && (
                      <p className="text-startTimeTextColor font-medium text-12px pt-2">
                        Every {moment(this.state.selectedTimeDt)?.format('dddd')} at{' '}
                        {moment(this.state.changeSelectedDate)
                          .tz(getCookie('time_zone'))
                          ?.format('h:mm A z')}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center">
                    {this.state.isCheckedRepeat === true ? (
                      <p className="text-mytLabel mr-15px text-15 font-medium md:flex zm:hidden">
                        Yes
                      </p>
                    ) : (
                      <p
                        style={{ marginLeft: '160px' }}
                        className="text-mytLabel text-15 font-medium md:flex zm:hidden mr-15px"
                      >
                        No
                      </p>
                    )}
                    <SwitchButton
                      id={'1'}
                      isChecked={this.state.isCheckedRepeat}
                      value={'yes'}
                      onClick={this.onclickDt}
                    />
                  </div>
                </div>
              </div>
              {/* } */}
              {this.state.errorMsg?.length !== 0 && (
                <p className="text-mytRedText text-15.5 font-medium mb-5 error-msg">
                  {this.state.errorMsg !== ''
                    ? HTMLReactParser(this.state.errorMsg)
                    : 'Something went wrong, please try again after sometime '}
                </p>
              )}
              {Object.keys(this.props.scheduleSessionDataInfoErrMsg) &&
              Object.keys(this.props.scheduleSessionDataInfoErrMsg).length ? (
                <p className="text-mytRedText text-15.5 font-medium mb-5">
                  {this.props.scheduleSessionDataInfoErrMsg['message']}
                </p>
              ) : (
                ''
              )}
              <button
                onClick={() => this.editDataBtn()}
                className={`btn btn-primary w-full md:text-18.5 zm:text-17 md:py-5 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-6 zm:h-49px lg:h-60px`}
              >
                Schedule
              </button>
            </div>
          </PopupWrapper>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    schedule_class_status: state.classdetails.schedule_class_status,
    schedule_class_response: state.classdetails.schedule_class_response,
    get_subscription_data_status: state.dashboardv1.get_subscription_data_status,
    get_subscription_data_response: state.dashboardv1.get_subscription_data_response,
    scheduleSessionDataInfo: state.dashboardv1.scheduleSessionDataInfo,
    scheduleSessionDataInfoErrMsg: state.classdetails.scheduleSessionDataInfoErrMsg
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    set_class_details_variable: (key: any, payload: any) => {
      dispatch(actions.set_class_details_variable(key, payload));
    },
    set_home_variable: (key: any, payload: any) => {
      dispatch(actions.set_home_variable(key, payload));
    },
    scheduleClass: (payload: any) => {
      dispatch(actions.scheduleClass(payload));
    },
    get_subscription_data: (payload: any) => {
      dispatch(actions.get_subscription_data(payload));
    }
  };
};

const DashboardScheduleClassPopupWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardScheduleClassPopup);

export default DashboardScheduleClassPopupWithRedux;
