import React from 'react';
import { analytics_track, get_time_taken } from '../../../util_functions';
import './get_started.css';
const open_zendesk = () => {
	window.zE(() => {
		window.zE.showIPMWidget();
	});
};

const track_fn = (name, start_time) => {
	const options = {
		referrer_component: 'home-get-started',
		plan: 'guest',
		time_since_page_load: get_time_taken(start_time)
	};
	analytics_track(`${name} link click`, options);
};

const on_signup_click = (scroll_fn, start_time) => {
	scroll_fn();
	track_fn('signup', start_time);
};
const GetStarted = props => {
	return (
		<div className="tp-get-started-sec bg-light">
			<div className="container">
				<h2>Get started</h2>
				<p className="subpara">The interview process is quick and simple</p>
				<p className="divider-line">
					<img src="https://images.myyogateacher.com/Teacher-Page/Dividers3.png" />
				</p>
				<div className="row3getstarted">
					<div className="col35getstarted">
						<div className="rowinsidegetstarted">
							<div className="yImggetstarted">
								<img src="https://images.myyogateacher.com/home-page/India+Map.png" />
								<div className="dot dot-1" />
								<div className="dot dot-2" />
								<div className="dot dot-3" />
								<div className="dot dot-4" />
								<div className="dot dot-5" />
								<div className="dot dot-6" />
								<div className="dot dot-7" />
								<div className="dot dot-8" />
								<div className="dot dot-9" />
								<div className="dot dot-10" />
							</div>
							<p className="map-text">Our team of Yoga teachers from all around the India</p>
						</div>
					</div>

					<div className="col65getstarted">
						<div className="tprowfull">
							<div className="col50getstarted">
								<div className="rowinsidegetstarted">
									<div className="icon">
										<img src="https://images.myyogateacher.com/home-page/bg_circular_tag.png" />
										<span>#1</span>
									</div>
									<div className="text">
										<h4>Apply online</h4>
										<p>Simply apply on our website by signing up</p>
										<h3>How to sign up online?</h3>
									</div>
								</div>
								<div className="rowinsidegetstarted">
									<div className="icon">
										<img src="https://images.myyogateacher.com/home-page/bg_circular_tag.png" />
										<span>#2</span>
									</div>
									<div className="text">
										<h4>Interview & training</h4>
										<p>Connect with a mentor, give a demo session, receive feedback and training</p>
										<h3>Do I need to travel for the Interview & training?</h3>
									</div>
								</div>

								<div className="rowinsidegetstarted">
									<div className="icon">
										<img src="https://images.myyogateacher.com/home-page/bg_circular_tag.png" />
										<span>#3</span>
									</div>
									<div className="text">
										<h4>Start teaching</h4>
										<p>Complete your profile, provide availability, start teaching</p>
										<h3>Do I need to leave my current job?</h3>
									</div>
								</div>
								<div className="rowinsidegetstarted">
									<div className="div-signup-get-started">
										<a
											className="btn-purple-getstarted"
                      onClick={() => on_signup_click(props.go_to_signup, props.start_time)}
										>
											Sign up to apply now
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="tp-row-call-us tp-smcallus-hide">
							<div className="col100getstarted">
								<p>Still need help? Talk to our team 24/7</p>
								<div className="gsfull-width">
									<a
										className="btn-border-grey"
										onClick={() => {
											open_zendesk();
											track_fn('help chat', props.start_time);
										}}
									>
										<img className="icon live-chat" src="https://images.myyogateacher.com/Teacher-Page/ic_chat.png" />
										<span>Live chat</span>
									</a>
									<span>or</span>
									<a
										className="btn-border-grey"
										href="mailto:care@myyogateacher.com"
										onClick={() => track_fn('email us', props.start_time)}
									>
										<img className="icon" src="https://images.myyogateacher.com/Teacher-Page/ic_email.png" />
										<span>Email us</span>
									</a>

									<span>or</span>
									<p className="callus-btn">
										<img className="icon" src="https://images.myyogateacher.com/Teacher-Page/ic_call.png" />
										<span>
											<b>Call Us</b>{' '}
											<a href="tel:+91 94141 14756" onClick={() => track_fn('phone', props.start_time)}>
												+91 94141 14756
											</a>
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="tp-row-call-us tp-smcallus-show">
					<div className="col100getstarted">
						<p>Still need help? Talk to our team 24/7</p>
						<div className="gsfull-width">
							<a className="btn-border-grey" onClick={open_zendesk}>
								<img className="icon" src="https://images.myyogateacher.com/Teacher-Page/ic_chat.png" />
								<span>Live chat</span>
							</a>
							<span>or</span>
							<a className="btn-border-grey" href="mailto:care@myyogateacher.com">
								<img className="icon" src="https://images.myyogateacher.com/Teacher-Page/ic_email.png" />
								<span>Email us</span>
							</a>

							<span>or</span>
							<p className="callus-btn">
								<img className="icon" src="https://images.myyogateacher.com/Teacher-Page/ic_call.png" />
								<span>
									<b>Call Us</b> <a href="tel:+91 94141 14756">tel:+91 94141 14756</a>
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GetStarted;
