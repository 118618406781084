import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { BredcrumbIcon, CloseIcon } from '../../../svg';

import Checkbox from '../../common/CheckBox/index';

import SlotsForTherapy from './SlotsForTherapy';
import TimeSlots from './TimeSlots';
import urls from '../../urls';
import { post_api } from '../../redux/api_funcs';
import RangePicker from './components/RangePicker/RangePicker';
import { getCookie, sendEventToClarity } from '../../util_functions';
import { OrangeTick, RedCancelIcon } from '../../new_teacher_design/BackPainTherapy/svg';
import Modal from '../../new_teacher_design/BackPainTherapy/CommonComponents/Modal';
import Loader from '../../new_teacher_design/components/FixedComponent/Loader';
import Header from '../util_components/header';

const therapistUpdates = [
  {
    id: 1,
    label: 'Prior coaches',
    bgColor: 'bg-primary'
  },
  {
    id: 2,
    label: 'Any coach',
    bgColor: 'bg-lightOrangeBGColor'
  },
  {
    id: 3,
    label: 'No coach',
    bgColor: 'bg-orangeLiteColor'
  }
];
const Weeks = [
  {
    id: 1,
    label: 'Mon'
  },
  {
    id: 2,
    label: 'Tue'
  },
  {
    id: 3,
    label: 'Wed'
  },
  {
    id: 4,
    label: 'Thu'
  },
  {
    id: 5,
    label: 'Fri'
  },
  {
    id: 6,
    label: 'Sat'
  },
  {
    id: 7,
    label: 'Sun'
  }
];

function TherapySessionBooking(props) {
  const [time, setTime] = useState([]);
  const [slots, setSlots] = useState({});
  const [selfSlots, setSelfSlots] = useState({});
  const [currentDateRange, setCurrentDateRange] = useState([]);
  const [formattedDateRange, setFormattedDateRange] = useState([]);
  const [slotSelected, setSlotSelected] = useState({});
  const [teachersSelected, setTeachersSelected] = useState([]);
  const [toggleSelfAvailaiblity, setToggleSelfAvailaiblity] = useState(false);
  const [booked, setbooked] = useState(false);
  const [bookedSessionDetails, setBookedSessionDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [toggelFailPopup, setToggelFailPopup] = useState(false);
  const [failReason, setFailReason] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [noOfDaysToDisplay, setnoOfDaysToDisplay] = useState(window.innerWidth > 564 ? 7 : 4);
  const [slotListMobile, setSlotListMobile] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const student_uuid = urlParams.get('id');
  const redirectFrom = urlParams.get('from');

  const navigate = useNavigate();

  const formatData = (response) => {
    const convertedObject = {};

    response.forEach((day) => {
      const dayObject = {};

      day.slots.forEach((slot) => {
        dayObject[slot.time] = {
          epoch_time: slot.epoch_time,
          available_teachers: slot.available_teachers
        };
      });
      convertedObject[day.date] = dayObject;
    });

    return convertedObject;
  };
  const renderSessionBookedPopup = () => {
    const epochTime = bookedSessionDetails?.epoch_start_time || '';

    let date = moment(epochTime);
    date = date.tz(timeZone);
    const formattedDate = date.format('dddd Do MMM [at] h:mmA z');

    return (
      <Modal
        onClose={() => {
          setbooked(false);
          navigate(-1);
        }}
      >
        <div className="flex flex-col justify-center items-center zm:px-25px md:px-10 pb-10 pt-1.5">
          <OrangeTick />
          <div className="font-semibold zm:text-19px md:text-19px leading-22px text-greyText zm:mt-10px md:mt-5 text-center flex items-center justify-center">
            You have booked a Back Pain Coaching session
          </div>
          <div className="font-normal text-14.5px leading-26px text-mytgrey-lite mt-5px">
            for {bookedSessionDetails?.student_name || ''} with{' '}
            {bookedSessionDetails?.teacher_name?.split(' ')[0] || ''} on
          </div>
          <div className="font-medium text-14.5px leading-26px text-blackmt-5px ">
            {formattedDate || ''}
          </div>
          <div className="font-normal text-14.5px leading-26px text-mytgrey-lite mt-5 text-center">
            {' '}
            We just sent an email to {bookedSessionDetails?.student_name || ''}
          </div>
        </div>
      </Modal>
    );
  };
  const renderFailPopup = () => (
      <Modal
        onClose={() => {
          setToggelFailPopup(false);
          getData();
        }}
      >
        <div className="flex flex-col justify-center items-center zm:px-25px md:px-10 pb-10 pt-1.5">
          <RedCancelIcon />
          <div className="font-semibold text-19px leading-22px mt-15px text-19 text-center capitalize">
            {failReason}
          </div>
        </div>
      </Modal>
    );
  const getData = () => {
    const url = urls.therapy_slots;
    setLoading(true);
    const payload = {};
    payload.student_uuid = student_uuid;

    post_api(url, payload, true)
      .then((res) => {
        const days = res.data.available_slots;
        // slots.filter((slot) => {
        //     return slot.available_teachers.some(
        //         (teacher) => teacher.uuid === teacherUUID
        //     );
        // });
        const teacherUUID = getCookie('teacher_uuid');
        const filteredDays = [];
        const timezone = res?.data?.student_timezone || 'America/Los_Angeles';
        setTimeZone(timezone);
        [...res.data.available_slots].forEach((day, index) => {
          const filteredSlots = [];
          day.slots.forEach((slot, index) => {
            let isTeacherThere = false;
            slot.available_teachers.forEach((teacher, index) => {
              if (teacher.uuid === teacherUUID) {
                isTeacherThere = true;
              }
            });
            if (isTeacherThere) {
              filteredSlots.push(slot);
            }
          });
          if (filteredSlots.length > 0) {
            const filteredDay = { ...day, slots: filteredSlots };
            filteredDays.push(filteredDay);
          }
        });
        const selfSlots = formatData(filteredDays);
        const slotsAvailable = formatData(res.data.available_slots);
        setSlots(slotsAvailable);
        setSelfSlots(selfSlots);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const toggleSelfAvailibilityFunc = () => {
    setToggleSelfAvailaiblity(!toggleSelfAvailaiblity);
    setTeachersSelected([]);
    setSlotSelected({});
  };
  useEffect(() => {
    getData();
    sendEventToClarity();
  }, []);

  const convertTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    let convertedHours = parseInt(hours);
    let period = 'AM';

    if (convertedHours >= 12) {
      period = 'PM';
      if (convertedHours > 12) {
        convertedHours -= 12;
      }
    }
    if (minutes?.includes('AM') || minutes?.includes('PM')) {
      return `${convertedHours}:${minutes}`;
    } 
      return `${convertedHours}:${minutes} ${period}`;
    
  };
  const bookSession = (teacher = {}) => {
    const url = urls.schedule_therapy_consultation;
    const payload = {
      target_teacher_uuid: teacher.uuid,
      start_time: slotSelected.epoch_time,
      student_uuid
    };
    post_api(url, payload, true)
      .then((res) => {
        if (res.data && res.data.sessions && res.data.sessions.length > 0) {
          setBookedSessionDetails(res.data.sessions[0]);
          setbooked(true);
          getData();
        }
      })
      .catch((err) => {
        setToggelFailPopup(true);
        setFailReason(err.response.data.reason);
        console.log(err.response.data.reason);
      });
  };
  if (loading) {
    return <Loader />;
  }
  const guessedTimezone = timeZone || 'America/Los_Angeles';
  const currentTime = moment().tz(guessedTimezone);
  const timeZoneIntitals = currentTime.format('z');
  const teacherUUID = getCookie('teacher_uuid');
  const modifiedTeacherList =
    toggleSelfAvailaiblity && teacherUUID
      ? [...teachersSelected].filter((teacher) => teacher.uuid === teacherUUID)
      : [...teachersSelected];
  return (
    <>
      <Header />
      {booked ? (
        renderSessionBookedPopup()
      ) : (
        <div className="bg-backpainBGColor flex justify-center zm:px-25px zm:pt-25px md:pt-80px w-full pb-40px customScrollBar zm:mt-70px lg:mt-0">
          <div className="h-fit zm:w-full md:max-w-980px">
            <div className="flex items-center justify-between">
              <div>
                {redirectFrom && redirectFrom === 'profile' ? (
                  <div className="flex items-center gap-16px">
                    <div
                      className="text-14px leading-18px font-semibold text-mytpurple cursor-pointer"
                      onClick={() =>
                        props?.router?.location?.state?.profileUrl
                          ? navigate(props?.router?.location?.state?.profileUrl)
                          : null
                      }
                    >
                      {props?.router?.location?.state?.profileInfo?.name || ''}
                    </div>
                    <div>
                      <BredcrumbIcon />
                    </div>
                    <div className="text-14px leading-18px font-semibold text-mytgrey-lite cursor-pointe">
                      Book a 1-on-1 session
                    </div>
                  </div>
                ) : null}
                <div className="mt-20px zm:mb-7px md:mb-15px zm:text-19px md:text-38px leading-44px font-extrabold">
                  Book a 1-on-1 session
                </div>
                <div className="leading-18px zm:text-13px md:text-15px font-normal text-mytgrey-contactHeading">
                  This option should be mindfully used in agreement with the client. All sessions
                  are booked for a duration of 30 minutes.
                </div>
              </div>
            </div>
            <div className="h-auto w-full flex md:flex-row zm:flex-col zm:gap-20px md:gap-0 zm:mt-34px md:mt-50px">
              <div className="flex justify-center md:p-40px zm:p-20px w-full bg-white border border-stepperPannelBorderColor shadow-borderShadow zm:rounded-8px md:rounded-tr-none md:rounded-br-none">
                <div className="flex flex-col w-full">
                  <div className="flex items-center">
                    <p className="zm:text-17px md:text-19px leading-22px text-mytgrey-subText font-semibold ">
                      Availability of coaches
                    </p>
                    <span className="text-13px font-bold leading-16px ml-5px text-mytgrey-subText">
                      ({timeZoneIntitals || ''})
                    </span>
                  </div>
                  <div className="flex items-center zm:gap-10px md:gap-20px mt-10px mb-20px flex-wrap">
                    {therapistUpdates?.map((items, index) => (
                        <div key={index} className="flex items-center gap-5px">
                          <div className={`h-12px w-12px ${items.bgColor}`} />
                          <div className="text-13.5px font-medium leading-18px text-mytgrey-lite">
                            {items.label}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="zm:py-15px md:py-20px px-20px bg-mytTrailCard rounded-9px zm:mb-35px md:mb-25px">
                    <Checkbox
                      className="md:text-15px"
                      isChecked={toggleSelfAvailaiblity}
                      name="Show only my availability"
                      onChange={() => toggleSelfAvailibilityFunc()}
                    />
                  </div>
                  <div className="flex flex-col items-center w-max">
                    <RangePicker
                      onDateRangeChange={(dateRange) => {
                        setTeachersSelected([]);
                        setSlotSelected({});
                        let leftDate = dateRange[0];
                        let rightDate = dateRange[1];
                        leftDate = moment(leftDate).format('MMM Do');
                        rightDate = moment(rightDate).format('MMM Do');
                        setCurrentDateRange(dateRange);
                        setFormattedDateRange([leftDate, rightDate]);
                      }}
                      enableDays={noOfDaysToDisplay}
                    />
                    <div className="flex w-full zm:gap-11px md:gap-18px">
                      <div>
                        <TimeSlots
                          slots={slots}
                          getTimeSlotsValue={(timeSlotsValue) => setTime(timeSlotsValue)}
                        />
                      </div>
                      <div>
                        <div>
                          <div className="flex">
                            {Weeks.slice(0, noOfDaysToDisplay).map((week, index) => (
                                <div key={index}>
                                  {time.map((time, i) => {
                                    // this is buggy
                                    const timeFormatted = convertTime(time);
                                    const day =
                                      currentDateRange.length === 2
                                        ? addDays(currentDateRange[0], index)
                                        : addDays(new Date(), index);
                                    const dayFormatted = moment(day).format('ddd, MMM Do');
                                    const slotsToPick = toggleSelfAvailaiblity ? selfSlots : slots;
                                    const teachersAvailaible =
                                      slotsToPick.hasOwnProperty(dayFormatted) &&
                                      slotsToPick[dayFormatted].hasOwnProperty(timeFormatted)
                                        ? slotsToPick[dayFormatted][timeFormatted]
                                            .available_teachers
                                        : [];
                                    const slotPicked =
                                      slotsToPick.hasOwnProperty(dayFormatted) &&
                                      slotsToPick[dayFormatted].hasOwnProperty(timeFormatted)
                                        ? slotsToPick[dayFormatted][timeFormatted]
                                        : {};
                                    const isSlotSelected =
                                      slotsToPick.hasOwnProperty(dayFormatted) &&
                                      slotsToPick[dayFormatted].hasOwnProperty(timeFormatted)
                                        ? slotSelected.epoch_time ===
                                          slotsToPick[dayFormatted][timeFormatted].epoch_time
                                        : false;
                                    const isPreviousTeacher =
                                      !!(slotsToPick.hasOwnProperty(dayFormatted) &&
                                      slotsToPick[dayFormatted].hasOwnProperty(timeFormatted) &&
                                      slotsToPick[dayFormatted][timeFormatted].hasOwnProperty(
                                        'available_teachers'
                                      ) &&
                                      slotsToPick[dayFormatted][
                                        timeFormatted
                                      ].available_teachers.filter(
                                        (teacher) => teacher.is_previous_teacher === 1
                                      ).length > 0);
                                    const epochTime = slotPicked.epoch_time;
                                    const date = moment(epochTime).tz(
                                      Intl.DateTimeFormat().resolvedOptions().timeZone
                                    );
                                    const formattedDisplayDate = date.format(
                                      'ddd, Do MMM [at] h:mmA z'
                                    );
                                    return (
                                      <SlotsForTherapy
                                        key={i}
                                        onClick={(teacher) => {
                                          const dateSlot =
                                            slotsToPick.hasOwnProperty(dayFormatted) &&
                                            slotsToPick[dayFormatted].hasOwnProperty(timeFormatted)
                                              ? slotsToPick[dayFormatted][timeFormatted]
                                              : {};
                                          if (isSlotSelected) {
                                            setSlotSelected({});
                                          } else {
                                            setSlotSelected(dateSlot);
                                            setTeachersSelected(teachersAvailaible);
                                            setSlotListMobile(true);
                                          }
                                        }}
                                        state={
                                          teachersAvailaible.length === 0
                                            ? 'no_coaches'
                                            : teachersAvailaible.length > 0 &&
                                                toggleSelfAvailaiblity
                                              ? 'self_availibility'
                                              : isPreviousTeacher
                                                ? 'prior_coaches'
                                                : 'any_coaches'
                                        }
                                        customClass="hover:bg-mytgreen cursor-pointer"
                                        isSelected={isSlotSelected}
                                        teachersList={teachersAvailaible}
                                        displayTime={formattedDisplayDate}
                                        toggleSelfAvailaiblity={toggleSelfAvailaiblity}
                                      />
                                    );
                                  })}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  teachersSelected.length > 0
                    ? `md:max-w-350px zm:w-full md:h-auto zm:fixed md:static zm:left-0 zm:bottom-0 zm:bg-mytHeaderBg md:bg-transparent zm:h-screen zm:flex zm:items-end md:block zm:pt-60px md:pt-0 zm:z-60 md:z-0 ${
                        slotListMobile ? 'zm:block' : 'zm:hidden'
                      }`
                    : 'md:max-w-350px zm:w-full zm:hidden md:block'
                }`}
              >
                <div
                  className={`relative w-full md:bg-rightPannelBGColor border md:border-rightPannelBorderColor zm:rounded-tl-3xl zm:rounded-tr-3xl md:rounded-bl-none md:rounded-tl-none md:rounded-tr-8px md:h-full md:pt-110px overflow-scroll zm:bg-white ${
                    teachersSelected.length > 0 ? 'zm:h-calc140px zm:p-30px ' : ''
                  }`}
                >
                  <div
                    className="absolute top-20px right-20px zm:block md:hidden"
                    onClick={() => setSlotListMobile(false)}
                  >
                    <CloseIcon />
                  </div>
                  {teachersSelected.length > 0 && (
                    <div className="md:text-lightorange zm:text-black zm:text-17px zm:font-bold md:font-medium zm:leading-normal text-left font-medium md:text-12px font-Inter mb-40px">
                      Your local time is{' '}
                      {moment(slotSelected.epoch_time)
                        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .format('ddd, Do MMM [at] h:mmA z') || ''}
                    </div>
                  )}
                  {[...modifiedTeacherList].map((teacher, index) => (
                      <div className="flex mb-40px">
                        <div className="rounded-full w-50px h-50px mr-10px">
                          <img className="rounded-full" src={teacher?.profile_photo} />
                        </div>
                        <div className="flex flex-col justify-center gap-20px">
                          <div className="flex items-center mt-14px text-19px font-medium leading-23px">
                            {teacher?.name || ''}
                          </div>
                          <button className="btn btn-orange" onClick={() => bookSession(teacher)}>
                            Book a session
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {toggelFailPopup ? renderFailPopup() : null}
    </>
  );
}

export default TherapySessionBooking;
