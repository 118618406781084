import React, { Component } from 'react';

class PopupWrapper extends Component {
	constructor(props) {
		super(props);

		this.wrapperRef = React.createRef();
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	/**
	 * Alert if clicked on outside of element
	 */
	handleClickOutside = (event) => {
		if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
			this.props.closePopup();
		}
	};

	render() {
		return (
			<>
				<div className='relative inset-0 z-50'>
					<div
						onClick={(e) => this.handleClickOutside(e)}
						className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'
					>
						<div
							ref={this.wrapperRef}
							className='z-50 bg-white zm:rounded-tl-3xl zm:rounded-tr-3xl zm-roundex-lb-none zm:rounded-rb-none md:rounded-lg md:p-10 zm:p-30px md:w-492px zm:w-full relative'
						>
							<div className='flex justify-end cursor-pointer absolute top-1 right-1'>
								<div onClick={this.props.closePopup} className='mt-1 mr-1'>
									<svg
										width='40'
										height='40'
										viewBox='0 0 34 34'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<rect width='34' height='34' rx='17' fill='none' />
										<path
											d='M22 12L12 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M12 12L22 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</div>
							<div className='mb-0'>{this.props?.children}</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default PopupWrapper;
