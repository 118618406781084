// eslint-disable-next-line no-unused-vars
import React, { useMemo } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const withRouter = (Component) => {
	const ComponentWithRouterProp = (props) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();

		// const searchParams = useMemo(() => {
		// 	let params = new URLSearchParams(location.search);
		// 	params.delete('headless');
		// 	params.delete('hostname');
		// 	params.delete('loggedin');
		// 	params.delete('device');

		// 	return params;
		// }, [location.search]);

		// let pathname = useMemo(() => {
		// 	let path = location.pathname;
		// 	if (path.endsWith('/')) {
		// 		path = path.substring(0, path.length - 1);
		// 	}

		// 	return path;
		// }, [location.pathname]);

		// const locSearch = useMemo(() => {
		// 	return searchParams.toString();
		// }, [searchParams]);

		// const href = `${pathname}?${locSearch}`;
		// const fullHref = `https://${hostname}${href}`;

		return <Component {...props} router={{ location, navigate, params }} />;
	};

	return ComponentWithRouterProp;
};
