import React, {useEffect} from 'react'
import { UnstableNetwork } from '../../livekit_assets/svg'

const NetworkUnstablePopup = ({ onClose }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 3000);
        return () => clearTimeout(timer);
    }, [onClose]);
    return (
        <div className='fixed inset-0 z-50 overflow-y-auto popup-container'>
            <div className='flex min-h-full items-end justify-center text-center sm:items-center sm:p-0'>
                <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-400px'>
                    <div className='warning-wrapper'>
                        <div><UnstableNetwork /></div>
                        <div className='warning-title'>Your internet connection is Unstable</div>
                        <div className='warning-description'>Please check your internet connection and try to reconnect.</div>
                        <div className='button-container'>
                            <button type="button" class="warning-cancel-button modal-button" onClick={onClose}>
                                Dismiss
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NetworkUnstablePopup