import React, { useState, useRef, useEffect } from 'react';
import { VideocallOptions, VideocallOptionsMobile } from '../livekit_assets/svg';
import RemoveStudentPopup from './RemoveStudentPopup';

function VideoDropDownMenu({
  data,
  onSelect,
  isItemPinned,
  getDropDownStatus,
  getParticipantName,
  idx,
  isClipRecOngoing
}) {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const openDropDown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const closeDropDown = () => {
    setIsDropDownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef?.current && !dropdownRef?.current?.contains(event.target)) {
      closeDropDown();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    if (option.type === 'actions.ban') {
      setSelectedOption(option);
      setShowConfirmation(true);
    } else {
      onSelect(option);
      closeDropDown();
    }
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      onSelect(selectedOption);
    }
    setSelectedOption(null);
    setShowConfirmation(false);
    closeDropDown();
  };

  useEffect(() => {
    if (getDropDownStatus) {
      getDropDownStatus(isDropDownOpen);
    }
  }, [isDropDownOpen]);

  console.log('data', data);
  return (
    <>
      <div className="video-call-drop-down-wrapper cursor-pointer" ref={dropdownRef}>
        <div onClick={openDropDown} className="videoDropDown">
          <VideocallOptions />
        </div>
        <div onClick={openDropDown} className="videoDropDownMobile">
          {isItemPinned ? <VideocallOptions /> : <VideocallOptionsMobile />}
        </div>
        {isDropDownOpen && data[0]?.items && (
          <div
            className={`${idx === 0 ? 'optionDropDown' : ''} video-call-drop-down-menu cursor-pointer ${isItemPinned ? 'pinned-video-call-drop-down-menu' : 'unpinned-video-call-drop-down-menu'}`}
          >
            {data[0]?.items
              .filter((option) => {
                if (isClipRecOngoing) {
                  return option?.label !== 'Start clip'; // Remove 'Start clip' when recording is ongoing
                } 
                  return option?.label !== 'Stop clip'; // Remove 'Stop clip' when recording is not ongoing
                
              })
              .map((option) => (
                <p key={option.label} onClick={() => handleOptionClick(option)}>
                  {option?.label === 'Pin in view' && isItemPinned ? 'Unpin' : option?.label}
                </p>
              ))}
          </div>
        )}
      </div>
      {showConfirmation ? (
        <RemoveStudentPopup
          onConfirmation={handleConfirmation}
          onCancel={() => setShowConfirmation(false)}
          participantName={getParticipantName}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default VideoDropDownMenu;
