import React, { useEffect } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { sendEventToClarity } from '../../../../util_functions';

function Career() {
  useEffect(() => {
    sendEventToClarity();
  }, []);

  return (
    <>
      <Header />
      <div className="relative mx-auto sm:mt-16 zm:mt-8 pb-20">
        <div className="lg:max-w-5xl md:container sm:w-full mx-auto zm:pl-4 zm:pr-0 sm:px-6">
          <div>
            <h1>Careers</h1>
            <p>Join our team and help build the world’s best Yoga platform.</p>
          </div>
          <div className="mt-20">
            <h2 className="border-b">Design</h2>
            <div className="my-6">
              <div className="shadow rounded">
                <div className="md:flex justify-between items-center p-4">
                  <div>
                    <div className="text-mytorange-default text-xl font-semibold">
                      Director, Brand Design
                    </div>
                    <p>Fulltime San Francisco HQ or remote</p>
                  </div>
                  <button className="btn btn-orange zm:mt-4 md:mt-0">Find out more</button>
                </div>
              </div>
            </div>
            <div className="my-6">
              <div className="shadow rounded">
                <div className="md:flex justify-between items-center p-4">
                  <div>
                    <div className="text-mytorange-default text-xl font-semibold">
                      Chief Design Officer
                    </div>
                    <p>Fulltime San Francisco HQ or remote</p>
                  </div>
                  <button className="btn btn-orange zm:mt-4 md:mt-0">Find out more</button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20">
            <h2 className="border-b">Engineering</h2>
            <div className="my-6">
              <div className="shadow rounded">
                <div className="md:flex justify-between items-center p-4">
                  <div>
                    <div className="text-mytorange-default text-xl font-semibold">Engineering</div>
                    <p>Fulltime San Francisco HQ or remote</p>
                  </div>
                  <button className="btn btn-orange zm:mt-4 md:mt-0">Find out more</button>
                </div>
              </div>
            </div>
            <div className="my-6">
              <div className="shadow rounded">
                <div className="md:flex justify-between items-center p-4">
                  <div>
                    <div className="text-mytorange-default text-xl font-semibold">
                      Senior Infrastructure Engineer
                    </div>
                    <p>Fulltime San Francisco HQ or remote</p>
                  </div>
                  <button className="btn btn-orange zm:mt-4 md:mt-0">Find out more</button>
                </div>
              </div>
            </div>
            <div className="my-6">
              <div className="shadow rounded">
                <div className="md:flex justify-between items-center p-4">
                  <div>
                    <div className="text-mytorange-default text-xl font-semibold">
                      Senior Security Analyst
                    </div>
                    <p>Fulltime San Francisco HQ or remote</p>
                  </div>
                  <button className="btn btn-orange zm:mt-4 md:mt-0">Find out more</button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20">
            <h2 className="border-b">Sales</h2>
            <div className="my-6">
              <div className="shadow rounded">
                <div className="md:flex justify-between items-center p-4">
                  <div>
                    <div className="text-mytorange-default text-xl font-semibold">
                      Enterprise Partnerships Manager
                    </div>
                    <p>Fulltime San Francisco HQ or remote</p>
                  </div>
                  <button className="btn btn-orange zm:mt-4 md:mt-0">Find out more</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Career;
