import React from 'react';

function VideoThumbnail({ videoSrc, modifiedClassName, thumbnailSize }) {
  return (
    <div style={thumbnailSize}>
      <video className={modifiedClassName} preload="metadata">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoThumbnail;
