import React from 'react';
import PropTypes from 'prop-types';
import './custom_button.css';

const keyStrokeListenerFunction = (e, onClick) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        onClick();
    }
};
const CustomButton = props => {
    const degree = props.component === 'primary' ? 'primary' : 'secondary';
    const button_loading = props.loading ? 'custom-button-disable' : null;
    return (
        <button
            className={`custom-button ${props.buttonStyle} ${degree} ${button_loading}`}
            onClick={() => (props.loading ? null : props.onClick())}
            tabIndex="0"
            onKeyPress={e => keyStrokeListenerFunction(e, props.onClick)}
        >
            {props.label}
            {props.secondary_text !== '' ? <span className="custom-buttom-secondary"> ({props.secondary_text})</span> : null}
        </button>
    );
};

CustomButton.propTypes = {
    component: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
    secondary_text: PropTypes.string,
    onClick: PropTypes.func
};

CustomButton.defaultProps = {
    component: 'primary',
    label: 'Button',
    loading: false,
    secondary_text: '',
    onClick: () => null
};

export default CustomButton;
