import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import 'react-toastify/dist/ReactToastify.css';

import MainApp from './app/index';
import configureStore from './app/redux/store';
import LoadingScreen from './app/components/util_components/loading_screen';

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={<LoadingScreen />}>
        <MainApp />
      </Sentry.ErrorBoundary>
    </Provider>
  );
}

export default App;
