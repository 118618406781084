import React, { Component } from 'react';
import Player from '@vimeo/player';
import { analytics_track, get_time_taken } from '../../../util_functions';
import { isMobile, isTablet } from 'react-device-detect';
import PropTypes from 'prop-types';
import './custom_vimeo_video.css';

export default class CustomVimeoPlayer extends Component {
	state = {
		mute: true,
		hover: false,
		is_mobile: isMobile || isTablet,
		play_started: !(isMobile || isTablet || this.props.custom_1),
		first_time: true,
		first_time_start_time: null,
	};
	customVideoRef = React.createRef();
	start_segment_track = () => {
		const options = {
			referrer_component: this.props.parent_component,
			plan: 'guest',
			time_since_page_load: get_time_taken(this.props.start_time),
			video_url: this.props.url,
			teacher_name: this.props.teacher_name,
		};
		analytics_track('vimeo video start', options);
	};

	end_segment_track = () => {
		const options = {
			referrer_component: this.props.parent_component,
			plan: 'guest',
			video_url: this.props.url,
			teacher_name: this.props.teacher_name,
		};
		options.duration_of_playback =
			this.state.first_time_start_time !== null ? get_time_taken(this.state.first_time_start_time) : 'null';
		analytics_track('vimeo video end', options);
	};

	UNSAFE_componentWillMount() {
		let args = [];
		args.push('controls=0');
		if (!(this.state.is_mobile || this.props.custom_1)) {
			args.push('autoplay=1');
			args.push('loop=1');
		}
		const url = `${this.props.url}?${args.join('&')}`;
		this.setState({ url });
	}

	componentDidMount() {
		this.start_customs();
		window.addEventListener('scroll', this.scroll_listner);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scroll_listner);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.url !== this.props.url) {
			let args = [];
			args.push('controls=0');
			if (!(this.state.is_mobile || this.props.custom_1)) {
				args.push('autoplay=1');
				args.push('loop=1');
			}
			const url = `${this.props.url}?${args.join('&')}`;
			this.setState({
				url,
				play_started: !(isMobile || isTablet || this.props.custom_1),
			});
		}
	}

	scroll_listner = () => {
		const details = this.customVideoRef.current.getBoundingClientRect();
		if (!(this.state.isMobile || this.props.custom_1)) {
			if (details.height !== 0) {
				if (details.height < window.innerHeight - details.top + 50 && details.top > 0) {
					if (!this.state.play_started) {
						this.on_play();
					}
				} else {
					if (this.state.play_started) {
						this.on_pause();
					}
				}
			}
		}
	};
	start_customs = () => {
		var iframe = document.querySelector('iframe');
		if (iframe !== null) {
			this.player = new Player(iframe);
			this.player.ready().then(() => {
				if (!(this.state.is_mobile || this.props.custom_1)) {
					this.player.setVolume(0);
					// this.player.on('play', this.on_play);
				}
			});
			this.player.on('ended', () => {
				this.setState({ play_started: false });
				try {
					if (this.state.first_time) {
						this.end_segment_track();
						this.setState({ first_time: false });
					}
				} catch {}
			});
		}
	};
	on_play = () => {
		this.player.play();
		this.setState({ play_started: true });
		try {
			if (this.state.first_time) {
				this.setState({ first_time_start_time: new Date().getTime() });
				this.start_segment_track();
			}
		} catch {}
	};
	on_pause = () => {
		this.player.pause();
		this.setState({ play_started: false });
		try {
			if (this.state.first_time) {
				this.end_segment_track();
				this.setState({ first_time: false });
			}
		} catch {}
	};
	on_hover = () => {
		this.setState({ hover: true });
	};

	on_hover_out = () => {
		this.setState({ hover: false });
	};
	on_un_mute = () => {
		if (this.player) {
			this.player.setVolume(1);
			this.setState({ mute: false });
		}
	};
	on_mute = () => {
		if (this.player) {
			this.player.setVolume(0);
			this.setState({ mute: true });
		}
	};
	restart_video = () => {
		if (this.player) {
			this.player.play();
			this.player.setCurrentTime(0);
		}
	};
	toggle_play = () => {
		if (this.state.play_started) {
			this.on_pause();
		} else {
			this.on_play();
		}
	};
	render_mute_icons = () => {
		const hover_class = this.state.hover ? 'custom-video-mute-image' : 'custom-video-mute-image mute-class';
		if (!(this.state.is_mobile || this.props.custom_1)) {
			if (this.state.mute) {
				return (
					<img
						className='custom-video-mute-image'
						src='https://images.myyogateacher.com/website/home/ic_muted@2x.png'
						alt='mute'
						onClick={this.on_un_mute}
					/>
				);
			} else {
				return (
					<img
						className={hover_class}
						src='https://images.myyogateacher.com/website/home/ic_unmuted@2x.png'
						alt='unmute'
						onClick={this.on_mute}
					/>
				);
			}
		}
		return null;
	};
	render_play_icon = () => {
		if (this.state.is_mobile || this.props.custom_1) {
			if (!this.state.play_started) {
				return (
					<div className='custom-vimeo-play' onClick={this.on_play}>
						<img src='https://images.myyogateacher.com/Teacher-Page/ic_play.png' alt='ic' />
					</div>
				);
			}
		}
		return null;
	};

	render() {
		return (
			<div className='vimeo' onMouseOver={this.on_hover} onMouseOut={this.on_hover_out} ref={this.customVideoRef}>
				<img src='https://images.myyogateacher.com/transparent.png' alt='img' className='vimeo-blank-image' />
				<iframe
					id='custom-vimeo-player'
					src={this.state.url}
					title='MyYogaTeacher'
					frameBorder='0'
					webkitallowfullscreen='true'
					mozallowfullscreen='true'
					allowFullScreen
					allow='autoplay'
				/>
				<div className='vimeo-play-frame' onClick={this.toggle_play} />
				{this.render_mute_icons()}
				{this.render_play_icon()}
			</div>
		);
	}
}

CustomVimeoPlayer.propTypes = {
	custom_1: PropTypes.bool,
};

CustomVimeoPlayer.defaultProps = {
	custom_1: false,
};

//custom_1 show the player how it looks in mobile even in desktop with play icon and no mute icon
