import { useMemo } from 'react';
import { useLocation } from 'react-router';

const useSearchParams = () => {
	const { search } = useLocation();
	// returns an object with search params as key value pairs
	const trimParams = (paramString) => {
		let res = {};
		// remove the first ? in the search string
		paramString = paramString.slice(1);
		let queries = paramString.split('&');
		queries.forEach((query) => {
			let [key, value] = query.split('=');
			res[key] = value;
		});
		return res;
	};

	return useMemo(() => trimParams(search), [search]);
};

export default useSearchParams;
