import React, { Component } from 'react';
import Spinner from '../../util_components/spinner';
import urls from '../../../urls';
import { post_api } from '../../../redux/api_funcs';

import './signup.css';
import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class index extends Component {
  state = {
    name: '',
    name_error: false,
    email: '',
    email_error: false,
    email_error_msg: '',
    signup_loading: false,
    singup_success: false,
    resend_loading: false,
    signup_verified: false
  };

  componentDidMount() {
    sendEventToClarity();
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}_error`]: false
    });
  };

  prevent_enter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  sign_up = () => {
    if (!this.state.signup_loading) {
      const { email, name } = this.state;
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = re.test(String(email).toLowerCase());
      if (name.trim() === '') {
        this.setState({ name_error: true });
      } else if (email.trim() === '') {
        this.setState({
          email_error: true,
          email_error_msg: 'This field cannot be empty'
        });
      } else if (!result) {
        this.setState({
          email_error: true,
          email_error_msg: 'Please enter valid email'
        });
      } else {
        const payload = {
          name,
          email
        };
        const url = urls.onboard_teacher;
        this.setState({ signup_loading: true }, () => {
          post_api(url, payload, false).then((res) => {
            const signup_verified = res.data.email_verified;
            this.setState({
              signup_verified: signup_verified === 1,
              singup_success: true,
              signup_loading: false
            });
          });
        });
      }
    }
  };

  on_signup_press = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.sign_up();
    }
  };

  render_email_signup = () => (
      <div className="hp-signup-right">
        <div className="hp-modal-content">
          <h1>
            <b>Hey</b> there!
          </h1>
          <p className="ntext">Sign up to get started</p>
          <form className="hp-form">
            <div className="hp-form-group">
              <input
                name="name"
                type="text"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInput}
                onKeyPress={this.prevent_enter}
                className="input_name"
              />
              <label>Name</label>
              {this.state.name_error ? (
                <div className="hp-form-group-error">
                  <p>This field cannot be empty</p>
                  <div className="hp-form-group-error-tr" />
                </div>
              ) : null}
            </div>
            <div className="hp-form-group">
              <input
                name="email"
                type="email"
                placeholder="Email Id"
                className="hp-form-group-lower"
                value={this.state.email}
                onChange={this.handleInput}
                onKeyPress={this.on_signup_press}
              />
              <label>Email Id</label>
              {this.state.email_error ? (
                <div className="hp-form-group-error">
                  <p>{this.state.email_error_msg}</p>
                  <div className="hp-form-group-error-tr" />
                </div>
              ) : null}
            </div>
            <div className="hp-form-btn">
              <a
                className={`btn-purple ${this.state.signup_loading ? 'btn-purple-loading' : ''}`}
                onClick={this.sign_up}
              >
                <span>Submit</span>
                {this.state.signup_loading ? (
                  <div className="btn-purple-loader">
                    <Spinner
                      circleRadius={12}
                      borderLength={1}
                      smallBorderColor="#4332DF"
                      largeBorderColor="#fff"
                    />
                  </div>
                ) : (
                  ''
                )}
              </a>
            </div>
          </form>
        </div>
      </div>
    );

  resend_email = () => {
    this.setState({ resend_loading: true }, () => {
      const { name, email } = this.state;
      const payload = {
        name,
        email
      };
      const url = urls.onboard_teacher;
      post_api(url, payload, false).then((res) => {
        const signup_verified = res.data.email_verified;
        this.setState({
          signup_verified: signup_verified === 1,
          singup_success: true,
          resend_loading: false
        });
      });
    });
  };

  open_login = () => this.props.router.navigate.push('/');

  render_signup_success = () => (
    <div className="hp-signup-right">
      <div className="hp-modal-content">
        <h1>
          {this.state.signup_verified ? (
            <b>Email already present</b>
          ) : (
            <b>Verify your email address</b>
          )}
        </h1>
        {this.state.signup_verified ? (
          <p className="ntext">Continue to login</p>
        ) : (
          <p className="ntext">We have sent you an email at {this.state.email}</p>
        )}

        {!this.state.signup_verified ? (
          this.state.resend_loading ? (
            <Spinner type="dots" />
          ) : (
            <a className="login-text after-link" onClick={this.resend_email}>
              Resend email
            </a>
          )
        ) : null}
        {!this.state.signup_verified && this.state.resend_email_text ? (
          <p className="resend-email-text">We have sent an email to your email id.</p>
        ) : null}
        <a className="login-text after-link" onClick={this.open_login}>
          Login
        </a>
      </div>
    </div>
  );

  render() {
    return (
      <div className="hp-signup-container">
        <div id="myModal">
          <div className="modal-dialog-signup" role="document">
            <div className="modal-content hp-signup">
              <div className="hp-signup-left" />
              {this.state.singup_success
                ? this.render_signup_success()
                : this.render_email_signup()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const IndexWithRouter = withRouter(index);

export default IndexWithRouter;
