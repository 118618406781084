import * as t from '../action_types';
import { getCookie } from '../../utils';


var initialStata = {

    my_session_status: 'none',
    my_session_response: {},

    my_session_data_status: 'none',
    my_session_data_response: {},

    my_session_cancelled_classes_status: 'none',
    my_session_cancelled_classes_response: {},

    my_session_replaced_classes_status: 'none',
    my_session_replaced_classes_response: {},

    my_session_noshow_classes_status: 'none',
    my_session_noshow_classes_response: {},

    my_session_question_classes_status: 'none',
    my_session_question_classes_response: {},
}


const set_mysession_variable = (state, payload) => {
    return {
      ...state,
      [payload.key]: payload.payload,
    };
  };

export default function (state = initialStata, action) {
    switch (action.type) {
      case t.set_mysession_variable:
        return set_mysession_variable(state, action.payload);
      default:
        return state;
    }
  }
  