import React from 'react';

const NonAvailability = (props) => {
  return (
    <>
      <div className='relative inset-0 z-index-max'>
        <div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
          <div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
            <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
              <div onClick={props.closeNonAvailability}>
                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect width='34' height='34' rx='17' fill='none' />
                  <path
                    d='M22 12L12 22'
                    stroke='#BFBFBF'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12 12L22 22'
                    stroke='#BFBFBF'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
            <div className='mb-0'>
              <div className='flex justify-center flex-col items-center'>
                <div className='w-full'>
                  <div className="flex flex-col justify-center items-center mt-4">
                    <div className="text-17 font-semibold">This session is scheduled outside of your availability. if you are unable to take the session, please cancel or contact admin for a replacement.</div>
                    <button onClick={props.closeNonAvailability} className="btn btn-primary w-24 mt-4">OK</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NonAvailability;
