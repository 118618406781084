import React from 'react';
import moment from 'moment';
import AvailabilityList from '../availability-list';
import EditSlots from '../popup/edit-slots';
import {
  get_avalability_slots_data,
  delete_avalability_slots_data
} from '../../../redux/api_functions/availability_api';
import EditAvailability from '../popup/edit-availability';
import AddAvailability from '../popup/add-availability-slots';
import DeleteSlots from '../popup/delete-slots';
import DeleteAvailabilitySlots from '../popup/delete-availability-slots';
import ReplacementSlots from '../popup/replacement-slots';
import CancelSession from '../popup/cancel-session';
import ChangeAvailibility from '../popup/change-availibility';
import { removeCookie, analytics_reset, sendEventToClarity } from '../../../util_functions';

import Loader from '../../new_teacher_wesite/components/loader';
import { withRouter } from '../../../utils/router';

const {pathname} = window.location;
const searchParams = new URLSearchParams(window.location.search);
class SlotsDate extends React.Component {
  weekdayshort = moment.weekdaysShort();

  state = {
    showYearTable: false,
    showMonthTable: false,
    showDateTable: true,
    dateObject: moment(),
    allmonths: moment.months(),
    selectedDay: null,
    datesList: [],
    counter: 0,
    newData: [],
    todayDate: new Date(),
    disableLeft: false,
    disableRight: false,
    dateClicked: null,
    editTeacherPopup: false,
    setTeacherData: [],
    innerModal: false,
    addAvailabilityPopup: false,
    currentMonthRemaningDaysObj: [],
    currentAllMonthRemaningDaysObj: [],
    currentDateData: {},
    editAvailabilitySlotData: {},
    DeleteSlotsPopup: false,
    deleteSlotsPopupData: {},
    deleteAllAvailability: false,
    deleteAllAvailabilityData: null,
    ReplacementSlotsPopup: false,
    cancelSession: false,
    replacementData: {},
    currentDateAddAvailibility: moment(new Date()).format('YYYY-MM-DD'),
    ChangeAvailibilityPopup: false,
    editPayloadData: null,
    isLoading: false,
    deleteRangeAvailablity: {},
    startAddDateDT: null,
    endAddDateDT: null,
    currentMonthSelected: null
  };

  componentDidMount() {
    const currentStartMonthOnload = searchParams.get('start_date');
    const currentEndMonthOnload = searchParams.get('end_date');
    let startFormatedtDate;
    let endFormatedtDate;

    if (currentStartMonthOnload === null && currentEndMonthOnload === null) {
      const startDate = new Date();
      const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

      startFormatedtDate = moment(startDate).format('YYYY-MM-DD');
      endFormatedtDate = moment(endDate).format('YYYY-MM-DD');

      searchParams.append('start_date', startFormatedtDate);
      searchParams.append('end_date', endFormatedtDate);

      this.props.router.navigate(`${pathname}?${searchParams.toString()}`);
    }

    this.dataCalling();

    if (currentStartMonthOnload !== null && currentEndMonthOnload !== null) {
      this.setState({
        dateObject: moment(currentStartMonthOnload),
        currentDateAddAvailibility: moment(currentStartMonthOnload).format('YYYY-MM-DD'),
        dateClicked: moment(currentStartMonthOnload).format('DD'),
        currentMonthRemaningDaysObj: this.getDaysArray(
          moment(currentStartMonthOnload).format('YYYY'),
          this.getMonthFromString(moment(currentStartMonthOnload).format('MM'), 'initialRender')
        )
      });
    } else {
      const date = new Date();
      this.setState({
        dateClicked: date.getDate(),
        currentMonthRemaningDaysObj: this.getDaysArray(
          this.year(),
          this.getMonthFromString(this.month()),
          'initialRender'
        )
      });
    }

    sendEventToClarity();
  }

  dataCalling = (dt) => {
    this.setState({
      isLoading: true
    });

    let payload;
    const currentStartMonthOnload = searchParams.get('start_date');
    const currentEndMonthOnload = searchParams.get('end_date');

    if (this.state.startAddDateDT !== null && this.state.endAddDateDT !== null) {
      payload = {
        start_date: this.state.startAddDateDT,
        end_date: this.state.endAddDateDT
      };
    } else {
      payload = {
        start_date: currentStartMonthOnload,
        end_date: currentEndMonthOnload
      };
    }

    if (payload !== null) {
      get_avalability_slots_data(payload)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ setTeacherData: response?.data });
            this.functionForDataManipulation(
              response?.data,
              this.state.currentDateAddAvailibility !==
                moment(new Date()).format(moment.HTML5_FMT.DATE)
                ? this.state.currentDateAddAvailibility
                : moment(new Date()).format(moment.HTML5_FMT.DATE)
            );
            this.getLatestDataObj(response?.data);
            this.setState({
              isLoading: false
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            removeCookie('teacher_token_1');
            localStorage.removeItem('teacherLocalChannels');
            analytics_reset();
            this.props.router.navigate('/login');
            this.setState({
              isLoading: true
            });
          }
        });
    }
  };

  // edit data slots for popup

  functionForDataManipulation = (obj, selectedDate) => {
    for (let i = 0; i < obj.teacher_schedule_day.length; i++) {
      if (obj.teacher_schedule_day[i].date === selectedDate) {
        this.setState({ currentDateData: obj.teacher_schedule_day[i] }, () => {});
        break;
      } else {
        this.setState({
          currentDateData: {
            date: selectedDate,
            show_green: 0,
            show_grey: 0,
            show_red: 0,
            total_availability: '0 hrs',
            total_availability_count: 0,
            available_slots: [],
            sessions_data: []
          }
        });
      }
    }
  };

  daysInMonth = () => this.state.dateObject.daysInMonth();

  year = () => this.state.dateObject.format('Y');

  currentDay = () => this.state.dateObject.format('D');

  getMonth = (date) => moment(date).format('MMMM');

  month = () => {
    let monthDt;
    const currentStartMonthOnload = searchParams.get('start_date');
    if (this.state.dateObject && this.state.dateObject.length) {
      monthDt = this.state.dateObject.format('MMMM');
    } else {
      monthDt = moment(currentStartMonthOnload).format('MMMM');
    }

    return monthDt;
  };

  onPrev = () => {
    let curr = '';
    if (this.state.showYearTable === true) {
      curr = 'year';
    } else {
      curr = 'month';
    }

    this.setState({
      isLoading: true
    });

    const fetchingCurrentYear = this.state.dateObject.subtract(1, curr);
    this.setState({
      dateObject: fetchingCurrentYear,
      currentMonthRemaningDaysObj: this.getDaysArray(
        fetchingCurrentYear.format('Y'),
        this.getMonthFromString(fetchingCurrentYear.format('MMMM'))
      )
    });

    const payload = {
      start_date: this.getDaysArray(
        fetchingCurrentYear.format('Y'),
        this.getMonthFromString(fetchingCurrentYear.format('MMMM'))
      )[0].filteredCompleteDate,
      end_date: this.getDaysArray(
        fetchingCurrentYear.format('Y'),
        this.getMonthFromString(fetchingCurrentYear.format('MMMM'))
      ).at(-1).filteredCompleteDate
    };

    searchParams.delete('start_date');
    searchParams.delete('end_date');
    searchParams.append('start_date', payload.start_date);
    searchParams.append('end_date', payload.end_date);

    this.props.router.navigate(`${pathname}?${searchParams.toString()}`);

    // localStorage.setItem('current_month', JSON.stringify(payload));

    this.setState({
      currentDateAddAvailibility: payload.start_date,
      startAddDateDT: payload.start_date,
      endAddDateDT: payload.end_date
    });
    get_avalability_slots_data(payload)
      .then((response) => {
        if (response?.data?.status === 'success') {
          this.setState({ setTeacherData: response?.data }, () => {
            const startMonthDate = this.getDaysArray(
              fetchingCurrentYear.format('Y'),
              this.getMonthFromString(fetchingCurrentYear.format('MMMM'))
            )[0].filteredCompleteDate;

            this.state.setTeacherData.teacher_schedule_day.forEach((el) => {
              if (el.date === startMonthDate) {
                this.setState({
                  currentDateData: el,
                  dateClicked: moment(startMonthDate).format('D')
                });
                
              }
            });
            this.setState({
              isLoading: false
            });
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          removeCookie('teacher_token_1');
          localStorage.removeItem('teacherLocalChannels');
          analytics_reset();
          this.props.router.navigate('/login');
          this.setState({
            isLoading: true
          });
        }
      });
  };

  onNext = () => {
    let curr = '';
    if (this.state.showYearTable === true) {
      curr = 'year';
    } else {
      curr = 'month';
    }

    this.setState({
      isLoading: true
    });

    const fetchingCurrentYear = this.state.dateObject.add(1, curr);
    this.setState({
      dateObject: fetchingCurrentYear,
      currentMonthRemaningDaysObj: this.getDaysArray(
        fetchingCurrentYear.format('Y'),
        this.getMonthFromString(fetchingCurrentYear.format('MMMM'))
      )
    });

    const payload = {
      start_date: this.getDaysArray(
        fetchingCurrentYear.format('Y'),
        this.getMonthFromString(fetchingCurrentYear.format('MMMM'))
      )[0].filteredCompleteDate,
      end_date: this.getDaysArray(
        fetchingCurrentYear.format('Y'),
        this.getMonthFromString(fetchingCurrentYear.format('MMMM'))
      ).at(-1).filteredCompleteDate
    };
    // localStorage.setItem('current_month', JSON.stringify(payload));
    searchParams.delete('start_date');
    searchParams.delete('end_date');
    searchParams.append('start_date', payload.start_date);
    searchParams.append('end_date', payload.end_date);

    this.props.router.navigate(`${pathname}?${searchParams.toString()}`);

    this.setState({
      currentDateAddAvailibility: payload.start_date,
      startAddDateDT: payload.start_date,
      endAddDateDT: payload.end_date
    });
    get_avalability_slots_data(payload)
      .then((response) => {
        if (response?.data?.status === 'success') {
          this.setState({ setTeacherData: response?.data }, () => {
            const startMonthDate = this.getDaysArray(
              fetchingCurrentYear.format('Y'),
              this.getMonthFromString(fetchingCurrentYear.format('MMMM'))
            )[0].filteredCompleteDate;

            this.state.setTeacherData.teacher_schedule_day.forEach((el) => {
              if (el.date === startMonthDate) {
                this.setState({
                  currentDateData: el,
                  dateClicked: moment(startMonthDate).format('D')
                });
                
              }
            });
            this.setState({
              isLoading: false
            });
          });
          this.setState({
            isLoading: false
          });
        }
      })
      .catch((e) => {
        console.log(JSON.stringify(e));
        if (e.response.status === 401) {
          removeCookie('teacher_token_1');
          localStorage.removeItem('teacherLocalChannels');
          analytics_reset();
          this.props.router.navigate('/login');
          this.setState({
            isLoading: true
          });
        }
      });
  };

  onDayClick = (date) => {
    this.setState({
      currentDateAddAvailibility: date.filteredCompleteDate,
      dateClicked: date.date
    });
    this.functionForDataManipulation(this.state.setTeacherData, date.filteredCompleteDate);
  };

  scrollDates = (x, y) => {
    document.getElementById('date').scrollBy(x, y);
  };

  editTeacherPopupBtn = () => {
    this.setState({
      editTeacherPopup: !this.state.editTeacherPopup
    });
  };

  // --------For convertng the month string to number---------//
  getMonthFromString = (mon, ini) => {
    if (ini === 'initialRender') {
      return new Date(moment().month(mon)).getMonth();
    } 
      return new Date(Date.parse(`${mon  } 1, 2012`)).getMonth() + 1;
    
  };

  // --------Function for setting the remaining month and day--------//

  getDaysArray = (year, month) => {
    const monthIndex = month - 1;
    const names = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let date = null;
    const dt = new Date();

    const currentMonth = dt.getMonth() + 1;
    const currentYear = dt.getFullYear() + 1;

    if (year !== currentYear && month !== currentMonth) {
      date = new Date(year, monthIndex);
    } else {
      date = new Date();
    }
    const result = [];
    while (date.getMonth() === monthIndex) {
      result.push({
        date: date.getDate(),
        name: names[date.getDay()],
        completeDate: new Date(date),
        filteredCompleteDate: moment(new Date(date)).format(moment.HTML5_FMT.DATE)
      });
      date.setDate(date.getDate() + 1);
    }

    return result;
  };

  slotData = (data) => {
    if (
      data.availability_crons[0].repeat_schedule === 0 &&
      data.availability_crons[0].repeat_forever === 0
    ) {
      const payload = {
        start_date: data.date_time.epoch_start_datetime,
        end_date: data.date_time.epoch_end_datetime,
        start_date_date_time: data.date_time.start_date,
        end_date_date_time: data.date_time.end_date,
        start_date_availability_chron: data.availability_crons[0].epoch_start_datetime,
        end_date_availability_chron: data.availability_crons[0].epoch_end_datetime,
        start_time: data.date_time.start_time,
        end_time: data.date_time.end_time,
        repeat_schedule: 0,
        repeat_forever: 0,
        available_sunday: 0,
        available_monday: 0,
        available_tuesday: 0,
        available_wednesday: 0,
        available_thursday: 0,
        available_friday: 0,
        available_saturday: 0,
        teacher_schedule_cron_uuid: data.availability_crons[0].teacher_schedule_cron_uuid,
        trial_only_availability: data.availability_crons[0].trial_only_availability,
        currentDate: true,
        availability_type: 'single'
      };
      this.setState({
        editPayloadData: payload,
        innerModal: true,
        editTeacherPopup: false
      });
    } else {
      this.setState({
        editAvailabilitySlotData: data,
        ChangeAvailibilityPopup: !this.state.ChangeAvailibilityPopup,
        editTeacherPopup: false
      });
    }
  };

  closeEditAvailablityPopup = () => {
    this.setState({
      innerModal: false
    });
  };

  addTeacherPopupBtn = () => {
    this.setState({
      addAvailabilityPopup: !this.state.addAvailabilityPopup
    });
  };

  closeAddAvailablityPopup = () => {
    this.setState({
      addAvailabilityPopup: false
    });
  };

  deleteAvailabilityPopupOpen = (data) => {
    this.setState({
      DeleteSlotsPopup: true,
      innerModal: false,
      deleteSlotsPopupData: data
    });
  };

  closeDeletePopup = () => {
    this.setState({
      DeleteSlotsPopup: false
    });
  };

  deleteAllAvailabilityPopup = (data, payload) => {
    this.setState({
      //   deleteAllAvailability: !this.state.deleteAllAvailability,
      deleteAllAvailabilityData: data,
      DeleteSlotsPopup: false,
      deleteAllAvailability: true,
      deleteRangeAvailablity: payload
    });
  };

  closeDeleteAllAvailability = () => {
    this.setState({
      deleteAllAvailability: false
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.setTeacherData?.teacher_schedule_day?.length !==
      this.state.setTeacherData?.teacher_schedule_day?.length
    ) {
      this.getLatestDataObj();
    }
    // console.log("this is update component", this.state.setTeacherData)
  }

  getLatestDataObj = (data) => {
    const teacherData = data || { ...this.state.setTeacherData };
    const currentData = [...this.state.currentMonthRemaningDaysObj];
    if (teacherData?.teacher_schedule_day?.length) {
      teacherData?.teacher_schedule_day.forEach((each) => {
        currentData.forEach((ele) => {
          if (each.date === ele.filteredCompleteDate) {
            ele.dayAllData = each;
          }
        });
      });
    }

    this.setState(
      {
        currentAllMonthRemaningDaysObj: currentData
      },
      () => {}
    );
    // console.log("this is updated data", this.state.currentAllMonthRemaningDaysObj);

    // Update Edit Slots function
    this.updateDataEditSlots = (data) => {
      if (data === true) {
        this.dataCalling();
        this.setState({
          innerModal: false
        });
        // window.location.reload();
      }
    };

    this.addDataEditSlots = (data) => {
      if (data === true) {
        this.dataCalling();
        this.setState({
          addAvailabilityPopup: false,
          deleteAllAvailability: false
        });
      }
    };

    // Delete Edit Slots function
    this.deleteDataEditSlots = (data) => {
      if (data === true) {
        this.dataCalling();
        this.setState({
          DeleteSlotsPopup: false
        });
      }
    };

    this.repalcementRequest = (data) => {
      if (data.allow_teacher_replacement === 1) {
        this.setState({
          replacementData: data,
          ReplacementSlotsPopup: true,
          deleteAllAvailability: false
        });
      } else if (data.allow_teacher_replacement === 0) {
        this.setState({
          replacementData: data,
          ReplacementSlotsPopup: false,
          deleteAllAvailability: false,
          cancelSession: true
        });
      }
    };

    this.deleteAllAvailability = (dt) => {
      const payload = {
        start_date: this.state.deleteRangeAvailablity.start_date,
        end_date: this.state.deleteRangeAvailablity.end_date,
        start_time: dt?.availability_crons[0].start_time,
        end_time: dt?.availability_crons[0].end_time,
        iana_timezone: 'America/Chicago',
        teacher_schedule_cron_uuid: dt?.availability_crons[0].teacher_schedule_cron_uuid
      };

      delete_avalability_slots_data(payload)
        .then((response) => {
          if (response?.data?.status == 'success') {
            this.dataCalling();
          }
        })
        .catch((e) => {
          if (e?.response?.status === 401) {
            removeCookie('teacher_token_1');
            localStorage.removeItem('teacherLocalChannels');
            analytics_reset();
            this.props.router.navigate('/login');
            props.addDataEditSlots(true);
            setIsloading(false);
          } else if (e?.response?.status === 400) {
            props.addDataEditSlots(true);
            setIsloading(false);
          }
        });
    };

    this.closeReplacementPopup = () => {
      this.setState({
        ReplacementSlotsPopup: false,
        deleteAllAvailability: true
      });
    };

    this.closeReplacementPopupSingleCall = () => {
      this.deleteAllAvailability(this.state.editAvailabilitySlotData);
      this.setState({
        ReplacementSlotsPopup: false
      });
    };
  };

  closeChangeAvailibilityPopup = () => {
    this.setState({
      cancelSession: false
    });
  };

  closeChangeAvailibilityPopupAll = () => {
    this.setState({
      ChangeAvailibilityPopup: false
    });
  };

  payloadData = (data) => {
    this.setState({
      editPayloadData: data,
      ChangeAvailibilityPopup: false,
      innerModal: true
    });
  };

  render() {
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            {this.state.editTeacherPopup && (
              <EditSlots
                closePopup={this.editTeacherPopupBtn}
                data={this.state.currentDateData}
                slotsData={(data) => this.slotData(data)}
              />
            )}
            {this.state.ChangeAvailibilityPopup && (
              <ChangeAvailibility
                payloadData={(data) => this.payloadData(data)}
                editAvailabilitySlotDataObj={this.state.editAvailabilitySlotData}
                closeChangeAvailibilityPopup={this.closeChangeAvailibilityPopupAll}
              />
            )}
            {this.state.innerModal && (
              <EditAvailability
                editPayloadData={this.state.editPayloadData}
                updateDataEditSlots={(data) => this.updateDataEditSlots(data)}
                deleteAvailability={(data) => this.deleteAvailabilityPopupOpen(data)}
                closeEditAvailablityPopup={this.closeEditAvailablityPopup}
              />
            )}
            {this.state.addAvailabilityPopup && (
              <AddAvailability
                closeAddAvailablityPopup={this.closeAddAvailablityPopup}
                addDataEditSlots={(data) => this.addDataEditSlots(data)}
                currentDateAddAvailibility={this.state.currentDateAddAvailibility}
              />
            )}
            {this.state.DeleteSlotsPopup && (
              <DeleteSlots
                deleteDataEditSlots={(data) => this.deleteDataEditSlots(data)}
                deleteSlotsPopupData={this.state.deleteSlotsPopupData}
                closeDeletePopup={this.closeDeletePopup}
                deleteAllAvailability={(data, payload) =>
                  this.deleteAllAvailabilityPopup(data, payload)
                }
              />
            )}

            {this.state.deleteAllAvailability && (
              <DeleteAvailabilitySlots
                deleteDataEditSlots={(data) => this.deleteDataEditSlots(data)}
                repalcementRequest={(data) => this.repalcementRequest(data)}
                deleteAllAvailabilityData={this.state.deleteAllAvailabilityData}
                closeDeleteAllAvailability={this.closeDeleteAllAvailability}
                deleteAllAvailability={(data, payload) =>
                  this.deleteAllAvailabilityPopup(data, payload)
                }
              />
            )}
            {this.state.ReplacementSlotsPopup && (
              <ReplacementSlots
                closeReplacementPopup={this.closeReplacementPopup}
                replacementData={this.state.replacementData}
                closeReplacementPopupSingleCall={this.closeReplacementPopupSingleCall}
              />
            )}
            {this.state.cancelSession && (
              <CancelSession
                closeChangeAvailibilityPopup={() => this.closeChangeAvailibilityPopup()}
              />
            )}

            <div className="shadow-mytboxshadow border border-mytgrey-subBorder rounded-lg bg-white">
              <div className="flex justify-between items-start pt-25px pl-60px pr-60px">
                <div>
                  <div className="font-bold text-xl font-inter text-mytTeacherAvailableText">
                    Your{' '}
                    {this.state.currentDateData.date === moment(new Date()).format('YYYY-MM-DD')
                      ? 'today’s'
                      : moment(this.state.currentDateData.date).format('MMM, DD')}{' '}
                    availability is {this.state.currentDateData?.total_availability}
                  </div>
                  <div className="flex justify-start items-center text-base mt-2.5">
                    <div className="font-extrabold mr-1">Tip: </div>
                    <div className="text-mytgrey-default">
                      Increase the available time to get more classes
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-orange mr-2.5 h-38px text-13.5px"
                    onClick={this.addTeacherPopupBtn}
                  >
                    Add Availability
                  </button>
                  <button
                    className="btn-white-default h-38px text-13.5px"
                    onClick={this.editTeacherPopupBtn}
                  >
                    Edit Availability
                  </button>
                </div>
              </div>
              <div className="mt-5 mb-4 pl-60px pr-60px">
                <div className={`flex justify-center items-center `}>
                  <button
                    className={`cursor-pointer ${
                      moment(new Date()).format('MMMM') === this.month()
                        ? 'opacity-50 pointer-events-none'
                        : ''
                    }`}
                    disabled={moment(new Date()).format('MMMM') === this.month()}
                    onClick={(e) => {
                      this.onPrev();
                    }}
                  >
                    {/* disabled={this.state.disableLeft} */}
                    <img
                      src="https://images.myyogateacher.com/web_images/chevron-down-left-teacher-site.svg"
                      alt="arrow-left"
                    />
                  </button>
                  {!this.state.showMonthTable && (
                    <div className="text-base font-medium text-mytTeacherAvailableText ml-3 mr-3">
                      {this.month()}, {this.year()}
                    </div>
                  )}
                  <button
                    className="cursor-pointer"
                    onClick={(e) => {
                      this.onNext();
                    }}
                  >
                    <img
                      src="https://images.myyogateacher.com/web_images/chevron-down-right-teacher-site.svg"
                      alt="right-arrow"
                    />
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-start pl-60px pr-60px border-b">
                <img
                  className="cursor-pointer"
                  onClick={(e) => {
                    this.scrollDates(-550, 0);
                  }}
                  src="https://images.myyogateacher.com/web_images/arrow_left_teacher_ab.svg"
                  alt="left-arrow"
                />

                <div
                  className="flex justify-start items-center overflow-x-scroll overflow-y-hidden dayScrollSection duration-75 pl-1.5"
                  id="date"
                >
                  {this.state.currentMonthRemaningDaysObj.length &&
                    this.state.currentMonthRemaningDaysObj.map((d) => (
                      <div
                        key={d.date}
                        className="w-24 flex justify-center items-center flex-col mr-4 activeDateSlot"
                      >
                        {/* <div className="w-10 h-10 flex justify-center items-center flex-col border rounded-full cursor-pointer" onClick={e => { this.onDayClick(d.key); }}> */}
                        {/* <div className={`w-10 h-10 flex justify-center items-center flex-col border rounded-full cursor-pointer ${this.state.currentDateData.date === d.filteredCompleteDate ? 'text-white bg-dark_purple' : 'text-black bg-white'}  ${d.date === this.state.dateClicked ? 'text-white bg-dark_purple' : 'text-black bg-white'}`} onClick={e => { this.onDayClick(d); }}> */}
                        {/* <div className={`w-10 h-10 flex justify-center items-center flex-col border rounded-full cursor-pointer ${this.state.currentDateData['date'] === d?.date ? 'text-white bg-dark_purple' : 'text-black bg-white'} ${d.date === this.state.dateClicked ? 'text-white bg-dark_purple' : 'text-black bg-white'}`} onClick={e => { this.onDayClick(d); }}> */}
                        <div
                          className={`w-10 h-10 flex justify-center items-center flex-col border rounded-full cursor-pointer ${
                            d.date == this.state.dateClicked
                              ? 'text-white bg-dark_purple'
                              : 'text-black bg-white'
                          }`}
                          onClick={(e) => {
                            this.onDayClick(d);
                          }}
                        >
                          <div>{d.date}</div>
                        </div>
                        <div className="text-m_gray font-normal text-11 mt-1 mb-2">{d.name}</div>
                        <div
                          className={`flex justify-center items-center  ${
                            d.dayAllData?.show_green ||
                            d.dayAllData?.show_grey ||
                            d.dayAllData?.show_red === 1
                              ? 'mb-13px'
                              : 'mb-18px'
                          }`}
                        >
                          {/* {d.dayAllData?.show_green} */}
                          <div
                            className={`bg-mytgreen w-6px h-6px rounded-full mr-0.5 ${
                              d.dayAllData?.show_green === 1 ? 'block' : 'hidden'
                            }`}
                           />
                          <div
                            className={`bg-mytgrey-lite w-6px h-6px rounded-full ml-0.5 ${
                              d.dayAllData?.show_grey === 1 ? 'block' : 'hidden'
                            }`}
                           />
                          <div
                            className={`bg-mytRedText w-6px h-6px rounded-full ml-0.5 ${
                              d.dayAllData?.show_red === 1 ? 'block' : 'hidden'
                            }`}
                           />
                          {/* <div className="bg-mytTeacherAvailabilitySlotsBg2 w-2 h-2 rounded-full ml-0.5"></div> */}
                        </div>
                        {/* <img className={`${d.date === this.state.dateClicked ? 'block' : 'hidden'}`} src="https://images.myyogateacher.com/web_images/selectedDateBorder.svg" alt="border" /> */}
                      </div>
                    ))}
                </div>
                <img
                  className="cursor-pointer"
                  onClick={(e) => {
                    this.scrollDates(550, 0);
                  }}
                  src="https://images.myyogateacher.com/web_images/arrow_right_teacher_ab.svg"
                  alt="right-border"
                />
              </div>
              {this.state.currentDateData ? (
                <AvailabilityList data={this.state.currentDateData} />
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

const SlotsDateWithRouter = withRouter(SlotsDate);

export default SlotsDateWithRouter;
