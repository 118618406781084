import React, { Component } from 'react';
import moment from 'moment';
import CustomTextarea from '../../util_components/custom_text_area';
import CustomInput from '../../util_components/custom_input';
import CustomButton from '../../util_components/custom_button';
import CustomAlert from '../../util_components/custom_alert';
import CustomDropdown from '../../util_components/custom_dropdown';
import CustomDatePicker from '../../util_components/custom_date_picker';
import { how_did_you_hear_about_us } from '../../../constants';

export const render_other_hear_us = details => {
	if (typeof details?.type !== 'undefined' && details?.type !== '') {
		if (details?.type === 'Internet Search') {
			return (
				<div>
					<div>
						<span className="profile-other-hear-blur"> Type: </span>
						{details?.type}
					</div>
					<div>
						<span className="profile-other-hear-blur"> Search term :</span> {details?.value}
					</div>
				</div>
			);
		} else if (details?.type === 'Event') {
			return (
				<div>
					<div>
						<span className="profile-other-hear-blur"> Type: </span>
						{details?.type}
					</div>
					<div>
						<span className="profile-other-hear-blur"> Event name :</span> {details?.value}
					</div>
					<div>
						<span className="profile-other-hear-blur"> Event date : </span>
						{details?.date}
					</div>
				</div>
			);
		} else if (details?.type === 'Friend') {
			return (
				<div>
					<div>
						<span className="profile-other-hear-blur"> Type:</span>
						{details?.type}
					</div>
					<div>
						<span className="profile-other-hear-blur"> Name of friend :</span> {details?.value}
					</div>
				</div>
			);
		} else if (details?.type === 'myYogaTeacher Student') {
			return (
				<div>
					<div>
						<span className="profile-other-hear-blur"> Type:</span>
						{details?.type}
					</div>
					<div>
						<span className="profile-other-hear-blur"> Name of student :</span> {details?.value}
					</div>
				</div>
			);
		} else if (details?.type === 'myYogaTeacher Teacher') {
			return (
				<div>
					<div>
						<span className="profile-other-hear-blur"> Type:</span>
						{details?.type}
					</div>
					<div>
						<span className="profile-other-hear-blur"> Name of teacher :</span> {details?.value}
					</div>
				</div>
			);
		} else if (details?.type === 'Other') {
			return (
				<div>
					<div>
						<span className="profile-other-hear-blur"> Type:</span>
						{details?.type}
					</div>
					<div>
						<span className="profile-other-hear-blur"> Details :</span> {details?.value}
					</div>
				</div>
			);
		}
		return (
			<div>
				<div>
					<span className="profile-other-hear-blur"> Type:</span>
					{details?.type}
				</div>
			</div>
		);
	} else return <div>--</div>;
};

export const render_other_all_details = details => {
	const intro_to_students = details?.intro_to_students?.replace(/\u21B5/g, '\n')?.split('\n');
	// const how_did_you_hear_about_us = details.how_did_you_hear_about_us.replace(/\u21B5/g, '\n').split('\n');
	return (
		<div>
			<div className="profile-basic-detail-values">
				<div className="profile-basic-detail-index other-detail-index">How did you hear about us:</div>
				<div className="profile-other-detail-values">{render_other_hear_us(details?.how_did_you_hear_about_us)}</div>
			</div>
			<div className="profile-basic-detail-values">
				<div className="profile-basic-detail-index other-detail-index">Introduction to students:</div>
				<div className="profile-basic-detail-value">
					{details?.intro_to_students !== ''
						? intro_to_students?.map((item, index) => (
								<span key={index + item}>
									{item}
									<br />
								</span>
						  ))
						: '--'}
				</div>
			</div>
		</div>
	);
};

export class RenderOtherEdit extends Component {
	state = {
		blog_links: '',
		confirm_open: false
	};
	onEditorStateChange = (editorState, key) => {
		this.props.handleEditorChange(editorState, 'other_details', key);
	};
	handle_story_change = (index, value) => {
		let stories = [...this.props.details.stories];
		stories[index].url = value;
		this.props.handleChange(stories, 'other_details', 'stories');
	};
	add_story = () => {
		const obj = {
			title: '',
			image: '',
			url: ''
		};
		let stories = [...this.props.details.stories];
		stories.push(obj);
		this.props.handleChange(stories, 'other_details', 'stories');
	};
	remove_story_confirm = index => {
		this.setState({ confirm_open: true, delete_index: index });
	};
	remove_story = () => {
		const index = this.state.delete_index;
		let stories = [...this.props.details.stories];
		stories.splice(index, 1);
		this.props.handleChange(stories, 'other_details', 'stories');
		this.setState({ confirm_open: false });
	};
	render_links = () => {
		return (
			<div>
				<div className="profile-edit-rich-text">Story links:</div>
				{this.props.details.stories.map((story, index) => {
					return (
						<div className="profile-edit-text-area" key={index + story.title}>
							<div className="profile-other-story-edit">
								<CustomInput
									value={story.url}
									label={`Link ${index + 1}*`}
									handleChange={e => this.handle_story_change(index, e.target.value)}
									width="700px"
								/>
								<img
									src="https://images.myyogateacher.com/teacher_web_app/ic_delete_red.png"
									alt="close"
									onClick={() => this.remove_story_confirm(index)}
								/>
							</div>
							{story.url.indexOf('blog.myyogateacher.com') === -1 ? (
								<div className="profile-other-error">* The url should be a valid myYogaTeacher blog url</div>
							) : null}
						</div>
					);
				})}
				<div className="profile-other-add-story">
					{' '}
					<CustomButton onClick={this.add_story} label="Add story" component="secondary" />
				</div>
			</div>
		);
	};
	handle_hear_us_dropdown = e => {
		const details = { ...this.props.details.how_did_you_hear_about_us };
		details.type = e;
		details.value = '';
		details.date = null;
		this.props.handleChange(details, 'other_details', 'how_did_you_hear_about_us');
	};
	handle_hear_us_text = e => {
		const details = { ...this.props.details.how_did_you_hear_about_us };
		details.value = e.target.value;
		this.props.handleChange(details, 'other_details', 'how_did_you_hear_about_us');
	};
	handle_hear_us_date = e => {
		const details = { ...this.props.details.how_did_you_hear_about_us };
		details.date = moment(e).format('YYYY-MM-DD');
		this.props.handleChange(details, 'other_details', 'how_did_you_hear_about_us');
	};
	render_hear_inner = () => {
		const details = this.props?.details?.how_did_you_hear_about_us;
		const detail_text = typeof details?.value === 'undefined' ? '' : details.value;
		if (details?.type === 'Internet Search') {
			return (
				<div className="profile-edit-text-area profile-other-hear-edit">
					<CustomInput
						value={detail_text}
						label={'Search term*'}
						handleChange={this.handle_hear_us_text}
						width="700px"
					/>
				</div>
			);
		} else if (details?.type === 'Event') {
			return (
				<div className="profile-edit-text-area profile-other-hear-edit profile-other-hear-event">
					<CustomInput
						value={detail_text}
						label={'Name of Event*'}
						handleChange={this.handle_hear_us_text}
						width="500px"
					/>
					<div className="profile-other-hear-edit">
						<div className="profile-basic-dropdown-label">Date Of Event*</div>
						<CustomDatePicker
							startDate={
								typeof details.date === 'undefined' || details.date === null
									? new Date()
									: moment(details.date, 'YYYY-MM-DD').toDate()
							}
							// startDate={""}
							handleChange={this.handle_hear_us_date}
							component="profile"
						/>
					</div>
				</div>
			);
		} else if (details?.type === 'Friend') {
			return (
				<div className="profile-edit-text-area profile-other-hear-edit">
					<CustomInput
						value={detail_text}
						label={'Name of Friend*'}
						handleChange={this.handle_hear_us_text}
						width="700px"
					/>
				</div>
			);
		} else if (details?.type === 'myYogaTeacher Student') {
			return (
				<div className="profile-edit-text-area profile-other-hear-edit">
					<CustomInput
						value={detail_text}
						label={'Name of Student*'}
						handleChange={this.handle_hear_us_text}
						width="700px"
					/>
				</div>
			);
		} else if (details?.type === 'myYogaTeacher Teacher') {
			return (
				<div className="profile-edit-text-area profile-other-hear-edit">
					<CustomInput
						value={detail_text}
						label={'Name of Teacher*'}
						handleChange={this.handle_hear_us_text}
						width="700px"
					/>
				</div>
			);
		} else if (details?.type === 'Other') {
			return (
				<div className="profile-edit-text-area profile-other-hear-edit">
					<CustomInput
						value={detail_text}
						label={'Specify Details*'}
						handleChange={this.handle_hear_us_text}
						width="700px"
					/>
				</div>
			);
		} else return null;
	};
	render_how_did_hear = () => {
		const details = this.props?.details?.how_did_you_hear_about_us;
		return (
			<div>
				<div className="profile-edit-rich-text">How did you hear about us:*</div>
				<div className="profile-edit-text-area">
					<CustomDropdown
						value={details?.type}
						handleChange={this.handle_hear_us_dropdown}
						drop_down_list={how_did_you_hear_about_us}
						width="400px"
					/>
				</div>
				{this.render_hear_inner()}
			</div>
		);
	};
	render() {
		return (
			<div className="profile-details-edit-text">
				{this.state.confirm_open ? (
					<CustomAlert
						bodyText="Are you sure you want to delete?"
						cancelFunc={this.close_confirm}
						confirmFunc={this.remove_story}
					/>
				) : null}
				<div>
					{/* <div className="profile-edit-rich-text">{covert_to_pascal_with_spaces('how_did_you_hear_about_us')}:</div>
					<div className="profile-edit-text-area">
						<CustomTextarea
							value={this.props.details.how_did_you_hear_about_us}
							handleChange={e => this.props.handleChange(e.target.value, 'other_details', 'how_did_you_hear_about_us')}
						/>
					</div> */}
					{/* {this.render_links()} */}
					{this.render_how_did_hear()}
					<div className="profile-edit-rich-text">Introduction to students:</div>
					<div className="profile-edit-text-area">
						<CustomTextarea
							value={this.props.details.intro_to_students}
							handleChange={e => this.props.handleChange(e.target.value, 'other_details', 'intro_to_students')}
						/>
					</div>
				</div>
			</div>
		);
	}
}
