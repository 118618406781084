export const weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const calendar_months_short = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const address_proof_list = ['Aadhar Card', 'Passport', 'Gas Bill', 'Telephone Bill', 'Other'];

export const gender_list = ['MALE', 'FEMALE'];

export const marital_list = ['MARRIED', 'UNMARRIED'];

export const yoga_teaching_list = [
	'Gentle',
	'Vinyasa',
	'Power yoga',
	'Hatha/ hatha flow',
	'Bikram/ hot',
	'Ashtanga',
	'Yin',
	'Restorative',
	'Iyengar',
	'Yoga fusion',
	'Kundalini',
];

export const how_did_you_hear_about_us = [
	'Internet Search',
	'Event',
	'Friend',
	'Facebook Ads',
	'myYogaTeacher Student',
	'myYogaTeacher Teacher',
	'Got an email from myYogaTeacher',
	'Other',
];

const weekDaysShortTitle = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

export const yoga_levels = ['Beginner', 'Intermediate', 'Advance'];
export const teaching_years = [
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'10',
	'11',
	'12',
	'13',
	'14',
	'15',
	'16',
	'17',
	'18',
	'19',
	'20',
	'21',
	'22',
	'23',
	'24',
	'25',
	'26',
	'27',
	'28',
	'29',
	'30',
];
export const profile_compartments = [
	'Basic',
	'Yoga',
	// "App",
	// "Social",
	// "Documents",
	// "Bank Account",
	'Other',
];

export const teacher_goals = [
	'Reduce Stress & Anxiety',
	'Improve Strength & Flexibility',
	'Manage Pain',
	'Mindfulness & Inner Calm',
	'Reduce Weight',
	'Get into better shape',
	// 'Some other - specify'
];

export const basic_details_indices = [
	'first_name',
	'last_name',
	// "middle_name",
	'email',
	'phone_personal',
	'gender',
	'street',
	'city',
	'state',
	'zipcode',
	'marital_status',
	// "degree",
	'date_of_birth',
	// "teaching_address"
];

export const yoga_details_indices = [
	// "life_motto",
	// "yoga_philosophy",
	'years_of_yoga_teaching_experience',
	'years_of_yoga_practise',
	'yoga_types_teaching',
	'goals',
	'certificates',
	// "teaching_experience_years",
	// "workshops_experience"
];

export const social_details_indices = [
	'facebook_profile',
	'instagram_profile',
	'youtube_profile',
	'linkedin_profile',
	'twitter_profile',
	'personal_blog',
	'personal_website',
];

export const app_details_indices = ['yoga_types_teaching'];

export const bank_details_indices = ['bank_account_number', 'account_type', 'bank_branch', 'bank_ifsc_code'];

export const other_details_indices = [
	// "non_yoga_work_experience",
	// "hobbies",
	'how_did_you_hear_about_us',
	'intro_to_students',
	'stories',
	// "family_details",
	// "approx_hours_availability",
	// "language_spoken",
	// "other_details"
];

export const docs_details_indices = [
	// "address_proof_document",
	// "address_proof_document_url",
	// "id_proof_document",
	// "id_proof_document_url",
	'resume_document_url',
	'video_url',
	'teacher_edit_auditing',
];

export const cancel_reasons = [
	'Internet Outage - weather related',
	'Internet Outage',
	'Power Outage - weather related',
	'Power Outage',
	'Feeling Unwell',
	'Medical Emergency',
	'Family Emergency',
	'Hardware Issues',
	'Other',
];

export const teacher_details_null_values = {
	teacher_uuid: '',
	total_earnings: '',
	average_rating: '',
	teaching_style: '',
	yoga_level_targets: '',
	notification_preference: '',
	yoga_types_practiced: '',
	place_of_birth: '',
	teaching_address: '',
	facebook_profile: '',
	instagram_profile: '',
	linkedin_profile: '',
	twitter_profile: '',
	youtube_profile: '',
	personal_website: '',
	business_website: '',
	personal_blog: '',
	professional_blog: '',
	teacher_known_for: '',
	video_url: '',
	first_name: '',
	middle_name: '',
	last_name: '',
	email: '',
	email_verified: '',
	phone_personal: '',
	date_of_birth: '',
	ip_address: '',
	city: '',
	state: '',
	country: '',
	street: '',
	zipcode: '',
	lat: '',
	long: '',
	gender: '',
	marital_status: '',
	profile_photo: '',
	device: '',
	user_agent: '',
	credits: '',
	credits_currency: '',
	language_spoken: '',
	approx_hours_availability: '',
	about_me: '',
	degree: '',
	yoga_philosophy: '',
	family_details: '',
	id_proof_document: '',
	id_proof_document_url: '',
	address_proof_document: '',
	address_proof_document_url: '',
	life_motto: '',
	yoga_inspiration: '',
	teaching_experience: '',
	workshops_experience: '',
	non_yoga_work_experience: '',
	intro_to_students: '',
	hobbies: '',
	other_details: '',
	how_did_you_hear_about_us: '',
	bank_account_number: '',
	account_type: '',
	bank_branch: '',
	bank_ifsc_code: '',
	resume_document_url: '',
	years_of_yoga_teaching_experience: '',
	years_of_yoga_practise: '',
	yoga_types_teaching: [],
	certificates: [],
	goals: [],
	stories: [],
};

export const indian_states = [
	'Andaman and Nicobar Islands',
	'Andhra Pradesh',
	'Arunachal Pradesh',
	'Assam',
	'Bihar',
	'Chandigarh',
	'Chhattisgarh',
	'Dadra and Nagar Haveli',
	'Daman and Diu',
	'Delhi',
	'Goa',
	'Gujarat',
	'Haryana',
	'Himachal Pradesh',
	'Jammu and Kashmir',
	'Jharkhand',
	'Karnataka',
	'Kerala',
	'Lakshadweep',
	'Madhya Pradesh',
	'Maharashtra',
	'Manipur',
	'Meghalaya',
	'Mizoram',
	'Nagaland',
	'Odisha',
	'Puducherry',
	'Punjab',
	'Rajasthan',
	'Sikkim',
	'Tamil Nadu',
	'Telengana',
	'Tripura',
	'Uttar Pradesh',
	'Uttarakhand',
	'West Bengal',
];

export const virbant_community_images = [
	{
		imageUrl: 'https://images.myyogateacher.com/Teacher-Page/IMG_1.png',
		imageUrlOriginal: 'https://images.myyogateacher.com/Teacher-Page/IMG_1_ORIGINAL.JPG',
	},
	{
		imageUrl: 'https://images.myyogateacher.com/Teacher-Page/IMG_2.png',
		imageUrlOriginal: 'https://images.myyogateacher.com/Teacher-Page/IMG_2_ORIGINAL.JPG',
	},
	{
		imageUrl: 'https://images.myyogateacher.com/Teacher-Page/IMG_4.png',
		imageUrlOriginal: 'https://images.myyogateacher.com/Teacher-Page/IMG_4_ORIGINAL.jpg',
	},
	{
		imageUrl: 'https://images.myyogateacher.com/Teacher-Page/IMG_3.png',
		imageUrlOriginal: 'https://images.myyogateacher.com/Teacher-Page/IMG_3_ORIGINAL.jpg',
	},
	{
		imageUrl: 'https://images.myyogateacher.com/Teacher-Page/IMG_7.png',
		imageUrlOriginal: 'https://images.myyogateacher.com/Teacher-Page/IMG_7_ORIGINAL.JPG',
	},
	{
		imageUrl: 'https://images.myyogateacher.com/Teacher-Page/IMG_6.png',
		imageUrlOriginal: 'https://images.myyogateacher.com/Teacher-Page/IMG_6_ORIGINAL.jpg',
	},
	{
		imageUrl: 'https://images.myyogateacher.com/Teacher-Page/IMG_5.png',
		imageUrlOriginal: 'https://images.myyogateacher.com/Teacher-Page/IMG_5_ORIGINAL.JPG',
	},
];

export const teachers_say_list = [
	{
		name: 'Kanica',
		experience: '12+ Years',
		profile_pic: 'https://images.myyogateacher.com/Teacher-Page/kanicas-image.png',
		videoUrl: 'https://player.vimeo.com/video/348148647',
	},
	{
		name: 'Rohan',
		experience: '6+ Years',
		profile_pic: 'https://images.myyogateacher.com/profile_pics/teachers/Rohan-image.png',
		videoUrl: 'https://player.vimeo.com/video/348149686',
	},
];

export const target_audience_names = [
	{ value: 'Beginner', label: 'Beginner' },
	{ value: 'Intermediate', label: 'Intermediate' },
	{ value: 'Advance', label: 'Advance' },
];

export const backpian_client_session_status = [
	'Client cancelled the session',
	'Coach cancelled the session',
	'Care team cancelled the session',
	'Client and Coach missed the session',
];

export const session_status_value = [
    'ACTIVE',
    'SCHEDULED',
    'STUDENT_NO_SHOW',
    'STUDENT_AND_TEACHER_NO_SHOW',
    'CANCELLED_BY_TEACHER',
    'CANCELLED_BY_STUDENT',
    'CANCELLED',
    'TEACHER_NO_SHOW'
]
