import { createSelector } from 'reselect';
export const teacher_details_all = (state) => state.profile.teacher_details;
const basic_details_indices = (state) => state.profile.basic_details_indices;
const yoga_details_indices = (state) => state.profile.yoga_details_indices;
const social_details_indices = (state) => state.profile.social_details_indices;
const other_details_indices = (state) => state.profile.other_details_indices;
const docs_details_indices = (state) => state.profile.docs_details_indices;
const bank_details_indices = (state) => state.profile.bank_details_indices;
const app_details_indices = (state) => state.profile.app_details_indices;

export const basic_details_selector = createSelector(basic_details_indices, teacher_details_all, (keys, teacher) =>
	keys.reduce((obj, key) => ({ ...obj, [key]: teacher[key] }), {})
);

export const yoga_details_selector = createSelector(yoga_details_indices, teacher_details_all, (keys, teacher) => {
	return keys.reduce((obj, key) => ({ ...obj, [key]: teacher[key] }), {});
});

export const social_details_selector = createSelector(social_details_indices, teacher_details_all, (keys, teacher) =>
	keys.reduce((obj, key) => ({ ...obj, [key]: teacher[key] }), {})
);

export const other_details_selector = createSelector(other_details_indices, teacher_details_all, (keys, teacher) =>
	keys.reduce((obj, key) => ({ ...obj, [key]: teacher[key] }), {})
);

export const docs_details_selector = createSelector(docs_details_indices, teacher_details_all, (keys, teacher) =>
	keys.reduce((obj, key) => ({ ...obj, [key]: teacher[key] }), {})
);

export const bank_details_selector = createSelector(bank_details_indices, teacher_details_all, (keys, teacher) =>
	keys.reduce((obj, key) => ({ ...obj, [key]: teacher[key] }), {})
);

export const app_details_selector = createSelector(app_details_indices, teacher_details_all, (keys, teacher) =>
	keys.reduce((obj, key) => ({ ...obj, [key]: teacher[key] }), {})
);
