import React, { useState, useEffect } from 'react'
import WhiteBox from './CommonComponents/WhiteBox'
import { HeadText } from './StudentProfileDetails'
import { DocIcon } from './svg'
import HumanSkeletonDummy from './HumanSkeletonDummy/HumanSkeletonDummy'
import { capitalizeFirstLetter } from '../../util_functions'


export function AboutMeText(props) {
    // Check if props.text is a valid string before rendering it
    const textToRender = typeof props.text === 'string' ? props.text : '';

    return (
        <div className={` ${props.classnames ? props.classnames : ''} text-xs leading-15px text-black`}>
            {textToRender}
        </div>
    );
}

export function PlusMore(props) {
    return (
        <div className='text-xs leading-22px font-semibold text-mytgrey-lite'>{props.text}</div>
    )
}

export function AboutImg(
    {
        heightFeet,
        heightInches,
        weight,
        bodyPartsSelected,
    }
) {

    const bodyPartsLength = bodyPartsSelected?.length;
    const checkPart = (...parts) => {
        return parts.some(part => bodyPartsSelected?.includes(part));
    }

    return (
        <div className='absolute flex flex-col items-end left-60px -top-3px'>
            <div className='h-310px'>
                <HumanSkeletonDummy
                    neck={checkPart('neck')}
                    leftShoulder={checkPart('left shoulder')}
                    rightShoulder={checkPart('right shoulder')}
                    midBack={checkPart('mid back')}
                    leftLowerBack={checkPart('left lower back')}
                    midLowerBack={checkPart('mid lower back')}
                    rightLowerBack={checkPart('right lower back')}
                    rightHip={checkPart('right hip')}
                    leftHip={checkPart('left hip')}
                    rightLeg={checkPart('right leg')}
                    leftLeg={checkPart('left leg')}
                    leftKnee={checkPart('left knee')}
                    rightKnee={checkPart('right knee')}
                    bodyPartsLength={bodyPartsLength}
                />
            </div>
            <div className='flex gap-10px text-12px font-normal text-mytgrey-subText mt-5px w-max zm:pr-15px md:pr-30px'>
                <div>Height - {heightFeet} ft {heightInches} in</div>
                <div>Weight - {weight} lb</div>
            </div>
        </div>
    )
}

export function Reports({ text, filePath }) {
    return (
        <a href={filePath} target="_blank" rel="noopener noreferrer" >
            <div className='flex items-center bg-white border border-solid border-bordergrey shadow-mytboxshadow rounded-lg pr-11px py-8.68px pl-12.68px my-5px w-fit cursor-pointer'>
                <DocIcon />
                <div className='text-xs leading-17px text-black font-medium ml-5px'>{text}</div>
            </div>
        </a>
    )
}

const AboutMe = (props) => {
    const clientGender = capitalizeFirstLetter(props?.data?.gender);
    const clientAge = props?.data?.age;
    const clientGenderAndAge = `${Number.isNaN(clientGender) || clientGender === null ? 'N/A' : `${clientGender}, ${clientAge} years old`}`;
    const clientBackPainDuration = capitalizeFirstLetter(props?.data?.back_pain_duration);
    const clientPainLevel = `${props.data?.back_pain_intensity}/10`;
    const clientHeightInches = props.data?.height_inches;
    const clientHeightFeet = props.data?.height_feet;
    const clientWeight = props.data?.weight;
    const diagnosisFromDoctor = capitalizeFirstLetter(props?.data?.back_pain_doctor_diagnosis);
    const otherMedicalCondition = capitalizeFirstLetter(props?.data?.back_pain_doctor_other_diagnosis);
    const painTrigger = capitalizeFirstLetter(props?.data?.back_pain_trigger);
    const reports = props.data?.back_pain_reports?.map((report) => { return report });
    const backPainLocation = props.data?.back_pain_location?.map((locate) => { return locate });

    useEffect(() => {
        convertArrayToString();
    }, [])

    const convertArrayToString = (arr) => {
        if (arr?.length === 0) return '';
        const lastItem = arr?.pop();
        const joinedString = arr?.join(', ');
        if (arr?.length > 1) {
            return `${joinedString} & ${lastItem}`;
        } else {
            return lastItem;
        }
    };
    const backPainLocationString = capitalizeFirstLetter(convertArrayToString(backPainLocation));

    const [nextReports, setNextReports] = useState(2);
    const [isReadMoreClicked, setIsReadMoreClicked] = useState(false);

    const handleMoreButton = () => {
        setNextReports(nextReports + 4);
    }

    return (
        <>
            {
                props.loading ?
                    console.log("Loading...")
                    :
                    <>
                        <HeadText
                            text="About me"
                            classnames="mb-17px" />
                        <WhiteBox>
                            <div className='flex justify-between zm:flex-col midScreen:flex-row gap-25px relative min-h-325px zm:relative'>
                                <div className='midScreen:pt-15px zm:pt-60px'>
                                    <AboutMeText
                                        text="Pain Area"
                                        classnames="font-bold" />
                                    <AboutMeText
                                        text={backPainLocationString}
                                        classnames="font-normal mt-0.5 mb-10 text-wrap w-108px" />
                                    <AboutMeText
                                        text="Pain level"
                                        classnames="font-bold" />
                                    <AboutMeText
                                        text={clientPainLevel}
                                        classnames="font-normal mt-0.5 mb-10" />
                                    <AboutMeText
                                        text="Pain duration"
                                        classnames="font-bold" />
                                    <AboutMeText
                                        text={clientBackPainDuration}
                                        classnames="font-normal mt-0.5 mb-10" />
                                    {
                                        painTrigger ?
                                            <div className='zm:hidden md:block'>
                                                <AboutMeText
                                                    text="Pain trigger:"
                                                    classnames="font-bold zm:pt-35px md:pt-0" />
                                                <div className='text-12px font-normal leading-15px text-black mt-0.5 w-249px'>
                                                    <div className={`${isReadMoreClicked ? '' : 'ellipsisStyle whitespace-nowrap'}`}>
                                                        {painTrigger}
                                                    </div>
                                                    {
                                                        painTrigger?.length >= 196 ?
                                                            isReadMoreClicked ?
                                                                null
                                                                :
                                                                <span className='text-xs leading-22px font-semibold text-mytgrey-lite cursor-pointer' onClick={() => setIsReadMoreClicked(true)}>Read more</span>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                    }

                                </div>
                                <AboutImg
                                    heightFeet={clientHeightFeet}
                                    heightInches={clientHeightInches}
                                    weight={clientWeight}
                                    bodyPartsSelected={props.data?.back_pain_location?.map((result) => { return result })}
                                />
                                <div className='midScreen:ml-2.5 midScreen:w-265px'>
                                    <AboutMeText
                                        text={clientGenderAndAge}
                                        classnames="font-normal mb-10 text-greyText zm:absolute midScreen:static -top-15px left-160px zm:w-max md:w-auto" />
                                    <div className='midScreen:ml-2.5'>
                                    {
                                        painTrigger ?
                                            <div className=' zm:block midScreen:hidden zm:mb-30px mt-60px'>
                                                <AboutMeText
                                                    text="Pain trigger:"
                                                    classnames="font-bold" />
                                                <div className='text-12px font-normal leading-15px text-black mt-0.5 w-249px'>
                                                    <div className={`${isReadMoreClicked ? '' : 'ellipsisStyle whitespace-nowrap'}`}>
                                                        {painTrigger}
                                                    </div>
                                                    {
                                                        painTrigger?.length >= 196 ?
                                                            isReadMoreClicked ?
                                                                null
                                                                :
                                                                <span className='text-xs leading-22px font-semibold text-mytgrey-lite cursor-pointer' onClick={() => setIsReadMoreClicked(true)}>Read more</span>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                        {
                                            diagnosisFromDoctor ?
                                                <>
                                                    <AboutMeText
                                                        text="Diagnosis from Doctor"
                                                        classnames="font-bold" />
                                                    <AboutMeText
                                                        text={diagnosisFromDoctor}
                                                        classnames="font-normal mt-0.5 mb-10" />
                                                </>
                                                :
                                                null
                                        }

                                        {
                                            otherMedicalCondition ?
                                                <>
                                                    <AboutMeText
                                                        text="Any other medical conditions"
                                                        classnames="font-bold" />
                                                    <AboutMeText
                                                        text={otherMedicalCondition}
                                                        classnames="font-normal mt-0.5 mb-10" />
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            reports?.length > 0 ?
                                                <>
                                                    <AboutMeText
                                                        text="Reports"
                                                        classnames="font-bold" />
                                                    {
                                                        reports?.slice(0, nextReports).map((report, index) => {
                                                            return (
                                                                <Reports
                                                                    key={index}
                                                                    text={report.file_name}
                                                                    filePath={report.file_url}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    {
                                                        nextReports >= reports?.length ?
                                                            null :
                                                            <div className='cursor-pointer' onClick={handleMoreButton}>
                                                                <PlusMore text={`+${reports?.length - 2} more`} />
                                                            </div>
                                                    }
                                                </>
                                                :
                                                null
                                        }

                                    </div>
                                </div>
                            </div>
                        </WhiteBox>
                    </>
            }
        </>
    )
}

export default AboutMe;

