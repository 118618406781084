import React, { Component } from 'react';
import './footer.css';

class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<div className="footer-cp">© 2018 myYogaTeacher, Inc.</div>
				<div className="footer-buttons">
					<a href="https://myyogateacher.com/privacypolicy" target="_blank" rel="noopener noreferrer">
						PRIVACY POLICY
					</a>
					<a href="https://myyogateacher.com/termsofuse" target="_blank" rel="noopener noreferrer">
						TERMS AND CONDITIONS
					</a>
					<a href="https://myyogateacher.com/contactus" target="_blank" rel="noopener noreferrer">
						HELP
					</a>
					<a href="https://myyogateacher.com/faq" target="_blank" rel="noopener noreferrer">
						FAQ'S
					</a>
					<a href="https://myyogateacher.com/contactus" target="_blank" rel="noopener noreferrer">
						CONTACT US
					</a>
				</div>
			</div>
		);
	}
}

export default Footer;
