import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../utils/router';
import Header from '../util_components/header';
import * as actions from '../../redux/action_creators';
import LoadingScreen from '../util_components/loading_screen';
import { ToggleCheckbox } from '../../common/ToggleCheckbox';
import './style.css';
import { isUserLoggedIn } from '../../new_teacher_design/utils/general_funcs';
import { sendEventToClarity } from '../../util_functions';

function CommunicationPreferencesBase({
  getTeacherNotificationPreferences,
  getTeacherNotificationPreferencesStatus,
  getTeacherNotificationPreferencesResponse,
  updateStudentNotificationPreferences,
  router
}) {
  const [loading, setLoading] = useState(true);

  const [toggleSwitchClassScheduling, setToggleSwitchClassScheduling] = useState([
    {
      checkedEmail: false,
      title: 'Class scheduled',
      apiPramEmail: 'booking_confirmation_email'
    },
    {
      checkedEmail: false,
      title: 'Class cancellation',
      apiPramEmail: 'cancellation_email'
    },
    {
      checkedEmail: false,
      title: 'Class time changed',
      apiPramEmail: 'time_change_email'
    }
  ]);

  const [toggleSwitchClassReminders, setToggleSwitchClassReminders] = useState([
    {
      checkedEmail: false,
      title: '1 hour before class',
      apiPramEmail: 'reminder_1_hour_email'
    },
    {
      checkedEmail: false,
      title: '24 hours before class',
      apiPramEmail: 'reminder_1_day_email'
    }
  ]);

  const [toggleSwitchAccountMarketing, setToggleSwitchAccountMarketing] = useState([
    {
      checkedEmail: false,
      title: 'Daily Summary',
      apiPramEmail: 'daily_summary_email'
    }
  ]);

  useEffect(() => {
    sendEventToClarity();
  }, []);

  useEffect(() => {
    if (!isUserLoggedIn()) {
      return router.navigate('/');
    }

    getTeacherNotificationPreferences();
  }, [router, getTeacherNotificationPreferences]);

  useEffect(() => {
    if (getTeacherNotificationPreferencesStatus === 'success') {
      // Class Scheduling
      let updatedToggleSwitchClassScheduling = [...toggleSwitchClassScheduling];
      updatedToggleSwitchClassScheduling = updatedToggleSwitchClassScheduling.map(
        (item, index) => ({
          ...item,
          checkedEmail: !!getTeacherNotificationPreferencesResponse[item.apiPramEmail]
        })
      );

      // Class Reminders
      let updatedToggleSwitchClassReminders = [...toggleSwitchClassReminders];
      updatedToggleSwitchClassReminders = updatedToggleSwitchClassReminders.map((item, index) => ({
        ...item,
        checkedEmail: !!getTeacherNotificationPreferencesResponse[item.apiPramEmail]
      }));

      // Account & Marketing
      let updatedToggleSwitchAccountMarketing = [...toggleSwitchAccountMarketing];
      updatedToggleSwitchAccountMarketing = updatedToggleSwitchAccountMarketing.map(
        (item, index) => ({
          ...item,
          checkedEmail: getTeacherNotificationPreferencesResponse[item.apiPramEmail]
        })
      );

      setToggleSwitchClassScheduling(updatedToggleSwitchClassScheduling);
      setToggleSwitchClassReminders(updatedToggleSwitchClassReminders);
      setToggleSwitchAccountMarketing(updatedToggleSwitchAccountMarketing);
      setLoading(false);
    }
  }, [getTeacherNotificationPreferencesResponse, getTeacherNotificationPreferencesStatus]);

  const goBack = () => {
    router.navigate(-1);
  };

  const redirectToHome = () => {
    router.navigate('/');
  };

  const convertToObj = (param) => {
    const {key} = param;
    const {value} = param;
    const obj = {};
    obj[key] = value === 'true' ? true : value === 'false' ? false : value;
    return obj;
  };

  const onToggleSchedulingChange = (index) => {
    if (!isUserLoggedIn()) {
      return redirectToHome();
    }

    const updatedToggleSwitchClassScheduling = [...toggleSwitchClassScheduling];
    const param = {};

    updatedToggleSwitchClassScheduling[index].checkedEmail =
      !updatedToggleSwitchClassScheduling[index].checkedEmail;
    param.key = updatedToggleSwitchClassScheduling[index].apiPramEmail;
    param.value = !!updatedToggleSwitchClassScheduling[index].checkedEmail;

    updateStudentNotificationPreferences(convertToObj(param));
    setToggleSwitchClassScheduling(updatedToggleSwitchClassScheduling);
  };

  const onToggleRemindersChange = (index) => {
    if (!isUserLoggedIn()) {
      return redirectToHome();
    }

    const updatedToggleSwitchClassReminders = [...toggleSwitchClassReminders];
    const param = {};

    updatedToggleSwitchClassReminders[index].checkedEmail =
      !updatedToggleSwitchClassReminders[index].checkedEmail;
    param.key = updatedToggleSwitchClassReminders[index].apiPramEmail;
    param.value = !!updatedToggleSwitchClassReminders[index].checkedEmail;

    updateStudentNotificationPreferences(convertToObj(param));
    setToggleSwitchClassReminders(updatedToggleSwitchClassReminders);
  };

  const onToggleAccountMarketingChange = (index) => {
    if (!isUserLoggedIn()) {
      return redirectToHome();
    }

    const updatedToggleSwitchAccountMarketing = [...toggleSwitchAccountMarketing];
    const param = {};

    updatedToggleSwitchAccountMarketing[index].checkedEmail =
      !updatedToggleSwitchAccountMarketing[index].checkedEmail;
    param.key = updatedToggleSwitchAccountMarketing[index].apiPramEmail;
    param.value = !!updatedToggleSwitchAccountMarketing[index].checkedEmail;

    updateStudentNotificationPreferences(convertToObj(param));
    setToggleSwitchAccountMarketing(updatedToggleSwitchAccountMarketing);
  };

  return (
    <>
      <Header />
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="compref-body">
          <div className="compref-card">
            <div className="compref-card-header">
              <button className="cmp-back-btn" onClick={goBack}>
                <img
                  alt="back-arrow"
                  src="https://images.myyogateacher.com/icons/back-arrow-white1.svg"
                />
              </button>
              <h1>Communication Preferences</h1>
            </div>
            <div className="com-pre-tabs">
              <button className="sel">Email</button>
            </div>
            <div className="compre-task email-pref">
              <p className="conpre-info-head-up-text">Get notified via your registered email id</p>

              {/* Class scheduling */}
              <p className="conpre-info-head">CLASS SCHEDULING</p>
              <div className="compre-on-off-div">
                {toggleSwitchClassScheduling.map((item, index) => {
                  if (!item.apiPramEmail) {
                    return null;
                  }
                  return (
                    <div key={JSON.stringify(item)} className="compre-row-ofon">
                      <span>{item.title}</span>
                      <ToggleCheckbox
                        click_fn={() => onToggleSchedulingChange(index)}
                        checked={item.checkedEmail}
                      />
                    </div>
                  );
                })}
              </div>

              {/* Class reminders */}
              <p className="conpre-info-head">CLASS reminders</p>
              <div className="compre-on-off-div">
                {toggleSwitchClassReminders.map((item, index) => {
                  if (!item.apiPramEmail) {
                    return null;
                  }
                  return (
                    <div key={JSON.stringify(item)} className="compre-row-ofon">
                      <span>{item.title}</span>
                      <ToggleCheckbox
                        click_fn={() => onToggleRemindersChange(index)}
                        checked={item.checkedEmail}
                      />
                    </div>
                  );
                })}
              </div>

              {/* Account and marketing */}
              <p className="conpre-info-head">Account</p>
              <div className="compre-on-off-div">
                {toggleSwitchAccountMarketing.map((item, index) => {
                  if (!item.apiPramEmail) {
                    return null;
                  }
                  return (
                    <div key={JSON.stringify(item)} className="compre-row-ofon">
                      <span>{item.title}</span>
                      <ToggleCheckbox
                        click_fn={() => onToggleAccountMarketingChange(index)}
                        checked={item.checkedEmail}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
    getTeacherNotificationPreferencesStatus: state.home.get_teacher_notification_preferences_status,
    getTeacherNotificationPreferencesResponse:
      state.home.get_teacher_notification_preferences_response
  });

const mapDispatchToProps = (dispatch) => ({
    getTeacherNotificationPreferences: (payload) => {
      dispatch(actions.get_teacher_notification_preferences(payload));
    },
    updateStudentNotificationPreferences: (payload) => {
      dispatch(actions.update_teacher_notification_preferences(payload));
    }
  });

const CommunicationPreferencesWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationPreferencesBase);
const CommunicationPreferences = withRouter(CommunicationPreferencesWithRedux);

export default CommunicationPreferences;
