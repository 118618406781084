import React from 'react';
import './index.css';

interface ISwitchButtonProps {
  id: string;
  isChecked: boolean;
  value: string;
  onClick: () => void;
}

const SwitchButton = ({
  id,
  isChecked,
  value,
  onClick,
}: ISwitchButtonProps) => {
  return (
    <>
      <label className="cust-tog-switch">
        <input
          onChange={onClick}
          value={value}
          checked={isChecked}
          type="checkbox"
          id={id}
        />
        <div className="cust-tog-slider-button"></div>
      </label>
    </>
  );
};

export default SwitchButton;
