import * as t from '../action_types';

// different status
// 1. none
// 2. inprogress
// 3. success
// 4. fail


var initialState = {
  availability_submit_status: 'none',
  availability_schedule: [],

  my_session_status: 'none',
  my_session_response: {},

  my_session_data_status: 'none',
  my_session_data_response: {},

};

const set_availability_variable = (state, payload) => {
  return {
    ...state,
    [payload.key]: payload.payload
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
      case t.set_availability_variable:
      return set_availability_variable(state, action.payload);
    default:
      return state;
  }
}