import React, { Component } from 'react';


class OutsideAlerter extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.close_card();
    }
  }

  render() {
    return (
      <>
        <div className='mb-0' onClick={(e) => this.handleClickOutside(e)}>
          <div ref={this.wrapperRef}>
            {this.props?.children}
          </div>
        </div>
      </>
    );
  }
}

export default OutsideAlerter;