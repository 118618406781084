import moment from 'moment-timezone';
import React, { useEffect, useRef, useState, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './TimePicker.css';
import { TimeIcon } from '../../assets/svg/popup_icons';
import { getCookie } from '../../utils/cookie_funcs';

const TimePicker = (props: any) => {

    const [inputTypeLabel] = useState(props?.inputTypeLabel);
    const [startDate, setStartDate] = useState(new Date(new Date(props?.selectedTime).toLocaleString('en', { timeZone: !!getCookie('time_zone') ? getCookie('time_zone') : "Asia/Kolkata" })));
    const isFirstRender = useRef(true)

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false // toggle flag after first render/mounting
            return;
        }
        props.onClickTimeValue(startDate)
    }, [startDate])

    return (
        <>
            <div className="lg:m-0 zm:mr-2">
                <p className='font-medium text-mytLabel text-13.5'>{inputTypeLabel}</p>
                <div className='relative cursor-pointer mt-2 w-fit'>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        className="input-date zm:w-150px xs:w-195px rounded-lg text-15 font-medium shadow-sm flex items-center formInput border"
                    />
                    <div className='pointer-events-none absolute top-10px right-3'>{<TimeIcon />}</div>
                </div>
            </div>
        </>
    )
}

export default TimePicker;