import moment from "moment-timezone";
import React, { useEffect } from "react";
import Button from "./CommonComponents/Button";
import { GreyStar } from "./svg";

export const SessionConsumedBanner = (props) => {
    const isNotExpired = Boolean(!props?.data?.is_backpain_program_expired) || false;
    const isBought = Boolean(props?.data?.is_subscribed)|| false;
    return (
        <div className="bg-mytBgRequestCard py-19px flex items-center justify-center md:mt-0 zm:mt-70px">
            {(!isNotExpired || !isBought)?null:<GreyStar />}
            <div className="font-medium zm:text-12px md:text-13px leading-22px text-mytRequestText ml-2.5">
                {!isBought && isNotExpired?'Has not enrolled to Back Pain Relief Program':!isNotExpired && !isBought?'Back Pain Relief pack expired':`Back pain relief program; ${!Number.isNaN(props?.data?.completed_session_pack_hours) ? props.data.completed_session_pack_hours : ''} out of ${!Number.isNaN(props?.data?.total_session_pack_hours) ? props?.data?.total_session_pack_hours : ''} ${ props?.data?.total_session_pack_hours>1?'sessions':'session'} consumed`}
            </div>
        </div>
    )
}

export function HeadText(props) {
    return (
        <div className={` ${props.classnames ? props.classnames : ''} font-extrabold zm:text-20px md:text-3xl text-black zm:mt-50px md:mt-70px`}>{props.text}</div>
    )
}

export function SubHeadText(props) {
    const Timezone = props.timezone || 'America/Los_Angeles';
    const currentTime = moment().tz(Timezone);
    const formattedTimezone = currentTime.format('z');
    return (
        <div className="flex flex-row">
            <div className={`font-semibold zm:text-17px md:text-19px leading-22px text-greyText ${props.classnames ? props.classnames : ''} mr-2px`}>{ props.text }</div>
            {props?.showTimeZone ?<span className={`font-Inter font-bold text-13px leading-22px text-black ${props.classnames ? props.classnames : ''} `}>({formattedTimezone})</span>:null}
            </div>
    )
}

{/*-------Session Details page Button----------*/ }
export function SessionDetailPageBtn(props) {
    return (
        <div className="flex items-center justify-between pb-62px border-b border-solid border-mytgrey-hover mb-62px">
            <div>
                <div className="font-bold text-22px text-mytTeacherTextGray mb-2.5">Want to schedule another consult with Amelia? </div>
                <div className="font-normal text-base leading-6 text-black">This option should be mindfully used in agreement with the student.</div>
            </div>
            <Button btntext="Book follow-up consult"
                color="btn-mytPurple"
                classNames="pt-19px pb-19px pl-26px pr-26px" />
        </div>

    )
}
{/*-------Session Details page Button----------*/ }