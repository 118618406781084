// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';

import { BsFillCloudDownloadFill, BsFillShareFill, BsFillClipboardPlusFill } from 'react-icons/bs';

import { toast } from 'react-toastify';
import VideoThumbnail from './VideoThumbnail';

function MediaViewerPlayer({ medias, show, selectedMedia, onConfirm }) {
  const [focusedMedia, setFocusedMedia] = useState(null);

  useEffect(() => {
    if (!selectedMedia) return;
    setFocusedMedia(selectedMedia);
    const container = document.getElementById('imgBar');
    const medias = container.querySelectorAll('img');
    let selectedNode;
    medias.forEach((image) => {
      if (image.src === selectedMedia.url) {
        selectedNode = image;
      }
    });
    selectedNode?.scrollIntoView({ block: 'center' });
  }, [selectedMedia]);

  const downloadImage = (image) => {
    fetch(image.url, {
      method: 'GET',
      mode: 'cors'
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'student-image.jpeg';
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); // afterwards we remove the element again
      });
  };

  return (
    <>
      {show && (
        <div
          className="relative z-20"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-90 transition-opacity " />

          <div className="fixed inset-0 z-20 overflow-y-auto overflow-x-auto">
            <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
              <div className="z-20 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                <div className="bg-white px-2 pt-2 pb-2 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start justify-center">
                    <div className="mt-1 text-center sm:mt-0 sm:text-left">
                      <div className="mt-0 im-body-container">
                        {focusedMedia?.url && (
                          <div className="im-main-container">
                            {focusedMedia?.mime === 'video/mp4' ? (
                              <video
                                src={focusedMedia.url}
                                className="img-bar rounded-2xl"
                                autoPlay
                                controls
                                preload="metadata"
                              />
                            ) : (
                              <img
                                className="large-img-view"
                                src={focusedMedia.url}
                                alt="selected image"
                              />
                            )}
                            <div className="image-modal-overlay">
                              <BsFillCloudDownloadFill
                                size="1.5rem"
                                data-tooltip-id="video-tooltip"
                                data-tooltip-content="Download"
                                className="image-action-items"
                                onClick={() => downloadImage(focusedMedia)}
                              />

                              <BsFillShareFill
                                size="1.5rem"
                                data-tooltip-id="video-tooltip"
                                data-tooltip-content="Share"
                                className="image-action-items"
                              />

                              <BsFillClipboardPlusFill
                                size="1.5rem"
                                data-tooltip-id="video-tooltip"
                                data-tooltip-content="Copy to clipboard"
                                className="image-action-items"
                                onClick={() => {
                                  toast.info('Link Copied', {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    autoClose: 5000
                                  });

                                  navigator?.clipboard?.writeText(focusedMedia.url);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <div className="img-bar" id="imgBar">
                          {medias.map((media, index) =>
                            media.mime === 'video/mp4' ? (
                              <div onClick={() => setFocusedMedia(media)}>
                                <VideoThumbnail
                                  videoSrc={media.url}
                                  modifiedClassName={
                                    focusedMedia?.url === media?.url ? 'selected' : undefined
                                  }
                                  thumbnailSize={{ width: '162px' }}
                                />
                              </div>
                            ) : (
                              <img
                                src={media.url}
                                alt="session images"
                                className={
                                  focusedMedia?.url === media?.url ? 'selected' : undefined
                                }
                                onClick={() => setFocusedMedia(media)}
                                key={index}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-3 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    onClick={onConfirm}
                    className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MediaViewerPlayer;
