import React from 'react';

interface ICheckBoxProps {
  forCheck?: string;
  id: string;
  className?: string;
  label: string;
  isChecked: boolean;
  value: any;
  onClick: (value: string) => void;
}

const CheckBox = ({
  forCheck,
  id,
  className,
  label,
  isChecked,
  value,
  onClick,
}: ICheckBoxProps) => {

  return (
    <div>
      <label
        onClick={() => onClick(value)}
        className={`flex ${className === 'cancel-future-class' ? 'items-center ' : 'items-start ' } checkBoxHoverState gap-12px`}
        htmlFor={forCheck}
      >
        <div className="checkbox1_on_1_page_v1 checkbox">
          <input
            type="checkbox"
            id={id}
            name=""
            checked={isChecked}
            value={value}
            onChange={() => onClick(value)}
          />
          <label htmlFor={forCheck}></label>
        </div>
        <label
          htmlFor={forCheck}
          className={`font-inter text-13.5 zm:text-sm font-medium text-mytLabel cursor-pointer checkBoxHoverState ${isChecked ? 'text-primary' : 'text-mytLabel'} ${className}`}
        >
          {label}
        </label>
      </label>
    </div>
  );
};

export default CheckBox;
