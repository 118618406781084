import React, { useState } from 'react'
import { post_api } from '../../redux/api_funcs';
import urls from '../../urls';
import { setCookie } from '../../util_functions';
import Button from './CommonComponents/Button'
import PainLevelPopup from './PainLevelPopup';
import { Mandala } from './svg'
import GroupButton from "../../components/GroupSessionButton";
import MytToolTip from '../BackPainTherapy/CommonComponents/MytToolTip/MytToolTip';
const ProfileHeader = (props) => {
    const [showPainLevelPopup, setShowPainLevelPopup] = useState(false);
    const [showCopied, setShowCopied] = useState(false);
    const isConsultTherapist = parseInt(localStorage.getItem('is_consultant_therapist')) || false;
    const { studentUUID } = props;
    const { 
        is_backpain_program_expired = 0, 
        is_subscribed = 0, 
        joined_a_coaching_session = 0, 
        has_student_booked_consult_with_teacher = 1, 
        photo = '',
        back_pain_levels = [], 
        name = '', 
        local_time = '', 
        slug = '' 
    } = props.data;

    const getFollowUpConsultStates = () => {
        let showFollowUpBtn = false;
        let disableFollowUpBtn = true;
        
    if( !isConsultTherapist || joined_a_coaching_session === 1 ) {
        showFollowUpBtn = false;
        disableFollowUpBtn = true;
    }
    else if(isConsultTherapist && Boolean(has_student_booked_consult_with_teacher) ) {
        showFollowUpBtn = true
        disableFollowUpBtn = false
    }
    else if(isConsultTherapist && !Boolean(has_student_booked_consult_with_teacher) ) {
        showFollowUpBtn = true
        disableFollowUpBtn = true
    }
        return { showFollowUpBtn, disableFollowUpBtn };
    }
    const copyContent = async (text = '') => {
        try {
            await navigator.clipboard.writeText(text);
            setShowCopied(true);
            setTimeout(() => {
                setShowCopied(false)
            }, 3000);
            console.log('Content copied to clipboard');
            /* Resolved - text copied to clipboard successfully */
        } catch (err) {
            console.error('Failed to copy: ', err);
            /* Rejected - text failed to copy to the clipboard */
        }
    }
    const { showFollowUpBtn = true, disableFollowUpBtn = false } = getFollowUpConsultStates();
    return (

        <div className="rounded-lg border border-solid border-mytgrey-litev1 profile-bgpurple h-auto w-full md:py-54px zm:py-30px">
            {showPainLevelPopup ? <PainLevelPopup student_uuid={studentUUID} onClose={() => {
                setShowPainLevelPopup(false);
            }} /> : null}
            <div className="flex items-center justify-center flex-col">
                <div className="zm:w-full md:w-fit flex items-center justify-center flex-col relative">
                    <img src={photo || ''}
                        width="103px"
                        height="103px"
                        className="w-103px h-103px rounded-full z-50" />
                    <div className="font-extrabold md:text-40px zm:text-30px leading-48px mt-7px z-50">{name || ''}</div>
                    <div className="text-sm text-center leading-17px font-medium text-mytgrey-lite mb-34px z-50 px-11px mt-6px zm:mb-30px md:mt-">Local time - {local_time || ''}, Unique ID: {slug || ''}
                        <span className="underline underline-offset-4 ml-1.5 z-50 cursor-pointer" onClick={() => copyContent(slug)}>{showCopied ? 'Copied' : 'Copy'}</span>
                        {props?.data?.concierge_teacher ? <span className='ml-15px'>{`Concierge - ${props?.data?.concierge_teacher || ''}`}
                    </span>:null}
                    </div>
                    <div className="flex items-center z-50 zm:flex-col midScreen:flex-row zm:gap-10px midScreen:gap-0 zm:w-full midScreen:w-auto zm:px-45px md:px-0">

                        <>
                            {!is_subscribed ? <MytToolTip content={!is_subscribed && !is_backpain_program_expired ? 'Back Pain relief program needs to be purchased' : 'Back pain relief program expired'} id='book-1-on-1' position={'top'} /> : null}

                            <Button btntext="Book 1-on-1 Session"
                                id='book-1-on-1'
                                disable={!props?.data?.is_subscribed}
                                onClick={() => props.onClick({ type: 'therapy_1_on_1' })}
                                isTooltip={true}
                                btnclassNames="md:mr-2.5 zm:w-full midScreen:w-auto"
                                classNames='zm:w-full midScreen:w-auto'
                                 />
                            {!is_subscribed ? <MytToolTip content={!is_subscribed && !is_backpain_program_expired ? 'Back Pain relief program needs to be purchased' : 'Back pain relief program expired'} id='book-group' position={'top'} /> : null}

                            <GroupButton student_uuid={studentUUID || ''}
                                disable={!is_subscribed}
                            >
                                <Button btntext="Book a Group Class"
                                    color="btn-mytPurple"
                                    btnclassNames="md:mr-2.5 zm:w-full md:w-auto"
                                    isTooltip={true}
                                    disable={!is_subscribed}
                                    id='book-group'
                                    classNames='zm:w-full midScreen:w-auto'

                                /></GroupButton>
                        </>
                        {disableFollowUpBtn 
                        ? <MytToolTip content={'Contact care team for consult booking'} id='book-follow-up' position={'top'} /> 
                        : null
                        }

                        {showFollowUpBtn ?
                            <Button btntext="Book Follow up Consult"
                                color="btn-mytPurple"
                                id='book-follow-up'
                                isTooltip={true}
                                disable={disableFollowUpBtn}
                                btnclassNames="md:mr-2.5 zm:w-full midScreen:w-auto"
                                classNames='zm:w-full midScreen:w-auto'
                                onClick={() => props.onClick({ type: 'follow_up' })}
                            /> : null}
                        {back_pain_levels?.length > 0 ? <Button btntext="View Pain Level"
                            color="btn-white-default"
                            btnclassNames="md:mr-2.5 zm:w-full midScreen:w-auto"
                            classNames='zm:w-full midScreen:w-auto'
                            onClick={() => setShowPainLevelPopup(true)}
                        /> : null}
                    </div>
                    <div className="absolute bottom-0 flex align-center justify-center -mb-54px"><Mandala /></div>
                </div>
            </div>
        </div>
    )
}

export default ProfileHeader