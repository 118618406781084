import React, { Component } from 'react';

import './vibrant-community.css';
import { analytics_track, get_time_taken } from '../../../util_functions';
import { virbant_community_images } from '../../../constants';

import PopupImgViewer from '../popup_image_viewer';

export default class VibrantCommunity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopup: false,
			image_index: 0
		};
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyPress, false);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyPress, false);
	}

	handleKeyPress = e => {
		const length = virbant_community_images.length;
		if (e.keyCode === 39) {
			if (this.state.image_index < length - 1) {
				this.setState({ image_index: this.state.image_index + 1 });
			}
		} else if (e.keyCode === 37) {
			if (this.state.image_index !== 0) {
				this.setState({ image_index: this.state.image_index - 1 });
			}
		}
	};
	handleImageClick = index => {
		this.setState({ showPopup: !this.state.showPopup, imageIndex: index });
		const options = {
			referrer_component: 'home',
			plan: 'guest',
			time_since_page_load: get_time_taken(this.props.start_time),
			image_url: virbant_community_images[index].imageUrl
		};
		analytics_track(`open image viewer`, options);
	};

	popup_close = () => {
		this.setState({ showPopup: false });
	};

	renderImages() {
		return (
			<div className="rowfullwidth-teacher-img">
				<ul>
					{virbant_community_images.map((images, index) => {
						return (
							<li key={index}>
								<img onClick={() => this.handleImageClick(index)} src={images.imageUrl} alt="teach-images" />
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	renderMobileViewImages = () => {
		// return virbant_community_images.map((images, index) => {
		return (
			<div className="tp-mobile-gallery-slider">
				<div className="image-slides">
					<img width="75%" src={virbant_community_images[this.state.image_index].imageUrl} alt="pic" />
					{this.state.image_index !== 0 ? (
						<button
							onClick={() => this.setState({ image_index: this.state.image_index - 1 })}
							className="w3-button w3-display-left"
						>
							<img src="https://images.myyogateacher.com/Teacher-Page/Arrow-Image-Popup.png" alt="arr" />
						</button>
					) : null}
					{this.state.image_index !== virbant_community_images.length - 1 ? (
						<button
							onClick={() => {
								this.setState({ image_index: this.state.image_index + 1 });
							}}
							className="w3-button w3-display-right"
						>
							<img src="https://images.myyogateacher.com/Teacher-Page/Arrow-Image-Popup.png" alt="arr" />
						</button>
					) : null}
				</div>
			</div>
		);
	};

	render() {
		return (
			<div className="bg-light vibrant-container">
				<div className="container">
					<h2>Vibrant community</h2>
					<p className="subpara mb-hide">Be a part of a community of teachers to share experiences and learn</p>
					<p className="divider-line">
						<img src="https://images.myyogateacher.com/home-page/Divider.png" alt="div" />
					</p>

					{this.renderImages()}

					{this.state.showPopup ? (
						<PopupImgViewer
							image_index={this.state.imageIndex}
							popup_close={this.popup_close}
							start_time={this.props.start_time}
						/>
					) : null}

					{this.renderMobileViewImages()}
				</div>
			</div>
		);
	}
}
