import { post_api } from '../../../redux/api_funcs';

export const callJsonPostApi = (url, payload, type = 'application/json') => {
	let params = window.location.search.slice(1);
	let parts = params.split('&');
	let token = null;

	parts.forEach((part) => {
		let [key, value] = part.split('=');
		if (key === 'token') {
			token = value;
		}
	});

	return post_api(url, payload, true, type, null, token);
};

export const userFromParticipant = (participant) => {
	return participant?.identity?.split(':')[0];
};
