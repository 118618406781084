import { all } from "redux-saga/effects";
import dashboardSaga from "./dashboard_saga";
import availabilitySaga from "./availability_saga";
import profileSaga from "./profile_saga";
import homeSaga from "./home_saga";
import welcomeSaga from "./welcome_saga";
import zoomSaga from "./zoom_saga";
import classDetailsSaga from '../../new_teacher_design/Redux/sagas/classdetails_saga';
import dashboardSagaNew from '../../new_teacher_design/Redux/sagas/dashboard_saga';
import mySessiondSagaNew from '../../new_teacher_design/Redux/sagas/mysession_saga';
import uploadFileSaga from '../../new_teacher_design/Redux/sagas/uploadfiles_saga';

export default function* rootSaga() {
  yield all([
    dashboardSaga(),
    availabilitySaga(),
    profileSaga(),
    homeSaga(),
    welcomeSaga(),
    zoomSaga(),
    classDetailsSaga(),
    dashboardSagaNew(),
    mySessiondSagaNew(),
    uploadFileSaga(),
  ]);
}
