import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import Header from '../../util_components/header';
import DownloadApp from '../../util_components/download_app/download_app';
import './cm.css';

class CheckMobile extends Component {
	render() {
		if (isMobile || isTablet) {
			return (
				<div>
					<Header hide_tabs={true} />
					<div className='home-mobile-container' style={{ marginTop: '50px' }}>
						<img src='https://images.myyogateacher.com/website/ic_error@2x.png' alt='error' />
						<p> Phone browser not supported</p>
						<div className='home-mobile-message'>
							Sign in from the Desktop / Laptop browser for best experience.
						</div>
					</div>
					<DownloadApp no_benefits={true} />
				</div>
			);
		}
		return this.props.children;
	}
}

export default CheckMobile;
