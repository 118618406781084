import React, { Component } from "react";
import PropTypes from "prop-types";
import Spinner from "../../util_components/spinner";
import "./custom_file_upload.css";

export default class CustomFileUpload extends Component {
  state = {
    loading: false
  };
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ loading: false });
    }
  }
  onFileClick = () => {
    const realInput = document.getElementById("custom-real-input");
    realInput.click();
  };
  onChange = e => {
    this.setState({ loading: true });
    this.props.handleFileChange(e);
  };
  render() {
    if (this.props.type === "image") {
      return (
        <div className="custom-file-input-image" onClick={this.onFileClick}>
          <input type="file" id="custom-real-input"  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" onChange={this.onChange} />
          <div
            className="custom-file-image"
            style={{
              backgroundImage: `url(${
                this.props.image_url
              }), url("https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png")`
            }}
          />
          <div className="custom-file-info">{this.props.label}</div>
          {this.state.loading ? (
            <div className="custom-file-spinner">
              <Spinner type="dots" />
            </div>
          ) : null}
        </div>
      );
    } else if (this.props.type === "doc") {
      return (
        <div className="custom-file-input-doc">
          {!this.state.loading && this.props.file_url !== "" &&
          typeof this.props.file_url !== "undefined" ? (
            <a
              className="custom-file-link"
              href={this.props.file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              view {this.props.component}
            </a>
          ) : !this.state.loading && (this.props.teacher_edit_auditing.resume_document_url !== '' ||
          typeof this.props.teacher_edit_auditing.resume_document_url !== 'undefined' )&& <p className="custom-file-review">Your {this.props.component} is under review</p>}
          {/* <label htmlFor="fileinput" >Select Image</label> */}
          <input id="fileinput" type="file"  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" onChange={this.onChange} />
          {this.state.loading ? (
            <div className="custom-file-spinner">
              <Spinner type="dots" />
            </div>
          ) : null}
        </div>
      );
    }
    else if (this.props.type === "video") {
      return (
        <div className="custom-file-input-doc">
          {!this.state.loading && this.props.file_url !== "" &&
          typeof this.props.file_url !== "undefined" ? (
            <a
              className="custom-file-link"
              href={this.props.file_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              view {this.props.component}
            </a>
          ) : !this.state.loading && (this.props.teacher_edit_auditing.resume_document_url !== '' ||
          typeof this.props.teacher_edit_auditing.resume_document_url !== 'undefined' )&& <p className="custom-file-review">Your {this.props.component} is under review</p>}
          {/* <label htmlFor="fileinput" >Select Image</label> */}
          <input id="fileinput" type="file"  accept="video*" onChange={this.onChange} />
          {this.state.loading ? (
            <div className="custom-file-spinner">
              <Spinner type="dots" />
            </div>
          ) : null}
        </div>
      );
    }
  }
}

CustomFileUpload.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string
};

CustomFileUpload.defaultProps = {
  type: "doc",
  label: "Upload a file"
};
