import React from 'react';
import PropTypes from 'prop-types';

import './custom_radio_button.css';

const RadioButton = props => (
	<div className="radio-container" onClick={props.onRadioClick}>
		<div className={`custom-radio-circle ${props.selected ? 'custom-radio-selected' : null}`}>
			{props.selected ? <div className="radio-selected" /> : false}
		</div>
		<div className="custom-radio-text">{props.label}</div>
	</div>
);

RadioButton.propTypes = {
	selected: PropTypes.bool,
	label: PropTypes.string,
	onRadioClick: PropTypes.func
};

RadioButton.defaultProps = {
	selected: false,
	label: 'Button',
	onRadioClick: () => null
};

export default RadioButton;
