import React, { PureComponent } from 'react';
import moment from 'moment';
import { get_date_value } from '../../../util_functions';

export default class CommonTimer extends PureComponent {
	state = {
		start_timer: true,
		diff_time: 0,
	};
	componentDidMount() {
		this.set_diff_time();
	}
	componentWillUnmount() {
		this.close_timer();
	}
	set_diff_time = () => {
		const diff_sec = Math.floor(moment(this.props.timer_time).diff(moment()) / 1000);
		this.setState({ diff_time: diff_sec }, () => {
			this.timer = setInterval(() => {
				let diff_time = this.state.diff_time;
				if (diff_time === 0) {
					this.close_timer();
				} else {
					this.setState({ diff_time: diff_time - 1 });
				}
			}, 1000);
		});
	};
	close_timer = () => {
		if (this.time) {
			clearInterval(this.timer);
		}
		if (!!this.props.refresh_sessions) {
			this.props.refresh_sessions();
		}
	};
	render() {
		if (!!this.state.diff_time && this.state.diff_time > 0) {
			return (
				<div style={{ width: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					{get_date_value(this.state.diff_time)}
				</div>
			);
		}
		return null;
	}
}
