import React, { useState } from 'react'
import Button from './CommonComponents/Button'
import WhiteBox, { Boxcontent } from './CommonComponents/WhiteBox'
import { HeadText } from './StudentProfileDetails'
import TherapyPlanPopUp from './TherapyPlanPopUp'

const BackPainTherapy = (props) => {
    const [showCreateTherapyPlanPopup, setCreateTherapyPlanPopup] = useState(false);
    const [showEditTherapyPlanPopup, setEditTherapyPlanPopup] = useState(false);
    return (
        <div>
            {showCreateTherapyPlanPopup && (
                <TherapyPlanPopUp session_details={{ student_uuid: props.studentUUID }} onClose={() => {
                    props.onEditTherapyPlan(() => {
                        setCreateTherapyPlanPopup(false);
                    })
                }} />
            )}
            {showEditTherapyPlanPopup && <TherapyPlanPopUp isEditing={true} session_details={{ student_uuid: props.studentUUID }} prefilledValues={props.data.back_pain_therapy} onClose={() => {
                props.onEditTherapyPlan(() => {
                    setEditTherapyPlanPopup(false);
                })
            }} />}
            <HeadText
                classnames="mb-15px"
                text="Back Pain Plan" />
            <WhiteBox classnames="p-30px">
                {props.data.is_roadmap_present === 1 ?
                    <>
                        {props.data.back_pain_therapy.map((section, index) => {
                            return (
                                <Boxcontent
                                    classnames="mt-0px mb-30px"
                                    textstyle="text-14px leading-17px font-bold"
                                    parastyle="text-14px leading-18px font-medium"
                                    subhead={section?.question || ''}
                                    para={section?.answer || ''} />
                            )
                        })}
                    </> :
                    <div className='whitespace-pre-line text-13px leading-5 font-normal text-greyText'>
                        Plan has not been created yet. Click on the below button to create the back
                        pain relief plan.
                    </div>
                }
                {props.data.is_roadmap_present === 1 ? <Button btntext="Edit Plan"
                    color="btn-white-default"
                    btnclassNames="mt-30px" onClick={() => setEditTherapyPlanPopup(!showEditTherapyPlanPopup)} /> : <Button btntext="Create Relief Plan"
                        color="btn-white-default"
                        btnclassNames="mt-30px" onClick={() => setCreateTherapyPlanPopup(!showCreateTherapyPlanPopup)} />}
            </WhiteBox>
        </div>
    )
}

export default BackPainTherapy