import React, { Component } from 'react';
import { post_request_repalcement } from "../../../redux/api_functions/availability_api";
import Loader from '../../new_teacher_wesite/components/loader';


class LiveSessionCancel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      getReplacementData: props.cancelSessionData,
      SetCustomInput: '',
      SetCheckBoxReasonuseState: String,
      setReasonList: '',
      isLoading: false,
      errMsg: false,
      reason: [
        {
          id: 1,
          reasonItem: 'Internet Outage - weather related',
          checked: false,
        },
        {
          id: 2,
          reasonItem: 'Power Outage - weather related',
          checked: false,
        },
        {
          id: 3,
          reasonItem: 'Power Outage',
          checked: false,
        },
        {
          id: 4,
          reasonItem: 'Feeling Unwell',
          checked: false,
        },
        {
          id: 5,
          reasonItem: 'Medical Emergency',
          checked: false,
        },
        {
          id: 6,
          reasonItem: 'Family Emergency',
          checked: false,
        },
        {
          id: 7,
          reasonItem: 'Hardware Issues',
          checked: false,
        },
        {
          id: 8,
          reasonItem: 'Urgent unplanned reason',
          checked: false,
        }
      ]

    }
  }





  submitData = () => {
    if (this.state.setReasonList !== '' || this.state.SetCustomInput !== '') {
      let payload = {
        'session_uuid': this.state.getReplacementData.session_uuid,
        'reason_for_replacement': this.state.setReasonList,
        'details_for_replacement': this.state.SetCustomInput,
      }
      // console.log("this is payload", payload);
      this.setState({ isLoading: true });
      post_request_repalcement(payload).then(response => {
        if (response?.data?.status === "success") {
          // console.log("this is api get response 1", response);
          this.props.closeReplacementPopup(false);
          this.setState({ isLoading: false });
        }
      })
    } else {
      this.setState({
        errMsg: true
      })
    }
  }

  getReason = (reasonData) => {
    this.setState({
      setReasonList: reasonData.reasonItem,
      errMsg: false
    })
  }


  customInput = (data) => {
    this.setState({
      SetCustomInput: data.target.value,
      errMsg: false
    })
  }

  // console.log("tis isf s f;s f;s", getReplacementData);


  render() {
    return (
      <>
        {this.state.isLoading ?
          <Loader /> :
          <div className='relative inset-0 z-index-max'>
            <div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
              <div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
                <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
                  <div onClick={() => this.props.closeReplacementPopup(false)}>
                    <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <rect width='34' height='34' rx='17' fill='none' />
                      <path
                        d='M22 12L12 22'
                        stroke='#BFBFBF'
                        strokeWidth='1.66667'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12 12L22 22'
                        stroke='#BFBFBF'
                        strokeWidth='1.66667'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                </div>
                <div className='mb-0'>
                  <div className='flex justify-start flex-col items-start'>
                    <div className='w-full'>
                      <div className="flex justify-center items-center">
                        <div className="text-17 font-semibold">Replacement reason</div>
                      </div>
                      <div className="mt-5 border-b h-44 overflow-x-scroll">
                        {this.state.reason.map((list) => {
                          return (
                            <>
                              <label key={list?.id} htmlFor={list?.id}>
                                <div className="flex justify-start items-center mt-3 mb-3">
                                  <div className='custom_radio'>
                                    <input
                                      type='radio'
                                      id={list.id}
                                      onClick={() => this.getReason(list)}
                                      name='featured'
                                    // checked={isItemChecked}
                                    />
                                    <label htmlFor={list.id}></label>
                                  </div>
                                  <label htmlFor={list?.id} className="text-base text-mytTeacherTextGray cursor-pointer">{list?.reasonItem}</label>
                                </div>
                              </label>

                              {/* <label htmlFor={list?.id}>
                            <div className="flex justify-start items-center mt-3 mb-3">
                              <div className='checkbox'>
                                <input type='checkbox' id={list?.id} name='' value={list.id} onChange={(list) => getReason(list)} />
                                <label htmlFor={list?.id}></label>
                              </div>
                              <label htmlFor={list?.id} className="text-base text-mytTeacherTextGray cursor-pointer">{list?.reasonItem}</label>
                            </div>
                          </label> */}
                              {/* <div className="flex items-center">
                           <input type='checkbox' id={list?.id} name='' value={list.id} onChange={(list) => getReason(list)}/>
                            <label htmlFor={list?.id} className="text-base text-mytTeacherTextGray cursor-pointer">{list?.reasonItem}</label>
                           </div> */}
                            </>
                          )
                        })}
                      </div>
                      <div className="mt-3">
                        <div className="text-sm">Any other reason?</div>
                        <div>
                          <textarea
                            className='formInput min-h-24 mt-1 p-18px'
                            autoFocus
                            onChange={(data) => this.customInput(data)}
                          ></textarea>
                        </div>
                      </div>
                      {this.state.errMsg === true &&
                        <div className='text-mytRedText2 text-sm font-medium mt-3'>Please Select Reason for Replace</div>
                      }
                      <div className="mt-5 flex flex-col justify-center items-center mt-4">
                        {/* <button className={`btn btn-primary mb-3 ${this.state.errMsg === true ? 'opacity-50 pointer-events-none' : ''}`} onClick={this.submitData}>Request a replacement</button> */}
                        <button className={`btn btn-primary mb-3 `} onClick={this.submitData}>Request a replacement</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default LiveSessionCancel;
