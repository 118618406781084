import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEventToClarity } from '../../../../util_functions';

function Confirmation() {
  const navigate = useNavigate();

  useEffect(() => {
    sendEventToClarity();
  }, []);

  return (
    <div className="md:grid md:grid-cols-2 h-screen w-screen">
        <div className="md:m-16 md:ml-28 zm:m-8">
          <div className="flex items-center w-32 cursor-pointer">
            <img
              src="https://images.myyogateacher.com/web_images/web_prod/arrowLeft.png"
              alt="arrowLeft"
              className="w-2 h-4"
            />
            <div className="text-mytgrey-default text-sm ml-4"> Home</div>
          </div>
          <div className="mt-10">
            <img src="https://images.myyogateacher.com/MYT_Logo.svg" alt="MyYogaTeacher_Logo" />
          </div>
          <div className="zm:w-full md:w-9/12 mt-32">
            <svg
              width="30"
              height="30"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="42" height="42" rx="21" fill="#FEDFD0" />
              <path
                d="M28.7777 15.9441L18.0833 26.6385L13.2222 21.7774"
                stroke="#EE731B"
                strokeWidth="3.11111"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className="text-3xl font-extrabold font-avenir mt-4">Password Reset</div>
            <div className="font-avenir text-mytgrey-lite text-sm mt-3">
              Please check your email for reset password instructions
            </div>
            <div className="font-avenir text-mytgrey-lite text-sm">instructions</div>
            <button className="btn btn-orange mt-5" onClick={() => navigate('/redeem-lesson')}>
              Continue
            </button>
          </div>
        </div>
        <div className="bg-mytBgLogin w-full h-full bg-cover bg-no-repeat bg-right zm:hidden md:block" />
      </div>
  );
}

export default Confirmation;
