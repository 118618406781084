import React from 'react';
import PropTypes from 'prop-types';
import './custom_text_area.css';

const CustomInput = props => (
	<div className="custom-textarea-container" style={{ width: props.width }}>
		<textarea type={props.type} value={props.value} onChange={props.handleChange} name={props.name} rows={props.rows} />
		{typeof props.label !== 'undefined' ? <label>{props.label}</label> : null}
	</div>
);

CustomInput.propTypes = {
	width: PropTypes.string,
	type: PropTypes.string,
	inputHeight: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	rows: PropTypes.number
};

CustomInput.defaultProps = {
	width: '750px',
	type: 'text',
	// label: 'Label',
	name: 'input',
	handleChange: () => null,
	rows: 4
};

export default CustomInput;
