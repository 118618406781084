import React, { useEffect, useState } from "react";
import { format, addMonths, subMonths, subDays, addDays } from "date-fns";
import "./RangePicker.css";

function MonthPicker(props) {
    useEffect(() => {
        if (props.date) {
            setSelectedDate(props.date);
      }
    }, [props.date])
    
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handlePreviousMonth = () => {
        setSelectedDate(subMonths(selectedDate, 1));
        let previousMonth = subMonths(selectedDate, 1);
        props?.onPreviousMonth([previousMonth, subDays(selectedDate, 1)]);
    };

    const handleNextMonth = () => {
        setSelectedDate(addMonths(selectedDate, 1));
        let nextMonth = addMonths(selectedDate, 1);
        props?.onNextMonth([nextMonth, addDays(nextMonth, 29)]);
    };

    return (
        <div className="date-picker zm:w-full sm:w-auto">
            <div className='flex flex-row gap-10px md:ml-95px'>
                <div className="cursor-pointer" onClick={handlePreviousMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12 15L7.0094 10L12 5" stroke="#0D011C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <span className="month-label ">
                    {format(selectedDate, "MMMM, yyyy")}
                </span>
                <div className='cursor-pointer' onClick={handleNextMonth}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M8 15L12.9906 10L8 5" stroke="#0D011C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            {/* Additional date picker content */}
        </div>
    );
}

export default MonthPicker;