import { all, put, takeEvery, call } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { verify_email_api, check_email_verification } from '../api_functions/welcome_api';
import { setCookie } from '../../util_functions';

export function* verifyEmailToken(action) {
	try {
		yield put(actions.set_welcome_variable('verifiation_status', 'inprogress'));
    const response = yield call(verify_email_api, action.payload);
    yield put(actions.set_home_variable('verification_name', response.data.name));
		const new_response = yield call(check_email_verification, response.data.signed_up_id);
		setCookie('teacher_token_1', new_response.data.token, 864000);
		yield put(actions.set_home_variable('teacher_token', new_response.data.token));
		yield put(actions.set_welcome_variable('verifiation_status', 'success'));
	} catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    }
    catch (e) { }
    const exc_msg = error.response.data.exception_message
    if (exc_msg === 'User Email Already Present') {
			yield put(actions.set_welcome_variable('verification_error_message', 'User already verified. Please login'));
    }
    else {
      yield put(actions.set_welcome_variable('verification_error_message', exc_msg));
    }
		yield put(actions.set_welcome_variable('verifiation_status', 'fail'));
	}
}

export default function* welcomeSaga() {
	yield all([takeEvery(t.verify_email_token, verifyEmailToken)]);
}
