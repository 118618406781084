import React, { useRef, useEffect } from 'react';
import { ClosePannel as CloseIcon } from '../livekit_assets/svg';
import VideoThumbnail from './VideoThumbnail';

function SessionImagesDrawer({ mediasInSession, onClose, handleMediaClick }) {
  const imageDrawerRef = useRef(null);

  // useEffect(() => {
  // 	const handleClickOutside = (event) => {
  // 		if  (imageDrawerRef.current && !imageDrawerRef.current.contains(event.target)) {
  //             event.preventDefault()
  // 			onClose();
  // 		}
  //     }

  //     const handleClickInside = (event) => {
  //         event.stopPropagation();
  //       };

  // 	document.addEventListener('mousedown', handleClickOutside);
  //     imageDrawerRef?.current?.addEventListener('mousedown', handleClickInside);
  // 	return () => {
  // 		document.removeEventListener('mousedown', handleClickOutside);
  //         imageDrawerRef.current?.removeEventListener('mousedown', handleClickInside);
  // 	};
  // }, [onClose]);

  return (
    <div ref={imageDrawerRef} className="pannel-wrapper" style={{ height: '100%' }}>
      <div className="pannel-close" onClick={onClose}>
        <CloseIcon />
      </div>
      <div className="pannel-title">Media</div>
      {mediasInSession.length === 0 ? (
        <p>No media present for this session yet!</p>
      ) : (
        <div className="participants-list-wrapper">
          {mediasInSession.map((data, index) => (
            <div
              key={index}
              className="participant-card"
              style={{ padding: '0px', margin: '0px', border: '1px solid black' }}
              onClick={() => {
                handleMediaClick(data);
              }}
            >
              {data.mime === 'video/mp4' ? (
                <VideoThumbnail videoSrc={data.url} />
              ) : (
                <img
                  style={{ width: 'auto', height: 'auto', borderRadius: '2%', cursor: 'pointer' }}
                  src={data.url}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SessionImagesDrawer;
