import React from 'react'
import { connect } from 'react-redux'
import Carousel from '../Carousel/Carousel';
import { DownloadIcon, DeleteIcon, CrossIcon, LoaderIcon } from "../../assets/svg/popup_icons.jsx";
import { saveAs } from 'file-saver';
import Loader from '../FixedComponent/Loader';
import * as actions from '../../Redux/action_creators';

interface IState {
    selectedCarouselImage: number;
    files: any;
    loading: boolean;
    isButtonLoading: boolean;
}
interface IMapStateToProps {
    selectedCarouselImage: number;
    files: any;
    delete_file_status: string;
    delete_file_response: any;
}

interface IMapDispatchToProps {
    closePopupImageCarousel: () => void;
    deleteImage: (files?: any) => void;
    getSelectedImage: (payload?: any) => void;
}

type ImageCarouselAllProps = IMapStateToProps & IMapDispatchToProps;
export class ImageCarousel extends React.Component<ImageCarouselAllProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            selectedCarouselImage: this.props?.selectedCarouselImage,
            files: this.props?.files,
            loading: false,
            isButtonLoading: false,
        }
    }

    componentDidUpdate(prevProps): void {
        if (prevProps.delete_file_status === 'loading' && this.props.delete_file_status === 'success') {
            this.setState({ loading: false });
            this.state.files.splice(this.state.selectedCarouselImage, 1);

            let newFiles = this.state.files
            if (newFiles.length > 1) {
                if (this.state.selectedCarouselImage === newFiles.length) {
                    this.setState({ files: newFiles, selectedCarouselImage: !this.state.selectedCarouselImage ? 0 : this.state.selectedCarouselImage - 1 });
                }
                else {
                    this.setState({ files: newFiles, selectedCarouselImage: !this.state.selectedCarouselImage ? 0 : this.state.selectedCarouselImage },);
                }
            } else if (newFiles.length === 1) {
                this.setState({ files: newFiles, selectedCarouselImage: !this.state.selectedCarouselImage ? 0 : this.state.selectedCarouselImage - 1 });
            }
            else {
                this.props?.closePopupImageCarousel();
            }
        }
        if (prevProps.delete_file_status === 'loading' && this.props.delete_file_status === 'fail') {
            this.setState({ loading: false });
        }
    }

    closePopupImageCarousel = () => {
        this.props?.closePopupImageCarousel();
    }

    changeCarouselIndex = (data) => {
        this.setState({ selectedCarouselImage: data });
    }

    deleteCarouselImage = () => {
        let payload = {
            uuid: this.state.files[this.state.selectedCarouselImage].uuid
        }
        this.setState({ loading: true });
        this.props.deleteImage(payload);
    }

    shareImage = () => {
        this.props.getSelectedImage([this.state.files[this.state.selectedCarouselImage]]);
    }

    render() {
        return (
            <>
                <div className=''>
                    <div
                        className='fixed h-full w-screen zm:bg-mytHeaderBg inset-0 z-60 flex md:items-center zm:items-end md:justify-center zm:justify-end'
                    >
                        <div className='z-50 bg-white rounded-lg md:p-10 zm:p-30px w-full h-full relative'>
                            <div className='flex justify-end cursor-pointer absolute top-1 right-1' onClick={this.closePopupImageCarousel}>
                                <div className='mt-1 mr-1'>
                                    {<CrossIcon />}
                                </div>
                            </div>
                            <div className='mb-0 h-full'>
                                <div className='w-full flex items-center justify-center'>
                                    <div className='w-5/6 font-semibold text-subText md:text-17 zm:text-17 pb-25px'>Photos captured during the class</div>
                                </div>
                                <div className="w-full flex justify-center h-5/6">
                                    {
                                        this.state.loading === true &&
                                        <Loader />
                                    }
                                    <Carousel {...{ selectedCarouselImage: this.state.selectedCarouselImage, files: this.state.files, changeCarouselIndex: this.changeCarouselIndex }} />
                                </div>
                                <div className='w-full flex items-center justify-center'>
                                    <div className="flex w-5/6 justify-between mt-25px">
                                        <p className='text-center mt-1 text-20px font-bold text-mytgrey-carouselTotalImage'>{this.state.selectedCarouselImage + 1}/{this.state.files.length}</p>
                                        <div className='flex'>
                                            <button onClick={this.shareImage} className='w-max btn btn-primary md:text-17 zm:text-17 md:py-2 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-9px'>
                                                Share with student
                                            </button>
                                            <button disabled={this.state.loading && this.props.delete_file_status !== 'success'} onClick={this.deleteCarouselImage} className='flex ml-2 items-center btn-white rounded-lg px-4 md:text-17 zm:text-17 md:py-2 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-9px'>
                                                Delete

                                                <span className='ml-2'>{<DeleteIcon />}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        delete_file_status: state.uploadFile.delete_file_status,
        delete_file_response: state.uploadFile.delete_file_response,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        deleteImage: files => {
            dispatch(actions.deleteImage(files))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel)