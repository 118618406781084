import React from 'react';
import './faq.css';
const FAQ = props => {
	let new_class = typeof props.component === 'undefined' ? '' : `-${props.component}`;
	let lp_6_class = props.component === 'home' ? 'lp-2-6 lp-2-6-home' : `lp-2-6`;
	return (
		<div className="lp-2-0">
			<div className="lp-2-1" />
			<div className="lp-2-2">
				<div className={`lp-2-3${new_class}`}>FAQ</div>
				{props.component === 'home' ? (
					<div className="lp2-mandala">
						<img width="100%" src="https://images.myyogateacher.com/home-page/Divider.png" alt="div" />
					</div>
				) : null}
				<div className="lp-2-4">
					<>
						<div className="lp-2-5">
							<div className={lp_6_class}>
								<p>#1</p>
							</div>
							<div className={`lp-2-8${new_class}`}>
								<div>I am interested in teaching, how should I get started?</div>
								<p>
									Please enter your email address on this website. https://teacher.myyogateacher.com. We will send you
									an email to kickstart the process! You can also email us your resume to teach@myyogateacher.com.
								</p>
							</div>
						</div>
						<div className="lp-2-5">
							<div className={lp_6_class}>
								<p>#2</p>
							</div>
							<div className={`lp-2-8${new_class}`}>
								<div>How long is a session?</div>
								<p>
									A session is 55 minutes which includes about 50 minutes of practice with about 5 minutes of opening
									and closing.
								</p>
							</div>
						</div>
						<div className="lp-2-5">
							<div className={lp_6_class}>
								<p>#3</p>
							</div>
							<div className={`lp-2-8${new_class}`}>
								<div>How do I communicate with the students?</div>
								<p>
									You can communicate with a student using chat feature inside the app. You will not have access to
									student’s email address or phone number. Please do not request the student’s email address or phone
									number without prior permission from myYogaTeacher Care team.
								</p>
								<a className="btn-border faqseemore" href="/faq">
									<span>See more</span>
									<img className="btn-img" src="https://images.myyogateacher.com/home-page/arrowo.png" alt="arr" />
								</a>
							</div>
						</div>
					</>
				</div>
			</div>
		</div>
	);
};

export default React.memo(FAQ);
