import * as t from '../action_types';
import { modifyFiles } from '../../utils/uploadFile_utils'

const INITIAL_STATE = {
  fileProgress: {
    // format will be like below
    // 1: {  --> this interpreted as uploaded file #1
    //   id: 1,
    //   file,
    //   progress: 0,
    // },
  },
  session_uuid: null,
  upload_file_status: 'none',
  upload_file_response: null,

  get_file_status: 'none',
  get_file_response: null,

  get_all_file_status: 'none',
  get_all_file_response: null,

  get_customized_image_status: 'none',
  get_customized_image_response: null,

  delete_file_status: 'none',
  delete_file_response: null,

  selected_files: null,
  upload_sharing_file_status : 'none'
}

const set_upload_file_variable = (state, payload) => {
  return {
    ...state,
    [payload.key]: payload.payload,
  };
};

const fileProgressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case t.set_upload_file_variable:
      return set_upload_file_variable(state, action.payload)

    case t.SET_UPLOAD_FILE:
      return {
        ...state,
        session_uuid: action.payload.session_uuid,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.payload.files),
        },
      }

    case t.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          },
        },
      }

    case t.SUCCESS_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
          },
        },
      }

    case t.FAILURE_UPLOAD_FILE:
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 0,
            progress: 0,
          },
        },
      }

    default:
      return state
  }
}

export default fileProgressReducer