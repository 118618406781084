import React from 'react'
import { WarningIcon } from '../livekit_assets/svg'

const RecordingPopUp = ({ yes, no }) => {
    return (
        <div className='fixed inset-0 z-10 overflow-y-auto popup-container'>
            <div className='flex min-h-full items-end justify-center text-center sm:items-center sm:p-0'>
                <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-400px'>
                    <div className='warning-wrapper'>
                        <div><WarningIcon /></div>
                        <div className='warning-title'>Stop recording</div>
                        <div className='warning-description'>Recording the session is recommended for quality assurance and future reference</div>
                        <div className='button-container'>
                            <button type='button' className='warning-red-button modal-button' onClick={yes}>
                                Stop recording
                            </button>
                            <button type="button" class="warning-cancel-button modal-button" onClick={no}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecordingPopUp