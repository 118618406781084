import React from 'react';
import { connect } from 'react-redux';
import { getCookie, image_error_replace, is_production } from '../../../util_functions';
import urls from '../../../urls';
import Spinner from '../../util_components/spinner';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import CommonTimer from '../../util_components/common_timer';
import './re_re.css';

class RequestReplacement extends React.Component {
	state = {
		sessions: [],
		open_re: false,
		action_loading: false,
	};
	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (prevProps.teacher_token !== this.props.teacher_token) {
			if (this.props.teacher_token === '') {
				this.close_socket();
			} else {
				this.close_socket();
			}
		}
	}

	componentWillUnmount() {
		this.close_socket();
	}

	close_socket = () => {
		if (this.socket) {
			this.socket.close();
		}
	};

	start_events = () => {
		this.socket.on('authenticated', () => {
			if (this.socketInterval || this.state.retry_times >= 10) {
				this.setState({ retry_times: 0 }, () => {
					clearInterval(this.socketInterval);
				});
			}
			this.socket.on('request_replacement_notification', (data) => {
				// console.log(data)
			});
			this.socket.on('session_accepted', (payload) => {
				this.props.load_dashboard_sessions();
				const teach =
					payload.data.teacher_uuid === this.props.teacher_details.uuid
						? 'You have'
						: `${payload.data.teacher_name} has`;
				const message = `${teach} accepted the session with ${payload.data.student_name} at ${payload.data.teacher_local_time}`;
				this.props.set_notification_variable(true, 'success', message);
			});
			this.socket.on('session_accept_failed', (data) => {
				if (!is_production()) {
					console.log('the failed data is ', data);
				}
				this.props.set_notification_variable(true, 'error', data.data.reason.message);
			});
			this.socket.on('get_replacement_sessions', (resp) => {
				const sessions = [...resp.data.sessions];
				this.setState({
					sessions,
					open_re: sessions.length !== 0,
					action_loading: false,
				});
			});
			this.socket.on('refresh_home', () => {
				this.props.load_dashboard_sessions();
			});
		});
		this.socket.on('disconnect', () => {
			// console.log('socket disconnected')
			this.setState({ open_re: false });
		});
		this.socket.on('error', (err) => {
			try {
				const resp_error = JSON.parse(err);
				if (resp_error.type === 'auth_error') {
					// console.log('closing socket');
					this.socket.close();
				}
			} catch (e) {
				if (!is_production()) {
					console.log('The socket error ', e);
				}
				this.socket.close();
			}
		});
		this.socket.on('connect_error', (err) => {
			if (!is_production()) {
				console.log('connection error', err);
			}
			this.socket.close();
		});
	};

	close_re_re = () => this.setState({ open_re: false });

	on_book_click = (uuid, type) => {
		this.setState({ action_loading: true }, () => {
			// this.socket.emit("accept_session", { session_uuid: uuid, type: type });
		});
	};
	on_reject_click = (uuid) => {
		this.setState({ action_loading: true }, () => {
			// this.socket.emit("reject_session", { session_uuid: uuid });
		});
	};
	on_later_click = (uuid) => {
		// this.socket.emit("show_later", { session_uuid: uuid });
	};

	render_single_session = (session) => {
		console.log('session------------------>', session);
		const is_group_session = session.type === 'GROUP_SESSION';
		const img_url = is_group_session
			? 'https://images.myyogateacher.com/icons/ic_group.png'
			: session.student.profile_photo;
		const teacher_local_time = is_group_session
			? `${session.teacher_local_time} (${session.duration} min)`
			: session.teacher_local_time;
		return (
			<div className='re-re-si-ses-full' key={session.uuid}>
				<div className='re-re-timer'>
					<CommonTimer timer_time={session.waiting_time_limit_for_replacement} />
				</div>
				<div className='re-re-telt'>{teacher_local_time}</div>
				<div className='re-re-si-ses'>
					<img src={img_url} onError={image_error_replace} />
					<div className='re-re-si-ses-de'>
						{is_group_session ? (
							<div className='re-re-name'>
								{`${session.group_session_name}`}
								<div className='dsh-group-cont'>
									<div className='dsh-group-title'>Group Class</div>
								</div>
							</div>
						) : (
							<div className='re-re-name'>{`${session.student.first_name} ${session.student.last_name}`}</div>
						)}
						{!is_group_session ? (
							<>
								<div className='re-re-yl'>{session.student.yoga_level}</div>
								<div className='re-re-slt'>Student local time: {session.student_local_time}</div>
							</>
						) : null}
						{is_group_session ? (
							<>
								<div className='re-re-slt'>
									Target audience: {session.group_session_target_audience}
								</div>
							</>
						) : null}
						{this.state.action_loading ? (
							<Spinner type='dots' />
						) : (
							<div className='re-re-btn'>
								<button
									className='re-re-acc'
									onClick={() => this.on_book_click(session.uuid, session.type)}
								>
									Accept
								</button>
								<button className='re-re-rej' onClick={() => this.on_reject_click(session.uuid)}>
									Reject
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const sessions = this.state.sessions;
		if (this.state.open_re) {
			return (
				<div className='re-re-con'>
					<img
						className='re-re-cl'
						src='https://images.myyogateacher.com/teacher_web_app/ic_cross@3x.svg'
						alt='c'
						onClick={this.close_re_re}
					/>
					<h4 className='re-re-title'>Request replacement sessions</h4>
					<div className='re-re-in-con'>{sessions.map(this.render_single_session)}</div>
				</div>
			);
		}
		return null;
	}
}

const mapStateToProps = (state) => {
	return {
		teacher_details: state.profile.teacher_details,
		teacher_token: selectors.get_teacher_token(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		set_notification_variable: (show, type, mes) => {
			dispatch(actions.set_notification_variable(show, type, mes));
		},
		load_dashboard_sessions: () => {
			dispatch(actions.load_dashboard_sessions());
		},
	};
};

const RequestReplacementWithRedux = connect(mapStateToProps, mapDispatchToProps)(RequestReplacement);
export default RequestReplacementWithRedux;
