import { createSelector } from 'reselect';
export const get_home_component_index = state => state.home.home_component_index;
export const get_teacher_uuid = state => state.home.teacher_uuid;
export const get_home_password_loading = state => state.home.password_loading;
export const get_home_reset_password_status = state => state.home.reset_password_status;
export const get_profile_verified_status = state => state.home.profile_verified_status;
export const get_login_status = state => state.home.login_status;
export const get_teacher_token = state => state.home.teacher_token;
export const get_forgot_status = state => state.home.forgot_status;
export const load_application_status = state => state.home.load_application_status;
export const get_signup_status = state => state.home.signup_status;
export const login_error_msg = state => state.home.login_error_msg;
export const forgot_error_msg = state => state.home.forgot_error_msg;
export const my_sessions = state => state.home.my_sessions;
export const create_roadmap_status = state => state.home.create_roadmap_status;
export const update_session_recap_status = state => state.home.update_session_recap_status;
export const update_roadmap_ques_status = state => state.home.update_roadmap_ques_status;
export const update_whole_roadmap_status = state => state.home.update_whole_roadmap_status;
export const get_yoga_journey_status = state => state.home.get_yoga_journey_status;

export const get_my_sessions = createSelector(my_sessions, my_sessions => my_sessions);
