import { all, put, takeEvery, takeLatest, call, delay, select } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
// import * as selectors from '../selectors';
// import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { error_log } from '../../utils';
import { put_api, post_api, get_api, patch_api } from '../../utils/config';
import urls from '../../urls/urls';

export function* getTeacherScheduleList(action) {
  try {
    yield put(actions.set_home_variable('get_teacher_list_status', 'loading'));
    const get_teacher_list = urls.getTeacherList;
    const payload = {
      ...action.payload
    };
    const response = yield call(get_api, get_teacher_list, true);
    yield put(actions.set_home_variable('get_teacher_list_response', response.data));

    yield put(actions.set_home_variable('get_teacher_list_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_home_variable('get_teacher_list_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* getTeacherNotification(action) {
  try {
    yield put(actions.set_home_variable('get_teacher_notifications_status', 'loading'));
    const get_teacher_notifications = urls.getTeacherNotification;
    const response = yield call(post_api, get_teacher_notifications, true);
    yield put(actions.set_home_variable('get_teacher_notifictions_response', response.data));
    yield put(actions.set_home_variable('get_teacher_notifications_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_home_variable('get_teacher_notifications_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* getTeacherTimeSlots(action) {
  try {
    yield put(actions.set_home_variable('get_teacher_time_slots_status', 'loading'));
    const payload = {
      ...action.payload
    };
    const get_Teacher_Time_slots = urls.getTeacherTimeSlots;
    const response = yield call(post_api, get_Teacher_Time_slots, payload, true);
    yield put(actions.set_home_variable('get_teacher_time_slots_response', response.data));
    yield put(actions.set_home_variable('get_teacher_time_slots_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_home_variable('get_teacher_time_slots_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* handleErrorFromApi(action) {
  try {
    const { error } = action.payload;
    const { type } = action.payload;

    if (Object.keys(error).includes('request')) {
      const responseString = Object.keys(error).includes('response')
        ? JSON.stringify(error.response)
        : '';
      error_log('the error is');
      error_log(error);
      try {
        if (error.response.status === 401) {
          // yield put(actions.log_out());
        }
      } catch {}
    } else {
      error_log('the error is');
      error_log(error);
    }
  } catch (e) {}
}

export function* getNotifications(action) {
  yield put(actions.set_loading_variable('get_notifications_status', 'loading'));
  try {
    const url = urls.get_notifications;
    const payload = { ...action.payload };
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_home_variable('get_notifications_response', response.data.results));
    yield put(actions.set_loading_variable('get_notifications_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('get_notifications_status', 'fail'));
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* updateNotificationReadStatus(action) {
  yield put(actions.set_loading_variable('update_notification_status', 'loading'));
  try {
    const url = urls.update_notification_read_status;
    const payload = { ...action.payload };
    const response = yield call(post_api, url, payload, true);
    yield put(actions.set_home_variable('update_notification_read_status_response', response.data));
    yield put(actions.set_loading_variable('update_notification_status', 'success'));
  } catch (error) {
    yield put(actions.set_loading_variable('update_notification_status', 'fail'));
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* update_session_time_duration(action) {
  try {
    yield put(actions.set_home_variable('update_session_time_duration_status', 'loading'));

    const payload = {
      ...action.payload
    };
    const { update_session_time_duration } = urls;
    const response = yield call(post_api, update_session_time_duration, payload, true);

    yield put(actions.set_home_variable('update_session_time_duration_response', response.data));
    yield put(actions.set_home_variable('update_session_time_duration_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(
      actions.set_home_variable('update_session_time_duration_response', error.response.data)
    );
    yield put(actions.set_home_variable('update_session_time_duration_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* cancel_sessions(action) {
  try {
    yield put(actions.set_home_variable('cancel_session_status', 'loading'));

    const payload = {
      ...action.payload
    };
    const { cancel_sessions } = urls;
    const response = yield call(post_api, cancel_sessions, payload, true);

    yield put(actions.set_home_variable('cancel_session_response', response.data));
    yield put(actions.set_home_variable('cancel_session_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_home_variable('cancel_session_error', error.response.data));
    yield put(actions.set_home_variable('cancel_session_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* replacement_session(action) {
  try {
    yield put(actions.set_home_variable('replacement_session_status', 'loading'));

    const payload = {
      ...action.payload
    };
    const cancel_session = urls.replacement_session;
    const response = yield call(post_api, cancel_session, payload, true);

    yield put(actions.set_home_variable('replacement_session_response', response.data));
    yield put(actions.set_home_variable('replacement_session_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_home_variable('replacement_session_response', error.response.data));
    yield put(actions.set_home_variable('replacement_session_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

//  Subscription data

export function* get_subscription_data(action) {
  try {
    yield put(actions.set_home_variable('get_subscription_data_status', 'loading'));
    const get_teacher_list = urls.subscription_data;
    const payload = {
      ...action.payload
    };
    const response = yield call(post_api, get_teacher_list, payload, true);
    yield put(actions.set_home_variable('get_subscription_data_response', response.data));

    yield put(actions.set_home_variable('get_subscription_data_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_home_variable('get_subscription_data_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export default function* homeSaga() {
  yield all([
    takeEvery(t.handle_error_from_api, handleErrorFromApi),
    takeEvery(t.getTeacherList, getTeacherScheduleList),
    takeEvery(t.get_notifications, getNotifications),
    takeEvery(t.get_teacher_time_slots, getTeacherTimeSlots),
    takeEvery(t.update_notification_read_status, updateNotificationReadStatus),
    takeEvery(t.update_session_time_duration, update_session_time_duration),
    takeEvery(t.cancel_sessions, cancel_sessions),
    takeEvery(t.replacement_session, replacement_session),
    takeEvery(t.get_subscription_data, get_subscription_data)
  ]);
}
