import React, { Component } from 'react';
import CustomDropdown from '../../../components/util_components/custom_dropdown';
import { cancel_reasons } from '../../../constants';
import CheckBox from '../../../new_teacher_design/components/FixedComponent/Input/Checkbox';
import SwitchButton from '../../../new_teacher_design/components/FixedComponent/SwitchButton';
import urls from '../../../new_teacher_design/urls/urls';
import { post_api } from '../../../new_teacher_design/utils/config';
import ConfirmPopup from './ConfirmPopup';

export default class CancelSessionPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancel_reason: '',
            other_details: '',
            cancel_error: false,
            is_student_requested: false,
            cancel_all_future_repeat_class: false,
            session_uuid: this.props.session_uuid,
            warning_msg: '',
            btn_disable: true,
            show_successfull_popup: false,
            error_log: '',
            show_api_error: false
        };
    }
    change_cancel_reason = val => {
        this.setState({
            cancel_reason: val,
            other_details: '',
            cancel_error: false,
            is_student_requested: false,
            show_api_error: false,
            error_log: ''
        }, () => {
            this.load_data()
        });
    };
    handleDetails = e => {
        const capitalizedOtherDetails = e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1);
        this.setState({ other_details: capitalizedOtherDetails });
    };

    trigger_cancel_session_warning = async() => {
        const {session_uuid, is_student_requested, cancel_all_future_repeat_class, cancel_reason} = this.state
        let payload = {
        "session_uuid": session_uuid,
        "cancel_requested_by": is_student_requested ? "STUDENT" : "TEACHER",
        "repeat_cancel": cancel_all_future_repeat_class ? 1 : 0
        }
        let url = urls.cancel_session_warning
        let response = await post_api(url, payload, true)
        if (response.data.status === 'success') {
          this.setState({
              warning_msg: response?.data?.warning, 
              btn_disable: false,
              show_api_error: false,
              error_log: ''
            })
        }
      }
      

      load_data = () => {
        const {session_uuid, is_student_requested, cancel_reason} = this.state 
        if ((!!session_uuid && is_student_requested) ||( !!session_uuid && cancel_reason !== '')) {
            this.trigger_cancel_session_warning()
        }
      }



    confirm_cancel = async() => {
        const {btn_disable, session_uuid, cancel_reason, other_details, cancel_all_future_repeat_class, is_student_requested} = this.state
        let payload = {
            "session_uuid": session_uuid,
            "reason_for_cancel": cancel_reason,
            "other_details": other_details,
            "cancel_requested_by": is_student_requested ? "STUDENT" : "TEACHER",
            "repeat_cancel": cancel_all_future_repeat_class ? 1 : 0

        }
        let url = urls.cancel_session_v1
        if (!btn_disable) {

            post_api(url, payload, true)
            .then((res) => {
                if (res.data.status === 'success') {
                    this.setState({show_successfull_popup: true})
                }
            })
            .catch((err) => {
                console.log('err: ', err);
                this.setState({error_log: err?.response?.data?.message, show_api_error: true, warning_msg: ''})
            })

        }
    };

    closeConfirmPopup = () => {
        this.setState({show_successfull_popup: false}, () => {
            this.props.cancelFunc();
            window.location.reload();
        })
    }

    is_requested_by_student = () => this.setState({ is_student_requested: !this.state.is_student_requested, cancel_reason: '', other_details: ''}, () => {
        this.load_data()
    })
    handle_cancel_all_repeat_class = () => this.setState({ cancel_all_future_repeat_class: !this.state.cancel_all_future_repeat_class }, () => {
        this.load_data()
    })
    render() {
        const { is_student_requested, cancel_all_future_repeat_class, warning_msg, btn_disable, show_successfull_popup, cancel_reason, show_api_error, error_log } = this.state
        let is_btn_disable = is_student_requested === true || cancel_reason !== '' ? false : true

        return (
            <div className="relative inset-0 z-50">
                <div className={` ${show_successfull_popup ? 'hidden' : 'block'} fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end`}>
                    <div className="z-50 bg-white zm:rounded-2xl zm:rounded-tl-3xl zm:rounded-tr-3xl zm:rounded-bl-none zm:rounded-br-none md:rounded-lg md:p-40px zm:px-30px zm:py-40px md:w-492px zm:w-full relative">
                        <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
                            <div onClick={this.props.cancelFunc}>
                                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect width='34' height='34' rx='17' fill='none' />
                                    <path
                                        d='M22 12L12 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        d='M12 12L22 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                        </div>
                        {typeof this.props.header !== 'undefined' ? <div className="cancel-popup-header">Header</div> : null}
                        <div className="font-semibold text-subText md:text-19 zm:text-17 mb-25px" dangerouslySetInnerHTML={{ __html: this.props.bodyText }}></div>
                        <div className=''>
                            <div className='bg-mytGrayLightV3 rounded-lg p-15px mb-25px flex items-center justify-between gap-20px'>
                                <div className='flex flex-col'>
                                    <p className='text-mytLabel text-15 font-medium'>Student asked to cancel the class</p>
                                </div>
                                <div className='flex md:items-center zm:items-start'>
                                    {is_student_requested === true ?
                                        <p className='text-mytLabel mr-15px text-15 font-medium md:flex zm:hidden'>Yes</p>
                                        :
                                        <p className='text-mytLabel text-15 font-medium md:flex zm:hidden mr-15px'>No</p>
                                    }
                                    <SwitchButton id={'1'} isChecked={is_student_requested} value={'yes'} onClick={this.is_requested_by_student} />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <p className='text-mytLabel text-13.5px leading-16px font-medium'>Reason</p>

                            <CustomDropdown
                                className={`input-date rounded-lg text-15 font-medium shadow-sm flex items-center justify-between ${is_student_requested ?  'formInputDisable ' :'formInput '} border mt-8px w-full`} 
                                value={this.state.cancel_reason}
                                handleChange={this.change_cancel_reason}
                                drop_down_list={cancel_reasons}
                                width='100%'
                                dropDownIcon='https://images.myyogateacher.com/web_images/header/v1/chevron-down-icon.svg'
                                dropDownContent='w-auto'
                                dropDownContents='absolute top-60px h-200px overflow-scroll pt-25px pb-13px w-max bg-white justify-center border border-gray-200 shadow-mytboxshadow rounded-lg cursor-pointer px-30px gap-12px z-10'
                                dropDownValues='class-btn-size text-black font-inter font-semibold text-15.5px leading-19px mb-12px'
                                is_disable={is_student_requested ? true : false}
                            />
                            {' '}
                        </div>
                        <div className="flex flex-col mt-25px">
                            <div className='text-mytLabel text-13.5px leading-16px font-medium'>Any other reason</div>
                            <textarea
                                className='input-date w-full rounded-lg text-15 font-medium shadow-sm flex items-center formInput border pt-10px h-100px resize-none leading-22px mt-8px'
                                onChange={this.handleDetails}
                                value={this.state.other_details}
                                width="400px"
                                placeholder='Enter here...'
                            />
                        </div>
                            {this.props.is_repeat === 1 ? <div className='mt-5 bg-mytTrailCard py-15px px-5 mb-15px rounded-lg'>
                                <CheckBox
                                    id="1"
                                    label="Cancel all future repeat classes"
                                    className='cancel-future-class'
                                    value=''
                                    isChecked={cancel_all_future_repeat_class}
                                    onClick={this.handle_cancel_all_repeat_class}
                                />
                            </div> : <></>}
                        {is_btn_disable === false ? <div className={`message text-mytgrey-lite text-13.5px font-medium ${this.props.is_repeat === 1 ? 'mb-15px mt-0' : 'mb-0 mt-15px'}`}>{warning_msg}</div> : <></>}
                        {show_api_error ? <div className='message text-mytRedText2 text-13.5px font-medium mb-15px'>{error_log}</div> : null}
                        <div>
                            <div className="flex items-center justify-start">
                                <button className={`btn btn-primary cursor-pointer zm:w-full md:w-auto text-17px h-49px ${this.props.is_repeat === 0 ? 'mt-5' : 'mt-0 '} ${btn_disable || is_btn_disable ? 'opacity-50 pointer-events-none' : '' }`} disabled={btn_disable} onClick={this.confirm_cancel}>Cancel this class</button>
                            </div>
                        </div>
                    </div>
                </div>
                {show_successfull_popup ?
                <ConfirmPopup
                closeConfirmPopup = {this.closeConfirmPopup}
                is_from_change_session={false}
                />: null
             }
            </div>
        )
    }
}
