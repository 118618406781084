import React from "react";
import "./loading.css";
const Test = () => {
  return (
    <div className="custom-loading">
      <img src="https://images.myyogateacher.com/web_images/web_prod/mobileHeaderIcon.png" alt="logo"
        width="256" height="28" style={{ width: "75px", height: "60px" }} />
    </div>
  );
};

export default React.memo(Test);
