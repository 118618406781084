import React from 'react';
import './s_workshop_detail.css';
import { Player, BigPlayButton } from 'video-react';
import { connect } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import ChatService from 'myt-chat/src/external/ChatService';
import * as actions from '../../../redux/action_creators';

import CompleteVideo from '../../util_components/complete_video';

import Opencustpop from '../open_cust_pop/opencustpop';
import LoadingScreen from '../../util_components/loading_screen';

import SimplePop from './simple_pop';
import Header from '../../util_components/header';
import './classroom.css';
import './confirm.css';

import './chat_pop.css';

import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class SingleWorkshopDetail extends React.Component {
  state = {
    loading: true,
    view_teacher_profile: false,
    open_teacher_uuid: '',
    is_read_more: false,
    work_des_read_more: false,
    aabt_work_toggle_read_more: false,
    link_copied: false,
    copy_open: false,
    close_copy: false,
    open_share_popup: false,
    // for class enrollment
    openPopupViewPartici: false,
    open_big_video: false,
    current_video_url: '',
    sessions_data: [],
    sessions_data_all: [],
    breadcrumbs_names: [],
    enrolledStudentsList: [],

    upcomingClassList: [],
    recentClassList: [],
    upcomingClassListAll: [],
    recentClassListAll: [],

    ratingReviewsList: [],

    openPaymentReceipt: false,
    openTopSecReadMore: false,
    showInterestPop: false,
    work_sess_des_read_more: false,
    abt_teacher_read_more: false,
    atBottom: false,

    show_img_value: null
  };

  constructor(props) {
    super(props);

    this.props.get_teacher_details();
  }

  preventRightClick(event) {
    event.preventDefault();
  }

  scrollHandler() {
    this.setState({
      atBottom: window.scrollY >= window.innerHeight
    });
  }

  componentDidMount() {
    window.onscroll = this.scrollHandler.bind(this);
    const body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = '#f5f6fa';

    if (!this.props.router.params.id) {
      this.props.router.navigate('/workshops');
      return;
    }

    this.preview = !!new URLSearchParams(this.props.router.location.search).get('preview');
    this.preview_mode = new URLSearchParams(this.props.router.location.search).get('preview')
      ? ''
      : '[PREVIEW MODE]';

    if (this.props.profile_loading_status === 'success') {
      this.props.get_workshop_details({
        workshop_slug: this.props.router.params.id,
        iana_timezone: moment.tz.guess(true)
      });
    }

    sendEventToClarity();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profile_loading_status === 'loading' &&
      this.props.profile_loading_status === 'success'
    ) {
      this.props.get_workshop_details({
        workshop_slug: this.props.router.params.id,
        iana_timezone: moment.tz.guess(true)
      });
    }

    if (
      prevProps.workshopDetailsStatus === 'loading' &&
      this.props.workshopDetailsStatus === 'success'
    ) {
      if (
        this.props.workshopDetailsResponse.teacher_uuid !== this.props.teacher_details.uuid &&
        this.props.workshopDetailsResponse.co_teachers.findIndex(
          (ws) => ws === this.props.teacher_details.uuid
        ) === -1
      ) {
        this.props.router.navigate('/home');
      }

      ChatService.openGroupChat(this.props.workshopDetailsResponse?.group_chat_channel_id, true);
      const sessions_data = [];
      let sessions_data_all;

      const upcoming_session_list = [];
      const upcoming_session_list_all = [];

      const recent_session_list = [];
      const recent_session_list_all = [];

      if (true) {
        this.props.workshopDetailsResponse.sessions_data.reverse().map((sessionData) => {
          if (sessionData.epoch_start_time > Date.now()) {
            upcoming_session_list_all.push(sessionData);
            upcoming_session_list.push(sessionData);
          } else {
            recent_session_list_all.push(sessionData);
            recent_session_list.push(sessionData);
          }
        });
      }

      let breadcrumbpreview = '';
      if (this.preview) {
        breadcrumbpreview = `${this.props.workshopDetailsResponse.workshop_name  } [PREVIEW MODE]`;
      } else {
        breadcrumbpreview = this.props.workshopDetailsResponse.workshop_name;
      }
      const breadcrumbs_names = [
        {
          name: 'Workshops',
          url: '/workshops'
        },
        {
          name: breadcrumbpreview,
          url: `/workshops/${  this.props.workshopDetailsResponse.slug}`
        }
      ];

      const enrolledStudentsList =
        this.props.workshopDetailsResponse.students_enrolled_list.length > 5
          ? this.props.workshopDetailsResponse.students_enrolled_list.slice(0, 5)
          : this.props.workshopDetailsResponse.students_enrolled_list;

      const ratingReviewsList = this.props.workshopDetailsResponse.reviews_ratings.slice(0, 3);

      this.setState({
        loading: false,
        sessions_data,
        sessions_data_all,
        breadcrumbs_names,
        enrolledStudentsList,
        recentClassList:
          recent_session_list.length > 2 ? recent_session_list.slice(0, 2) : recent_session_list,
        recentClassListAll: recent_session_list_all,
        upcomingClassList:
          upcoming_session_list.length > 1
            ? upcoming_session_list.reverse().slice(0, 1)
            : upcoming_session_list,
        upcomingClassListAll: upcoming_session_list_all,
        ratingReviewsList
      });
    }
  }

  componentWillUnmount() {
    // todo: close this.groupChannel.channelUuid
    document.removeEventListener('contextmenu', this.preventRightClick);
  }

  showInterestPop = () => {
    this.setState({
      showInterestPop: true
    });
  };

  closeInterestPop = () => {
    this.setState({
      showInterestPop: false
    });
  };

  openPaymentReceipt = () => {
    this.setState({
      openPaymentReceipt: true
    });
  };

  closePaymentReceipt = () => {
    this.setState({
      openPaymentReceipt: false
    });
  };

  show_payrec_content = () => (
      <div className="sw-payment-cont">
        <div className="sw-pay-rec-row">
          <span>Membership plan:</span>
          <h4>{this.props.workshopDetailsResponse.payment_details.membership_plan}</h4>
        </div>
        <div className="sw-pay-rec-row">
          <span>Receipt number:</span>
          <h4>{this.props.workshopDetailsResponse.payment_details.invoice_id}</h4>
        </div>
        <div className="sw-pay-rec-row">
          <span>Transaction date:</span>
          <h4>{this.props.workshopDetailsResponse.payment_details.transaction_date}</h4>
        </div>

        <div className="sw-pay-rec-row">
          <span>Card:</span>
          <h4>
            {this.props.workshopDetailsResponse.payment_details.card_type},
            {this.props.workshopDetailsResponse.payment_details.card_last4}
          </h4>
        </div>

        <div className="sw-pay-rec-row">
          <span>Amount:</span>
          <h4>{this.props.workshopDetailsResponse.payment_details.purchase_price}</h4>
        </div>
      </div>
    );

  breadcrumb_back = () => {
    this.props.router.navigate('/workshops');
  };

  // for enrolled classroom
  openPopupViewPartici = () => {
    this.setState({
      openPopupViewPartici: true
    });
  };

  closePopupViewPartici = () => {
    this.setState({
      openPopupViewPartici: false
    });
  };

  open_big_video = (url) => {
    this.setState({ open_big_video: true, current_video_url: url });
  };

  close_big_video = () => {
    this.setState({ open_big_video: false, current_video_url: '' });
  };

  show_opc_content = () => (
      <div>
        <ul className="paricipi_ul">
          {!!this.props.workshopDetailsResponse.students_enrolled_list &&
            this.props.workshopDetailsResponse.students_enrolled_list.map(
              (enrolledStudents, index) => (
                  <li key={index}>
                    <div
                      className="partici_pic"
                      style={{
                        backgroundImage: `url(), url(${enrolledStudents.profile_photo})`
                      }}
                     />
                    <span className="partici_name">{enrolledStudents.name}</span>
                  </li>
                )
            )}
        </ul>
      </div>
    );

  render_course_info_video = () => {
    const url = this.props.workshopDetailsResponse.teacher_video_url;
    const thumbnail = this.props.workshopDetailsResponse.video_thumbnail_url
      ? this.props.workshopDetailsResponse.video_thumbnail_url
      : 'https://images.myyogateacher.com/icons/transparent_16_9.png';

    let type = null;
    if (url.indexOf('.mp4') !== -1) {
      type = 'video/mp4';
    } else if (url.indexOf('.m3u8') !== -1) {
      type = 'application/x-mpegURL';
    }
    if (type) {
      return (
        <div className="sw-t-course-video">
          <img alt="video-pic" className="tw-thumbnail" src={thumbnail} />

          <div className="sw-t-course-video-inner">
            <CompleteVideo
              key={url}
              src={url}
              show_controls
              type={type}
              thumbnail={thumbnail}
              frame="vjs-fill"
              video_play_event="teacher_profile : teacher_profile : intro_video - played"
              video_pause_event="teacher_profile : teacher_profile : intro_video - paused"
            />
          </div>
        </div>
      );
    }
    return null;
  };

  render_part_info_video = () => {
    const url = this.props.workshopDetailsResponse.teacher_video_url;
    const thumbnail = 'https://i.vimeocdn.com/video/861638219_360.jpg';

    let type = null;
    if (url.indexOf('.mp4') !== -1) {
      type = 'video/mp4';
    } else if (url.indexOf('.m3u8') !== -1) {
      type = 'application/x-mpegURL';
    }
    if (type) {
      return (
        <div className="sw-prst-video">
          <img alt="video-pic" className="tw-thumbnail" src={thumbnail} />

          <div className="sw-t-course-video-inner">
            <CompleteVideo
              key={url}
              src={url}
              type={type}
              thumbnail={thumbnail}
              frame="vjs-fill"
              video_play_event="teacher_profile : teacher_profile : intro_video - played"
              video_pause_event="teacher_profile : teacher_profile : intro_video - paused"
            />
          </div>
        </div>
      );
    }
    return null;
  };

  // enrolled class popups ends here
  share_link = (is_referYourFriends = false) => {
    this.setState({ open_share_popup: true });
  };

  close_share_popup = () => {
    this.setState({
      open_share_popup: false
    });
  };

  close_copy = () =>
    this.setState({ close_copy: true }, () =>
      setTimeout(() => {
        this.setState({ copy_open: false, close_copy: false });
      }, 400)
    );

  openTeacherProfile = (teacher_uuid) => {
    this.setState({
      view_teacher_profile: true,
      open_teacher_uuid: teacher_uuid
    });
  };

  close_teacher_profile = () => {
    this.setState({ view_teacher_profile: false });
  };

  renderStars = (
    value = parseFloat(this.props.workshopDetailsResponse.average_rating).toFixed(1)
  ) => {
    const starCount = Math.floor(value);
    const fraction = value % starCount;

    const starComponent = [];
    for (let i = 0; i < starCount; i++) {
      starComponent.push(<span key={`plus-${  i}`} className="cmt-star plus" />);
    }

    if (fraction >= 0.75) {
      starComponent.push(
        <span key="minus-three-quarter" className="cmt-star minus-three-quarter" />
      );
    } else if (fraction >= 0.5) {
      starComponent.push(<span key="minus-half" className="cmt-star minus-half" />);
    } else if (fraction >= 0.25) {
      starComponent.push(<span key="minus-quarter" className="cmt-star minus-quarter" />);
    } else if (fraction > 0) {
      starComponent.push(<span key="minus" className="cmt-star minus" />);
    }

    for (let i = starCount + 1; i < 5; i++) {
      starComponent.push(<span key={`minus-${  i}`} className="cmt-star minus" />);
    }

    return starComponent;
  };

  toggle_read_more = () => {
    try {
    } catch (error) {
      console.log(error);
    }

    this.setState({
      work_des_read_more: !this.state.work_des_read_more
    });
  };

  open_work_sess_des_read_more = () => {
    this.setState({
      work_sess_des_read_more: true
    });
  };

  abt_teacher_read_more = () => {
    this.setState({
      abt_teacher_read_more: !this.state.abt_teacher_read_more
    });
  };

  close_work_sess_des_read_more = () => {
    this.setState({
      work_sess_des_read_more: false
    });
  };

  abt_work_toggle_read_more = () => {
    this.setState({
      abt_work_toggle_read_more: !this.state.abt_work_toggle_read_more
    });
  };

  renderCourseSchedule() {
    const percent =
      (this.props.workshopDetailsResponse.classes_finished /
        this.props.workshopDetailsResponse.total_classes) *
      100;

    // enrolled workshop item
    return (
      <div className="when-enrolled sw-workshop-syll">
        <div className="sw-body-cont">
          <div className="sw-up-progress-head">
            <h2>Course schedule ({this.props.workshopDetailsResponse.workshop_time_string})</h2>

            {this.props.workshopDetailsResponse.total_classes > 0 && (
              <div className="sw-lp-meter-desk">
                <span className="sww_class_meter_info">
                  {this.props.workshopDetailsResponse.classes_finished} /{' '}
                  {this.props.workshopDetailsResponse.total_classes} classes
                </span>
                <div className="sw_meter">
                  <span style={{ width: `${percent}%` }} />
                </div>
              </div>
            )}
          </div>

          <div className="sw-course-timeline">
            {this.state.upcomingClassList.length > 0 && (
              <h4 className="sw_prst_text">
                <span>Upcoming class</span>
              </h4>
            )}
            {this.state.upcomingClassList.map((sessionData, index) => (
                <div key={index} className="sw_ct_cont sw-present-course">
                  <div className="sw_ct_date">
                    <h5>{sessionData.session_number}</h5>
                    <span className="sw_ct_month">{sessionData.start_date}</span>
                  </div>

                  <div className="sw_ct_info-up">
                    <div className="sw_ct_info">
                      <h4>{sessionData.workshop_session_name}</h4>

                      {!!sessionData.workshop_session_description && (
                        <>
                          {sessionData.workshop_session_description.length > 100 ? (
                            !this.state.work_sess_des_read_more ? (
                              <p>
                                {sessionData.workshop_session_description.slice(0, 100)}
                                <span
                                  className="sw-read-more"
                                  onClick={this.open_work_sess_des_read_more}
                                >
                                  More
                                </span>
                              </p>
                            ) : (
                              <p>
                                {sessionData.workshop_session_description}
                                <span
                                  className="sw-read-more"
                                  onClick={this.close_work_sess_des_read_more}
                                >
                                  Less
                                </span>
                              </p>
                            )
                          ) : (
                            <p>{sessionData.workshop_session_description}</p>
                          )}
                        </>
                      )}

                      <p className="sw_class_time">
                        <img
                          alt="time"
                          src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                        />{' '}
                        {sessionData.timing_text}
                      </p>
                    </div>
                    <div className="row-btn-class-sch">
                      {this.state.upcomingClassListAll.length !==
                        this.state.upcomingClassList.length && (
                        <button
                          onClick={() => {
                            this.setState({
                              upcomingClassList: this.state.upcomingClassListAll,
                              recentClassList: this.state.recentClassListAll
                            });
                          }}
                          className="sw-btn sw_view_sch_btn"
                        >
                          See entire schedule
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}

            {this.state.recentClassList.length > 0 &&
              this.state.recentClassList.map((sessionData, index) => (
                  <div key={index}>
                    {index === 0 && <h4 className="sw_recent_class_text">Recent classes</h4>}
                    <div className="sw_ct_cont">
                      <div className="sw_ct_date sw-recent-top-date">
                        <h5>{sessionData.session_number}</h5>
                        <span className="sw_ct_month">{sessionData.start_date}</span>
                      </div>
                      <div className="sw_ct_info-up sw-recent-top-class">
                        <div className="sw_ct_info">
                          <div className="sw_ct_info-in">
                            <div className="sw_ct_text">
                              <h4>{sessionData.workshop_session_name}</h4>
                              {/* <p>{sessionData.session_number_text}</p> */}
                              {!!sessionData.workshop_session_description && (
                                <p>{sessionData.workshop_session_description}</p>
                              )}
                              <p className="sw_class_time">
                                <img
                                  alt="time"
                                  src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                                />{' '}
                                {sessionData.timing_text}
                                {/* <span className='sw_time_zone'>{this.props.student_details.student_timezone}</span> */}
                              </p>
                            </div>
                            <div className="sw_ct_video">
                              <img
                                className="sw_ct_part_vid"
                                src="https://images.myyogateacher.com/mysession-video2.png"
                                alt="video"
                              />
                              <div className="sw_ct_part_vid_cont">
                                <button
                                  className="v-play-btn"
                                  onClick={() => {
                                    if (sessionData.replay_url) {
                                      this.open_big_video(sessionData.replay_url);
                                    }
                                  }}
                                >
                                  <img
                                    src="https://images.myyogateacher.com/play-sm-icon.png"
                                    alt="play"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          {!!sessionData.teacher_notes && (
                            <div className="sw_teacher_notes">
                              <h4>Teacher notes</h4>
                              <p>{sessionData.teacher_notes}</p>
                            </div>
                          )}
                          {!!sessionData.my_notes && (
                            <div className="sw_my_notes">
                              <h4>My notes</h4>
                              <p>{sessionData.my_notes}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>

          {this.state.recentClassList.length !== this.state.recentClassListAll.length && (
            <div className="sw-time-load-more-btn">
              <button
                onClick={() => {
                  this.setState({
                    recentClassList: this.state.recentClassListAll
                  });
                }}
                className="sw-btn sw-load-more-btn"
              >
                Load more
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  openTopSecReadMore = () => {
    this.setState({
      openTopSecReadMore: true
    });
  };

  closeTopSecReadMore = () => {
    this.setState({
      openTopSecReadMore: false
    });
  };

  renderRenderAbout() {
    return (
      <div className="sw-abt-teacher">
        <div className="sw-body-cont">
          <h2>About Teacher</h2>
          <div className="sw-teacher-breif-info">
            <div
              className="sw-t-img"
              style={{
                backgroundImage: `url(${this.props.workshopDetailsResponse.teacher_profile}), url("https://images.myyogateacher.com/profile-pic.png")`
              }}
             />

            <div className="sw-t-detail">
              <h4 className="sw-b-tname">{this.props.workshopDetailsResponse.teacher_name}</h4>
              <p className="mb-t-abt">
                {this.props.workshopDetailsResponse.teacher_intro.length > 200 ? (
                  !this.state.abt_teacher_read_more ? (
                    <>
                      {this.props.workshopDetailsResponse.teacher_intro.slice(0, 160)}
                      ...
                      <span className="abw-read-more" onClick={this.abt_teacher_read_more}>
                        More
                      </span>
                    </>
                  ) : (
                    <>
                      {this.props.workshopDetailsResponse.teacher_intro}
                      <span className="abw-read-more" onClick={this.abt_teacher_read_more}>
                        Less
                      </span>
                    </>
                  )
                ) : (
                  this.props.workshopDetailsResponse.teacher_intro
                )}
              </p>

              <p className="desk-t-abt">{this.props.workshopDetailsResponse.teacher_intro}</p>
              <button
                className="sw-btn sw-t-profile"
                onClick={() => {
                  this.openTeacherProfile(this.props.workshopDetailsResponse.teacher_uuid);
                }}
              >
                View Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCourseVideo() {
    return (
      <div className="sw-video-sec">
        <div className="sw-body-cont">
          <h2>What you will learn</h2>

          <div className="video-cont">{this.render_course_info_video()}</div>
        </div>
      </div>
    );
  }

  render_price = () => {
    const details = this.props.workshopDetailsResponse;

    return (
      <>
        <h4 className="price-w">{`$${details.package_price - details.discount_for_members}`}</h4>
        <p className="red-crossed-line">{`$${details.package_price} standard rate`}</p>
      </>
    );
  };

  render_price_for_mobile = () => {
    const details = this.props.workshopDetailsResponse;
    const show_instalments = details.show_instalments === 1;
    return (
      <>
        <p className="sw-p-para mb-highlight-price">
          {`$${details.package_price - details.discount_for_members}`}
          {show_instalments ? (
            <>
              <span className="price-w-or">&nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;</span>
              <span>{`${details.number_of_instalments}x payments of $${details.instalment_price}`}</span>
            </>
          ) : null}
        </p>
        <h4 className="mb-std-rate-cross-line">{`$${details.package_price} standard rate`}</h4>
      </>
    );
  };

  show_video_content = () => (
      <div onContextMenu={this.contextMenu} className="single-workshop-video">
        <Player playsInline autoPlay src={this.state.current_video_url}>
          <BigPlayButton position="center" />
        </Player>
      </div>
    );

  render() {
    if (this.state.loading) {
      return <LoadingScreen />;
    }
    const workshop_details = this.props.workshopDetailsResponse;
    const payment_price =
      !!workshop_details.payment_details && Object.keys(workshop_details.payment_details).length > 0
        ? workshop_details.payment_details.purchase_price
        : `$${workshop_details.package_price}`;
    return (
      <div className={`sw-detail-page ${!true ? 'sw-detail-page-btm-pad' : ''}`}>
        <Header />
        <div className="sw-top-section">
          {this.props.workshopDetailsResponse.banner_image ? (
            <img
              alt="banner"
              className="sw-top-banner-img"
              src={this.props.workshopDetailsResponse.banner_image}
            />
          ) : (
            <img
              alt="banner"
              className="sw-top-banner-img"
              src="https://images.myyogateacher.com/bg_img_sw1.png"
            />
          )}

          <div className="sw-top-sec-cont">
            <div className="container-top-sec" />

            <div className="sw-top-sec-text">
              <div className="container">
                <h1>{this.props.workshopDetailsResponse.workshop_name}</h1>
                <p>
                  {this.props.workshopDetailsResponse.workshop_description.length > 200 ? (
                    !this.state.work_des_read_more ? (
                      <>
                        {this.props.workshopDetailsResponse.workshop_description.slice(0, 200)}
                        <span className="sw-read-more" onClick={this.openTopSecReadMore}>
                          More
                        </span>
                      </>
                    ) : (
                      <>
                        {this.props.workshopDetailsResponse.workshop_description}
                        <span className="sw-read-more" onClick={this.toggle_read_more}>
                          Less
                        </span>
                      </>
                    )
                  ) : (
                    this.props.workshopDetailsResponse.workshop_description
                  )}
                </p>
                <div className="sw-teacher-info">
                  <div
                    className="sw-teacher-pic"
                    style={{
                      backgroundImage: `url(), url(${this.props.workshopDetailsResponse.teacher_profile})`
                    }}
                   />

                  <h4 className="sw-t-name">{`${this.props.workshopDetailsResponse.teacher_name} (Teacher)`}</h4>
                  {true && this.state.enrolledStudentsList.length > 0 && (
                    <div
                      onClick={() => {
                        this.setState({ openPopupViewPartici: true });
                      }}
                      className="w_classroom_participants"
                    >
                      {this.state.enrolledStudentsList.map((enrolledStudents, index) => (
                          <span
                            key={index}
                            style={{
                              backgroundImage: `url(), url(${enrolledStudents.profile_photo})`
                            }}
                          />
                        ))}

                      {this.props.workshopDetailsResponse.students_enrolled_list.length -
                        this.state.enrolledStudentsList.length >
                        0 && (
                        <button className="view_all_parti" onClick={this.openPopupViewPartici}>
                          +
                          {this.props.workshopDetailsResponse.students_enrolled_list.length -
                            this.state.enrolledStudentsList.length}{' '}
                          More
                        </button>
                      )}
                    </div>
                  )}

                  {this.props.workshopDetailsResponse.number_of_rating > 0 &&
                    parseFloat(this.props.workshopDetailsResponse.average_rating).toFixed(1) >
                      0 && (
                      <div className="top-sec-rating">
                        <div className="star-div">
                          {this.renderStars().map((s) => s)}
                        </div>
                        <span className="total-vote">
                          {parseFloat(this.props.workshopDetailsResponse.average_rating).toFixed(1)}{' '}
                          <b>({this.props.workshopDetailsResponse.number_of_rating} votes)</b>
                        </span>
                      </div>
                    )}
                </div>
                {this.props.workshopDetailsResponse.number_of_rating > 0 &&
                  parseFloat(this.props.workshopDetailsResponse.average_rating).toFixed(1) > 0 && (
                    <div className="mb-top-sec-rating">
                      <div className="star-div">
                        {this.renderStars().map((s) => s)}
                      </div>
                      <span className="total-vote">
                        {parseFloat(this.props.workshopDetailsResponse.average_rating).toFixed(1)}{' '}
                        <b>({this.props.workshopDetailsResponse.number_of_rating} votes)</b>
                      </span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="sw-d-body">
          {/* tabs ends here */}

          {/* following sec will come in mobile view */}
          <div className="mb-sw-about-course">
            <div className="sw-body-cont">
              <h2>About Course</h2>
              <p>
                {this.props.workshopDetailsResponse.workshop_description.length > 200 ? (
                  !this.state.abt_work_toggle_read_more ? (
                    <>
                      {this.props.workshopDetailsResponse.workshop_description.slice(0, 100)}
                      <span className="abw-read-more" onClick={this.abt_work_toggle_read_more}>
                        More
                      </span>
                    </>
                  ) : (
                    <>
                      {this.props.workshopDetailsResponse.workshop_description}
                      <span className="abw-read-more" onClick={this.abt_work_toggle_read_more}>
                        Less
                      </span>
                    </>
                  )
                ) : (
                  this.props.workshopDetailsResponse.workshop_description
                )}
              </p>
            </div>
          </div>

          {this.renderCourseSchedule()}

          {!!this.props.workshopDetailsResponse.teacher_video_url && this.renderCourseVideo()}

          {this.props.workshopDetailsResponse.reviews_ratings.length > 0 && (
            <div className="sw-rating-sec">
              <div className="sw-body-cont">
                <h2>
                  Ratings &amp; Reviews{' '}
                  {this.props.workshopDetailsResponse.number_of_rating > 0 &&
                    parseFloat(this.props.workshopDetailsResponse.average_rating).toFixed(1) >
                      0 && (
                      <span className="rating-span">
                        {parseFloat(this.props.workshopDetailsResponse.average_rating).toFixed(1)}
                        /5 ({this.props.workshopDetailsResponse.number_of_rating})
                      </span>
                    )}
                </h2>

                <div className="comment-section">
                  {this.state.ratingReviewsList.map((ratingReviews, index) => {
                    const starCount = ratingReviews.rating;

                    return (
                      <div className="comment-item" key={index}>
                        <div className="comment-header">
                          <div
                            className="cmt-user-pic"
                            style={{
                              backgroundImage: `url(${ratingReviews.student_picture}), url("https://images.myyogateacher.com/profile-pic.png")`
                            }}
                           />

                          <div
                            className={`cmt-head-text ${
                              ratingReviews.review === '' ? 'no_review_txt' : ''
                            }`}
                          >
                            <div className="cmt-row-user">
                              <h4 className="cmt_user_name">
                                <b>{ratingReviews.student_name}</b>{' '}
                                <span className="rate-text">rated it</span>
                                <div className="star-div">
                                  <div className="star-div">
                                    {this.renderStars(starCount).map((s) => s)}
                                  </div>
                                </div>
                              </h4>
                              <span className="cmt-date-posted">
                                {moment(ratingReviews.review_date).format('MMM D YYYY')}
                              </span>
                            </div>
                            {ratingReviews.review !== '' && (
                              <p className="sw-rating-para">{ratingReviews.review}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {this.props.workshopDetailsResponse.reviews_ratings.length !=
                  this.state.ratingReviewsList.length && (
                  <div className="sw-time-load-more-btn">
                    <button
                      onClick={() => {
                        this.setState({
                          ratingReviewsList: this.props.workshopDetailsResponse.reviews_ratings
                        });
                      }}
                      className="sw-btn sw-load-more-btn"
                    >
                      Load more
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {true ? (
          <div className="sw-enroll-footer sw-detail-page-footer">
            <div className="sw-desk-footer-cont">
              <div className="sw-footer-row">
                <div className="sw-duration-info">
                  <h4>{this.props.workshopDetailsResponse.workshop_time_string}</h4>

                  {!!this.props.workshopDetailsResponse.total_hours && (
                    <p className="sw_f_time">
                      <img
                        alt="time"
                        src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                      />{' '}
                      {this.props.workshopDetailsResponse.total_hours} Hours{' '}
                      {!!this.props.workshopDetailsResponse.frequency_text &&
                        `(${  this.props.workshopDetailsResponse.frequency_text  })`}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="sw-mb-footer-cont">
              <div className="sw-footer-row">
                <div className="sw-duration-info">
                  <h4>{this.props.workshopDetailsResponse.workshop_time_string}</h4>
                  {!!this.props.workshopDetailsResponse.total_hours && (
                    <p className="sw_f_time">
                      <img
                        alt="time"
                        src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                      />{' '}
                      {this.props.workshopDetailsResponse.total_hours} Hours{' '}
                      {!!this.props.workshopDetailsResponse.frequency_text &&
                        `(${  this.props.workshopDetailsResponse.frequency_text  })`}
                    </p>
                  )}
                  {this.props.workshopDetailsResponse.seats_left > 0 &&
                    this.props.workshopDetailsResponse.seats_left < 10 && (
                      <span className="seat-info">
                        {this.props.workshopDetailsResponse.seats_left} seats left
                      </span>
                    )}
                </div>

                <div className="sw-mb-f-price-info refer-info">
                  <div className="sw-price-info">
                    <h4>{payment_price}</h4>
                    <p className="sw-pay-receipt" onClick={this.openPaymentReceipt}>
                      Payment receipt
                      <img
                        alt="arrow"
                        src="https://images.myyogateacher.com/icons/ic_ar_right_sw.svg"
                      />
                    </p>
                  </div>

                  <div className="sw-purchase-button sw-refer-btn">
                    <div className="sw-btn-row-in">
                      <button
                        onClick={() => {
                          this.share_link(true);
                        }}
                        className="sw-btn grey-s-outline"
                      >
                        Refer your friends{' '}
                      </button>
                    </div>
                  </div>
                </div>
                {!!this.props.workshopDetailsResponse.referral_benefit && (
                  <span className="sw-earn-info-no-bg">
                    <img
                      alt="offer"
                      src="https://images.myyogateacher.com/icons/ic_offer_orange.svg"
                    />
                    {this.props.workshopDetailsResponse.referral_benefit}
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="sw-detail-page-footer">
            <div className="sw-footer-cont">
              <div className="sw-desk-footer-cont">
                <div className="sw-footer-row">
                  <div className="sw-duration-info">
                    <h4>{this.props.workshopDetailsResponse.workshop_time_string}</h4>

                    {!!this.props.workshopDetailsResponse.total_hours && (
                      <p className="sw_f_time">
                        <img
                          alt="time"
                          src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                        />{' '}
                        {this.props.workshopDetailsResponse.total_hours} Hours{' '}
                        {!!this.props.workshopDetailsResponse.frequency_text &&
                          `(${  this.props.workshopDetailsResponse.frequency_text  })`}
                      </p>
                    )}
                  </div>

                  <div className="sw-price-info std-rate" />

                  <div className="sw-purchase-button">
                    {this.props.workshopDetailsResponse.seats_left > 0 &&
                      this.props.workshopDetailsResponse.seats_left < 10 && (
                        <span className="seat-info">
                          {this.props.workshopDetailsResponse.seats_left} seats left
                        </span>
                      )}
                  </div>
                </div>
              </div>

              {/* for mobile view */}
              <div className="sw-mb-footer-cont">
                <div className="sw-footer-row">
                  <div className="sw-duration-info">
                    {this.props.workshopDetailsResponse.seats_left > 0 &&
                      this.props.workshopDetailsResponse.seats_left < 10 && (
                        <span className="seat-info">
                          {this.props.workshopDetailsResponse.seats_left} seats left
                        </span>
                      )}
                    <h4>{this.props.workshopDetailsResponse.workshop_time_string}</h4>

                    {!!this.props.workshopDetailsResponse.total_hours && (
                      <p className="sw_f_time">
                        <img
                          alt="time"
                          src="https://images.myyogateacher.com/icons/ic_watch_green.svg"
                        />{' '}
                        {this.props.workshopDetailsResponse.total_hours} Hours{' '}
                        {!!this.props.workshopDetailsResponse.frequency_text &&
                          `(${  this.props.workshopDetailsResponse.frequency_text  })`}
                      </p>
                    )}
                  </div>

                  <div className="sw-mb-f-price-info">
                    <div className="sw-price-info">{this.render_price_for_mobile()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.open_share_popup ? (
          <div className="share-cont-div">
            <div className="share-cont-overlay" onClick={this.close_share_popup} />
            <div className="share-cont-modal">
              <img
                alt="close"
                src="https://images.myyogateacher.com/icons/close-p-ic.svg"
                className="sh-pop-close-ic"
                onClick={this.close_share_popup}
              />

              <p className="share-done-pop-btm">
                <span onClick={this.close_share_popup}>Done</span>
              </p>
            </div>
          </div>
        ) : null}

        {this.state.openPopupViewPartici && (
          <Opencustpop
            closePopupViewPartici={this.closePopupViewPartici}
            heading_head="All students"
            btn_ft_ln_text="Close"
            opc_content={this.show_opc_content()}
          />
        )}

        {/* comment start */}
        {this.state.open_big_video && (
          <div className="sw-pop-outer">
            <div className="sw-pop-overlay" onClick={this.close_big_video} />
            <div className="sw-confirm-pop-body">
              <img
                className="sw_pop_close_img"
                src="https://images.myyogateacher.com/icons/ic_white_cross.png"
                alt="cross"
                onClick={this.close_big_video}
              />
              {this.state.current_video_url === null || this.state.current_video_url === '' ? (
                <p>Recording is being processed, please check back again.</p>
              ) : (
                <> {this.show_video_content()}</>
              )}
            </div>
          </div>
        )}
        {/* commend ed */}

        {this.state.openPaymentReceipt && (
          <Opencustpop
            closePopupViewPartici={this.closePaymentReceipt}
            heading_head="Membership"
            btn_ft_ln_text="Close"
            opc_content={this.show_payrec_content()}
          />
        )}
        {this.state.openTopSecReadMore && (
          <div className="sw-pop-outer">
            <div className="sw-pop-overlay" onClick={this.closeTopSecReadMore} />
            <div className="sw-rm-pop-body">
              <img
                className="sw_pop_close_img"
                src="https://images.myyogateacher.com/icons/ic_white_cross.png"
                alt="cross"
                onClick={this.closeTopSecReadMore}
              />
              <div className="sw-rm-cont">
                {this.props.workshopDetailsResponse.workshop_description}
              </div>
            </div>
          </div>
        )}
        {this.state.showInterestPop && (
          <SimplePop
            close_simple_pop={this.closeInterestPop}
            sParaText="Thank you for showing your interest for this workshop. We will reach out to you when this is offered again."
            close_bd_btn_txt="Okay"
          />
        )}

        {this.state.openTopSecReadMore && (
          <div className="sw-pop-outer">
            <div className="sw-pop-overlay" onClick={this.closeTopSecReadMore} />
            <div className="sw-rm-pop-body">
              <img
                className="sw_pop_close_img"
                src="https://images.myyogateacher.com/icons/ic_white_cross.png"
                alt="cross"
                onClick={this.closeTopSecReadMore}
              />
              <div className="sw-rm-cont">
                {this.props.workshopDetailsResponse.workshop_description}
              </div>
            </div>
          </div>
        )}
        {this.state.showInterestPop && (
          <SimplePop
            close_simple_pop={this.closeInterestPop}
            sParaText="Thank you for showing your interest for this workshop. We will reach out to you when this is offered again."
            close_bd_btn_txt="Okay"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    student_details: state.profile.student_details,
    mobile_home_var: state.home.mobile_home_var,

    profile_loading_status: state.home.profile_loading_status,
    teacher_details: state.profile.teacher_details,
    config_status: state.loading.config_status,

    student_details_status: state.loading.student_details_status,
    workshopDetailsStatus: state.home.workshopDetailsStatus,
    workshopDetailsResponse: state.home.workshopDetailsResponse
  });

const mapDispatchToProps = (dispatch) => ({
    get_teacher_details: (payload) => dispatch(actions.get_teacher_details()),

    get_workshop_details: (payload) => {
      dispatch(actions.get_workshop_details(payload));
    },
    get_workshop_details_wo_token: (payload) => {
      dispatch(actions.get_workshop_details_wo_token(payload));
    }
  });

const SingleWorkshopDetailWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleWorkshopDetail);
const SingleWorkshopDetailWithRouter = withRouter(SingleWorkshopDetailWithRedux);

export default SingleWorkshopDetailWithRouter;
