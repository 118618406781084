import React from 'react';
import { post_api } from '../../redux/api_funcs';
import urls from '../../urls';
import { is_production } from '../../util_functions';

const GroupButton = (props) => {
	const impersonateBPStudent = (student_uuid) => {
		const url = urls.backpain_get_impersonation_token;
		const payload = { student_uuid: student_uuid };
		post_api(url, payload, true)
			.then((res) => {
				if (res.status === 200) {
					let token = res.data.token;
					let web_url = is_production()
						? 'https://lifetreehealth.com'
						: 'https://testenv1.lifetreehealth.com';
					window.open(`${web_url}/auth-user?token=${token}`);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	return (
		<div
			className='class-btn-size text-black font-inter font-semibold text-15.5px leading-19px w-full'
			onClick={(e) => {
				props?.disable ? null : impersonateBPStudent(props.student_uuid);
			}}
		>
			{props.children ? props.children : 'Book Back pain Group'}
		</div>
	);
};

export default GroupButton;
