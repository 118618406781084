import React, { Component } from 'react';
import CustomVimeoVideo from '../../util_components/custom_vimeo_video';
import { analytics_track, get_time_taken } from '../../../util_functions';
import './teacher-say.css';
import { teachers_say_list } from '../../../constants';

export default class TeacherSay extends Component {
	constructor(props) {
		super(props);

		this.state = {
			teacher_selected: 0,
			hide_0: true,
			hide_1: true
		};
	}

	track_fn = (name, teacher_name) => {
		const options = {
			referrer_component: 'home',
			plan: 'guest',
			time_since_page_load: get_time_taken(this.props.start_time),
			teacher_name
		};
		analytics_track(name, options);
	};

	renderVideo() {
		if (this.state.teacher_selected === 0) {
			return (
				<CustomVimeoVideo
					url={teachers_say_list[0].videoUrl}
					custom_1={true}
					teacher_name="Kanica"
					parent_component="home"
					start_time={this.props.start_time}
				/>
			);
		} else {
			return (
				<CustomVimeoVideo
					url={teachers_say_list[1].videoUrl}
					custom_1={true}
					teacher_name="Rohan"
					parent_component="home"
					start_time={this.props.start_time}
				/>
			);
		}
	}

	handleteacherSelection = index => {
		// e.currentTarget.dataset.index
		this.setState({ teacher_selected: index });
		this.track_fn('teacher image click', teachers_say_list[index].name);
	};

	renderTeacherList = () => {
		return (
			<div className="tp-tsay-thumb">
				{teachers_say_list.map((teacher, index) => {
					const class_select_tsay = index === this.state.teacher_selected ? 'select-tsay' : '';
					return (
						<div className={`tp-rowinside-thumb ${class_select_tsay}`} key={index + teacher.name}>
							<div onClick={() => this.handleteacherSelection(index)} className="icon">
								<a className="outer-bodder-div" />
								<div className="tsay-pic-container">
									<img className="tsay-pic" src={teacher.profile_pic} />
								</div>
							</div>
							<div className="text">
								<h4>{teacher.name}</h4>
								<p>{teacher.experience}</p>
							</div>
						</div>
					);
				})}
			</div>
		);
	};
	toggleHide = () => {
		const hide = this.state[`hide_${this.state.teacher_selected}`];
		this.setState({ [`hide_${this.state.teacher_selected}`]: !hide });
	};

	on_next_video = () => {
		this.setState({ teacher_selected: (this.state.teacher_selected + 1) % 2 });
	};

	render() {
		return (
			<div className="teacher-say-container">
				<div className="container">
					<h2>What our teachers are saying...</h2>
					<p className="subpara mb-hide">Straight from the heart, no cut ;)</p>
					<p className="divider-line">
						<img src="https://images.myyogateacher.com/home-page/Divider.png" />
					</p>

					<div className="tp-row-teacher-say">
						{this.renderTeacherList()}

						<button className="tp-ar tp-ar-left">
							<img src="https://images.myyogateacher.com/Teacher-Page/Arrow-Image-Popup.png" />
						</button>

						<div className="tp-tsay-video-div">
							<div className="video-container">
								<div className="video-back">{this.renderVideo()}</div>
							</div>
						</div>

						<button className="tp-ar tp-ar-right" onClick={this.on_next_video}>
							<img src="https://images.myyogateacher.com/Teacher-Page/Arrow-Image-Popup.png" />
						</button>
					</div>
				</div>
			</div>
		);
	}
}
