export const pretexts = (name, session) => {
	switch (session) {
		case 1:
			return `<p> Hey ${name}, </p>
      <p> It was great meeting you today!</p>
      <p>I just wanted to send you a recap of what we talked about today to make sure we’re on the same page.</p>
      `;
		case 2:
			return `<p> Hi ${name}, </p>
      <p> So I wanted to share our “Roadmap” that we talked about today.</p>
      `;
		case 3:
			return `<p> Hey again ${name}, </p>
      <p> Just wanted to send a quick recap of today’s session.</p>
      `;
		default:
			return `<p> Hi ${name}, </p>`;
	}
};

export const posttexts = teacher_name => `<p> See you soon!</br>
      ${teacher_name}</p>
			<p>[TEACHER PERSONA}</p>
			<p></p>
      <p>PS. In case you want to respond to this message, the best way to get a hold of me is through chat in the app. I’ll get back to you as soon as possible!</p>`;
