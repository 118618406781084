import React, { useState, useEffect, useRef } from 'react';
import { ClosePopUpLayout } from '../../../svg';

function PopUpLayout({ customClass, onClose, children }) {
  const popLayoutRef = useRef(null);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popLayoutRef.current && !popLayoutRef.current.contains(event.target)) {
        if (onClose) {
          onClose();
        }
      }
    };

    const handleClickInside = (event) => {
      event.stopPropagation();
    };

    document.addEventListener('mousedown', handleOutsideClick);
    popLayoutRef.current?.addEventListener('mousedown', handleClickInside);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      popLayoutRef.current?.removeEventListener('mousedown', handleClickInside);
    };
  }, [onClose]);

  return (
    <div className="relative inset-0 z-99">
      <div className="fixed zm:bg-mytHeaderBg inset-0 z-10 overflow-y-scroll">
        <div
          className={`zm:w-full md:w-492px zm:top-0 md:left-0 md:right-0 ml-auto mr-auto zm:rounded-tl-3xl zm:rounded-tr-3xl absolute xl:inset-y-auto zm:bottom-0 md:mb-30px md:mt-20 lg:mt-80px`}
          onClick={onClose}>
          <div
            ref={popLayoutRef}
            className={`zm:w-full md:w-auto zm:rounded-tl-3xl zm:rounded-tr-3xl md:rounded-10px absolute md:inset-x-auto md:inset-y-auto md:top-22 zm:bottom-0`}>
            <div className={`${customClass ? customClass : 'md:w-492px'} rounded-10px`}>
              <div
                className={`flex flex-col justify-end w-full ${viewportHeight <= 750 ? 'zm:h-[70vh] xs:h-[66vh] sm:h-full' : ''}`}>
                <div className='relative'>
                  <div
                    onClick={onClose}
                    className="self-end absolute top-12px right-12px cursor-pointer">
                    <ClosePopUpLayout />
                  </div>
                  <div
                    className={`md:mb-30px bg-white md:rounded-8px p-0 pt-10 zm:rounded-tl-3xl zm:rounded-tr-3xl`}
                    onClick={(e) => e.stopPropagation()}>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopUpLayout;
