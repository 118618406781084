import React, { Component } from 'react';
import Spinner from '../../../util_components/spinner';
import urls from '../../../../urls';
import Loader from '../../components/loader';
import { post_api } from '../../../../redux/api_funcs';
import { withRouter } from '../../../../utils/router';
import { sendEventToClarity } from '../../../../util_functions';

class SignUp extends Component {
  state = {
    name: '',
    name_error: false,
    email: '',
    email_error: false,
    email_error_msg: '',
    signup_loading: false,
    singup_success: false,
    resend_loading: false,
    signup_verified: false,
    password_error: false,
    password_error_msg: '',
    password: '',
    show_password_icon: false
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}_error`]: false
    });
  };

  componentDidMount() {
    sendEventToClarity();
  }

  prevent_enter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  sign_up = () => {
    if (!this.state.signup_loading) {
      const { email, name, password } = this.state;
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = re.test(String(email).toLowerCase());
      if (name.trim() === '') {
        this.setState({ name_error: true });
      } else if (email.trim() === '') {
        this.setState({
          email_error: true,
          email_error_msg: 'This field cannot be empty'
        });
      } else if (!result) {
        this.setState({
          email_error: true,
          email_error_msg: 'Please enter valid email'
        });
      } else if (password === '') {
        this.setState({
          password_error: true,
          password_error_msg: 'Please enter password'
        });
      } else if (password.length < 6) {
        this.setState({
          password_error: true,
          password_error_msg: 'Password must be 6 characters long'
        });
      } else {
        const payload = {
          name,
          email,
          password
        };
        const url = urls.onboard_teacher;
        this.setState({ signup_loading: true }, () => {
          post_api(url, payload, false).then((res) => {
            const signup_verified = res.data.email_verified;
            this.setState({
              signup_verified: signup_verified === 1,
              singup_success: true,
              signup_loading: false
            });
          });
        });
      }
    }
  };

  on_signup_press = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.sign_up();
    }
  };

  togglePasswordVisibility = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  render_email_signup = () => (
      <div className="hp-signup-right">
        <div className="hp-modal-content">
          <h1>
            <b>Hey</b> there!
          </h1>
          <p className="ntext">Sign up to get started</p>
          <form className="hp-form">
            <div className="hp-form-group">
              <input
                name="name"
                type="text"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleInput}
                onKeyPress={this.prevent_enter}
                className="input_name"
              />
              <label>Name</label>
              {this.state.name_error ? (
                <div className="hp-form-group-error">
                  <p>This field cannot be empty</p>
                  <div className="hp-form-group-error-tr" />
                </div>
              ) : null}
            </div>
            <div className="hp-form-group">
              <input
                name="email"
                type="email"
                placeholder="Email Id"
                className="hp-form-group-lower"
                value={this.state.email}
                onChange={this.handleInput}
                onKeyPress={this.on_signup_press}
              />
              <label>Email Id</label>
              {this.state.email_error ? (
                <div className="hp-form-group-error">
                  <p>{this.state.email_error_msg}</p>
                  <div className="hp-form-group-error-tr" />
                </div>
              ) : null}
            </div>
            <div className="hp-form-btn">
              <span
                className={` btn btn-orange ${this.state.signup_loading ? 'btn-orange-loading' : ''}`}
                onClick={this.sign_up}
              >
                Submit
              </span>
              {this.state.signup_loading ? (
                <div className="btn-purple-loader">
                  <Spinner
                    circleRadius={12}
                    borderLength={1}
                    smallBorderColor="#4332DF"
                    largeBorderColor="#fff"
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </form>
        </div>
      </div>
    );

  resend_email = () => {
    this.setState({ resend_loading: true }, () => {
      const { name, email, password } = this.state;
      const payload = {
        name,
        email,
        password
      };
      const url = urls.onboard_teacher;
      post_api(url, payload, false).then((res) => {
        const signup_verified = res.data.email_verified;
        this.setState({
          signup_verified: signup_verified === 1,
          singup_success: true,
          resend_loading: false
        });
      });
    });
  };

  open_login = () => this.props.router.navigate('/login');

  render_signup_success = () => (
      <div className="md:flex h-screen w-screen">
          <div className="loginSignupLeftContainer">
            <div className="loginSignupTopSection">
              <div
                className="breadcrumbBackContainer"
                onClick={() => this.props.router.navigate('/')}
              >
                <img
                  src="https://images.myyogateacher.com/web_images/web_prod/arrowLeft.png"
                  alt="arrowLeft"
                  className="w-2 h-3"
                />
                <div className="breadcrumbBackContent">Home</div>
              </div>
              <div className="breadcrumbBackContentLogo">
                <img
                  src="https://images.myyogateacher.com/MYT_Logo.svg"
                  alt="MyYogaTeacher_Logo"
                  className="breadcrumbBackContentLogoImg"
                />
                <div
                  className="breadcrumbBackContainerMobile"
                  onClick={() => this.props.router.navigate('/')}
                >
                  <div className="breadcrumbBackContent">Home</div>
                </div>
              </div>
            </div>
            <div className="loginSignupMidSection">
              <div className="loginSignupHeadingContainer">
                {this.state.signup_verified ? null : this.state.resend_loading ? (
                  ''
                ) : (
                  <div className="flex mb-2.5">
                    <div className="font-inter font-bold text-base text-mytgreen">
                      Your account has been successfully created
                    </div>
                    <div className="ml-12px">
                      <svg
                        width="17"
                        height="22"
                        viewBox="0 0 17 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.7672 7.15625L6.41406 15.5094L2.61719 11.7125"
                          stroke="#04A777"
                          strokeWidth="2.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                <div className="loginSignupHeading w-499px font-inter">
                  {this.state.signup_verified
                    ? 'Email already present'
                    : 'Verify your email address'}
                </div>
                <div className="text-mytgrey-lite text-base tracking-tight mt-3">
                  {this.state.signup_verified ? (
                    <div>
                      <p>Continue to login</p>
                      <button
                        className="loginSignupBtn"
                        onClick={() => this.props.router.navigate('/login')}
                      >
                        Login
                      </button>
                    </div>
                  ) : this.state.resend_loading ? (
                    ''
                  ) : (
                    <div className="font-inter font-normal text-base">
                      We have sent you an email at {this.state.email}
                    </div>
                  )}
                </div>
                <br />
                {!this.state.signup_verified ? (
                  this.state.resend_loading ? (
                    <Spinner type="dots" />
                  ) : (
                    <button className="loginSignupBtn" onClick={() => this.resend_email()}>
                      Resend email
                    </button>
                  )
                ) : null}
              </div>
            </div>
            <div className="loginSignupBottomSection" />
          </div>
          <div className="loginSignupRightImage" />
        </div>
    );

  render() {
    return (
      <>
        {this.state.singup_success ? (
          this.render_signup_success()
        ) : (
          <div className="md:flex h-screen w-screen">
            <div className="loginSignupLeftContainer">
              <div className="loginSignupTopSection">
                <div
                  className="breadcrumbBackContainer"
                  onClick={() => this.props.router.navigate('/')}
                >
                  <img
                    src="https://images.myyogateacher.com/web_images/web_prod/arrowLeft.png"
                    alt="arrowLeft"
                    className="w-2 h-3"
                  />
                  <div className="breadcrumbBackContent">Home</div>
                </div>
                <div className="breadcrumbBackContentLogo">
                  <img
                    src="https://images.myyogateacher.com/MYT_Logo.svg"
                    alt="MyYogaTeacher_Logo"
                    className="breadcrumbBackContentLogoImg"
                  />
                  <div
                    className="breadcrumbBackContainerMobile"
                    onClick={() => this.props.router.navigate('/')}
                  >
                    <div className="breadcrumbBackContent">Home</div>
                  </div>
                </div>
              </div>
              <div className="loginSignupMidSection">
                <div className="loginSignupHeadingContainer">
                  <div className="loginSignupHeading">Teacher Sign up</div>
                  <div className="zm:my-15px">
                    <label className="loginSignupFormLabel">Name</label>
                    <input
                      type="text"
                      name="name"
                      className={`formInput mt-1 py-5 ${
                        this.state.name_error ? 'formInputError' : null
                      }`}
                      onChange={this.handleInput}
                      onKeyPress={this.prevent_enter}
                      value={this.state.name}
                    />

                    {this.state.name_error ? (
                      <div className="loginSignupErrorMsg">
                        <p>This field cannot be empty</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="zm:my-25px">
                    <label className="loginSignupFormLabel">Email</label>
                    <input
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleInput}
                      onKeyPress={this.on_signup_press}
                      className={`formInput mt-1 py-5 ${
                        this.state.email_error ? 'formInputError' : null
                      }`}
                    />
                    {this.state.email_error ? (
                      <div className="loginSignupErrorMsg">
                        <p>{this.state.email_error_msg}</p>
                      </div>
                    ) : null}
                  </div>
                  <div className="relative">
                    <div className="zm:my-25px">
                      <label className="loginSignupFormLabel">Password</label>
                      <input
                        name="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        className={`formInput mt-1 py-5 ${
                          this.state.password_error ? 'formInputError' : null
                        }`}
                        value={this.state.password}
                        onChange={this.handleInput}
                        onKeyPress={this.on_signup_press}
                      />
                      {this.state.password_error ? (
                        <div className="loginSignupErrorMsg">
                          <p>{this.state.password_error_msg}</p>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="eyeIcon cursor-pointer pt-51.2px"
                      onClick={this.togglePasswordVisibility}
                    >
                      <img
                        src={
                          this.state.showPassword
                            ? 'https://images.myyogateacher.com/web_images/eye-off.svg'
                            : 'https://images.myyogateacher.com/web_images/web_prod/eye.png'
                        }
                        alt="eye-icon"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <button className="loginSignupBtn" onClick={this.sign_up}>
                    Continue
                    {this.props.newSignUpStatus === 'loading' || this.state.apiLoading ? (
                      <span className="pl-4">
                        <Loader />
                      </span>
                    ) : (
                      ''
                    )}
                  </button>
                </div>
                <div className="lg:mt-0 zm:mt-2">
                  <a className="text-mytgrey-lite zm:text-14.5 zm:pt-2 lg:pt-2" href="/login">
                    Already a Teacher?{' '}
                    <span className="font-semibold text-black hover:underline cursor-pointer">
                      Login
                    </span>
                  </a>
                </div>
              </div>
              <div className="loginSignupBottomSection" />
            </div>
            <div className="loginSignupRightImage" />
          </div>
        )}{' '}
      </>
    );
  }
}

const SignUpWithRouter = withRouter(SignUp);

export default SignUpWithRouter;
