import React, { Component, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/action_creators';
import * as selectors from '../../redux/selectors';
import minimize from '../../assets/images/minimize.png';
import arrow_dropdown from '../../assets/images/arrow_dropdown.png';
import microphone from '../../assets/images/microphone.svg';
import microphone_muted from '../../assets/images/microphone_muted.svg';
import speaker from '../../assets/images/speaker.png';
import maximise_chat from '../../assets/images/maximise_chat.png';
import anchor from '../../assets/images/anchor.png';
import moment from 'moment';

import * as Twilio from 'twilio-client';

const TIME = { seconds: 0, minutes: 0 };
const mapStateToPropsTimer = (state) => ({
	session: state.home.session,
	call_details: state.home.get_consult_session_call_details,
	call_details_status: state.home.get_consult_session_call_details_status,
});
const mapDispatchToPropsTimer = (dispatch) => ({
	getActiveCallStatus: (payload) => dispatch(actions.get_active_call_status(payload)),
});

const Timer = connect(
	mapStateToPropsTimer,
	mapDispatchToPropsTimer
)((props) => {
	let { seconds = 0, minutes = 0 } = TIME;
	const [[mins, secs], setTime] = useState([minutes, seconds]);
	const [isActive, setIsActive] = useState(false);
	const tick = () => {
		setTime([
			moment().diff(moment(props.call_details.call_start_time), 'minutes'),
			moment().diff(moment(props.call_details.call_start_time), 'seconds') % 60,
		]);
	};
	let timerRef = useRef();
	let callRref = useRef();
	useEffect(() => {
		if (!isActive && props.activeCall !== callRref.current) {
			props.getActiveCallStatus({ consult_id: props.session.consult_id });
		}
		if (!isActive && timerRef.current === 'loading' && props.call_details_status === 'success') {
			setIsActive(true);
		}

		if (isActive === true) {
			const timerId = setTimeout(() => tick(), 1000);
			return () => clearInterval(timerId);
		}
		timerRef.current = props.call_details_status;
		callRref.current = props.activeCall;
		if (isActive && props.call_details_status === 'loading') setIsActive(false);
	});
	return isActive ? (
		<p className='text-center font-inter text-sm text-opacity-66 text-white mt-1.4 leading-4.1'>
			{`${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}
		</p>
	) : null;
});

const MakeDraggableElement = function (elmnt) {
	//https://www.cssscript.com/draggable-popup-window-engine/
	var pos1 = 0,
		pos2 = 0,
		pos3 = 0,
		pos4 = 0;
	if ('ontouchstart' in document.documentElement) {
		var pos1touch = 0,
			pos2touch = 0,
			pos3touch = 0,
			pos4touch = 0;
	}
	if (document.getElementById('anchor')) {
		document.getElementById('anchor').onmousedown = dragMouseDown;
		document.getElementById('anchor').ontouchstart = dragMouseDown;
	}

	function dragMouseDown(e) {
		if (!('ontouchstart' in document.documentElement)) {
			e.preventDefault();
		}
		pos3 = e.clientX;
		pos4 = e.clientY;
		if ('ontouchstart' in document.documentElement) {
			try {
				pos3touch = e.touches[0].clientX;
				pos4touch = e.touches[0].clientY;
			} catch (error) {}
		}
		document.onmouseup = closeDragElement;
		document.onmousemove = elementDrag;
		document.ontouchend = closeDragElement;
		document.ontouchmove = elementDrag;
	}

	function elementDrag(e) {
		e.preventDefault();
		let height = document.documentElement.clientHeight - 100;
		let width = document.documentElement.clientWidth - 300;
		if ('ontouchstart' in document.documentElement) {
			pos1touch = pos3touch - e.touches[0].clientX;
			pos2touch = pos4touch - e.touches[0].clientY;
			pos3touch = e.touches[0].clientX;
			pos4touch = e.touches[0].clientY;
			let top = elmnt.offsetTop - pos2touch;
			let left = elmnt.offsetLeft - pos1touch;
			elmnt.style.top = top < 0 ? 0 : Math.min(top, height) + 'px';
			elmnt.style.left = left < 0 ? 0 : Math.min(left, width) + 'px';
		} else {
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			let top = elmnt.offsetTop - pos2;
			let left = elmnt.offsetLeft - pos1;
			elmnt.style.top = top < 0 ? 0 : Math.min(top, height) + 'px';
			elmnt.style.left = left < 0 ? 0 : Math.min(left, width) + 'px';
		}
	}

	function closeDragElement() {
		document.onmouseup = null;
		document.onmousemove = null;
		document.ontouchend = null;
		document.ontouchmove = null;
	}
};

class TwilioVoice extends Component {
	state = {
		showLargePopUp: false,
		showSmallPopUp: false,
		activeConnection: null,
		showInputOptions: false,
		showOutputOptions: false,
		activeMicGroupId: undefined,
		activeSpeakerGroupId: undefined,
		micOff: false,
		inputOptions: [],
		outputOptions: [],
		callStatusMessage: '',
	};

	componentDidMount() {
		if (this.props.teacher_token) {
			this.props.getToken();
			this.props.set_home_variable('twilioVoiceCallStatus', 'initialising');
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (
			props.load_application_status === 'success' &&
			props.twilioVoiceCallStatus === 'initialising' &&
			!!props.twilio_token
		) {
			Twilio.Device.setup(props.twilio_token);
			props.set_home_variable('twilioVoiceCallStatus', 'loading');
		}

		if (props.goToActiveCall === true) {
			if (!state.activeConnection) {
				props.set_home_variable('consult_call_active', false);
				props.set_home_variable('makeCall', false);
				props.set_home_variable('session', {});
				alert('Connection Error \nRefresh Page and try again');
			} else return { ...state, showLargePopUp: true, showSmallPopUp: false };
		}
		return null;
	}

	setUpTwilioVoice = () => {
		//TWILIO Event Listeners Setup for Voice Calls
		const endCall = () =>
			this.setState(
				{
					showLargePopUp: false,
					showSmallPopUp: false,
					activeConnection: null,
					micOff: false,
				},
				() => {
					TIME.seconds = 0;
					TIME.minutes = 0;
					this.props.set_home_variable('consult_call_active', false);
					this.props.set_home_variable('makeCall', false);
					this.props.set_home_variable('session', {});
				}
			);

		Twilio.Device.ready(() => {
			this.props.set_home_variable('twilioVoiceCallStatus', 'success');
			console.log('Twilio ready');
			Twilio.Device.audio.on('deviceChange', () => {
				this.updateMicOptions();
			});
			navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then((stream) => {
					let activeMicGroupId;
					let activeSpeakerGroupId;
					Twilio.Device.audio.availableInputDevices.forEach((d) => {
						if (d.deviceId !== 'default') {
							if (
								!this.state.activeMicGroupId &&
								Twilio.Device.audio.availableInputDevices.get('default') &&
								d.groupId === Twilio.Device.audio.availableInputDevices.get('default').groupId
							)
								activeMicGroupId = d.groupId;
						}
					});
					Twilio.Device.audio.availableOutputDevices.forEach((d) => {
						if (d.deviceId !== 'default') {
							if (
								!this.state.activeSpeakerGroupId &&
								Twilio.Device.audio.availableOutputDevices.get('default') &&
								d.groupId === Twilio.Device.audio.availableOutputDevices.get('default').groupId
							)
								activeSpeakerGroupId = d.groupId;
						}
					});
					this.setState({
						activeMicGroupId,
						activeSpeakerGroupId,
					});

					stream.getTracks().forEach((track) => track.stop());
					// https://www.twilio.com/docs/voice/sdks/javascript/overview-1x-deprecated/best-practices#working-with-microphones-and-getusermedia
				})
				.catch((error) => {
					console.log(error);
				});
		});

		Twilio.Device.on('disconnect', (conn) => {
			endCall();
		});

		Twilio.Device.on('connect', (conn) => {
			this.setState({ activeConnection: conn, showLargePopUp: true, callStatusMessage: '' }, () => {
				this.props.set_home_variable('consult_call_active', true);
			});
		});

		Twilio.Device.error((err) => {
			let { code } = err;
			console.log('Twilio error:', err.message);
			switch (code) {
				case 31208:
				case 31201:
					alert('Please allow microphone access to make call.');
					break;
				case 31205:
					this.setState({ callStatusMessage: 'refreshing token', showLargePopUp: true });
					break;
				default:
					endCall();
			}
		});
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.teacher_token && this.props.teacher_token) {
			this.props.getToken();
			this.props.set_home_variable('twilioVoiceCallStatus', 'initialising');
		}
		if (document.getElementById('smallPopup')) MakeDraggableElement(document.getElementById('smallPopup'));
		if (prevProps.twilioVoiceCallStatus === 'initialising' && this.props.twilioVoiceCallStatus === 'loading')
			this.setUpTwilioVoice();
		if (this.props.goToActiveCall === true) this.props.set_home_variable('goToActiveCall', false);

		if (!prevProps.makeCall && this.props.makeCall) {
			let { consult_id, student_phone_personal } = this.props.session;
			let connection = Twilio.Device.connect({
				// number: "+1" + student_phone_personal
				number: student_phone_personal,
				consultID: consult_id,
			});
		}
	}

	updateMicOptions = () => {
		let { showInputOptions, showOutputOptions } = this.state;
		this.setState(
			{
				...(!!showInputOptions ? { showInputOptions: false } : {}),
				...(!!showOutputOptions ? { showOutputOptions: false } : {}),
			},
			() => {
				let intervalId = setTimeout(() => {
					this.setState({
						...(!!showInputOptions ? { showInputOptions: true } : {}),
						...(!!showOutputOptions ? { showOutputOptions: true } : {}),
					});
					clearInterval(intervalId);
				}, 500);
			}
		);
	};
	renderOutputDevices = () => {
		let outputOptions = [];
		Twilio.Device.audio.availableOutputDevices.forEach((d) => {
			if (d.deviceId !== 'default') {
				outputOptions.push(
					<p
						onClick={() => this.setOutputDevice(d.deviceId)}
						className={`font-semibold text-15.5 leading-4.2 mt-3.5 font-inter ${
							d.groupId === this.state.activeSpeakerGroupId ? 'text-primary' : ''
						}`}
					>
						{d.label}
					</p>
				);
			}
		});
		return outputOptions;
	};
	setOutputDevice = (id) => {
		Twilio.Device.audio.speakerDevices
			.set(id)
			.then(() => {
				this.setState({
					activeSpeakerGroupId: Twilio.Device.audio.speakerDevices.get(id).values().next().value.groupId,
				});
			})
			.catch((error) => {
				alert('output device unavailable');
			});
	};
	renderInputDevices = () => {
		let inputOptions = [];
		Twilio.Device.audio.availableInputDevices.forEach((d) => {
			if (d.deviceId !== 'default') {
				inputOptions.push(
					<p
						onClick={() => this.setInputDevice(d.deviceId)}
						className={`font-semibold text-15.5 leading-4.2 mt-3.5 font-inter ${
							d.groupId === this.state.activeMicGroupId ? 'text-primary' : ''
						}`}
					>
						{d.label}
					</p>
				);
			}
		});
		return inputOptions;
	};
	setInputDevice = (id) => {
		Twilio.Device.audio
			.setInputDevice(id)
			.then(() => {
				this.setState({ activeMicGroupId: Twilio.Device.audio.availableInputDevices.get(id).groupId });
			})
			.catch((error) => {
				console.log(error);
				alert('input device failed');
			});
	};

	renderCallPopUp = () => {
		try {
			let {
				student_name,
				avatar_medium = 'https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png',
			} = this.props.session || {};
			if (!student_name) {
				return null;
			} else {
				return (
					<div className='select-none -inset-0 flex justify-center items-center fixed h-screen w-screen bg-mytHeaderBg z-10'>
						<div
							className='w-416 h-448 rounded-lg flex flex-col items-center relative  bg-gradient-to-r from-mytTeacherBgGradientPurpleI to-mytTeacherBgGradientPurpleII'
							onClick={(event) => {
								if (event.target === event.currentTarget)
									this.setState({
										showOutputOptions: false,
										showInputOptions: false,
									});
							}}
						>
							<img
								onClick={() =>
									this.setState({
										showLargePopUp: false,
										showSmallPopUp: true,
									})
								}
								className='absolute w-4.2 h-4.2 top-3.2 right-3.2 cursor-pointer'
								src={minimize}
							/>
							<div className='mt-15 relative rounded-full '>
								<div className='animate-ping-medium bg-white rounded-full w-28 h-28' />
								<img className='absolute top-0  rounded-full w-28 h-28 z-10' src={avatar_medium} />
							</div>
							<p className='text-center font-inter font-normal text-3xl text-white mt-4'>
								{student_name}
							</p>
							{this.state.activeConnection ? (
								<Timer activeCall={this.state.activeConnection} ref={this.timerLarge} />
							) : (
								<p className='text-center font-inter text-sm text-opacity-66 text-white mt-1.4 leading-4.1'>
									{this.state.callStatusMessage || 'connecting...'}
								</p>
							)}
							<div className='flex flex-row self-center mt-11'>
								<span
									onClick={() =>
										this.setState({
											showOutputOptions: !this.state.showOutputOptions,
											showInputOptions: false,
										})
									}
									className='flex w-12 h-12 bg-white bg-opacity-20 justify-center rounded-full items-center relative mr-2.5 cursor-pointer'
								>
									<img className='w-7 h-1.35' src={speaker} />
									<span className='absolute flex w-5 h-5 right-0 bg-mytTeacherBgPurple drop-shadow -bottom-1 justify-center rounded-full items-center'>
										<img src={arrow_dropdown} />
									</span>
									{this.state.showOutputOptions ? (
										<div className='rounded-lg bg-white flex w-56 flex-col absolute px-7 pt-3.5 pb-6 top-13.5 -right-18'>
											{this.renderOutputDevices()}
										</div>
									) : null}
								</span>
								<span className='flex w-12 h-12 bg-white bg-opacity-20 justify-center rounded-full items-center relative cursor-pointer'>
									<span
										className='flex flex-1 justify-center items-center h-full'
										onClick={(event) => {
											this.state.activeConnection.mute(!this.state.activeConnection.isMuted());
											this.setState({
												micOff: this.state.activeConnection.isMuted
													? this.state.activeConnection.isMuted()
													: false,
											});
										}}
									>
										{this.state.micOff ? (
											<img className='h-6 ml-0.5' src={microphone_muted} />
										) : (
											<img className='h-6' src={microphone} />
										)}
									</span>
									<span
										className='absolute flex w-5 h-5 right-0 bg-mytTeacherBgPurple drop-shadow -bottom-1 justify-center rounded-full items-center'
										onClick={() =>
											this.setState({
												showOutputOptions: false,
												showInputOptions: !this.state.showInputOptions,
											})
										}
									>
										<img src={arrow_dropdown} />
									</span>
									{this.state.showInputOptions ? (
										<div className='overflow-auto rounded-lg bg-white flex w-56 flex-col absolute px-7 pt-3.5 pb-6 top-13.5 -left-18'>
											{this.renderInputDevices()}
										</div>
									) : null}
								</span>
							</div>
							<div
								onClick={() => this.state.activeConnection && this.state.activeConnection.disconnect()}
								className='flex justify-center rounded-full items-center bg-mytRedText mt-5 w-60 h-12 cursor-pointer'
							>
								<p className=' text-white font-inter font-semibold text-base leading-5.5'>End call</p>
							</div>
						</div>
					</div>
				);
			}
		} catch (e) {
			return null;
		}
	};

	renderSmallPopup = () => {
		let { student_name = 'name', avatar_medium /*number*/ } = this.props.session || {};
		return (
			<div
				id='smallPopup'
				className='fixed flex flex-row top-13 right-13 items-center rounded-lg h-25 w-75 z-10 bg-gradient-to-r from-mytTeacherBgGradientPurpleI to-mytTeacherBgGradientPurpleII'
			>
				<img id='anchor' className='absolute top-1 right-1 w-4 h-4 cursor-move' src={anchor} />
				<span className='ml-4 relative rounded-full bg-white'>
					<div className='rounded-full animate-ping-small top-0 bg-white w-16 h-16' />
					<img className='rounded-full absolute top-0 w-16 h-16' src={avatar_medium} />
				</span>
				<span className='flex ml-4 flex-col items-start w-31 overflow-hidden'>
					<p className='font-inter font-normal leading-6.7 text-22 text-white truncate'>
						{student_name.split(' ')[0]}
					</p>
					<p className='font-inter font-medium text-opacity-70 mt-1.2 leading-3.5 text-s text-white'>
						<Timer activeCall={this.state.activeConnection} ref={this.timerSmall} />
					</p>
				</span>
				<span
					onClick={(event) =>
						this.setState({
							showLargePopUp: true,
							showSmallPopUp: false,
						})
					}
					className='flex items-center justify-center w-13 h-13 rounded-full bg-consultCallGrey bg-opacity-30 cursor-pointer'
				>
					<img className='w-6 h-6' src={maximise_chat} />
				</span>
			</div>
		);
	};

	render() {
		return (
			<>
				{this.state.showLargePopUp ? this.renderCallPopUp() : null}
				{this.state.showSmallPopUp ? this.renderSmallPopup() : null}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		load_application_status: selectors.load_application_status(state),
		sessions: selectors.get_sessions(state),
		makeCall: state.home.makeCall,
		session: state.home.session,
		twilioVoiceCallStatus: state.home.twilioVoiceCallStatus,
		goToActiveCall: state.home.goToActiveCall,
		twilio_token: state.home.twilio_token,
		call_details_status: state.home.get_consult_session_call_details_status,
		teacher_token: selectors.get_teacher_token(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		set_home_variable: (key, payload) => {
			dispatch(actions.set_home_variable(key, payload));
		},
		load_application: () => {
			dispatch(actions.load_application());
		},
		getToken: () => {
			dispatch(actions.get_twilio_voice_token());
		},
		getActiveCallStatus: (payload) => {
			dispatch(actions.get_active_call_status(payload));
		},
	};
};

const TwilioVoiceWithRedux = connect(mapStateToProps, mapDispatchToProps)(TwilioVoice);

export default TwilioVoiceWithRedux;
