import React from 'react';
import Header from '../../util_components/header_new_design';
import Footer from '../../util_components/footer_new_design';
import axios from 'axios';
import CustomVimeoPlayer from '../../util_components/custom_vimeo_video';
import './policy.css';
import './legal.css';
import './faq.css';
import { withRouter } from '../../../utils/router';

class NewFaq extends React.Component {
	state = {
		all_faq: [],
		faq_response: [],
		display_faq: [],
		section_list: [],
		section_index: -1,
		start_time: new Date().getTime(),
	};
	componentDidMount() {
		this.fetch_faq();
	}
	fetch_faq = () => {
		axios('https://js.myyogateacher.com/teacher-faq.json').then((response) => {
			const faq = response.data.data;
			let section_list = [];
			let all_faq = [];
			faq.map((items) => {
				section_list.push(items.section);
				all_faq.push(...items.faq);
			});
			this.setState({
				section_index: -1,
				section_list,
				all_faq,
				faq_response: [...faq],
				display_faq: [...all_faq],
			});
		});
	};
	change_section = (index) => {
		if (index === -1) {
			this.setState({ section_index: -1, display_faq: [...this.state.all_faq] });
		} else {
			this.setState({
				section_index: index,
				display_faq: [...this.state.faq_response[index].faq],
			});
		}
	};
	get_class_name = (index) => (index === this.state.section_index ? 'btn-border active' : 'btn-border');
	render_faq_header = () => {
		return (
			<div className='faq-tags-cont'>
				<a id='all' className={this.get_class_name(-1)} onClick={() => this.change_section(-1)}>
					All
				</a>
				{this.state.section_list.map((section, index) => {
					return (
						<a
							className={this.get_class_name(index)}
							key={index + section}
							onClick={() => this.change_section(index)}
						>
							{section}
						</a>
					);
				})}
			</div>
		);
	};
	create_links = (inputText) => {
		let replacedText, replacePattern1, replacePattern2, replacePattern3, replacePattern4;

		//URLs starting with http://, https://, or ftp://
		replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
		replacedText = inputText.replace(
			replacePattern1,
			'<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
		);

		//URLs starting with "www." (without // before it, or it'd re-link the ones done above).
		replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		replacedText = replacedText.replace(
			replacePattern2,
			'$1<a href="http://$2" target="_blank" rel="noopener noreferrer">$2</a>'
		);

		//Change email addresses to mailto:: links.
		replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
		replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

		replacePattern4 =
			/((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/gim;
		replacedText = replacedText.replace(replacePattern4, (text) => {
			return `<a href="tel:${text}">${text}</a>`;
		});
		return replacedText;
	};
	render_faq_item = (item) => {
		return (
			<>
				{item.description.map((desc_item, index) => {
					if (desc_item.type === 'text') {
						return (
							<p
								style={{ whiteSpace: 'pre-line' }}
								key={index + desc_item.type}
								dangerouslySetInnerHTML={{ __html: this.create_links(desc_item.value) }}
							/>
						);
					} else if (desc_item.type === 'video') {
						return (
							<CustomVimeoPlayer
								url={desc_item.html_video_link}
								custom_1={true}
								parent_component='faq'
								teacher_name='faq-page'
								start_time={this.state.start_time}
							/>
						);
					} else if (desc_item.type === 'image') {
						return <img width='100%' src={desc_item.value} alt='img' />;
					}
				})}
			</>
		);
	};
	render_faq_content = () => {
		return (
			<div className='faq-dis-cont experience'>
				{this.state.display_faq.map((faq, index) => (
					<div className='qa-row-faq'>
						<div className='nicon'>
							<span>#{index + 1}</span>
						</div>
						<div className='fqanswer'>
							<h4>{faq.name}</h4>
							{typeof faq.description !== 'undefined' ? this.render_faq_item(faq) : null}
						</div>
					</div>
				))}
			</div>
		);
	};
	faq = () => (
		<div className='faq-page'>
			<div className='fq-fix-header'>
				<h2>FAQ</h2>
				{this.render_faq_header()}
			</div>
			{this.render_faq_content()}
		</div>
	);

	render() {
		return (
			<div className='custom-faq'>
				<Header parent_component='faq' start_time={this.state.start_time} />
				<a className='backhome' href='/'>
					&lt; Back to Home
				</a>
				<div className='legal-page'>
					<div className='faq-page-header'>
						<div className='header-logo'>
							<a href='/'>
								<img
									className='mytlogo'
									src='https://images.myyogateacher.com/myt-logo-new2.png'
									alt='logo'
								/>
							</a>
						</div>
					</div>
					<div className='faq-content'>
						{this.faq()}
						<Footer parent_component='faq' start_time={this.state.start_time} />
					</div>
				</div>
			</div>
		);
	}
}

const NewFaqWithRouter = withRouter(NewFaq);

export default NewFaqWithRouter;
