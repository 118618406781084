import * as t from "../action_types";
import {
  basic_details_indices,
  yoga_details_indices,
  social_details_indices,
  other_details_indices,
  teacher_details_null_values,
  docs_details_indices,
  bank_details_indices,
  app_details_indices,
} from "../../constants";

var initialState = {
  basic_details_indices,
  yoga_details_indices,
  social_details_indices,
  other_details_indices,
  docs_details_indices,
  bank_details_indices,
  app_details_indices,
  teacher_details: teacher_details_null_values
};

const set_single_variable = (state, payload) => {
  return {
    ...state,
    [payload.key]: payload.payload
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case t.set_profile_variable:
      return set_single_variable(state, action.payload);
    default:
      return state;
  }
}
