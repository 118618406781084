import React, { Component } from 'react';
import moment from 'moment';
import LiveSession from '../popup/session-live';
import LiveSessionCancel from '../popup/live-session-cancel';
import NonAvailability from '../popup/non-availability';
import {  getCookie } from '../../../util_functions';

export default class AvailabilityList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeSlots: [
				{
					time: '00:00 AM',
				},
				{
					time: '01:00 AM',
				},
				{
					time: '02:00 AM',
				},
				{
					time: '03:00 AM',
				},
				{
					time: '04:00 AM',
				},
				{
					time: '05:00 AM',
				},
				{
					time: '06:00 AM',
				},
				{
					time: '07:00 AM',
				},
				{
					time: '08:00 AM',
				},
				{
					time: '09:00 AM',
				},
				{
					time: '10:00 AM',
				},
				{
					time: '11:00 AM',
				},
				{
					time: '12:00 PM',
				},
				{
					time: '01:00 PM',
				},
				{
					time: '02:00 PM',
				},
				{
					time: '03:00 PM',
				},
				{
					time: '04:00 PM',
				},
				{
					time: '05:00 PM',
				},
				{
					time: '06:00 PM',
				},
				{
					time: '07:00 PM',
				},
				{
					time: '08:00 PM',
				},
				{
					time: '09:00 PM',
				},
				{
					time: '10:00 PM',
				},
				{
					time: '11:00 PM',
				},
			],
			studentData: {},
			heightCalculated: null,
			availableSlotsHeight: null,
			currentTimeHeight: String,
			currentDate: new Date(),
			chatPopup: false,
			sessionPopupData: null,
			cancelSession: false,
			cancelSessionData: null,
			nonAvailabilityPopup: false,
		};
	}

	componentDidMount() {
		// this.dataCalling();
		this.currentTimeFunction();
		let height = 100 / 60;
		let currentTime = moment(new Date()).diff(new Date(moment().startOf('day').toString()), 'minutes');
		let currentTimeHeightSelection = currentTime * height + 10 + 'px';
		this.setState({
			currentTimeHeight: currentTimeHeightSelection,
		});
	}

	showData1 = () => {
		if (this.props.data.available_slots && this.props.data.available_slots.length) {
			// let timeZone = getCookie("time_zone")
			this.props.data.available_slots.forEach((each) => {
				let height = 100 / 60;
				let endEpochTimeCreater = moment(`${each.date_time.end_date} ${each.date_time.end_time}`).valueOf();
				let startEpochTimeCreater = moment(
					`${each.date_time.start_date} ${each.date_time.start_time}`
				).valueOf();
				let currentTimeHeightSelection =
					((endEpochTimeCreater - startEpochTimeCreater) / 1000 / 60) * height + 10 + 'px';
				each['availibilityBgHeight'] = currentTimeHeightSelection;
				let calculatedTimeStartBg = moment(startEpochTimeCreater).diff(
					moment(each.date_time.start_date).startOf('day').valueOf(),
					'minutes'
				);
				let currentTimeHeightSelectionBg = calculatedTimeStartBg * height + 10 + 'px';
				each['currentTimeHeightSelectionBg'] = currentTimeHeightSelectionBg;
			});
		} else {
			return;
		}
	};
	showData = () => {
		if (this.props.data.sessions_data && this.props.data.sessions_data.length) {
			this.props.data.sessions_data.forEach((each) => {
				let height = 100 / 60;
				let calculatedTime = moment(each.start_time).diff(
					new Date(moment(this.props.data.date).startOf('day').toString()),
					'minutes'
				);
				let calculatedHeight = each.duration * height + 'px';
				let heightGetCalu = calculatedTime * height + 10 + 'px';
				each['heightOfCard'] = heightGetCalu;
				each['cardHeight'] = calculatedHeight;
			});
		} else {
			return;
		}
	};

	componentDidUpdate() {
		if (moment(this.state.currentDate).format('YYYY-MM-DD') !== this.props.data.date) {
			clearInterval(this.currentTimeFunction());
		}
	}

	componentWillUnmount() {
		clearInterval(this.currentTimeFunction());
	}

	currentTimeFunction = () => {
		return setInterval(() => {
			let height = 100 / 60;
			let currentTime = moment(new Date()).diff(new Date(moment().startOf('day').toString()), 'minutes');
			let currentTimeHeightSelection = currentTime * height + 10 + 'px';
			this.setState({
				currentTimeHeight: currentTimeHeightSelection,
			});
		}, 60000);
	};

	openLiveSessionPopup = (data) => {
		data['availibilitySlotsData'] = this.props.data.available_slots;
		this.setState({
			chatPopup: true,
			sessionPopupData: data,
		});
	};

	closeSessionPopup = () => {
		this.setState({
			chatPopup: false,
		});
	};

	cancelSessionPopup = (data) => {
		this.setState({
			chatPopup: false,
			cancelSessionData: data,
			cancelSession: true,
		});
	};

	closeReplacementPopup = (data) => {
		if (data === false) {
			this.setState({
				cancelSession: false,
			});
		}
	};

	openOutSideAvailibility = (data) => {
		if (data === false) {
			this.setState({
				nonAvailabilityPopup: false,
			});
		} else {
			this.setState({
				nonAvailabilityPopup: true,
			});
		}
	};
	closeOutSideAvailibilityPopup = () => {
		this.setState({
			nonAvailabilityPopup: false,
		});
	};

	render() {
		this.showData();
		this.showData1();
		return (
			<>
				{this.state.chatPopup && (
					<LiveSession
						cancelSessionPopup={(data) => this.cancelSessionPopup(data)}
						closeSessionPopup={this.closeSessionPopup}
						sessionPopupData={this.state.sessionPopupData}
					/>
				)}
				{this.state.cancelSession && (
					<LiveSessionCancel
						closeReplacementPopup={(data) => this.closeReplacementPopup(data)}
						cancelSessionData={this.state.cancelSessionData}
					/>
				)}
				{this.state.nonAvailabilityPopup && (
					<NonAvailability closeNonAvailability={this.closeOutSideAvailibilityPopup} />
				)}
				<div className='pt-25px pb-25px pl-60px pr-60px slotsContainer'>
					<div className='flex justify-between items-start'>
						<div className='flex flex-col justify-start items-start relative'>
							{this.state.timeSlots.map((data, index) => {
								return (
									// let time = moment(date.time).format(LT);
									<div className='flex justify-start items-start h-100px' key={index + 1}>
										<div className='flex justify-center items-start timeClass'>
											<div className='text-sm font-normal text-mytTeacherAvailableText'>
												{data.time}
											</div>
										</div>
										<div className='flex justify-center items-start lineClass mt-2.5 pl-8'>
											<div className='border border-dashed w-657px'></div>
										</div>
									</div>
								);
							})}
							{this.props.data.available_slots?.map((data, index) => (
								<div
									key={index + 2}
									className='absolute left-107px w-88% availaibilityBg'
									style={{
										top: data.currentTimeHeightSelectionBg,
										height: data.availibilityBgHeight,
									}}
								></div>
							))}

							{this.props.data?.sessions_data?.map((data, index) => (
								<>
									<div
										key={index + 1}
										onClick={() => this.openLiveSessionPopup(data)}
										className={`shadow-mytboxshadow border border-mytgrey-subBorder rounded-lg w-10/12 pl-3 pr-5 flex justify-between items-center absolute left-107px cursor-pointer ${
											data?.outside_availability === 0
												? 'bg-white'
												: 'bg-slotsChanged pointer-events-none'
										} ${
											data?.requested_replacement === 0
												? 'bg-white'
												: 'bg-slotsChanged pointer-events-none'
										}  `}
										style={{ top: data.heightOfCard, height: data.cardHeight }}
									>
										<div className='w-full h-full relative'>
											<div
												className={`bg-mytTeacherProfileBgImg bg-contain bg-left-top bg-no-repeat h-full -ml-3 absolute top-0 left-0 w-full ${
													data.duration === 30 ? 'mandlaBGPosition30min' : ''
												}`}
											>
												<img
													className={`rounded-full w-11 h-11 object-cover absolute  ${
														data.duration === 90 ? 'slotsCard90' : 'top-25px left-7'
													}  ${data.duration === 30 ? 'slotsCard30' : ''}`}
													alt='userimg'
													src={data.avatar_medium}
												/>
											</div>
											<div className='h-full flex justify-between items-center'>
												<div
													className={`flex justify-center items-start flex-col h-full ml-24`}
												>
													<div className='flex justify-start items-center'>
														<div className='text-base font-normal text-mytTeacherAvailableText'>
															{data.student_name}
														</div>
														{/* {data.liveSessionValue === true ?
                            <div className="text-10px bg-mytRedText text-white pr-2 pl-2 pt-1 pb-1 ml-1 rounded">LIVE</div>
                            : null} */}
													</div>
													<div className='flex justify-start items-center'>
														<div className='text-mytgrey-lite font-normal text-xs '>
															1-on-1 Classes
														</div>
														<div className='w-6px h-6px rounded-full bg-mytgrey-lite mr-5px ml-5px'></div>
														<div className='flex justify-start items-center'>
															<div className='text-mytgrey-lite font-normal text-xs'>
																{data.duration} mins
															</div>
														</div>
														<div className='w-6px h-6px rounded-full bg-mytgrey-lite mr-5px ml-5px'></div>
														<div className='flex justify-start items-center'>
															<div className='text-mytgrey-lite font-normal capitalize text-xs'>
																{data.yoga_level}
															</div>
														</div>
													</div>
												</div>
												<div className='cursor-pointer'>
													<img
														src='https://images.myyogateacher.com/web_images/chevron-down-right-teacher-site.svg'
														alt='s'
													/>
												</div>
											</div>
										</div>
									</div>
									{data?.outside_availability ? (
										<div
											className={`infoIconClass ${data.duration === 90 ? 'mt-16' : ''} ${
												data.duration === 60 ? 'mt-10' : ''
											} ${data.duration === 30 ? 'mt-3' : ''}`}
											style={{ top: data.heightOfCard }}
											onClick={() => this.openOutSideAvailibility()}
										>
											<img
												className='infoIconRotate'
												src='https://images.myyogateacher.com/web_images/web_prod/alert-circle.svg'
												alt='info'
											/>
										</div>
									) : null}
								</>
							))}

                            {moment(this.state.currentDate).tz(getCookie("time_zone")).format('YYYY-MM-DD') === this.props.data.date ? (
                                <div
                                    className='absolute left-88 flex justify-start items-center w-full currentTimeLine'
                                    style={{ top: this.state.currentTimeHeight }}
                                >
                                    <div className='w-2 h-2 rounded-full bg-primary'></div>
                                    <div className='currentTimeLineScale bg-primary border border-primary w-88% -ml-1'></div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
