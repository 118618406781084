import React from 'react'

function LoggedInFooter() {
    return (
        <div className='md:flex md:justify-center mt-70px mx-auto '>
            <div className=' mx-auto md:w-1100px zm:px-5 xl:px-0 flex justify-between md:items-center md:flex-row zm:flex-col-reverse border-t border-borderGrayColor pt-5 pb-25px'>
                <div className='wrapper'>
                    <div className='flex sm:mb-5 zm:mb-15px ml-1 items-center gap-3'>
                        <a href='https://www.facebook.com/myyogateacher.inc' target='_blank' rel='noreferrer'>
                            <img
                                className=' hover:opacity-50'
                                src='https://images.myyogateacher.com/web_images/web_prod/dashboard/facebook-icon.svg'
                                alt='facebook_profile'
                            />
                        </a>
                        <a href='https://twitter.com/myYogaTeacher' target='_blank' rel='noreferrer'>
                            <img
                                className=' hover:opacity-50'
                                src='https://images.myyogateacher.com/web_images/web_prod/dashboard/twitter-icon.svg'
                                alt='twitter_profile'
                            />
                        </a>
                        <a href='https://www.instagram.com/myyogateacher' target='_blank' rel='noreferrer'>
                            <img
                                className=' hover:opacity-50'
                                src='https://images.myyogateacher.com/web_images/web_prod/dashboard/instagram-icon.svg'
                                alt='instagram_profile'
                            />
                        </a>
                    </div>
                    <div className='text-mytgrey-sublite font-normal md:text-10.5px zm:text-12.5px'>
                        © Copyright 2020 &nbsp; &nbsp; MyYogaTeacher Inc
                    </div>
                </div>
                <div className='flex md:justify-between xl:mb-8 md:mb-3 zm:mb-4 gap-30px'>
                    <div className=' flex lg:flex-row zm:flex-col gap-30px'>
                        <a href='https://myyogateacher.com/privacypolicy'
                            className='text-13px leading-4 font-medium zm:pb-3.5 xl:-ml-4 '>
                            Privacy Policy
                        </a>
                        <a href='https://myyogateacher.com/termsofuse'
                            className='text-13px leading-4 font-medium'>
                            Terms of use
                        </a>
                    </div>
                    <div className='flex lg:flex-row zm:flex-col gap-30px'>
                        <a href='https://myyogateacher.com/faq'
                            className='text-13px leading-4 font-medium  zm:pb-3.5'>
                            FAQ
                        </a>
                        <a href='https://myyogateacher.com/contactus'
                            className='text-13px leading-4 font-medium xl:mr-1 '>
                            Contact us
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoggedInFooter