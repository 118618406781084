import { isMobile, isTablet } from 'react-device-detect';
import { sha256 } from 'js-sha256';
import { getCookie, setCookie } from './cookie_funcs';

const all_event_flow = [];

export const segment_track = (name, attr = {}) => {
  try {
    const time_since_page_load = window?.myt_seg_start_date
      ? Math.floor((new Date().getTime() - window?.myt_seg_start_date) / 1000)
      : '';
    const options = {
      ...attr,
      user_platform: isMobile ? 'web_mobile' : isTablet ? 'web_tablet' : 'web',
      time_since_page_load,
      all_event_flow
    };
    try {
      options.webAppVersion = import.meta.env.VITE_APP_VER;
      options.webReleaseStage = import.meta.env.VITE_APP_STAGE;
    } catch {}

    mixpanel.track(name, options);
    all_event_flow.push(name);
  } catch (e) {
    console.log(e);
  }
};

let mixpanel_product_token = '';
let library_name = '';

switch (import.meta.env.VITE_APP_STAGE) {
  case 'production':
    mixpanel_product_token = 'ed9f0fc457e3336db454b250242fc0c8';
    library_name = 'production_student_new';
    break;
  default:
    mixpanel_product_token = 'cf64730183cec13930f0a39071df5ac5';
    library_name = 'testing_student_new';
    break;
}

export const load_mixpanel = () => {};

export const fbPixelPayloadFn = () => {
  const fbp = getCookie('_fbp');
  const fbc = getCookie('_fbc');
  const event_source_url = window.location.href;
  const platform = 'website';
  if (typeof getCookie('student_uuid') === 'undefined' || getCookie('student_uuid') === '') {
    return { fbp, fbc, platform, event_source_url };
  }
  return { fbp, fbc, platform, event_source_url };
};

export const fb_pixel_track_fn = (key, event, params = {}) => {
  try {
    if (key === 'track') {
      key = 'trackCustom';
    }
    window.fbq(key, event, params);
  } catch {
    setTimeout(() => {
      fb_pixel_track_fn(key, event, params);
    }, 5000);
  }
};

export const fb_event_track = (key, params = null) => {
  if (import.meta.env.VITE_APP_STAGE !== 'production') {
    return;
  }
  try {
    window.FB.AppEvents.logEvent(window.FB.AppEvents.EventNames[key], null, {
      ...params
    });
  } catch (err) {
    console.log(err);
  }
};

export const ga_event_track = (category, action, label, value = null) => {
  try {
    const params = {
      hitType: 'event',
      eventCategory: category,
      eventAction: action
    };
    if (label != null) {
      params.eventLabel = label;
    }
    if (value != null) {
      params.eventValue = value;
    }
    window.ga('send', params);
  } catch (err) {
    console.log(err);
  }
};

export const getAllEventsFlow = () => all_event_flow;

export const analytics_identify_alone = (id) => {
  mixpanel.identify(id);
};

export const analytics_set = (key, value) => {
  mixpanel.people.set({ [key]: value });
};

export const analytics_set_multiple = (properties) => {
  mixpanel.people.set(properties);
};

export const fb_standard_event_track_fn = (key, event, params = {}) => {
  try {
    window.fbq(key, event, params);
  } catch {
    setTimeout(() => {
      fb_standard_event_track_fn(key, event, params);
    }, 5000);
  }
};

export const error_log = (e) => console.log(e);

export const log_item = (item = '') => console.log(item);

export const analytics_reset = () => {
  mixpanel.reset();

  try {
    // window.analytics.ready(function(){
    //     window.mixpanel.cookie.clear();
    //   });
    // console.log(window.analytics.reset)
    // window.analytics.reset();
  } catch (e) {
    // alert(e)
  }
};

export const analytics_identify = (id, name, email) => {
  try {
    // window.analytics.identify(id, {
    // 	name,
    // 	email
    // });
    mixpanel.people.set({ email, name });
    mixpanel.identify(id, {
      name,
      email
    });
  } catch (e) {
    console.error(e);
    // alert("error while calling mixpanel identify")
  }
};

export const analytics_register = (key, value) => {
  try {
    mixpanel.register({
      key: value
    });
  } catch (e) {
    alert(e);
  }
};

export const analytics_alias = (id) => {
  try {
    // window.analytics.alias(id);

    mixpanel.alias(id);
  } catch (e) {
    console.error(e);
    // alert('error alias')
  }
};

export const segment_page = (name) => {
  try {
    mixpanel.page(name);
  } catch {}
};

export const get_fb_pixel_user_data_payload = (student_data_response) => {
  const payload = {};
  const first_name = student_data_response.first_name ? student_data_response.first_name : null;
  const last_name = student_data_response.last_name ? student_data_response.last_name : null;
  const gender = student_data_response.gender
    ? student_data_response.gender == 'MALE' || student_data_response.gender == 'HE'
      ? 'm'
      : student_data_response.gender == 'FEMALE' || student_data_response.gender == 'SHE'
        ? 'f'
        : null
    : null;
  const phone =
    student_data_response.phone_no && student_data_response.phone_cc
      ? `${student_data_response.phone_cc}${student_data_response.phone_no}`
      : null;
  const email = student_data_response.email ? student_data_response.email : null;
  if (first_name) {
    payload.fn = sha256(first_name.trim().toLowerCase());
  }
  if (last_name) {
    payload.ln = sha256(last_name.trim().toLowerCase());
  }
  if (gender) {
    payload.ge = sha256(gender);
  }
  if (phone) {
    payload.ph = sha256(phone.trim().toLowerCase());
  }
  if (email) {
    payload.em = sha256(email.trim().toLowerCase());
  }
  return payload;
};

export const fb_pixel_track_init_fn = (student_response_data) => {
  const userData = get_fb_pixel_user_data_payload(student_response_data);
  setCookie('userData', userData);
  if (import.meta.env.VITE_APP_STAGE !== 'production') {
    try {
      window.fbq('init', '1875947919214605', userData);
    } catch {
      console.log('in catch');
    }
  } else {
    try {
      window.fbq('init', '328108534563486', userData);
    } catch {
      console.log('in catch');
    }
  }
};

export const logAddToCartEvent = (
  content,
  contentData,
  contentId,
  contentType,
  currency,
  price
) => {
  if (import.meta.env.VITE_APP_STAGE !== 'production') {
    return;
  }
  try {
    const params = {};
    params.fb_content = content;
    params[window.FB.AppEvents.ParameterNames.CONTENT] = contentData;
    params[window.FB.AppEvents.ParameterNames.CONTENT_ID] = contentId;
    params[window.FB.AppEvents.ParameterNames.CONTENT_TYPE] = contentType;
    params[window.FB.AppEvents.ParameterNames.CURRENCY] = currency;
    window.FB.AppEvents.logEvent(window.FB.AppEvents.EventNames.ADDED_TO_CART, price, params);
  } catch {}
};
export const get_user_properties = (details = {}) => {
  let properties = {};
  if (Object.keys(details).length === 0) {
    properties = { all_properties_of_user: [] };
  } else {
    try {
      properties = {
        is_user_in_free_trial: details.is_free_trial,
        membership_level: details.is_student_subscribed === 1 ? details.subscribed_pkg_name : '',
        has_user_booked_session:
          details.number_of_sessions_finished +
            details.scheduled_sessions.length +
            details.cancelled_sessions.length >
          0
            ? 1
            : 0,
        has_user_joined_session: details.number_of_sessions_finished > 0 ? 1 : 0,
        number_of_sessions_booked_by_user:
          details.number_of_sessions_finished +
          details.cancelled_sessions.length +
          details.scheduled_sessions.length,
        number_of_sessions_joined_by_user: details.number_of_sessions_finished,
        pretrial: '',
        trial_student: details.is_free_trial,
        membership_status: details.is_student_subscribed === 1 ? 'yes' : ''
      };
      const utm_source = getCookie('myt_utm_source');
      const utm_medium = getCookie('myt_utm_medium');
      const utm_campaign = getCookie('myt_utm_campaign');
      const utm_content = getCookie('myt_utm_content');
      const utm_term = getCookie('myt_utm_term');

      const offer_type = getCookie('offer_type');
      const funnel_type = getCookie('funnel_type');
      properties.utm_source = utm_source;
      properties.utm_medium = utm_medium;
      properties.utm_campaign = utm_campaign;
      properties.utm_content = utm_content;
      properties.utm_term = utm_term;
      properties.offer_type = offer_type;
      properties.funnel_type = funnel_type;
    } catch (error) {
      properties = {};
    }
  }
  return { ...properties };
};

export const createOptions = () => ({
  style: {
    base: {
      fontSize: '14px',
      color: '#4A4A4A',
      letterSpacing: '0px',
      lineHeight: '1.5',
      fontWeight: '400',
      '::placeholder': {
        color: '#4a4a4a66',
        opacity: '0.4'
      }
    },
    invalid: {
      color: '#c23d4b'
    }
  }
});
