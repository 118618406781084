import { combineReducers } from 'redux';
import home from './home_reducer';
import availability from './availability_reducer';
import dashboard from './dashboard_reducer';
import profile from './profile_reducer';
import welcome from './welcome_reducer';
import zoom from './zoom_reducer';
import loading from './loading_reducer';
import notif from './notification_reducer';
import req_replacement from './request_replacement';
import { removeCookie } from '../../util_functions';
import * as t from '../action_types';
import ChatService from 'myt-chat/src/external/ChatService';
import dashboardv1 from '../../new_teacher_design/Redux/reducers/dashboard_reducer';
import mysession from '../../new_teacher_design/Redux/reducers/mysession_reducer';
import classdetails from '../../new_teacher_design/Redux/reducers/classdetails_reduces';
import loadingv1 from '../../new_teacher_design/Redux/reducers/loading_reducer';
import uploadFile from '../../new_teacher_design/Redux/reducers/uploadFile_reducer';

const appReducer = combineReducers({
	home,
	availability,
	dashboard,
	profile,
	welcome,
	zoom,
	loading,
	notif,
	req_replacement,
	dashboardv1,
	mysession,
	classdetails,
	loadingv1,
	uploadFile,
});

const rootReducer = (state, action) => {
	if (action.type === t.log_out) {
		removeCookie('teacher_token_1');
		ChatService.logout();
		localStorage.removeItem('teacherLocalChannels');
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
