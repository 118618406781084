import React, { Component } from 'react';
import './popup.css';
import { withRouter } from '../../../utils/router';

class Confirm extends Component {
	close_stu_sch_confirm = () => {
		this.props.router.navigate('/home');
	};
	render() {
		return (
			<div className='t-pop-cont'>
				<div className='t-pop-overlay' onClick={this.close_stu_sch_confirm} />

				<div className='st-confirm-div'>
					<img
						onClick={this.close_stu_sch_confirm}
						className='t-pop-cross'
						alt='close'
						src='https://images.myyogateacher.com/icons/ic_white_cross.png'
					/>
					<div className='sess-created'>
						<div className='yel-ok-stu'>
							<img alt='right' src='https://images.myyogateacher.com/icons/right-black.png' />
						</div>

						<h4>Session Created</h4>
						<button className='btn btn-blk-bd-pr' onClick={this.close_stu_sch_confirm}>
							Continue to home
						</button>
					</div>
				</div>
			</div>
		);
	}
}

const ConfirmWithRouter = withRouter(Confirm);

export default ConfirmWithRouter;
