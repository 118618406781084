import React from 'react';
import { connect } from 'react-redux';
import './styles.css';
import * as actions from '../../Redux/action_creators';
import Loader from '../FixedComponent/Loader';
import { CrossIcon } from "../../assets/svg/popup_icons.jsx";

interface IState {
    files: any;
    selectedImages: any;
    isLoading: boolean;
    errorMsg: string;
    scrollHeight: Number;
}
interface IMapStateToProps {
    files: any;
    page: number;
    student_name: string;
    get_customized_image_status: string;
    get_customized_image_response: any;
    get_all_file_status: string;
    get_all_file_response: any;
}

interface IMapDispatchToProps {
    closeImageSelectionPopup: () => void;
    customizePhotos: (payload?: any) => void;
    isScroll: (payload?: any) => void;
    getSelectedImage: (payload?: any) => void;
    getCustomizedImage: (payload?: any) => void;
}

type ImageSelectionAllProps = IMapStateToProps & IMapDispatchToProps;
export class ImageSelectionToShare extends React.Component<ImageSelectionAllProps, IState>{

    myRef: any;

    constructor(props) {
        super(props);
        this.state = {
            files: this.props?.files,
            selectedImages: [],
            isLoading: false,
            errorMsg: '',
            scrollHeight: 0
        }
        this.myRef = React.createRef();
    }

    componentDidMount(): void {

    }

    componentDidUpdate(prevProps): void {
        if (prevProps.get_customized_image_status === 'loading' && this.props.get_customized_image_status === 'success') {
            this.setState({ isLoading: false });
            this.props.customizePhotos(this.props?.get_customized_image_response?.data?.customised_images);
        }
        if (prevProps.get_customized_image_status === 'loading' && this.props.get_customized_image_status === 'fail') {
            this.setState({ isLoading: false });
        }
        if (prevProps.get_all_file_status === 'loading' && this.props.get_all_file_status === 'success') {
            this.setState({ files: [...this.state.files, ...this.props.get_all_file_response.data.items] });
        }
    }

    getCustomizedTheme = () => {
        if (this.state.selectedImages.length) {
            let payload = {
                uuids: this.state.selectedImages.map((_) => {
                    return _.uuid
                })
            }
            this.setState({ isLoading: true });
            this.props.getCustomizedImage(payload);
        } else {
            this.setState({ errorMsg: 'Choose a picture to continue' });
        }
    }

    getSelectedImage = () => {
        if (this.state.selectedImages.length) {
            this.props.getSelectedImage(this.state.selectedImages);
        } else {
            this.setState({ errorMsg: 'Choose a picture to continue' });
        }
    }

    changeThemeSelection = (event, img: any, indexValue) => {
        let selectedImages = this.state.selectedImages;
        img.isChecked = event.target.checked;
        if (event.target.checked) {
            selectedImages.splice(indexValue, 0, img);
            this.setState({ selectedImages });
        } else {
            let imageIndex = selectedImages.findIndex(ele => ele.uuid === img.uuid);
            imageIndex >= 0 ? selectedImages.splice(imageIndex, 1) : '';
            this.setState({ selectedImages });
        }
        this.state.selectedImages.length ? this.setState({ errorMsg: '' }) : '';
    }

    onScroll = () => {
        if (this.myRef.current && this.props.page >= 0) {
            const { scrollTop, scrollHeight, clientHeight } = this.myRef.current;
            if ((Math.ceil(scrollTop + clientHeight) === scrollHeight || Math.ceil(scrollTop + clientHeight) - 1 === scrollHeight) && scrollHeight !== this.state.scrollHeight) {
                this.setState({ scrollHeight });
                this.props?.isScroll({ page: this.props.page + 1 })
                // This will be triggered after hitting the last element.
                // API call should be made here while implementing pagination.
            }
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading === true ?
                        <Loader /> :
                        <div className='relative inset-0 z-50'>
                            <div
                                className='fixed h-screen w-full zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'
                            >
                                <div className='z-50 bg-white rounded-lg md:p-10 zm:p-30px zm:w-full relative md:w-669px'>
                                    <div className='flex justify-end cursor-pointer absolute top-1 right-1' onClick={this.props?.closeImageSelectionPopup}>
                                        <div className='mt-1 mr-1'>
                                            {<CrossIcon />}
                                        </div>
                                    </div>
                                    <div className='mb-0'>
                                        <div className='font-semibold text-subText md:text-17 zm:text-17'>Select pictures to share with {this.props.student_name}</div>
                                        <div className='flex items-center'>
                                            <p className='text-mytgrey-contactHeading text-13 font-medium mt-10px mb-15px'>{this.state.selectedImages.length} Selected</p>
                                        </div>
                                        <div className='flex flex-wrap gap-10px p-2 rounded-lg max-h-300px' onScroll={this.onScroll} ref={this.myRef} style={{ backgroundColor: '#FFEBDD', height: 'auto', overflow: 'auto' }}>
                                            {this.state.files.length && this.state.files.map((img, i) => (
                                                <div key={img.uuid} onClick={() => this.changeThemeSelection({ target: { checked: !img.isChecked } }, img, i)} className={`rounded-lg border-2 cursor-pointer ${img.isChecked ? 'border-primary' : ''}`} style={{ width: 'fit-content', blockSize: 'fit-content', height: 'fit-content' }}>
                                                    <label className="checkbox-container">
                                                        <input id={img.uuid} type="checkbox" checked={img.isChecked} />
                                                        <span onClick={() => this.changeThemeSelection({ target: { checked: !img.isChecked } }, img, i)} className={`checkmark ${img.isChecked ? 'bg-primary' : ''}`}></span>
                                                    </label>
                                                    <div className="rounded-lg w-fit h-full">
                                                        <img src={img.signed_url} className="rounded-lg object-contain" style={{ height: '100px', minWidth: 'auto', maxWidth: '176px' }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {
                                            this.state.errorMsg &&
                                            <p className='text-mytRedText text-15.5 font-medium my-5'>{this.state.errorMsg}</p>
                                        }
                                        <div className='flex mt-20px'>
                                            <button onClick={this.getSelectedImage} className='btn btn-primary h-46px md:text-17 zm:text-13px md:py-2 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-9px'>
                                                Share photos
                                            </button>
                                            <button onClick={this.getCustomizedTheme} className='flex ml-2 h-46px items-center btn-white rounded-lg px-4 md:text-17 zm:text-17 md:py-2 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-9px'>
                                                Customize photos
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                }
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        get_customized_image_status: state.uploadFile.get_customized_image_status,
        get_customized_image_response: state.uploadFile.get_customized_image_response,
        get_all_file_status: state.uploadFile.get_all_file_status,
        get_all_file_response: state.uploadFile.get_all_file_response,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getCustomizedImage: (payload) => {
            dispatch(actions.getCustomizedImage(payload))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelectionToShare)