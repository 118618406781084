/* eslint-disable react/prop-types */
import React from 'react';
import DonutChart from 'react-donut-chart';
import '../my-sessions/style.css';

const PieChart = (props) => {
	const data = props.chartData.datasets[0].data.map((value, index) => ({
		label: props.chartData.labels[index],
		value: value,
	}));
	return (
		<div className='pieChartcontainer'>
			<DonutChart data={data} colors={props.chartData.datasets[0].backgroundColor} className='dounutClass' />
		</div>
	);
};

export default PieChart;
