import React, { useEffect, useState, forwardRef } from 'react';
import { my_session_question_classes } from '../../../../../redux/api_functions/availability_api';
import { removeCookie, analytics_reset } from '../../../../../util_functions';
import { withRouter } from '../../../../../utils/router';
import { useDispatch,  } from 'react-redux';
import * as actions from '../../../../../new_teacher_design/Redux/action_creators';

const SessionRecapPopup = (props) => {
	const [getQuestionData, setQuestionData] = useState(props.data);
	const [getQuestionData1, setQuestionData1] = useState(
		getQuestionData?.session_questions[0].answer !== 0 ? getQuestionData?.session_questions[0].answer : ''
	);
	const [getQuestionData2, setQuestionData2] = useState(
		getQuestionData?.session_questions[1].answer !== 0 ? getQuestionData?.session_questions[1].answer : ''
	);
    const dispatch = useDispatch()

	const customAnswer1 = (e) => {
		setQuestionData1(e.target.value);
	};
	const customAnswer2 = (e) => {
		setQuestionData2(e.target.value);
	};

	const save = () => {
		let payload = {
			recap: getQuestionData2,
			notes: getQuestionData1,
			session_uuid: getQuestionData?.session_uuid,
		};

		my_session_question_classes(payload)
			.then((response) => {
				if (response?.data?.status === 'success') {
					// console.log(" res ", response);
					props.resRecapQuestionClose(getQuestionData.start_date);
                    dispatch(actions.set_mysession_variable('my_session_data_status', 'none'))
                    dispatch(actions.set_mysession_variable('my_session_status', 'none'))
                    dispatch(actions.getMySessionV1(props.selectedPayload));
                    dispatch(actions.getMySessionData(props.selectedPayload))
				} else if (response?.response?.status === 401) {
					removeCookie('teacher_token_1');
					localStorage.removeItem('teacherLocalChannels');
					analytics_reset();
					this.props.router.navigate('/login');
					this.setState({
						isLoading: true,
					});
				}
			})
			.catch((e) => {
				// console.log(JSON.stringify(e));
				if (e.response.status === 401) {
					removeCookie('teacher_token_1');
					localStorage.removeItem('teacherLocalChannels');
					analytics_reset();
					this.props.router.navigate('/login');
				}
			});
	};

	const modal = React.useRef(null);

	const on_close_modal = (e) => {
		if (modal && modal.current && !modal.current.contains(e.target)) {
			props.recapQuestionClose();
		}
	};


	return (
		<>
			<div className='relative inset-0 z-index-max'>
				<div
					onClick={(e) => on_close_modal(e)}
					className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'
				>
					<div
						ref={modal}
						className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'
					>
						<div className='flex justify-end cursor-pointer absolute top-1 right-1'>
							<div onClick={props.recapQuestionClose}>
								<svg
									width='40'
									height='40'
									viewBox='0 0 34 34'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<rect width='34' height='34' rx='17' fill='none' />
									<path
										d='M22 12L12 22'
										stroke='#BFBFBF'
										strokeWidth='1.66667'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
									<path
										d='M12 12L22 22'
										stroke='#BFBFBF'
										strokeWidth='1.66667'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
						</div>
						<div className='mb-0'>
							<div className='flex justify-center flex-col items-center'>
								<div className='w-full'>
									<div>
										<div className='text-19 text-mytgrey-subText font-semibold'>
											Session recap -{' '}
											<span className='capitalize'>{getQuestionData?.session_recap_text}</span>
										</div>
										<div className='text-13.5 text-mytgrey-videoPopup font-medium mt-2'>
											It helps to create student roadmap
										</div>
										<div>
											<div className='text-mytLabel text-13.5 font-medium mt-25px'>
												1. Teacher notes
											</div>
											<div>
												<textarea
													placeholder='Add answer here..'
													value={getQuestionData1}
													onChange={customAnswer1}
													className='formInput h-100px mt-1 p-18px mySessionPlaceholderClr'
												></textarea>
											</div>
											<div className='text-12.5px font-medium text-mytBgGrayLiteV1 mt-15px'>
												This won’t be shared with student
											</div>
										</div>
										<div className='mb-25px'>
											<div className='text-mytLabel text-13.5 font-medium mt-25px'>
												2. Session recaps
											</div>
											<div>
												<textarea
													placeholder='Add answer here..'
													value={getQuestionData2}
													onChange={customAnswer2}
													className='formInput h-100px mt-1 p-18px mySessionPlaceholderClr'
												></textarea>
											</div>
											<div className='text-12.5px font-medium text-mytBgGrayLiteV1 mt-15px'>
												This will be shared with student
											</div>
										</div>
										<button className='btn btn-orange' onClick={() => save()}>
											Save answers
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const SessionRecapPopupWithRouter = withRouter(SessionRecapPopup);

export default SessionRecapPopupWithRouter;
