import { isMobile, isTablet } from 'react-device-detect';
import Cookies from 'js-cookie';

export const replaceNewLinesWithBreakCharacter = (message) => {
  let newMessage = '';
  const ms = message.trim().split('\n');
  for (let i = 0; i < ms.length; i++) {
    newMessage += ms[i];
    if (i < ms.length - 1) newMessage += '%0D%0A%0D%0A';
  }
  return HtmlReactParser(newMessage);
};

export const getUtmParamsForSharingUrl = (
  sessionUuid,
  utm_campaign,
  membershipTag,
  utm_source_prep,
  workshop_name = null,
  utm_content
) => {
  let queryString = '?';
  let utm_source = '';

  utm_source = `${isMobile ? 'mobile_web_app' : isTablet ? 'tablet_web_app' : 'desktop_web'}${
    utm_source_prep !== '' && utm_source_prep !== undefined && utm_source_prep !== null ? ':' : ''
  }${utm_source_prep}`;
  let utm_term = '';
  utm_term = `${membershipTag !== '' && membershipTag !== undefined && membershipTag !== null ? membershipTag : ''}${
    membershipTag !== '' &&
    membershipTag !== undefined &&
    membershipTag !== null &&
    sessionUuid !== null &&
    sessionUuid !== undefined
      ? ':'
      : ''
  }${sessionUuid !== null && sessionUuid !== undefined ? sessionUuid : ''}`;

  if (workshop_name !== null && workshop_name !== undefined) {
    utm_term = workshop_name;
  }
  // let utm_term = sessionUuid !== null && sessionUuid !== undefined ? sessionUuid : ''
  queryString += `utm_campaign=${utm_campaign}`;
  queryString += `&utm_source=${utm_source}`;
  if (utm_term !== '') {
    queryString += `&utm_term=${utm_term}`;
  }
  if (utm_term !== '' && utm_content !== '') {
    queryString += `&utm_term=${utm_term}&utm_content=${utm_content}`;
  }

  return queryString;
};

export const capitalizeFirstLetter = (string) => {
  console.log('asdajsdaskhdgkasdasgkdkasgdkas', string);
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getCookie = (cookie_name) => Cookies.get(cookie_name);

export const setCookie = (cookie_name, value, age = 5184000) => {
  Cookies.set(cookie_name, value, {
    expires: age / 864e2,
    path: '/',
    domain: '.myyogateacher.com',
    secure: true,
    sameSite: 'Strict'
  });
};

export const isUserLoggedIn = () => {
  const impersonationFromTeacherToken = getCookie('impersonationFromTeacherToken');
  const teacher_token_1 = getCookie('teacher_token_1');

  const teacherTokenExits = teacher_token_1 && teacher_token_1 !== '';
  const impersonationTeacherTokenExits =
    impersonationFromTeacherToken && impersonationFromTeacherToken !== '';

  return teacherTokenExits || impersonationTeacherTokenExits || false;
};

// outsideClickHandler
export function handleClickOutside(elementRef, callback) {
  function handleClickOutside(event) {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      callback();
    }
  }

  document.addEventListener('click', handleClickOutside);

  return function removeOutsideClickListener() {
    document.removeEventListener('click', handleClickOutside);
  };
}
