import React from 'react'

const ConfirmPopup = (props) => {
    return (
        <>
            <div className='relative inset-0 z-50'>
                <div
                    className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'
                >
                    <div className='z-50 bg-white zm:rounded-tl-3xl zm:rounded-tr-3xl zm-roundex-lb-none zm:rounded-rb-none md:rounded-lg md:p-10 zm:p-30px md:w-492px zm:w-full relative'>
                        <div className='flex justify-end cursor-pointer absolute top-1 right-1' onClick={props.closeConfirmPopup}>
                            <div className='mt-1 mr-1'>
                                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect width='34' height='34' rx='17' fill='none' />
                                    <path
                                        d='M22 12L12 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        d='M12 12L22 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center md:mt-0 zm:mt-9px'>
                            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="44" height="44" rx="22" fill="#EE731B" />
                                <path d="M31.4265 14.9285L18.4622 27.8928L12.5693 22" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <div className='text-subText md:text-19 zm:text-17 font-semibold pt-4'>{props?.is_from_change_session ? 'Class details updated': 'Class cancelled'}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmPopup