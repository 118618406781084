import React from "react";

const MobileHeader = ({ isOpen, toggle }) => {
  console.log(isOpen);

  return (
    // <div className={isOpen ? 'grid grid-rows-4 text-center items-center bg-yellow-500' : 'hidden'} onClick={toggle}>
    <div
      className={
        isOpen
          ? "text-center items-center bg-yellow-500 duration-1000 ease-in-out"
          : "hidden duration-1000 ease-in-out"
      }
    >
      <div className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0" onClick={toggle}></div>
      <div className="bg-white absolute inset-0 w-screen h-3/6">
        <div
          className="flex justify-between items-center zm:px-5 xl:px-10 h-16  text-black relative font-mono"
          role="navigation"
        >
          <img
            className="icon zm:w-10 zm:flex xl:hidden"
            src="https://images.myyogateacher.com/web_images/web_prod/mobileHeaderIcon.png" alt="mobileHeaderIcon"
          />

          <div className="px-4 cursor-pointer md:hidden" onClick={toggle}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </div>
        <div className="">
          <a className="navItem zm:py-3" href='https://myyogateacher.com/pricing'>Pricing</a>
          <a className="navItem zm:py-3" href='https://myyogateacher.com/articles'>Articles</a>
          <div className="navItem zm:py-3 flex justify-center items-center">
            More{" "}
            <svg
              className="w-5 h-5 zm:ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
        </div>
        <div className="flex flex-col zm:mx-10 zm:mt-10">
          <button className="btn zm:bg-mytgray hover:bg-mytgrey-hover zm:my-2">
            Login
          </button>
          <button className="btn btn-orange zm:my-2">
            Start your free trial
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
