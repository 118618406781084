import React, { Component } from 'react';
import { Player, BigPlayButton } from 'video-react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import LoadingScreen from '../../util_components/loading_screen';
import CustomModal from '../../util_components/custom_modal';
import urls from '../../../urls';
import { post_api } from '../../../redux/api_funcs';
import * as actions from '../../../redux/action_creators';
import './journey.css';
import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class YogaJourney extends Component {
  state = {
    loading: true,
    student_slug: '',
    journey: [],
    student_create_date: '',
    session_open: false,
    session_url: '',
    sessions_finished: 0,
    sessions_scheduled: 0
  };

  componentDidMount() {
    const student_slug = this.props.router.params.studentSlug;
    try {
      const {sessions_scheduled} = this.props.router.location.state;
      const {sessions_finished} = this.props.router.location.state;
      const {student_uuid} = this.props.router.location.state;
      let {profile_photo} = this.props.router.location.state;
      const {student_name} = this.props.router.location.state;
      profile_photo = profile_photo || '';

      if (student_uuid) {
        this.setState(
          {
            student_slug,
            sessions_finished,
            sessions_scheduled,
            student_name,
            student_uuid,
            profile_photo
          },
          () => this.load_data()
        );
      } else {
        this.props.router.navigate(`/student-profile/${this.props.router.params.studentSlug}`);
      }
    } catch (e) {
      this.props.router.navigate(`/student-profile/${this.props.router.params.studentSlug}`);
    }

    sendEventToClarity();
  }

  load_data = () =>
    this.setState({ loading: true }, () => {
      const { student_slug } = this.state;
      const url = urls.get_yoga_journey_by_slug;
      const payload = { student_slug };
      post_api(url, payload, true).then((res) => {
        this.setState({
          journey: [...res.data.results],
          student_create_date: res.data.student_created_date,
          loading: false
        });
      });
    });

  renderInitialStepItem = () => {
    const createDate = this.state.student_create_date;
    return (
      <div className="yj-cont">
        <div className="yj-date">
          {createDate ? (
            <>
              <span className="y-date">{moment(createDate).format('DD')}</span>
              <span className="y-month">{moment(createDate).format('MMM YYYY')}</span>
            </>
          ) : null}
        </div>
        <div className="yj-info-up">
          <div className="yj-info">
            <h4>You took the first steps for physical and mental wellness</h4>
            <p className="light-text">You signed up :-)</p>
            <img
              className="foot-print"
              src="https://images.myyogateacher.com/Teacher-Page/icons/foott_yj.svg"
            />
          </div>
        </div>
      </div>
    );
  };

  open_session = (url) => {
    this.setState({ session_url: url }, () => this.setState({ session_open: true }));
  };

  render_item = (item) => (
      <div key={item.session_uuid}>
        <div className="yj-cont">
          <div className="yj-date">
            {item.session_teacher_time ? (
              <>
                <span className="y-date">
                  {moment(item.session_teacher_time, 'MMM DD, YYYY').format('DD')}
                </span>
                <span className="y-month">
                  {moment(item.session_teacher_time, 'MMM DD, YYYY').format('MMM YYYY')}
                </span>
              </>
            ) : (
              <>
                <span className="y-date" />
                <span className="y-month" />
              </>
            )}
          </div>
          <div className="yj-info-up">
            <div className="yj-info">
              <h4>
                {item.session_type !== 'YOGA' &&
                  (item.session_type !== 'GROUP_SESSION_STUDENT' ||
                    item.session_type !== 'GROUP_SESSION') &&
                  item.teacher_name}
                {item.session_type === 'YOGA' && `${item.teacher_name} (1-on-1)`}
                {(item.session_type === 'GROUP_SESSION_STUDENT' ||
                  item.session_type === 'GROUP_SESSION') &&
                  `${item.session_name.substring(13)} by ${item.teacher_name} (Group class)`}
              </h4>
              <p className="his_which_session">{item.session_number} session</p>
              <div className="yj-video-cont">
                {item.replay_url ? (
                  <>
                    <img src="https://images.myyogateacher.com/mysession-video2.png" alt="video" />
                    <button
                      className="v-play-btn"
                      onClick={() => this.open_session(item.replay_url)}
                    >
                      <img
                        className="play"
                        src="https://images.myyogateacher.com/play-sm-icon.png"
                        alt="play"
                      />
                    </button>
                  </>
                ) : (
                  <>
                    <img
                      src="https://images.myyogateacher.com/video_thumbnail_not_recorded.png"
                      alt="no-rec"
                    />
                    <div className="yj-vid-err">
                      <img
                        className="err"
                        src="https://images.myyogateacher.com/ic_error.png"
                        alt="err"
                      />
                      <span>{item.msg_for_empty_replay_url}</span>
                    </div>
                  </>
                )}
              </div>
              {item.session_recap ? (
                <>
                  <h5>Session recap</h5>
                  <p className="s-recap-info break-words">{item.session_recap}</p>
                </>
              ) : null}

              {item.session_notes ? (
                <>
                  <h5>Session notes</h5>
                  <p className="s-recap-info break-words">{item.session_notes}</p>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {/* {!!item.student_roadmap.uuid ? this.render_roadmap_item(item, index) : null} */}
      </div>
    );

  contextMenu = (e) => e.preventDefault();

  close_session = () => this.setState({ session_open: false });

  goBack = () => this.props.router.navigate(-1);

  open_roadmap = () => {
    this.props.router.navigate(`/road-map/${this.props.router.params.studentSlug}`, {
      state: {
        show_roadmap: 'true',
        student_uuid: this.state.student_uuid,
        profile_photo: this.state.profile_photo,
        student_name: this.state.student_name
      }
    });
  };

  create_roadmap = () =>
    this.setState({ loading: true }, () => {
      const url = urls.create_roadmap;
      const payload = { student_uuid: this.state.student_uuid };
      post_api(url, payload, true)
        .then(() => {
          this.props.set_home_variable('is_roadmap_present', true);
          this.setState({ loading: false });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ loading: false });
        });
    });

  render() {
    const { loading, journey, student_create_date, sessions_finished, sessions_scheduled } =
      this.state;
    return (
      <div className="m-card-body">
        <div className="m-card">
          <div className="m-card-header">
            <div className="m-card-head-wrap">
              <button className="btn btn-mcard-back" onClick={this.goBack}>
                <img
                  alt="back-arrow"
                  src="https://s3.amazonaws.com/images.myyogateacher.com/icons/back-arrow-white1.svg"
                />
              </button>
              <h1>Yoga Journey</h1>
              {sessions_scheduled > 0 || sessions_finished > 0 ? (
                <p>{sessions_finished} Sessions done</p>
              ) : null}
              {this.props.is_roadmap_present ? (
                <button className="btn green-btn btn-go-roadmap" onClick={this.open_roadmap}>
                  Roadmap{' '}
                  <img src="https://images.myyogateacher.com/home-page/warrow.png" alt="arrow" />
                </button>
              ) : (
                <button onClick={this.create_roadmap} className="btn green-btn btn-go-roadmap">
                  Create roadmap
                </button>
              )}
            </div>
          </div>

          <div className="m-card-content">
            {journey.map(this.render_item)}
            {student_create_date ? this.renderInitialStepItem() : null}
          </div>
        </div>
        {loading ? <LoadingScreen /> : null}
        <CustomModal show={this.state.session_open} close={this.close_session}>
          <div onContextMenu={this.contextMenu}>
            <Player playsInline autoPlay src={this.state.session_url}>
              <BigPlayButton position="center" />
            </Player>
          </div>
        </CustomModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    is_roadmap_present: state.home.is_roadmap_present
  });

const mapDispatchToProps = (dispatch) => ({
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    }
  });

const YogaJourneyWithRedux = connect(mapStateToProps, mapDispatchToProps)(YogaJourney);
const YogaJourneyWithRouter = withRouter(YogaJourneyWithRedux);

export default YogaJourneyWithRouter;
