import React from 'react';

import cross from '../../../assets/images/cross.png';

const HELPFUL_QUESTIONS = [
	'What would you like to get from your yoga practice?',
	'So let’s say you practice yoga for six months, what kind of results would you like to achieve?',
	'What are you doing for your exercise and wellbeing now? How’s that going for you?',
	'Have you practiced yoga before?',
	'What kinds of things have you done to help with your goals in the past?',
	'What has worked and what hasn’t worked for you?',
	'What’s motivating you to want to change now?',
];

const HelpfulQuestions = (props) => {
	let { onCloseRequest } = props;
	return (
		<div className='-inset-0 flex justify-center items-center fixed h-screen w-screen bg-mytHeaderBg z-10'>
			<div className='w-123 rounded-lg flex flex-col items-center relative  bg-white pt-10 pb-11'>
				<img
					onClick={(event) => event.target === event.currentTarget && onCloseRequest()}
					className='w-2.75 h-2.75 absolute top-2.5 right-2.5 cursor-pointer'
					src={cross}
				/>
				<p className='font-bold text-2xl 	leading-7.2	'>Helpful questions for consult</p>
				{HELPFUL_QUESTIONS.map((question, index) => (
					<span
						className='mt-8 w-111 mx-6 rounded-md flex flex-col items-start bg-consultQuestionsBackground'
						key={index}
					>
						<p className='px-4.2 font-inter text-base text-consultQuestions my-4 leading-5'>{question}</p>
					</span>
				))}
			</div>
		</div>
	);
};

export default HelpfulQuestions;
