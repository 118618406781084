import React from 'react';

import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile_header';

export function withLayout(OriginalComponent) {
	return class WrappedLayout extends React.Component {
		render() {
			return (
				<>
					{this.props?.isHeader && <Header toggle={this.props?.toggle} />}
					{this.props?.isHeader && <MobileHeader isOpen={this.props?.isOpen} toggle={this.props?.toggle} />}
					<OriginalComponent {...this.props} />
					{this.props?.isFooter && <Footer />}
				</>
			);
		}
	};
}
