import React, { useState } from 'react';
import Duration from '../FixedComponent/Input/Duration';
import CheckBox from '../FixedComponent/Input/Checkbox';

const EditClassDuration = (props) => {

    return (
        <>
            <div className='relative inset-0 z-50'>
                <div
                    className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'
                >
                    <div className='z-50 bg-white zm:rounded-tl-3xl zm:rounded-tr-3xl zm-roundex-lb-none zm:rounded-rb-none md:rounded-lg md:p-10 zm:p-30px md:w-492px zm:w-full relative'>
                        <div className='flex justify-end cursor-pointer absolute top-1 right-1' onClick={props.closeClassDurationPopup}>
                            <div className='mt-1 mr-1'>
                                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect width='34' height='34' rx='17' fill='none' />
                                    <path
                                        d='M22 12L12 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        d='M12 12L22 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className='mb-0'>
                            <div className='font-semibold text-subText md:text-19 zm:text-17 md:mt-0 zm:mt-9px'>Edit class duration</div>
                            <p className='text-mytGrayLiteV2 font-medium text-sm pt-2 mb-6'>{props.sessionDetails.student_name} - {props.sessionDetails.start_time}, {props.sessionDetails.duration} Mins class</p>

                            <Duration inputTypeLabel={'Duration'} selectedDuration={props.classDuration} onClickDurationValue={(dt) => props.changeClassDuration(dt)} />

                            <div className={`shadow-teacherCardShadow bg-mytTrailCard rounded-lg mt-5 flex justify-between items-center md:px-6 zm:px-4 md:py-5 zm:py-4 mb-6`}>
                                <CheckBox
                                    id="1"
                                    label={"I've discussed this with my student & we're in agreement"}
                                    className={'zm:text-13.5px md:text-sm'}
                                    value={props.is_changed_by_teacher}
                                    isChecked={props.is_changed_by_teacher ? true : false}
                                    onClick={props.changeTeacherSelection}
                                />
                            </div>
                            {props.durationReason && <p className='text-mytgrey-lite text-13.5 font-medium mb-3 leading-tight mb-1 text-red-600 dark:text-red-500'>{props.durationReason}</p>}
                            <button disabled={props.is_changed_by_teacher ? false : true} className='btn btn-primary w-full md:text-18.5 zm:text-17 md:py-5 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-9px' onClick={props.updateClassDuration}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditClassDuration;