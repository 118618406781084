import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import HtmlReactParser from 'html-react-parser';
import Header from '../../util_components/header';
import { get_num_with_ordinal, sendEventToClarity } from '../../../util_functions';
import { pretexts, posttexts } from './templates';
import 'react-quill/dist/quill.snow.css';
import './roadmap.css';

export const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }],
    ['clean']
  ]
};

export const formats = [
  'font',
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet'
];
export default class Roadmap extends Component {
  state = {
    items: [
      { teacher_uuid: 1, teacher_name: 'Anupama' },
      { teacher_uuid: 2, teacher_name: 'Rachana' },
      { teacher_uuid: 3, teacher_name: 'Divya' }
    ],
    teacher_uuid: 2,
    text: ''
  };

  componentDidMount() {
    sendEventToClarity();
  }

  render_single_roadmap = (teacher, index) => (
      <div className="single-map">
        <div className="map-left">
          {HtmlReactParser(pretexts('harsha', index + 1))}
          <ReactQuill
            value={this.state.tempalte || ''}
            onChange={this.handleTemplate}
            ref={(el) => {
              this.reactQuillRef = el;
            }}
            modules={modules}
            placeholder="So you would like to heal your knee and decrease back pain. As well as lose some weight and reduce your stress and anxiety. &#10; You’ve recently had knee surgery and after years of working at a desk are experiencing lots of back pain. Plus the stress from your job is coming home with you."
            formats={formats}
          />
          {HtmlReactParser(posttexts('Harsha Hota'))}
          {/* {posttexts('harsha', index)} */}
          <button>Save</button>
          <button>Save draft</button>
          <button>Send email</button>
        </div>
        <div className="map-center">
          <div className="map-center-box" />
          <div className="map-center-circle">
            <div>{get_num_with_ordinal(index + 1)}</div>
            <hr />
            <span>23 Oct 4:30 PM CST</span>
            <hr />
            <span>Teacher: {teacher.teacher_name}</span>
          </div>
          <div className="map-center-box" />
        </div>
        <div className="map-right">
          {teacher.teacher_uuid === this.state.teacher_uuid ? (
            <>
              <textarea />
              <button>Save</button>
            </>
          ) : (
            <div style={{ textAlign: 'left', width: '100%' }}>
              Message by {teacher.teacher_name}
            </div>
          )}
        </div>
      </div>
    );

  render() {
    return (
      <div className="roadmap">
        <Header hide_tabs />
        <div className="roadmap-inner">
          <div style={{ textAlign: 'center' }}>Student: Harsha</div>
          {this.state.items.map((item, index) => this.render_single_roadmap(item, index))}
        </div>
      </div>
    );
  }
}
