import React from 'react';
import { covert_to_pascal_with_spaces, convert_to_pascal } from '../../../util_functions';

export const RenderBasicWithoutEdit = props => {
	return (
		<div>
			<div className="profile-basic-edit-details">
				<div className="profile-basic-address-line">
					<div className="profile-basic-address-line-text">Your personal details</div>
				</div>
			</div>
			<div className="basic-details-we">
				<div className="basic-details-we-text">
					<div className="profile-basic-detail-values non-edit-headlines">
						<div className="profile-basic-detail-index">First Name</div>
						<div className="profile-basic-detail-value">
							{props.profile.first_name === '' || typeof props.profile.first_name === 'undefined'
								? '- -'
								: covert_to_pascal_with_spaces(props.profile.first_name)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">Last Name</div>
						<div className="profile-basic-detail-value">
							{props.profile.last_name === '' || typeof props.profile.last_name === 'undefined'
								? '- -'
								: covert_to_pascal_with_spaces(props.profile.last_name)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">Email</div>
						<div className="profile-basic-detail-value">
							{props.profile.email === '' || typeof props.profile.email === 'undefined' ? '- -' : props.profile.email}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">Phone Number</div>
						<div className="profile-basic-detail-value">
							{props.profile.phone_personal === '' || typeof props.profile.phone_personal === 'undefined'
								? '- -'
								: covert_to_pascal_with_spaces(props.profile.phone_personal)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">Gender</div>
						<div className="profile-basic-detail-value">
							{props.profile.gender === '' || typeof props.profile.gender === 'undefined'
								? '- -'
								: convert_to_pascal(props.profile.gender)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">Marital Status</div>
						<div className="profile-basic-detail-value">
							{props.profile.marital_status === '' || typeof props.profile.marital_status === 'undefined'
								? '- -'
								: convert_to_pascal(props.profile.marital_status)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">Date of Birth</div>
						<div className="profile-basic-detail-value">
							{props.profile.date_of_birth === '' || typeof props.profile.date_of_birth === 'undefined'
								? '- -'
								: covert_to_pascal_with_spaces(props.profile.date_of_birth)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">Street</div>
						<div className="profile-basic-detail-value">
							{props.profile.street === '' || typeof props.profile.street === 'undefined'
								? '- -'
								: covert_to_pascal_with_spaces(props.profile.street)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">City</div>
						<div className="profile-basic-detail-value">
							{props.profile.city === '' || typeof props.profile.city === 'undefined'
								? '- -'
								: covert_to_pascal_with_spaces(props.profile.city)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">State</div>
						<div className="profile-basic-detail-value">
							{props.profile.state === '' || typeof props.profile.state === 'undefined'
								? '- -'
								: covert_to_pascal_with_spaces(props.profile.state)}
						</div>
					</div>
					<div className="profile-basic-detail-values">
						<div className="profile-basic-detail-index">Zipcode</div>
						<div className="profile-basic-detail-value">
							{props.profile.zipcode === '' || typeof props.profile.zipcode === 'undefined'
								? '- -'
								: covert_to_pascal_with_spaces(props.profile.zipcode)}
						</div>
					</div>
				</div>
				<div
					className="basic-details-we-img"
					style={{
						backgroundImage: `url(${
							props.profile_photo
						}), url("https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png")`
					}}
				/>
			</div>
		</div>
	);
};
