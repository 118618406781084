import React, { useState } from "react";
import "./dashboard_funcs.css";

const CommunityClasses = (props) => {
  const [desc_read_more, setDescReadMore] = useState(false);
  const [notes_read_more, setNotesReadMore] = useState(false);
  const { proposal, requester, session } = props.data;
  return (
    <>
      {/* card for invitation user */}
      <div className="group_class_section">
        <div className="group_class_card">
          <div className="card_heading">{proposal.date_range}</div>
          <div className="session_details border_bottom">
            <img
              className="user_img"
              src={
                !!proposal.avatar
                  ? proposal.avatar
                  : "https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png"
              }
            />

            <div className="user_details_section">
              <div className="user_info">
                <div className="user_name">{proposal.name}</div>
              </div>
              <div className="class_details">
                <img
                  className="session_icon_single"
                  src="https://images.myyogateacher.com/single_user.png"
                />
                <div className="classes_name">{session.type}</div>
              </div>
              <div className="class_details">
                <img
                  className="schedule_icon"
                  src="https://images.myyogateacher.com/schedule.png"
                />
                <div className="classes_name">{proposal.duration} mins</div>
              
              </div>

              <div className="class_details">
               
                 <div className="classes_name"> Target audience: {proposal.level}</div>
              
              </div>

             
            </div>
          </div>
          <div className="class_section_notes border_bottom">
            <div className="teacher_note">Class description</div>
            <div className="teacher_descrition">
              {desc_read_more
                ? proposal.description
                : proposal.description.length > 120
                ? proposal.description.substring(0, 120)
                : proposal.description}
              {proposal.description.length > 120 && (
                <span
                  onClick={() => {
                    setDescReadMore(!desc_read_more);
                  }}
                  className="read_more_btn"
                >
                  {desc_read_more ? " Read less" : " Read More"}
                </span>
              )}
            </div>
          </div>
          {!!proposal.notes && (
            <div className="class_section_notes border_bottom">
              <div className="teacher_note">Student notes</div>
              <div className="teacher_descrition">
                {notes_read_more
                  ? proposal.notes
                  : proposal.notes.length > 120
                  ? proposal.notes.substring(0, 120)
                  : proposal.notes}
                <span
                  onClick={() => {
                    setNotesReadMore(!notes_read_more);
                  }}
                  className="read_more_btn"
                >
                  {notes_read_more ? " Read less" : " Read More"}
                </span>
              </div>
            </div>
          )}
          <div className="session_book_section">
            <div className="user_profile">
              <div className="user_profile_img">
                <img
                  className="profile_frame"
                  src="https://images.myyogateacher.com/profile_design.png"
                />
                <div className="profile_img_section">
                  <img className="profile_img" src={requester.avatar_round} />
                </div>
              </div>
              <div
                onClick={() => {
                  props.onRequestorProfileClick(props.data);
                }}
                className="user_section"
              >
                <div className="user_section_details">
                  <div className="user_request">Requested by</div>
                  <div className="user_name">
                    {requester.name}{" "}
                    {requester.role === "student" ? "(Student)" : "(Teacher)"}
                    <img
                      className="user_section_expand"
                      src="https://images.myyogateacher.com/Path.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="session_btn_group">
              <button
                onClick={() => {
                  props.onDecline(props.data, props.index);
                }}
                className="decline_btn"
              >
                Decline
              </button>
              <button
                onClick={() => {
                  props.onAccept(props.data, props.index);
                }}
                className="accept_btn"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------ */}
    </>
  );
};

export default CommunityClasses;
