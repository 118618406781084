import * as t from "../action_types";

export const set_loading_variable = (key, payload) => ({
  type: t.set_loading_variable,
  payload: {
    key,
    payload,
  },
});

// export const handle_error_from_api = (payload) => {
//   return {
//     type: t.handle_error_from_api,
//     payload,
//   };
// };
