import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/action_creators';
import { withRouter } from '../../../utils/router';

class ClassRequests extends React.Component {
	componentDidUpdate(prevProps) {
		if (
			prevProps.community_proposal_accept_status === 'loading' &&
			this.props.community_proposal_accept_status === 'success'
		) {
			alert('Success');
		}
	}

	acceptCommunityClassRequest = (uuid) => {
		this.props.community_proposal_accept({ uuid });
	};

	ignoreCommunityClassRequest = (uuid) => {
		this.props.community_proposal_ignore({ uuid });
	};

	render() {
		return null;
	}
}

const mapStateToProps = (state) => {
	return {
		community_proposal_accept_response: state.home.community_proposal_accept_response,
		community_proposal_ignore_status: state.home.community_proposal_ignore_status,
		community_proposal_ignore_response: state.home.community_proposal_ignore_response,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		community_proposal_accept: (payload) => {
			dispatch(actions.community_proposal_accept(payload));
		},
		community_proposal_ignore: (payload) => {
			dispatch(actions.community_proposal_ignore(payload));
		},
	};
};

const ClassRequestsWithRedux = connect(mapStateToProps, mapDispatchToProps)(ClassRequests);
const ClassRequestsWithRouter = withRouter(ClassRequestsWithRedux);

export default ClassRequestsWithRouter;
