import React, { useState } from 'react';
import { buildMenuOptions } from '../menu';
import { NotificationTick } from '../livekit_assets/svg';

const ReactionModal = ({ data, onSelect }) => {
  const [messageSentText, setMessageSentText] = useState('');
  const [isMSGSent, setisMSGSent] = useState(false);
  const localParticipantInfo = data.localParticipant;
  const participant = data.getParticipant;
  const metaData = participant.map((meta) => {
    return JSON.parse(meta.metadata);
  });
  const menuOptions = buildMenuOptions(localParticipantInfo, participant, metaData);

  const onMSGSent = (msg) => {
    onSelect(msg);
    setMessageSentText(msg?.label);
    setisMSGSent(true);

    setTimeout(() => {
        setisMSGSent(false);
      }, 1500);
  };
  
  return (
    <div className="reaction-container">
      <div className="reaction-emoji-list">
        {menuOptions[1].emojis.map((item) => {
          return (
            <div className="emoji-style" onClick={() => onSelect(item)}>
              {item.label}
            </div>
          );
        })}
      </div>
      <div className="reaction-seperator"></div>
      <div className="reaction-sub-heading">{menuOptions[1].label}</div>
      <div className="reaction-list-container">
        {menuOptions[1].items.map((msg) => {
          return (
            <div className="flex items-center justify-between">
              <div className="reaction-message-container" onClick={() => onMSGSent(msg)}>
                {msg.label}
              </div>
              <div>
                {messageSentText === msg.label && isMSGSent && (
                  <div className='text-white'>
                    <NotificationTick color="#fff" />
                  </div>
                )} 
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReactionModal;
