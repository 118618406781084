import * as t from "../action_types";

export const set_welcome_variable = (key, payload) => ({
  type: t.set_welcome_variable,
  payload: {
    key,
    payload
  }
});

export const verify_email_token = (payload) => ({
  type: t.verify_email_token,
  payload
})
