import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.css';
import Slider from "react-slick";
import { CrossIcon } from '../../assets/svg/popup_icons';

interface IState {
    progress: number;
    isNexthover: boolean;
    isPrevioushover: boolean;
    selectedTheme: any;
    selectedIndex: number;
    selectedPhotos: any;
    errorMsg: string;
}

interface IMapStateToProps {
    selectedCarouselImage: number;
    delete_file_status: string;
    delete_file_response: any;
    selectedPhotos: any;
    student_name: string;
}

interface IMapDispatchToProps {
    closeThemePopup: () => void;
    getSelectedImage: (payload?: any) => void;
}

type ThemeCarouselAllProps = IMapStateToProps & IMapDispatchToProps;
export class ThemeCarousel extends React.Component<ThemeCarouselAllProps, IState> {

    myRef: any;
    slider: any;

    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            isNexthover: false,
            isPrevioushover: false,
            selectedTheme: [],
            selectedIndex: 0,
            selectedPhotos: [],
            errorMsg: ''
        }
        this.myRef = React.createRef();
    }

    componentDidMount(): void {
        let selectedPhotos = this.props.selectedPhotos.map((_: any, i: number) => {
            _.selectedCount = 0;
            _.selectedTheme = _.items[0];
            _.isChecked = i === 0;
            return _;
        })
        this.setState({ progress: ((0) / (this.slider.props.children.length - 1)) * 100, selectedPhotos, selectedTheme: [selectedPhotos[0]] });
    }

    downloadImage = async () => {
        const link = document.createElement('a');
        link.download = this.state.selectedPhotos[this.state.selectedIndex].selectedTheme.filename;
        link.href = this.state.selectedPhotos[this.state.selectedIndex].selectedTheme.signed_url;
        link.target = '_blank';
        link.click();
    }

    next = (indexValue: number) => {
        let selectedPhotos = this.state.selectedPhotos;
        if (selectedPhotos[indexValue].items.length > selectedPhotos[indexValue].selectedCount + 1) {
            selectedPhotos[indexValue].selectedCount += 1;
            selectedPhotos[indexValue].selectedTheme = selectedPhotos[indexValue].items[selectedPhotos[indexValue].selectedCount];
            this.setState({ selectedPhotos })
        }
    }

    switchTheme = (indexValue: number) => {
        let selectedPhotos = this.state.selectedPhotos;
        if (selectedPhotos[indexValue].items.length > selectedPhotos[indexValue].selectedCount + 1) {
            selectedPhotos[indexValue].selectedCount += 1;
            selectedPhotos[indexValue].selectedTheme = selectedPhotos[indexValue].items[selectedPhotos[indexValue].selectedCount];
            this.setState({ selectedPhotos })
        } else {
            selectedPhotos[indexValue].selectedCount = 0;
            selectedPhotos[indexValue].selectedTheme = selectedPhotos[indexValue].items[selectedPhotos[indexValue].selectedCount];
            this.setState({ selectedPhotos })
        }
    }

    previous = (indexValue: number) => {
        let selectedPhotos = this.state.selectedPhotos;
        if (selectedPhotos[indexValue].selectedCount > 0) {
            selectedPhotos[indexValue].selectedCount -= 1;
            selectedPhotos[indexValue].selectedTheme = selectedPhotos[indexValue].items[selectedPhotos[indexValue].selectedCount];
            this.setState({ selectedPhotos })
        }
    }

    beforeChangeSlider = (current, next) => {
        var calc = ((next) / (this.slider.props.children.length - 1)) * 100;
        this.setState({ progress: calc, selectedIndex: next });
    }

    mouseOver = (event, nextOrPrev: string) => {
        if (nextOrPrev === 'next') {
            this.setState({ isNexthover: true });
            event.target.style.fill = '#EE731B';
        }
        else if (nextOrPrev === 'prev') {
            this.setState({ isPrevioushover: true });
            event.target.style.fill = '#EE731B';
        }
    }

    mouseOut = (event, nextOrPrev: string) => {
        if (nextOrPrev === 'next') {
            this.setState({ isNexthover: false });
            event.target.style.fill = "#321A50";
        }
        else if (nextOrPrev === 'prev') {
            this.setState({ isPrevioushover: false });
            event.target.style.fill = "#321A50";
        }
    }

    changeThemeSelection = (event, img: any, indexValue) => {
        let selectedTheme = this.state.selectedTheme;
        img.isChecked = event.target.checked;
        if (event.target.checked) {
            selectedTheme.splice(indexValue, 0, img);
            this.setState({ selectedTheme });
        } else {
            selectedTheme.splice(indexValue, 1);
            this.setState({ selectedTheme });
        }
        this.state.selectedTheme.length ? this.setState({ errorMsg: '' }) : '';
    }

    getSelectedImage = () => {
        let newSelectedPhotos = [];
        this.state.selectedTheme.map((_) => {
            newSelectedPhotos.push(_.selectedTheme)
        })
        newSelectedPhotos.length ? this.props.getSelectedImage(newSelectedPhotos) : this.setState({ errorMsg: 'Choose a picture to continue' });
    }

    render() {
        const settings = {
            dots: false,
            centerMode: true,
            infinite: false,
            slidesToShow: 1,
            variableWidth: true,
            beforeChange: (current, next) => this.beforeChangeSlider(current, next)
        };
        return (
            <div className='relative inset-0 z-50'>
                <div
                    className='fixed h-screen w-full zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'
                >
                    <div className='z-50 bg-white rounded-lg md:p-10 zm:p-30px max-w-570px zm:w-full relative'>
                        <div className='flex justify-end cursor-pointer absolute top-1 right-1' onClick={this.props?.closeThemePopup}>
                            <div className='mt-1 mr-1'>
                                {<CrossIcon />}
                            </div>
                        </div>
                        <div className='mb-0'>
                            <div className='font-semibold text-subText md:text-17 zm:text-17'>Customize & select pictures to share with {this.props.student_name}</div>
                            <div className='flex items-center my-10px'>
                                <p className='text-mytgrey-contactHeading text-13 font-medium'>{this.state.selectedTheme.length} Selected</p>
                            </div>
                            <div className='slickSliderMainContainer'>
                                <Slider ref={c => (this.slider = c)} {...settings}>
                                    {
                                        this.state.selectedPhotos.map((ele, i) => {
                                            return (
                                                <div key={ele.original_image_uuid} className="relative w-full flex justify-center" id={ele.original_image_uuid} ref={this.myRef}>
                                                    <button type="button" onClick={() => this.switchTheme(i)} className="absolute right-7px top-7px z-10 flex items-center text-left font-semibold text-secondary bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 rounded-lg text-sm px-14px py-1.5 dark:bg-gray-800 dark:text-white ">
                                                        <div>Switch Theme</div>
                                                    </button>

                                                    <label className="checkbox-container">
                                                        <input id={i} type="checkbox" checked={ele.isChecked} onChange={($event) => this.changeThemeSelection($event, ele, i)} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    {
                                                        ele.selectedTheme &&
                                                        <div className='background'>
                                                            <img className='rounded-10 h-full w-full' src={ele.selectedTheme.signed_url} alt={ele.selectedTheme.filename} />
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </Slider>
                            </div>
                            <div className="releative mt-2 w-full bg-gray-200 rounded-full h-1px dark:bg-gray-700">
                                <div className="bg-black h-1 rounded-full" style={{ width: `${this.state.progress}%` }}></div>
                            </div>
                            {
                                this.state.errorMsg &&
                                <p className='mt-3 text-mytRedText text-15.5 font-medium mb-5'>{this.state.errorMsg}</p>
                            }
                            <div className="flow-root mt-3">
                                <button onClick={this.getSelectedImage} className='h-46px btn btn-primary md:text-17 zm:text-17 md:py-2 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-9px'>
                                    Share with {this.props.student_name.split(" ")[0]}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default ThemeCarousel;