import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import LoadingScreen from '../../components/util_components/loading_screen';
import urls from '../../urls';
import { post_api } from '../utils/config';
import Modal from './CommonComponents/Modal'

const PainLevelPopup = (props) => {
    const [loading, setloading] = useState(false);
    const [data, setData] = useState([]);
    const getData = () => {
        const url = urls.client_pain_levels;
        const payload = {
            student_uuid: props.student_uuid
        }
        setloading(true);
        post_api(url, payload, true).then(res => {

            if (Object.prototype.hasOwnProperty.call(res.data, 'client_pain_levels')) {
                setData(res?.data?.client_pain_levels);
                setloading(false);
            }
        }).catch(err => {
            setloading(false);
            console.log(err);
        })
    }
    useEffect(() => {
        getData();
    }, [])

    return (
        <Modal onClose={props.onClose}>
            {loading ? <LoadingScreen /> : <div className=' px-10 pb-10 pt-1.5'>
                <div className='font-semibold text-19px leading-22px text-greyText pb-61px'>Pain level</div>
                <div className='rounded-5px border border-solid border-mytgrey-subBorder max-h-366px overflow-auto'>
                    <table className='progress-card'>
                        <tbody>
                            <tr>
                                <th colspan="2" className='text-mytgrey-lite font-normal text-14.5px leading-26px text-center pl-3'>Before the session</th>
                                <th className='text-mytgrey-lite font-normal text-14.5px leading-26px text-center'>After the session</th>
                            </tr>
                            {data.map((row, index) => {
                                return (
                                    <tr key={row.session_id}>
                                        <td className='font-semibold text-13.5px leading-4 text-center text-mytpurple'>{index}</td>
                                        <td className='font-semibold text-13.5px leading-4 text-center text-mytpurple'>{row.pre_pain_level}</td>
                                        <td className='font-semibold text-13.5px leading-4 text-center text-mytpurple'>{row.post_pain_level}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>}
        </Modal>
    )
}

export default PainLevelPopup;