import * as t from "../action_types";

export const set_zoom_variable = (key, payload) => ({
  type: t.set_zoom_variable,
  payload: {
    key,
    payload
  }
});

export const get_zoom_signature = payload => ({
  type: t.get_zoom_signature,
  payload
});

export const session_log_start = payload => ({
  type: t.session_log_start,
  payload
});

export const session_log_end = payload => ({
  type: t.session_log_end,
  payload
});

export const join_session = payload => ({
  type: t.join_session,
  payload
});
