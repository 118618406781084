import React, { Component } from 'react';
import {post_api} from '../../../redux/api_funcs';
import LoadingScreen from '../../util_components/loading_screen';
import urls from '../../../urls';

export default class EditPopup extends Component {
  state = {
    loading: false,
    show_btn_save: false
  }
  inputRef = React.createRef()
  componentDidMount() {
    this.inputRef.current.focus()
    this.setState({ selectedAnswer: this.props.selectedQuestion.answer })
  }
  handleChange = e => this.setState({ selectedAnswer: e.target.value, show_btn_save: true });

  save_next = () => {
    if (!!this.state.selectedAnswer) {
      this.setState({loading: true}, () => {
        const url = urls.update_session_recap;
        let payload = {
          session_uuid: this.props.sessionUuid,
          [this.props.ques_item.api_param]: this.state.selectedAnswer
        };
        post_api(url,payload,true)
        .then(() => {
          this.setState({ loading: false }, () => this.props.updateAnswerValue(this.state.selectedAnswer))
        })
      })
    }
  }
  render() {
    const { selectedAnswer, show_btn_save,loading } = this.state;
    let btn_class = `btn btn-pri ${show_btn_save ? 'show_it' : 'btn_dis'}`
    return (
      <div className="edit_rm_pop">
        {loading ? <LoadingScreen/> : null}
        <div className="edit_rm_pop_overlay" onClick={this.props.onClose} />
        <div className="edit_rm_pop_content">
          <button className="btn btn_rmpop_close" onClick={this.props.onClose}>
            <img alt="close" src="https://images.myyogateacher.com/icons/ic_white_cross.png" />
          </button>
          <div className="edit_rm_ques_block">
            <span className="ic_q">Q</span>
            <div className="rm_ques_ans_in">
              <h4>{this.props.ques_item.question}</h4>
              <textarea onChange={this.handleChange} value={selectedAnswer} ref={this.inputRef} />
              <button className={btn_class} onClick={this.save_next}>Save and Next</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
