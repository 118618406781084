import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Test from '../../components/util_components/custom_loading';
import Header from '../../components/util_components/header';
import { post_api } from '../../redux/api_funcs';
import AboutMe from './AboutMe';
import BackPainTherapy from './BackPainTherapy';
import ProfileHeader from './ProfileHeader';
import { SessionConsumedBanner } from './StudentProfileDetails';
import TherapyJourney from './TherapyJourney';
import LoggedInFooter from '../../components/new_teacher_wesite/components/LoggedInFooter';
import urls from '../../urls';
import { sendEventToClarity } from '../../util_functions';

function StudentProfileBP(props) {
  const [profileInfo, setProfileInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const id = urlParams.get('id');

  const navigate = useNavigate();

  const fetchStudentProfile = (cb) => {
    const url = urls.backpain_client_profile;
    const payload = {
      student_uuid: id
    };

    const token = id;
    const type = 'json';
    const onUploadProgress = (progressEvent) => {
      console.log(progressEvent);
    };
    setLoading(true);
    post_api(url, payload, token, type, onUploadProgress)
      .then((response) => {
        setProfileInfo(response.data.session_details);
        setLoading(false);
        if (cb) {
          cb();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('error', error);
      });
  };
  useEffect(() => {
    fetchStudentProfile();
    sendEventToClarity();
  }, []);

  const navigateToRoute = (type) => {
    const time = props?.router?.location?.state?.student_time || '10:30 AM EDT';
    switch (type) {
      case 'therapy_1_on_1': {
        navigate(`/book-coaching?id=${id}&&from=profile`, {
          state: {
            timeZone: time,
            profileInfo,
            profileUrl: `/backpain-client-profile?id=${id}`
          }
        });
        return;
      }
      case 'therapy_group':
      case 'pain_level':
      case 'follow_up': {
        navigate(`/book-consult`, {
          state: { student_uuid: id, student_name: profileInfo.name }
        });
      }
    }
  };
  if (loading) {
    return <Test />;
  }
  return (
    <>
      <Header />
      <SessionConsumedBanner data={profileInfo} studentUUID={id} />
      <ProfileHeader
        data={profileInfo}
        studentUUID={id}
        onClick={({ type }) => navigateToRoute(type)}
      />
      <div className="flex items-center justify-center mb-70px zm:px-25px">
        <div className="zm:w-full md:w-fit">
          <AboutMe data={profileInfo} studentUUID={id} />
          <BackPainTherapy
            data={profileInfo}
            studentUUID={id}
            onEditTherapyPlan={(cb) => fetchStudentProfile(cb)}
          />
          <TherapyJourney data={profileInfo} studentUUID={id} />
        </div>
      </div>
      <LoggedInFooter />
    </>
  );
}

export default StudentProfileBP;
