import axios from 'axios';
import urls from '../../urls';
import { getCookie } from '../../util_functions';

export const reset_password_api = payload =>
	axios({
		method: 'post',
		url: urls.update_name_password,
		headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
		data: payload
	}).then(response => {
		return response;
	});

export const login_api = payload =>
	axios({
		method: 'post',
		url: urls.login_app,
		// headers: {'Content-Type' : 'application/x-www-form-urlencoded'},
		data: {
			...payload
		}
	})
		.then(response => {
			return Promise.resolve(response);
		})
		.catch(error => {
			return Promise.reject(error);
		});
