import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import Spinner from '../../util_components/spinner';
import CustomSingleSelect from '../../util_components/custom_single_select';
import './popup.css';
import { connect } from 'react-redux';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';

class SelDateTime extends Component {
	state = {
		start_date: null,
		session_durations: [
			{ value: 30, label: '30 min' },
			{ value: 60, label: '60 min' },
			{ value: 90, label: '90 min' },
			{ value: 120, label: '120 min' },
		],
		duration: 60,
		confirm_sch_session: false,
		error_message: 'You have already booked a session ',
		error_message_show: false,
		no_err_sel: false,
		btn_loading_sch: false,
	};

	componentDidUpdate(prevProps) {
		if (
			prevProps.schedule_session_with_student_status === 'loading' &&
			this.props.schedule_session_with_student_status === 'success'
		) {
			this.setState({
				btn_loading_sch: false,
			});
			this.props.sch_confirm();
		}
		if (
			prevProps.schedule_session_with_student_status === 'loading' &&
			this.props.schedule_session_with_student_status === 'failure'
		) {
			this.show_err_message();
			this.setState({
				btn_loading_sch: false,
			});
		}
	}

	show_err_message = () => {
		this.setState({
			error_message_show: true,
			error_message: this.props.schedule_session_with_student_err_msg,
		});
	};

	render_date_picker_one = () => {
		// const st_date = this.state.start_date;

		const CustomInputOne = ({ onClick }) => {
			return (
				<>
					<div className={`tinput-date-in ${this.state.no_err_sel ? 'err-input' : ''}`} onClick={onClick}>
						<img alt='calander' src='https://images.myyogateacher.com/teacher_web_app/ic_calendar-s.svg' />

						{this.state.start_date === null ? (
							<span>----</span>
						) : (
							<span className='tinput-date'>
								{moment(this.state.start_date).format('MMM D, YYYY') +
									' at ' +
									moment(this.state.start_date).tz(moment.tz.guess()).format('h:mm A z')}
							</span>
						)}

						<span className='tdate-arrow' />
					</div>
					{this.state.no_err_sel ? <p className='err-text-sel-in'>Please select date</p> : null}
				</>
			);
		};
		// const startDate = this.state.vacation_start_date !== null ? new Date(this.state.vacation_start_date) : new Date();
		// const startDateOne = new Date();
		return (
			<DatePicker
				selected={this.state.start_date}
				onChange={(date) => this.set_start_date(date)}
				customInput={<CustomInputOne />}
				minDate={new Date()}
				maxDate={this.state.max_date}
				showTimeSelect
				dateFormat='MMMM d, yyyy h:mm aa'
			/>
		);
	};

	bookSession = () => {
		if (this.state.start_date !== null) {
			this.setState({
				btn_loading_sch: true,
			});
			const payload = {
				student_uuid: this.props.student_uuid,
				start_time: this.state.start_date.getTime(),
				duration: this.state.duration,
			};

			this.props.schedule_session_with_student(payload);
		} else {
			this.setState({
				no_err_sel: true,
			});
		}
	};

	selectDuration = (e) => {
		this.setState({
			duration: e.target.value,
		});
	};
	set_start_date = (date) =>
		this.setState({
			start_date: date,
			session_create_err: false,
			error_message_show: false,
			no_err_sel: false,
		});
	render() {
		return (
			<>
				<div className='t-pop-cont'>
					<div className='t-pop-overlay' onClick={this.props.close_sch_sess_w_stu} />
					<div className='t-pop-main'>
						<img
							onClick={this.props.close_sch_sess_w_stu}
							className='t-pop-cross'
							alt='close'
							src='https://images.myyogateacher.com/icons/ic_white_cross.png'
						/>
						<div className='tpop-body'>
							<h4>Pick a date</h4>

							<div className='student-sel-sess-time'>
								<div className='stu-sel-sess-date'>
									<div className='tsession-date-picker'>
										{/* <img alt="icon" src="https://images.myyogateacher.com/teacher_web_app/ic_calendar-s.svg" /> */}
										{this.render_date_picker_one()}
									</div>
									<label>Session date</label>
								</div>
								<div className='stu-sel-sess-duration'>
									<CustomSingleSelect
										label='Duration'
										options={this.state.session_durations}
										onChange={this.selectDuration}
										name='session_duration'
										value={this.state.duration}
										style={{ height: '40px' }}
										defaultOption={'Duration'}
									/>
								</div>
							</div>
							{this.state.start_date !== null && (
								<div className='teacher-local-time'>
									<h4>
										Student local time
										<b>
											{this.state.start_date !== null &&
												moment(this.state.start_date)
													.tz(this.props.student_timezone)
													.format('MMM D, YYYY') +
													' at ' +
													moment(this.state.start_date)
														.tz(this.props.student_timezone)
														.format('h:mm A z')}
										</b>
									</h4>
								</div>
							)}
							{this.state.error_message_show ? (
								<h4 className='err-text'>{this.props.schedule_session_with_student_err_msg}</h4>
							) : null}

							<div className='tpop-footer'>
								<button
									className={`btn btn-pri ${this.state.btn_loading_sch ? 'btn-pri-loading' : ''}`}
									onClick={this.bookSession}
								>
									Confirm
									{this.state.btn_loading_sch ? (
										<Spinner
											circleRadius={16}
											borderLength={1}
											smallBorderColor='#4332DF'
											largeBorderColor='#fff'
										/>
									) : null}
								</button>
								<span className='btn-sep-pop' />
								<button className='btn btn-pop-cancel' onClick={this.props.close_sch_sess_w_stu}>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		schedule_session_with_student_status: state.dashboard.schedule_session_with_student_status,
		schedule_session_with_student_response: state.dashboard.schedule_session_with_student_response,
		schedule_session_with_student_err_msg: state.dashboard.schedule_session_with_student_err_msg,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		schedule_session_with_student: (payload) => {
			dispatch(actions.schedule_session_with_student(payload));
		},
	};
};

const SelDateTimeWithRedux = connect(mapStateToProps, mapDispatchToProps)(SelDateTime);

export default SelDateTimeWithRedux;
