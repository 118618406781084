import React, { Component } from 'react';

class AudioAnalyser extends Component {
	constructor(props) {
		super(props);
		this.state = { audioData: new Uint8Array(0), x: 0, success_count: 0 };
		this.tick = this.tick.bind(this);
	}
	componentDidMount() {
		this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
		this.analyser = this.audioContext.createAnalyser();
		this.analyser.fftSize = 4096;
		this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
		this.source = this.audioContext.createMediaStreamSource(this.props.audio);
		this.source.connect(this.analyser);
		this.rafId = requestAnimationFrame(this.tick);
	}
	componentWillUnmount() {
		cancelAnimationFrame(this.rafId);
		this.analyser.disconnect();
		this.source.disconnect();
		if (this.state.success_count >= 5) {
			this.props.onSuccess();
		} else {
			this.props.onFail();
		}
	}
	tick() {
		this.analyser.getByteTimeDomainData(this.dataArray);
		const db = this.calculate_decibels(this.dataArray);
		const flag = db > 45 ? 1 : 0;
		this.setState({
			audioData: this.dataArray,
			x: this.state.x + 1,
			db,
			success_count: this.state.success_count + flag
		});
		this.rafId = requestAnimationFrame(this.tick);
	}
	calculate_decibels = audioData => {
		try {
			const buf = audioData;
			const bufLength = buf.length;
			let total = 0;
			let i = 0;
			let float;
			while (i < bufLength) {
				float = buf[i++] / 0x80 - 1;
				total += float * float;
			}
			const rms = Math.sqrt(total / bufLength);
			const db = 20 * Math.log10(rms / 0.001);
			if (db > 45) {
				this.setState({ success_count: this.state.success_count + 1 });
			}
			return db;
		} catch {
			return 0;
		}
	};
	render() {
		return <AudioVisualiser db={this.state.db} x={this.state.x} />;
	}
}

class AudioVisualiser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			x: 0
		};
		this.canvas = React.createRef();
	}
	componentDidMount() {
		this.draw_basic_line();
	}
	componentDidUpdate() {
		this.draw();
	}
	draw_basic_line() {
		const canvas = this.canvas.current;
		const context = canvas.getContext('2d');
		context.beginPath();
		// Staring point (10,45)
		context.moveTo(10, 100);
		// End point (180,47)
		context.lineTo(2000, 100);
		// Make the line visible
		context.stroke();
	}
	draw() {
		let { x, db } = this.props;
		// db = db < 15 ? 0 : db;
		const canvas = this.canvas.current;
		const context = canvas.getContext('2d');
		context.beginPath();
		context.moveTo(10 + x * 1.5, 100 - db * 2);
		context.lineTo(10 + x * 1.5, 100 + db * 2);
		context.stroke();
	}
	render() {
		return <canvas width="1000" height="300" ref={this.canvas} />;
	}
}

export default class Deibel extends Component {
	state = {
		audio: null
	};
	componentDidMount() {
		this.getMicrophone();
	}
	async getMicrophone() {
		const audio = await navigator.mediaDevices.getUserMedia({
			audio: true,
			video: false
		});
		this.setState({ audio });
		setTimeout(() => this.stopMicrophone(), 10000);
	}
	stopMicrophone = () => {
		this.state.audio.getTracks().forEach(track => track.stop());
		this.setState({ audio: null });
	};
	toggleMicrophone = () => {
		if (this.state.audio !== null) {
			this.stopMicrophone();
		} else {
			this.getMicrophone();
		}
	};
	render() {
		return (
			<main>
				{/* <div className="controls">
					<button onClick={this.toggleMicrophone}>
						{this.state.audio ? 'Stop microphone' : 'Get microphone input'}
					</button>
				</div> */}
				{this.state.audio ? (
					<AudioAnalyser
						audio={this.state.audio}
						onSuccess={this.props.on_audio_success}
						onFail={this.props.on_audio_fail}
					/>
				) : (
					''
				)}
			</main>
		);
	}
}
