import React from 'react'

function Button(props) {
    const { btntext, classNames, btnclassNames, color = "btn-orange", onClick, disable,id,isTooltip} = props;
    return (
        <div id={id || ''} className={`${btnclassNames ? btnclassNames : ''} ${disable && !isTooltip ? 'pointer-events-none opacity-50' :disable && isTooltip? 'opacity-50': 'cursor-pointer'}`}>
            <button onClick={disable?null:onClick} className={`${classNames ? classNames : ''} ${color} text-17px leading-22px font-semibold pt-3.5 px-22px pb-13px rounded-lg whitespace-nowrap`} style={{ opacity: props.disabled ? '0.3' : '1' }}>
                {btntext}
            </button>
        </div>
    )
}

export default Button