import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './toggle.css';

export class ToggleCheckbox extends PureComponent {
	render() {
		return (
			<label className='cust-tog-swi'>
				<input
					type='checkbox'
					id='customTogSwitch'
					checked={this.props.checked}
					onChange={this.props.click_fn}
				/>
				<div className='cust-tog-slider'></div>
			</label>
		);
	}
}
ToggleCheckbox.propTypes = {
	checked: PropTypes.bool,
	click_fn: PropTypes.func,
};

ToggleCheckbox.defaultProps = {
	checked: false,
	click_fn: () => console.log('Toggle switch clicked'),
};

// export default ToggleCheckbox;
