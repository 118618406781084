import React, { useEffect, useState, forwardRef } from 'react';
import './index.css';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { edit_avalability_slots_data } from '../../../redux/api_functions/availability_api';
import Loader from '../../new_teacher_wesite/components/loader';
import { removeCookie, analytics_reset, getCookie, convertDateWithTimezone } from '../../../util_functions';
import * as actions from '../../../redux/action_creators';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EditAvailability = (props) => {
    const navigate = useNavigate();
    const teacherTimezone = getCookie("time_zone")

    useEffect(() => {
        props.get_teacher_details();
    }, []);

    const editType = props.editPayloadData.availability_type_dt
    const startTimeValue = editType === "original" ? props?.editPayloadData.start_date_availability_chron : props?.editPayloadData.start_date_date_time

    let startDataDataEdit = moment(new Date(convertDateWithTimezone(startTimeValue, teacherTimezone))).format('ddd, DD-MMM-YY');
    let endDataDataEdit = moment(new Date(convertDateWithTimezone(props?.editPayloadData.end_date_availability_chron, teacherTimezone))).format('ddd, DD-MMM-YY');

    const [availabilityDateTime, setAvailabilityDateTime] = useState(props.editPayloadData);
    const [getTrialSession, setTrialSession] = useState(
        props.editPayloadData.trial_only_availability === 1 ? true : false
    );
    const [getRepeatSession, setRepeatSession] = useState(props.editPayloadData?.repeat_schedule === 1 ? true : false);
    const [startDate, setStartDate] = useState(new Date(convertDateWithTimezone(props?.editPayloadData.start_date, teacherTimezone)));
    const [endDate, setEndDate] = useState(new Date(convertDateWithTimezone(props?.editPayloadData.end_date, teacherTimezone)));
    const [selectedStartCalenderDate, setSelectedStartCalenderDate] = useState(
        new Date(props?.editPayloadData.start_date_availability_chron)
    );
    const [selectedEndCalenderDate, setSelectedEndCalenderDate] = useState(new Date(props?.editPayloadData.end_date_availability_chron));
    const [startAvailabilityDate, setStartAvailabilityDate] = useState(startDataDataEdit);
    const [endAvailabilityDate, setEndAvailabilityDate] = useState(endDataDataEdit);
    const [getErrorMsg, setErrorMsg] = useState('');
    const [isContinue, setIsContinue] = useState(false)

    const [calenderValue, setCalenderValue] = useState({
        Sunday: { isSelected: false },
        Monday: { isSelected: false },
        Tuesday: { isSelected: false },
        Wednesday: { isSelected: false },
        Thursday: { isSelected: false },
        Friday: { isSelected: false },
        Saturday: { isSelected: false },
    });

    const [isLoading, setIsloading] = useState(false);
    const [getIsValtidate, setIsValtidate] = useState(false);

    const checkingSelectedDay = (day) => {
        const newData = { [day]: { isSelected: !calenderValue[day].isSelected } };
        const destructuringValue = { ...calenderValue, ...newData };
        // console.log(destructuringValue)
        setCalenderValue(destructuringValue);
    };

    const addAvailabilityForTrialUser = (event) => {
        setTrialSession(event.target.checked);
    };

    const calculateMinTime = (date) => {
        let isToday = moment(date).isSame(moment(), 'day');
        if (isToday) {
            let nowAddOneHour = moment(new Date()).add({ hours: 0 }).toDate();
            return nowAddOneHour;
        }
        return moment().startOf('day').toDate();
    };

    const [minTimeSelect, setMinTimeSelect] = useState(calculateMinTime(new Date(availabilityDateTime.start_date)));

    // eslint-disable-next-line react/display-name
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className='example-custom-input' onClick={onClick} ref={ref}>
            <img src='https://images.myyogateacher.com/web_images/calender_icon_teacher.svg' alt='calender-icon' />
        </button>
    ));

    // eslint-disable-next-line react/display-name
    const ExampleCustomInputEndDate = forwardRef(({ value, onClick }, ref) => (
        <div
            className='text-base text-mytTeacherTextGray customTimePickerTeacherAvailability'
            onClick={onClick}
            ref={ref}
        >
            <div className='flex justify-between items-center w-full cursor-pointer'>
                <div className='text-base text-mytTeacherTextGray'>{endAvailabilityDate}</div>
                <button className='example-custom-input' onClick={onClick} ref={ref}>
                    <img
                        src='https://images.myyogateacher.com/web_images/calender_icon_teacher.svg'
                        alt='calender-icon'
                    />
                </button>
            </div>
        </div>
    ));

    function selectedStartCalenderDateEvent(date) {
        setStartAvailabilityDate(moment(new Date(date)).format('ddd, DD-MMM-YY'));
    }
    function selectedEndCalenderDateEvent(date) {
        setEndAvailabilityDate(moment(new Date(date)).format('ddd, DD-MMM-YY'));
    }

    const repeatSessionTime = () => {
        setRepeatSession((data) => !data);
    };

    useEffect(() => {
        checkedDaysValue();
    }, []);

    const checkedDaysValue = () => {
        const clonningData = { ...calenderValue };

        clonningData['Sunday']['isSelected'] = props.editPayloadData?.available_sunday === 1 ? true : false;
        clonningData['Monday']['isSelected'] = props.editPayloadData?.available_monday === 1 ? true : false;
        clonningData['Tuesday']['isSelected'] = props.editPayloadData?.available_tuesday === 1 ? true : false;
        clonningData['Wednesday']['isSelected'] = props.editPayloadData?.available_wednesday === 1 ? true : false;
        clonningData['Thursday']['isSelected'] = props.editPayloadData?.available_thursday === 1 ? true : false;
        clonningData['Friday']['isSelected'] = props.editPayloadData?.available_friday === 1 ? true : false;
        clonningData['Saturday']['isSelected'] = props.editPayloadData?.available_saturday === 1 ? true : false;
        setCalenderValue(clonningData);
    };

    const handleStartDate = (date) => {
        if (startDate && startDate !== date) {
            setIsContinue(false)
        }
        setStartDate(date);
    }

    const handleEndDate = (date) => {
        if (endDate && endDate !== date) {
            setIsContinue(false)
        }
        setEndDate(date)
    }

    const submitData = (actionType) => {
        let sunday = calenderValue['Sunday']['isSelected'] ? 1 : 0;
        let monday = calenderValue['Monday']['isSelected'] ? 1 : 0;
        let tuesday = calenderValue['Tuesday']['isSelected'] ? 1 : 0;
        let wednesday = calenderValue['Wednesday']['isSelected'] ? 1 : 0;
        let thusday = calenderValue['Thursday']['isSelected'] ? 1 : 0;
        let friday = calenderValue['Friday']['isSelected'] ? 1 : 0;
        let saturday = calenderValue['Saturday']['isSelected'] ? 1 : 0;

        let payload = {
            start_date: moment(startAvailabilityDate).format('YYYY-MM-DD'),
            end_date: moment(getRepeatSession === true ? endAvailabilityDate : startAvailabilityDate).format(
                'YYYY-MM-DD'
            ),
            start_time: moment(startDate).format('HH:mm:ss'),
            end_time: moment(endDate).format('HH:mm:ss'),
            repeat_schedule: getRepeatSession === false ? 0 : 1,
            repeat_forever: 0,
            available_sunday: getRepeatSession === false ? 0 : sunday,
            available_monday: getRepeatSession === false ? 0 : monday,
            available_tuesday: getRepeatSession === false ? 0 : tuesday,
            available_wednesday: getRepeatSession === false ? 0 : wednesday,
            available_thursday: getRepeatSession === false ? 0 : thusday,
            available_friday: getRepeatSession === false ? 0 : friday,
            available_saturday: getRepeatSession === false ? 0 : saturday,
            teacher_schedule_cron_uuid: props.editPayloadData?.teacher_schedule_cron_uuid,
            trial_only_availability: getTrialSession === true ? 1 : 0,
            currentDate: props.editPayloadData?.currentDate === true ? true : false,
            availability_type: props.editPayloadData?.availability_type,
            confirm_to_continue: isContinue
        };

        if (actionType === 'submit') {
            if (moment(startDate).format('HH:mm:ss') !== moment(endDate).format('HH:mm:ss')) {
                setIsValtidate(false);
                setIsloading(true);
                edit_avalability_slots_data(payload)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            props.updateDataEditSlots(true);
                            setIsloading(false);
                            window.location.reload();
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 401) {
                            removeCookie('teacher_token_1');
                            localStorage.removeItem('teacherLocalChannels');
                            analytics_reset();
                            navigate('/login');
                            props.addDataEditSlots(true);
                            setIsloading(false);
                        } else if (e.response.status === 400) {
                            // console.log(JSON.stringify(e.response.data.message));
                            // props.addDataEditSlots(true);
                            setIsloading(false);
                            setErrorMsg(JSON.stringify(e.response.data.message));
                            if (e.response.data.message === `There are sessions scheduled which will be out of your availability after this change. Please check and confirm to continue.`) {
                                setIsContinue(true)
                            }
                        }
                    });
            } else {
                setIsValtidate(true);
            }
        } else if (actionType === 'deletePopup') {
            props.deleteAvailability(payload);
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className='relative inset-0 z-index-max'>
                    <div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
                        {/* ref={handleClickOutside} */}
                        <div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
                            <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
                                <div onClick={props.closeEditAvailablityPopup}>
                                    <svg
                                        width='40'
                                        height='40'
                                        viewBox='0 0 34 34'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <rect width='34' height='34' rx='17' fill='none' />
                                        <path
                                            d='M22 12L12 22'
                                            stroke='#BFBFBF'
                                            strokeWidth='1.66667'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M12 12L22 22'
                                            stroke='#BFBFBF'
                                            strokeWidth='1.66667'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className='mb-0'>
                                <div className='flex justify-start flex-col items-start'>
                                    <div className='w-full'>
                                        <div className='flex justify-center items-center'>
                                            <div className='text-17 font-semibold'>Edit availability</div>
                                        </div>
                                        <div className='mt-5'>
                                            <div className='text-mytgrey-default text-sm font-normal'>Start Date</div>
                                            <div className='flex justify-between items-center mt-3 mb-3'>
                                                <div className='text-base text-mytTeacherTextGray'>
                                                    {startAvailabilityDate}
                                                </div>
                                                <div>
                                                    <DatePicker
                                                        selected={selectedStartCalenderDate}
                                                        onChange={(date) => selectedStartCalenderDateEvent(date)}
                                                        customInput={<ExampleCustomInput />}
                                                        minDate={new Date()}
                                                        showDisabledMonthNavigation
                                                        disabledKeyboardNavigation
                                                        disabled={
                                                            props.editPayloadData.availability_type_dt === 'original'
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <div className='text-mytgrey-default text-sm font-normal'>Time</div>
                                            <div className='flex justify-between items-center mt-3 pb-2 border-b'>
                                                <div className='text-base text-mytgrey-default'>Start time</div>
                                                <div className='text-base text-mytTeacherTextGray customTimePickerTeacherAvailability'>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleStartDate}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption='Time'
                                                        dateFormat='hh:mm aa'
                                                        minDate={new Date()}
                                                        minTime={minTimeSelect}
                                                        maxTime={moment().endOf('day').toDate()}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex justify-between items-center mt-2 mb-3'>
                                                <div className='text-base text-mytgrey-default'>End time</div>
                                                <div className='text-base text-mytTeacherTextGray customTimePickerTeacherAvailability'>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={handleEndDate}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption='Time'
                                                        dateFormat='hh:mm aa'
                                                        minTime={minTimeSelect}
                                                        maxTime={moment().endOf('day').toDate()}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {!availabilityDateTime.currentDate === true ? (
                                            <div className='mt-5 bg-mytTrailCard py-15px px-5 rounded-lg'>
                                                {/* <div className="text-mytgrey-default text-sm font-normal">Repeat</div> */}
                                                <div className='flex justify-start items-center'>
                                                    <div className='checkbox'>
                                                        <input
                                                            type='checkbox'
                                                            id='1'
                                                            name=''
                                                            value=''
                                                            onClick={repeatSessionTime}
                                                            defaultChecked={getRepeatSession}
                                                        />
                                                        <label htmlFor='1'></label>
                                                    </div>
                                                    <div className='text-base text-mytTeacherTextGray'>
                                                        Repeat availability
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {getRepeatSession === true ? (
                                            <>
                                                <div className='daysAbailabilitySlots border-b pb-5 mt-5'>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Sunday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Sunday')}
                                                    >
                                                        S
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Monday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Monday')}
                                                    >
                                                        M
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Tuesday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Tuesday')}
                                                    >
                                                        T
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Wednesday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Wednesday')}
                                                    >
                                                        W
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Thursday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Thursday')}
                                                    >
                                                        T
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Friday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Friday')}
                                                    >
                                                        F
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Saturday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Saturday')}
                                                    >
                                                        S
                                                    </div>
                                                </div>

                                                <div className='mt-5'>
                                                    <div className='mt-3 mb-3'>
                                                        <div className='text-mytgrey-default text-sm font-normal'>
                                                            End Date
                                                        </div>
                                                        <div className='flex justify-between items-center mt-3 mb-3 customDatepikerItem2'>
                                                            {/* <div className="text-base text-mytTeacherTextGray">{endAvailabilityDate}</div> */}
                                                            <DatePicker
                                                                selected={selectedEndCalenderDate}
                                                                onChange={(date) => selectedEndCalenderDateEvent(date)}
                                                                customInput={<ExampleCustomInputEndDate />}
                                                                minDate={new Date()}
                                                                showDisabledMonthNavigation
                                                                disabledKeyboardNavigation
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                        {props.teacher_details?.allow_trial_only_availability !== 0 ? (
                                            <>
                                                <div className='mt-5 bg-mytTrailCard py-15px px-5 rounded-lg'>
                                                    <label className='flex justify-start items-start'>
                                                        <div className='checkbox'>
                                                            <input
                                                                type='checkbox'
                                                                id='2'
                                                                name=''
                                                                value=''
                                                                checked={getTrialSession}
                                                                onClick={addAvailabilityForTrialUser}
                                                            />
                                                            <label htmlFor='2'></label>
                                                        </div>
                                                        <label
                                                            htmlFor='2'
                                                            className={`text-base cursor-pointer  ${getTrialSession === true
                                                                ? 'text-primary'
                                                                : 'text-mytTeacherTextGray'
                                                                }`}
                                                        >
                                                            <div className='text-base cursor-pointer text-mytTeacherTextGray'>
                                                                Availability only for trial students
                                                            </div>
                                                            <div className='text-mytgrey-default text-xs font-normal'>
                                                                Availability won't be visible to regular students
                                                            </div>
                                                        </label>
                                                    </label>
                                                </div>
                                            </>
                                        ) : null}
                                        {getIsValtidate && (
                                            <div className='loginSignupErrorMsg'>
                                                start time and end time can't be same
                                            </div>
                                        )}
                                        {getErrorMsg.length ? (
                                            <div className='loginSignupErrorMsg'>{JSON.parse(getErrorMsg)}</div>
                                        ) : (
                                            ''
                                        )}
                                        <div className='mt-8'>
                                            <button
                                                className='btn btn-primary mr-5'
                                                onClick={() => submitData('submit')}
                                            >
                                                Save
                                            </button>
                                            {props?.editPayloadData?.availability_type_dt === "original" ? null : <button
                                                className='btn btn-danger'
                                                onClick={() => submitData('deletePopup')}
                                            >
                                                Delete availability
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        teacher_details: state.profile.teacher_details,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_teacher_details: (key, payload) => {
            dispatch(actions.get_teacher_details(key, payload));
        },
    };
};

const EditAvailabilityWithRedux = connect(mapStateToProps, mapDispatchToProps)(EditAvailability);
export default EditAvailabilityWithRedux;
