import React, { useEffect, useState } from 'react';
import moment from "moment";

const EditSlots = (props) => {
  const [slotsDataList, setSlotsDataList] = useState(props.data);

  return (
    <>
      {Object.keys(slotsDataList).length !== 0 ?
        <div>
          <div className='relative inset-0 z-index-max'>
            <div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
              <div className='z-50 bg-white rounded-lg p-10 2xl:w-/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
                <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
                  <div onClick={props.closePopup}>
                    <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <rect width='34' height='34' rx='17' fill='none' />
                      <path
                        d='M22 12L12 22'
                        stroke='#BFBFBF'
                        strokeWidth='1.66667'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12 12L22 22'
                        stroke='#BFBFBF'
                        strokeWidth='1.66667'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                </div>
                <div className='mb-0'>
                  <div className='flex justify-start flex-col items-start'>
                    {/* {slotsDataList.map = ((date) => */}
                    <div className='w-full'>
                      <div className="mb-4">
                        <div className="text-17 font-semibold">{props.data.total_availability_count === 0 ? 'You do not have any availability to edit on this date' : 'Select the availability to Edit / Delete'}</div>
                        <div className="text-13 font-normal text-mytPopupSubHeading mt-2">Total available time - {slotsDataList.total_availability}</div>
                      </div>
                      {slotsDataList.available_slots.map((dates, i) => {
                        var chnagedStartTime = moment(dates.date_time.start_time, "HH:mm:ss").format("LT");
                        var chnagedEndTime = moment(dates.date_time.end_time, "HH:mm:ss").format("LT");
                        return (
                          <div className="editSlotsSection cursor-pointer " key={i + 1} onClick={() => props.slotsData(dates)}>
                            {/* {dates.date_time.map((list) => {
                              return ( */}
                            <div>
                              <div className="flex justify-between items-center pt-15px pb-15px" >
                                {/* onClick={props.slotsData} */}
                                <div>
                                  <div className="text-base font-normal mb-px">{chnagedStartTime} - {chnagedEndTime}</div>
                                  {dates.availability_crons.map((list, k) => {
                                    let uniqueKey = Math.random(k + 1);
                                    return (

                                      <>
                                      {list?.repeat_schedule === 1 ?  
                                        <div key={uniqueKey} className="flex jutify-start items-center text-mytPopupSubHeading text-13 font-normal">
                                          <div>Repeat every</div>
                                          <div className="flex jutify-start ml-1">
                                            <div className="mr-1">{list.available_monday == 1 ? 'Mon,' : ''}</div>
                                            <div className="mr-1">{list.available_tuesday == 1 ? 'Tue,' : ''}</div>
                                            <div className="mr-1">{list.available_wednesday == 1 ? 'Wed,' : ''}</div>
                                            <div className="mr-1">{list.available_thursday == 1 ? 'thu,' : ''}</div>
                                            <div className="mr-1">{list.available_friday == 1 ? 'Fri,' : ''}</div>
                                            <div className="mr-1">{list.available_saturday == 1 ? 'Sat,' : ''}</div>
                                            <div className="mr-1">{list.available_sunday == 1 ? 'Sun' : ''}</div>
                                          </div>
                                        </div>
                                        : null}
                                      </>
                                    )
                                  })}
                                </div>
                                <div>
                                  <img src="https://images.myyogateacher.com/web_images/chevron-down-right-teacher-site.svg" alt="right-arrow" />
                                </div>
                              </div>
                            </div>
                            {/* )
                            })} */}
                            {/* <div className="flex justify-between items-center pt-15px pb-15px">
                              <div>
                                <div className="text-base font-normal mb-px">5 - 7:30 PM</div>
                              </div>
                              <div>
                                <img src="https://images.myyogateacher.com/web_images/chevron-down-right-teacher-site.svg" />
                              </div>
                            </div> */}
                          </div>
                        )
                      })}
                      {/* <div className="flex justify-center items-center mt-3">
                        <button onClick={props.closePopup} className="btn btn-white">Cancel</button>
                      </div> */}
                    </div>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null

      }
    </>
  );
};



export default EditSlots;