import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { DateIcon, TimeIcon } from '../../../new_teacher_design/assets/svg/popup_icons';
import RadioButton from '../../../new_teacher_design/BackPainTherapy/CommonComponents/RadioButton';
import Duration from '../../../new_teacher_design/components/FixedComponent/Input/Duration';
import SwitchButton from '../../../new_teacher_design/components/FixedComponent/SwitchButton';
import { getCookie } from '../../../util_functions';
import './SessionStyle.css';
import * as actions from '../../../../app/new_teacher_design/Redux/action_creators';
import { connect } from 'react-redux';
import Loader from '../../new_teacher_wesite/components/loader';
import urls from '../../../new_teacher_design/urls/urls';
import { post_api } from '../../../new_teacher_design/utils/config';
import ConfirmPopup from './ConfirmPopup';

const RadioBtnOption = () => [{
    label: 'Doesn’t work for the student',
    id: 1,
    isSelected: false,
    value: 'Doesn’t work for the student'
}, {
    label: 'Doesn’t work for me',
    id: 2,
    isSelected: false,
    value: 'Doesn’t work for me'
}]


class ChangeSessionDateAndTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            session_date: new Date(this.props.session_details.epoch_start_time),
            isCheckedRepeat: this.props.session_details.is_recurring === 1 ? true : false,
            session_time: new Date(this.props.session_details.epoch_start_time),
            repeat_reschedule: this.props.session_details.is_recurring === 1 ? true : false,
            reschedule_requested_by: '',
            requested_by: RadioBtnOption(),
            changeInitiation: '',
            session_epoch_time: null,
            student_uuid: this.props.session_details.student_uuid,
            teacher_uuid: this.props.session_details.teacher_uuid,
            session_uuid: this.props.session_details.session_uuid,
            session_duration: this.props.session_details.duration,
            loading: true,
            subscriptionDataDt: {},
            is_booking_enable: 0,
            warning_message: '',
            btn_disable: true,
            show_api_error: false,
            error_log: ''

        }
    }

    componentDidMount = () => {
        let payload = {
            "student_uuid": this.state.student_uuid
        }
        this.props.get_subscription_data(payload)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.get_subscription_data_status === 'loading' && this.props.get_subscription_data_status === 'success') {
            this.setState({
                loading: false,
                subscriptionDataDt: this.props.get_subscription_data_response
            })
        }
       
    }

    trigger_change_time_warning = async() => {
        
        const {session_uuid, reschedule_requested_by, session_epoch_time, repeat_reschedule, session_duration} = this.state
        let payload = {
        "session_uuid": session_uuid,
        "reschedule_requested_by": reschedule_requested_by,
        "start_time": session_epoch_time,
        "repeat_reschedule": repeat_reschedule ? 1 : 0,
        "session_duration":session_duration
      }
        let url = urls.session_change_time_warning
        let response = await post_api(url, payload, true)
        this.setState({
          warning_message: response?.data?.session_warning?.message,
          is_booking_enable: response?.data?.session_warning?.change_allowed
          })
    }
      
    

    load_data = () => {
        const { session_duration, reschedule_requested_by, session_epoch_time } = this.state
        if ((session_duration !== null && reschedule_requested_by !== '' && session_epoch_time !== null)) {

            this.trigger_change_time_warning()
        }
    }

    on_date_change = (dt) => {
        this.setState({ session_date: dt, error_log: '', show_api_error: false}, () => {
            this.convert_to_epoch_time()
        })
    }

    on_time_change = (dt) => {
        this.setState({ session_time: dt, error_log: '', show_api_error: false }, () => {
            this.convert_to_epoch_time()
        })
    }

    convert_to_epoch_time = () => {
        const { session_date, session_time } = this.state;
        const date_moment = moment(session_date, 'ddd MMM DD YYYY HH:mm:ss ZZ');
        const time_moment = moment(session_time, 'ddd MMM DD YYYY HH:mm:ss ZZ');
        const combinedMoment = moment(date_moment).hour(time_moment.hour()).minute(time_moment.minute());

        const epochTime = Date.parse(combinedMoment);
        this.setState({ session_epoch_time: epochTime }, () => {
            this.load_data()
        });
    }

    on_duration_click = (dt) => {
        this.setState({ session_duration: dt, error_log: '', show_api_error: false }, () => {
            this.load_data()
        })
    }

    on_radio_click = (e) => {
        const valueC = e.target.id;
        const modifiedData = this.state.requested_by;
        let reschedule_requested_by = '';
        modifiedData.forEach((item) => {
            item.isSelected = item.id === +valueC;
            if (item.isSelected === true) {
                item.value === 'Doesn’t work for the student' ? reschedule_requested_by = 'STUDENT' : reschedule_requested_by = 'TEACHER'
                this.setState({ changeInitiation: item.value, reschedule_requested_by })


            }
            return item;
        });
        this.setState({ requested_by: modifiedData, error_log: '', show_api_error: false }, () => {
            this.load_data()
        })

    }

    on_click_repeat = () => {
        this.setState({ repeat_reschedule: !this.state.repeat_reschedule, isCheckedRepeat: !this.state.isCheckedRepeat, error_log: '', show_api_error: false }, () => {
            this.load_data()
        })
    }

    change_sesion_time = async() => {
        const {session_uuid, reschedule_requested_by, session_epoch_time, repeat_reschedule, session_duration} = this.state
        let url = urls.change_session_time;
        let payload = {
            "session_uuid": session_uuid,
            "reschedule_requested_by": reschedule_requested_by,
            "start_time": session_epoch_time,
            "repeat_reschedule": repeat_reschedule ? 1 : 0,
            "duration": session_duration
        }

        post_api(url, payload, true)
            .then ((res) => {
                if (res.data.status === "success") {
                    this.setState({show_successfull_popup: true})
                }
            })
            .catch((err) => {
                this.setState({error_log: err.response.data.message, show_api_error: true})
            })
    }

    closeConfirmPopup = () => {
        this.setState({show_successfull_popup: false}, () => {
            this.props.cancel_pick_date();
            window.location.reload();
        })
    }

    render() {
        const { session_date, repeat_reschedule, isCheckedRepeat, requested_by, session_time, loading, subscriptionDataDt, session_duration, warning_message, error_log, is_booking_enable, show_successfull_popup, show_api_error } = this.state
        const maxDate = session_date ? new Date(session_date.getTime() + 6 * 24 * 60 * 60 * 1000) : null;

        const setDateTime = moment(session_date).set({hour: moment(session_time).hour(),minute: moment(session_time).minute(),});
        const studentLocalTime = setDateTime?.tz(subscriptionDataDt?.student_timezone)?.format('ddd, MMM DD, h:mm A z');

        return (
            <>
                {loading ? <Loader /> : 
                <div className={` ${show_successfull_popup ? 'hidden': 'block'} fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-40 flex md:items-center zm:items-end md:justify-center zm:justify-end`}>
                    <div onClick={this.props.cancel_pick_date}></div>
                    <div className='z-50 bg-white zm:rounded-tl-3xl zm:rounded-tr-3xl zm-roundex-lb-none zm:rounded-rb-none md:rounded-lg md:p-10 zm:p-30px md:w-492px zm:w-full relative'>
                        <div className='font-semibold text-subText md:text-19 zm:text-17'>Edit class details</div>
                        <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
                            <div onClick={this.props.cancel_pick_date}>
                                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect width='34' height='34' rx='17' fill='none' />
                                    <path
                                        d='M22 12L12 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        d='M12 12L22 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='date-time flex flex-col mt-25px'>
                                <div className='flex item-center md:justify-between zm:justify-start zm:gap-15px md:gap-0px' >
                                    <div className='date flex flex-col customDatepikerItem'>
                                        <div className='font-medium text-mytLabel text-13.5 mb-8px'>Date</div>
                                        <div className='flex justify-between items-center customDatepikerItem1 zm:w-150px xs:w-195px relative'>
                                            {this.props.session_details.is_recurring === 1 ?
                                            <DatePicker
                                                selected={session_date}
                                                minDate={session_date}
                                                dateFormat="MMM d, yyyy"
                                                onChange={(dt) => this.on_date_change(dt)}
                                                disabledKeyboardNavigation
                                                showDisabledMonthNavigation
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                maxDate={maxDate}                                            
                                            />
                                             : 
                                            <DatePicker
                                                selected={session_date}
                                                minDate={new Date()}
                                                dateFormat="MMM d, yyyy"
                                                onChange={(dt) => this.on_date_change(dt)}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} 
                                            />}
                                            <div className='absolute zm:right-15px md:right-13px'><DateIcon /></div>
                                        </div>
                                    </div>
                                    <div className='time flex flex-col'>
                                        <div className='font-medium text-mytLabel text-13.5 mb-8px'>Start Time</div>
                                        <div className='flex justify-between items-center customDatepikerItem2 zm:w-150px xs:w-195px relative'>
                                            <DatePicker
                                                selected={session_time}
                                                minDate={new Date()}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={30}
                                                timeCaption='Time'
                                                dateFormat='hh:mm aa'
                                                onChange={(dt) => this.on_time_change(dt)}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }} 
                                            />
                                            <div className='absolute zm:right-45px md:right-86px font-medium text-mytLabel text-13.5'>IST</div>
                                            <div className='absolute zm:right-15px md:right-13px'><TimeIcon /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='student-local-time'>
                                    <p className='text-startTimeTextColor text-12px font-medium font-inter pt-2px'>Student's local time is {studentLocalTime}</p>
                                </div>
                            </div>
                            <div className='duration mt-25px'>
                                <Duration
                                    inputTypeLabel={'Duration'}
                                    selectedDuration={session_duration}
                                    onClickDurationValue={(dt) => this.on_duration_click(dt)}
                                />
                            </div>
                            <div className='is-repeat'>
                                {this.props.session_details.is_recurring === 1 ?  <div className=''>
                                    <div className='bg-mytGrayLightV3 rounded-lg p-15px mb-25px flex items-center justify-between gap-20px'>
                                        <div className='flex flex-col'>
                                            <p className='text-mytLabel text-15 font-medium'>Apply to all repeat sessions</p>
                                            {isCheckedRepeat === true &&
                                                <p className='text-startTimeTextColor font-medium text-12px pt-2'>
                                                    Class will repeat every {moment(session_date)?.format('dddd')} at {moment(session_time).tz(getCookie('time_zone'))?.format('h:mm A z')}
                                                </p>

                                            }
                                        </div>
                                        <div className='flex md:items-center zm:items-start'>
                                            {isCheckedRepeat === true ?
                                                <p className='text-mytLabel mr-15px text-15 font-medium md:flex zm:hidden'>Yes</p>
                                                :
                                                <p className='text-mytLabel text-15 font-medium md:flex zm:hidden mr-15px'>No</p>
                                            }
                                            <SwitchButton id={'1'} isChecked={repeat_reschedule} value={'yes'} onClick={this.on_click_repeat} />
                                        </div>
                                    </div>
                                </div> : <></>}
                            </div>
                            <div className='requested-by'>
                                <div className='bg-mytGrayLightV3 rounded-lg pt-15px pr-15px pl-15px mb-25px flex flex-col'>
                                    <div className='text-mytLabel text-15 font-medium mb-15px'>Making changes as class</div>
                                    <div className=''>
                                        {requested_by.map((i) => {
                                            return (
                                                <div className='radiobtnstyle cursor-pointer mb-15px'>
                                                    <RadioButton
                                                        id={i.id}
                                                        label={i.label}
                                                        name={i.label}
                                                        onClick={(e) => this.on_radio_click(e)}
                                                        isChecked={i.isSelected}
                                                        value={i.value}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </div>
                            <div className='message text-mytgrey-lite text-13.5px font-medium mb-15px'>{warning_message}</div>
                            {show_api_error ? <div className='message text-mytRedText2 text-13.5px font-medium mb-15px'>{error_log}</div> : null}
                            <div className='save-btn'>
                                <button className={`btn btn-primary w-full md:text-18.5 zm:text-17 md:py-5 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-6 zm:h-49px lg:h-60px ${is_booking_enable === 0 ? 'opacity-50 pointer-events-none' : '' }`} disabled={!is_booking_enable} onClick={this.change_sesion_time}  >Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>}
                {show_successfull_popup ? 
                <ConfirmPopup
                closeConfirmPopup = {this.closeConfirmPopup}
                is_from_change_session={true}
                  /> 
                : null}
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        get_subscription_data_status: state.dashboardv1.get_subscription_data_status,
        get_subscription_data_response: state.dashboardv1.get_subscription_data_response,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        get_subscription_data: (payload) => {
            dispatch(actions.get_subscription_data(payload))
        },
    }
}

const ChangeSessionDateAndTimeWithRedux = connect(mapStateToProps, mapDispatchToProps)(ChangeSessionDateAndTime)

export default ChangeSessionDateAndTimeWithRedux