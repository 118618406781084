import React, { useState } from 'react';
import { SlotsSelectedIcon } from '../../../svg';
import { getCookie } from '../../util_functions';

const SlotsForTherapy = ({
	state,
	customClass,
	isSelected,
	onClick,
	teachersList = [],
	displayTime = '',
	toggleSelfAvailaiblity = false,
}) => {
	const [isHovered, setIsHovered] = useState(false);

	let backgroundColor;

	if (state === 'prior_coaches' || state === 'self_availibility') {
		backgroundColor = 'bg-primary';
	} else if (state === 'any_coaches') {
		backgroundColor = 'bg-lightOrangeBGColor';
	} else if (state === 'no_coaches') {
		backgroundColor = 'bg-orangeLiteColor';
	}

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};
	let timeArr = displayTime.split(',');
	let teacherUUID = getCookie('teacher_uuid');
	let modifiedTeacherList =
		toggleSelfAvailaiblity && teacherUUID
			? [...teachersList].filter((teacher) => teacher.uuid === teacherUUID)
			: [...teachersList];
	return (
		<div
			className={`${customClass} ${
				isSelected ? 'bg-mytgreen' : backgroundColor
			} flex items-center justify-center relative border border-whimytBgReplacementte`}
			style={{ width: '52px', height: '41px' }}
			onMouseEnter={state === 'no_coaches' ? null : handleMouseEnter}
			onMouseLeave={state === 'no_coaches' ? null : handleMouseLeave}
			onClick={state === 'no_coaches' ? null : onClick}
		>
			{isSelected ? <SlotsSelectedIcon /> : null}
			{state === 'no_coaches'
				? null
				: isHovered && (
						<div className='absolute top-45px left-0 border border-mytBgReplacement shadow-borderShadow bg-white py-25px px-13px w-max rounded-7px z-10'>
							{timeArr.length === 2 ? (
								<div className='text-mytgrey-title text-left font-medium text-12.5px leading-normal font-Inter mb-10px'>
									<span>Your local time is {`${timeArr[0]},`}</span>
									<br />
									<span>{timeArr[1] || ''}</span>
								</div>
							) : null}
							<div className=' flex flex-col gap-12px mt-8px'>
								{modifiedTeacherList.map((teacher) => {
									let isPreviousTeacher =
										teacher?.is_previous_teacher || state === 'self_availibility' || false;

									return (
										<div
											className='text-15.5px leading-19px font-semibold flex gap-5px items-center'
											key={teacher.uuid}
										>
											<div
												className={`h-12px w-12px ${
													isPreviousTeacher ? 'bg-primary' : 'bg-lightOrangeBGColor'
												}`}
											/>
											<div>{teacher?.name || ''}</div>
										</div>
									);
								})}
							</div>
						</div>
				  )}
		</div>
	);
};

export default SlotsForTherapy;
