import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../util_components/spinner';

import Confirm from './Confirm';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import SelDateTime from './SelDateTime';
import './profile.css';
import './pop-style.css';
import { StudentProfileIcon } from '../../../../svg';

class StudentProfilePopUp extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	renderBlock = (text, index) => {
		return (
			<div className='vs-text-block' key={index + text}>
				{text.toUpperCase()}
			</div>
		);
	};

	UNSAFE_componentWillMount() {
		if (this.props.student_details !== '' && this.props.student_details.uuid === this.props.student_uuid) {
			this.setState({ loading: false });
		} else {
			const payload = {
				student_uuid: this.props.student_uuid,
			};
			this.props.load_student_details(payload);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.student_loading_status === 'loading' && this.props.student_loading_status === 'success') {
			this.setState({ loading: false });
		}
	}
	open_sch_sess_w_stu = () => {
		this.setState({
			open_sch_sess_with_student: true,
		});
	};

	render_student_details = () => {
		const slug = this.props.student_details.slug;
		let is_concierge = this.props.teacher_details.opt_in_concierge;
		let is_admin_imp = this.props.is_admin_imp;
		return (
			<>
				<div className='stu_pop_open'>
					<div className='stu-pop-overlay' onClick={this.props.closeFunc} />
					<div className='vs-container'>
						<div className='vs-header'>
							<div className='vs-bg' />
							<button className='vs-pop-close-btn' onClick={this.props.closeFunc}>
								{' '}
								<img
									className='close_min_chat'
									src='https://images.myyogateacher.com/teacher_web_app/ic_cross@3x.svg'
									alt='c'
								/>
							</button>
							<div className='vs-name-details'>
								<h4 className='vs-name'>
									{this.props.student_details.first_name}
									<span
										style={{
											fontWeight: 'normal',
											color: '#afafaf',
											fontSize: '14px',
											marginLeft: '5px',
										}}
									>
										(Unique ID:{' '}
										{is_concierge === 1 || is_admin_imp ? (
											<a
												href={`https://hridaya.myyogateacher.com/backpain-client-profile?id=${this.props.student_details.uuid}`}
												target='_blank'
												rel='noopener noreferrer'
											>
												{slug}
											</a>
										) : (
											slug
										)}
										)
									</span>
								</h4>
								<div className='vs-details'>
									<span>{this.props.student_details.yoga_level.toUpperCase()}</span>

									<span>{this.props.student_details.number_of_sessions_finished} SESSIONS DONE</span>
									<span>{this.props.student_details.membership_plan.toUpperCase()}</span>
								</div>
							</div>

							<div
								className='vs-profile-pic'
								style={{
									backgroundImage: `url(${this.props.student_details.profile_photo})`,
								}}
							/>
						</div>

						<div className='vs-basic-details'>
							{!!this.props?.student_details?.concierge_name && (
								<div className='vs-basic-container'>
									<div className='vs-basic-icon'>
										<StudentProfileIcon />
									</div>
									<div className='vs-basic-desc'>
										<h4 className='vs-basic-desc-title'>Concierge</h4>
										<p className='vs-basic-desc-text'>
											{this.props?.student_details?.concierge_name}
										</p>
									</div>
								</div>
							)}
							<div className='vs-basic-container'>
								<div className='vs-basic-icon'>
									<img
										src='https://images.myyogateacher.com/teacher_web_app/ic_yoga@2x.png'
										alt='yoga'
									/>
								</div>
								<div className='vs-basic-desc'>
									<h4 className='vs-basic-desc-title'>Experience with Yoga</h4>
									<p className='vs-basic-desc-text'>
										<span className='exp-p'>{this.props.student_details.yoga_level}</span>,{' '}
										{this.props.student_details.years_of_experience}
									</p>
								</div>
							</div>

							{this.props.student_details.yoga_types_practiced.trim() !== '' ? (
								<div className='vs-basic-container'>
									<div className='vs-basic-icon'>
										<img
											className='mat'
											src='https://images.myyogateacher.com/teacher_web_app/ic_yoga_practiced@2x.png'
											alt='yoga'
										/>
									</div>
									<div className='vs-basic-desc'>
										<h4 className='vs-basic-desc-title'>Types of Yoga practiced</h4>
										<p className='vs-basic-desc-text'>
											{this.props.student_details.yoga_types_practiced
												.split(',')
												.map((item, index) => {
													return this.renderBlock(item, index);
												})}
										</p>
									</div>
								</div>
							) : null}

							<div className='vs-basic-container'>
								<div className='vs-basic-icon'>
									<img
										src='https://images.myyogateacher.com/teacher_web_app/ic_goals@2x.png'
										alt='yoga'
									/>
								</div>
								<div className='vs-basic-desc'>
									<h4 className='vs-basic-desc-title'>Goals</h4>
									<p className='vs-basic-desc-text'>
										{this.props.student_details.goals.split(',').map((item, index) => {
											return this.renderBlock(item, index);
										})}
									</p>
								</div>
							</div>

							{this.props.student_details.medical_history_details.length !== 0 ? (
								<div className='vs-basic-container'>
									<div className='vs-basic-icon'>
										<img
											src='https://images.myyogateacher.com/teacher_web_app/ic_medical_history@2x.png'
											alt='yoga'
										/>
									</div>
									<div className='vs-basic-desc'>
										<h4 className='vs-basic-desc-title'>Medical history</h4>
										<p className='vs-basic-desc-text'>
											{this.props.student_details.medical_history_details}
										</p>
									</div>
								</div>
							) : (
								<div className='vs-basic-container'>
									<div className='vs-basic-icon'>
										<img
											src='https://images.myyogateacher.com/teacher_web_app/ic_medical_history@2x.png'
											alt='yoga'
										/>
									</div>
									<div className='vs-basic-desc'>
										<h4 className='vs-basic-desc-title'>Medical history</h4>
										<p className='vs-basic-desc-text'>No medical history details</p>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				{/* <div className="main-c-footer">
          <div className="main-c-footer-cont">
            <button style={{ display: "none" }} className="btn btn-pri">
              Yoga Journey
            </button>
            <button className="btn btn-back">
              <img
                src="https://images.myyogateacher.com/back_arrow_btm.png"
                alt="back arrow"
              />
              Back
            </button>
            <button className="btn btn-pri " onClick={this.open_sch_sess_w_stu}>
              Book a session with {this.props.student_details.first_name}
            </button>
          </div>
        </div>
        */}
				{this.state.open_sch_sess_with_student ? (
					<SelDateTime
						student_timezone={this.props.student_details.iana_timezone}
						student_uuid={this.props.student_details.uuid}
						close_sch_sess_w_stu={this.close_sch_sess_w_stu}
						sch_confirm={this.sch_confirm}
					/>
				) : null}
				{this.state.confirm_sch_session ? <Confirm /> : null}
			</>
		);
	};

	render_loading = () => {
		return (
			<div className='vs-loading'>
				{' '}
				<Spinner />
			</div>
		);
	};

	render() {
		return <>{this.state.loading ? this.render_loading() : this.render_student_details()}</>;
	}
}

const mapStateToProps = (state) => {
	return {
		student_details: selectors.get_student_details(state),
		student_loading_status: selectors.student_loading_status(state),
		teacher_details: state.profile.teacher_details,
		is_admin_imp: state.home.is_admin_imp,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		load_student_details: (payload) => {
			dispatch(actions.load_student_details(payload));
		},
	};
};

const StudentProfilePopUpWithRedux = connect(mapStateToProps, mapDispatchToProps)(StudentProfilePopUp);

export default StudentProfilePopUpWithRedux;
