import * as t from '../action_types';

export const set_mysession_variable = (key, payload) => {
  return {
    type: t.set_mysession_variable,
    payload: {
      key,
      payload,
    },
  };
};

export const getMySessionV1 = payload => {
  return {
    type: t.my_session_v1,
    payload,
  };
};

export const getMySessionData = payload => {
  return {
    type: t.my_session_data_v1,
    payload,
  };
};

export const getMySessionCancelClasses = payload => {
  return {
    type: t.my_session_cancelled_classes,
    payload,
  };
};

export const getMySessionReplaceClasses = payload => {
  return {
    type: t.my_session_replaced_classes,
    payload,
  };
};

export const getMySessionNoShowClasses = payload => {
  return {
    type: t.my_session_noshow_classes,
    payload,
  };
};

export const getMySessionQuestionClasses = payload => {
  return {
    type: t.my_session_question_classes,
    payload,
  };
};