import React from 'react'
import './RadioBtn.css'

function RadioButton(props) {
    const { id, value, name, isChecked, disabled, forCheck, label, classNames, color = "whiteRadioBtn", key } = props;
    return (
        <div >
            <label className="flex items-center cursor-pointer ">
                <div className={`${color}`} key={key}>
                    <input
                        type="radio"
                        id={id}
                        value={value}
                        name={name}
                        checked={Boolean(isChecked)}
                        disabled={disabled}
                        onChange={props.onClick} />
                    <label htmlFor={forCheck}></label>
                </div>
                <label
                    htmlFor={id}
                    className={`whitespace-nowrap pointer-events-none font-inter leading-22px text-13.5px font-medium cursor-pointer labelHoverState ${isChecked ? 'text-primary' : 'text-mytLabel'} ${classNames}`}
                >
                    {label}
                </label>
            </label>
        </div>

    )
}

export default RadioButton