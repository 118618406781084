import React from 'react';
import { analytics_track, get_time_taken } from '../../../util_functions';
import './our-mission.css';

const on_signup_click = (scroll_fn, start_time) => {
	scroll_fn();
	const options = {
		referrer_component: 'home-our-mission',
		plan: 'guest',
		time_since_page_load: get_time_taken(start_time)
	};
	analytics_track(`signup link click`, options);
};

const OurMission = props => {
	return (
		<div className="bg-light our-mission-container">
			<div className="container">
				<div className="rowinside-ourmission">
					<div className="col4-ourmission">
						<div className="tp-ourmission">
							<img
								className="our-mission"
								src="https://images.myyogateacher.com/Teacher-Page/img-mission-mandala.png"
								alt="man"
							/>
						</div>
					</div>
					<div className="col8-ourmission">
						<h2>Our mission is to improve physical and mental well being of the world</h2>
						<p className="divider-line">
							<img src="https://images.myyogateacher.com/Teacher-Page/Dividers3.png" alt="dvd" />
						</p>
						<div className="join-mission">
              <a className="btn-border-dash" onClick={() => on_signup_click(props.go_to_signup, props.start_time)}>
								<span>Join the mission</span>
								<img className="icon" src="https://images.myyogateacher.com/home-page/arrow+next.png" alt="ic" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OurMission;
