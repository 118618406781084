import React, { useEffect, useState, useMemo } from 'react';
import OutsideAlerter from '../ClickDetecte';
import { DurationIcon } from '../../../assets/svg/popup_icons';

const Duration = (props) => {
	const [inputTypeLabel, setInputTypeLabel] = useState(props.inputTypeLabel);
	const [getOpenTimeSlots, setOpenTimeSlots] = useState(false);
	const [getSelectedTime, setSelectedTime] = useState(props?.selectedDuration);
	const [getSelectedTimeProps, setSelectedTimeProps] = useState(null);
	const [getDurationData] = useState([
		{
			id: 1,
			duration: '30 mins',
			durationTime: 30,
		},
		// {
		//   id: 2,
		//   duration: '45 mins',
		//   durationTime: 45,
		// },
		{
			id: 3,
			duration: '60 mins',
			durationTime: 60,
		},
		{
			id: 4,
			duration: '90 mins',
			durationTime: 90,
		},
	]);

	useMemo(() => {
		getDurationData.filter((el) => {
			if (el.durationTime === getSelectedTime) {
				setSelectedTime(el.duration);
				setSelectedTimeProps(el?.durationTime);
			}
		});
	}, []);

	useEffect(() => {
		props.onClickDurationValue(getSelectedTimeProps);
	}, [getSelectedTimeProps]);

	function openTimeSlots() {
		setOpenTimeSlots(true);
	}

	function selectedTimeDt(dt) {
		getDurationData.filter((el) => {
			if (el.durationTime === dt) {
				setSelectedTime(el?.duration);
				setSelectedTimeProps(el?.durationTime);
			}
		});
		setOpenTimeSlots(false);
	}

	return (
		<>
			<div>
				<div className='md:w-195px zm:w-1/2 lg:m-0 zm:mr-2'>
					<p className='font-medium text-mytLabel text-13.5'>{inputTypeLabel}</p>
					<div className='relative cursor-pointer mt-2'>
						<div className='realtive durationIconContainer' onClick={openTimeSlots}>
							<input
								className='formInput text-mytpurple text-15 font-medium mb-25px'
								placeholder='Feb 9, 2021'
								readOnly
								value={getSelectedTime}
							/>
							<div className='pointer-events-none absolute top-5 right-3 pl-2.5 durationIcon'>
								{<DurationIcon />}
							</div>
						</div>
						<OutsideAlerter close_card={() => setOpenTimeSlots(false)}>
							{getOpenTimeSlots && (
								<>
									<div className='w-full h-auto overflow-y-scroll mt-1 absolute z-50 mt-2 shadow-md top-10 right-0 bg-white rounded-lg border border-gray pt-25px pb-25px pl-25px pr-25px'>
										{getDurationData.map((dt) => {
											return (
												<div
													key={dt?.duration}
													onClick={() => selectedTimeDt(dt?.durationTime)}
													className={`text-start mb-3 selectedMonthOption ${
														getSelectedTime === dt?.duration ? 'text-primary' : 'text-black'
													}`}
												>
													{dt?.duration}
												</div>
											);
										})}
									</div>
								</>
							)}
						</OutsideAlerter>
					</div>
				</div>
			</div>
		</>
	);
};

export default Duration;
