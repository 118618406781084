import React from 'react';
import PropTypes from 'prop-types';

import './custom_checkbox.css';

const CustomCheckbox = props => {
	return (
		<div className="custom-checkbox" onClick={props.onClick}>
			<input className='checkBoxAlignment' type="checkbox" readOnly checked={props.checked} />
			<div className="custom-checkbox-label">{props.label}</div>
		</div>
	);
};

CustomCheckbox.propTypes = {
	checked: PropTypes.bool,
	onClick: PropTypes.func,
	label: PropTypes.string
};

CustomCheckbox.defaultProps = {
	label: 'label',
	checked: false,
	onClick: () => null
};

export default CustomCheckbox;
