import React, { useEffect, useState, forwardRef } from 'react';
import { my_session_replaced_classes } from '../../../../../redux/api_functions/availability_api';
import { removeCookie, analytics_reset } from '../../../../../util_functions';
import Loader from '../../../../new_teacher_wesite/components/loader';
import moment from 'moment';
import { withRouter } from '../../../../../utils/router';

const ReplacedClassPopup = (props) => {
	const [isLoading, setIsloading] = useState(false);
	const [endAvailabilityDate, setEndAvailabilityDate] = useState(null);

	useEffect(() => {
		replacedDataFunction();
	}, []);

	const replacedDataFunction = () => {
		setIsloading(true);
		let payload = {
			month: moment(props.data, 'MMM, YYYY').format('M'),
			year: moment(props.data, 'MMM, YYYY').format('YYYY'),
		};
		my_session_replaced_classes(payload)
			.then((response) => {
				if (response?.status === 'success') {
					setEndAvailabilityDate(response?.data);
					setIsloading(false);
				} else if (response.response.status === 401) {
					removeCookie('teacher_token_1');
					localStorage.removeItem('teacherLocalChannels');
					analytics_reset();
					this.props.router.navigate('/login');
					this.setState({
						isLoading: true,
					});
				}
			})
			.catch((e) => {
				if (e.response.status === 401) {
					removeCookie('teacher_token_1');
					localStorage.removeItem('teacherLocalChannels');
					analytics_reset();
					this.props.router.navigate('/login');
					setIsloading(false);
				}
			});
	};

	const modal = React.useRef(null);

	const on_close_modal = (e) => {
		if (modal && modal.current && !modal.current.contains(e.target)) {
			props.replacedPopupClose();
		}
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<div className='relative inset-0 z-index-max'>
					<div
						onClick={(e) => on_close_modal(e)}
						className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'
					>
						<div ref={modal} className='z-50 bg-white rounded-lg p-10 zm:w-492px relative'>
							<div className='flex justify-end cursor-pointer absolute top-1 right-1'>
								<div onClick={props.replacedPopupClose}>
									<svg
										width='40'
										height='40'
										viewBox='0 0 34 34'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<rect width='34' height='34' rx='17' fill='none' />
										<path
											d='M22 12L12 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M12 12L22 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</div>
							<div className='mb-0'>
								<div className='flex justify-center flex-col items-center'>
									<div className='w-full'>
										<div>
											<div className='text-19 font-semibold text-mytgrey-subText mb-15px'>
												{props.data2?.total_cancelled} Replaced in{' '}
												{moment(props.data, 'MMM, YYYY').format('MMMM YYYY')}
											</div>
											<div className='border rounded-lg border-mytgrey-litev1'>
												<div className='flex justify-start items-center text-13.5 text-mytgrey-lite font-medium'>
													<div className='flex justify-start items-center w-282px border border-mytgrey-litev1 pl-15px h-30px border-t-0 border-b-0 border-r-0 border-l-0'>
														Replaced within 24 hours
													</div>
													<div className='flex justify-start items-center w-130px border border-mytgrey-litev1 h-30px pl-11 border-t-0 border-b-0 border-r-0'>
														{endAvailabilityDate?.replaced_within_24_hours}
													</div>
												</div>
												<div className='flex justify-start items-center text-13.5 text-mytgrey-lite font-medium'>
													<div className='flex justify-start items-center w-282px border border-mytgrey-litev1 pl-15px h-30px border-b-0 border-r-0 border-l-0'>
														Replaced outside 24 hours
													</div>
													<div className='flex justify-start items-center w-130px border border-mytgrey-litev1 h-30px pl-11 border-b-0 border-r-0'>
														{endAvailabilityDate?.replaced_outside_24_hours}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const ReplacedClassPopupWithRouter = withRouter(ReplacedClassPopup);

export default ReplacedClassPopupWithRouter;
