import React, { Component } from "react";
import "./accept_popup.css";

export default function AcceptPopup(props) {
  return (
    <div className="accept_popup accept_class_model">
      <div className="popup">
        <p>{props.message}</p>
        <div className="btn-accept-popup">
          <a onClick={props.onCancel} className="cencel">
            Cancel
          </a>
          <a onClick={props.onConfirm} className="confirm">
            Confirm
          </a>
        </div>
      </div>
    </div>
  );
}
// export default class AcceptPopup extends Component {
//   constructor(props) {
//     super(props);
//   }
//   render() {
//     return (
//       <div className="accept_popup accept_class_model">
//         <div className="popup">
//           <p>{this.props.message}</p>
//           <div className="btn">
//             <a onClick={this.props.onCancel} className="cencel">
//               Cancel
//             </a>
//             <a onClick={this.props.onConfirm} className="confirm">
//               Confirm
//             </a>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
