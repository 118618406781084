import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast as NotificationManager } from 'react-toastify';
import HeaderWithRouter from '../util_components/header';
import { get_api, post_api } from '../../redux/api_funcs';
import urls from '../../urls';
import RadioButton from './RadioButton_v2';
import { ChevronDown } from '../../../svg';
import { sendEventToClarity } from '../../util_functions';

const dataMins = [
  {
    id: 0,
    title: '5 Mins',
    seconds: 300
  },
  {
    id: 1,
    title: '10 Mins',
    seconds: 600
  },
  {
    id: 2,
    title: '15 Mins',
    seconds: 900
  },
  {
    id: 3,
    title: '30 Mins',
    seconds: 1800
  }
];

const hours = [
  '12 am',
  '01 am',
  '02 am',
  '03 am',
  '04 am',
  '05 am',
  '06 am',
  '07 am',
  '08 am',
  '09 am',
  '10 am',
  '11 am',
  '12 pm',
  '01 pm',
  '02 pm',
  '03 pm',
  '04 pm',
  '05 pm',
  '06 pm',
  '07 pm',
  '08 pm',
  '09 pm',
  '10 pm',
  '11 pm'
];

function PhoneCallReminder() {
  const [isLoading, setLoading] = useState(true);

  const [panelLeft, setPanelLeft] = useState(false);
  const [panelRight, setPanelRight] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const [startTime, setStartTime] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);

  const [delayTime, setDelayTime] = useState(undefined);

  const handleChange = (e) => {
    // console.log('handleChange', e.checked);
  };

  const toggleReminders = () => {
    setEnabled(!enabled);
  };

  const toggleButtonLeft = () => {
    setPanelLeft(!panelLeft);
  };

  const toggleButtonRight = () => {
    setPanelRight(!panelRight);
  };

  useEffect(() => {
    sendEventToClarity();
  }, []);

  useEffect(() => {
    if (!isLoading) return;

    get_api(urls.phone_call_reminder, true)
      .then((response) => {
        setLoading(false);

        // console.log('phone_call_popup', response.data);

        // if (response.data?.status !== 'success') {
        //   return props.closeCallPopup();
        // }

        if (
          response.data.data.notify_session_begin_time &&
          response.data.data.notify_session_end_time &&
          response.data.data.notify_session_begin_time !==
            response.data.data.notify_session_end_time
        ) {
          setEnabled(true);
          setStartTime(response.data.data.notify_session_begin_time);
          setEndTime(response.data.data.notify_session_end_time);
        } else {
          setEnabled(false);
        }

        dataMins.forEach((x) => {
          if (x.seconds === response.data.data.notify_session_delta_sec) {
            setDelayTime(x.id);
          }
        });
      })
      .catch((error) => {
        console.log('phone_call_popup', error);
        setLoading(false);
        //  props.closeCallPopup();
      });
  });

  const startTimeSelected = (hour) => {
    setStartTime(moment(hour, 'hh a').utc().format('HH:mm:ss'));
    toggleButtonLeft();
  };

  const endTimeSelected = (hour) => {
    setEndTime(moment(hour, 'hh a').utc().format('HH:mm:ss'));
    toggleButtonRight();
  };

  const localStartTime = () => moment.utc(startTime, 'HH:mm:ss').local();
  const localEndTime = () => moment.utc(endTime, 'HH:mm:ss').local();

  const saveChanges = () => {
    if (enabled) {
      if (startTime === undefined) {
        return NotificationManager.error('start time not selected');
      }

      if (endTime === undefined) {
        return NotificationManager.error('end time not selected');
      }

      if (delayTime === undefined) {
        return NotificationManager.error('delay time not selected');
      }
    }

    const payload = {
      notify_session_enabled: enabled,
      notify_session_begin_time: enabled ? startTime : '00:00:00',
      notify_session_end_time: enabled ? endTime : '00:00:00',
      notify_session_delta_sec: enabled ? dataMins[delayTime].seconds : 300
    };

    post_api(urls.phone_call_reminder, payload, true)
      .then((response) => {
        // console.log('result', response);
        if (response.data?.status !== 'success') {
          try {
            NotificationManager.error(response.data.reason);
          } catch {}
        } else {
          NotificationManager.info('Phone call reminder settings updated');
          //   props.closeCallPopup();
        }
      })
      .catch((err) => {
        console.log('phone_call-popup>post_api', err);
      });
  };

  return (
    <>
      <HeaderWithRouter />
      <div className="px-25px">
        <div className="flex flex-col justify-center md:max-w-[700px] w-full my-100px mx-auto bg-white border border-border shadow rounded-lg md:p-10 zm:p-30px">
          <div className="sm:text-19px font-semibold leading-22px font-inter zm:text-18px">
            Setup phone call reminders
          </div>
          <div className="text-14.5px text-xl font-normal font-inter text-mytgrey-lite sm:mt-10px sm:mb-20px zm:mt-10px zm:mb-10px">
            Setup automated calls for your classes, so that you never miss a class or are never late
            !
          </div>
          <div
            className="bg-mytgrey-rowBgColor flex items-center justify-between h-46px pt-8px pb-8px pl-15px pr-15px rounded-lg"
            onClick={() => toggleReminders()}
          >
            <div className="text-15px font-medium font-inter text-mytpurple sm:text-lg">
              Phone call reminders
            </div>
            <div className="flex items-center ">
              <label className="cursor-pointer switch relative inline-block w-54px h-30px">
                <input
                  type="checkbox"
                  className="checkbox opacity-0 w-0 h-0"
                  checked={enabled}
                  onChange={handleChange}
                  onClick={(e) => e.stopPropagation()}
                />
                <span className="slider round absolute pointer top-0 left-0 bottom-0 right-0 " />
              </label>
            </div>
          </div>
          {/* START */}
          <div>
            <div>
              <div className="text-13.5px font-medium font-inter text-mytgrey-phoneCallPopupText mt-30px mb-7px">
                Remind me for classes starting between
              </div>
              <div className="flex items-center">
                <div className="relative">
                  <div
                    onClick={toggleButtonLeft}
                    className="shadow-sm cursor-pointer flex items-center justify-between zm:w-[104px] sm:w-140px h-46px pl-16px pr-16px border border-mytgrey-DropDownBgColor rounded-lg md:text-15px zm:text-sm font-medium text-mytpurple sm:text-lg"
                  >
                    <div>{startTime === undefined ? '- -' : localStartTime().format('hh a')}</div>
                    <div>
                      <ChevronDown />
                    </div>
                  </div>
                  {panelLeft ? (
                    <div className="absolute h-100px overflow-scroll pb-0 pt-0 flex flex-col z-50 left-0 top-50px border bg-white shadow-teacherCardShadow py-5 px-6 rounded-lg w-full">
                      {hours.map((hour, idx) => (
                          <p
                            key={idx}
                            style={{ cursor: 'pointer' }}
                            onClick={() => startTimeSelected(hour)}
                          >
                            {hour}
                          </p>
                        ))}
                    </div>
                  ) : null}
                </div>
                <div className="sm:text-13.5px font-medium font-inter text-mytgrey-lite ml-10px mr-10px">
                  and
                </div>
                <div className="relative">
                  <div
                    onClick={toggleButtonRight}
                    className="shadow-sm cursor-pointer flex items-center justify-between zm:w-[104px] sm:w-140px h-46px pl-16px pr-16px border border-mytgrey-DropDownBgColor rounded-lg md:text-15px zm:text-sm font-medium text-mytpurple"
                  >
                    <div>{endTime === undefined ? '- -' : localEndTime().format('hh a')}</div>
                    <div>
                      <ChevronDown />
                    </div>
                  </div>
                  {panelRight ? (
                    <div className="absolute h-100px overflow-scroll pb-0 pt-0 flex flex-col z-50 left-0 top-50px border bg-white shadow-teacherCardShadow py-5 px-6 rounded-lg w-full">
                      {hours.map((hour, idx) => (
                          <p
                            key={idx}
                            style={{ cursor: 'pointer' }}
                            onClick={() => endTimeSelected(hour)}
                          >
                            {hour}
                          </p>
                        ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="text-13.5px font-medium font-inter text-mytgrey-phoneCallPopupText mt-30px mb-5px zm:pr-20px zm:pl-30px sm:pl-0 sm:pr-0">
              How many minutes before the class should we call you ?
            </div>
            <div className="zm:pl-30px zm:pr-30px sm:pl-0 sm:pr-0">
              <div className="flex zm:flex-wrap items-center zm:gap-y-2.5 sm:gap-0">
                {dataMins.map((dt) => (
                    <div key={dt.id}>
                      <RadioButton
                        id={dt.id}
                        label={dt.title}
                        name={dt.title}
                        value={dt.title}
                        className="text-15"
                        isChecked={delayTime === dt.id}
                        disabled={false}
                        onClick={(e) => setDelayTime(dt.id)}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="text-13.5px font-medium font-inter text-mytgrey-phoneCallPopupText mt-30px mb-5px zm:pr-20px zm:pl-30px sm:pl-0 sm:pr-0">
              Please note we will remind you only once for back to back classes
            </div>
            <div className="flex mt-30px">
              <button
                className="btn-orange-icon rounded-lg w-412px flex items-center justify-center zm:text-18px sm:text-18.5px font-semibold font-inter sm:h-60px zm:h-49px"
                onClick={saveChanges}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhoneCallReminder;
