import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { size, toArray } from 'lodash';
import * as actions from '../../Redux/action_creators';

import UploadItem from "../UploadItem/UploadItem";

const UploadProgress = (props) => {

  const { fileProgress, uploadingFile, session_uuid } = props;
  const uploadedFileAmount = size(fileProgress);

  useEffect(() => {
    const fileToUpload = toArray(fileProgress).filter(file => file.progress === 0)
    let payload = {
      files: fileToUpload,
      session_uuid: session_uuid
    }
    session_uuid && fileToUpload.length && uploadingFile(payload)
  }, [uploadedFileAmount])

  return uploadedFileAmount > 0 ? (
    <>
      {size(fileProgress)
        ? toArray(fileProgress).map(file => <UploadItem key={file.id} file={file} />)
        : null}
    </>
  ) : null
}

const mapStateToProps = state => ({
  fileProgress: state.uploadFile.fileProgress,
  session_uuid: state.uploadFile.session_uuid,
})

const mapDispatchToProps = dispatch => ({
  uploadingFile: files => dispatch(actions.uploadingFile(files)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress)