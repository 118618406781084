import React, { Component } from "react";
import axios from "axios";
import Spinner from "../../util_components/spinner";
import CustomInput from "../../util_components/custom_input";
import CustomButton from "../../util_components/custom_button";
import { setCookie, getCookie, removeCookie } from "../../../util_functions";
import urls from "../../../urls";

import "./clear_email.css";

export default class ClearEmail extends Component {
  state = {
    loading: false,
    login_email: "",
    login_pwd: "",
    login_error: false,
    login_error_msg: "",
    token: "",
    clear_email: "",
    logged_in: false,
    clear_error: false,
    clear_error_msg: "",
    email_cleared: false
  };
  componentDidMount() {
    const token = getCookie("clear_token");
    if (typeof token !== "undefined" && token !== "" && token !== null) {
      this.setState({ loading: false, token, logged_in: true });
    } else {
      this.setState({ loading: false });
    }
  }
  handle_state_change = (key, value) => {
    this.setState({
      [key]: value,
      login_error: false,
      clear_error: false
    });
  };
  login_func = () => {
    if (this.state.login_email !== "" && this.state.login_pwd !== "") {
      this.setState({ loading: true });
      axios({
        method: "post",
        url: urls.clear_email_login,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: this.state.login_email,
          password: this.state.login_pwd
        }
      })
        .then(response => {
          setCookie("clear_token", response.data.token, 86400);
          this.setState({
            loading: false,
            logged_in: true,
            clear_email: "",
            token: response.data.token
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            login_error: true,
            login_error_msg: "Email and password doesnt match or you dont have access to this page"
          });
        });
    } else {
      this.setState({
        login_error: true,
        login_error_msg: "All fields are compulsory"
      });
    }
  };
  clear_email = () => {
    if (this.state.clear_email !== "") {
      this.setState({ loading: true });
      axios({
        method: "post",
        url: urls.clear_email_queue,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token
        },
        data: {
          email: this.state.clear_email
        }
      })
        .then(() => {
          this.setState({ loading: false, email_cleared: true });
        })
        .catch(error => {
          if (error.response.status === 401) {
            removeCookie("clear_token");
            this.setState({
              logged_in: false,
              login_error: true,
              login_error_msg: "Token expired. Please login again",
              loading: false
            });
          } else {
            this.setState({
              loading: false,
              clear_error: true,
              clear_error_msg: "Error occured"
            });
          }
        });
    } else {
      this.setState({
        clear_error: true,
        clear_error_msg: "All fields are compulsory"
      });
    }
  };
  render_details = () => {
    if (this.state.loading) {
      return (
        <div className="clear-email">
          <Spinner />
          <div>loading....</div>
        </div>
      );
    }
    if (this.state.email_cleared) {
      return (
        <div className="clear-email">
          Email cleared
          <div className="reset-password-submit">
            <CustomButton
              label="Clear another email"
              onClick={() => this.setState({ email_cleared: false, clear_email: "" })}
            />
          </div>
        </div>
      );
    }
    if (this.state.logged_in) {
      return (
        <div className="clear-email">
          <div className="reset-password-input">
            <CustomInput
              label="Enter email"
              handleChange={e => this.handle_state_change("clear_email", e.target.value)}
              value={this.state.clear_email || ''}
            />
          </div>
          <div className="reset-password-submit">
            <CustomButton label="Submit" onClick={this.clear_email} />
          </div>
          {this.state.clear_error ? <div className="login-error"> {this.state.clear_error_msg} </div> : null}
        </div>
      );
    }
    return (
      <div className="clear-email">
        <div className="reset-password-input">
          <CustomInput
            label="Email"
            handleChange={e => this.handle_state_change("login_email", e.target.value)}
            value={this.state.login_email || ''}
          />
        </div>
        <div className="reset-password-input">
          <CustomInput
            label="Password"
            type="password"
            handleChange={e => this.handle_state_change("login_pwd", e.target.value)}
            value={this.state.login_pwd}
          />
        </div>
        <div className="reset-password-submit">
          <CustomButton label="Login" onClick={this.login_func} />
        </div>
        {this.state.login_error ? <div className="login-error"> {this.state.login_error_msg} </div> : null}
      </div>
    );
  };
  render() {
    return (
      <div className="clear-container">
        <img className="clear-email-icon" src="https://images.myyogateacher.com/MYT_Logo.svg" alt="Logo"
         style={{ width: "256px", height: "28px", marginLeft: "40px" }} />
        {this.render_details()}
      </div>
    );
  }
}
