// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast as NotificationManager } from 'react-toastify';
import { analytics_track, sendEventToClarity } from '../../../util_functions';
import CustomLoading from '../../util_components/custom_loading';
import { profile_compartments } from '../../../constants';
import {
  RenderBasicEdit,
  render_profile_component_all_details,
  RenderSocialEdit,
  RenderAppEdit,
  get_compartment_names
} from './profile_funcs';
import { RenderBasicWithoutEdit } from './basic_funcs';
import { render_yoga_all_details, RenderYogaEdit } from './yoga_funcs';
import { render_documents, EditDocuments } from './docs_funcs';
import { render_other_all_details, RenderOtherEdit } from './other_funcs';
import './profile.css';
import CustomButton from '../../util_components/custom_button';
import CheckMobile from '../check_mobile';
import Header from '../../util_components/header';
import Footer from '../../util_components/footer';

import * as selectors from '../../../redux/selectors';
import * as actions from '../../../redux/action_creators';

class Profile extends Component {
  state = {
    profile_compartment_index: 0,
    edit_basic: this.props.onboarding_status === 'INTERESTED_TO_JOIN',
    edit_yoga: this.props.onboarding_status === 'INTERESTED_TO_JOIN',
    edit_social: this.props.onboarding_status === 'INTERESTED_TO_JOIN',
    edit_docs: this.props.onboarding_status === 'INTERESTED_TO_JOIN',
    edit_other: this.props.onboarding_status === 'INTERESTED_TO_JOIN',
    edit_app: this.props.onboarding_status === 'INTERESTED_TO_JOIN',
    error_basic: false,
    error_yoga: false,
    error_social: false,
    error_docs: false,
    error_other: false,
    error_app: false,
    basic_details: this.props.basic_details,
    yoga_details: {
      ...this.props.yoga_details,
      yoga_types_teaching: !this.props.yoga_details.yoga_types_teaching
        ? []
        : this.props.yoga_details.yoga_types_teaching,
      goals: this.props.yoga_details.goals.slice(0, 3)
    },
    // goals: filterYogaGoals(this.props.yoga_details.goals, this.props.config_values.yoga_goals).slice(0, 3)
    social_details: this.props.social_details,
    other_details: {
      ...this.props.other_details,
      stories:
        typeof this.props.other_details.stories === 'string'
          ? []
          : this.props.other_details.stories,
      how_did_you_hear_about_us:
        typeof this.props.other_details.how_did_you_hear_about_us === 'string'
          ? {}
          : this.props.other_details.how_did_you_hear_about_us
    },
    docs_details: this.props.docs_details,
    app_details: this.props.app_details,
    files: {},
    loading: false,
    load_profile_component: ''
  };

  track_fn = (name) => {
    const options = {
      referrer_component: 'profile'
    };
    analytics_track(`${name} click`, options);
  };

  componentDidMount() {
    window.document.title = 'Your profile details';
    sendEventToClarity();
  }

  componentWillUnmount() {
    window.document.title = 'MyYogaTeacher';
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (this.state.loading && this.props.profile_update_status === 'success') {
      this.set_load_compartment('success');
    }
    if (this.state.loading && this.props.profile_update_status === 'error') {
      this.set_load_compartment('error');
    }
    if (oldProps.basic_details !== newProps.basic_details) {
      this.setState({ basic_details: newProps.basic_details });
    }
    if (oldProps.yoga_details !== newProps.yoga_details) {
      const years_of_yoga_teaching_experience =
        newProps.yoga_details.years_of_yoga_teaching_experience.toString();
      const years_of_yoga_practise = newProps.yoga_details.years_of_yoga_practise.toString();
      const yoga_types_teaching =
        this.props.yoga_details.yoga_types_teaching === ''
          ? []
          : this.props.yoga_details.yoga_types_teaching;
      // const goals = filterYogaGoals(this.props.yoga_details.goals, this.props.config_values.yoga_goals).slice(0, 3);
      const goals = this.props.yoga_details.goals.slice(0, 3);
      this.setState({
        yoga_details: {
          ...newProps.yoga_details,
          goals,
          years_of_yoga_practise,
          years_of_yoga_teaching_experience,
          yoga_types_teaching
        }
      });
    }
    if (oldProps.social_details !== newProps.social_details) {
      this.setState({ social_details: newProps.social_details });
    }
    if (oldProps.other_details !== newProps.other_details) {
      this.setState({
        other_details: {
          ...newProps.other_details,
          stories:
            typeof newProps.other_details.stories === 'string'
              ? []
              : newProps.other_details.stories,
          how_did_you_hear_about_us:
            typeof newProps.other_details.how_did_you_hear_about_us === 'string'
              ? {}
              : newProps.other_details.how_did_you_hear_about_us
        }
      });
      this.setState({ other_details: newProps.other_details });
    }
    if (oldProps.docs_details !== newProps.docs_details) {
      this.setState({ docs_details: newProps.docs_details });
    }
    if (oldProps.app_details !== newProps.app_details) {
      this.setState({ app_details: newProps.app_details });
    }
  }

  set_load_compartment = (resp) => {
    if (resp === 'success') {
      const profile_component = this.state.load_profile_component;
      this.setState({
        [`edit_${profile_component}`]: !this.state[`edit_${profile_component}`],
        load_profile_component: '',
        loading: false
      });
    } else {
      this.setState({
        load_profile_component: '',
        loading: false
      });
    }
  };

  render_profile_compartments = () => profile_compartments.map((compartment, index) => {
      const circle_style =
        this.state.profile_compartment_index === index
          ? { backgroundColor: '#576CE4', color: '#FFFFFF' }
          : null;
      const text_style = this.state.profile_compartment_index === index ? { opacity: 1 } : null;
      return (
        <div key={compartment + index}>
          <div
            className="profile-compartment"
            onClick={() => {
              this.setState({ profile_compartment_index: index });
              this.track_fn(`${compartment.toLowerCase()  } tab`);
            }}
          >
            <div className="profile-compartment-circle" style={circle_style}>
              {index + 1}
            </div>
            <p style={text_style}>{compartment}</p>
          </div>
          <div className="horizontal-line" />
        </div>
      );
    });

  handleChange = (e, component, index) => {
    this.setState({
      [`error_${component.split('_')[0]}`]: false,
      [component]: {
        ...this.state[component],
        [index]: e
      }
    });
  };

  handleEditorChange = (value, component, index) => {
    this.setState({
      [`error_${component.split('_')[0]}`]: false,
      [component]: {
        ...this.state[component],
        [index]: value
      }
    });
  };

  render_error_message = (profile_compartment) => this.state[profile_compartment] ? (
      <div className="profile-error">*All fields are compulsory</div>
    ) : null;

  handleFileChange = (flag, component, e) => {
    if (flag === 'file') {
      this.setState({
        docs_error: false,
        docs_details: {
          ...this.state.docs_details,
          [component]: e.target.files[0]
        }
      });
    } else if (flag === 'dropdown') {
      this.setState({
        docs_error: false,
        docs_details: {
          ...this.state.docs_details,
          [component]: e
        }
      });
    } else if (flag === 'profile_photo') {
      this.props.update_profile_files('photo', component, e.target.files[0]);
    } else if (flag === 'resume') {
      this.props.update_profile_files('resume', component, e.target.files[0]);
    } else if (flag === 'video') {
      this.props.update_profile_files('video', component, e.target.files[0]);
    }
  };

  render_basic_details = () =>
    this.state.edit_basic ? (
      <div>
        <RenderBasicEdit
          profile={this.state.basic_details}
          handleChange={this.handleChange}
          profile_photo={this.props.profile_photo}
          handleFileChange={this.handleFileChange}
        />
        {this.render_error_message('error_basic')}
      </div>
    ) : (
      <RenderBasicWithoutEdit
        profile={this.state.basic_details}
        profile_photo={this.props.profile_photo}
      />
    );

  render_yoga_details = () => this.state.edit_yoga ? (
      <div>
        <RenderYogaEdit
          details={this.state.yoga_details}
          handleEditorChange={this.handleEditorChange}
          handleChange={this.handleChange}
          teacher_goals={this.props.config_values.yoga_goals}
        />
        {this.render_error_message('error_yoga')}
      </div>
    ) : (
      <div>
        <div className="profile-basic-edit-details">
          <div className="profile-basic-address-line">
            <div className="profile-basic-address-line-text">Yoga: Your yoga experience</div>
            {/* <div className="simple-square" />
						<hr /> */}
          </div>
        </div>
        <div className="profile-single-tab">{render_yoga_all_details(this.state.yoga_details)}</div>
      </div>
    );

  render_social_links = () => (
      <div>
        {this.state.edit_social ? (
          <RenderSocialEdit details={this.state.social_details} handleChange={this.handleChange} />
        ) : (
          <div className="profile-single-tab">
            {render_profile_component_all_details(this.state.social_details)}
          </div>
        )}
        {/* {this.render_edit_save_button("social")} */}
      </div>
    );

  render_documents = () => this.state.edit_docs ? (
      <div className="profile-single-tab">
        {/* <EditDocuments
              handleFileChange={this.handleFileChange}
              component={"id_proof_document"}
              label={"Id Proof"}
              dropdown={true}
              file={this.state.id_file}
              details={this.state.docs_details}
            /> */}
        <EditDocuments
          handleFileChange={this.handleFileChange}
          component="resume"
          upload_url="upload_resume"
          label="Resume"
          dropdown={false}
          file={this.state.resume_document_url}
          details={this.state.docs_details}
        />
        {this.render_error_message('error_docs')}
      </div>
    ) : (
      <div className="profile-single-tab">{render_documents(this.state.docs_details)}</div>
    );

  render_other = () => (
      <div>
        <div className="profile-basic-edit-details">
          <div className="profile-basic-address-line">
            <div className="profile-basic-address-line-text">Others: Your resume & reference</div>
            {/* <div className="simple-square" />
						<hr /> */}
          </div>
          <br />
          <EditDocuments
            handleFileChange={this.handleFileChange}
            component="resume"
            upload_url="upload_resume"
            label="Resume"
            dropdown={false}
            file={this.state.docs_details.resume_document_url}
            details={this.state.docs_details}
          />
          <EditDocuments
            handleFileChange={this.handleFileChange}
            component="video"
            upload_url="update_video"
            label="Intro video"
            dropdown={false}
            file={this.state.docs_details.video_url}
            details={this.state.docs_details}
          />

          {this.state.edit_other ? (
            <div>
              <RenderOtherEdit
                details={this.state.other_details}
                handleChange={this.handleChange}
                handleEditorChange={this.handleEditorChange}
              />
              {this.render_error_message('error_other')}
            </div>
          ) : (
            render_other_all_details(this.state.other_details)
          )}
        </div>
      </div>
    );

  render_app_details = () => this.state.edit_app ? (
      <div>
        <RenderAppEdit details={this.state.app_details} handleChange={this.handleChange} />
        {this.render_error_message('error_edit')}
      </div>
    ) : (
      <div className="profile-single-tab">
        {render_profile_component_all_details(this.state.app_details)}
      </div>
    );

  render_bank_details = () => <div>bank details</div>;

  render_profile_information = () => {
    switch (this.state.profile_compartment_index) {
      case 0:
        return this.render_basic_details();
      case 1:
        return this.render_yoga_details();
      case 2:
        return this.render_other();
      default:
        return null;
    }
  };

  toggle_edit_button = () => {
    if (this.state.profile_compartment_index === 0) {
      this.setState({ edit_basic: !this.state.edit_basic });
    }
  };

  verify_other_details_for_api = (component) => {
    const details = this.state.other_details;
    const {stories} = details;
    this.other_error = false;
    for (let i = 0; i < stories.length; i++) {
      const story = stories[i];
      if (story.url === '' || story.url.indexOf('myyogateacher.com') === -1) {
        this.other_error = true;
      }
    }
    if (this.other_error) {
      NotificationManager.error('* fields are compulsory');
    } else {
      this.props.update_profile_details(component, this.state.other_details);
      this.setState({
        [`edit_${component}`]: !this.state[`edit_${component}`]
      });
    }
  };

  apply_save_button = (profile_component) => {
    let teacher_status = '';
    try {
      teacher_status = this.props.teacher_details.status;
    } catch {}
    if (this.state.profile_compartment_index === 2) {
      this.verify_other_details_for_api(profile_component);
      if (teacher_status !== 'INTERESTED_TO_JOIN') {
        NotificationManager.info('Our team will verify your changes and update it here.');
      }
    } else if (this.state.profile_compartment_index !== 3) {
      this.setState({ loading: true, load_profile_component: profile_component }, () => {
        this.props.update_profile_details(
          profile_component,
          this.state[`${profile_component}_details`]
        );
      });
      if (teacher_status !== 'INTERESTED_TO_JOIN') {
        NotificationManager.info('Our team will verify your changes and update it here.');
      }
    }
  };

  render_new_buttons = () => {
    const profile_component = get_compartment_names(this.state.profile_compartment_index);
    if (this.state[`edit_${profile_component}`]) {
      return (
        <div className="profile-header-buttons">
          <div className="profile-custom-button">
            <CustomButton
              label="Save"
              // secondary_text="move to next screen"
              onClick={() => this.apply_save_button(profile_component)}
            />
          </div>
        </div>
      );
    } 
      return (
        <div className="profile-header-buttons">
          <div className="profile-custom-button">
            <CustomButton
              label="Edit"
              onClick={() =>
                this.setState({
                  [`edit_${profile_component}`]: !this.state[`edit_${profile_component}`]
                })
              }
            />
          </div>
        </div>
      );
    
  };

  render_profile = () => (
      <div className="home-content">
        <div className="profile-container">
          {this.state.loading ? <CustomLoading /> : null}
          <div className="profile-header">
            <div className="profile-header-title">Your details</div>
            {this.render_new_buttons()}
          </div>
          <div className="profile-details">
            <div className="profile-compartments-container">
              <div className="profile-compartments">
                {this.render_profile_compartments()}
                <div className="horizontal-line-end" />
              </div>
            </div>
            <div className="profile-information">{this.render_profile_information()}</div>
          </div>
        </div>
      </div>
    );

  render() {
    return (
      <CheckMobile>
        <div className="home">
          <Header />
          {this.render_profile()}
          <Footer />
        </div>
      </CheckMobile>
    );
  }
}

const mapStateToProps = (state) => ({
    basic_details: selectors.basic_details_selector(state),
    yoga_details: selectors.yoga_details_selector(state),
    social_details: selectors.social_details_selector(state),
    other_details: selectors.other_details_selector(state),
    docs_details: selectors.docs_details_selector(state),
    bank_details: selectors.bank_details_selector(state),
    app_details: selectors.app_details_selector(state),
    onboarding_status: selectors.teacher_details_all(state).status,
    profile_photo: selectors.teacher_details_all(state).profile_photo,
    teacher_details: selectors.teacher_details_all(state),
    config_values: state.home.config_values,
    profile_update_status: state.home.profile_update_status
  });

const mapDispatchToProps = (dispatch) => ({
    update_profile_details: (component_name, data) => {
      dispatch(actions.update_profile_details(component_name, data));
    },
    update_profile_files: (key, file_component, payload) => {
      dispatch(actions.update_profile_files(key, file_component, payload));
    }
  });

const ProfileWithRedux = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ProfileWithRedux;
