import React, { PureComponent } from 'react';
import SingleRenderedSession from './single_session';
import { connect } from 'react-redux';
import * as selectors from '../../../redux/selectors';
import * as actions from '../../../redux/action_creators';
import './dashboard_funcs.css';
import { SMSList } from './sms_list';

class DashboardFuncs extends PureComponent {
	state = {
		session_index_selected: 0,
		modalChat: false,
		nextColumn: 4,
	};

	handleMoreButton = () => {
		this.setState({
			nextColumn: this.state.nextColumn + 4,
		});
	};

	render_sessions = (item, index, sessions) => {
		// let isLast = index + 1 === session.length;
		let isLast = index === sessions.length - 1;
		return (
			<div key={item.session_uuid} id={index}>
				<SingleRenderedSession
					index={index}
					item={item}
					isLast={isLast}
					is_community_class_proposal_pending={item.is_community_class_proposal_pending}
				/>
			</div>
		);
	};

	render() {
		const sessions = this.props.sessions;

		if (sessions.length > 0) {
			return (
				<div className='dashboard-sessions-list'>
					{/* {sessions.slice(0, this.state.nextColumn)?.map(this.render_sessions)} */}
					{sessions
						.slice(0, this.state.nextColumn)
						?.map((item, index) => this.render_sessions(item, index, sessions))}
					{this.state.nextColumn >= sessions.length ? null : (
						<div
							className='text-mytblack md:w-auto zm:w-full w-auto zm:mt-25px md:mt-40px btn-white-default font-semibold text-15 relative flex items-center bg-mytgray hover:bg-mytgrey-hover lg:mb-40px'
							onClick={this.handleMoreButton}
						>
							Load More
						</div>
					)}
					{this.props.loadMoreSessions !== undefined && this.props.loadMoreSessions.trim() !== '' && (
						<div
							className='load-more-sessions'
							onClick={() => {
								this.props.load_dashboard_sessions();
							}}
						>
							{this.props.loadMoreSessions}
						</div>
					)}
					<div className='zm:flex flex-col lg:hidden mt-30px'>
						<SMSList />
					</div>
				</div>
			);
		}
		return null;
	}
}

const mapStateToProps = (state) => {
	return {
		dashboard_loading: selectors.get_dashboard_loading(state),
		sessions_in_next_24_hours: selectors.get_sessions_in_next_24_hours(state),
		availability_in_next_7_days: selectors.get_availability_in_next_7_days(state),
		sessions: selectors.get_sessions(state),
		loadMoreSessions: state.dashboard.loadMoreSessions,
		teacher_token: selectors.get_teacher_token(state),
		profile_basic_details: selectors.basic_details_selector(state),
		teacher_details: state.profile.teacher_details,
		account_created: state.home.account_created,
		create_group_session: state.home.create_group_session,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		cancel_session: (payload) => {
			dispatch(actions.cancel_session(payload));
		},
		load_my_sessions: () => {
			dispatch(actions.load_my_sessions());
		},
		load_dashboard_sessions: () => {
			dispatch(actions.load_dashboard_sessions());
		},
	};
};

const DashboardFuncsWithRedux = connect(mapStateToProps, mapDispatchToProps)(DashboardFuncs);

export default DashboardFuncsWithRedux;
