import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingScreen from '../../util_components/loading_screen';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import './edit_rm_pop.css';

class EditRoadMapPop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedAnswer: '',
			show_loader: false,
			show_btn_save: false,
		};

		this.handleChange = this.handleChange.bind(this);
	}
	inputRef = React.createRef();

	componentDidMount() {
		this.inputRef.current.focus();
		this.setState({ selectedAnswer: this.props.selectedQuestion.answer });
	}
	componentDidUpdate(prevProps) {
		if (
			prevProps.update_session_recap_status === 'loading' &&
			this.props.update_session_recap_status === 'success'
		) {
			this.hideLoader();
			this.props.updateMySessionData();
		}
		if (prevProps.update_roadmap_ques_status === 'loading' && this.props.update_roadmap_ques_status === 'success') {
			this.hideLoader();
			this.props.updateMySessionData();
		}
	}

	handleChange(event) {
		this.setState({ selectedAnswer: event.target.value, show_btn_save: true });
	}

	showLoader = () => {
		this.setState({
			show_loader: true,
		});
	};

	hideLoader = () => {
		this.setState({
			show_loader: false,
		});
	};

	updateQuestionNotesAndRecap(paramType) {
		this.showLoader();
		var payload = {
			session_uuid: this.props.sessionUuid,
		};
		payload[paramType] = this.state.selectedAnswer;
		this.props.update_session_recap(payload);
	}

	updateQuestionAnswer(questionUuid) {
		this.showLoader();
		const payload = {
			roadmap_question_uuid: questionUuid,
			answer: this.state.selectedAnswer,
		};
		this.props.updateAnswerValue(this.state.selectedAnswer);
		this.props.update_roadmap_question(payload);
	}

	render() {
		if (this.state.show_loader) {
			return <LoadingScreen />;
		}

		return (
			<div className='edit_rm_pop'>
				<div className='edit_rm_pop_overlay' onClick={this.props.onClose} />
				<div className='edit_rm_pop_content'>
					<button className='btn btn_rmpop_close' onClick={this.props.onClose}>
						<img alt='close' src='https://images.myyogateacher.com/icons/ic_white_cross.png' />
					</button>
					<div className='edit_rm_ques_block'>
						<span className='ic_q'>Q</span>
						<div className='rm_ques_ans_in'>
							<h4>{this.props.ques_item.question}</h4>
							<textarea
								onChange={this.handleChange}
								value={this.state.selectedAnswer}
								ref={this.inputRef}
							/>
							<button
								className={`btn btn-pri ${this.state.show_btn_save ? 'show_it' : 'btn_dis'}`}
								onClick={() => {
									if (this.state.selectedAnswer != '') {
										//this.props.ques_item.answer = this.state.selectedAnswer;
										if (this.props.ques_item.api_param == 'answer') {
											this.updateQuestionAnswer(this.props.ques_item.roadmap_question_uuid);
										} else {
											this.updateQuestionNotesAndRecap(this.props.ques_item.api_param);
										}
									}
								}}
							>
								Save and Next
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		// student_details: selectors.get_student_details(state),
		create_roadmap_status: state.home.create_roadmap_status,
		update_session_recap_status: state.home.update_session_recap_status,
		update_roadmap_ques_status: state.home.update_roadmap_ques_status,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		create_roadmap: (payload) => {
			dispatch(actions.create_roadmap(payload));
		},
		update_session_recap: (payload) => {
			dispatch(actions.update_session_recap(payload));
		},
		update_roadmap_question: (payload) => {
			dispatch(actions.update_roadmap_question(payload));
		},
		update_roadmap: (payload) => {
			dispatch(actions.update_roadmap(payload));
		},
	};
};

const EditRoadMapPopWithRedux = connect(mapStateToProps, mapDispatchToProps)(EditRoadMapPop);

export default EditRoadMapPopWithRedux;
