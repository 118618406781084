import React, { useEffect, useState } from 'react';
import { post_api } from '../../redux/api_funcs';
import urls from '../../urls';
import Button from './CommonComponents/Button';
import Modal from './CommonComponents/Modal';

const EditSessionNotesPopUp = (props) => {
	const [sessionNotes, setSessionNotes] = useState('');

	useEffect(() => {
		if (props.data) {
			setSessionNotes(props.data);
		}
	}, []);

	const onSubmit = () => {
		const url = urls.update_session_recap;
		const payload = {
			session_uuid: props.sessionUUID,
			notes: sessionNotes,
		};
		post_api(url, payload, true)
			.then((res) => {
				props.onSubmit();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Modal onClose={props.onClose}>
			<div className=' px-10 pb-10 pt-1.5'>
				<div className='font-semibold text-19px leading-22px text-greyText mb-5'>Edit session notes</div>
				<textarea
					placeholder='Please enter here...'
					className='formInput h-159px mt-1 px-18px py-5 text-15px leading-21px font-medium text-mytpurple'
					name='editsession'
					value={sessionNotes}
					onChange={(e) => setSessionNotes(e.target.value)}
				/>
				<Button
					btnclassNames='mt-10'
					btntext='Save Changes'
					classNames='pt-12.5px pb-11.5px pr-18px pl-18px'
					onClick={() => onSubmit()}
				/>
			</div>
		</Modal>
	);
};

export default EditSessionNotesPopUp;
