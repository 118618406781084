import React, { useState, useEffect } from "react";
import "./dashboard_funcs.css";
import moment from "moment";

const SessionClasses = (props) => {
    const [waitUntil, setWaitUntil] = useState("");
    let countDown = null;
    useEffect(() => {
        if (props.data.type === "SESSION_REPLACEMENT") {
            let countDownDate = new Date(props.data.session.waitUntil).getTime();
            countDown = setInterval(() => {
                // Get today's date and time
                let now = new Date().getTime();
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                let str = "";
                if (days > 0) {
                    str = str + days + ":";
                }

                str = str + hours + ":";

                str = str + minutes + ":";

                str = str + seconds;

                if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
                    setWaitUntil(
                        `Requested by ${props.data.teacher.first_name}; expires in ${str} `
                    );
                } else {
                    clearInterval(countDown);
                    setWaitUntil("Session Expired");
                }
            }, 1000);
        }
        return () => {
            clearInterval(countDown);
        };
    }, []);

    const { session, student } = props.data;

    const timezone = props.timezone;
    const date = moment(session.sessionStartTime).calendar(null, {
        sameDay: "[Today] [at] h:mm A ",
        nextDay: "[Tomorrow] [at] h:mm A",
        nextWeek: "ddd, MMM Do, h:mm A",
        sameElse: "ddd, MMM Do, h:mm A",
    });

    return (
        <>
            {/* card for invitation user */}
            <div className="relative zm:mt-14px lg:mt-25px rounded-lg zm:p-20px md:p-20px bg-mytBgReplacement">
                <div className="text-20px font-inter font-bold mb-15px">{date + " " + timezone}</div>
                <div className="flex zm:flex-col md:flex-row">
                    <div className="flex-shrink-0 zm:mb-5px md:mb-0">

                        <img
                            className="w-50px h-50px rounded-full"
                            src={
                                !!student.profilePhoto
                                    ? student.profilePhoto
                                    : "https://images.myyogateacher.com/teacher_web_app/Placeholder_Profile_Pic@3x.png"
                            }
                        />
                    </div>
                    <div className="md:ml-2 mt-1">
                        <p className="text-black zm:text-15px md:text-19px font-medium zm:mb-5px md:mb-0">{session.name}</p>
                        <p className="flex gap-10px mt-2px mb-5px">
                            <p className="text-startTimeTextColor zm:text-12px md:text-13px font-medium text-xs leading-16px">{session.duration} minutes</p>
                            {
                                !!session.target_audience &&
                                <p className="text-startTimeTextColor zm:text-12px md:text-13px font-medium text-xs leading-16px">{"Target audience: " + session.target_audience}</p>
                            }
                        </p>
                        {
                            session?.sessionType === "YOGA" ?
                                <span className=" bg-mytBgReplacementClassType text-mytWhiteReplace zm:text-10px font-semibold px-4px py-3px rounded">1-on-1 Class</span>
                                :
                                <span className=" bg-mytClassReplace text-mytWhiteReplace zm:text-10px px-4px py-3px font-semibold rounded">Group Class</span>
                        }
                        {
                            session?.sessionType === "BACK_PAIN_THERAPY" ?
                                <>
                                    <span className=" bg-YWFBGColor text-mytWhiteReplace zm:text-10px font-semibold px-4px py-3px rounded">1-on-1</span>

                                    <span className=" bg-YWFBGColor text-mytWhiteReplace zm:text-10px font-semibold px-4px py-3px rounded">Back Pain Coaching</span>
                                </> : null
                        }
                    </div>
                </div>
                <div className="group_class_card">
                    <div className="session_details">
                        <div className="user_details_section">
                            <div
                                onClick={() => {
                                    if (session.sessionType === "YOGA") {
                                        props.onUserProfileClick(student.slug);
                                    }
                                }}
                                className="user_info"
                            >
                                {session.sessionType === "YOGA" && (
                                    <img
                                        className="user_name_expand"
                                        src="https://images.myyogateacher.com/teacher_web_app/ic_chevron_down.png"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="session_book_section">
                        <div className="text-startTimeTextColor zm:text-12px md:text-13px font-medium text-xs leading-16px py-15px">{waitUntil}</div>
                        <div className="flex">
                            <button
                                onClick={() => {
                                    props.onAccept(props.data, props.index);
                                }}
                                className="btn btn-primary md:text-17px zm:text-15px md:pt-14px md:pb-13px zm:py-3 font-inter font-semibold md:h-49px zm-h-46px w-119px"
                            >
                                Accept
                            </button>
                            <button
                                onClick={() => {
                                    props.onDecline(props.data, props.index);
                                }}
                                className="btn text-mytblack bg-mytgray hover:bg-gray-300 w-119px zm:ml-10px md:ml-20px md:text-17px zm:text-15px md:pt-14px md:pb-13px zm:px-23px md:px-30px font-inter font-semibold md:h-49px zm-h-46px"
                            >
                                Decline
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            {/* ------------------------ */}
        </>
    );
};

export default SessionClasses;
