import { all, put, takeEvery, call } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
// import { post_api } from '../../apis/config';
import { post_api } from '../../../redux/api_funcs';
import urls from '../../urls/urls';

export function* get_class_details(action) {
  try {
    yield put(actions.set_class_details_variable('class_details_status', 'loading'));
    const class_details_url = urls.class_details;
    const payload = {
      ...action.payload
    };
    const response = yield call(post_api, class_details_url, payload, true);
    yield put(actions.set_class_details_variable('class_details_response', response.data));
    yield put(actions.set_class_details_variable('class_details_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_class_details_variable('class_details_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* schedule_class(action) {
  try {
    yield put(actions.set_class_details_variable('schedule_class_status', 'loading'));
    const { schedule_class } = urls;
    const payload = {
      ...action.payload
    };
    const response = yield call(post_api, schedule_class, payload, true);
    const session_uuid = response?.data?.scheduled_sessions[0]?.session_uuid;
    yield put(actions.set_class_details_variable('schedule_class_response', response.data));
    yield put(actions.set_class_details_variable('schedule_class_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_class_details_variable('schedule_class_response', error.response.data));
    yield put(actions.set_class_details_variable('schedule_class_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* update_student_count(action) {
  try {
    yield put(actions.set_class_details_variable('student_count_status', 'loading'));
    const student_count_url = urls.update_student_count;
    const payload = {
      ...action.payload
    };
    const response = yield call(post_api, student_count_url, payload, true);
    yield put(actions.set_class_details_variable('student_count_response', response.data));
    yield put(actions.set_class_details_variable('student_count_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_class_details_variable('student_count_response', error.response.data));
    yield put(actions.set_class_details_variable('student_count_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* update_class_duration(action) {
  try {
    yield put(actions.set_class_details_variable('class_duration_status', 'loading'));
    const payload = {
      ...action.payload
    };
    const class_duration_url = `${urls.update_class_duration}/${payload.session_uuid}/duration`;
    const response = yield call(post_api, class_duration_url, { duration: payload.duration }, true);
    yield put(actions.set_class_details_variable('class_duration_response', response.data));
    yield put(actions.set_class_details_variable('class_duration_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_class_details_variable('class_duration_response', error.response.data));
    yield put(actions.set_class_details_variable('class_duration_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* get_student_profile(action) {
  try {
    yield put(actions.set_class_details_variable('student_profile_status', 'loading'));
    const student_profile_url = urls.get_student_profile;
    const payload = {
      ...action.payload
    };
    const response = yield call(post_api, student_profile_url, payload, true);
    yield put(actions.set_class_details_variable('student_profile_response', response.data));
    yield put(actions.set_class_details_variable('student_profile_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_class_details_variable('student_profile_response', error.response.data));
    yield put(actions.set_class_details_variable('student_profile_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export default function* classDetailsSaga() {
  yield all([
    takeEvery(t.schedule_class, schedule_class),
    takeEvery(t.class_details, get_class_details),
    takeEvery(t.update_student_count, update_student_count),
    takeEvery(t.student_profile, get_student_profile),
    takeEvery(t.update_class_duration, update_class_duration)
  ]);
}
