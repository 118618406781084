import axios from 'axios';
import urls from '../../urls';
import moment from 'moment-timezone';
import { getCookie } from '../../util_functions';

export const get_availability_api = () =>
  axios({
    method: 'post',
    url: urls.get_availability_list,
    headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') }
  })
    .then(response => {
      return response.data;
    })
    .catch(response => {
      return response;
    });

export const add_availability_api = payload =>
  axios({
    method: 'post',
    url: urls.add_availability,
    headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
    data: { ...payload, iana_timezone: moment.tz.guess(true) }
  }).then(response => {
    return response;
  });

export const edit_availability_api = payload =>
  axios({
    method: 'post',
    url: urls.edit_availability,
    headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
    data: { ...payload, iana_timezone: moment.tz.guess(true) }
  }).then(response => {
    return response;
  });


export const my_session_v1 = (payload) =>{
  axios({
    method: 'get',
    url: urls.my_session_v1 + `${'/' + payload.month + '/' + payload.year}`,
    headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
  })
    .then(response => {
      return response.data;
    })
    .catch(response => {
      return response;
    });
}
export const my_session_data_v1 = (payload) =>
axios({
  method: 'get',
  url: urls.my_session_data_v1 + `${'/' + payload.month + '/' + payload.year}`,
  headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
})
.then(response => {
  return response.data;
})
.catch(response => {
      return response;
});

export const my_session_cancelled_classes = (payload) =>
  axios({
    method: 'get',
    url: urls.my_session_cancelled_classes  + `${'/' + payload.month + '/' + payload.year}`,
    headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
  })
    .then(response => {
      return response.data;
    })
    .catch(response => {
      return response;
    });
export const my_session_noshow_classes = (payload) =>
  axios({
    method: 'get',
    url: urls.my_session_noshow_classes  + `${'/' + payload.month + '/' + payload.year}`,
    headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
  })
    .then(response => {
      return response.data;
    })
    .catch(response => {
      return response;
    });

export const my_session_replaced_classes = (payload) =>
  axios({
    method: 'get',
    url: urls.my_session_replaced_classes  + `${'/' + payload.month + '/' + payload.year}`,
    headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
  })
    .then(response => {
      return response.data;
    })
    .catch(response => {
      return response;
    });

    export const my_session_question_classes = payload =>
    axios({
      method: 'put',
      url: urls.my_session_question_classes,
      headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
      data: { ...payload, iana_timezone: moment.tz.guess(true) }
    }).then(response => {
      return response;
    });



  export const get_avalability_slots_data = payload =>
	axios({
		method: 'post',
		url: urls.get_teacher_slots,
		headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
		data: { ...payload, iana_timezone: moment.tz.guess(true), "timezone": -330 }
	}).then(response => {
    // console.log(payload,"................calling")
		return response;
	});

  export const get_replacement_sessions_teacher_slots = payload =>
	axios({
		method: 'post',
		url: urls.get_replacement_sessions_teacher_slots,
		headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
		// data: { ...payload, iana_timezone: moment.tz.guess(true) }
		data: payload 
	}).then(response => {
    // console.log(payload,"................calling")
		return response;
	});


export const edit_avalability_slots_data = payload =>
    axios({
        method: 'post',
        url: urls.update_teacher_slots,
        headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
        data: { ...payload, iana_timezone: getCookie("time_zone"), "timezone": -330 }
    }).then(response => {
        // console.log(payload,"................calling")
        return response;
    });

  export const post_request_repalcement = payload =>
	axios({
		method: 'post',
		url: urls.post_request_repalcement,
		headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
		data: payload
	}).then(response => {
    // console.log(payload,"................calling")
		return response;
	});


  export const add_avalability_slots_data = payload =>
	axios({
		method: 'post',
		url: urls.add_teacher_slots,
		headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
		data: { ...payload, iana_timezone: moment.tz.guess(true) }
	}).then(response => {
    // console.log(payload,"................calling")
		return response;
	});

export const delete_avalability_slots_data = payload =>
    axios({
        method: 'post',
        url: urls.delete_teacher_slots,
        headers: { Authorization: 'Bearer ' + getCookie('teacher_token_1') },
        data: { ...payload, iana_timezone: getCookie("time_zone") }
    }).then(response => {
        // console.log(payload,"................calling")
        return response;
    });
