import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './spinner.css';

class Spinner extends Component {
	state = {
		dot_selected_index: 0,
		loop_running: true
	};
	componentWillUnmount() {
		this.setState({ loop_running: false });
	}
	change_dot_index = () => {
		if (this.state.loop_running) {
			this.setState({
				dot_selected_index: (this.state.dot_selected_index + 1) % 3
			});
			setTimeout(() => {}, 200);
		}
	};
	get_prev_index = index => (index === 0 ? 2 : index - 1);
	get_dot_index = index =>
		index === this.state.dot_selected_index
			? `big-dot`
			: index === this.get_prev_index(this.state.dot_selected_index)
			? 'medium-dot'
			: null;
	render() {
		if (this.props.type === 'circle-fullpage') {
			return (
				<div className="custom-spinner-full-page">
					<div
						className="spinner-reusable"
						style={{
							height: `${this.props.circleRadius}px`,
							width: `${this.props.circleRadius}px`,
							borderRadius: `${this.props.circleRadius}px`,
							border: `${this.props.borderLength}px solid`,
							borderColor: this.props.largeBorderColor,
							borderTop: `${this.props.borderLength}px solid ${this.props.smallBorderColor}`
						}}
					/>
				</div>
			);
		} else if (this.props.type === 'circle') {
			return (
				<div
					className="spinner-reusable"
					style={{
						height: `${this.props.circleRadius}px`,
						width: `${this.props.circleRadius}px`,
						borderRadius: `${this.props.circleRadius}px`,
						border: `${this.props.borderLength}px solid`,
						borderColor: this.props.largeBorderColor,
						borderTop: `${this.props.borderLength}px solid ${this.props.smallBorderColor}`
					}}
				/>
			);
		} else {
			return (
				<div className="spinner-dots">
					<div className="spinner-dots-circle" id="dot1" />
					<div className="spinner-dots-circle" id="dot2" />
					<div className="spinner-dots-circle" id="dot3" />
				</div>
			);
		}
	}
}

Spinner.propTypes = {
	type: PropTypes.string,
	circleRadius: PropTypes.number,
	borderLength: PropTypes.number,
	smallBorderColor: PropTypes.string,
	largeBorderColor: PropTypes.string
};

Spinner.defaultProps = {
	type: 'circle',
	circleRadius: 100,
	borderLength: 4,
	smallBorderColor: '#f3f3f3',
	largeBorderColor: '#4332DF'
};

export default Spinner;
