import React, { useState, useEffect } from 'react';
import { NetworkSignalOffline, NetworkSignalStable, NetworkSignalUnStable } from '../../livekit_assets/svg';
import NetworkUnstablePopup from './NetworkUnstablePopup';

const NetworkConnectionStatus = ({ status, showNetworkPopup=false }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(true);

    const [backgroundColorClass, setBackgroundColorClass] = useState('offline-background');
    const [svg, setSvg] = useState(<NetworkSignalOffline />);
    const [title, setTitle] = useState('Your connection is offline');
    const [description, setDescription] = useState('You will experience some issues with your audio and video');

    useEffect(() => {
        switch (status) {
            case 'stable':
                setBackgroundColorClass('stable-background');
                setSvg(<NetworkSignalStable />);
                setTitle('Your connection is stable');
                setDescription('Your audio and video should be working fine');
                break;
            case 'unstable':
                setBackgroundColorClass('unstable-background');
                setSvg(<NetworkSignalUnStable />);
                setTitle('Your connection is unstable');
                setDescription('You may experience some issues with your audio and video');
                break;
        }
    }, [status]);

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <>
            {status === 'unstable' && isPopupVisible ? (
                <NetworkUnstablePopup onClose={handleClosePopup} />
            ) : showNetworkPopup ? (
                <div className={`connection-container ${backgroundColorClass}`}>
                    <div className='connection-section-one'>
                        <div>{svg}</div>
                        <div className='connection-title'>{title}</div>
                        <div className='connection-description'>{description}</div>
                    </div>
                    { window.ReactNativeWebView === undefined &&
                        <>
                         <div className='connection-seperator'></div>
                        <div className='contact-support'>
                            <span>Still having trouble?</span>
                            {/* <a href='/contactus' target='_blank'>
                                &nbsp;Contact support
                                </a> */}
                            <a href='/contactus' target='_blank'>
                                &nbsp;Contact support
                            </a>
                        </div>
                        </>
                    }
                   
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default NetworkConnectionStatus;
