import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditRoadMapPop from './edit_popup';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class SessionRecap extends Component {
  state = {
    loading: true,
    questions: [],
    pop_prevent_user_info: false,
    remaining_ans: null,
    is_read_more: false,
    showAddAnswer: false,
    selectedIndex: 0,
    selectedAnswer: '',
    session_uuid: '',
    values_changed: false
  };

  componentDidMount() {
    sendEventToClarity();
    if (this.props.my_sessions_status === 'none') {
      this.props.router.navigate('/mysessions');
    }
    try {
      const loc_state = this.props.router.location.state;
      this.setState({
        questions: loc_state.session_questions,
        session_uuid: loc_state.session_uuid,
        loading: false
      });
    } catch (e) {
      this.props.router.navigate('/mysessions');
    }
  }

  getAnsweredQuestionCount = () => this.state.questions.filter((item) => !!item.answer).length;

  closeAddAns = () => this.setState({ showAddAnswer: false });

  updateMySessionData = () => this.setState({ showAddAnswer: false });

  goBack = () => tthis.props.router.navigate(-1);

  closePop_prevent = () => this.setState({ pop_prevent_user_info: false });

  updateAnswerValue = (answer) => {
    const {questions} = this.state;
    questions[this.state.selectedIndex].answer = answer;
    this.setState({ questions, values_changed: true, showAddAnswer: false }, () => {
      const {my_sessions} = this.props;
      const my_index = my_sessions.findIndex((m) => m.session_uuid === this.state.session_uuid);
      const completed_text = `${this.getAnsweredQuestionCount()}/2 completed`;
      const new_session = {
        ...my_sessions[my_index],
        session_questions: this.state.questions,
        session_recap_text: completed_text
      };
      const new_sessions = [
        ...my_sessions.slice(0, my_index),
        new_session,
        ...my_sessions.slice(my_index + 1)
      ];
      this.props.set_home_variable('my_sessions', new_sessions);
    });
  };

  goBackCondition = () => {
    const { questions } = this.state;
    const questionAnswered = this.getAnsweredQuestionCount();
    const totalQuestions = questions !== null && questions !== 'undefined' ? questions.length : null;
    const remaining_ans = (totalQuestions - questionAnswered).toString();
    if (questionAnswered == totalQuestions) {
      this.props.router.navigate(-1);
    } else {
      this.setState({
        pop_prevent_user_info: true,
        remaining_ans
      });
    }
  };

  onEditPressed = (selectedItem, selectedIndex) => {
    this.setState({
      selectedIndex,
      showAddAnswer: true,
      selectedAnswer: selectedItem.answer
    });
  };

  renderRoadMapQuestion = (item, index) => {
    const answer = '';
    const subText =
      item.question == 'Teacher notes?'
        ? '* This won’t be shared with student'
        : '* This will be shared with student';
    const item_answer = !this.state.is_read_more ? item.answer.slice(0, 100) : item.answer;
    const answer_read_text = !this.state.is_read_more ? `... Read more` : 'Read less';
    return (
      <li className={item.answer ? 'rm_green_yes' : null}>
        <div className="rm-edit-ques-cont">
          {!!item.answer && <span className="rm_green_right" />}
          <div className="edit-ques">
            {item.question}
            <p>{subText}</p>
          </div>
          {item.answer != '' ? (
            <div className="edit-ans">
              {!!item.answer && item.answer.length > 100 ? (
                <>
                  {item_answer}
                  <span className="yj-read-more" onClick={this.toggle_read_more}>
                    {answer_read_text}
                  </span>
                </>
              ) : (
                item.answer
              )}
              <button className="btn rm_edit_btn" onClick={() => this.onEditPressed(item, index)}>
                Edit
              </button>
            </div>
          ) : (
            <button className="btn add-rm-ans-btn" onClick={() => this.onEditPressed(item, index)}>
              Add answer
            </button>
          )}
        </div>
      </li>
    );
  };

  render() {
    const { questions, session_uuid, selectedIndex, pop_prevent_user_info, remaining_ans } =
      this.state;
    return (
      <div className="m-card-body">
        <div className="m-card">
          <div className="m-card-header">
            <div className="m-card-head-wrap">
              <button className="btn btn-mcard-back" onClick={this.goBackCondition}>
                <img
                  alt="back-arrow"
                  src="https://s3.amazonaws.com/images.myyogateacher.com/icons/back-arrow-white1.svg"
                />
              </button>

              <h1>
                {this.getAnsweredQuestionCount()} / {this.state.questions.length} completed
              </h1>
              <p>Session questions - It helps to create student roadmap</p>
            </div>
          </div>
          <div className="rm-c-edit-cont">
            <ul className="rm-edit-ques-ul">{questions.map(this.renderRoadMapQuestion)}</ul>
          </div>
        </div>

        {this.state.showAddAnswer ? (
          <EditRoadMapPop
            onClose={this.closeAddAns}
            selectedQuestion={questions[selectedIndex]}
            ques_item={questions[selectedIndex]}
            sessionUuid={session_uuid}
            updateMySessionData={this.updateMySessionData}
            updateAnswerValue={this.updateAnswerValue}
          />
        ) : null}

        {pop_prevent_user_info ? (
          <div className="pop_prevent_pop_outer">
            <div className="pop_prevent_in">
              {remaining_ans} more roadmap questions remaining.
              <div className="pop_prevent_btn_row">
                <button className="p_prev_skip_btn" onClick={this.goBack}>
                  Skip
                </button>
                <button className="p_prev_ok_btn" onClick={this.closePop_prevent}>
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    my_sessions: selectors.get_my_sessions(state),
    my_sessions_status: selectors.get_my_sessions_status(state)
  });

const mapDispatchToProps = (dispatch) => ({
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    }
  });

const SessionRecapWithRedux = connect(mapStateToProps, mapDispatchToProps)(SessionRecap);
const SessionRecapWithRouter = withRouter(SessionRecapWithRedux);

export default SessionRecapWithRouter;
