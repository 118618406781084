import axios, { AxiosPromise, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { getCookie, utm_cookies, deviceInfo } from '../utils';

export function post_api<Type>(
  url: string,
  payload: any,
  token: boolean = false,
  type?: string,
  onUploadProgress?: () => void
): Promise<AxiosResponse<Type>> {
  const headers: AxiosRequestHeaders = {};

  if (token) {
    headers.Authorization =
      'Bearer ' +
      (getCookie('teacher_token_1') || (url.includes('/teacher_consult/') ? getCookie('teacher_token_1') : getCookie('impersonationFromTeacherToken')));
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }

  let data = {};

  if (type === 'formdata') {
    data = payload;
  } else {
    data = { ...payload, ...utm_cookies(), ...deviceInfo() };
  }
  return axios({
    method: 'post',
    url: url,
    headers: headers,
    data,
    onUploadProgress,
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function delete_api<Type>(
  url: string,
  payload: any,
  token = false,
  type = 'none',
  onUploadProgress: () => void
): AxiosPromise<Type> {
  const headers: AxiosRequestHeaders = {};
  if (token) {
    headers.Authorization =
      'Bearer ' +
      (getCookie('student_token') ||
        (url.includes('/teacher_consult/')
          ? getCookie('teacher_token_1')
          : getCookie('impersonationFromTeacherToken')));
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }

  let data = {};

  if (type === 'formdata') {
    data = payload;
  } else {
    data = { ...payload, ...utm_cookies(), ...deviceInfo() };
  }

  return axios({
    method: 'delete',
    url: url,
    headers: headers,
    data,
    onUploadProgress,
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function put_api<Type>(
  url: string,
  payload: any,
  token = false,
  type = 'none'
): AxiosPromise<Type> {
  const headers: AxiosRequestHeaders = {};
  if (token) {
    headers.Authorization =
      'Bearer ' +
      (getCookie('student_token') ||
        (url.includes('/teacher_consult/') || getCookie('teacher_token_1')
          ? getCookie('teacher_token_1')
          : getCookie('impersonationFromTeacherToken')));
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }

  let data = {};

  if (type === 'formdata') {
    data = payload;
  } else {
    data = { ...payload, ...utm_cookies(), ...deviceInfo() };
  }

  return axios({
    method: 'put',
    url: url,
    headers: headers,
    data,
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function get_api<Type>(url: string, token = false): AxiosPromise<Type> {
  const headers: AxiosRequestHeaders = {};

  if (token) {
    headers.Authorization =
      'Bearer ' +
      (getCookie('teacher_token_1') || (url.includes('/teacher_consult/') ? getCookie('teacher_token_1') : getCookie('impersonationFromTeacherToken')));
  }
  return axios({
    method: 'get',
    url: url,
    headers: headers,
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function patch_api<Type>(
  url: string,
  payload: any,
  token = false,
  type = 'none'
): AxiosPromise<Type> {
  const headers: AxiosRequestHeaders = {};

  if (token) {
    headers.Authorization =
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdHVkZW50X3V1aWQiOiI2ODQ5ZGJmOS04OWQxLTQzYzEtODVkOC0wMGY5MWExMDMzMDUiLCJpbXBlcnNvbmF0ZV90b2tlbiI6ZmFsc2UsInNlcnZpY2VfdXVpZCI6IiIsImlhdCI6MTY0ODA1OTMxMCwiZXhwIjoxNjc5NTk1MzEwfQ.6O5sEWxqJ7Q6vUa0OplQUzyqPBvI67nw0_QJK_qBYQw';
  }
  if (type === 'formdata') {
    headers['content-type'] = 'multipart/form-data';
  }

  let data = {};

  if (type === 'formdata') {
    data = payload;
  } else {
    data = { ...payload, ...utm_cookies(), ...deviceInfo() };
  }

  return axios({
    method: 'patch',
    url: url,
    headers: headers,
    data,
  })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}
