/* 
    vid_mode    ==  1 //only teacher
    vid_mode    ==  0 //unknown, all
*/

export const VideoMode = {
  Public: 0,
  Teacher: 1
};

export const JoinIdentity = {
  Main: 'main_token',
  Aux: 'aux_token'
};

export const DeviceMode = {
  Main: 0,
  Aux: 1
};

export const RecordingStatus = {
  Unknown: 0,
  OnGoing: 1,
  WillStart: 2,
  Disabled: 3
};

export const AccessSessionClipStudents = [
  '508141e7-5326-4d53-8deb-62adbdd42be7',
  '3d2c3596-5885-4369-b00c-0873805e4e56',
  '8b7b4e41-6a7c-40df-9fe4-dac18d5df290'
];
