import React, { useState } from 'react';
import { post_request_repalcement } from '../../../redux/api_functions/availability_api';
import Loader from '../../new_teacher_wesite/components/loader';
import { removeCookie, analytics_reset } from '../../../util_functions';
import { useNavigate } from 'react-router-dom';

const ReplacementSlots = (props) => {
	const navigate = useNavigate();

	const [getReplacementData, SetReplacementData] = useState(props.replacementData);
	const [getCustomInput, SetCustomInput] = useState('');
	const [reasonList, setReasonList] = useState('');
	const [isLoading, setIsloading] = useState(false);
	const [getErrMsg, setErrMsg] = useState(false);
	const [reason, setReason] = useState([
		{
			id: 1,
			reasonItem: 'Internet Outage - weather related',
			checked: false,
		},
		{
			id: 2,
			reasonItem: 'Power Outage - weather related',
			checked: false,
		},
		{
			id: 3,
			reasonItem: 'Power Outage',
			checked: false,
		},
		{
			id: 4,
			reasonItem: 'Feeling Unwell',
			checked: false,
		},
		{
			id: 5,
			reasonItem: 'Medical Emergency',
			checked: false,
		},
		{
			id: 6,
			reasonItem: 'Family Emergency',
			checked: false,
		},
		{
			id: 7,
			reasonItem: 'Hardware Issues',
			checked: false,
		},
		{
			id: 8,
			reasonItem: 'Urgent unplanned reason',
			checked: false,
		},
	]);

	const submitData = () => {
		if (reasonList !== '' || getCustomInput !== '') {
			let payload = {
				session_uuid: getReplacementData.session_uuid,
				reason_for_replacement: reasonList,
				details_for_replacement: getCustomInput,
			};
			// console.log("this is payload", payload);
			setIsloading(true);
			post_request_repalcement(payload)
				.then((response) => {
					if (response?.data?.status === 'success') {
						// console.log("this is api get response 1", response);
						if (getReplacementData.lengthData === true) {
							props.closeReplacementPopupSingleCall();
							setIsloading(false);
						} else {
							props.closeReplacementPopup();
							setIsloading(false);
						}
					}
				})
				.catch((e) => {
					console.log(JSON.stringify(e));
					if (e.response.status === 401) {
						removeCookie('teacher_token_1');
						localStorage.removeItem('teacherLocalChannels');
						analytics_reset();
						navigate('/login');
						props.addDataEditSlots(true);
						setIsloading(false);
					} else if (e.response.status === 400) {
						props.addDataEditSlots(true);
						setIsloading(false);
					}
				});
		} else {
			setErrMsg(true);
		}
	};

	const getReason = (reasonData) => {
		setReasonList(reasonData.reasonItem);
		setErrMsg(false);
	};

	const customInput = (data) => {
		SetCustomInput(data.target.value);
		setErrMsg(false);
	};

	// console.log('tis isf s f;s f;s', getReplacementData);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<div className='relative inset-0 z-index-max'>
					<div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
						<div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
							<div className='flex justify-end cursor-pointer absolute top-1 right-1'>
								<div onClick={props.closeReplacementPopup}>
									<svg
										width='40'
										height='40'
										viewBox='0 0 34 34'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<rect width='34' height='34' rx='17' fill='none' />
										<path
											d='M22 12L12 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M12 12L22 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</div>
							<div className='mb-0'>
								<div className='flex justify-start flex-col items-start'>
									<div className='w-full'>
										<div className='flex justify-center items-center'>
											<div className='text-17 font-semibold'>Replacement reason</div>
										</div>
										<div className='mt-5 border-b h-44 overflow-x-scroll'>
											{reason.map((list) => {
												return (
													<>
														<label key={list?.id} htmlFor={list?.id}>
															<div className='flex justify-start items-center mt-3 mb-3'>
																<div className='custom_radio'>
																	<input
																		type='radio'
																		id={list.id}
																		onClick={() => getReason(list)}
																		name='featured'
																		// checked={isItemChecked}
																	/>
																	<label htmlFor={list.id}></label>
																</div>
																<label
																	htmlFor={list?.id}
																	className='text-base text-mytTeacherTextGray cursor-pointer'
																>
																	{list?.reasonItem}
																</label>
															</div>
														</label>
													</>
												);
											})}
										</div>
										<div className='mt-3'>
											<div className='text-sm'>Any other reason?</div>
											<div>
												<textarea
													className='formInput min-h-24 mt-1 p-18px'
													autoFocus
													onChange={(data) => customInput(data)}
												></textarea>
											</div>
										</div>
										{getErrMsg === true && (
											<div className='text-mytRedText2 text-sm font-medium mt-3'>
												Please Select Reason for Replace
											</div>
										)}
										<div className='mt-5 flex flex-col justify-center items-center mt-4'>
											<button className='btn btn-primary mb-3' onClick={submitData}>
												Request a replacement
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ReplacementSlots;
