import React from 'react';
import PropTypes from 'prop-types';
import './custom_sigle_select.css';

const CustomSelect = props => {
  return (
    <div className="flex flex-col-reverse">
      <select className='input-date w-full rounded-lg text-15 font-medium shadow-sm flex items-center formInput border react-datepicker-ignore-onclickoutside' name={props.name} value={props.value} onChange={props.onChange} style={props.customStyle}>
        <option value="" selected disabled>
          {props.defaultOption}
        </option>
        {props.options.map(option => {
          return (
            <option value={option.value} key={option.value + option.label}>
              {option.label}
            </option>
          );
        })}
      </select>
      {props.noLabel ? null : (
        <label className='font-medium text-mytLabel text-13.5 mt-25px mb-8px' style={props.labelStyle}>
          {props.label}:
        </label>
      )}
    </div>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultOption: PropTypes.string,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

CustomSelect.defaultProps = {
  label: 'Enter Label',
  name: 'custom_select',
  defaultOption: 'Select an option',
  style: {
    width: '200px'
  },
  placeholder: 'Enter text',
  options: [
    { value: 1, label: 'one' },
    { value: 2, label: 'two' }
  ],
  noLabel: false
};

export default CustomSelect;
