import Cookies from 'js-cookie';

export const getCookie = (cookie_name) => {
	return Cookies.get(cookie_name);
};

export const setCookie = (cookie_name, value, age = 5184000) => {
	Cookies.set(cookie_name, value, {
		expires: age / 864e2,
		path: '/',
		domain: '.myyogateacher.com',
		secure: true,
		sameSite: 'Strict',
	});
};

export const removeCookie = (cookie_name) => {
	Cookies.remove(cookie_name, { path: '/', domain: '.myyogateacher.com' });
	Cookies.remove('gc_monetization_tag', {
		path: '/',
		domain: '.myyogateacher.com',
	});
	Cookies.remove('gc_monetization_url', {
		path: '/',
		domain: '.myyogateacher.com',
	});
};

const kv = {
	myt_utm_source: 'utm_source',
	myt_utm_medium: 'utm_medium',
	myt_utm_campaign: 'utm_campaign',
	myt_utm_content: 'utm_content',
	myt_utm_term: 'utm_term',
};

export const utm_cookies = () => {
	let utm_cookies = {};

	Object.keys(kv).forEach((k) => {
		let cookie = getCookie(k);
		if (cookie) utm_cookies[kv[k]] = cookie;
	});

	return utm_cookies;
};
