import React, { useEffect, useState } from 'react';
import PopupLayout from '../../../common/PopupLayout';
import HtmlReactParser from 'html-react-parser';
import { numberToOrdinal } from '../../../util_functions';

const ConciergeNotesPopup = ({ onClose, onClick, div_session_uuid, data, session_attending}) => {

  const gettingToknowNotes = data?.student_additional_info;
  const firstOneOnOneSessionNotes = data?.first_one_on_one_session_notes;

    let sessionRecaps = ''
    let teacherNotes = ''
    if(firstOneOnOneSessionNotes?.length !== 0){
        sessionRecaps = firstOneOnOneSessionNotes[0]?.session_recap
        teacherNotes = firstOneOnOneSessionNotes[0]?.teacher_notes
    }

    const showFirstOneOnOneSessionNotes = sessionRecaps !== '' || teacherNotes !== '';
    
  return (
    <PopupLayout customClass="md:w-[492px]" onClose={onClose}>
      <div className="px-25px">
        {gettingToknowNotes !== '' && (
          <>
            <div className="text-19px leading-22px font-semibold font-inter text-mytgrey-subText">
              Getting to know Student better
            </div>
            <div className="conciergeNotesStyle pt-15px pb-10px text-15px font-medium leading-27px text-mytgrey-lite mb-10px break-words">
              {gettingToknowNotes && HtmlReactParser(gettingToknowNotes)}
            </div>
          </>
        )}
        {firstOneOnOneSessionNotes?.length > 0 && session_attending === 2 && showFirstOneOnOneSessionNotes && (
          <>
            <div className="text-19px leading-22px font-semibold font-inter text-mytgrey-subText">
              First 1-on-1 Trial Session Notes
            </div>
            {firstOneOnOneSessionNotes.map((notes, index) => (
              <div key={index} className="conciergeNotesStyle pt-15px pb-10px flex flex-col break-words">
                {notes?.session_recap ? <p>Session Recap : {HtmlReactParser(notes?.session_recap)}</p> : <></>}
                {notes?.teacher_notes ? <p>Teacher Notes : {HtmlReactParser(notes?.teacher_notes)}</p> : <></>}
              </div>
            ))}
          </>
        )}
        {session_attending > 2 && (data?.teacher_session_notes !== '' && data?.teacher_session_notes !== undefined) &&
        <>
          <div className="text-19px leading-22px font-semibold font-inter text-mytgrey-subText">
              {numberToOrdinal(session_attending - 1)} 1-on-1 Trial Session Notes
          </div>
          <div className="conciergeNotesStyle pt-15px pb-10px flex flex-col text-mytgrey-lite break-words">
            {data?.teacher_session_notes}
          </div>
        </>
        }
        <div className="sticky bottom-0 pb-25px bg-white">
          <div
            className="btn btn-green h-46px cursor-pointer mt-15px w-full"
            data-div_session_uuid={div_session_uuid}
            onClick={onClick}
            >
            Join Now
          </div>
        </div>
      </div>
    </PopupLayout>
  );
};

export default ConciergeNotesPopup;
