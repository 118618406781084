import React, { useEffect } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { sendEventToClarity } from '../../../../util_functions';

function CareerPost() {
  useEffect(() => {
    sendEventToClarity();
  }, []);

  return (
    <>
      <Header />
      <div className="relative mx-auto sm:mt-16 zm:mt-8 zm:px-5 md:px-0  pb-20">
        <div className="lg:max-w-5xl md:container sm:w-full mx-auto zm:pl-4 zm:pr-0 sm:px-6">
          <div>
            <h1>Director, Brand Design</h1>
            <p>Fulltime San Francisco HQ or remote</p>
          </div>
          <div className="mt-20">
            <h2 className="mb-4">As a Director, Brand Design, you’ll … </h2>
            <p className="mb-4">
              - Directly manage, develop, and grow a group of brand designers focused on web and
              creative design
            </p>
            <p className="mb-4">- Help define myYogaTeacher’s brand strategy with leadership</p>
            <p className="mb-4">
              - Provide strategic direction for team members to be autonomous in executing campaigns
              and contribute to the evolution of the myYogaTeacher brand
            </p>
            <p className="mb-4">
              - Be an active member of our designer leadership team — that includes product design
              and user research — to foster a psychologically safe and creative environment
            </p>
            <p className="mb-4">
              - Promote and cultivate diversity, equity, and inclusion, and include it in your
              team’s roadmap
            </p>

            <p className="mb-4">
              - That said, these responsibilities are just the start! At myYogaTeacher, we encourage
              you to contribute wherever your interests take you and shape your role accordingly.
              And this isn’t just a philosophical bent: we actually give you 4 hours a week (10% of
              the work week) to pursue passion projects outside of your role responsibilities.{' '}
            </p>
            <svg
              width="40"
              height="6"
              viewBox="0 0 50 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 3.50136C3.32034 2.49824 4.94042 1.96976 6.5983 2.00136C11.1983 2.00136 11.1983 5.00136 15.7983 5.00136C20.3983 5.00136 20.3983 2.00136 24.9983 2.00136C29.5983 2.00136 29.5983 5.00136 34.1989 5.00136C38.7995 5.00136 38.8004 2.00136 43.4019 2.00136C45.0596 1.96949 46.6796 2.49758 48 3.50026"
                stroke="#EE731B"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="mt-20">
            <h2 className="mb-4">About you</h2>
            <p className="mb-4">
              - Have experience leading in-house brand design teams at scaling companies
            </p>
            <p className="mb-4">
              - Experience building out in-house design operations and partnerships with external
              agencies/contractors
            </p>

            <p className="mb-4">
              - Strong communicator and collaborator; experience working with executives and leaders
            </p>

            <p className="mb-4">
              - Passion for mentoring and coaching creative teams, as well as attracting new talent
            </p>

            <p className="mb-4">
              - Thrive in an environment of cross-functional collaboration. This role works closely
              with Marketing, Education, Sales and Customer Success, and Leadership
            </p>

            <p className="mb-4">
              - Experience in content development for various marketing channels: illustration,
              video, text, digital ads, events, etc.
            </p>

            <p className="mb-4">- Have experience leading remote design teams</p>

            <p className="mb-8">
              - If you don’t meet 100% of the above qualifications, you should still seriously
              consider applying. Studies show that you can still be considered for a role if you
              meet just 50% of the role’s requirements.
            </p>

            <svg
              width="40"
              height="6"
              viewBox="0 0 50 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 3.50136C3.32034 2.49824 4.94042 1.96976 6.5983 2.00136C11.1983 2.00136 11.1983 5.00136 15.7983 5.00136C20.3983 5.00136 20.3983 2.00136 24.9983 2.00136C29.5983 2.00136 29.5983 5.00136 34.1989 5.00136C38.7995 5.00136 38.8004 2.00136 43.4019 2.00136C45.0596 1.96949 46.6796 2.49758 48 3.50026"
                stroke="#EE731B"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="mt-20">
            <h2 className="mb-4">About you</h2>
            <p className="mb-4">
              At myYogaTeacher, we believe that our success will be defined not only by what we do —
              but also by how and why we do it. So, here is the myYogaTeacher “why” and our “how”:
            </p>
            <div className="font-semibold text-base text-black mt-10">
              {' '}
              Our dual missions — one for the world, one for us
            </div>
            <p className="mb-4">
              For the world: To empower everyone to create for the web and spark an unprecedented
              wave of digital innovation. For ourselves: Lead fulfilling, impactful lives.
            </p>
            <div className="font-semibold text-base text-black mt-10">
              Our core behaviors (how we act)
            </div>
            <p className="mb-2">Start with customers</p>
            <p className="mb-2">Practice extraordinary kindness</p>
            <p className="mb-2">Be radically candid</p>
            <p className="mb-2">Move intentionally fast</p>
            <p className="mb-2">Just fix it</p>
            <p className="mb-2">Lead by serving others</p>
            <p className="mb-2">Dream big</p>
            <div className="font-semibold text-base text-black mt-10">Our commitments to you</div>
            <p className="mb-2">
              We’ll pay you! This is a full-time, salaried position that includes equity
            </p>

            <p className="mb-2">
              We’ll invest in your physical and mental well-being with health, dental, and vision
              benefits and a monthly stipend for health and wellness expenses
            </p>

            <p className="mb-2">
              We’ll pay you to take a vacation … seriously. We’ll give you a $1,000 bonus for taking
              your first vacation with us that is more than 5 days
            </p>

            <p className="mb-2">We offer flexible parental leave</p>

            <p className="mb-2">
              We provide remote employees with the equipment they need to create a great remote work
              environment
            </p>

            <p className="mb-2">
              We will offer you the support you need to help you grow as an impactful Director,
              Brand Design and a human being
            </p>

            <p className="mb-2">Ready to apply?</p>

            <p className="mb-8">
              If you share our values and our enthusiasm for empowering the world, we’d love to
              review your application!
            </p>
            <svg
              width="40"
              height="6"
              viewBox="0 0 50 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 3.50136C3.32034 2.49824 4.94042 1.96976 6.5983 2.00136C11.1983 2.00136 11.1983 5.00136 15.7983 5.00136C20.3983 5.00136 20.3983 2.00136 24.9983 2.00136C29.5983 2.00136 29.5983 5.00136 34.1989 5.00136C38.7995 5.00136 38.8004 2.00136 43.4019 2.00136C45.0596 1.96949 46.6796 2.49758 48 3.50026"
                stroke="#EE731B"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="bg-mytorange-fade rounded-md mt-16 p-5">
            <h1>Apply Now</h1>
            <p className="text-mytBrown font-medium py-4">
              At myYogaTeacher, we believe that our success will be defined not only by what we do —
              but also by how and why we do it. So, here is the myYogaTeacher “why” and our
              “how”:e’d love to review your application!
            </p>
            <button className="btn btn-orange">Apply for this job</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CareerPost;
