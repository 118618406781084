import * as t from "../action_types";

var initialState = {
  dashboard_loading: true,
  sessions_in_next_24_hours: 0,
  availability_in_next_7_days: 0,
  sessions: [],
  session_cancel_status: "none",
  student_details: "",
  student_loading_status: "none",
  schedule_session_with_student_status: 'none',
  schedule_session_with_student_response: {},
  schedule_session_with_student_err_msg: '',
  get_session_update_slots_status: 'none',
  session_update_slots: [],
  session_list_status: 'none',
  change_session_time_status: 'none',
  change_session_time_err_msg: '',
  loadMoreSessions: '',
  account_status_message: '',
  cancel_session_status: 'none'
};

const set_single_variable = (state, payload) => {
  return {
    ...state,
    [payload.key]: payload.payload
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case t.set_dashboard_variable:
      return set_single_variable(state, action.payload);
    default:
      return state;
  }
}
