import React, { Component } from 'react';
import { connect } from 'react-redux';
import { analytics_track, get_time_taken, sendEventToClarity } from '../../../../util_functions';
import Loader from '../../components/loader';
import * as selectors from '../../../../redux/selectors';
import * as actions from '../../../../redux/action_creators';
import { withRouter } from '../../../../utils/router';

class Reset extends Component {
  state = {
    name: '',
    email: '',
    email_error: false,
    email_error_msg: '',
    name_error: false,
    location: '',
    password: '',
    window_width: 0,
    type: 'password'
  };

  componentDidMount() {
    sendEventToClarity();
  }

  track_fn = (name) => {
    const options = {
      referrer_component: 'home',
      plan: 'guest',
      time_since_page_load: get_time_taken(this.props.start_time)
    };
    analytics_track(name, options);
  };

  validate_email = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = re.test(String(email).toLowerCase());
    return result;
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, [`${e.target.name}_error`]: false });
  };

  forgot_password = () => {
    if (this.props.forgot_status !== 'loading') {
      const { email } = this.state;
      if (email.trim() === '') {
        this.setState({ email_error: true, email_error_msg: 'This field cannot be empty' });
      } else if (!this.validate_email(email)) {
        this.setState({ email_error: true, email_error_msg: 'Please enter valid email' });
      } else {
        this.track_fn('forgot password submit click');
        this.props.forgot_password({
          email
        });
      }
    }
  };

  render() {
    return (
      <div className="md:flex h-screen w-screen">
          <div className="loginSignupLeftContainer">
            <div className="loginSignupTopSection">
              <div
                className="breadcrumbBackContainer"
                onClick={() => this.props.router.navigate('/')}
              >
                <img
                  src="https://images.myyogateacher.com/web_images/web_prod/arrowLeft.png"
                  alt="arrowLeft"
                  className="w-2 h-3"
                />
                <div
                  className="breadcrumbBackContent"
                  onClick={() => this.props.router.navigate('/')}
                >
                  Home
                </div>
              </div>
              <div className="breadcrumbBackContentLogo">
                <img
                  src="https://images.myyogateacher.com/MYT_Logo.svg"
                  alt="MyYogaTeacher_Logo"
                  className="breadcrumbBackContentLogoImg"
                />
                <div
                  className="breadcrumbBackContainerMobile"
                  onClick={() => this.props.router.navigate('/')}
                >
                  <div
                    className="breadcrumbBackContent"
                    onClick={() => this.props.router.navigate('/')}
                  >
                    Home
                  </div>
                </div>
              </div>
            </div>
            <div className="loginSignupMidSection">
              <div className="loginSignupHeadingContainer">
                <div className="loginSignupHeading">Reset Password</div>
                <div className="text-mytgrey-lite text-base tracking-tight mt-3">
                  Enter your email address to reset your password
                </div>
                <div className="my-6 zm:my-25px">
                  <label className="loginSignupFormLabel">Email</label>
                  <input
                    type="email"
                    className={`formInput pr-10 py-5 ${
                      this.state.email_error ? 'formInputError' : null
                    }`}
                    id="inputEmail"
                    required=""
                    name="email"
                    onChange={this.handleChange}
                    onKeyPress={this.prevent_enter}
                    value={this.state.email}
                  />
                  {this.state.email_error ? (
                    <div className="error">
                      <div className="loginSignupErrorMsg">{this.state.email_error_msg}</div>
                    </div>
                  ) : null}
                </div>
                <button className="loginSignupBtn" onClick={this.forgot_password}>
                  Reset Password
                  {this.props.forgot_status === 'loading' ? (
                    <div className="btn-purple-loader pl-2 ">
                      <Loader />
                    </div>
                  ) : null}
                </button>
                {this.props.forgot_status === 'success' ? (
                  <>
                    <br />
                    <p className="text-orange-default text-lg font-medium">
                      Please check your email and follow the link to change your password
                    </p>
                  </>
                ) : null}
              </div>
              <div className="mt-12">
                <div className="text-mytgrey-lite zm:text-14.5 pt-4 zm:mt-2 zm:mb-10 lg:mt-4">
                  Back to{' '}
                  <span
                    className="font-semibold hover:underline text-black cursor-pointer"
                    onClick={() => this.props.router.navigate('/login')}
                  >
                    Login
                  </span>
                </div>
              </div>
            </div>
            <div className="loginSignupBottomSection" />
          </div>
          <div className="loginSignupRightImage" />
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
    forgot_status: selectors.get_forgot_status(state),
    forgot_error_msg: selectors.forgot_error_msg(state)
  });

const mapDispatchToProps = (dispatch) => ({
    forgot_password: (payload) => dispatch(actions.forgot_password(payload))
  });

const ResetWithRedux = connect(mapStateToProps, mapDispatchToProps)(Reset);
const ResetWithRouter = withRouter(ResetWithRedux);

export default ResetWithRouter;
