import React, { Component } from 'react';
import { connect } from 'react-redux';
import { analytics_track, get_time_taken, sendEventToClarity } from '../../../../util_functions';
import Spinner from '../../../util_components/spinner';
import * as selectors from '../../../../redux/selectors';
import * as actions from '../../../../redux/action_creators';
import { withRouter } from '../../../../utils/router';

class Login extends Component {
  state = {
    name: '',
    email: '',
    email_error: false,
    email_error_msg: '',
    password_error: false,
    name_error: false,
    location: '',
    password: '',
    show_signup: false,
    window_width: 0,
    type: 'password'
  };

  inputRef = React.createRef();

  componentDidMount() {
    sendEventToClarity();
    this.setState({ window_width: window.outerWidth });
    window.addEventListener('resize', this.updateDimensions, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ window_width: window.outerWidth });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.login_status === 'loading' && this.props.login_status === 'success') {
      this.track_fn('login success');
      window.location.replace('/home');
    }

    if (prevProps.sign_up_status === 'loading' && this.props.sign_up_status === 'success') {
      this.track_fn('signup success');
      this.setState({
        name: '',
        email: '',
        location: ''
      });
    }
    if (prevProps.forgot_status === 'loading' && this.props.forgot_status === 'success') {
      this.track_fn('forgot password success');
      this.setState({
        name: '',
        email: '',
        location: ''
      });
    }
  }

  track_fn = (name) => {
    const options = {
      referrer_component: 'home',
      plan: 'guest',
      time_since_page_load: get_time_taken(this.props.start_time)
    };
    analytics_track(name, options);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, [`${e.target.name}_error`]: false });
  };

  toggle_login = () => {
    this.setState({ show_signup: !this.state.show_signup });
  };

  validate_email = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = re.test(String(email).toLowerCase());
    return result;
  };

  log_in = () => {
    if (this.props.login_status !== 'loading') {
      const { email, password } = this.state;
      if (email.trim() === '') {
        this.setState({ email_error: true, email_error_msg: 'This field cannot be empty' });
      } else if (password.trim() === '') {
        this.setState({ password_error: true });
      } else if (!this.validate_email(email)) {
        this.setState({ email_error: true, email_error_msg: 'Please enter valid email' });
      } else {
        this.track_fn('login submit click');
        this.props.log_in_to_app({
          email,
          password
        });
      }
    }
  };

  handleClickPassword = () => {
    const tp = this.state.type;
    this.setState(({ type }) => ({
      type: type === 'text' ? 'password' : 'text'
    }));
  };

  render() {
    return (
      <div className="md:flex h-screen w-screen">
          <div className="loginSignupLeftContainer">
            <div className="loginSignupTopSection">
              <div
                className="breadcrumbBackContainer"
                onClick={() => this.props.router.navigate('/')}
              >
                <img
                  src="https://images.myyogateacher.com/web_images/web_prod/arrowLeft.png"
                  alt="arrowLeft"
                  className="w-2 h-3"
                />
                <div className="breadcrumbBackContent">Home</div>
              </div>
              <div
                className="breadcrumbBackContentLogo cursor-pointer"
                onClick={() => this.props.router.navigate('/')}
              >
                <img
                  src="https://images.myyogateacher.com/MYT_Logo.svg"
                  alt="MyYogaTeacher_Logo"
                  className="breadcrumbBackContentLogoImg"
                />
                <div
                  className="breadcrumbBackContainerMobile"
                  onClick={() => this.props.router.navigate('/')}
                >
                  <div className="breadcrumbBackContent">Home</div>
                </div>
              </div>
            </div>
            <div className="loginSignupMidSection">
              <div className="loginSignupHeadingContainer">
                <div className="loginSignupHeading">Teacher Login</div>
                <div className="my-3 zm:my-15px relative">
                  <label className="loginSignupFormLabel">Email</label>
                  <input
                    type="email"
                    className={`formInput mt-1 ${this.state.email_error ? 'formInputError' : ''}`}
                    name="email"
                    id="inputEmail"
                    required=""
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                  {this.state.email_error ? (
                    <div className="hp-form-group-error error-mdg-login-new">
                      <div className="loginSignupErrorMsg">{this.state.email_error_msg}</div>
                    </div>
                  ) : null}
                </div>
                <div className="my-6 zm:my-25px">
                  <div className="flex justify-between items-center">
                    <div className="loginSignupFormLabel">Password</div>
                    <div
                      className="loginSignupFormLabel hover:underline cursor-pointer text-black"
                      onClick={() => this.props.router.navigate('/reset-password')}
                    >
                      Forgot password?
                    </div>
                  </div>
                  <div className="relative">
                    <div className="relative mt-1">
                      <input
                        type={this.state.type}
                        className={`formInput pr-10 ${
                          !this.state.email_error &&
                          (this.state.password_error || this.props.login_status === 'fail')
                            ? 'formInputError'
                            : ''
                        }`}
                        name="password"
                        id="inputPassword"
                        required=""
                        value={this.state.password}
                        onKeyPress={this.on_login_press}
                        onChange={this.handleChange}
                      />
                      <div
                        className="eyeIcon cursor-pointer pt-2"
                        onClick={this.handleClickPassword}
                      >
                        {this.state.type == 'text' ? (
                          <img
                            src="https://images.myyogateacher.com/web_images/eye-off.svg"
                            className="w-6"
                          />
                        ) : (
                          <img
                            src="https://images.myyogateacher.com/web_images/web_prod/eye.png"
                            alt="eye"
                            className="w-6"
                          />
                        )}
                      </div>
                    </div>
                    {!this.state.email_error && this.state.password_error ? (
                      <div className="passwordError">
                        <div className="loginSignupErrorMsg">This field cannot be empty</div>
                        {/* <div className='hp-form-group-error-tr' /> */}
                      </div>
                    ) : !this.state.email_error && this.props.login_status === 'fail' ? (
                      <div className="passwordError">
                        <div className="loginSignupErrorMsg">{this.props.login_error_msg}</div>
                        {/* <div className='hp-form-group-error-tr' /> */}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={`${this.props.login_status === 'fail' ? 'mt-54px' : 'mt-0'}`}>
                  <button className="loginSignupBtn" onClick={this.log_in}>
                    Login
                    {this.props.login_status === 'loading' ? (
                      <div className="btn-orange">
                        <Spinner
                          circleRadius={16}
                          borderLength={1}
                          smallBorderColor="#4332DF"
                          largeBorderColor="#fff"
                        />
                      </div>
                    ) : null}
                  </button>
                </div>
              </div>
              <div className="lg:mt-2 zm:mt-2">
                <div className="text-mytgrey-lite zm:text-14.5 pt-4 zm:mt-2 zm:mb-10 lg:mt-4">
                  Want to become a Teacher?{' '}
                  <a
                    className="font-semibold hover:underline text-black cursor-pointer"
                    href="/signup"
                  >
                    Sign up
                  </a>
                </div>
              </div>
            </div>
            <div className="loginSignupBottomSection" />
          </div>
          <div className="loginSignupRightImage" />
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
    login_status: selectors.get_login_status(state),
    login_error_msg: selectors.login_error_msg(state)
  });

const mapDispatchToProps = (dispatch) => ({
    log_in_to_app: (index) => dispatch(actions.log_in_to_app(index))
  });

const LoginWithRedux = connect(mapStateToProps, mapDispatchToProps)(Login);
const LoginWithRouter = withRouter(LoginWithRedux);

export default LoginWithRouter;
