import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../redux/action_creators';
import './notif.css';

class Notifications extends Component {
	state = {
		show_notif: false,
		close_transition: false,
	};
	componentDidMount() {
		window.addEventListener('resize', this.set_window_width);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.set_window_width);
	}
	set_window_width = () => this.props.set_home_variable('application_width', window.innerWidth);

	componentDidUpdate(prevProps) {
		if (prevProps.show_notif !== this.props.show_notif && this.props.show_notif) {
			this.setState({ show_notif: true }, () => this.hide_notif());
		}
	}
	hide_notif = () =>
		setTimeout(() => {
			this.close_notif();
		}, 20000);

	close_notif = () => {
		if (this.props.show_notif) {
			this.setState({ close_transition: true }, () =>
				setTimeout(() => {
					this.setState({ close_transition: false, show_notif: false }, () =>
						this.props.set_notification_variable(false, '', '')
					);
				}, 400)
			);
		}
	};

	render() {
		if (this.state.show_notif) {
			const close_class = this.state.close_transition ? 'notifications-close' : '';
			let notif_class = ' ';
			let notif_url = 'https://images.myyogateacher.com/icons/ic_teacher_not_available@2x.png';
			if (this.props.notif_type === 'success') {
				notif_class = 'notifications-success ';
				notif_url = 'https://images.myyogateacher.com/icons/success_emoji@2x.png';
			}
			if (this.props.notif_type === 'error') {
				notif_class = 'notifications-error ';
				notif_url = 'https://images.myyogateacher.com/icons/fail_emoji@2x.png';
			}
			return (
				<div className={'notifications-container ' + notif_class + close_class}>
					<img src={notif_url} />
					<div className='notifications-text'>{this.props.notif_text}</div>
					<div className='notfications-close' onClick={this.close_notif}>
						x
					</div>
				</div>
			);
		} else return null;
	}
}

const mapStateToProps = (state) => {
	return {
		show_notif: state.notif.show_notif,
		notif_type: state.notif.notif_type,
		notif_text: state.notif.notif_text,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		set_notification_variable: (show, type, mes) => {
			dispatch(actions.set_notification_variable(show, type, mes));
		},
		set_home_variable: (key, payload) => {
			dispatch(actions.set_home_variable(key, payload));
		},
	};
};

const NotificationsWithRedux = connect(mapStateToProps, mapDispatchToProps)(Notifications);

export default NotificationsWithRedux;
