import React from "react";
import "./confirm.css";
class SimplePop extends React.Component {
  render() {
    return (
      <div className="sw-pop-outer">
        <div className="sw-pop-overlay" onClick={this.props.close_simple_pop} />
        <div className="sw-simple-pop-body">
          <img
            className="sw_pop_close_img"
            src="https://images.myyogateacher.com/icons/ic_white_cross.png"
            alt="cross"
            onClick={this.props.close_simple_pop}
          />
          <div className="sw-simpop_cont">
            {this.props.sHeadingText && <h1>{this.props.sHeadingTextt}</h1>}
            {this.props.sParaText && <p>{this.props.sParaText}</p>}
            {this.props.close_bd_btn_txt && (
              <button
                className="s-bd-btn"
                onClick={this.props.close_simple_pop}
              >
                {this.props.close_bd_btn_txt}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default SimplePop;
