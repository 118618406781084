import * as t from "../action_types";
import { getCookie } from "../../util_functions";
// home_component_indexes

// 1. dashboard
// 2. availability
// 3. profile

// reset_password_status states
// success
// fail

var initialState = {
  home_component_index: 3,
  load_application_status: "none",
  profile_update_status: "none",
  profile_loading_status: "none",
  teacher_token:
    typeof getCookie("teacher_token_1") === "undefined"
      ? ""
      : getCookie("teacher_token_1"),
  is_admin_imp: getCookie("is_admin_imp") === "undefined"
  ? false
  : getCookie("is_admin_imp"),
  password_loading: false,
  set_password_status: "none",
  profile_verified_status: false,
  login_status: "none",
  signup_status: "none",
  forgot_status: "none",
  login_error_msg: "",
  forgot_error_msg: "",
  config_values: {},
  yoga_journey_values: {},
  update_session_recap_values: {},
  update_roadmap_ques_values: {},
  getStudentsStatus: "none",
  studentsList: [],
  more_my_sessions: 0,
  account_created: 0,
  create_group_session: false,
  create_roadmap_status: "none",
  update_session_recap_status: "none",
  update_roadmap_ques_status: "none",
  update_whole_roadmap_status: "none",
  get_yoga_journey_status: "none",
  currentRoadMapSession: {},
  is_roadmap_present: false,
  my_sessions: [],
  show_request_sessions_popups: false,
  twilioVoiceCallStatus: 'none',
  selected_dashboard_session_uuid : '',
  session : {}
};

const setHomeComponentindex = (state, payload) => {
  return {
    ...state,
    home_component_index: payload,
  };
};

const setTeacherUuid = (state, payload) => {
  return {
    ...state,
    teacher_uuid: payload,
  };
};

const set_home_variable = (state, payload) => {
  return {
    ...state,
    [payload.key]: payload.payload,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_home_component_index:
      return setHomeComponentindex(state, action.payload);
    case t.set_teacher_uuid:
      return setTeacherUuid(state, action.payload);
    case t.set_home_variable:
      return set_home_variable(state, action.payload);
    default:
      return state;
  }
}
