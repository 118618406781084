import React from "react";

const Footer = () => {
  return (
    <>
      <div className="border-t mt-0">
        <div className="max-w-5xl mx-auto px-4 sm:px-6">
          <div className="w-full flex justify-between py-16 flex-wrap">
            <div className="xl:w-3/12 md:w-4/12 sm:w-full ">
              <a href="https://myyogateacher.com/">
                <span className="sr-only">logo</span>
                <img alt="MyYogaTeacher_Logo"
                  src="https://images.myyogateacher.com/MYT_Logo.svg"
                  className="w-58"
                />
              </a>
              <div className="mt-6 inline-flex justify-between items-center list-none">
                <a
                  className="pr-2"
                  href="https://www.facebook.com/myyogateacher.inc"
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <path
                        d="M21.45 0H4.55C2.0371 0 0 2.0371 0 4.55V21.45C0 23.9629 2.0371 26 4.55 26H21.45C23.9629 26 26 23.9629 26 21.45V4.55C26 2.0371 23.9629 0 21.45 0Z"
                        fill="#385997"
                      />
                      <path
                        d="M22.1 12.025H18.0375V8.93752C18.0375 8.1819 18.9069 8.12502 19.175 8.12502H21.9375V3.90002H19.175C16.1159 3.90002 13.8125 6.06534 13.8125 8.93752V12.025H10.4V16.25H13.8125V26H18.0375V16.25H21.45L22.1 12.025Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="26" height="26" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  className="pr-2"
                  href="https://www.instagram.com/myyogateacher"
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.55 26L21.45 26C23.9629 26 26 23.9629 26 21.45L26 4.55C26 2.03711 23.9629 0 21.45 0L4.55 0C2.03711 0 0 2.03711 0 4.55L0 21.45C0 23.9629 2.03711 26 4.55 26Z"
                      fill="url(#paint0_radial)"
                    />
                    <path
                      d="M17.875 22.75H8.125C6.83207 22.75 5.59209 22.2364 4.67785 21.3221C3.76361 20.4079 3.25 19.1679 3.25 17.875V8.125C3.25 6.83207 3.76361 5.59209 4.67785 4.67785C5.59209 3.76361 6.83207 3.25 8.125 3.25H17.875C19.1679 3.25 20.4079 3.76361 21.3221 4.67785C22.2364 5.59209 22.75 6.83207 22.75 8.125V17.875C22.75 19.1679 22.2364 20.4079 21.3221 21.3221C20.4079 22.2364 19.1679 22.75 17.875 22.75ZM8.125 5.2C7.34957 5.20107 6.60621 5.50959 6.0579 6.0579C5.50959 6.60621 5.20107 7.34957 5.2 8.125V17.875C5.20107 18.6504 5.50959 19.3938 6.0579 19.9421C6.60621 20.4904 7.34957 20.7989 8.125 20.8H17.875C18.6504 20.7989 19.3938 20.4904 19.9421 19.9421C20.4904 19.3938 20.7989 18.6504 20.8 17.875V8.125C20.7989 7.34957 20.4904 6.60621 19.9421 6.0579C19.3938 5.50959 18.6504 5.20107 17.875 5.2H8.125Z"
                      fill="white"
                    />
                    <path
                      d="M13 18.525C11.9072 18.525 10.839 18.2009 9.93045 17.5938C9.02187 16.9868 8.31372 16.1239 7.89554 15.1143C7.47737 14.1047 7.36796 12.9938 7.58114 11.9221C7.79432 10.8504 8.32053 9.8659 9.09321 9.09321C9.8659 8.32053 10.8504 7.79432 11.9221 7.58114C12.9938 7.36796 14.1047 7.47737 15.1143 7.89554C16.1239 8.31372 16.9868 9.02187 17.5938 9.93045C18.2009 10.839 18.525 11.9072 18.525 13C18.5239 14.465 17.9415 15.8696 16.9056 16.9056C15.8696 17.9415 14.465 18.5239 13 18.525ZM13 9.42498C12.2929 9.42498 11.6017 9.63465 11.0138 10.0275C10.4259 10.4203 9.96769 10.9786 9.69711 11.6319C9.42653 12.2851 9.35573 13.0039 9.49367 13.6974C9.63161 14.3909 9.9721 15.0279 10.4721 15.5279C10.972 16.0279 11.609 16.3683 12.3025 16.5063C12.996 16.6442 13.7148 16.5734 14.3681 16.3028C15.0213 16.0323 15.5797 15.574 15.9725 14.9861C16.3653 14.3982 16.575 13.707 16.575 13C16.5739 12.0522 16.1969 11.1435 15.5267 10.4733C14.8565 9.80305 13.9478 9.42605 13 9.42498Z"
                      fill="white"
                    />
                    <path
                      d="M18.525 8.77495C19.0635 8.77495 19.5 8.33843 19.5 7.79995C19.5 7.26147 19.0635 6.82495 18.525 6.82495C17.9865 6.82495 17.55 7.26147 17.55 7.79995C17.55 8.33843 17.9865 8.77495 18.525 8.77495Z"
                      fill="white"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(-0.523045 25.4719) rotate(180) scale(60.0417)"
                      >
                        <stop stopColor="#F9ED32" />
                        <stop offset="0.36" stopColor="#EE2A7B" />
                        <stop offset="0.44" stopColor="#D22A8A" />
                        <stop offset="0.6" stopColor="#8B2AB2" />
                        <stop offset="0.83" stopColor="#1B2AF0" />
                        <stop offset="0.88" stopColor="#002AFF" />
                      </radialGradient>
                    </defs>
                  </svg>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/myYogaTeacher"
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.55 26L21.45 26C23.9629 26 26 23.9629 26 21.45L26 4.55C26 2.03711 23.9629 0 21.45 0L4.55 0C2.03711 0 0 2.03711 0 4.55L0 21.45C0 23.9629 2.03711 26 4.55 26Z"
                      fill="#00AADE"
                    />
                    <path
                      d="M19.175 8.45002C19.5628 8.25063 19.9066 7.97523 20.1857 7.64022C20.4649 7.30521 20.6738 6.91745 20.8 6.50002C20.15 7.15002 18.7687 7.36533 18.7687 7.36533C18.2912 6.77847 17.6238 6.37664 16.8817 6.22912C16.1395 6.0816 15.3692 6.19764 14.7035 6.55722C14.0378 6.9168 13.5183 7.49739 13.2348 8.19889C12.9513 8.9004 12.9214 9.67884 13.1503 10.4H13C11.6762 10.3122 10.3852 9.94888 9.20997 9.33326C8.03469 8.71764 7.00096 7.86331 6.175 6.82502C6.175 6.82502 4.225 9.42502 7.15 11.375C7.15 11.375 5.85 11.375 5.525 11.05C5.525 11.05 5.2 13 8.125 14.3C7.92091 14.393 7.69926 14.4411 7.475 14.4411C7.25074 14.4411 7.02909 14.393 6.825 14.3C6.825 15.5553 8.13312 16.575 9.75 16.575C9.75 17.4728 7.56844 18.2 4.875 18.2C9.1 21.125 19.175 20.475 19.825 9.42502L21.45 7.80002L19.175 8.45002Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <p className="copyright zm:invisible sm:visible">
                © Copyright 2020 &nbsp;&nbsp;&nbsp;&nbsp; myYogaTeacher Inc
              </p>
            </div>
            <div className="xl:w-9/12 md:w-8/12 zm:w-full xl:pl-80 lg:pl-72 md:pl-40">
              <div className="w-full flex justify-between flex-wrap">
                <div className="sm:w-4/12 zm:w-full sm:mt-0 zm:mt-8">
                  <h4 className="footerHeading">Company</h4>
                  <ul className="list-none">
                    <li className="mb-1.5">
                      <a className="footerLink" href="/">
                        Teach With Us
                      </a>
                    </li>
                    <li className="mb-1.5">
                      {" "}
                      <a
                        className="footerLink"
                        href="https://myyogateacher.com/pricing"
                      >
                        Pricing
                      </a>
                    </li>
                    <li className="mb-1.5">
                      <a
                        className="footerLink"
                        href="https://myyogateacher.com/aboutus"
                      >
                        About Us
                      </a>
                    </li>
                    <li className="mb-1.5">
                      <a
                        className="footerLink"
                        href="https://myyogateacher.com/articles"
                      >
                        Articles
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sm:w-3/12 zm:w-full sm:mt-0 zm:mt-6">
                  <h4 className="footerHeading">Support</h4>
                  <ul className="list-none">
                    <li className="mb-1.5">
                      <a
                        className="footerLink"
                        href="/contactus"
                      >
                        Contact
                      </a>
                    </li>
                    <li className="mb-1.5">
                      <a
                        className="footerLink"
                        href="mailto:support@myyogateacher.com"
                      >
                        Support
                      </a>
                    </li>
                    <li className="mb-1.5">
                      <a
                        className="footerLink"
                        href="https://myyogateacher.com/faq"
                      >
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sm:w-3/12 zm:w-full sm:mt-0 zm:mt-8">
                  <h4 className="footerHeading">Privacy</h4>
                  <ul className="list-none">
                    <li className="mb-1.5">
                      <a
                        className="footerLink"
                        href="https://myyogateacher.com/privacypolicy"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="mb-1.5">
                      {" "}
                      <a
                        className="footerLink"
                        href="https://myyogateacher.com/termsofuse"
                      >
                        Terms of Use
                      </a>
                    </li>
                  </ul>
                </div>
                <p className="copyright zm:visible sm:invisible">
                  © Copyright 2020 &nbsp;&nbsp;&nbsp;&nbsp; myYogaTeacher Inc
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
