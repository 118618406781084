import React, { useState, useEffect } from 'react';
import { NotificationTick } from '../livekit_assets/svg';

function NotificationBadge({ text = 'Notification', show = true }) {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [show]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="notification-badge-wrapper">
      <div className="notification-badge-container">
        <p>{text}</p>
        <p>
          <NotificationTick color="#1C1C1C" />
        </p>
      </div>
    </div>
  );
}

export default NotificationBadge;
