import React from 'react';

const ShowUniqueId = ({ closeUniquePopup, student_slug, student_thumb }) => {

    function copyUniqueId() {
        navigator?.clipboard?.writeText(student_slug);
        closeUniquePopup();
    }

    return (
        <>
            <div className='relative inset-0 z-50'>
                <div
                    className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'
                >
                    <div className='z-50 bg-white zm:rounded-tl-3xl zm:rounded-tr-3xl zm-roundex-lb-none zm:rounded-rb-none md:rounded-lg md:p-10 zm:p-30px md:w-492px zm:w-full relative'>
                        <div className='flex justify-end cursor-pointer absolute top-1 right-1' onClick={closeUniquePopup}>
                            <div className='md:mt-1 mr-1'>
                                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect width='34' height='34' rx='17' fill='none' />
                                    <path
                                        d='M22 12L12 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        d='M12 12L22 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <img className="p-1 w-10 h-10 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={student_thumb} alt={student_slug} />
                            <div className='text-subText md:text-19px zm:text-17 mt-1 font-semibold pt-4 text-center leading-tight'>{student_slug}</div>
                            <div className='flex flex-col w-full mt-3'>
                                <button className='btn btn-primary md:py-5 zm:py-3 mt-10px md:text-18.5 zm:text-17 font-semibold' onClick={copyUniqueId}>
                                    Copy Unique ID
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShowUniqueId;