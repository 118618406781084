import React from 'react'

const WhiteBox = (props) => {
    return (
        <div className={` ${props.classnames ? props.classnames : ''} zm:w-full md:w-840px bg-white border border-solid border-mytgrey-litev1 shadow-mytboxshadow rounded-lg zm:px-20px zm:pb-30px zm:pt-25px md:pt-25px md:px-40px md:pb-40px`}>
            {props.children}
        </div>
    )
}
export default WhiteBox;

export function Boxcontent(props) {
    return (
        <div className={`${props.classnames ? props.classnames : ''} mt-30px`}>
            <div className={`${props.textstyle ? props.textstyle : ''} text-13.5 leading-4 font-semibold text-black mb-5px`}>{props.subhead}</div>
            <div className={`${props.parastyle ? props.parastyle : ''} text-13px leading-5 font-normal text-greyText`}>{props.para}
            </div>
        </div>
    )
}