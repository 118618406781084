import React, { Component } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { debounce } from '../../../util_functions';
import './teaching-with.css';

export default class TeachingWith extends Component {
	state = {
		teacher_selected: 0
	};
	availableRef = React.createRef();
	componentDidMount() {
		if (!(isMobile || isTablet) && window.outerWidth > 768) {
			document.addEventListener(
				'scroll',
				debounce(() => {
					this.scroll_listner();
				}, 15)
			);
		} else {
			this.start_scroll();
		}
	}
	start_scroll = () =>
		setTimeout(() => {
			this.auto_scroll();
		}, 4000);

	auto_scroll = () => {
		this.setState({ teacher_selected: (this.state.teacher_selected + 1) % 3 });
		this.start_scroll();
	};
	componentWillUnmount() {
		if (!(isMobile || isTablet)) {
			document.removeEventListener(
				'scroll',
				debounce(() => {
					this.scroll_listner();
				}, 100)
			);
		}
	}
	shouldComponentUpdate(nextProps) {
		if (nextProps !== this.props) {
			return false;
		}
		return true;
	}

	scroll_listner = () => {
		try {
			if (window.outerWidth > 768) {
				const details = this.availableRef.current.getBoundingClientRect();
				const dist = Math.floor((Math.max(window.innerHeight - details.height, 0) + 100) / 3);
				const index_value = 2 - Math.max(Math.min(Math.floor(details.top / dist), 2), 0);
				if (this.state.teacher_selected !== index_value) {
					this.setState({ teacher_selected: index_value });
				}
			}
		} catch {}
	};
	handleDeviceImgChange = event => {
		event.preventDefault();
		this.setState({ teacher_selected: parseInt(event.currentTarget.dataset.index, 10) });
	};
	render_one = () => {
		return (
			<div className="mt70">
				<div className="tp-row-teaching-with">
					<div className="tp-teach-col-left">
						<div className="tp-teach-img">
							<img
								className="tp-img-back"
								src="https://images.myyogateacher.com/Teacher-Page/teach-with-img1.png"
								alt="with-1"
							/>
							<img
								className="tp-img"
								src="https://images.myyogateacher.com/Teacher-Page/Mandala-with-oval-copy.png"
								alt="with-1"
							/>
						</div>
					</div>

					<div className="tp-teach-col-right">
						<div className="teachwithtext">
							<h4>Become a better teacher</h4>
							<p>Learn from the world class training content and a vibrant community.</p>
						</div>
					</div>
				</div>
			</div>
		);
	};
	render_two = () => {
		return (
			<div className="mt70">
				<div className="tp-row-teaching-with">
					<div className="tp-teach-col-left">
						<div className="tp-teach-img">
							<img
								className="tp-img-back"
								src="https://images.myyogateacher.com/Teacher-Page/teach-with-img2.png"
								alt="with-2"
							/>
							<img
								className="tp-img"
								src="https://images.myyogateacher.com/Teacher-Page/Mandala-with-oval-copy.png"
								alt="with-2"
							/>
						</div>
					</div>

					<div className="tp-teach-col-right">
						<div className="teachwithtext">
							<h4>Enjoy Flexibility</h4>
							<p>
								Teach from home or on the go whenever you want.
								<br /> No need to commute!
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};

	render_three = () => {
		return (
			<div className="mt70">
				<div className="tp-row-teaching-with">
					<div className="tp-teach-col-left">
						<div className="tp-teach-img">
							<img
								className="tp-img-back"
								src="https://images.myyogateacher.com/Teacher-Page/teach-with-img3.png"
								alt="with-3"
							/>
							<img
								className="tp-img"
								src="https://images.myyogateacher.com/Teacher-Page/Mandala-with-oval-copy.png"
								alt="with-3"
							/>
						</div>
					</div>

					<div className="tp-teach-col-right">
						<div className="teachwithtext">
							<h4>Make good money</h4>
							<p>
								Earn upto $10-15 (<span> &#8377;</span>
								700-1,000) per hour long session & upto $2000(<span> &#8377;</span> 1,40,000) per month
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	};
	render_teacher_container = () => {
		switch (this.state.teacher_selected) {
			case 0:
				return this.render_one();
			case 1:
				return this.render_two();
			default:
				return this.render_three();
		}
	};
	render() {
		return (
			<div className="teaching-with-container" ref={this.availableRef}>
				<div className="container">
					<h2>Teaching with myYogaTeacher means</h2>
					<p className="divider-line">
						<img src="https://images.myyogateacher.com/home-page/Divider.png" alt="divider" />
					</p>
					<div className="teaching-with-carousel">
						{this.render_teacher_container()}
						<div className="avail-carousel">
							<div className="hr-line" />
							<div className="av-car-circle" data-index={0} onClick={this.handleDeviceImgChange}>
								{this.state.teacher_selected === 0 ? <div className="av-car-ins" /> : null}
							</div>
							<div className="av-car-circle av-car-circle-2" data-index={1} onClick={this.handleDeviceImgChange}>
								{this.state.teacher_selected === 1 ? <div className="av-car-ins" /> : null}
							</div>
							<div className="av-car-circle" data-index={2} onClick={this.handleDeviceImgChange}>
								{this.state.teacher_selected === 2 ? <div className="av-car-ins" /> : null}{' '}
							</div>
							<div className="hr-line hr-line-rev" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
