// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import moment from 'moment';

import { isDevMode, copyTextToClipboardinHttp,sendEventToClarity } from '../../../../../util_functions';
import Loader from '../../../../new_teacher_wesite/components/loader';
import RecapVideoPopup from '../popup/video';

import { connect } from 'react-redux';
import Header from '../../../../util_components/header';
import Footer from '../../../../new_teacher_wesite/components/footer';
import { Chart, registerables } from 'chart.js';

import SessionRecapPopup from '../popup/sessionRecapPopup';
import CancelledClassPopup from '../popup/cancelledPopup';
import NoShowPopup from '../popup/noShowPopup';
import ReplacedClassPopup from '../popup/replaces';
import CancelledSession from '../popup/cancelledSession';
import * as actions from '../../../../../redux/action_creators';
import CheckMobile from '../../../../teacher/check_mobile';
import TherapyPlanPopUp from '../../../../../new_teacher_design/BackPainTherapy/TherapyPlanPopUp';
import FollowUpConsultationPopUp from '../../../../../new_teacher_design/BackPainTherapy/FollowUpConsultationPopUp';
import EditSessionNotesPopUp from '../../../../../new_teacher_design/BackPainTherapy/EditSessionNotesPopUp';
import urls from '../../../../../urls.js';
import PieChart from '../my-sessions/DonutChart';
import { withRouter } from '../../../../../utils/router';
import { session_status_value } from '../../../../../constants';
import { AttendanceRecap } from '../../../../teacher/dashboard/Modal/AttendanceRecap';
import MytToolTip from '../../../../../../app/new_teacher_design/BackPainTherapy/CommonComponents/MytToolTip/MytToolTip';
import { RedQuestionMark } from '../../../../../../svg';
import { handleClickOutside } from '../../../../../new_teacher_design/utils/general_funcs';

Chart.register(...registerables);

class MySessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      monthDates: [],
      newMonthDates: [],
      dateObj: [],
      dateObjFilterData: [],
      follow_up_popup: false,
      monthDropDown: false,
      selectedMonthData: new Date(),
      monthDataSelected: null,
      RecapVideoPopupObj: false,
      RecapVideoPopupData: null,
      mySessionAllDataV1: null,
      CancelledClassPopup: false,
      noShowPopupClassPopup: false,
      replacedClassPopup: false,
      questionRecap: false,
      questionRecapData: null,
      payload: null,
      timeFilterPopup: false,
      classFilterPopup: false,
      selectedClassFilterPopup: null,
      selectedTimeFilterPopup: null,
      classFilterDataType: null,
      timeFilterDataType: null,
      activeClassFilter: false,
      activeDurationFilter: false,
      cancelledSessionPopup: false,
      cancelledSessionPopupData: null,
      create_therapy_plan_popup: false,
      selected_session_details: {},
      attendanceRecap: false,
      attendanceRecapData: null,
      timeFilter: [
        {
          id: 1,
          duration: 'All',
          time: 'All'
        },
        {
          id: 2,
          duration: '30 Mins',
          time: 30
        },
        {
          id: 3,
          duration: '45 Mins',
          time: 45
        },
        {
          id: 4,
          duration: '60 Mins',
          time: 60
        },
        {
          id: 5,
          duration: '90 Mins',
          time: 90
        }
      ],
      classFilterData: [
        {
          id: 1,
          classType: 'All',
          classFilterType: 'All'
        },
        {
          id: 2,
          classType: 'Group Class',
          classFilterType: 'GROUP_SESSION'
        },
        {
          id: 3,
          classType: '1 on 1 Class',
          classFilterType: 'YOGA'
        },
        {
          id: 4,
          classType: 'Workshop',
          classFilterType: 'WORKSHOP_SESSION'
        },
        {
          id: 5,
          classType: 'Group Session Student',
          classFilterType: 'GROUP_SESSION_STUDENT'
        },
        {
          id: 6,
          classType: 'Video Consult',
          classFilterType: 'VIDEO_CONSULT'
        },
        {
          id: 7,
          classType: 'Yoga with Friends',
          classFilterType: 'YOGA_WITH_FRIENDS_SESSION'
        },
        {
          id: 8,
          classType: 'Yoga with Friends Student',
          classFilterType: 'YOGA_WITH_FRIENDS_SESSION_STUDENT'
        },
        {
          id: 9,
          classType: 'Back Pain Consult',
          classFilterType: 'BACK_PAIN_CONSULT'
        },
        {
          id: 10,
          classType: 'Back Pain 1-on-1',
          classFilterType: 'BACK_PAIN_THERAPY'
        },
        {
          id: 11,
          classType: 'Back Pain Group',
          classFilterType: 'BACK_PAIN_GROUP'
        }
      ],
      selectedDuration: 'All',
      selectedClass: 'All',
      editSessionNotesPopup: false,
      selectedPayload: {}
    };
    this.classTypeFilterRef = React.createRef();
    this.durationFilterRef = React.createRef();
  }

  componentDidMount() {
    this.monthCalulation();

    let payload = {
      month: moment(this.state.selectedMonthData, 'MMM, YYYY').format('M'),
      year: moment(this.state.selectedMonthData, 'MMM, YYYY').format('Y')
    };

    this.setState({
      isLoading: true,
      selectedPayload: payload
    });
    this.props.getMySessionV1(payload);
    this.props.getMySessionData(payload);

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.key === 27) {
        this.setState({
          replacedClassPopup: false,
          noShowPopupClassPopup: false,
          CancelledClassPopup: false,
          RecapVideoPopupObj: false,
          questionRecap: false,
          cancelledSessionPopup: false
        });
      }
    });
    if (this.props.my_session_status === 'success') {
      this.setState({ isLoading: false });
    }

    sendEventToClarity();
  }
  // Create month Obj

  // Create month Obj

  monthCalulation = () => {
    var m = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var last_n_months = [];
    this.setState({
      monthDataSelected: last_n_months
    });

    var d = new Date();
    for (var i = 0; i < 3; i++) {
      last_n_months[i] = m[d.getMonth()] + ', ' + d.getFullYear().toString();
      d.setMonth(d.getMonth() - 1);
    }
    return last_n_months;
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.my_session_data_status === 'loading' &&
      this.props.my_session_data_status === 'success'
    ) {
      this.mySessionAllData_v1();
      this.setState({
        isLoading: false
      });
    }

    if (prevProps.my_session_status === 'loading' && this.props.my_session_status === 'success') {
      this.mySessionDataCalling();
      this.setState({
        isLoading: false
      });
    }
    if (prevProps.my_session_status === 'none' && this.props.my_session_status === 'loading') {
      this.setState({ isLoading: true });
    }
    if (prevProps.my_session_status === 'loading' && this.props.my_session_status === 'success') {
      this.setState({ isLoading: false });
    }
    if (prevProps.my_session_status === 'loading' && this.props.my_session_status === 'failure') {
      this.setState({ isLoading: false });
    }
  }

  // Create date Obj

  // Create date Obj

  dayFuntion = (timeZone) => {
    var daysInMonth = [];
    var monthDateNew = moment(this.state.selectedMonthData, 'MMM,YYYY').startOf('month');
    var monthDate = moment(monthDateNew).tz(timeZone);
    for (let i = 0; i < monthDate?.daysInMonth(); i++) {
      let newDay = monthDate.clone().add(i, 'days');
      daysInMonth?.push(newDay.format('MMM DD yyyy'));
    }

    this.setState(
      {
        monthDates: daysInMonth
      },
      () => this.arrangDataFunc()
    );
  };

  arrangDataFunc = () => {
    if (this.props.my_session_status === 'success') {
      let data = this.props.my_session_response?.data?.my_session;
      if (this.state.monthDates && this.state.monthDates.length) {
        data.forEach((element) => {
          this.state.monthDates.forEach((each) => {
            if (moment(element.start_date).format('MMM DD YYYY') === each) {
              this.state.newMonthDates.push(element);
            }
          });
        });
        this.dateGrouping();
      }
    }
  };

  // my session Api call

  mySessionDataCalling = (data, dataCalling) => {
    if (dataCalling === true) {
      let payload = {
        month:
          data !== 0
            ? moment(data, 'MMM, YYYY').format('M')
            : moment(this.state.selectedMonthData, 'MMM, YYYY').format('M'),
        year:
          data !== 0
            ? moment(data, 'MMM, YYYY').format('YYYY')
            : moment(this.state.selectedMonthData, 'MMM, YYYY').format('Y')
      };
      this.props.getMySessionV1(payload);
      this.props.getMySessionData(payload);
      if (this.props.my_session_status === 'success') {
        if (this.props.my_session_response?.data?.teacher_timezone !== '') {
          this.dayFuntion(this.props.my_session_response?.data?.teacher_timezone);
        }
      }
      this.setState({
        isLoading: false
      });
    } else if (dataCalling === undefined) {
      if (this.props.my_session_status === 'success') {
        if (this.props.my_session_response?.data?.teacher_timezone !== '') {
          this.dayFuntion(this.props.my_session_response?.data?.teacher_timezone);
        }
      }
    }
  };

  // ------------------------------- //

  // Session total data list Api call

  mySessionAllData_v1 = (data, dataCalling) => {
    if (dataCalling === true) {
      let payload = {
        month:
          data !== 0
            ? moment(data, 'MMM, YYYY').format('M')
            : moment(this.state.selectedMonthData, 'MMM, YYYY').format('M'),
        year:
          data !== 0
            ? moment(data, 'MMM, YYYY').format('YYYY')
            : moment(this.state.selectedMonthData, 'MMM, YYYY').format('Y')
      };
      //this.props.getMySessionData(payload);
      if (this.props.my_session_status === 'success') {
        this.dayFuntion(this.props.my_session_response?.data?.teacher_timezone);
      }
      this.setState({
        isLoading: false
      });
    }

    if (this.props.my_session_data_status === 'success') {
      this.setState({
        mySessionAllDataV1: this.props.my_session_data_response?.data
      });
    }
  };

  // ------------------------------- //

  //  filter data accoding to date Function

  dateGrouping = () => {
    let obj = {};
    this.state.newMonthDates.forEach((dt) => {
      if (obj[dt.start_date]) {
        obj[dt.start_date].push(dt);
      } else {
        obj[dt.start_date] = [dt];
      }
    });
    this.setState(
      {
        dateObjFilterData: obj,
        dateObj: JSON.parse(JSON.stringify(obj))
      },
      () => this.filterClasstype()
    );
  };

  // ------------------------------- //

  //  function for filter data accoding to Class Type & Duration

  filterClasstype = () => {
    let dataFilter = JSON.parse(JSON.stringify(this.state.dateObjFilterData));
    let classType = [];
    let timeFilter = [];
    Object.keys(dataFilter).forEach((key) => {
      dataFilter[key].forEach((dt) => {
        if (!classType.includes(dt.session_type)) {
          classType.push(dt.session_type);
        }
        if (!timeFilter.includes(dt.duration)) {
          timeFilter.push(dt.duration);
        }
      });
    });

    if (classType.length && timeFilter.length) {
      this.setState({
        classFilterDataType: classType,
        timeFilterDataType: timeFilter
      });
    }
  };

  // ------------------------------- //

  toggleOpen = () => {
    this.setState({ monthDropDown: true });
  };

  toggleClosed = () => {
    this.setState({ monthDropDown: false });
  };

  classFilterPopupClickOpen = () => {
    this.setState({ classFilterPopup: true }, () => {
      this.removeOutsideClickListener = handleClickOutside(this.classTypeFilterRef, () => {
        this.setState({ classFilterPopup: false });
      });
    });
  };

  durationFilterClickOpen = () => {
    this.setState({ timeFilterPopup: true }, () => {
      this.removeOutsideClickListener = handleClickOutside(this.durationFilterRef, () => {
        this.setState({ timeFilterPopup: false });
      });
    });
  };

  selectedMonthDataObj = (data) => {
    let dataCalling = true;
    this.setState(
      {
        isLoading: true,
        selectedMonthData: data,
        newMonthDates: []
      },
      this.mySessionDataCalling(data, dataCalling),
      this.mySessionAllData_v1(data, dataCalling)
    );
  };

  RecapVideoPopupClick = (data) => {
    this.setState({
      recapVideoPopupData: data,
      RecapVideoPopupObj: true
    });
  };

  recapVideoPopupClose = () => {
    this.setState({
      RecapVideoPopupObj: false
    });
  };

  openQuestionPopup = (event, data) => {
    event.stopPropagation();
    this.setState({
      questionRecapData: data,
      questionRecap: true
    });
  };
  openRecapPopup = (event, sessionUUID) => {
    event.stopPropagation();
    this.setState({
      // questionRecapData: data,
      attendanceRecap: true,
      attendanceRecapData: sessionUUID
    });
  };
  recapModalClose = () => {
    this.setState({
      attendanceRecap: false
    });
  };

  recapQuestionClose = () => {
    this.setState({
      questionRecap: false
    });
  };

  resRecapQuestionClose = (data) => {
    this.setState(
      {
        questionRecap: false,
        newMonthDates: []
      },
      this.mySessionDataCalling(moment(data, 'MMM, YYYY').format('MMM, YYYY')),
      this.mySessionAllData_v1(moment(data, 'MMM, YYYY').format('MMM, YYYY'))
    );
  };

  openStudentProfile(e, data) {
    e.stopPropagation();
    this.props.router.navigate(`/student-profile/${data}`);
  }
  openNewStudentProfile = (e, data) => {
    e.stopPropagation();
    let studentUUID = data.student_uuid;
    this.props.router.navigate(`/backpain-client-profile?id=${studentUUID}`);
  };

  // open group class function

  openGPClass(e, data) {
    e.stopPropagation();
    if (data !== '') {
      if (window.location.hostname === 'https://webstg2.myyogateacher.com/') {
        window.open(`https://webstg2.myyogateacher.com/group_classes/${data}`);
      } else {
        window.open(`https://myyogateacher.com/group_classes/${data}`);
      }
    }
  }

  // ------------------------------- //

  render_student_name_link = (data) => {
    if (
      data?.session_type === 'VIDEO_CONSULT' ||
      data?.session_type === 'YOGA' ||
      data?.session_type === 'YOGA_WITH_FRIENDS_SESSION' ||
      data?.session_type === 'BACK_PAIN_CONSULT' ||
      data?.session_type === 'BACK_PAIN_THERAPY'
    ) {
      return (
        <div
          className="flex justify-start items-center w-64 cursor-pointer"
          onClick={(e) =>
            data?.session_type === 'BACK_PAIN_CONSULT' || data?.session_type === 'BACK_PAIN_THERAPY'
              ? this.openNewStudentProfile(e, data)
              : this.openStudentProfile(e, data?.student_slug)
          }>
          <img className="w-38px h-38px rounded mr-15px" alt="user img" src={data?.icon_url} />
          <div className="text-13 font-medium">{data?.student_name}</div>
        </div>
      );
    } else
      return (
        <div
          className="flex justify-start items-center w-64 cursor-pointer"
          onClick={(e) => this.openGPClass(e, data?.group_page_slug)}>
          <img className="w-38px h-38px rounded mr-15px" alt="user img" src={data?.icon_url} />
          <div className="text-13 font-medium">{data?.group_session_name}</div>
        </div>
      );
  };

  openCancelledPopup = () => {
    if (this.state.mySessionAllDataV1?.total_cancelled !== 0) {
      this.setState({
        CancelledClassPopup: true
      });
    }
  };

  closeCancelledPopupV1 = () => {
    this.setState({
      CancelledClassPopup: false
    });
  };

  openNoShowPopup = () => {
    if (this.state.mySessionAllDataV1?.total_no_show !== 0) {
      this.setState({
        noShowPopupClassPopup: true
      });
    }
  };

  closeopenNoShowPopupPopupV1 = () => {
    this.setState({
      noShowPopupClassPopup: false
    });
  };

  selectedTimeDuration = (list) => {
    this.setState(
      {
        selectedDuration: list.time,
        selectedTimeFilterPopup: list.duration,
        timeFilterPopup: false,
        activeDurationFilter: true
      },
      () => this.filterDateObjData()
    );
  };

  cancelledSessionPopupClick = (data) => {
    if (data.status === 'CANCELLED' || 'NO_SHOW_CANCELLATION_1HOUR' || 'JOINED_BUT_CANCELLED') {
      this.setState({
        cancelledSessionPopupData: data,
        cancelledSessionPopup: true
      });
    }
  };

  cancelledSessionPopupclose = () => {
    this.setState({
      cancelledSessionPopup: false
    });
  };

  // Class Type and Class Duration filter function

  filterDateObjData = () => {
    let dataFilter = JSON.parse(JSON.stringify(this.state.dateObjFilterData));
    Object.keys(dataFilter).forEach((key) => {
      if (this.state.selectedDuration === 'All' && this.state.selectedClass === 'All') {
        if (this.state.selectedDuration === 'All') {
          this.setState({
            activeDurationFilter: false
          });
        }
        if (this.state.selectedClass === 'All') {
          this.setState({
            activeClassFilter: false
          });
        }
        return false;
      } else if (this.state.selectedDuration === 'All') {
        dataFilter[key] = dataFilter[key].filter((dt) => {
          return this.state.selectedClass === dt.session_type;
        });
      } else if (this.state.selectedClass === 'All') {
        dataFilter[key] = dataFilter[key].filter((dt) => {
          return this.state.selectedDuration === dt.duration;
        });
      } else {
        dataFilter[key] = dataFilter[key].filter((dt) => {
          return (
            this.state.selectedDuration === dt.duration &&
            this.state.selectedClass === dt.session_type
          );
        });
      }
    });

    this.setState({
      dateObj: dataFilter
    });
  };

  classFilterSelectedData = (list) => {
    this.setState(
      {
        selectedClassFilterPopupData: list.classType,
        selectedClass: list.classFilterType,
        classFilterPopup: false,
        activeClassFilter: true
      },
      () => this.filterDateObjData()
    );
  };

  replacedPopupClose = () => {
    this.setState({
      replacedClassPopup: false
    });
  };

  replacedPopupOpen = () => {
    if (this.state.mySessionAllDataV1?.total_replaced !== 0) {
      this.setState({
        replacedClassPopup: true
      });
    }
  };

  redirectToClassDetails = (data) => {
    if (data?.status === 'FINISHED') {
      this.props.router.navigate(`/class-details/${data.session_uuid}`, {
        state: { session_notes: data.session_notes }
      });
    }
  };
  onConsultFormSubmit = (data) => {
    this.setState({
      isLoading: true
    });

    this.setState({
      isLoading: false,
      follow_up_popup: false
    });
    let modifiedDateObj = Object.assign({}, this.state.dateObj);
    let date = data.start_date;
    if (modifiedDateObj.hasOwnProperty(date)) {
      modifiedDateObj[date].forEach((session) => {
        if (session.session_uuid === data.session_uuid) {
          session.consultation_form_submitted = 1;
        }
      });
    }
  };
  onTherapyPlanSubmit = () => {
    let data = this.state.selected_session_details;

    this.setState({
      isLoading: false,
      create_therapy_plan_popup: false
    });
    let modifiedDateObj = Object.assign({}, this.state.dateObj);
    let date = data.start_date;
    if (modifiedDateObj.hasOwnProperty(date)) {
      modifiedDateObj[date].forEach((session) => {
        if (session.session_uuid === data.session_uuid) {
          session.roadmap_completed = 1;
        }
      });
    }
  };
  myKeyPress(e) {
    var keynum;

    if (window.event) {
      // IE
      keynum = e.keyCode;
    } else if (e.which) {
      // Netscape/Firefox/Opera
      keynum = e.which;
    }
    return String.fromCharCode(keynum);
  }
  copyDataInDevMode = (e, data) => {
    let key = this.myKeyPress(e);
    if (isDevMode() && key === 'T') {
      let url = urls.hridiya_session_details;
      let id = data.session_uuid;
      url = `${hridiya_session_details}?id=${id}`;
      copyTextToClipboardinHttp(url);
    }
  };

  componentWillUnmount() {
    if (this.removeOutsideClickListener) {
      this.removeOutsideClickListener();
    }
  }

  getSessionStatus = (dataList, sessionStatus) => {
    return (
      <>
        <div className="text-13 font-medium text-mytLabelRed flex justify-end items-center">
          Session status
        </div>
        <div id={dataList?.session_uuid} className="ml-10px">
          <RedQuestionMark />
        </div>
        <MytToolTip
          content={
            <ul>
              {sessionStatus?.map((status, index) => (
                <li key={index}>{status}.</li>
              ))}
            </ul>
          }
          id={dataList?.session_uuid}
          position={'top'}
        />
      </>
    );
  };

  render() {
    const chartData = {
      labels: [
        '1-on-1 classes',
        'Group classes',
        'Yoga with Friends',
        'Workshop',
        'Backpain 1-on-1',
        'Backpain Group',
        'Backpain Consult'
      ],
      datasets: [
        {
          label: 'Data',
          borderRadius: '8px',
          data: [
            this.state.mySessionAllDataV1?.one_on_one_finished,
            this.state.mySessionAllDataV1?.group_finished,
            this.state.mySessionAllDataV1?.ywf_finished,
            this.state.mySessionAllDataV1?.workshop_finished,
            this.state.mySessionAllDataV1?.back_pain_therapy_finished,
            this.state.mySessionAllDataV1?.back_pain_group_session_finished,
            this.state.mySessionAllDataV1?.back_pain_consult_finished
          ],
          backgroundColor: [
            '#286EFE',
            '#EE2A7B',
            '#FCD757',
            '#321A50',
            '#00A896',
            '#FF6F61',
            '#8A2FFF'
          ]
        }
      ]
    };
    const pieOptions = {
      plugins: {
        legend: {
          display: true,
          position: 'right',
          cutout: '10%',
          labels: {
            font: {
              size: 12
            }
          }
        }
      }
    };

    if (this.state.editSessionNotesPopup) {
      return (
        <EditSessionNotesPopUp
          data={this.state.selected_session_details?.session_notes || ''}
          sessionUUID={this.state.selected_session_details?.session_uuid || ''}
          onClose={() => {
            this.setState({ editSessionNotesPopup: false, selected_session_details: {} });
          }}
          onSubmit={() => {
            this.setState({ editSessionNotesPopup: false, selected_session_details: {} });
          }}
        />
      );
    }
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            {this.state.RecapVideoPopupObj && (
              <RecapVideoPopup
                recapVideoPopupData={this.state.recapVideoPopupData}
                recapVideoPopupClose={this.recapVideoPopupClose}
              />
            )}
            {this.state.questionRecap && (
              <SessionRecapPopup
                data={this.state.questionRecapData}
                resRecapQuestionClose={(data) => this.resRecapQuestionClose(data)}
                recapQuestionClose={this.recapQuestionClose}
                selectedPayload={this.state.selectedPayload}
              />
            )}
            {this.state.attendanceRecap && (
              <AttendanceRecap
                closeViewParticiPop={this.recapModalClose}
                sessionUUID={this.state.attendanceRecapData}
                config_values={this.props.config_values}
              />
            )}
            {this.state.CancelledClassPopup && (
              <CancelledClassPopup
                data2={this.state.mySessionAllDataV1}
                data={this.state.selectedMonthData}
                canclledPopupClose={this.closeCancelledPopupV1}
              />
            )}
            {this.state.noShowPopupClassPopup && (
              <NoShowPopup
                data2={this.state.mySessionAllDataV1}
                data={this.state.selectedMonthData}
                noShowPopupClose={this.closeopenNoShowPopupPopupV1}
              />
            )}
            {this.state.replacedClassPopup && (
              <ReplacedClassPopup
                data={this.state.selectedMonthData}
                replacedPopupClose={this.replacedPopupClose}
              />
            )}
            {this.state.cancelledSessionPopup && (
              <CancelledSession
                cancelledSessionPopupclose={this.cancelledSessionPopupclose}
                cancelledSessionPopupData={this.state.cancelledSessionPopupData}
              />
            )}
            {this.state.create_therapy_plan_popup && (
              <TherapyPlanPopUp
                session_details={this.state.selected_session_details}
                onSubmitClose={() => this.onTherapyPlanSubmit()}
                onClose={() => {
                  this.setState({
                    isLoading: false,
                    create_therapy_plan_popup: false
                  });
                }}
              />
            )}
            {this.state.follow_up_popup &&
              Object.keys(this.state.selected_session_details).length > 0 && (
                <FollowUpConsultationPopUp
                  onClose={() => {
                    this.setState({ follow_up_popup: false });
                  }}
                  onSubmitClose={() => {
                    this.onConsultFormSubmit(this.state.selected_session_details);
                    this.setState({ follow_up_popup: false });
                  }}
                  onSubmit={() => {
                    () => this.onConsultFormSubmit();
                  }}
                  createReliefPlan={() => {
                    this.setState({
                      follow_up_popup: false,
                      create_therapy_plan_popup: true
                    });
                  }}
                  data={this.state.selected_session_details}
                />
              )}

            <Header />
            <CheckMobile>
              <div className="w-full flex justify-center items-start mb-50px bg-mytgrey-litev1">
                <div className="mt-50px w-1098px m-auto mb-50px">
                  <div className="flex justify-between items-center">
                    <div className="text-3xl font-extrabold">My sessions</div>
                    <div className="relative">
                      <button
                        className="btn-white-default h-39px dropdown_month_session_btn"
                        onClick={this.state.monthDropDown ? this.toggleClosed : this.toggleOpen}>
                        <span className="mr-4">
                          {moment(this.state.selectedMonthData, 'MMM, YYYY').format('MMM, YYYY')}
                        </span>
                        <img
                          src="https://images.myyogateacher.com/web_images/arrow-down-medium.svg"
                          alt="arrow-down"
                        />
                      </button>
                      {this.state.monthDropDown ? (
                        <div className="absolute z-50 w-180px mt-2 shadow-md top-10 right-0 bg-white rounded-lg border border-gray pt-25px pb-25px pl-25px pr-25px">
                          {Object.keys(this.state.monthDataSelected).map((data, i) => {
                            return (
                              <div
                                key={i + 2332}
                                className="text-start mb-3 selectedMonthOption"
                                onClick={() =>
                                  this.selectedMonthDataObj(this.state.monthDataSelected[data])
                                }>
                                {this.state.monthDataSelected[data]}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="myt_shadow_v1 bg-white rounded-lg mt-15px">
                    <div className="pl-30px pr-30px pt-5 mb-5">
                      <div className="flex justify-between items-center border-b border-dashed w-full">
                        <div>
                          <div className="text-mytgreen text-6xl font-medium">
                            {this.state.mySessionAllDataV1?.total_finished} Classes
                          </div>
                          <div className="text-sm font-medium text-mytgrey-lite mt-3">
                            You’ve taken this month
                          </div>
                        </div>
                        <div className="flex">
                          <PieChart chartData={chartData} />
                        </div>
                      </div>
                      <div className="flex justify-start items-center pt-6 pb-7">
                        <div
                          className="flex justify-start items-center mr-50px cursor-pointer"
                          onClick={this.openCancelledPopup}>
                          <div className="text-base font-medium text-mytLitePurpleTextDefaultV1 mr-2.5">
                            {this.state.mySessionAllDataV1?.total_cancelled} Cancelled
                          </div>
                          <img
                            src="https://images.myyogateacher.com/web_images/chevron-right-icon._v1svg.svg"
                            alt="right_arrow_v1"
                          />
                        </div>
                        <div
                          className="flex justify-start items-center mr-50px cursor-pointer"
                          onClick={this.openNoShowPopup}>
                          <div className="text-base font-medium text-mytLitePurpleTextDefaultV1 mr-2.5">
                            {this.state.mySessionAllDataV1?.total_no_show} No show
                          </div>
                          <img
                            src="https://images.myyogateacher.com/web_images/chevron-right-icon._v1svg.svg"
                            alt="right_arrow_v1"
                          />
                        </div>
                        <div
                          className="flex justify-start items-center  cursor-pointer"
                          onClick={this.replacedPopupOpen}>
                          <div className="text-base font-medium text-mytLitePurpleTextDefaultV1 mr-2.5">
                            {this.state.mySessionAllDataV1?.total_replaced} Replaced
                          </div>
                          <img
                            src="https://images.myyogateacher.com/web_images/chevron-right-icon._v1svg.svg"
                            alt="right_arrow_v1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="myt_shadow_v1 bg-white rounded-lg mt-10">
                    <div className="flex justify-start items-center h-14 pl-7">
                      <div className="w-64 text-13.5 font-medium text-black  mr-0">Title</div>
                      <div className="relative w-181px">
                        <div
                          ref={this.classTypeFilterRef}
                          onClick={this.classFilterPopupClickOpen}
                          className="cursor-pointer text-13.5 font-medium text-black flex justify-start items-center">
                          <span className="mr-2">Class type</span>
                          <img
                            className="mt-1"
                            src="https://images.myyogateacher.com/web_images/arrow-down-medium.svg"
                            alt="arrow-down"
                          />
                        </div>
                        {this.state.activeClassFilter && (
                          <div className="bg-primary w-2 h-2 rounded-full absolute top-0 right-19"></div>
                        )}
                        <div>
                          {this.state.classFilterPopup && (
                            <div className="pt-25px pb-25px pl-25px pr-25px absolute z-50 w-max shadow-md top-10 right-0 bg-white rounded-lg border border-gray">
                              <div
                                onClick={() =>
                                  this.classFilterSelectedData(this.state.classFilterData[0])
                                }
                                className={`text-left cursor-pointer text-15.5 font-semibold hover:text-primary listDataTimeFilter ${
                                  this.state.classFilterData[0].classType ===
                                  this.state.selectedClassFilterPopupData
                                    ? 'text-primary'
                                    : 'text-black'
                                }`}>
                                {this.state.classFilterData[0].classType}
                              </div>
                              {this.state.classFilterData?.map((classType, a) => {
                                return (
                                  <>
                                    {this.state.classFilterDataType?.map((each) => {
                                      return (
                                        <>
                                          {each === classType?.classFilterType ? (
                                            <>
                                              <div
                                                key={233434 + a}
                                                onClick={() =>
                                                  this.classFilterSelectedData(classType)
                                                }
                                                className={`text-left cursor-pointer text-15.5 font-semibold hover:text-primary listDataTimeFilter ${
                                                  classType?.classType ===
                                                  this.state.selectedClassFilterPopupData
                                                    ? 'text-primary'
                                                    : 'text-black'
                                                } whitespace-nowrap`}>
                                                {classType?.classType}
                                              </div>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="w-130px text-13.5 font-medium text-black pl-0.2">
                        Time ({this.state.mySessionAllDataV1?.teacher_timezone_str})
                      </div>
                      <div className="relative w-181px pl-0.2">
                        <div
                          ref={this.durationFilterRef}
                          onClick={this.durationFilterClickOpen}
                          className="cursor-pointer text-13.5 font-medium text-black flex justify-start items-center">
                          <span className="mr-2">Duration</span>
                          <img
                            className="mt-1"
                            src="https://images.myyogateacher.com/web_images/arrow-down-medium.svg"
                            alt="arrow-down"
                          />
                        </div>
                        {this.state.activeDurationFilter && (
                          <div className="bg-primary w-2 h-2 rounded-full absolute top-0 right-88"></div>
                        )}
                        <div>
                          {this.state.timeFilterPopup && (
                            <div className="pt-25px pb-25px pl-25px pr-25px absolute z-50 w-180px shadow-md top-10 right-0 bg-white rounded-lg border border-gray">
                              <div
                                onClick={() => this.selectedTimeDuration(this.state.timeFilter[0])}
                                className={`text-left cursor-pointer text-15.5 font-semibold hover:text-primary listDataTimeFilter ${
                                  this.state.timeFilter[0].duration ===
                                  this.state.selectedTimeFilterPopup
                                    ? 'text-primary'
                                    : 'text-black'
                                }`}>
                                {this.state.timeFilter[0].duration}
                              </div>
                              {this.state.timeFilter?.map((timeList, b) => {
                                return (
                                  <>
                                    {this.state.timeFilterDataType?.map((each) => {
                                      return (
                                        <>
                                          {each === timeList?.time ? (
                                            <>
                                              <div
                                                key={23311 + b}
                                                onClick={() => this.selectedTimeDuration(timeList)}
                                                className={`text-left cursor-pointer text-15.5 font-semibold hover:text-primary listDataTimeFilter ${
                                                  timeList?.duration ===
                                                  this.state.selectedTimeFilterPopup
                                                    ? 'text-primary'
                                                    : 'text-black'
                                                }`}>
                                                {timeList?.duration}
                                              </div>
                                            </>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="w-22rem text-13.5 font-medium text-mytgrey-lite"></div>
                    </div>

                    {Object.keys(this.state.dateObj) !== 0 ? (
                      <>
                        {Object.keys(this.state.dateObj).map((data, d) => {
                          return (
                            <div key={this.state.dateObj[data][0]?.epoch_start_time + d + 6854}>
                              {this.state.dateObj[data].length ? (
                                <div className="text-mytgrey-lite pl-7 h-11 bg-mytMysessionBg border shadow-session flex justify-start items-center text-10px font-semibold border-r-0 border-l-0 border-b-0 uppercase">
                                  {moment(this.state.dateObj[data][0]?.start_date).format(
                                    'DD MMMM YYYY'
                                  )}{' '}
                                  <span className="ml-1">
                                    ({this.state.dateObj[data]?.length} Classes)
                                  </span>
                                </div>
                              ) : null}
                              {this.state.dateObj[data].map((dataList, k) => {
                                let show_to_do = dataList?.session_recap_text.indexOf('2/2') === -1;
                                let sessionStatus = dataList?.session_status_strings || [];
                                return (
                                  // <div onClick={() => this.RecapVideoPopupClick(dataList)} className='cursor-pointer pl-7 pr-7 flex justify-start items-center h-64px sessionList_v1' key={dataList?.epoch_start_time + k + 1}>
                                  <div
                                    onClick={() => this.redirectToClassDetails(dataList)}
                                    tabIndex="-1"
                                    onKeyDown={(e) => {
                                      if (isDevMode()) {
                                        this.copyDataInDevMode(e, dataList);
                                      }
                                    }}
                                    className="cursor-pointer pl-7 pr-7 flex justify-start items-center h-64px sessionList_v1"
                                    key={dataList?.epoch_start_time + k + 1}>
                                    {this.render_student_name_link(dataList)}
                                    <div className="text-13 font-medium text-mytgrey-lite w-181px">
                                      {dataList?.session_type === 'GROUP_SESSION'
                                        ? 'Group Class'
                                        : ''}
                                      {dataList?.session_type === 'YOGA' ? '1 on 1 Class' : ''}
                                      {dataList?.session_type === 'GROUP_SESSION_STUDENT'
                                        ? 'Group Session Student'
                                        : ''}
                                      {dataList?.session_type === 'VIDEO_CONSULT'
                                        ? 'Video Consult'
                                        : ''}
                                      {dataList?.session_type === 'WORKSHOP_SESSION'
                                        ? 'Workshop'
                                        : ''}
                                      {dataList?.session_type === 'WORKSHOP_SESSION_STUDENT'
                                        ? 'Workshop Student'
                                        : ''}
                                      {dataList?.session_type === 'YOGA_WITH_FRIENDS_SESSION'
                                        ? 'Yoga with Friends'
                                        : ''}
                                      {dataList?.session_type ===
                                      'YOGA_WITH_FRIENDS_SESSION_STUDENT'
                                        ? 'Yoga with Friends Student'
                                        : ''}
                                      {dataList?.session_type === 'BACK_PAIN_CONSULT'
                                        ? 'Back Pain Consult'
                                        : ''}
                                      {dataList?.session_type === 'BACK_PAIN_THERAPY'
                                        ? 'Back Pain 1-on-1'
                                        : ''}
                                      {dataList?.session_type === 'BACK_PAIN_GROUP'
                                        ? 'Back Pain Group'
                                        : ''}
                                    </div>
                                    <div className="text-13 font-medium text-mytgrey-lite w-130px">
                                      {dataList?.start_time.split('IST')}
                                    </div>
                                    <div className="text-13 font-medium text-mytgrey-lite w-90px">
                                      {dataList?.duration
                                        ? `${dataList?.duration} ${'mins'}`
                                        : '--'}
                                    </div>
                                    {dataList?.status !== 'FINISHED' ? (
                                      <>
                                        <div
                                          className="w-26rem flex justify-end items-center"
                                          onClick={() => this.cancelledSessionPopupClick(dataList)}>
                                          {sessionStatus?.length !== 0 && dataList?.session_uuid ? (
                                            this.getSessionStatus(dataList, sessionStatus)
                                          ) : session_status_value?.includes(
                                              dataList?.status.toString()
                                            ) && dataList?.status !== 'CANCELLED' ? (
                                            <button
                                              onClick={(e) => this.openQuestionPopup(e, dataList)}
                                              className="btn btn-primary h-39px text-13">
                                              {dataList?.status_text}
                                            </button>
                                          ) : (
                                            <div className="text-13 font-medium text-mytLabelRed flex justify-end items-center">
                                              {dataList?.status_text}
                                            </div>
                                          )}
                                          <img
                                            className="ml-8"
                                            src="https://images.myyogateacher.com/web_images/session_right_arrow.svg"
                                            alt="right-arrow-v1"
                                          />
                                        </div>
                                      </>
                                    ) : null}
                                    {dataList?.status === 'FINISHED' ? (
                                      <div
                                        className={`w-26rem flex ${sessionStatus?.length !== 0 && dataList?.session_uuid ? 'justify-between' : 'justify-end'}  items-center`}>
                                        {sessionStatus?.length !== 0 && dataList?.session_uuid && (
                                          <>
                                            <div className="flex items-center">
                                              {this.getSessionStatus(dataList, sessionStatus)}
                                            </div>
                                          </>
                                        )}
                                        <div className="text-13 font-medium text-mytgrey-lite flex justify-end items-center">
                                          {/*This code below is to show create relief plan popup
                                           */}{' '}
                                          <div className="relative mr-8">
                                            {show_to_do &&
                                            dataList?.session_type === 'BACK_PAIN_CONSULT' &&
                                            dataList?.roadmap_completed === 0 ? (
                                              <>
                                                <button
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (!dataList.consultation_form_submitted) {
                                                      this.setState({
                                                        follow_up_popup: true,
                                                        selected_session_details: dataList
                                                      });
                                                    } else if (dataList?.roadmap_completed === 0) {
                                                      this.setState({
                                                        create_therapy_plan_popup: true,
                                                        selected_session_details: dataList
                                                      });
                                                    } else if (dataList?.session_notes.length > 0) {
                                                      this.setState({
                                                        selected_session_details: dataList,
                                                        editSessionNotesPopup: true
                                                      });
                                                    }
                                                  }}
                                                  className="btn btn-primary h-39px">
                                                  {!dataList.consultation_form_submitted
                                                    ? 'Post Consultation Form'
                                                    : dataList?.roadmap_completed === 0
                                                      ? 'Create Relief Plan'
                                                      : ''}
                                                </button>
                                                {(!dataList.consultation_form_submitted &&
                                                  dataList?.session_type !== 'BACK_PAIN_THERAPY') ||
                                                (dataList.roadmap_completed === 0 &&
                                                  dataList?.session_type !==
                                                    'BACK_PAIN_THERAPY') ? (
                                                  <img
                                                    className="absolute todo_btn_v1"
                                                    src="https://images.myyogateacher.com/web_images/todo_pic_v1.svg"
                                                    alt="todo"
                                                  />
                                                ) : null}
                                              </>
                                            ) : null}
                                          </div>
                                          {dataList?.session_type !== 'BACK_PAIN_CONSULT' ? (
                                            <div className="relative flex items-center gap-8">
                                              {show_to_do ? (
                                                <>
                                                  {dataList?.session_type === 'GROUP_SESSION' ? (
                                                    <>
                                                      <button
                                                        onClick={(e) =>
                                                          this.openRecapPopup(
                                                            e,
                                                            dataList.session_uuid
                                                          )
                                                        }
                                                        className="btn btn-primary h-39px">
                                                        Attendance Recap
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <button
                                                      onClick={(e) =>
                                                        this.openQuestionPopup(e, dataList)
                                                      }
                                                      className="btn btn-primary h-39px relative">
                                                      Session recap
                                                      <img
                                                        className="absolute todo_btn_v1"
                                                        src="https://images.myyogateacher.com/web_images/todo_pic_v1.svg"
                                                        alt="todo"
                                                      />
                                                    </button>
                                                  )}
                                                  <img
                                                    src="https://images.myyogateacher.com/web_images/session_right_arrow.svg"
                                                    alt="right-arrow-v1"
                                                  />
                                                </>
                                              ) : (
                                                <button
                                                  onClick={(e) =>
                                                    this.openQuestionPopup(e, dataList)
                                                  }
                                                  className="btnGray h-39px text-13">
                                                  Session recap
                                                </button>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <Footer />
            </CheckMobile>
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    my_session_status: state.availability.my_session_status,
    my_session_response: state.availability.my_session_response,

    my_session_data_status: state.availability.my_session_data_status,
    my_session_data_response: state.availability.my_session_data_response,
    config_values: state.home.config_values
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMySessionV1: (payload) => {
      dispatch(actions.getMySessionV1(payload));
    },
    getMySessionData: (payload) => {
      dispatch(actions.getMySessionData(payload));
    },
    set_availability_variable: (payload) => {
      dispatch(actions.set_availability_variable(payload));
    }
  };
};

const MySessionsWithRedux = connect(mapStateToProps, mapDispatchToProps)(MySessions);
const MySessionsWithRouterRedux = withRouter(MySessionsWithRedux);

export default MySessionsWithRouterRedux;
