import React from 'react';
import { analytics_track, get_time_taken } from '../../../util_functions';
import './requirment.css';

const on_signup_click = (scroll_fn, start_time) => {
	scroll_fn();
	const options = {
		referrer_component: 'home-requirements',
		plan: 'guest',
		time_since_page_load: get_time_taken(start_time),
	};
	analytics_track(`signup link click`, options);
};

const Requirment = (props) => {
	return (
		<div className='requiment-get-started'>
			<div className='container'>
				<h2>Requirements to get started</h2>
				<p className='subpara mb-hide'>Teach yoga to the world from the comfort of your home</p>
				<p className='divider-line'>
					<img src='https://images.myyogateacher.com/Teacher-Page/Dividerm2.png' alt='div' />
				</p>

				<div className='tp-circular-div'>
					<div className='tp-circular-image-container'>
						<img
							className='tp-circular-img-back center-mandala'
							src='https://images.myyogateacher.com/Teacher-Page/Mandala@2x.png'
							alt='man'
						/>
						<img
							className='tp-circular-img'
							src='https://images.myyogateacher.com/Teacher-Page/img.png'
							alt='cir'
						/>
						<div className='tp-circular-img-btns'>
							<div className='tp-circular-btn-container'>
								<a className='tp-c-btn btn-c-1'>
									<img src='https://images.myyogateacher.com/Teacher-Page/icon-01.png' alt='ic' />
									<span>English language proficiency</span>
								</a>

								<a className='tp-c-btn btn-c-2'>
									<img src='https://images.myyogateacher.com/Teacher-Page/icon-02.png' alt='ic' />
									<span>Phone, computer or laptop</span>
								</a>

								<a className='tp-c-btn btn-c-3'>
									<img src='https://images.myyogateacher.com/Teacher-Page/icon-03.png' alt='ic' />
									<span>Place to practice</span>
								</a>

								<a className='tp-c-btn btn-c-4'>
									<img src='https://images.myyogateacher.com/Teacher-Page/icon-04.png' alt='ic' />
									<span>Fast internet connection</span>
								</a>

								<a className='tp-c-btn btn-c-5'>
									<img src='https://images.myyogateacher.com/Teacher-Page/icon-05.png' alt='ic' />
									<span>Minimum 1 year of teaching experience</span>
								</a>

								<a className='tp-c-btn btn-c-6'>
									<img src='https://images.myyogateacher.com/Teacher-Page/icon-06.png' alt='ic' />
									<span>Yoga mat</span>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='heading-inside-signup'>
					<h1>Are you ready to discover the joy of teaching online?</h1>
					<a
						className='btn-border-dash req-signup'
						onClick={() => on_signup_click(props.go_to_signup, props.start_time)}
					>
						<span>Sign up</span>
						<img
							className='icon'
							src='https://images.myyogateacher.com/home-page/arrow+next.png'
							alt='ic'
						/>
					</a>
				</div>

				<div className='mobile-requiment-sec'>
					<div className='mrow-req'>
						<ul>
							<li>
								<img src='https://images.myyogateacher.com/Teacher-Page/icon-01.png' alt='ic' />
								<h4>English language proficiency</h4>
							</li>
							<li>
								<img src='https://images.myyogateacher.com/Teacher-Page/icon-02.png' alt='ic' />
								<h4>Phone, computer or laptop</h4>
							</li>
							<li>
								<img src='https://images.myyogateacher.com/Teacher-Page/icon-03.png' alt='ic' />
								<h4>Place to practice</h4>
							</li>
							<li>
								<img src='https://images.myyogateacher.com/Teacher-Page/icon-04.png' alt='ic' />
								<h4>Fast internet connection</h4>
							</li>
							<li>
								<img src='https://images.myyogateacher.com/Teacher-Page/icon-05.png' alt='ic' />
								<h4>Minimum 1 year of teaching experience</h4>
							</li>
							<li>
								<img src='https://images.myyogateacher.com/Teacher-Page/icon-06.png' alt='ic' />{' '}
								<h4>Yoga mat</h4>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div className='rowinside-req'>
				<div className='col4-req'>
					<div className='tp-req-contain'>
						<img
							className='tp-req'
							src='https://images.myyogateacher.com/Teacher-Page/img-kanika-mandala.png'
							alt='man'
						/>
					</div>
				</div>
				<div className='col8-req'>
					<h2>Are you ready to discover the joy of teaching online?</h2>

					<div className='req-signup'>
						<a
							className='btn-border-dash'
							onClick={() => on_signup_click(props.go_to_signup, props.start_time)}
						>
							<span>Sign up</span>
							<img
								className='icon'
								src='https://images.myyogateacher.com/home-page/arrow+next.png'
								alt='ic'
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Requirment;
