// import React from 'react';
// import './index.css';

// interface IRadioButtonProps {
//     forCheck?: string;
//     id: string;
//     className?: string;
//     label: string;
//     value: string;
//     onClick_v2: any;
//     isChecked?: boolean;
//     name: string;
//     disabled?: boolean;
// }

// const RadioButton = ({ forCheck, id, className, label, name, value, onClick_v2, isChecked, disabled }: IRadioButtonProps) => {
//     return (
//         <div className="custom_radio_v2">
//             <input type='radio' id={id + '12'} value={value} name={name} checked={isChecked} disabled={disabled} onChange={(e) => onClick_v2(e)} />
//             <label htmlFor={id + '12'}>{label}</label>
//         </div>
//     );
// };

// export default RadioButton;


import React from 'react';
import './index.css';
interface ICheckBoxProps {
    forCheck?: string;
    id: string;
    className?: string;
    label: string;
    value: string;
    onClick: any;
    isChecked?: boolean;
    name: string;
    disabled?: boolean;
}

const RadioButton = ({ forCheck, id, className, label, name, value, onClick, isChecked, disabled }: ICheckBoxProps) => {
    return (
        <>
            <div>
                <label className="flex items-center w-101px cursor-pointer">
                    <div className="radio1_on_1">
                        <input type="radio" id={id} value={value} name={name} checked={isChecked} disabled={disabled} onChange={(e) => onClick(e)} />
                        <label htmlFor={forCheck}></label>
                    </div>
                    <label
                        htmlFor={id}
                        className={`whitespace-nowrap pointer-events-none font-inter sm:text-13.5px zm:text-13.5px font-medium sm:pr-10px cursor-pointer ${className} ${isChecked === true ? 'text-primary' : ' sm:text-mytLabel zm:text-mytLabel sm:text-opacity-60'}`}
                    >
                        {label}
                    </label>
                </label>
            </div>
        </>
    );
};

export default RadioButton;