import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../styles/PlayerControler.css';
import '@appigram/react-rangeslider/lib/index.css';
import Slider from '@appigram/react-rangeslider';

interface IState {

}
interface IMapStateToProps {
    played: number;
    playing: Boolean;
    volume: number;
}

interface IMapDispatchToProps {
    onPlayPause: (payload?: any) => void;
    changeSliderValue: (payload?: any) => void;
    handleSeekMouseUp: (payload?: any) => void;
    handleSeekMouseDown: (payload?: any) => void;
    handleClickFullscreen: (payload?: any) => void;
    onVolumeChange: (payload?: any) => any;
}

type PlayerControllerProps = IMapStateToProps & IMapDispatchToProps;

class PlayerControler extends React.Component<PlayerControllerProps, IState> {

    constructor(props) {
        super(props);
    }

    render() {
        let {
            onPlayPause,
            changeSliderValue,
            handleSeekMouseUp,
            handleSeekMouseDown,
            handleClickFullscreen,
            onVolumeChange,
            played,
            playing,
            volume
        } = this.props;
        return (
            <div className='controlsWrapper'>
                <div className="vp-controls">
                    <button type="button" onClick={onPlayPause} className="play rounded-box state-paused" aria-label="Play">
                        <div className="play-icon">
                            {
                                playing ? <svg className='play-icon-svg' viewBox="0 0 20 20" preserveAspectRatio="xMidYMid" focusable="false" aria-hidden="true">
                                    <rect className="fill" width="6" height="20" x="0" y="0"></rect>
                                    <rect className="fill" width="6" height="20" x="12" y="0"></rect>
                                </svg> : <svg className='play-icon-svg' viewBox="0 0 20 20" preserveAspectRatio="xMidYMid" focusable="false"
                                    aria-hidden="true">
                                    <polygon className="fill" points="1,0 20,10 1,20"></polygon>
                                </svg>
                            }
                        </div>
                    </button>
                    <div className="play-bar rounded-box">

                        <div className="vp-progress">
                            <Slider
                                min={0}
                                max={100}
                                value={played * 100}
                                tooltip={false}
                                onChangeStart={handleSeekMouseDown}
                                onChangeComplete={handleSeekMouseUp}
                                onChange={changeSliderValue}
                            />
                            {/* <div className="focus-target" role="slider" aria-label="Progress Bar" aria-valuemin={0} aria-valuemax={203}
                                aria-valuenow={0} tabIndex={0}></div>
                            <div className="loaded"></div>
                            <div className="played"></div>
                            <div className="cuepoints"></div>
                            <div className="chapters"></div> */}
                            {/* <input id="large-range" type="range" min="0" max="100" value={played * 100} onChange={changeSliderValue} onMouseUp={handleSeekMouseUp} onMouseDown={handleSeekMouseDown} className="w-full h-3 bg-gray-200 cursor-pointer range-lg dark:bg-gray-700"></input> */}


                            {/* <div className="ghost-timecode invisible hidden" role="presentation" aria-hidden="true" style={{ left: "77.833%" }}>
                                <div className="box">02:38</div>
                            </div>
                            <div className="timecode" role="presentation" aria-hidden="true">
                                <div className="box">03:23</div>
                            </div> */}
                        </div>
                        <div className="volume" role="slider" aria-label="Volume (use up/down arrow keys to change)" aria-valuemin={0}
                            aria-valuemax={1} tabIndex={0} aria-valuenow={1.000} aria-valuetext={'100%'}>
                            <div className={volume >= 0.2 ? 'activeVolume' : ''} onClick={() => onVolumeChange(0.2)}></div>
                            <div className={volume >= 0.4 ? 'activeVolume' : ''} onClick={() => onVolumeChange(0.4)}></div>
                            <div className={volume >= 0.6 ? 'activeVolume' : ''} onClick={() => onVolumeChange(0.6)}></div>
                            <div className={volume >= 0.8 ? 'activeVolume' : ''} onClick={() => onVolumeChange(0.8)}></div>
                            <div className={volume >= 1 ? 'activeVolume' : ''} onClick={() => onVolumeChange(1)}></div>
                        </div>
                        <div className='hdClass'>
                            <i>HD</i>
                        </div>
                        <button type="button" className="fullscreen" aria-label="Enter full screen" onClick={handleClickFullscreen}>
                            <div className="fullscreen-icon">
                                <svg viewBox="0 0 12 12" preserveAspectRatio="xMidYMid" focusable="false"
                                    aria-hidden="true">
                                    <polyline className="fill" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9" transform="translate(6,6)">
                                    </polyline>
                                    <polyline className="fill" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9"
                                        transform="translate(6,6) rotate(90)"></polyline>
                                    <polyline className="fill" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9"
                                        transform="translate(6,6) rotate(180)"></polyline>
                                    <polyline className="fill" points="6,6 5.9,2 4.9,3 2.9,1 1,2.9 3,4.9 2,5.9"
                                        transform="translate(6,6) rotate(270)"></polyline>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
});

const PlayerControlerWithRedux = connect(mapStateToProps, mapDispatchToProps)(PlayerControler);

export default PlayerControlerWithRedux;