export const capitalizeFirstLetter = (string) => {
  try{
    return (
      string.charAt(0).replace(/[_-]/g, " ").toUpperCase() +
      string.slice(1).replace(/[_-]/g, " ")
    );
  }
  catch(e){
    return ''
  }
};

export const removeSeperators = (string) => {
  return string.replace(/[_-]/g, " ");
};

export const captialLetters = (string) => {
  return string.replace(/[_-]/g, " ").toUpperCase();
};
