import { all, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import moment from 'moment-timezone';
import * as t from '../action_types';
import * as actions from '../action_creators';
// import * as selectors from "../selectors";
import { setCookie, analytics_alias } from '../../util_functions';
import { reset_password_api, login_api } from '../api_functions/home_api';
import { analytics_identify } from '../../util_functions';
import { post_api, get_api, put_api } from '../api_funcs';
import urls from '../../urls';

export function* setPassword(action) {
	try {
		yield put(actions.set_home_variable('password_loading', true));
		// const token = getCookie('teacher_token_1');
		yield call(reset_password_api, action.payload);
		yield put(actions.set_home_variable('password_loading', false));
		yield put(actions.set_home_variable('reset_password_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_availability_variable('availability_submit_status', 'fail'));
	}
}

export function* createRoadmap(action) {
	try {
		yield put(actions.set_home_variable('create_roadmap_status', 'loading'));
		const url = urls.create_roadmap;
		const payload = action.payload;
		// const result = yield call(post_api, url, null, true);
		yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('create_roadmap_status', 'success'));
		// yield put(actions.get_student_details());
		yield put(actions.load_short_dashboard_sessions());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('create_roadmap_status', 'fail'));
	}
}

export function* GetYogaJourney(action) {
	try {
		yield put(actions.set_home_variable('get_yoga_journey_status', 'loading'));
		// const url = urls.get_yoga_journey;
		const url = urls.get_yoga_journey_by_slug;
		const payload = action.payload;
		// const result = yield call(post_api, url, null, true);
		const result = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('yoga_journey_values', result.data));

		yield put(actions.set_home_variable('get_yoga_journey_status', 'success'));

		// yield put(actions.get_student_details());
		// yield put(actions.get_card_details());
		//segment_track("EV : card details added");
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('get_yoga_journey_status', 'fail'));
	}
}

export function* updateSessionRecap(action) {
	try {
		yield put(actions.set_home_variable('update_session_recap_status', 'loading'));
		const url = urls.update_session_recap;
		const payload = action.payload;
		// const result = yield call(post_api, url, null, true);
		const result = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('update_session_recap_values', result.data));
		yield put(actions.set_home_variable('update_session_recap_status', 'success'));
		// yield put(actions.get_student_details());
		// yield put(actions.get_card_details());
		//segment_track("EV : card details added");
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('update_session_recap_status', 'fail'));
	}
}

export function* updateRoadmapQuestion(action) {
	try {
		yield put(actions.set_home_variable('update_roadmap_ques_status', 'loading'));
		const url = urls.update_roadmap_question;
		const payload = action.payload;
		// const result = yield call(post_api, url, null, true);
		const result = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('update_roadmap_ques_values', result.data));
		yield put(actions.set_home_variable('update_roadmap_ques_status', 'success'));
		// yield put(actions.get_student_details());
		// yield put(actions.get_card_details());
		//segment_track("EV : card details added");
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('update_roadmap_ques_status', 'fail'));
	}
}

export function* updateRoadmap(action) {
	try {
		yield put(actions.set_home_variable('update_whole_roadmap_status', 'loading'));
		const url = urls.update_roadmap;
		const payload = action.payload;
		// const result = yield call(post_api, url, null, true);
		yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('update_whole_roadmap_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('update_whole_roadmap_status', 'fail'));
	}
}

export function* logInToApp(action) {
	try {
		yield put(actions.set_home_variable('login_status', 'loading'));
		const response = yield call(login_api, action.payload);
		setCookie('teacher_token_1', response.data.token, 864000);
		yield put(actions.set_home_variable('teacher_token', response.data.token));
		yield put(actions.set_home_variable('login_status', 'success'));
		localStorage.removeItem('teacherLocalChannels');
		analytics_alias(response.data.teacher_uuid);
		analytics_identify(response.data.teacher_uuid, response.data.name, action.payload.email);
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('login_error_msg', error.response.data.message));
		yield put(actions.set_home_variable('login_status', 'fail'));
	}
}

export function* signUp(action) {
	try {
		const url = urls.signup_app;
		yield put(actions.set_home_variable('signup_status', 'loading'));
		const payload = {
			...action.payload,
			timezone: new Date().getTimezoneOffset(),
		};
		yield call(post_api, url, payload);
		yield put(actions.set_home_variable('signup_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('signup_status', 'fail'));
	}
}

export function* forgotPassword(action) {
	try {
		yield put(actions.set_home_variable('forgot_status', 'loading'));
		const update_url = urls.reset_password;
		yield call(post_api, update_url, action.payload);
		yield put(actions.set_home_variable('forgot_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('forgot_error_msg', error.response.data.error));
		yield put(actions.set_home_variable('forgot_status', 'fail'));
	}
}

export function* loadApplication() {
	try {
		yield put(actions.set_home_variable('load_application_status', 'loading'));
		const update_url = urls.teacher_details;
		const payload = {
			iana_timezone: moment.tz.guess(true),
			timezone: new Date().getTimezoneOffset(),
		};
		const result = yield call(post_api, update_url, payload, true);

		analytics_identify(result.data.details.uuid, '', result.data.details.email, result.data.details.first_name);
		if (result.data && result.data.details && result.data.details.uuid) {
			setCookie('teacher_uuid', result.data.details.uuid);
		}
		yield put(actions.set_profile_variable('teacher_details', result.data.details));
		yield put(actions.set_home_variable('load_application_status', 'success'));
	} catch (error) {
		console.log(error.response);
		try {
			if (error.response.status == 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_home_variable('load_application_status', 'fail'));
	}
}

export function* getConfig() {
	try {
		yield put(actions.set_loading_variable('config_status', 'loading'));
		const url = urls.config;
		const result = yield call(get_api, url, false);
		yield put(actions.set_home_variable('config_values', result.data));
		yield put(actions.set_loading_variable('config_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		yield put(actions.set_loading_variable('config_status', 'fail'));
	}
}

export function* loadMySessions(action) {
	try {
		let more_list = action.payload;
		yield put(actions.set_loading_variable('my_sessions_status', 'loading'));
		const url = urls.my_sessions;
		const d = new Date();
		let short_list = more_list === 0 ? 0 : 1;
		const payload = {
			iana_timezone: moment.tz.guess(true),
			timezone: d.getTimezoneOffset(),
			short_list,
		};
		const result = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('my_sessions', [...result.data.my_sessions]));
		if (short_list === 0) {
			yield put(actions.set_home_variable('more_my_sessions', 1));
		}
		yield put(actions.set_loading_variable('my_sessions_status', 'success'));
	} catch (error) {
		yield put(actions.set_loading_variable('my_sessions_status', 'fail'));
		if (error.response.status === 401) {
			yield put(actions.log_out());
		}
	}
}

export function* getStudents(action) {
	try {
		yield put(actions.set_loading_variable('getStudentsStatus', 'loading'));
		const url = urls.getStudents;
		const result = yield call(post_api, url, action.payload, true);
		yield put(actions.set_home_variable('studentsList', result.data));
		yield put(actions.set_loading_variable('getStudentsStatus', 'success'));
	} catch (error) {
		yield put(actions.set_loading_variable('getStudentsStatus', 'fail'));
		if (error.response.status === 401) {
			yield put(actions.log_out());
		}
	}
}

export function* updateStatus(action) {
	try {
		const url = urls.update_status;
		const payload = {
			status: action.payload,
		};
		const result = yield call(post_api, url, payload, true);
		yield put(actions.get_teacher_details());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		console.log(error);
	}
}

// workshops

export function* getAllWorkshops(action) {
	try {
		const url = urls.get_all_workshops;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('getAllWorkshopStatus', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('getAllWorkshopsResponse', response.data.response));
		yield put(actions.set_home_variable('getAllWorkshopStatus', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('getAllWorkshopStatus', 'fail'));
		// yield put(actions.handle_error_from_api(err_payload));
		//segment_track('EV :  card details failed');
	}
}

export function* getAllWorkshopsWoToken(action) {
	try {
		const url = urls.get_all_workshops_wo_token;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('getAllWorkshopStatus', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('getAllWorkshopsResponse', response.data.response));
		yield put(actions.set_home_variable('getAllWorkshopStatus', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('getAllWorkshopStatus', 'fail'));
		// yield put(actions.handle_error_from_api(err_payload));
		//segment_track('EV :  card details failed');
	}
}

export function* getWorkshopDetails(action) {
	try {
		const url = urls.get_workshop_details;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('workshopDetailsStatus', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('workshopDetailsResponse', response.data.response));
		yield put(actions.set_home_variable('workshopDetailsStatus', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('workshopDetailsStatus', 'fail'));
		// yield put(actions.handle_error_from_api(err_payload));
		//segment_track('EV :  card details failed');
	}
}

export function* getWorkshopDetailsWoToken(action) {
	try {
		const url = urls.get_workshop_details_wo_token;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('workshopDetailsStatus', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('workshopDetailsResponse', response.data.response));
		yield put(actions.set_home_variable('workshopDetailsStatus', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('workshopDetailsStatus', 'fail'));
		// yield put(actions.handle_error_from_api(err_payload));
		//segment_track('EV :  card details failed');
	}
}

export function* getNotifications(action) {
	try {
		const url = urls.get_notifications;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('notificationStatus', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('notificationResponse', response.data.results));
		yield put(actions.set_home_variable('notificationStatus', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('notificationStatus', 'fail'));
	}
}

export function* update_notification_read_status(action) {
	try {
		const url = urls.update_notification_read_status;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('update_notification_read_status', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('update_notification_read_status_response', response.data));
		yield put(actions.set_home_variable('update_notification_read_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('update_notification_read_status', 'fail'));
	}
}

export function* get_new_notifications_summary(action) {
	try {
		const url = urls.get_new_notifications_summary;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('get_new_notifications_summary_status', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('get_new_notifications_summary_response', response.data.results));
		yield put(actions.set_home_variable('get_new_notifications_summary_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('get_new_notifications_summary_status', 'fail'));
	}
}

export function* read_announcement(action) {
	try {
		const url = urls.read_announcement;
		var payload = action.payload;
		yield put(actions.set_home_variable('read_announcement_status', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('read_announcement_response', response.data.results));
		yield put(actions.set_home_variable('read_announcement_status', 'success'));
		yield put(actions.get_announcements());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('read_announcement_status', 'fail'));
	}
}

export function* get_announcements(action) {
	try {
		const url = urls.get_announcements;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('get_announcements_status', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('get_announcements_response', response.data));
		yield put(actions.set_home_variable('get_announcements_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
		};

		yield put(actions.set_home_variable('get_announcements_status', 'fail'));
	}
}

export function* get_session_participants(action) {
	try {
		yield put(actions.set_home_variable('selected_dashboard_session_uuid', action.payload.session_uuid));

		const url = urls.get_session_participants;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('get_session_participants_status', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('get_session_participants_response', response.data));
		yield put(actions.set_home_variable('get_session_participants_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('get_session_participants_status', 'fail'));
	}
}

export function* community_proposal_accept(action) {
	try {
		const url = urls.community_proposal_accept;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('community_proposal_accept_status', 'loading'));
		const response = yield call(put_api, url, payload, true);
		yield put(actions.set_home_variable('community_proposal_accept_response', response.data));
		yield put(actions.set_home_variable('community_proposal_accept_status', 'success'));
		yield put(actions.load_short_dashboard_sessions());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('community_proposal_accept_response', err_payload));

		yield put(actions.set_home_variable('community_proposal_accept_status', 'fail'));
	}
}

export function* community_proposal_ignore(action) {
	try {
		const url = urls.community_proposal_ignore;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('community_proposal_ignore_status', 'loading'));
		const response = yield call(put_api, url, payload, true);
		yield put(actions.set_home_variable('community_proposal_ignore_response', response.data));
		yield put(actions.set_home_variable('community_proposal_ignore_status', 'success'));
		yield put(actions.load_short_dashboard_sessions());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('community_proposal_ignore_response', err_payload));

		yield put(actions.set_home_variable('community_proposal_ignore_status', 'fail'));
	}
}

export function* accept_session_replacement(action) {
	try {
		const url = urls.accept_session_replacement.replace('SESSION_UUID', action.payload.session_uuid);

		console.log('url', url);
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('accept_session_replacement_status', 'loading'));
		const response = yield call(put_api, url, payload, true);
		console.log('response', response);
		yield put(actions.set_home_variable('accept_session_replacement_response', response.data));
		yield put(actions.set_home_variable('accept_session_replacement_status', 'success'));

		yield put(actions.load_short_dashboard_sessions());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error: error.response.data,
			type: action.type,
		};

		yield put(actions.set_home_variable('accept_session_replacement_response', err_payload));

		yield put(actions.set_home_variable('accept_session_replacement_status', 'fail'));
	}
}

export function* reject_session_replacement(action) {
	try {
		const url = urls.reject_session_replacement.replace('SESSION_UUID', action.payload.session_uuid);

		var payload = { ...action.payload };
		yield put(actions.set_home_variable('reject_session_replacement_status', 'loading'));
		const response = yield call(put_api, url, payload, true);

		yield put(actions.set_home_variable('reject_session_replacement_response', response.data));
		yield put(actions.set_home_variable('reject_session_replacement_status', 'success'));
		yield put(actions.load_short_dashboard_sessions());
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error: error.response.data,
			type: action.type,
		};

		yield put(actions.set_home_variable('reject_session_replacement_response', err_payload));

		yield put(actions.set_home_variable('reject_session_replacement_status', 'fail'));
	}
}

export function* get_community_profile(action) {
	try {
		const url = urls.get_community_profile;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('get_community_profile_status', 'loading'));
		const result = yield call(post_api, url, payload, true);

		yield put(actions.set_home_variable('get_community_profile_response', result.data));
		yield put(actions.set_home_variable('get_community_profile_status', 'success'));
	} catch (error) {
		yield put(actions.set_home_variable('get_community_profile_status', 'failure'));

		const err_payload = {
			error,
			type: action.type,
		};
		// yield put(actions.handle_error_from_api(err_payload));
	}
}

export function* get_proposal_info(action) {
	try {
		const url = urls.get_proposal_info;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('get_proposal_info_status', 'loading'));
		const result = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('get_proposal_info_response', result.data));
		yield put(actions.set_home_variable('get_proposal_info_status', 'success'));
	} catch (error) {
		yield put(actions.set_home_variable('get_proposal_info_status', 'failure'));

		const err_payload = {
			error,
			type: action.type,
		};
		// yield put(actions.handle_error_from_api(err_payload));
	}
}

export function* propose_group_class(action) {
	try {
		const url = urls.propose_group_class;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('propose_group_class_status', 'loading'));
		const result = yield call(post_api, url, payload, true);

		yield put(actions.set_home_variable('propose_group_class_response', result.data));
		yield put(actions.set_home_variable('propose_group_class_status', 'success'));
	} catch (error) {
		yield put(actions.set_home_variable('propose_group_class_response', error.response.data));
		yield put(actions.set_home_variable('propose_group_class_status', 'failure'));
	}
}

export function* is_community_class_proposals_create_allowed(action) {
	try {
		const url = urls.is_community_class_proposals_create_allowed;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('is_community_class_proposals_create_allowed_status', 'loading'));
		const result = yield call(post_api, url, payload, true);

		yield put(actions.set_home_variable('is_community_class_proposals_create_allowed_response', result.data));
		yield put(actions.set_home_variable('is_community_class_proposals_create_allowed_status', 'success'));
	} catch (error) {
		yield put(
			actions.set_home_variable('is_community_class_proposals_create_allowed_response', error.response.data)
		);
		yield put(actions.set_home_variable('is_community_class_proposals_create_allowed_status', 'failure'));
	}
}

export function* impersonate_myt_user_teacher_consultancy(action) {
	try {
		const url = urls.impersonate_myt_user_teacher_consultancy;
		var payload = { ...action.payload };
		yield put(actions.set_home_variable('impersonate_myt_user_teacher_consultancy_response_status', 'loading'));
		const response = yield call(post_api, url, payload, true);
		yield put(actions.set_home_variable('impersonate_myt_user_teacher_consultancy_response', response.data));
		yield put(actions.set_home_variable('impersonate_myt_user_teacher_consultancy_response_status', 'success'));
	} catch (error) {
		try {
			if (error.response.status === 401) {
				yield put(actions.log_out());
			}
		} catch (e) {}
		const err_payload = {
			error,
			type: action.type,
		};

		yield put(actions.set_home_variable('impersonate_myt_user_teacher_consultancy_status', 'fail'));
	}
}

export default function* homeSaga() {
	yield all([
		takeEvery(t.set_password, setPassword),
		takeEvery(t.log_in_to_app, logInToApp),
		takeEvery(t.get_config, getConfig),
		takeEvery(t.forgot_password, forgotPassword),
		takeLatest(t.load_application, loadApplication),
		takeEvery(t.sign_up, signUp),
		takeLatest(t.load_my_sessions, loadMySessions),
		takeLatest(t.getStudents, getStudents),
		takeLatest(t.update_status, updateStatus),
		takeEvery(t.create_roadmap, createRoadmap),
		takeEvery(t.update_session_recap, updateSessionRecap),
		takeEvery(t.update_roadmap_question, updateRoadmapQuestion),
		takeEvery(t.update_roadmap, updateRoadmap),
		takeEvery(t.get_yoga_journey, GetYogaJourney),
		takeLatest(t.get_all_workshops, getAllWorkshops),
		takeLatest(t.get_all_workshops_wo_token, getAllWorkshopsWoToken),
		takeLatest(t.get_workshop_details, getWorkshopDetails),
		takeLatest(t.get_workshop_details_wo_token, getWorkshopDetailsWoToken),
		takeLatest(t.get_notifications, getNotifications),
		takeLatest(t.update_notification_read_status, update_notification_read_status),
		takeLatest(t.get_new_notifications_summary, get_new_notifications_summary),
		takeLatest(t.get_announcements, get_announcements),
		takeLatest(t.read_announcement, read_announcement),
		takeLatest(t.get_session_participants, get_session_participants),
		takeLatest(t.community_proposal_accept, community_proposal_accept),
		takeLatest(t.community_proposal_ignore, community_proposal_ignore),
		takeLatest(t.accept_session_replacement, accept_session_replacement),
		takeLatest(t.reject_session_replacement, reject_session_replacement),
		takeLatest(t.get_community_profile, get_community_profile),
		takeLatest(t.get_proposal_info, get_proposal_info),
		takeLatest(t.propose_group_class, propose_group_class),
		takeLatest(t.is_community_class_proposals_create_allowed, is_community_class_proposals_create_allowed),
		takeLatest(t.impersonate_myt_user_teacher_consultancy, impersonate_myt_user_teacher_consultancy),
	]);
}
