import moment from 'moment';
import React, { Component } from 'react';
import { analytics_alias } from '../../../util_functions';


class ChangeAvailibility extends Component {

    constructor(props) {
        super(props);
        this.state = {
            getPropsData: props.editAvailabilitySlotDataObj,
            // payloadData: {},
            startTimeConvert: null,
            endTimeConvert: null,
        }
    }

    componentDidMount() {

        this.setState({
            startTimeConvert: moment(`${this.state.getPropsData['date_time']['start_date']} ${this.state.getPropsData['date_time']['start_time']}`).valueOf(),
            endTimeConvert: moment(`${this.state.getPropsData['date_time']['end_date']} ${this.state.getPropsData['date_time']['end_time']}`).valueOf(),
        })

    }



    editSlotsData(typeOfDate) {

        if (typeOfDate === 'currentDate') {
            let payload = {
                "start_date": this.state.getPropsData?.date_time?.epoch_start_datetime,
                "end_date": this.state.getPropsData?.date_time?.epoch_end_datetime,
                "start_date_date_time": this.state.getPropsData?.date_time?.start_date,
                "end_date_date_time": this.state.getPropsData?.date_time?.end_date,
                "start_date_availability_chron": this.state.getPropsData?.availability_crons[0]?.epoch_start_datetime,
                "end_date_availability_chron": this.state.getPropsData?.availability_crons[0]?.epoch_end_datetime,
                "start_time": this.state.getPropsData?.date_time?.start_time,
                "end_time": this.state.getPropsData?.date_time?.end_time,
                "repeat_schedule": 0,
                "repeat_forever": 0,
                "available_sunday": 0,
                "available_monday": 0,
                "available_tuesday": 0,
                "available_wednesday": 0,
                "available_thursday": 0,
                "available_friday": 0,
                "available_saturday": 0,
                "teacher_schedule_cron_uuid": this.state.getPropsData?.availability_crons[0]?.teacher_schedule_cron_uuid,
                "currentDate": false,
                "trial_only_availability": this.state.getPropsData?.availability_crons[0]?.trial_only_availability,
                "availability_type": 'single',
                "availability_type_dt": 'single',
            }
            this.props.payloadData(payload);

        } else if (typeOfDate === 'futureDate') {
            let payload = {
                "start_date": this.state.getPropsData?.date_time?.epoch_start_datetime,
                "end_date": this.state.getPropsData?.date_time?.epoch_end_datetime,
                "start_date_date_time": this.state.getPropsData?.date_time?.start_date,
                "end_date_date_time": this.state.getPropsData?.date_time?.end_date,
                "start_date_availability_chron": this.state.getPropsData?.availability_crons[0]?.epoch_start_datetime,
                "end_date_availability_chron": this.state.getPropsData?.availability_crons[0]?.epoch_end_datetime,
                "start_time": this.state.getPropsData?.date_time?.start_time,
                "end_time": this.state.getPropsData?.date_time?.end_time,
                "repeat_schedule": this.state.getPropsData?.availability_crons[0]?.repeat_schedule,
                "repeat_forever": 0,
                "available_sunday": this.state.getPropsData?.availability_crons[0]?.available_sunday,
                "available_monday": this.state.getPropsData?.availability_crons[0]?.available_monday,
                "available_tuesday": this.state.getPropsData?.availability_crons[0]?.available_tuesday,
                "available_wednesday": this.state.getPropsData?.availability_crons[0]?.available_wednesday,
                "available_thursday": this.state.getPropsData?.availability_crons[0]?.available_thursday,
                "available_friday": this.state.getPropsData?.availability_crons[0]?.available_friday,
                "available_saturday": this.state.getPropsData.availability_crons[0]?.available_saturday,
                "teacher_schedule_cron_uuid": this.state.getPropsData.availability_crons[0]?.teacher_schedule_cron_uuid,
                "trial_only_availability": this.state.getPropsData.availability_crons[0].trial_only_availability,
                "currentDate": false,
                "availability_type": 'repeat',
                "availability_type_dt": 'future',
            }
            this.props.payloadData(payload);
        } else if (typeOfDate === 'originalDate') {

            let payload = {
                "start_date": this.state.getPropsData?.date_time?.epoch_start_datetime,
                "end_date": this.state.getPropsData?.date_time?.epoch_end_datetime,
                "start_date_date_time": this.state.getPropsData?.date_time?.start_date,
                "end_date_date_time": this.state.getPropsData?.date_time?.end_date,
                "start_date_availability_chron": this.state?.getPropsData.availability_crons[0]?.epoch_start_datetime,
                "end_date_availability_chron": this.state?.getPropsData.availability_crons[0]?.epoch_end_datetime,
                "start_time": this.state.getPropsData?.date_time?.start_time,
                "end_time": this.state.getPropsData?.date_time?.end_time,
                "repeat_schedule": this.state.getPropsData?.availability_crons[0]?.repeat_schedule,
                "repeat_forever": 0,
                "available_sunday": this.state.getPropsData?.availability_crons[0]?.available_sunday,
                "available_monday": this.state.getPropsData?.availability_crons[0]?.available_monday,
                "available_tuesday": this.state.getPropsData?.availability_crons[0]?.available_tuesday,
                "available_wednesday": this.state.getPropsData?.availability_crons[0]?.available_wednesday,
                "available_thursday": this.state.getPropsData?.availability_crons[0]?.available_thursday,
                "available_friday": this.state.getPropsData?.availability_crons[0]?.available_friday,
                "available_saturday": this.state.getPropsData?.availability_crons[0]?.available_saturday,
                "teacher_schedule_cron_uuid": this.state.getPropsData?.availability_crons[0]?.teacher_schedule_cron_uuid,
                "trial_only_availability": this.state.getPropsData?.availability_crons[0]?.trial_only_availability,
                "currentDate": false,
                "availability_type": 'repeat',
                "availability_type_dt": 'original',
            }
            this.props.payloadData(payload);
        }

    }




    render() {

        return (
            <>
                <div className='relative inset-0 z-index-max'>
                    <div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
                        <div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
                            <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
                                <div onClick={this.props.closeChangeAvailibilityPopup}>
                                    <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <rect width='34' height='34' rx='17' fill='none' />
                                        <path
                                            d='M22 12L12 22'
                                            stroke='#BFBFBF'
                                            strokeWidth='1.66667'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M12 12L22 22'
                                            stroke='#BFBFBF'
                                            strokeWidth='1.66667'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className='mb-0'>
                                <div className='flex justify-start flex-col items-start'>
                                    <div className='w-full'>
                                        <div className="mt-5">
                                            <div className="text-mytgrey-lite font-semibold text-sm">This is a repeating event</div>
                                            <div className="mt-4">
                                                <div className="daysAbailabilitySlots">
                                                    <div className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${this.state.getPropsData.availability_crons[0].available_sunday === 1 ? 'bg-mytTeacherTextGray3 text-white' : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'}`}>S</div>
                                                    <div className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${this.state.getPropsData.availability_crons[0].available_monday === 1 ? 'bg-mytTeacherTextGray3 text-white' : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'}`}>M</div>
                                                    <div className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${this.state.getPropsData.availability_crons[0].available_tuesday === 1 ? 'bg-mytTeacherTextGray3 text-white' : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'}`}>T</div>
                                                    <div className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${this.state.getPropsData.availability_crons[0].available_wednesday === 1 ? 'bg-mytTeacherTextGray3 text-white' : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'}`}>W</div>
                                                    <div className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${this.state.getPropsData.availability_crons[0].available_thursday === 1 ? 'bg-mytTeacherTextGray3 text-white' : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'}`}>T</div>
                                                    <div className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${this.state.getPropsData.availability_crons[0].available_friday === 1 ? 'bg-mytTeacherTextGray3 text-white' : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'}`}>F</div>
                                                    <div className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${this.state.getPropsData.availability_crons[0].available_saturday === 1 ? 'bg-mytTeacherTextGray3 text-white' : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'}`}>S</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <div className="flex justify-start items-center">
                                                <div className="mr-4">
                                                    <img src="https://images.myyogateacher.com/web_images/calender_icon_teacher.svg" alt="calender-icon" />
                                                </div>
                                                <div className="text-mytgrey-lite font-semibold text-sm">{moment(this.state.getPropsData.availability_crons[0].start_date).format('DD MMM, YYYY')} - {moment(this.state.getPropsData.availability_crons[0].end_date).format('DD MMM, YYYY')}</div>
                                            </div>
                                            <div className="flex justify-start items-center mt-4 mb-4">
                                                <div className="mr-4">
                                                    <img src="https://images.myyogateacher.com/web_images/clock_icon_teacher.svg" alt="clock-img" />
                                                </div>
                                                {/*<div className="text-mytgrey-lite font-semibold text-sm">{this.state.getPropsData.date_time.start_time} - {this.state.getPropsData.date_time.end_time}</div>*/}
                                                <div className="text-mytgrey-lite font-semibold text-sm">{moment(this.state.startTimeConvert).format('hh:mm A')} - {moment(this.state.endTimeConvert).format('hh:mm A')}</div>
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <div className="flex flex-col w-full">

                                                <button className="btn btn-primary mt-3 flex-col" onClick={() => this.editSlotsData('currentDate')}>
                                                    <div>Change just this day</div>
                                                    <div>{moment(this.state.getPropsData.date_time.start_date).format('DD MMM')}</div>
                                                </button>
                                                <button className="btn btn-green mt-3 flex-col" onClick={() => this.editSlotsData('futureDate')}>
                                                    <div>Change future availability</div>
                                                    <div>{moment(this.state.getPropsData.date_time.start_date).format('DD MMM')} - {moment(this.state.getPropsData.availability_crons[0].end_date).format('DD MMM')}</div>
                                                </button>
                                                <button className="btn btn-blue mt-3 flex-col" onClick={() => this.editSlotsData('originalDate')}>
                                                    <div>Change original availability</div>
                                                    <div>{moment(this.state.getPropsData.availability_crons[0].start_date).format('DD MMM')} - {moment(this.state.getPropsData.availability_crons[0].end_date).format('DD MMM')}</div>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default ChangeAvailibility;
