import React, { Component } from "react";
import "./popup.css";

export default class PopUpText extends Component {
  render() {
    return (
      <div className="relative inset-0 z-50">
        <div className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end">
          <div className="z-50 bg-white zm:rounded-2xl zm:rounded-tl-3xl zm:rounded-tr-3xl zm:rounded-bl-none zm:rounded-br-none md:rounded-lg md:p-40px zm:px-30px zm:py-40px md:w-492px zm:w-full relative">
            <div class="flex justify-end cursor-pointer absolute top-1 right-1" onClick={this.props.closePopup}>
              <svg width="40" height="40" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="34" height="34" rx="17" fill="none"></rect>
                <path d="M22 12L12 22" stroke="#BFBFBF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M12 12L22 22" stroke="#BFBFBF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </div>
            <div className="">
              <div className="font-semibold text-subText md:text-19 zm:text-17 mb-25px">
                {this.props.popupHeading !== "" ? <h2 className="heading">{this.props.popupHeading}</h2> : null}
              </div>
              <div className="flex items-center justify-center">{this.props.bodytext}</div>
              <div className="">
                {this.props.btnfooter}
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
