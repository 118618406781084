import React, { useState } from 'react';
import { useWindowSize } from '../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';

const Header = () => {
	const navigate = useNavigate();

	const [isOpen, setIsOpen] = useState(false);
	const [width] = useWindowSize();

	const toggle = () => {
		setIsOpen(!isOpen);
		if (!isOpen) {
			// window.onscroll = function () {
			// 	window.scrollTo(0, 0);
			// };
			var x = window.scrollX;
			var y = window.scrollY;
			window.onscroll = function () {
				window.scrollTo(x, y);
			};
		} else if (isOpen) {
			window.onscroll = function () {};
		}
	};
	const handleRedirection = (page) => {
		navigate(`/${page}`);
	};

	const [show, setShow] = useState(false);

	return (
		<>
			<div
				className='fixed top-0 right-0 w-full flex justify-between items-center zm:px-5 zm:py-4 xl:px-6 xl:py-8 zm:h-16 sm:h-88 bg-white text-black z-50'
				role='navigation'
			>
				<a href='https://myyogateacher.com/' className='items-center zm:hidden lg:flex cursor-pointer'>
					 
					<img
						className='icon lg:w-64'
						alt='MyYogaTeacher_Logo'
						src='https://images.myyogateacher.com/MYT_Logo.svg'
					/>
				</a>
				<a href='https://myyogateacher.com/' className='items-center zm:flex lg:hidden'>
					<img
						className='icon zm:w-10'
						alt='mobileHeaderIcon'
						src='https://images.myyogateacher.com/web_images/web_prod/mobileHeaderIcon.png'
					/>
				</a>
				<button
					className='btn btn-primary font-inter font-medium text-sm zm:block msd:hidden rounded-lg py-2 px-2'
					onClick={() => navigate('/signup')}
				>
					Apply Now
				</button>
				<div className='px-0 cursor-pointer msd:hidden' onClick={toggle}>
					<svg fill='none' viewBox='0 0 26 16' xmlns='http://www.w3.org/2000/svg' width='26' height='16'>
						<rect width='35' height='2' rx='1' fill='#272727'></rect>
						<rect y='6' width='35' height='2' rx='2' fill='#272727'></rect>
						<rect y='12' width='35' height='2' rx='2' fill='#272727'></rect>
					</svg>
					{/* <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg> */}
				</div>
				{width !== 'mobile' ? (
					<>
						<div className='navLink msd:flex hidden'>
							<div className='navItem' href='https://myyogateacher.com/articles'>
								<a href={'https://myyogateacher.com/articles'}>Articles </a>
							</div>
							<div className='navItem relative flex items-center ' onClick={() => setShow(!show)}>
								More{' '}
								<span className='ml-2 mt-1'>
									<svg
										width='14'
										height='8'
										viewBox='0 0 14 8'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M1 1L7 7L13 1'
											stroke='#321A50'
											strokeWidth='2'
											strokeLinecap='round'
											strokeLinejoin='round'
										></path>
									</svg>
								</span>
								{show && (
									<div className='absolute z-50 w-64 shadow-md top-8 right-0 bg-white px-5 py-25px rounded-lg border border-gray'>
										<div>
											<span className='navDropDownSectionHeading mt-0 navSectionHeading'>
												Support
											</span>
											<div className='block text-gray-500 text-xs mb-1'>
												<a className='navDropDownItem' href='https://myyogateacher.com/faq'>
													FAQ
												</a>
												<a
													className='navDropDownItem'
													href='https://myyogateacher.com/contactus'
												>
													Email Support
												</a>
												<a
													className='navDropDownItem'
													href='https://myyogateacher.com/contactus'
												>
													Call the Team
												</a>
											</div>
										</div>
										<div>
											<span className='navDropDownSectionHeading mt-30px'>Company</span>
											<div className='block text-dark_purple text-sm mb-2'>
												<a className='navDropDownItem' href='https://myyogateacher.com/aboutus'>
													About Us
												</a>
											</div>
										</div>
										<div>
											<span className='navDropDownSectionHeading mt-30px'>Teachers</span>
											<div className='block text-dark_purple text-sm mb-2'>
												<div className='navDropDownItem' onClick={() => navigate('/login')}>
													Teacher Login
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='flex items-center msd:flex hidden'>
							<div className='navItem' onClick={() => navigate('/login')}>
								Login
							</div>
							<button
								className='btn btn-primary py-3 px-5 font-inter font-medium text-sm'
								onClick={() => handleRedirection('signup')}
							>
								Apply Now
							</button>
						</div>
					</>
				) : (
					<div className={isOpen ? 'text-center items-center bg-yellow-500 ' : 'hidden'}>
						<div
							className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-50'
							onClick={() => toggle()}
						></div>
						<div className='bg-white absolute inset-0 w-screen h-3/6 z-50'>
							<div
								className='flex justify-between items-center zm:px-5 xl:px-10 h-16  text-black relative font-mono zm:bg-white'
								role='navigation'
							>
								<img
									className='icon zm:w-10 zm:block md:hidden'
									alt='mobileHeaderIcon'
									src='https://images.myyogateacher.com/web_images/web_prod/mobileHeaderIcon.png'
								/>

								<div className='px-4 cursor-pointer zm:block md:hidden' onClick={() => toggle()}>
									<svg
										className='w-6 h-6'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M6 18L18 6M6 6l12 12'
										></path>
									</svg>
								</div>
							</div>
							<div className='bg-white flex items-center flex-col py-4'>
								<a className='navItem zm:py-2' href='https://myyogateacher.com/articles'>
									Articles
								</a>
								<a className='navItem zm:py-2' onClick={() => navigate('/login')}>
									Login
								</a>
								<div className='zm:py-2 zm:px-10 w-full'>
									<a className='btn btn-orange w-full' onClick={() => navigate('/signup')}>
										Apply Now
									</a>
								</div>
							</div>
						</div>
					</div>
				)}{' '}
			</div>
		</>
	);
};

export default Header;
