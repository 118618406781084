import React, { useEffect, useState } from 'react';
import PopupLayout from '../../../common/PopupLayout';
import { OrangeStarIcon } from '../../../../svg';

const tipOfTheDayPoints = [
  'Greet students with a smile and introduce yourself',
  'Ask about Medical issues and health conditions',
  'Ask about Yoga experience and other physical activities',
  'Ask about Goals',
  'Ask about Lifestyle and work',
  'Set Agenda for the Session',
  'Summarize at the end of the session',
  'Encourage to book a second session',
  'Invite students to ask questions',
  'Mention that a Concierge will join at the end of the session'
];

const checkListPoints = [
  'Greet students with a smile and introduce yourself',
  'Ask about',
  'Session Best Practices'
];

const askAboutPoints = [
  'Medical issues and health conditions',
  'Yoga experience and other physical activities',
  'Goals',
  'Lifestyle and work'
];

const sessionBestPracticesPoints = [
  'Set Agenda',
  'Mention that a concierge will join at the end',
  'Summarize at the end',
  'Encourage to book a second session',
  'Invite students to ask questions'
];

const JoinMeetingCheckList = ({ onClose, onClick, div_session_uuid }) => {
  const [tipOfTheDay, setTipOfTheDay] = useState('');

  const getTipOfTheDay = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };

  useEffect(() => {
    setTipOfTheDay(getTipOfTheDay(tipOfTheDayPoints));
  }, []);

  return (
    <>
      <PopupLayout customClass="md:w-[492px]" onClose={onClose}>
        <div className=" px-25px">
          <div className="text-19px leading-22px font-semibold font-inter text-mytgrey-subText">
            Trial user checklist
          </div>
          <div className="bg-checklistBGColor rounded-8px px-15px py-12px flex gap-15px items-center mt-15px mb-20px">
            <div>
              <OrangeStarIcon />
            </div>
            <div>
              <div className="text-checklistTextColor text-11px font-medium mb-2px">
                Tip of the day
              </div>
              <div className="text-primary text-15px font-medium">{tipOfTheDay}</div>
            </div>
          </div>
          <ol className="list-decimal pl-20px">
            {checkListPoints.map((item, index) => (
              <li
                key={index}
                className="text-15px font-medium leading-27px text-mytgrey-lite mb-10px">
                {item}
                {index === 1 && (
                  <ol className="list-lowerAlpha pl-20px">
                    {askAboutPoints.map((subItem, subIndex) => (
                      <li
                        key={`ask-about-${subIndex}`}
                        className="text-15px font-medium leading-27px text-mytgrey-lite mb-10px">
                        {subItem}
                      </li>
                    ))}
                  </ol>
                )}
                {index === 2 && (
                  <ol className="list-lowerAlpha pl-20px">
                    {sessionBestPracticesPoints.map((subItem, subIndex) => (
                      <li
                        key={`session-best-practices-${subIndex}`}
                        className="text-15px font-medium leading-27px text-mytgrey-lite mb-10px">
                        {subItem}
                      </li>
                    ))}
                  </ol>
                )}
              </li>
            ))}
          </ol>
          <div className="sticky bottom-0 pb-25px bg-white">
            <div
              className="btn btn-green h-46px cursor-pointer mt-15px w-full "
              onClick={onClick}
              data-div_session_uuid={div_session_uuid}>
              Join Now
            </div>
          </div>
        </div>
      </PopupLayout>
    </>
  );
};

export default JoinMeetingCheckList;
