import React, { Component } from 'react';
import SideDrawer from './SideDrawer';
import Toolbar from './Toolbar';
import './Tooglebtn.css';

class ToogleBtn extends Component {
	state = {
		sideDrawerOpen: false
	};

	drawerToggleClickHandler = () => {
		this.setState(prevState => {
			return { sideDrawerOpen: !prevState.sideDrawerOpen };
		});
	};

	render() {
		let sideDrawer;

		if (this.state.sideDrawerOpen) {
			sideDrawer = <SideDrawer drawerToggleClickHandler={this.drawerToggleClickHandler} />;
		}

		return (
			<div className="toogle-container">
				<Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
				{sideDrawer}
			</div>
		);
	}
}
export default ToogleBtn;
