import React from 'react';

import './download_app.css';
function DownloadApp() {
	return (
		<div className="hp-download-app">
			<h2 className="download-app-heading">Download App</h2>
			<div className="app-store-buttons">
				<div className="ios-app-store-button">
					<a
						href="https://itunes.apple.com/us/app/myyogateacher/id1452509353?ls=1&mt=8"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src="https://images.myyogateacher.com/website/home/ios_app_store.png" alt="ic" />
					</a>
				</div>
				<div className="android-app-store-button">
					<a
						href="https://play.google.com/store/apps/details?id=com.myyogateacher.studentapp"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src="https://images.myyogateacher.com/website/home/android_app_store.png" alt="ic" />
					</a>
				</div>
			</div>
		</div>
	);
}

export default React.memo(DownloadApp);
