const dev_domain = 'https://gstg1.myyogateacher.com';
const stage_domain = 'https://gstg1.myyogateacher.com';
const preprod_domain = 'https://gstg1.myyogateacher.com';
const prod_domain = 'https://gapi.myyogateacher.com';
const urls = (domain) => ({
  getTeacherList: `${domain  }/v2/teacher_sessions/session/list`,
  getTeacherNotification: `${domain  },teacher/api/get_notifications`,

  get_notifications: `${domain  }/teacher/api/get_notifications`,
  update_notification_read_status: `${domain  }/teacher/api/get_new_notifications_summary`,

  getTeacherTimeSlots: `${domain  }/teacher/api/get_session_time_update_time_slots`,

  update_session_time_duration:
    `${domain  }/v2/schedule_session/teacher/update_session_time_duration`,
  cancel_sessions: `${domain  }/v2/schedule_session/teacher/cancel_session`,

  subscription_data: `${domain  }/v2/schedule_session/teacher/subscription_data`,
  create_roadmap: `${domain  }/teacher/api/create_roadmap`,
  get_roadmap_for_student: `${domain  }/teacher/api/get_roadmap_for_student`,
  save_roadmap_data: `${domain  }/teacher/api/save_roadmap_data`,
  config: `${domain  }/teacher/api/config.json`,
  // My Session Page URLS

  my_session_v1: `${domain  }/v2/teacher/profile/my_sessions`,
  my_session_data_v1: `${domain  }/v2/teacher/profile/get_sessions_status`,
  my_session_cancelled_classes: `${domain  }/v2/teacher/profile/get_sessions_status_cancelled`,
  my_session_replaced_classes: `${domain  }/v2/teacher/profile/get_sessions_replaced`,
  my_session_noshow_classes: `${domain  }/v2/teacher/profile/get_sessions_status_no_show`,
  my_session_question_classes: `${domain  }/v2/teacher/profile/update_session_recap`,

  // ----------------------------xxxxxxx--------------------------//

  // Class Details Page URLS
  // schedule_class: domain + '/v2/schedule_session/teacher/book',
  schedule_class: `${domain  }/teacher/api/schedule_session_v1`,
  class_details: `${domain  }/v2/teacher/profile/get_class_details`,
  // update_student_count: domain + '/v2/schedule_session/teacher/update_student_count',
  update_student_count: `${domain  }/teacher/api/update_student_count`,
  get_student_profile: `${domain  }/teacher/api/student_details_by_slug`,
  update_class_duration: `${domain  }/v2/schedule_session/teacher/sessions`,
  upload_image: `${domain  }/v2/schedule_session/teacher/session_images/upload`,
  get_upload_image: `${domain  }/v2/schedule_session/teacher/session_images/list`,
  get_all_upload_image: `${domain  }/v2/schedule_session/teacher/session_images/listall`,
  get_customized_image:
    `${domain  }/v2/schedule_session/teacher/session_images/get_customised_images`,
  delete_uploaded_image: `${domain  }/v2/schedule_session/teacher/session_images/delete`,
  session_change_time_warning: `${domain  }/teacher/api/session_change_time_warning`,
  change_session_time: `${domain  }/teacher/api/change_session_time`,
  cancel_session_warning: `${domain  }/teacher/api/cancel_session_warning`,
  cancel_session_v1: `${domain  }/teacher/api/cancel_session_v1`,
  track_device: `${domain  }/v2/recommendations/session_devices/track`
});

let url_domain = '';
let mytChatUrl = '';

switch (import.meta.env.VITE_APP_STAGE) {
  case 'staging':
    url_domain = stage_domain;
    mytChatUrl = 'samvaad-gstg1.myyogateacher.com';
    break;
  case 'preprod':
    url_domain = preprod_domain;
    mytChatUrl = 'samvaad-gstg1.myyogateacher.com';
    break;
  case 'production':
    url_domain = prod_domain;
    mytChatUrl = 'samvaad.myyogateacher.com';
    break;
  default:
    url_domain = dev_domain;
    mytChatUrl = 'samvaad-gstg1.myyogateacher.com';
    break;
}

export default urls(url_domain, mytChatUrl);
