import React, { Component } from 'react';
import Header from '../../util_components/header';
import Footer from '../../new_teacher_wesite/components/footer';
import SlotsDate from '../slots-date';
import CheckMobile from '../../teacher/check_mobile';


export default class TeacherSlots extends Component {
  
  render() {
    return (
      <>
      <CheckMobile>
        <Header hide_tabs={false}/>
        <div className="topContainer  xl:pb-16 zm:pt-28 xl:pt-16 bg-mytAvailibilityBg">
          <div className="w-full h-full flex justify-center items-start">
            <div className="w-872px m-auto">
              <SlotsDate />
            </div>
          </div>
        </div>
        <Footer />
        </CheckMobile>
      </>
    )
  }
}
