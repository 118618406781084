import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
	delete_avalability_slots_data,
	get_replacement_sessions_teacher_slots,
} from '../../../redux/api_functions/availability_api';
import { removeCookie, analytics_reset } from '../../../util_functions';
import Loader from '../../new_teacher_wesite/components/loader';

const DeleteAvailabilitySlots = (props) => {
	const navigate = useNavigate();

	// console.log("this is delete availability sessions slots", props.deleteAllAvailabilityData);

	const [getDeleteSlotsData, setDeleteSlotsData] = useState(props.deleteAllAvailabilityData);
	const [getSessionSlotsData, setSessionSlotsData] = useState();
	const [isLoading, setIsloading] = useState(false);
	// const [ requestReplacementPopup, setRequestReplacementPopup] = useState();
	// const [getSessionData, setSessionData] = useState();

	useEffect(() => {
		dataCallingApi();
	}, []);

	const dataCallingApi = () => {
		let payload = {
			teacher_schedule_cron_uuid: getDeleteSlotsData.teacher_schedule_cron_uuid,
			start_date: getDeleteSlotsData.start_date,
			end_date: getDeleteSlotsData.end_date,
		};
		setIsloading(true);
		get_replacement_sessions_teacher_slots(payload)
			.then((response) => {
				if (response?.data?.status === 'success') {
					if (response?.data?.sessions.length === 1) {
						let sessiondata = response?.data?.sessions;
						sessiondata.forEach((each) => {
							each['lengthData'] = true;
						});
						setSessionSlotsData(sessiondata);
					} else {
						let sessiondata = response?.data?.sessions;
						setSessionSlotsData(sessiondata);
						// console.log("this is api get response 1", response);
					}
					setIsloading(false);
				}
			})
			.catch((e) => {
				// console.log(JSON.stringify(e));
				if (e.response.status === 401) {
					removeCookie('teacher_token_1');
					localStorage.removeItem('teacherLocalChannels');
					analytics_reset();
					navigate('/login');
					props.addDataEditSlots(true);
					setIsloading(false);
				} else if (e.response.status === 400) {
					props.addDataEditSlots(true);
					setIsloading(false);
				}
			});
	};

    const deleteAllAvailability = () => {
        let payload = {
            ...props?.deleteAllAvailabilityData
        };

		delete_avalability_slots_data(payload)
			.then((response) => {
				setIsloading(true);
				if (response?.data?.status == 'success') {
					setIsloading(false);
					props.deleteDataEditSlots(true);
				}
				props.closeDeleteAllAvailability();
			})
			.catch((e) => {
				if (e?.response?.status === 401) {
					removeCookie('teacher_token_1');
					localStorage.removeItem('teacherLocalChannels');
					analytics_reset();
					navigate('/login');
					setIsloading(false);
				} else if (e?.response?.status === 400) {
					setIsloading(false);
				} else {
					navigate('/login');
				}
			});
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<div className='relative inset-0 z-index-max'>
					<div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
						<div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
							<div className='flex justify-end cursor-pointer absolute top-1 right-1'>
								<div onClick={props.closeDeleteAllAvailability}>
									<svg
										width='40'
										height='40'
										viewBox='0 0 34 34'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<rect width='34' height='34' rx='17' fill='none' />
										<path
											d='M22 12L12 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M12 12L22 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</div>
							<div className='mb-0'>
								<div className='flex justify-center flex-col items-center'>
									<div className='w-full'>
										<div className='flex flex-col justify-center items-center mt-4 pb-2'>
											<div className='text-17 font-semibold text-mytRedText2'>WARNING!</div>
											<div>
												{getSessionSlotsData?.length === 0 ? 0 : getSessionSlotsData?.length}{' '}
												Sessions scheduled between
											</div>
											<div>
												{moment(getDeleteSlotsData?.start_date).format('ddd, DD')} -{' '}
												{moment(getDeleteSlotsData?.end_date).format('ddd, DD')} to{' '}
												{moment(getDeleteSlotsData?.end_date).format('MMM YYYY')}
											</div>
										</div>
										<div className='h-80 overflow-x-scroll'>
											{getSessionSlotsData?.map((list, i) => {
												let startDate = moment.utc(list.start_time).format('DD MMM, HH:mm');
												return (
													<div className='mt-5 border-b pb-4' key={i}>
														<div className='text-xs text-primary'>{startDate}</div>
														<div className='flex justify-start items-start mt-2'>
															<img
																className='mr-2 w-12 h-12 rounded-full cursor-pointer'
																src={list?.avatar_medium}
																alt={list?.student_name}
															/>
															<div>
																<div className='text-base'>{list.student_name}</div>
																<div className='flex jsutify-start items-center '>
																	<div className='uppercase text-mytgrey-lite font-normal text-xs'>
																		{list.yoga_level}
																	</div>
																	<div className='flex justify-start items-center ml-2'>
																		<div className='w-6px h-6px rounded-full bg-mytgrey-lite mr-1'></div>
																		<div className='text-mytgrey-lite font-normal text-xs'>
																			{list.duration} mins
																		</div>
																	</div>
																</div>
																<button
																	className='btn btn-primary mt-4'
																	onClick={() => props.repalcementRequest(list)}
																>
																	Request a replacement
																</button>
															</div>
														</div>
													</div>
												);
											})}
										</div>
										<div className='mt-3 flex justify-center items-center'>
											<button
												className='btn btn-danger flex-col justify-center items-center w-full'
												onClick={() => deleteAllAvailability()}
											>
												<div>Delete availability</div>
												<div className='text-xs'>I’ll handle the sessions later</div>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DeleteAvailabilitySlots;
