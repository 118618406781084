import React from 'react';
import './style_pop.css';

class Opencustpop extends React.Component {
  render() {
    return (
      <div className="opc-outer">
        <div className="opc-overlay" onClick={this.props.closePopupViewPartici} />
        <div className="opc-body">
          <div className="opc-header">
            <h2>{this.props.heading_head}</h2>
            <span className="cust-close-in-h">
              <img
                onClick={this.props.closePopupViewPartici}
                src="https://images.myyogateacher.com/icons/cross_filt_pop.svg"
                alt="close"
              />
            </span>
          </div>
          <div className="opc-content">{this.props.opc_content}</div>
          <div className="opc-footer">
            <button className="opc-ft-ln-btn" onClick={this.props.closePopupViewPartici}>
              {this.props.btn_ft_ln_text}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default Opencustpop;
