import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../new_teacher_wesite/components/footer';
import Header from '../util_components/header';
import { sendEventToClarity } from '../../util_functions';

export class ContactUs extends Component {
  componentDidMount() {
    sendEventToClarity();
  }

  render() {
    return (
      <div>
        <Header hide_tabs />
        <div className="topContainer zm:my-46px  md:mt-90px md:mb-60px">
          <div className="baseContainer">
            <div className="flex justify-start flex-col">
              <div className="contentHeading">Get in touch.</div>
              <div className="contentHeading">We'd love to hear from you.</div>
              <div className="contentDescription text-16px">Speak to the MyYogaTeacher team</div>
            </div>
            <div className="flex h-full w-full mt-8">
              <div className="lg:w-3/6 zm:full mr-6">
                <div className="flex items-start justify-start py-4">
                  <div className="bg-sky_blue flex items-center justify-center p-2 mt-1 rounded-full">
                    <img
                      className="w-6"
                      src="https://images.myyogateacher.com/web_images/web_prod/mail.png"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="xl:text-lg zm:text-sm font-semibold text-mytgrey-contactHeading">
                      Email
                    </div>
                    <a
                      className="font-semibold xl:text-xl font-avenir md:text-base"
                      href="mailto: care@myyogateacher.com"
                    >
                      care@myyogateacher.com
                    </a>
                  </div>
                </div>
                <div className="flex items-start justify-start py-4">
                  <div className="bg-light_purple flex items-center justify-center p-2 mt-1    rounded-full">
                    <img
                      className="w-6"
                      src="https://images.myyogateacher.com/web_images/web_prod/address.png"
                    />
                  </div>
                  <div>
                    <div className="ml-3">
                      <div className="xl:text-lg zm:text-sm font-semibold text-mytgrey-contactHeading">
                        Headquarter office
                      </div>
                      <div className="font-semibold font-avenir xl:text-xl md:text-base">
                        22380 Balustrol Ct, Cupertino, CA 95014
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-start justify-start py-4">
                  <div className="bg-light_purple flex items-center justify-center p-2 mt-1    rounded-full">
                    <img
                      className="w-6"
                      src="https://images.myyogateacher.com/web_images/web_prod/address.png"
                    />
                  </div>
                  <div>
                    <div className="ml-3">
                      <div className="xl:text-lg zm:text-sm font-semibold text-mytgrey-contactHeading">
                        Bangalore office
                      </div>
                      <div className="font-semibold font-avenir xl:text-xl md:text-base">
                        #423, 1st Main, 8th Cross,
                      </div>
                      <div className="font-semibold font-avenir xl:text-xl md:text-base">
                        Domlur Layour,
                      </div>
                      <div className="font-semibold font-avenir xl:text-xl md:text-base">
                        Bangalore 560071
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-base font-semibold pt-0 font-avenir xl:text-lg text-mytDarkBlue">
                  Still need help? Talk to our team 24/7
                </div>
                <div className="flex items-start justify-start py-4">
                  <div className="bg-light_pink flex items-center justify-center p-2 mt-1 rounded-full">
                    <img
                      className="w-6"
                      src="https://images.myyogateacher.com/web_images/web_prod/phone.png"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="xl:text-lg zm:text-sm font-semibold text-mytgrey-contactHeading">
                      Call Us
                    </div>
                    <div>
                      <a
                        className="font-bold font-avenir py-1 lg:text-xl md:text-sm"
                        href="tel:+1 (855) 771-2929"
                      >
                        +1 (855) 771-2929 (USA)
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex items-start justify-start py-4">
                  <div className="bg-light_pink flex items-center justify-center p-2 mt-1 rounded-full">
                    <img
                      className="w-6"
                      src="https://images.myyogateacher.com/web_images/web_prod/phone.png"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="xl:text-lg zm:text-sm font-semibold text-mytgrey-contactHeading">
                      Call Us
                    </div>
                    <div>
                      <a
                        className="font-bold font-avenir py-1 lg:text-xl md:text-sm"
                        href="tel:+91 94141 14756"
                      >
                        +91 94141 14756 (INDIA)
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-3/6 md:w-4/6 xl:mr-16 lg:mr-10 md:mr-6 xl:mt-6 lg:mt-16 md:mt-24  zm:hidden lg:block">
                <img
                  className="rightImgSection"
                  src="https://images.myyogateacher.com/web_images/web_prod/contactUs.png"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const ContactUsWithRedux = connect(mapStateToProps, mapDispatchToProps)(ContactUs);

export default ContactUsWithRedux;
