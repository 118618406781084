import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../util_components/header_new_design';
import * as actions from '../../../redux/action_creators';
import { sendEventToClarity } from '../../../util_functions';

class Students extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_time: new Date().getTime()
    };
    this.props.getStudents();
  }

  componentDidMount() {
    sendEventToClarity();
  }

  render() {
    return (
      <>
        <Header start_time={this.state.start_time} />
        <div>
          {this.props.studentsList.map((student) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'space-between'
                }}
              >
                <img src={student.profile_photo} />
                <span>{student.student_name}</span>
                <button>Schedule Session</button>
              </div>
            ))}
        </div>
      </>
    );
  }

  renderStudents = () => {};
}

const mapStateToProps = (state) => ({
    getStudentsStatus: state.home.getStudentsStatus,
    studentsList: state.home.studentsList
  });

const mapDispatchToProps = (dispatch) => ({
    getStudents: (payload) => dispatch(actions.getStudents(payload))
  });

const StudentsWithRedux = connect(mapStateToProps, mapDispatchToProps)(Students);

export default StudentsWithRedux;
