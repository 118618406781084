import * as t from '../action_types';

// different status
// 1. none
// 2. loading
// 3. success
// 4. fail

var initialState = {
  // my_sessions_status: 'none',
  showLogoutLoader: false
};

const set_loading_variable = (state, payload) => {
  return {
    ...state,
    [payload.key]: payload.payload,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case t.set_loading_variable:
      return set_loading_variable(state, action.payload);
    default:
      return state;
  }
}
