import React, { useState, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import './RangePicker.css';
import { format, addWeeks, subWeeks, addDays, subDays, isSameDay, isBefore, differenceInCalendarDays } from 'date-fns';
import MonthPicker from './MonthPicker';

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(function ReactHorizontalDatePicker({
	enableDays, // Number of days to display
	enableScroll,
	selectedDay,
	onDateRangeChange,
}) {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [headingDate, setHeadingDate] = useState(new Date());
	const [currentWeek, setCurrentWeek] = useState(new Date());
	const [currentDate] = useState(new Date());
	const scrollWidth = 250;
	enableScroll = enableScroll || false;
	enableDays = enableDays || 7; // Default to 7 days if enableDays prop is not provided
	const heading_dateFormat = 'MMM yyyy';

	useEffect(() => {
		console.log(headingDate);
	}, [headingDate]);

	const applyStyles = (day) => {
		const classes = [];
		if (isSameDay(day, selectedDate)) {
			classes.push(' date-day-Item-selected');
		}

		if (isBefore(day, currentDate)) {
			classes.push(' date-day-item-disabled');
		}
		return classes.join(' ');
	};

	const _handlePosition = (pos, date) => {
		let currentPosition = pos.currentPosition;
		let previousPosition = pos.previousPosition;

		if (previousPosition == 'inside' && currentPosition == 'above') {
			setHeadingDate(date);
		}
		if (previousPosition == 'above' && currentPosition == 'inside') {
			setHeadingDate(addDays(date, -1));
		}
	};

	const _verticalList = () => {
		const _dayFormat = 'E';
		const _dateFormat = 'dd';
		const _monthFormat = 'MMM';
		const _verticalListItems = [];
		const _startDay = subDays(currentWeek, 0);
		for (let i = 0; i < enableDays; i++) {
			let _day = format(addDays(_startDay, i), _dayFormat);
			let _date = format(addDays(_startDay, i), _dateFormat);
			let _month = format(addDays(_startDay, i), _monthFormat);

			_verticalListItems.push(
				<Waypoint
					key={i}
					horizontal={true}
					onPositionChange={(pos) => (_date == 1 ? _handlePosition(pos, addDays(_startDay, i)) : '')}
				>
					<div className='wrapper'>
						{format(addDays(_startDay, i), _dateFormat) == 1 ? (
							<div className='scroll-head'>{format(addDays(_startDay, i), 'MMM')}</div>
						) : (
							<div className='blank-space-div'></div>
						)}
						<div
							className={`datepicker-date-day-Item wrapper ${applyStyles(addDays(_startDay, i))}`}
							onClick={() => onDateClick(addDays(_startDay, i))}
						>
							<div className='datepicker-day-label '>{_day}</div>
							<div className='datepicker-date-label ripple '>{_date}</div>
						</div>
					</div>
				</Waypoint>
			);
		}

		return (
			<div
				id='container'
				className={enableScroll === true ? ' datepicker-datelist-scrollable' : ' datepicker-dateList'}
			>
				{_verticalListItems}
			</div>
		);
	};

	const onDateClick = (day) => {
		setSelectedDate(day);
		if (selectedDay) {
			selectedDay(day);
		}
	};

	const nextScroll = () => {
		const numDays = enableDays || 7; // Default to 7 days if enableDays is not provided
		if (enableScroll) {
			const container = document.getElementById('container');
			if (container) {
				container.scrollLeft += scrollWidth * numDays;
			}
		} else {
			setCurrentWeek(addDays(currentWeek, numDays));
		}
		const nextDate = enableScroll
			? addDays(currentWeek, numDays)
			: enableDays
			? addDays(currentWeek, numDays)
			: addWeeks(currentWeek, 1);
		const nextDateLastDay = addDays(nextDate, numDays - 1);
		onDateRangeChange([nextDate, nextDateLastDay]);
		setSelectedDate(nextDate);
		setHeadingDate(nextDate);
		setCurrentWeek(nextDate);
	};

	const prevScroll = () => {
		const numDays = enableDays || 7; // Default to 7 days if enableDays is not provided
		if (enableScroll) {
			const container = document.getElementById('container');
			if (container) {
				container.scrollLeft -= scrollWidth * numDays;
			}
		} else {
			setCurrentWeek(subDays(currentWeek, numDays));
		}
		const previousDate = enableScroll
			? subDays(currentWeek, numDays)
			: enableDays
			? subDays(currentWeek, numDays)
			: subWeeks(currentWeek, 1);
		const previousDateLastDay = subDays(previousDate, numDays - 1);
		onDateRangeChange([previousDate, previousDateLastDay]);
		setSelectedDate(previousDate);
		setHeadingDate(previousDate);
		setCurrentWeek(previousDate);
	};

	let isPreviousAllowed = differenceInCalendarDays(subWeeks(currentWeek, 1), new Date()) >= -1 * enableDays || -7;

	return (
		<div className='datepicker-strip'>
			<MonthPicker
				date={selectedDate}
				onPreviousMonth={(dateRange) => {
					setSelectedDate(dateRange[0]);
					setHeadingDate(dateRange[0]);
					setCurrentWeek(dateRange[0]);
					onDateRangeChange(dateRange);
				}}
				onNextMonth={(dateRange) => {
					setSelectedDate(dateRange[0]);
					setHeadingDate(dateRange[0]);
					setCurrentWeek(dateRange[0]);
					onDateRangeChange(dateRange);
				}}
			/>
			<div className='datepicker'>
				<div className='wrapper'>
					<div className='scroll-head'>{format(headingDate, 'MMM')}</div>
					<div className='button-previous'>
						{' '}
						<button
							className='datepicker-button-previous'
							style={{ opacity: isPreviousAllowed ? '1' : '0.3' }}
							onClick={isPreviousAllowed ? prevScroll : null}
						>
							&#10132;
						</button>
					</div>
				</div>
				{_verticalList()}
				<div className='wrapper'>
					<div className='blank-space-div'></div>

					<div className='button-previous'>
						{' '}
						<button className='datepicker-button-next' onClick={nextScroll}>
							&#10132;
						</button>
					</div>
				</div>
			</div>
		</div>
	);
});
