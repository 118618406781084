import * as t from '../action_types';

export const set_upload_file_variable = (key, payload) => {
  return {
    type: t.set_upload_file_variable,
    payload: {
      key,
      payload,
    },
  };
};

export const uploadingFile = (data) => {
  return {
    type: t.UPLOAD_FILE,
    payload: data,
  }
};

export const setUploadFile = (data) => {
  return {
    type: t.SET_UPLOAD_FILE,
    payload: data,
  }
};

export const getUploadedImage = payload => {
  return {
    type: t.GET_UPLOAD_FILE,
    payload,
  };
};
export const getCustomizedImage = payload => {
  return {
    type: t.GET_CUSTOMIZED_IMAGE,
    payload,
  };
};

export const getAllStudentUploadedImage = payload => {
  return {
    type: t.GET_ALL_UPLOAD_FILE,
    payload,
  };
};

export const setUploadProgress = (id, progress) => ({
  type: t.SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
});

export const successUploadFile = (id) => ({
  type: t.SUCCESS_UPLOAD_FILE,
  payload: id,
});

export const failureUploadFile = (id) => ({
  type: t.FAILURE_UPLOAD_FILE,
  error: true,
  payload: id,
});

export const deleteImage = payload => {
  return {
    type: t.DELETE_IMAGE,
    payload,
  };
};

export const uploadFile = (url, files, token = true) => {
  return dispatch => {
    const headers = {};
    if (token) {
      headers.Authorization = 'Bearer ' + getCookie('teacher_token_1');
    }
    if (files.length) {
      files.forEach(async file => {
        const formPayload = new FormData()
        formPayload.append('files', file.file)
        try {
          await axios({
            url: url,
            method: 'post',
            headers: headers,
            data: formPayload,
            onUploadProgress: progress => {
              const { loaded, total } = progress
              const percentageProgress = Math.floor((loaded / total) * 100)
              dispatch(setUploadProgress(file.id, percentageProgress))
            },
          })
          dispatch(successUploadFile(file.id))
        } catch (error) {
          dispatch(failureUploadFile(file.id))
        }
      })
    }
  }
}