import React, { useState } from 'react'
import ConsultFormSuccessPopup from '../../components/new_teacher_wesite/modules/my-sessions-v1/popup/consultFormSuccessPopup';
import { post_api } from '../../redux/api_funcs';
import urls from '../../urls';
import Button from './CommonComponents/Button'
import Modal from './CommonComponents/Modal'
import RadioButton from './CommonComponents/RadioButton';
import PostConsultaionForm from './PostConsultaionForm';

const FollowUpConsultationPopUp = (props) => {
    const [showAddSessionNotesPopup, setShowAddSessionNotesPopup] = useState(false);
    const [showPostConsultationForm, setShowPostConsultationForm] = useState(false);
    const [showConsultFormSuccessPopup, setShowConsultFormSuccessPopup] = useState(false);
    const [postConsultFormStep, setPostConsultFormStep] = useState(1);
    const [sessionNotes, setSessionNotes] = useState('');
    const [data, setdata] = useState({
        "session_uuid": props.data.session_uuid,
        "is_follow_up_required": 0,
        "is_weakness_effected": 0,
        "weakness_effected_areas": "",
        "pain_triggers": "",
        "additional_observations": "",
        "therapy_program_recommended": 0,
        "is_therapy_program_purchase_interested": 0,
        "therapy_session_time_preference": ""
    })
    const isCompleted = (step = 1) => {
        const { is_weakness_effected, weakness_effected_areas, pain_triggers, additional_observations,is_therapy_program_purchase_interested,therapy_session_time_preference } = data;
        if (step === 1) {
            if ((is_weakness_effected && !weakness_effected_areas) || !pain_triggers) {
                return false;
            }
            else {
                return true;
            }
        }
        else if (step === 2) {
            if ((is_therapy_program_purchase_interested && !therapy_session_time_preference)) {
                return false;
            }
            else {
                return true;
            }
        }
    }
    const getPostConsultForm = () => {
        switch (postConsultFormStep) {
            case 1:
                {
                    return (<div className='px-10 pb-10 pt-1.5'>
                        <div className='font-semibold text-19px leading-22px text-greyText pb-5'>Post consultation form</div>
                        <div className='font-medium text-13.5px leading-4 text-mytLabel'>
                            Has experienced any numbness, tingling, or weakness in legs, feet, or toes, If yes, which specific areas are affected?
                        </div>
                        <div className='flex items-center mt-2.5 mb-15px'>
                            {RadioBtn.map((item, index) => {
                                let currentIndex = index;
                                return (
                                    <div className='radiobtnstyle'>
                                        <RadioButton
                                            key={`is_weakness_effected_${index}`}
                                            id={`is_weakness_effected_${index}`}
                                            label={item.label}
                                            name="is_weakness_effected" value={currentIndex === 0 ? data.is_weakness_effected : !data.is_weakness_effected} isChecked={currentIndex === 0 ? Boolean(data.is_weakness_effected) : Boolean(!data.is_weakness_effected)} onClick={(e) => setdata({ ...data, is_weakness_effected: currentIndex === 0 ? 1 : 0 })} />
                                    </div>
                                )
                            })}
                        </div>
                        <textarea
                            placeholder='Please enter here...'
                            className='formInput h-100px  px-18px py-18px text-15px leading-21px font-medium text-mytpurple'
                            name="editsession" onChange={(e) => setdata({ ...data, weakness_effected_areas: e.target.value })} />
                        <div className='font-medium text-13.5px leading-4 text-mytLabel mt-10 mb-2.5'>
                            Did student notice any factors that seem to trigger or exacerbate the pain
                        </div>
                        <textarea
                            placeholder='Eg:certain postures, lifting heavy objects, or prolonged sitting or standing'
                            className='formInput h-100px  px-18px py-18px text-15px leading-21px font-medium text-mytpurple'
                            name="editsession" onChange={(e) => setdata({ ...data, pain_triggers: e.target.value })} />
                        <div className='font-medium text-13.5px leading-4 text-mytLabel mt-10 mb-2.5'>
                            Any additional observations or pertinent information regarding the client's medical condition to pass on to the Coach
                        </div>
                        <textarea
                            placeholder='Please enter here...'
                            className='formInput h-100px  px-18px py-18px text-15px leading-21px font-medium text-mytpurple'
                            name="editsession" onChange={(e) => setdata({ ...data, additional_observations: e.target.value })} />
                        <div className='font-medium text-13.5px leading-4 text-mytLabel mt-10 mb-2.5'>
                        Do you recommend reffering the client to back pain relief program
                        </div>
                        <div className='flex items-center'>
                            {RadioBtn.map((item, index) => {
                                return (
                                    <div className='radiobtnstyle'>
                                        <RadioButton
                                            key={`therapy_program_recommended_${index}`}

                                            id={`therapy_program_recommended_${index}`}
                                            label={item.label}
                                            name="therapy_program_recommended" value={index === 0 ? data.therapy_program_recommended : !data.therapy_program_recommended} isChecked={index === 0 ? data.therapy_program_recommended : !data.therapy_program_recommended} onClick={(e) => setdata({ ...data, therapy_program_recommended: index === 0 ? 1 : 0 })} />
                                    </div>
                                )
                            })}
                        </div>
                        <Button
                            disable={!isCompleted(1)}
                            btntext="Next"
                            classNames="mt-10 pt-12.5px pb-11.5px pl-18px pr-18px"
                            onClick={() => setPostConsultFormStep(2)}
                        />
                    </div>)
                }

            case 2:
                {
                    return (
                        <div className='px-10 pb-10 pt-1.5'>
                            <div className='font-semibold text-19px leading-22px text-greyText pb-5'>Post consultation form</div>
                            <div className='font-medium text-13.5px leading-4 text-mytLabel'>
                                Did the client express interest in purchasing the Back Pain relief pack?                
                            </div>
                            <div className='flex items-center mt-2.5 mb-15px'>
                                {RadioBtn.map((item, index) => {
                                    return (
                                        <div className='radiobtnstyle'>
                                            <RadioButton
                                                key={`is_therapy_program_purchase_interested_${index}`}

                                                id={`is_therapy_program_purchase_interested_${index}`}
                                                label={item.label}
                                                name="is_therapy_program_purchase_interested" value={index === 0 ? data.is_therapy_program_purchase_interested : !data.is_therapy_program_purchase_interested} isChecked={index === 0 ? data.is_therapy_program_purchase_interested : !data.is_therapy_program_purchase_interested} onClick={(e) => setdata({ ...data, is_therapy_program_purchase_interested: index === 0 ? 1 : 0 })} />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='font-medium text-13.5px leading-4 text-mytLabel mt-10 mb-2.5'>
                                Client preffered days and times                </div>
                            <textarea
                                placeholder='Eg: Weekday Morning 9-12 AM'
                                className='formInput h-100px  px-18px py-18px text-15px leading-21px font-medium text-mytpurple'
                                name="editsession"
                                onChange={(e) => setdata({ ...data, therapy_session_time_preference: e.target.value })}
                            />


                            <Button
                                disable={!isCompleted(2)}
                                btntext="Submit Details"
                                classNames="mt-10 pt-12.5px pb-11.5px pl-18px pr-18px"
                                onClick={() => submitDetails()}
                            />
                        </div>)
                }

        }
    }
    const RadioBtn = [{
        label: "Yes",
        id: 1,
    }, {
        label: "No",
        id: 2,
    }]

    const submitDetails = () => {
        const url = urls.submit_consultation_form;
        post_api(url, data, true).then(res => {
            setShowConsultFormSuccessPopup(true);
        }).catch(err => {
            console.log(err);
        })
    }
    const submitSessionNotes = () => {
        const url = urls.update_session_recap;
        const payload = {
            session_uuid: props.data.session_uuid, notes: sessionNotes,
            is_follow_up_required:true

        }
        post_api(url, payload, true).then(res => {
            props.onClose();
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <Modal subdivclass={`rounded-10px ${showPostConsultationForm && !showConsultFormSuccessPopup ? 'h-calc80px':''}`} onClose={props.onClose}>
            {!showAddSessionNotesPopup && !showPostConsultationForm && !showConsultFormSuccessPopup ?
                <div className='px-10 pb-10 pt-1.5'>
                    <div className='font-semibold text-19px leading-22px text-greyText pb-10'>Is a follow up consultation needed for the client?</div>
                    <Button
                        btntext="Yes"
                        color="btn-white-default mb-2.5"
                        classNames="w-full"
                        onClick={() => {
                            setShowAddSessionNotesPopup(true)
                        }}
                    />
                    <Button
                        btntext="No"
                        color="btn-white-default"
                        classNames="w-full"
                        onClick={() => setShowPostConsultationForm(true)}
                    />
                </div>
                : !showPostConsultationForm && showAddSessionNotesPopup && !showConsultFormSuccessPopup ?
                    <div className='px-10 pb-10 pt-1.5'>
                        <div className='font-semibold text-19px leading-22px text-greyText mb-5'>Add session notes</div>
                        <div className='font-semibold text-13.5px leading-16px text-greyText mb-5 mt-48px'>Session notes</div>
                        <textarea
                            placeholder='Please enter here...'
                            className='formInput h-159px mt-1 px-18px py-16px text-15px leading-21px font-medium text-mytpurple'
                            name="editsession" onChange={(e) => setSessionNotes(e.target.value)} />

                        <Button
                            btnclassNames="mt-10"
                            btntext="Submit Details"
                            classNames="pt-12.5px pb-11.5px pr-18px pl-18px"
                            onClick={() => submitSessionNotes()}
                        />
                    </div> :
                    showPostConsultationForm && !showConsultFormSuccessPopup ?
                        getPostConsultForm()
                        : showConsultFormSuccessPopup ?
                            <ConsultFormSuccessPopup createReliefPlan={() => props.createReliefPlan()} onClose={() => props.onSubmitClose()} />
                            : null
            }
        </Modal>
    )
}

export default FollowUpConsultationPopUp