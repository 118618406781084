import { all, put, takeEvery, takeLatest, call, delay, select } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
// import * as selectors from '../selectors';
// import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import { error_log } from '../../utils';
import { get_api, put_api } from '../../../redux/api_funcs';
import urls from '../../urls/urls';

export function* getMySessionV1(action) {
  try {
    yield put(actions.set_mysession_variable('my_session_status', 'loading'));
    const payload = {
      ...action.payload
    };
    const my_session_v1 = `${urls.my_session_v1}${`/${payload.month}/${payload.year}`}`;
    const response = yield call(get_api, my_session_v1, payload, true);
    yield put(actions.set_mysession_variable('my_session_response', response.data));
    yield put(actions.set_mysession_variable('my_session_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_mysession_variable('my_session_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* getMySessionData(action) {
  try {
    yield put(actions.set_mysession_variable('my_session_data_status', 'loading'));
    const payload = {
      ...action.payload
    };
    const my_session_data_v1 = `${urls.my_session_data_v1}${`/${payload.month}/${payload.year}`}`;
    const response = yield call(get_api, my_session_data_v1, payload, true);
    yield put(actions.set_mysession_variable('my_session_data_response', response.data));
    yield put(actions.set_mysession_variable('my_session_data_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_mysession_variable('my_session_data_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* getMySessionCancelClasses(action) {
  try {
    yield put(actions.set_mysession_variable('my_session_cancelled_classes_status', 'loading'));
    const payload = {
      ...action.payload
    };
    const my_session_cancelled_classes = `${urls.my_session_cancelled_classes}${`/${payload.month}/${payload.year}`}`;
    const response = yield call(get_api, my_session_cancelled_classes, payload, true);
    yield put(
      actions.set_mysession_variable('my_session_cancelled_classes_response', response.data)
    );
    yield put(actions.set_mysession_variable('my_session_cancelled_classes_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_mysession_variable('my_session_cancelled_classes_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* getMySessionReplaceClasses(action) {
  try {
    yield put(actions.set_mysession_variable('my_session_replaced_classes_status', 'loading'));
    const my_session_replaced_classes = `${urls.my_session_replaced_classes}${`/${payload.month}/${payload.year}`}`;
    const payload = {
      ...action.payload
    };
    const response = yield call(get_api, my_session_replaced_classes, payload, true);
    yield put(
      actions.set_mysession_variable('my_session_replaced_classes_response', response.data)
    );
    yield put(actions.set_mysession_variable('my_session_replaced_classes_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_mysession_variable('my_session_replaced_classes_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* getMySessionNoShowClasses(action) {
  try {
    yield put(actions.set_mysession_variable('my_session_noshow_classes_status', 'loading'));
    const my_session_noshow_classes = `${urls.my_session_noshow_classes}${`/${payload.month}/${payload.year}`}`;
    const payload = {
      ...action.payload
    };
    const response = yield call(get_api, my_session_noshow_classes, payload, true);
    yield put(actions.set_mysession_variable('my_session_noshow_classes_response', response.data));
    yield put(actions.set_mysession_variable('my_session_noshow_classes_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_mysession_variable('my_session_noshow_classes_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* getMySessionQuestionClasses(action) {
  try {
    yield put(actions.set_mysession_variable('my_session_question_classes_status', 'loading'));
    const { my_session_question_classes } = urls;
    const response = yield call(put_api, payload, my_session_question_classes, true);
    yield put(
      actions.set_mysession_variable('my_session_question_classes_response', response.data)
    );
    yield put(actions.set_mysession_variable('my_session_question_classes_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_mysession_variable('my_session_question_classes_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export default function* mySessionSaga() {
  yield all([
    // takeEvery(t.my_session_v1, getMySessionV1),
    // takeEvery(t.my_session_data_v1, getMySessionData),
    takeEvery(t.my_session_cancelled_classes, getMySessionCancelClasses),
    takeEvery(t.my_session_replaced_classes, getMySessionReplaceClasses),
    takeEvery(t.my_session_noshow_classes, getMySessionNoShowClasses),
    takeEvery(t.my_session_question_classes, getMySessionQuestionClasses)
  ]);
}
