import React, { Component } from 'react';
import axios from 'axios';

import Spinner from '../../util_components/spinner';
import CustomInput from '../../util_components/custom_input';
import CustomButton from '../../util_components/custom_button';

import urls from '../../../urls';

import './reset_password.css';
import { Navigate } from 'react-router-dom';
import { sendEventToClarity } from '../../../util_functions';

export default class ResetPassword extends Component {
  state = {
    loading: true,
    verificaiton_error: false,
    verificaiton_error_msg: 'Oops something happened. Please try again',
    error_msg: '',
    pwd: '',
    re_pwd: '',
    pwd_error: false,
    user_token: '',
    pwd_status: 'none',
    go_to_login: false
  };

  componentDidMount() {
    sendEventToClarity();
    const urlString = window.location.href;
    const url = new URL(urlString);
    // TODO: migrate post ses-notification repo is migrated
    // try {
    //     if (
    //         !getCookie("cms_user_confirmation") ||
    //         getCookie("cms_user_confirmation") !== "true"
    //     ) {
    //         const aws_confirmation_uuid = url.searchParams.get(
    //             "aws_confirmation_uuid"
    //         );
    //         const aws_response_url = `${urls.email_primary_response}?id=${aws_confirmation_uuid}`;
    //         get_api(aws_response_url);
    //     }
    // } catch { }
    const token = url.searchParams.get('reset_token');
    if (token === null) {
      this.setState({
        loading: false,
        verificaiton_error: true,
        verificaiton_error_msg: 'Wrong url. Please enter correct url and try again.'
      });
    } else {
      this.post_verify_email_account(token);
    }
  }

  post_verify_email_account = (token) => {
    axios({
      method: 'post',
      url: urls.verify_rpswrd_tkn,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        reset_token: token
      }
    })
      .then((response) => {
        this.setState({ user_token: response.data.token, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false, verificaiton_error: true });
      });
  };

  reset_password = () => {
    this.setState({ pwd_status: 'loading' });
    axios({
      method: 'post',
      url: urls.update_name_password,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${  this.state.user_token}`
      },
      data: {
        password: this.state.pwd
      }
    })
      .then(() => {
        this.setState({ pwd_status: 'success' });
      })
      .catch(() => {
        this.setState({ loading: false, verificaiton_error: true });
      });
  };

  onSubmit = () => {
    if (this.state.pwd_status !== 'loading') {
      if (this.state.re_pwd === '' || this.state.pwd === '') {
        this.setState({
          pwd_error: true,
          pwd_error_msg: '* All fields are compulsory'
        });
      } else if (this.state.pwd !== this.state.re_pwd) {
        this.setState({
          pwd_error: true,
          pwd_error_msg: 'Passwords donot match'
        });
      } else if (this.state.pwd.length < 6) {
        this.setState({
          pwd_error: true,
          pwd_error_msg: 'The password should contain minimum 6 characters'
        });
      } else if (this.state.pwd.indexOf(' ') > -1) {
        this.setState({
          pwd_error: true,
          epwd_rror_msg: 'No spaces are allowed in the password'
        });
      } else if (!(/[a-zA-Z]/.test(this.state.pwd) && /[0-9]/.test(this.state.pwd))) {
        this.setState({
          pwd_error: true,
          pwd_error_msg: 'Password should contain atleast 1 alphabet and 1 number'
        });
      } else {
        this.reset_password();
      }
    }
  };

  onChange = (key, value) => {
    this.setState({
      [key]: value,
      pwd_error: false
    });
  };

  render() {
    if (this.state.go_to_login) {
      return <Navigate to="/" />;
    }
    if (this.state.loading) {
      return (
        <div className="reset-password-loading">
          <img
            src="https://images.myyogateacher.com/MYT_Logo.svg"
            alt="Logo"
            className="sm:block zm:hidden"
            width="256"
            height="28"
            style={{ width: '256px', height: '28px' }}
          />
          <img
            src="https://res.cloudinary.com/dgerdfai4/image/upload/f_auto/v1642535357/website/lp/mobileHeaderIcon.png"
            alt="mobile-logo"
            className="sm:hidden zm:block"
            width="36"
            height="22"
            style={{ width: '50px', height: '34px' }}
          />
          <Spinner />
          <p>Validating ....</p>
        </div>
      );
    }
    if (this.state.verificaiton_error) {
      return (
        <div className="reset-password-loading">
          <img
            src="https://images.myyogateacher.com/MYT_Logo.svg"
            alt="Logo"
            className="sm:block zm:hidden"
            width="256"
            height="28"
            style={{ width: '256px', height: '28px' }}
          />
          <img
            src="https://res.cloudinary.com/dgerdfai4/image/upload/f_auto/v1642535357/website/lp/mobileHeaderIcon.png"
            alt="mobile-logo"
            className="sm:hidden zm:block"
            width="36"
            height="22"
            style={{ width: '50px', height: '34px' }}
          />
          {this.state.verificaiton_error_msg}
        </div>
      );
    }
    if (this.state.pwd_status === 'success') {
      return (
        <div className="reset-password-loading">
          <img
            src="https://images.myyogateacher.com/MYT_Logo.svg"
            alt="Logo"
            className="sm:block zm:hidden"
            width="256"
            height="28"
            style={{ width: '256px', height: '28px' }}
          />
          <img
            src="https://res.cloudinary.com/dgerdfai4/image/upload/f_auto/v1642535357/website/lp/mobileHeaderIcon.png"
            alt="mobile-logo"
            className="sm:hidden zm:block"
            width="36"
            height="22"
            style={{ width: '50px', height: '34px' }}
          />
          <div>
            Reset successful. Please{' '}
            <span>
              <a href="/login" className="reset-go-home">
                Login
              </a>
            </span>{' '}
            to use all features.
          </div>
        </div>
      );
    }
    return (
      <div className="reset-password-loading">
        <img
          src="https://images.myyogateacher.com/MYT_Logo.svg"
          alt="Logo"
          className="sm:block zm:hidden"
          width="256"
          height="28"
          style={{ width: '256px', height: '28px' }}
        />
        <img
          src="https://res.cloudinary.com/dgerdfai4/image/upload/f_auto/v1642535357/website/lp/mobileHeaderIcon.png"
          alt="mobile-logo"
          className="sm:hidden zm:block"
          width="36"
          height="22"
          style={{ width: '50px', height: '34px' }}
        />
        <div className="loginSignupHeading md:mr-24">Reset Password</div>
        <div className="mt-25px">
          <CustomInput
            inputStyle="labelStyle inputStyle"
            label="Enter Password"
            type="password"
            handleChange={(e) => this.onChange('pwd', e.target.value)}
            value={this.state.pwd}
          />
        </div>
        <div className="mt-5">
          <CustomInput
            inputStyle="labelStyle inputStyle"
            label="Re Enter Password"
            type="password"
            handleChange={(e) => this.onChange('re_pwd', e.target.value)}
            value={this.state.re_pwd}
          />
        </div>
        <div className="reset-password-submit">
          <CustomButton buttonStyle="btn-style" label="Submit" onClick={this.onSubmit} />
        </div>
        {this.state.pwd_error ? (
          <div className="reset-password-error">{this.state.pwd_error_msg}</div>
        ) : null}
        {this.state.pwd_status === 'loading' ? (
          <div className="reset-password-submit-loading">
            <Spinner circleRadius={50} />
          </div>
        ) : null}
      </div>
    );
  }
}
