import * as t from "../action_types";

// different status
// 1. none
// 2. inprogress
// 3. success
// 4. fail

var initialState = {
  zoom_signature: "",
  zoom_signature_status: "none",
  zoom_session_logs_uuid: "",
  join_url: ''
};

const set_zoom_variable = (state, payload) => {
  return {
    ...state,
    [payload.key]: payload.payload
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case t.set_zoom_variable:
      return set_zoom_variable(state, action.payload);
    default:
      return state;
  }
}
