import { all, put, takeEvery, call } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import urls from '../../urls';
import { post_api, get_api } from '../api_funcs';
import { error_console } from '../../util_functions';
import {
  get_availability_api,
  add_availability_api,
  edit_availability_api
} from '../api_functions/availability_api';

export function* getMyAvailability() {
  try {
    const result = yield call(get_availability_api);
    yield put(actions.set_availability_variable('availability_schedule', result.teacher_schedule));
  } catch (error) {
    error_console('availability error');
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
  }
}

export function* addAvailability(action) {
  try {
    yield put(actions.set_availability_variable('availability_submit_status', 'inprogress'));
    yield call(add_availability_api, action.payload);
    yield put(actions.set_availability_variable('availability_submit_status', 'success'));
    yield put(actions.get_my_availability());
    yield put(actions.load_dashboard_sessions());
  } catch (error) {
    yield put(actions.set_availability_variable('availability_submit_status', 'fail'));
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
  }
}

export function* editAvailability(action) {
  try {
    yield put(actions.set_availability_variable('availability_submit_status', 'inprogress'));
    yield call(edit_availability_api, action.payload);
    yield put(actions.set_availability_variable('availability_submit_status', 'success'));
    yield put(actions.get_my_availability());
    yield put(actions.load_dashboard_sessions());
  } catch (error) {
    yield put(actions.set_availability_variable('availability_submit_status', 'fail'));
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
  }
}

export function* deleteAvailability(action) {
  try {
    const url = urls.delete_availability;
    const payload = {
      teacher_schedule_cron_uuid: action.payload
    };
    yield call(post_api, url, payload, true);
    yield put(actions.get_my_availability());
    yield put(actions.load_dashboard_sessions());
  } catch (error) {
    yield put(actions.set_availability_variable('availability_submit_status', 'fail'));
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
  }
}

export function* getMySessionV1(action) {
  try {
    yield put(actions.set_availability_variable('my_session_status', 'loading'));
    const payload = {
      ...action.payload
    };
    const my_session_v1 = `${urls.my_session_v1}${`/${payload.month}/${payload.year}`}`;
    const response = yield call(get_api, my_session_v1, payload, true);
    yield put(actions.set_availability_variable('my_session_response', response.data));
    yield put(actions.set_availability_variable('my_session_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_availability_variable('my_session_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* getMySessionData(action) {
  try {
    yield put(actions.set_availability_variable('my_session_data_status', 'loading'));
    const payload = {
      ...action.payload
    };
    const my_session_data_v1 = `${urls.my_session_data_v1}${`/${payload.month}/${payload.year}`}`;
    const response = yield call(get_api, my_session_data_v1, payload, true);
    yield put(actions.set_availability_variable('my_session_data_response', response.data));
    yield put(actions.set_availability_variable('my_session_data_status', 'success'));
  } catch (error) {
    const err_payload = {
      error,
      type: action.type
    };
    yield put(actions.set_availability_variable('my_session_data_status', 'fail'));
    yield put(actions.handle_error_from_api(err_payload));
  }
}

export function* handleErrorFromApi(action) {
  try {
    const { error } = action.payload;
    const { type } = action.payload;

    if (Object.keys(error).includes('request')) {
      const responseString = Object.keys(error).includes('response')
        ? JSON.stringify(error.response)
        : '';
      error_log('the error is');
      error_log(error);
      try {
        if (error.response.status === 401) {
          yield put(actions.log_out());
        }
      } catch {}
    } else {
      error_log('the error is');
      error_log(error);
    }
  } catch (e) {}
}

export default function* availabilitySaga() {
  yield all([
    takeEvery(t.my_session_v1, getMySessionV1),
    takeEvery(t.my_session_data_v1, getMySessionData),
    takeEvery(t.get_my_availability, getMyAvailability),
    takeEvery(t.add_availability, addAvailability),
    takeEvery(t.edit_availability, editAvailability),
    takeEvery(t.delete_availability, deleteAvailability),
    takeEvery(t.handle_error_from_api, handleErrorFromApi)
  ]);
}
