import React, { useEffect, useRef } from 'react';

const SessionEndedModal = ({
    show,
    title,
    body,
    onCancel,
    onConfirm,
    confirmText,
    cancelText,
    showErrorSVG = true,
}) => {

    const sessionEndModalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (sessionEndModalRef?.current && !sessionEndModalRef?.current?.contains(event.target)) {
            onCancel();
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [show]);
    return (
        <>
            {show && (
                <div className='relative z-10' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
                    <div className='fixed inset-0 bg-gray-900 bg-opacity-90 transition-opacity'></div>

                    <div className='fixed inset-0 z-10 overflow-y-auto'>
                        <div className='flex min-h-full items-end justify-center md:p-4 text-center sm:items-center sm:p-0'>
                            <div ref={sessionEndModalRef} className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 zm:w-full sm:max-w-400px'>
                                <div className='bg-white zm:pt-10 pb-5 px-30px sm:pt-30px'>
                                    <div className=''>
                                        {showErrorSVG && (
                                            <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:h-14 sm:w-14'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="25" viewBox="0 0 28 25" fill="none">
                                                    <g clipPath="url(#clip0_418_1686)">
                                                        <path d="M26.8902 20.466L16.3181 1.84019C15.8031 0.932253 14.8407 0.372559 13.7976 0.372559C12.7534 0.372559 11.7909 0.932253 11.2759 1.84019L0.704964 20.466C0.195407 21.3625 0.201947 22.4639 0.720768 23.356C1.23959 24.247 2.19439 24.7964 3.22604 24.7964H24.3685C25.4007 24.7964 26.3545 24.247 26.8738 23.356C27.3932 22.4639 27.3986 21.3619 26.8902 20.466ZM15.2418 21.3739C14.8875 21.6889 14.4668 21.8486 13.9927 21.8486C13.5125 21.8486 13.088 21.6911 12.7294 21.3804C12.3632 21.0622 12.1768 20.6164 12.1768 20.0551C12.1768 19.5624 12.3523 19.1373 12.6983 18.7918C13.0439 18.4468 13.4722 18.2713 13.9714 18.2713C14.4695 18.2713 14.9011 18.4457 15.2543 18.7907C15.608 19.1368 15.7878 19.5624 15.7878 20.0551C15.7873 20.6082 15.6036 21.0513 15.2418 21.3739ZM15.711 9.38053L15.2505 14.6549C15.1987 15.3116 15.0875 15.8064 14.911 16.1661C14.7164 16.5639 14.3769 16.7748 13.9284 16.7748C13.4711 16.7748 13.1343 16.5683 12.9539 16.1775C12.7937 15.8304 12.6836 15.3263 12.6166 14.6341L12.2733 9.50152C12.2084 8.48731 12.1762 7.77938 12.1762 7.3374C12.1762 6.69869 12.3501 6.18913 12.6929 5.8229C13.039 5.45341 13.4984 5.26539 14.0564 5.26539C14.7453 5.26539 15.2161 5.51553 15.457 6.0082C15.6783 6.46271 15.7862 7.09053 15.7862 7.92707C15.7873 8.4012 15.7617 8.89005 15.711 9.38053Z" fill="#FF2121" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_418_1686">
                                                            <rect width="28" height="25" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        )}
                                        <div className='mt-3 text-center sm:mt-15px sm:ml-4 '>
                                            <h3
                                                className='font-inter text-18px font-semibold leading-8 text-gray-900'
                                                id='modal-title'
                                            >
                                                {title}
                                            </h3>
                                            <div className=''>
                                                <p className='text-14px font-inter text-contactHeading leading-5 font-medium' 
                                                style={{color: '#999'}}>{body}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-15px sm:pb-30px zm:pb-10">
                                    <div className='px-30px'>
                                        {confirmText && (
                                            <button
                                                type='button'
                                                onClick={onConfirm}
                                                className='inline-flex w-full justify-center rounded-md bg-red-600 px-18px pt-3 pb-11px h-46px text-15px leading-22px font-semibold text-white shadow-sm hover:bg-red-500'
                                            >
                                                {confirmText}
                                            </button>
                                        )}
                                    </div>

                                    {/*------ END SESSION BUTTON----*/}
                                    {/* <div className='px-30px'>
										<button
											type='button'
											onClick={}
											className='w-full btnGray rounded-md px-18px pt-3 pb-11px h-46px'
										>
											End Session For All
										</button>
                                    </div> */}
                                    {/*------ END SESSION BUTTON----*/}

                                    <div className='px-30px'>
                                        {cancelText && (
                                            <button
                                                type='button'
                                                onClick={onCancel}
                                                className='btn-white-default mt-3 inline-flex w-full justify-center rounded-md h-46px ring-inset ring-gray-300 sm:mt-0'
                                            >
                                                {cancelText}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SessionEndedModal;
