import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import * as reselectors from '../../../redux/selectors';
import * as actions from '../../../redux/action_creators';
import './announcements.css';
import CheckMobile from '../check_mobile';
import Header from '../../util_components/header';

import LoadingScreen from '../../util_components/loading_screen';
import 'react-quill/dist/quill.snow.css';
import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      announcements: [],
      readAnnouncementSuccess: false,
      announcementTab: {},
      showSubHeading: {},
      readAnnouncementClicked: {}
    };
  }

  componentDidMount() {
    if (this.props.get_announcements_status != 'loading') {
      this.props.get_announcements();
    }
    sendEventToClarity();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.get_announcements_status === 'loading' &&
      this.props.get_announcements_status === 'success'
    ) {
      this.get_announcements();
    }

    if (
      prevProps.read_announcement_status === 'loading' &&
      this.props.read_announcement_status === 'success'
    ) {
      this.setState({ readAnnouncementSuccess: true });
    }
  }

  get_announcements = () => {
    if (this.state.loading) {
      this.setState(
        {
          announcements: this.props.get_announcements_response,
          loading: false,
          details: this.props.get_announcements_response.announcements[0],
          announcementTab:
            this.props.get_announcements_response.announcements[0].announcements.length > 0
              ? this.props.get_announcements_response.announcements[0].announcements[0]
              : {}
        },
        () => {
          if (
            this.props.get_announcements_response.announcements[0].announcements.length > 0 &&
            !this.props.get_announcements_response.announcements[0].announcements[0].is_opened
          ) {
            this.props.read_announcement({
              announcement_uuid:
                this.props.get_announcements_response.announcements[0].announcements[0].uuid
            });
          }
        }
      );
    } else {
      this.setState({
        announcements: this.props.get_announcements_response
      });
    }
  };

  announcementsDetails = (name, index) => {
    const {showSubHeading} = this.state;
    Object.keys(showSubHeading).map((each) => {
      if (index != each) {
        showSubHeading[each] = false;
      }
    });
    showSubHeading[index] = this.state.showSubHeading[index]
      ? !this.state.showSubHeading[index]
      : true;
    const details = this.state.announcements.announcements[index];
    this.setState({
      details,
      showSubHeading: { ...showSubHeading }
    });
  };

  handleAcknowledge = (uuid) => {
    this.props.read_announcement({
      announcement_uuid: uuid,
      acknowledgement: 1
    });
    const readAnnouncementClicked = {};
    readAnnouncementClicked[uuid] = true;
    this.setState({
      readAnnouncementClicked
    });
  };

  urlify = (text) => {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    // var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url, b, c) => {
      const url2 = c == 'www.' ? `http://${  url}` : url;
      return `<a href="${  url2  }" target="_blank">${  url  }</a>`;
    });
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
  };

  replaceNewLines = (message) => {
    let newMessage = '';
    const ms = message.trim().split('\n');
    for (let i = 0; i < ms.length; i++) {
      newMessage += ms[i];
      if (i < ms.length - 1) newMessage += '<br/>';
    }
    return newMessage;
  };

  show_red_dot = (ann) => {
    let res = 0;
    if (ann.acknowledgement_required === 1) res = !ann.is_opened || !ann.acknowledgement_given;
    else res = !ann.is_opened;
    return res;
  };

  render() {
    return (
      <CheckMobile>
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div className="announce">
            <Header />

            <div className="announce-sidebar">
              <h3 className="announce-heading_name">Announcements</h3>
              {!this.state.loading &&
              this.state.announcements &&
              this.state.announcements.announcements.length > 0
                ? this.state.announcements.announcements.map((each, index) => (
                      <React.Fragment key={each.uuid}>
                        <span
                          className={`announce-heading ${
                            this.state.showSubHeading[index] ? 'sel' : ''
                          }`}
                          onClick={() => this.announcementsDetails(each.name, index)}
                        >
                          {each.unread_announcements > 0 ? (
                            <span className="sub-noti">{each.unread_announcements}</span>
                          ) : each.announcements.filter((each) => !each.is_opened).length > 0 ? (
                            <span className="sub-noti">
                              {each.announcements.filter((each) => !each.is_opened).length}
                            </span>
                          ) : (
                            ''
                          )}
                          {each.name}
                        </span>
                        {this.state.details && this.state.details.name === each.name && (
                          <>
                            {/* <div>{this.state.details.name}</div> */}
                            {this.state.details.announcements.length > 0 &&
                            this.state.showSubHeading[index]
                              ? this.state.details.announcements.map((each) => (
                                  <div
                                    className={`announce-sub_heading ${
                                      this.state.announcementTab === each ? 'sel' : ''
                                    } ${!each.is_opened ? 'sub-unread' : 'sub-read'}`}
                                    onClick={() =>
                                      this.setState({ announcementTab: each }, () => {
                                        if (!each.is_opened) {
                                          this.props.read_announcement({
                                            announcement_uuid: each.uuid
                                          });
                                        }
                                      })
                                    }
                                  >
                                    {each.name}{' '}
                                    {this.show_red_dot(each) ? (
                                      <span className="sub-noti-point" />
                                    ) : null}
                                  </div>
                                ))
                              : this.state.details.announcements.length == 0 &&
                                this.state.showSubHeading[index] && (
                                  <div className={`announce-sub_heading `}>No Announcements</div>
                                )}{' '}
                          </>
                        )}
                      </React.Fragment>
                    ))
                : null}
            </div>

            <div className="announce-content">
              <button className="toggleit_mb_an">
                <div className="tbtn_cont_mb_an">
                  <div className="tbtn_line_mb_an" />
                  <div className="tbtn_line_mb_an" />
                  <div className="tbtn_line_mb_an" />
                </div>
              </button>
              {this.state.announcementTab && Object.keys(this.state.announcementTab).length > 0 && (
                <>
                  <div className="an-breadcrumbs">
                    <button className="an-bdcum-link">Announcements</button>
                    <button className="an-bdcum-link">
                      {this.state.announcementTab.annoucement_type_name}
                    </button>
                    <button className="an-bdcum-link">{this.state.announcementTab.name}</button>
                  </div>
                  <div className="publish-date">
                    Published on&nbsp;
                    {moment(this.state.announcementTab.published_date_epoch)
                      .tz(moment.tz.guess())
                      .format('ddd, MMM Do YYYY, hh:mm A z')}
                  </div>

                  <div
                    dangerouslySetInnerHTML={{ __html: this.state.announcementTab.content }}
                    className="ann-details ql-snow ql-editor"
                   />
                  {/* {HtmlReactParser(this.urlify(this.replaceNewLines()))} */}

                  {this.state.announcementTab.acknowledgement_required === 1 ? (
                    <div className="ack-footer">
                      {!this.state.announcementTab.acknowledgement_given ? (
                        <button
                          className={`announce-content-btn ${
                            this.state.readAnnouncementClicked[this.state.announcementTab.uuid]
                              ? 'dis'
                              : ''
                          }`}
                          onClick={() => this.handleAcknowledge(this.state.announcementTab.uuid)}
                        >
                          {this.state.readAnnouncementClicked[this.state.announcementTab.uuid]
                            ? 'Acknowledged'
                            : 'Acknowledge'}
                        </button>
                      ) : (
                        <button className="announce-content-btn dis">Acknowledged</button>
                      )}
                    </div>
                  ) : null}
                </>
              )}
            </div>

            {/* <Footer /> */}
          </div>
        )}
      </CheckMobile>
    );
  }
}

const mapStateToProps = (state) => ({
    teacher_details: state.profile.teacher_details,
    home_component_index: reselectors.get_home_component_index(state),
    get_announcements_status: state.home.get_announcements_status,
    get_announcements_response: state.home.get_announcements_response,
    read_announcement_status: state.home.read_announcement_status,
    read_announcement_response: state.home.read_announcement_response
  });

const mapDispatchToProps = (dispatch) => ({
    set_home_component_index: (index) => dispatch(actions.set_home_component_index(index)),
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    },
    update_status: (index) => dispatch(actions.update_status(index)),
    get_announcements: () => {
      dispatch(actions.get_announcements());
    },
    read_announcement: (payload) => {
      dispatch(actions.read_announcement(payload));
    }
  });

const AnnouncementsWithRedux = connect(mapStateToProps, mapDispatchToProps)(Announcements);
const AnnouncementsWithRouter = withRouter(AnnouncementsWithRedux);

export default AnnouncementsWithRouter;
