import * as t from '../action_types'

export const get_teacher_details = (payload) => {
    return {
        type: t.get_teacher_details,
        payload: payload,
    }
}

export const set_profile_variable = (key, payload) => ({
    type: t.set_profile_variable,
    payload: {
        key,
        payload,
    },
})

export const update_profile_details = (component_name, data) => ({
    type: t.update_profile_details,
    payload: {
        component_name,
        data,
    },
})

export const update_profile_files = (key, file_component, payload) => ({
    type: t.update_profile_files,
    payload: {
        key,
        file_component,
        payload,
    },
})

export const get_teacher_notification_preferences = (payload) => {
    return {
        type: t.get_teacher_notification_preferences,
        payload,
    }
}

export const update_teacher_notification_preferences = (payload) => {
    return {
        type: t.update_teacher_notification_preferences,
        payload,
    }
}