import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import LoadingScreen from '../../util_components/loading_screen';
import EditRoadMapPop from './EditRoadMapPop';
import './yoga_journey.css';
import './road_map.css';
import './edit_rm.css';
import { withRouter } from '../../../utils/router';
import { sendEventToClarity } from '../../../util_functions';

class AddRoadMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show_loader: false,
      totalTime: 0,
      showTimer: false,
      edit_cur_fitness_situ: false,
      answer_is_present: true,
      showAddAnswer: false,
      selectedIndex: 0,
      selectedAnswer: '',
      noSessionsLoading: false,
      timer: '',
      is_read_more: false,
      pop_prevent_user_info: false,
      remaining_ans: null,
      values_changed: false
    };
    this.answerRefs = [];
    this.studentRoadmap = null;
    this.sessionUuid = null;
    this.isRoadMapCreated = null;
  }

  componentDidMount() {
    if (Object.keys(this.props.currentRoadMapSession).length === 0) {
      this.props.router.navigate('/mysessions');
    } else {
      this.studentRoadmap = this.props.currentRoadMapSession.student_roadmap || null;
      this.sessionUuid = this.props.currentRoadMapSession.session_uuid;
      this.isRoadMapCreated = this.props.currentRoadMapSession.is_road_map_created || 0;
      this.setState({
        isRoadMapCreated: this.props.currentRoadMapSession.is_road_map_created || 0,
        sessionUuid: this.props.currentRoadMapSession.session_uuid,
        studentRoadmap: this.props.currentRoadMapSession.student_roadmap,
        questions: this.props.currentRoadMapSession.student_roadmap.roadmap_questions,
        no_of_sessions: this.props.currentRoadMapSession.student_roadmap.no_of_sessions,
        loading: false
      });
    }

    sendEventToClarity();
    // if (this.state.isRoadMapCreated === '0') {
    //   this.showLoader();
    //   this.props.create_roadmap({ session_uuid: this.state.sessionUuid }, (data, error) => {
    //     this.setState({ isRoadMapCreated: 1 });
    //     if (error) {
    //       alert(error.response ? error.response.data.message : error.message);
    //     } else {
    //       if (data.data.results[0] && data.data.results[0].student_roadmap) {
    //         this.studentRoadmap = data.data.results[0].student_roadmap;
    //         this.setState({ questions: data.data.results[0].student_roadmap.roadmap_questions, isRoadMapCreated: 1 });
    //       }
    //     }
    //   });
    // }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.create_roadmap_status === 'loading' &&
      this.props.create_roadmap_status === 'success'
    ) {
      this.setState({ isRoadMapCreated: 1 });

      this.hideLoader();
      this.timer();
    }
    if (this.state.totalTime > 0) {
      this.countDown();
    }

    if (
      prevProps.update_session_recap_status === 'loading' &&
      this.props.update_session_recap_status === 'success'
    ) {
      const {currentRoadMapSession} = this.props;

      for (const roadmapQuestion of currentRoadMapSession.student_roadmap.roadmap_questions) {
        if (roadmapQuestion.api_param === 'notes') {
          roadmapQuestion.answer = this.props.update_session_recap_values.results.notes;
        }
        if (roadmapQuestion.api_param === 'recap') {
          roadmapQuestion.answer = this.props.update_session_recap_values.results.recap;
        }
      }

      this.props.set_home_variable('currentRoadMapSession', currentRoadMapSession);
    }

    if (
      prevProps.update_roadmap_ques_status === 'loading' &&
      this.props.update_roadmap_ques_status === 'success'
    ) {
      // this.hideLoader();
      // this.props.updateMySessionData();
    }
    if (
      prevProps.update_whole_roadmap_status === 'loading' &&
      this.props.update_whole_roadmap_status === 'success'
    ) {
      this.hideLoader();
      this.updateAnswerValue(this.state.selectedAnswer);
    }
  }

  UNSAFE_componentWillMount() {
    if (
      this.props.currentRoadMapSession === undefined ||
      this.props.currentRoadMapSession === null ||
      Object.keys(this.props.currentRoadMapSession).length === 0
    ) {
      // window.location.replace('/');
    }
    this.countDown();
  }

  componentWillUnmount() {
    let parent = '';
    try {
      parent = this.props.router.location.state.parent_component;
    } catch (e) {}
    if (parent === 'mysessions') {
      this.props.load_my_sessions();
    }
    this.props.load_short_dashboard_sessions();
  }

  timer = () => {
    const countDownDate = new Date(this.props.currentRoadMapSession.epoch_start_time).getTime();

    var x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      //   var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Output the result in an element with id="demo"
      // document.getElementById('demo').innerHTML = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
      this.setState({
        showTimer: true,
        timer: `${hours.toString()  }:${  +minutes.toString()  }:${  +String(seconds)}`
      });

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        this.setState({
          showTimer: false
        });
      }
    }, 1000);
  };

  countDown() {
    const sec_num = this.state.totalTime; // don't forget the second param
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - hours * 3600) / 60);
    const seconds = parseInt(sec_num - hours * 3600 - minutes * 60);

    this.setState({
      showTimer: true,
      timer:
        `${(hours < 10 ? '0' : '') +
        hours.toString() 
        }:${ 
        minutes < 10 ? '0' : '' 
        }${minutes.toString() 
        }:${ 
        seconds < 10 ? '0' : '' 
        }${String(seconds)}`
    });

    if (this.state.totalTime != 0) {
      this.totalTime--;
      setTimeout(this.countDown, 1000);
    }
    if (this.state.totalTime == 0) {
      this.setState({ showTimer: false });
    }
  }

  getAnsweredQuestionCount = () => this.state.questions.filter((item) => !!item.answer).length;

  goBack = () => {
    this.props.router.navigate(-1);
  };

  goBackCondition = () => {
    const questionAnswered = this.getAnsweredQuestionCount();
    const totalQuestions =
      this.state.questions !== null && this.state.questions !== 'undefined'
        ? this.state.questions.length
        : null;
    const remaining_ans = (totalQuestions - questionAnswered).toString();
    if (questionAnswered == totalQuestions) {
      this.props.router.navigate(-1);
    } else {
      this.setState({
        pop_prevent_user_info: true,
        remaining_ans
      });
    }
  };

  closePop_prevent = () => {
    this.setState({
      pop_prevent_user_info: false
    });
  };

  showLoader = () => {
    this.setState({
      show_loader: true
    });
  };

  hideLoader = () => {
    this.setState({
      show_loader: false
    });
  };

  updateMySessionData = () => {
    this.setState({
      showAddAnswer: false
    });
  };

  updateMySessionDatacall() {
    if (!this.isFromHome) {
      const params = { timezone: new Date().getTimezoneOffset().toString() };
      this.props.update_roadmap(params, (result, error) => {
        this.hideLoader(false);
        this.setState({ showAddAnswer: false });
      });
    } else {
      this.showLoader();
      this.setState({ showAddAnswer: false });
    }
  }

  closeAddAns = () => {
    this.setState({
      showAddAnswer: false
    });
  };

  renderRoadMapQuestion(item, index) {
    return item.api_param == 'no_of_sessions'
      ? this.renderMonthsItem(item, index)
      : this.renderRoadMapQuestion_sp(item, index);
  }

  renderMonthsItem = (item, index) => {
    const {no_of_sessions} = this.state;
    const months = [
      { title: '5 Sessions', number: 5 },
      { title: '10 Sessions', number: 10 },
      { title: '20 Sessions', number: 20 },
      { title: '30 Sessions', number: 30 },
      { title: '40 Sessions', number: 40 },
      { title: '50 Sessions', number: 50 }
    ];
    return (
      <li className={item.answer != '' ? 'rm_green_yes' : null}>
        <div className="rm-edit-ques-cont">
          {item.answer != '' && <span className="rm_green_right" />}
          <div className="edit-ques">{item.question}</div>
          <div className="edit-ans edit_rm_btns_cont">
            {months.map((obj) => this.renderSelectedMonthItem(obj, item, index))}
          </div>
        </div>
      </li>
    );
  };

  renderSelectedMonthItem(obj, item, index) {
    const {title} = obj;
    const isSelectedMonth = item.answer.toLowerCase() == title.toLowerCase();
    return (
      <button
        className={`btn edit_rm_months ${isSelectedMonth ? 'sel' : ''}`}
        onClick={() => this.sel_this_month(obj, title, index)}
      >
        {title}
      </button>
    );
  }

  sel_this_month = (obj, item, index) => {
    this.setState({
      noSessionsLoading: true,
      selectedIndex: index,
      selectedAnswer: item,
      values_changed: true
    });
    this.showLoader();
    const payload = {
      roadmap_uuid: this.props.currentRoadMapSession.student_roadmap.uuid,
      no_of_sessions: obj.number.toString()
    };
    this.props.update_roadmap(payload);
  };

  toggle_read_more = () => {
    this.setState({
      is_read_more: !this.state.is_read_more
    });
  };

  renderRoadMapQuestion_sp = (item, index) => {
    const answer = '';
    const subText =
      item.question == 'Teacher notes?'
        ? '* This won’t be shared with student'
        : '* This will be shared with student';

    return (
      <>
        {this.state.studentRoadmap.no_of_months === 0 ? (
          <li className={item.answer ? 'rm_green_yes' : null}>
            <div className="rm-edit-ques-cont">
              {!!item.answer && <span className="rm_green_right" />}
              <div className="edit-ques">
                {item.question}
                {(item.question == 'Teacher notes?' || item.question == 'Session recaps') && (
                  <p>{subText}</p>
                )}
              </div>
              {item.answer != '' ? (
                <div className="edit-ans" ref={(answerRef) => (this.answerRefs[index] = answerRef)}>
                  {!!item.answer && item.answer.length > 100 ? (
                    !this.state.is_read_more ? (
                      <>
                        {item.answer.slice(0, 100)}
                        <span className="yj-read-more" onClick={this.toggle_read_more}>
                          ... Read more
                        </span>
                      </>
                    ) : (
                      <>
                        {item.answer}
                        <span className="yj-read-more" onClick={this.toggle_read_more}>
                          {' '}
                          Read less
                        </span>
                      </>
                    )
                  ) : (
                    item.answer
                  )}
                  <button
                    className="btn rm_edit_btn"
                    onClick={() => this.onEditPressed(item, index)}
                  >
                    Edit
                  </button>
                </div>
              ) : (
                <button
                  className="btn add-rm-ans-btn"
                  onClick={() => this.onEditPressed(item, index)}
                >
                  Add answer
                </button>
              )}
            </div>
          </li>
        ) : null}
      </>
    );
  };

  onEditPressed = (selectedItem, selectedIndex) => {
    this.setState({
      selectedIndex,
      showAddAnswer: true,
      selectedAnswer: selectedItem.answer
    });
  };

  render() {
    const months = ['2Months', '3Months', '4Months', '6Months', '8Months', '12Months'];
    if (this.state.show_loader) {
      return <LoadingScreen />;
    }
    if (this.state.loading) {
      return <LoadingScreen />;
    } 
      return (
        <div className="m-card-body">
          <div className="m-card">
            <div className="m-card-header">
              <div className="m-card-head-wrap">
                <button className="btn btn-mcard-back" onClick={this.goBackCondition}>
                  <img
                    alt="back-arrow"
                    src="https://s3.amazonaws.com/images.myyogateacher.com/icons/back-arrow-white1.svg"
                  />
                </button>

                <h1>
                  {this.getAnsweredQuestionCount()} / {this.state.questions.length} completed
                </h1>
                <p>Session questions - It helps to create student roadmap</p>
              </div>
            </div>

            {this.state.showTimer && (
              <div className="rm_ban_doit">Time to complete {this.state.timer}</div>
            )}

            <div className="rm-c-edit-cont">
              <ul className="rm-edit-ques-ul">
                {this.state.questions.map((item, index) => this.renderRoadMapQuestion(item, index))}
              </ul>
            </div>
          </div>

          {/* ------------------------ */}
          {this.state.edit_cur_fitness_situ ? (
            <EditRoadMapPop
              onClose={this.closeCurrFitnessSitu}
              ques="Dave’s current fitness situation"
            />
          ) : null}

          {this.state.showAddAnswer ? (
            <EditRoadMapPop
              onClose={this.closeAddAns}
              selectedQuestion={this.state.questions[this.state.selectedIndex]}
              ques_item={this.state.questions[this.state.selectedIndex]}
              sessionUuid={this.props.currentRoadMapSession.session_uuid}
              updateMySessionData={this.updateMySessionData}
              updateAnswerValue={this.updateAnswerValue}
            />
          ) : null}
          {this.state.pop_prevent_user_info ? (
            <div className="pop_prevent_pop_outer">
              <div className="pop_prevent_in">
                {this.state.remaining_ans} more roadmap questions remaining.
                <div className="pop_prevent_btn_row">
                  <button className="p_prev_skip_btn" onClick={this.goBack}>
                    Skip
                  </button>
                  <button className="p_prev_ok_btn" onClick={this.closePop_prevent}>
                    Okay
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    
  }

  updateAnswerValue = (answer) => {
    const {questions} = this.state;
    questions[this.state.selectedIndex].answer = answer;
    // try{
    //   let question = questions[this.state.selectedIndex].question;
    //   let roadmap = this.props.currentRoadMapSession;
    //   const display_roadmap = roadmap.student_roadmap.display_roadmap;
    //   for (let key of Object.keys(display_roadmap)){
    //     let item_index = display_roadmap[key].findIndex(i => i.question === question);
    //     if(item_index !== -1){
    //       roadmap.display_roadmap[key][item_index].answer = answer;
    //     }
    //   }
    // }
    // catch(e){
    // }
    this.setState({
      questions,
      values_changed: true
    });
  };
}

const mapStateToProps = (state) => ({
    create_roadmap_status: selectors.create_roadmap_status(state),
    update_session_recap_status: state.home.update_session_recap_status,
    update_session_recap_values: state.home.update_session_recap_values,
    update_roadmap_ques_status: state.home.update_roadmap_ques_status,
    update_roadmap_ques_values: state.home.update_roadmap_ques_values,
    update_whole_roadmap_status: state.home.update_whole_roadmap_status,
    currentRoadMapSession: state.home.currentRoadMapSession
  });

const mapDispatchToProps = (dispatch) => ({
    create_roadmap: (payload) => {
      dispatch(actions.create_roadmap(payload));
    },
    update_session_recap: (payload) => {
      dispatch(actions.update_session_recap(payload));
    },
    update_roadmap_question: (payload) => {
      dispatch(actions.update_roadmap_question(payload));
    },
    update_roadmap: (payload) => {
      dispatch(actions.update_roadmap(payload));
    },
    load_short_dashboard_sessions: () => {
      dispatch(actions.load_short_dashboard_sessions());
    },
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    },
    load_my_sessions: () => {
      dispatch(actions.load_my_sessions());
    }
  });

const AddRoadMapWithRedux = connect(mapStateToProps, mapDispatchToProps)(AddRoadMap);
const AddRoadMapWithRouter = withRouter(AddRoadMapWithRedux);

export default AddRoadMapWithRouter;
