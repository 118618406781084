import React, { Component } from 'react';
import moment from 'moment';
import CustomInput from '../../util_components/custom_input';
import CustomButton from '../../util_components/custom_button';
import CustomDropDown from '../../util_components/custom_dropdown';
import CustomDatePicker from '../../util_components/custom_date_picker';
import CustomRadioButton from '../../util_components/custom_radio_button';
import CustomAlert from '../../util_components/custom_alert';
import CustomCheckbox from '../../util_components/custom_checkbox';
import { yoga_teaching_list, teaching_years, yoga_levels } from '../../../constants';
import { chunk_array } from '../../../util_functions';

export const render_yoga_all_details = details => {
	return (
		<div>
			<div className="profile-basic-detail-values yoga-details">
				<div className="profile-basic-detail-index yoga-detail-index">Teaching experience:</div>
				<div className="profile-basic-detail-value">
					{details.years_of_yoga_teaching_experience === '' ||
					typeof details.years_of_yoga_teaching_experience === 'undefined'
						? '- -'
						: details.years_of_yoga_teaching_experience}
					{' years'}
				</div>
			</div>
			<div className="profile-basic-detail-values yoga-details">
				<div className="profile-basic-detail-index yoga-detail-index">Years of practice:</div>
				<div className="profile-basic-detail-value">
					{details.years_of_yoga_practise === '' || typeof details.years_of_yoga_practise === 'undefined'
						? '- -'
						: details.years_of_yoga_practise}
					{' years'}
				</div>
			</div>
			<div className="profile-basic-detail-values yoga-details">
				<div className="profile-basic-detail-index yoga-detail-index">Goals i can address:</div>
				<div className="profile-basic-detail-value">
					{details.goals?.length === 0 || typeof details.goals === 'undefined'
						? '- -'
						: details?.goals?.map((item, index) => (
								<div key={item + index} className="profile-basic-detail-multiple-value">
									{item}
								</div>
						  ))}
				</div>
			</div>
			<div className="profile-basic-detail-values yoga-details">
				<div className="profile-basic-detail-index yoga-detail-index">Yoga types:</div>
				<div className="profile-basic-detail-value">

					{details.yoga_types_teaching?.length === 0 || typeof details.yoga_types_teaching === 'undefined'
						? '- -'
						: details?.yoga_types_teaching?.map((item, index) => (
								<div key={item + index} className="profile-basic-detail-multiple-value">
									{item.name} {`(${item.level})`}
								</div>
						  ))}
				</div>
			</div>
			<div className="profile-basic-detail-values yoga-details">
				<div className="profile-basic-detail-index yoga-detail-index">Certificates:</div>
				<div className="profile-basic-detail-value">
					{details.certificates?.length === 0 || typeof details.certificates === 'undefined'
						? '- -'
						: details?.certificates?.map((item, index) => (
								<div key={item + index} className="profile-basic-detail-multiple-value">
									{item.name}
									{'  '}
									<span className="profile-yoga-cert-value">
										{'  '}
										{`  (${moment(item.start_date).format('DD/MM/YYYY')} - ${moment(item.end_date).format(
											'DD/MM/YYYY'
										)})`}
									</span>
								</div>
						  ))}
				</div>
			</div>
		</div>
	);
};

export class RenderYogaEdit extends Component {
	state = {
		confirm_open: false,
		goals_error: false
	};
	close_confirm = () => this.setState({ confirm_open: false });
	onYogaClick = (type, level) => {
		const yoga_types = this.props.details.yoga_types_teaching || [];
		let obj = yoga_types?.filter(o => o.name === type);
		if (obj.length===0) {
			yoga_types?.push({ name: type, level });
		} else {
			obj[0].level = level;
		}
		this.props.handleChange(yoga_types, 'yoga_details', 'yoga_types_teaching');
	};
	on_yoga_check_box_click = (type, checked) => {
		if (checked) {
			const yoga_types = this.props.details.yoga_types_teaching;
			const filteredTypes = yoga_types?.filter(item => item.name !== type);
			this.props.handleChange(filteredTypes, 'yoga_details', 'yoga_types_teaching');
		} else {
			this.onYogaClick(type, 'Intermediate');
		}
	};
	handle_date_change = (index, key, value) => {
		const certificates = this.props.details.certificates;
		certificates[index][key] = value;
		this.props.handleChange(certificates, 'yoga_details', 'certificates');
	};
	add_certificate_count = () => {
		const obj = {
			name: '',
			start_date: new Date(),
			end_date: new Date()
		};
		let certificates = this.props.details.certificates;
		certificates?.push(obj);
		this.props.handleChange(certificates, 'yoga_details', 'certificates');
	};
	remove_ceritificate_confirm = index => {
		this.setState({ confirm_open: true, delete_index: index });
	};
    remove_ceritificate = () => {
        const { delete_index } = this.state;
        const { details, handleChange } = this.props;
      
        if (details && details.certificates && delete_index !== undefined) {
          const certificates = [...details.certificates];
          certificates.splice(delete_index, 1);
      
          handleChange(certificates, 'yoga_details', 'certificates');
          this.setState({ confirm_open: false });
        } else {
          console.error("Error: Unable to remove certificate.");
        }
      };

    check_for_valid_dates = (d) => {
        return d instanceof Date && !isNaN(d);
      }
	render_certificates = () => {
		if (this.props.details.certificates?.length !== 0) {
            return this.props.details.certificates?.map((item, index) => {
				return (
					<div className="add-certificate" key={index}>
						<div className="add-certificate-div">
							<CustomInput
								handleChange={e => this.handle_date_change(index, 'name', e.target.value)}
								value={item.name}
								label="Name"
								width="700px"
							/>
						</div>
						<div className="add-certificate-date-div">
							<div className="profile-basic-dropdown-label">Start Date</div>
							<CustomDatePicker
                startDate={this.check_for_valid_dates(new Date(item.start_date))?new Date(item.start_date):new Date()}
								handleChange={e => this.handle_date_change(index, 'start_date', e)}
								component="profile"
							/>
						</div>
						<div className="add-certificate-date-div">
							<div className="profile-basic-dropdown-label">End Date</div>
							<CustomDatePicker
                startDate={this.check_for_valid_dates(new Date(item.end_date))?new Date(item.end_date):new Date()}
								handleChange={e => this.handle_date_change(index, 'end_date', e)}
								component="profile"
							/>
						</div>
						<div className="add-certificate-close">
							<img
								src="https://images.myyogateacher.com/teacher_web_app/ic_delete_red.png"
								alt="close"
								onClick={() => this.remove_ceritificate_confirm(index)}
							/>
						</div>
					</div>
				);
			});
		}
	};
	edit_goals = (item, item_index) => {
		let array = [...this.props.details.goals];
		if (item_index < 0) {
			if (array.length >= 3) {
				this.setState({ goals_error: true });
			} else {
				array.push(item);
				this.props.handleChange(array, 'yoga_details', 'goals');
				this.setState({ goals_error: false });
			}
		} else {
			array.splice(item_index, 1);
			this.props.handleChange(array, 'yoga_details', 'goals');
			this.setState({ goals_error: false });
		}
	};
	render_goals = () => {
		const chunked_goals = chunk_array(this.props.teacher_goals, 3);
		return chunked_goals?.map((arrays, index) => (
			<tr key={index}>
				{arrays?.map((item, new_index) => {
					const item_index = this.props.details.goals.indexOf(item);
					return (
						<td key={new_index}>
							<div className="profile-yoga-goals">
								<CustomCheckbox
									label={item}
									checked={item_index > -1}
									onClick={() => this.edit_goals(item, item_index)}
								/>
							</div>
						</td>
					);
				})}
			</tr>
		));
  };
  remove_older_goal = goal => {
    let array = [...this.props.details.goals];
    const index = array.findIndex(a => a === goal);
    array.splice(index, 1);
    this.props.handleChange(array, 'yoga_details', 'goals');
    this.setState({ goals_error: false });
  }
  render_older_goals = older_goals => {
    return(
      <div className="profile-yoga-edit-container profile-yoga-older-container">
        <div className="profile-basic-dropdown-label">Older goal values:</div>
        <div className="profile-yoga-older-edit">
          {older_goals?.map(goal => {
            return (
              <div className="profile-yoga-older-single">
                {goal}
                <img src="https://images.myyogateacher.com/icons/ic_cross@2x.png" onClick={() => this.remove_older_goal(goal)}/>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
	render() {
    const older_goals = this.props.details.goals.filter(a => this.props.teacher_goals.indexOf(a) === -1);
		return (
			<div className="profile-basic-edit-details profile-yoga-edit">
				{this.state.confirm_open ? (
					<CustomAlert
						bodyText="Are you sure you want to delete?"
						cancelFunc={this.close_confirm}
						confirmFunc={this.remove_ceritificate}
					/>
				) : null}
				<div className="profile-basic-address-line">
					<div className="profile-basic-address-line-text">Yoga: Your yoga experience</div>
				</div>
				<br />
				<div className="profile-details-edit-text">
					<div className="profile-basic-edit-custominput">
						<div className="profile-basic-dropdown-label">Teaching experience (years):</div>
						<CustomDropDown
							value={this.props.details.years_of_yoga_teaching_experience}
							handleChange={e => this.props.handleChange(e, 'yoga_details', 'years_of_yoga_teaching_experience')}
							drop_down_list={teaching_years}
						/>
					</div>
					<div className="profile-basic-edit-custominput">
						<div className="profile-basic-dropdown-label">Years of practice:</div>
						<CustomDropDown
							value={this.props.details.years_of_yoga_practise}
							handleChange={e => this.props.handleChange(e, 'yoga_details', 'years_of_yoga_practise')}
							drop_down_list={teaching_years}
						/>
					</div>
					<div className="profile-yoga-edit-container">
						<div className="profile-basic-dropdown-label">Goals i can address*:</div>
						<div>
							<table>
								<tbody>{this.render_goals()}</tbody>
							</table>
						</div>
					</div>
          {older_goals?.length > 0 ? this.render_older_goals(older_goals) : null}
					{this.state.goals_error ? (
						<div className="profile-yoga-edit-container">
							<div className="profile-basic-dropdown-label profile-error-label">Maximum 3 goals can be selected</div>
						</div>
					) : null}

					<div className="profile-yoga-edit-container">
						<div className="profile-basic-dropdown-label">Yoga teaching types*:</div>
						<div>
							<table>
								<tbody>
									{yoga_teaching_list.map(type => {
										const obj = this.props.details?.yoga_types_teaching?.find(o => o.name === type);
										const selected_type = typeof obj !== 'undefined';
										return (
											<tr>
												<td>
													<CustomCheckbox
														label={type}
														checked={selected_type}
														onClick={() => this.on_yoga_check_box_click(type, selected_type)}
													/>
												</td>
												{yoga_levels?.map((level, index) => {
													const selected = typeof obj === 'undefined' ? false : obj.level === level;
													return (
														<td>
															<CustomRadioButton
																label={level}
																onRadioClick={() => this.onYogaClick(type, level)}
																selected={selected}
															/>
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
					<div className="profile-basic-edit-custominput">
						<div className="profile-basic-address-line">
							<div className="profile-basic-address-line-text">Certificates:</div>
						</div>
						<br />
						{this.render_certificates()}
						<CustomButton label="Add Certificate" onClick={this.add_certificate_count} component="secondary" />
					</div>
				</div>
			</div>
		);
	}
}
