import React from "react";

import "./DrawerToggleButton.css";

const drawerToggleButton = props => {
  return (
    <button className="toggleit" onClick={props.click}>
      <div className="tbtn_cont">
        <div className="tbtn_line" />
        <div className="tbtn_line" />
        <div className="tbtn_line" />
      </div>
    </button>
  );
};

export default drawerToggleButton;
