import React, { Component } from 'react';
import Header from '../util_components/header_new_design';
import TopSection from './top_section';
import ToogleBtn from '../util_components/ToogleBtn';
import Requirment from './requirment';
import TeacherSay from './teacher_say';
import GetStarted from './get_started';
import OurMission from './our_mission';
import TeachingWith from './teaching_with';
import FAQ from '../util_components/faq';
import Footer from '../util_components/footer_new_design';
import VibrantCommunity from './vibrant-community';

import './common-teacher-page.css';
import { withRouter } from '../../utils/router';
import { sendEventToClarity } from '../../util_functions';

class Teacher extends Component {
  state = {
    scroll_blocked: false,
    offsetTop: 0,
    direction: '',
    intervalId: 0,
    delayInMs: 3,
    scrollStepInPx: 50,
    start_time: new Date().getTime()
  };

  componentDidMount() {
    sendEventToClarity();
  }

  scrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.state.scrollStepInPx);
  };

  scrollToTop = () => {
    const intervalId = setInterval(this.scrollStep, this.state.delayInMs);
    this.setState({ intervalId });
  };

  divRef = React.createRef();

  end_scroll_block = () => {
    this.setState({ scroll_blocked: false }, () => {
      window.scroll(0, this.state.offsetTop + this.state.top_offset);
    });
  };

  start_scroll_block = (top_offset, offsetTop) => {
    this.setState({ scroll_blocked: true, top_offset, offsetTop }, () => {
      window.scroll(0, offsetTop + top_offset);
    });
  };

  go_to_signup = () => {
    this.props.router.navigate('/signup');
  };

  render() {
    const main_classname = this.state.scroll_blocked
      ? 'teacher-container teacher-container-fixed '
      : 'teacher-container';
    const scroll_style = this.state.scroll_blocked
      ? { transform: `translateY(-${this.state.offsetTop + this.state.top_offset}px)` }
      : {};
    const {start_time} = this.state;
    return (
      <div className="home-root">
        <div className={main_classname}>
          <div className="teacher-scroll-container" style={scroll_style}>
            <Header start_time={start_time} />
            <TopSection start_time={start_time} go_to_signup={this.go_to_signup} />
            <ToogleBtn start_time={start_time} />
            <Requirment
              scrollToTop={this.scrollToTop}
              start_time={start_time}
              go_to_signup={this.go_to_signup}
            />
            <TeacherSay start_time={start_time} />
            <GetStarted
              scrollToTop={this.scrollToTop}
              start_time={start_time}
              go_to_signup={this.go_to_signup}
            />
            <FAQ component="home" />
            <OurMission
              scrollToTop={this.scrollToTop}
              start_time={start_time}
              go_to_signup={this.go_to_signup}
            />
            <TeachingWith
              start_scroll_block={this.start_scroll_block}
              end_scroll_block={this.end_scroll_block}
              scroll_blocked={this.state.scroll_blocked}
              ref={this.divRef}
              start_time={start_time}
            />
            <VibrantCommunity start_time={start_time} />
            <Footer start_time={start_time} />
          </div>
        </div>
      </div>
    );
  }
}

const TeacherWithRouter = withRouter(Teacher);

export default TeacherWithRouter;
