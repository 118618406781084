import React, { useEffect, useState, forwardRef } from 'react';

const RecapVideoPopup = (props) => {
  const [getDeleteSlots, setDeleteSlots] = useState(props.recapVideoPopupData);

  const modal = React.useRef(null);

	const on_close_modal = (e) => {
		if (modal && modal.current && !modal.current.contains(e.target)) {
			props.recapVideoPopupClose();
		}
	};


  return (
    <>
      <div className='relative inset-0 z-index-max'>
        <div onClick={(e) => on_close_modal(e)} className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
          <div ref={modal} className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
            <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
              <div onClick={props.recapVideoPopupClose}>
                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <rect width='34' height='34' rx='17' fill='none' />
                  <path
                    d='M22 12L12 22'
                    stroke='#BFBFBF'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12 12L22 22'
                    stroke='#BFBFBF'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
            <div className='mb-0'>
              <div className='flex justify-center flex-col items-center'>
                <div className='w-full'>
                  <div className="flex flex-col justify-center items-center">
                    <div className='w-80 text-center'>
                      <div className="text-2xl font-medium">{getDeleteSlots?.student_name}  {getDeleteSlots?.group_session_name !== "" ? `(${getDeleteSlots?.group_session_name})` : '' } </div>
                      {getDeleteSlots?.session_type === "GROUP_SESSION" ?
                        <div className="text-13.5 text-mytgrey-videoPopup font-medium mt-2 leading-6">Students joined: {getDeleteSlots?.joined_participants} (Members: {getDeleteSlots?.member_participants}, Non members: {getDeleteSlots?.non_member_participants}) Cancelled: {getDeleteSlots?.cancelled_participants}, No shows: {getDeleteSlots?.no_show_participants}</div>
                        : null}
                    </div>
                    <div className='h-full w-full mt-4'>
                      <div className='w-full'>
                        {getDeleteSlots?.replay_url === '' ?
                          <img src='https://images.myyogateacher.com/web_images/no_video.svg' alt="no-video" />
                          :
                          <iframe
                            src={getDeleteSlots?.replay_url}
                            frameBorder='0'
                            allow='fullscreen'
                            allowFullScreen
                            className='w-full h-56'
                            title='good'
                          ></iframe>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecapVideoPopup;
