import React from 'react';

import './SideDrawer.css';
const open_zendesk = () => {
	window.zE(() => {
		window.zE.showIPMWidget();
	});
};
const sideDrawer = props => {
	return (
		<nav className="side-drawer">
			<img
				className="side-drawer-close"
				src="https://images.myyogateacher.com/Teacher-Page/close-btn.png"
				alt="close"
				onClick={props.drawerToggleClickHandler}
			/>
			<ul>
				<li>
					<a href="https://teacher.myyogateacher.com">Teacher Portal</a>
				</li>

				<li>
					<a href="https://teacher.myyogateacher.com/contactus">Contact</a>
				</li>

				<li className="social-li">
					<div className="partli">
						<a onClick={open_zendesk}>
							<img className="icon" src="https://images.myyogateacher.com/home-page/h_chat1.png" alt="chat_icon" />
							<span>Live Chat</span>
						</a>

						<a href="tel:+91 94141 14756">
							<img className="iconphone" src="https://images.myyogateacher.com/home-page/h_call1.png" alt="call_icon" />
							<span>+91 94141 14756</span>
						</a>
					</div>
					<div className="partli socialimgsbtn">
						<a href="https://www.facebook.com/myyogateacher.inc" target="_blank" rel="noopener noreferrer">
							<img src="https://images.myyogateacher.com/home-page/L-FB1.png" alt="fb" />
						</a>
						<a href="https://twitter.com/myyogateacher" target="_blank" rel="noopener noreferrer">
							<img className="twitter-icon" src="https://images.myyogateacher.com/home-page/ic_twitter3.png" alt="twitter_icon" />
						</a>
						<a href="https://www.instagram.com/myyogateacher" target="_blank" rel="noopener noreferrer">
							<img src="https://images.myyogateacher.com/home-page/L-INSTA1.png" alt="insta" />
						</a>
					</div>
				</li>
			</ul>
		</nav>
	);
};

export default sideDrawer;
