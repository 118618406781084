import * as t from "../action_types";

export const set_home_component_index = (payload) => {
  return {
    type: t.set_home_component_index,
    payload: payload,
  };
};

export const load_application = () => {
  return {
    type: t.load_application,
    payload: null,
  };
};

export const set_home_variable = (key, payload) => {
  return {
    type: t.set_home_variable,
    payload: {
      key,
      payload,
    },
  };
};

export const getStudents = (payload) => {
  return {
    type: t.getStudents,
    payload: payload,
  };
};

export const create_roadmap = (payload) => ({
  type: t.create_roadmap,
  payload: payload,
});

export const update_session_recap = (payload) => ({
  type: t.update_session_recap,
  payload: payload,
});

export const update_roadmap_question = (payload) => ({
  type: t.update_roadmap_question,
  payload: payload,
});

export const update_roadmap = (payload) => ({
  type: t.update_roadmap,
  payload: payload,
});

export const get_yoga_journey = (payload) => ({
  type: t.get_yoga_journey,
  payload: payload,
});

export const set_password = (payload) => ({
  type: t.set_password,
  payload: payload,
});

export const log_in_to_app = (payload) => ({
  type: t.log_in_to_app,
  payload: payload,
});

export const sign_up = (payload) => ({
  type: t.sign_up,
  payload: payload,
});

export const log_out = () => ({
  type: t.log_out,
  payload: null,
});

export const forgot_password = (payload) => ({
  type: t.forgot_password,
  payload: payload,
});

export const load_my_sessions = (payload) => {
  return {
    type: t.load_my_sessions,
    payload,
  };
};

export const get_config = () => {
  return {
    type: t.get_config,
  };
};

export const update_status = (payload) => ({
  type: t.update_status,
  payload: payload,
});

//workshops
export const get_workshop_details = (payload) => ({
  type: t.get_workshop_details,
  payload: payload,
});

export const get_workshop_details_wo_token = (payload) => ({
  type: t.get_workshop_details_wo_token,
  payload: payload,
});

export const get_all_workshops = (payload) => ({
  type: t.get_all_workshops,
  payload: payload,
});

export const get_all_workshops_wo_token = (payload) => ({
  type: t.get_all_workshops_wo_token,
  payload: payload,
});

export const get_notifications = (payload) => ({
  type: t.get_notifications,
  payload: payload,
});

export const get_new_notifications_summary = (payload) => ({
  type: t.get_new_notifications_summary,
  payload: payload,
});

export const update_notification_read_status = (payload) => ({
  type: t.update_notification_read_status,
  payload: payload,
});

export const get_announcements = (payload) => ({
  type: t.get_announcements,
  payload: payload,
});

export const read_announcement = (payload) => ({
  type: t.read_announcement,
  payload: payload,
});

export const community_proposal_accept = (payload) => ({
  type: t.community_proposal_accept,
  payload: payload,
});

export const community_proposal_ignore = (payload) => ({
  type: t.community_proposal_ignore,
  payload: payload,
});
export const reject_session_replacement = (payload) => ({
  type: t.reject_session_replacement,
  payload: payload,
});
export const accept_session_replacement = (payload) => ({
  type: t.accept_session_replacement,
  payload: payload,
});
export const get_community_profile = (payload) => {
  return {
    type: t.get_community_profile,
    payload,
  };
};

export const get_proposal_info = (payload) => {
  return {
    type: t.get_proposal_info,
    payload,
  };
};

export const propose_group_class = (payload) => {
  return {
    type: t.propose_group_class,
    payload,
  };
};

export const is_community_class_proposals_create_allowed = (payload) => {
  return {
    type: t.is_community_class_proposals_create_allowed,
    payload,
  };
};

export const impersonate_myt_user_teacher_consultancy = (payload) => ({
  type: t.impersonate_myt_user_teacher_consultancy,
  payload: payload,
});
