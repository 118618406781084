import React, { Component } from 'react';
// import { get_replacement_sessions_teacher_slots } from "../../../redux/api_functions/availability_api";
// import * as actions from "../../../redux/action_creators";
import * as actions from '../../../redux/action_creators';
import { connect } from 'react-redux';
import { withRouter } from '../../../utils/router';

class LiveSession extends Component {
	constructor(props) {
		super(props);
		this.state = {
			setSessionData: props.sessionPopupData,
		};
	}

	componentDidMount() {
		this.props.get_zoom_token({
			session_uuid: this.state.setSessionData.session_uuid,
		});
	}

	joinMeeting = (data) => {
		if (this.state.setSessionData.call_provider === 'livekit') {
			window.open(
				`https://${window.location.host}/sessions/livekit?session_uuid=${this.state.setSessionData.session_uuid}`,
				'_blank'
			);
		} else {
			let zoomUrlJoin = this.props.join_url;
			window.open(zoomUrlJoin, '_blank', 'noopener,noreferrer');
		}
	};

	renderMeetingButton = () => {
		let currentTime = new Date();
		let d = new Date(this.state.setSessionData?.start_time);
		let joinBtnEnable = d.setHours(d.getHours() - 2);
		let activeBtn = currentTime > new Date(joinBtnEnable) ? true : false;

		return (
			<button
				className={`btn btn-green mt-5 ${activeBtn ? '' : 'opacity-40 pointer-events-none'} `}
				onClick={() => this.joinMeeting()}
			>
				Join now
			</button>
		);
	};

	render() {
		return (
			<>
				<div className='relative inset-0 z-index-max'>
					<div className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center'>
						<div className='z-50 bg-white rounded-lg p-10 2xl:w-3/12 xl:w-4/12 lg:w-5/12 md:w-6/12 zm:w-11/12 relative'>
							<div className='flex justify-end cursor-pointer absolute top-1 right-1'>
								<div onClick={this.props.closeSessionPopup}>
									<svg
										width='40'
										height='40'
										viewBox='0 0 34 34'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<rect width='34' height='34' rx='17' fill='none' />
										<path
											d='M22 12L12 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
										<path
											d='M12 12L22 22'
											stroke='#BFBFBF'
											strokeWidth='1.66667'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
							</div>
							<div className='mb-0'>
								<div className='flex justify-start flex-col items-start'>
									<div className='w-full'>
										{this.state.setSessionData.is_live === 1 ? (
											<div className='flex justify-center items-center'>
												<div className='text-17 font-semibold'>Your session is live</div>
											</div>
										) : null}
										<div className='mt-5'>
											<div className='flex justify-start items-start mt-2'>
												<img
													className='mr-2 w-12 h-12 rounded-full cursor-pointer'
													src={this.state.setSessionData?.avatar_medium}
													alt='icon'
												/>
												<div>
													<div className='flex justify-start items-center text-base'>
														<div>{this.state.setSessionData?.student_name}</div>
														{this.state.setSessionData.is_live === 1 ? (
															<div className='text-10px bg-mytRedText text-white pr-2 pl-2 pt-1 pb-1 ml-1 rounded'>
																LIVE
															</div>
														) : null}
													</div>
													<div className='flex jsutify-start items-center '>
														<div className='uppercase text-mytgrey-lite font-normal text-xs'>
															{this.state.setSessionData?.yoga_level}
														</div>
														<div className='flex justify-start items-center ml-2'>
															<div className='w-6px h-6px rounded-full bg-mytgrey-lite mr-1'></div>
															<div className='text-mytgrey-lite font-normal text-xs'>
																{this.state.setSessionData?.duration} mins
															</div>
														</div>
													</div>
													<div className='text-mytgrey-lite font-semibold text-xs mt-1'>
														local time - {this.state.setSessionData?.student_local_time}
													</div>
												</div>
											</div>
										</div>
										<div className='flex justify-center items-center'>
											<div className='flex flex-col w-3/4'>
												{this.renderMeetingButton()}
												{/* <button className="btn btn-primary mt-3">Chat with Merissa</button> */}
												<button
													className='btn btn-danger mt-3'
													onClick={() =>
														this.props.cancelSessionPopup(this.state.setSessionData)
													}
												>
													Cancel the session
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	zoom_signature: state.zoom.zoom_signature,
	zoom_signature_status: state.zoom.zoom_signature_status,
	config_values: state.home.config_values,
	join_url: state.zoom.join_url,
	session_update_slots: state.dashboard.session_update_slots,
});

const mapDispatchToProps = (dispatch) => {
	return {
		get_zoom_token: (payload) => {
			dispatch(actions.get_zoom_signature(payload));
		},
	};
};

const LiveSessionWithRedux = connect(mapStateToProps, mapDispatchToProps)(LiveSession);
const LiveSessionWithRouter = withRouter(LiveSessionWithRedux);

export default LiveSessionWithRouter;
