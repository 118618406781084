import * as t from "../action_types";

export const load_dashboard_sessions = () => {
  return {
    type: t.load_dashboard_sessions,
    payload: null,
  };
};

export const load_short_dashboard_sessions = () => {
  return {
    type: t.load_short_dashboard_sessions,
    payload: null,
  };
};

export const set_dashboard_variable = (payload) => {
  return {
    type: t.set_dashboard_variable,
    payload: payload,
  };
};

export const withdrawReplacementRequest = (payload) => {
  return {
    type: t.WITHDRAW_REPLACEMENT_REQEUEST,
    payload: payload,
  };
};

export const cancel_session = (payload) => {
  return {
    type: t.cancel_session,
    payload,
  };
};

export const load_student_details = (payload) => {
  return {
    type: t.load_student_details,
    payload,
  };
};

export const schedule_session_with_student = (payload) => {
  return {
    type: t.schedule_session_with_student,
    payload,
  };
};

export const load_student_details_slug = (payload) => {
  return {
    type: t.load_student_details_slug,
    payload,
  };
};

export const get_session_update_slots = (payload) => {
  return {
    type: t.get_session_update_slots,
    payload,
  };
};

export const change_session_time = (payload) => {
  return {
    type: t.change_session_time,
    payload,
  };
};

export const request_replacement = (payload) => {
  return {
    type: t.request_replacement,
    payload,
  };
};

export const get_session_participants = (payload) => {
  return {
    type: t.get_session_participants,
    payload,
  };
};

export const get_twilio_voice_token = () => {
  return {
    type: t.get_twilio_voice_token,
  };
};
export const get_active_call_status = (payload) => {
  return {
    type: t.get_active_call_status,
    payload,
  };
};
