import React, { Component } from 'react';
export const RefreshIcon = (props) => (
	<svg
		className={props.className || 'refresh'}
		width='20'
		height='16'
		viewBox='0 0 20 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M17.4047 4.96656L18.0882 3.56409C18.3475 3.07645 18.953 2.89133 19.4406 3.15061C19.9283 3.40989 20.1134 4.01539 19.8541 4.50303L17.9323 8.44621C17.9227 8.46785 17.9123 8.48931 17.901 8.51055C17.7713 8.75437 17.5551 8.92256 17.3104 8.99738C17.0657 9.07221 16.7924 9.05367 16.5486 8.92403C16.5162 8.90682 16.4852 8.88808 16.4555 8.86796L12.7699 6.64736C12.2822 6.38808 12.0971 5.78258 12.3564 5.29495C12.6157 4.80731 13.2212 4.62219 13.7088 4.88147L15.683 6.07091C14.8797 3.70356 12.6387 2 9.99985 2C8.06606 2 6.28756 2.92 5.16283 4.4491C4.83559 4.894 4.20965 4.98937 3.76475 4.66213C3.31986 4.33489 3.22448 3.70895 3.55172 3.26405C5.04942 1.2279 7.42312 0 9.99985 0C13.3444 0 16.2097 2.05245 17.4047 4.96656ZM4.31664 9.92912C5.11997 12.2965 7.36093 14 9.99978 14C11.9336 14 13.7121 13.08 14.8368 11.5509C15.164 11.106 15.79 11.0106 16.2349 11.3379C16.6798 11.6651 16.7751 12.291 16.4479 12.7359C14.9502 14.7721 12.5765 16 9.99978 16C6.65521 16 3.79001 13.9476 2.59495 11.0335L1.91147 12.4359C1.65218 12.9236 1.04669 13.1087 0.559047 12.8494C0.0714087 12.5901 -0.113711 11.9846 0.145571 11.497L2.06736 7.55379C2.07697 7.53215 2.08742 7.51069 2.09871 7.48945C2.22836 7.24563 2.44455 7.07744 2.68929 7.00262C2.93403 6.92779 3.20731 6.94633 3.45113 7.07597C3.4835 7.09318 3.51453 7.11192 3.54419 7.13204L7.2298 9.35264C7.71744 9.61192 7.90256 10.2174 7.64328 10.7051C7.384 11.1927 6.7785 11.3778 6.29086 11.1185L4.31669 9.92909L4.31664 9.92912Z'
			fill='#7D4AC3'
		/>
	</svg>
);

export const InfoIcon = (props) => (
	<svg
		className={props?.className || 'info'}
		width='22'
		height='22'
		viewBox='0 0 22 22'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M11.0054 0C4.92782 0 0 4.92353 0 11C0 17.0758 4.92782 22 11.0054 22C17.0801 22 22 17.0758 22 11C22 4.92353 17.0801 0 11.0054 0ZM10.9993 3.45752C11.8905 3.45752 12.6754 4.29044 12.6754 5.16267C12.6754 6.03995 11.8905 6.80915 10.9993 6.80915C10.1087 6.80915 9.32386 6.03995 9.32386 5.16267C9.32386 4.29044 10.1087 3.45752 10.9993 3.45752ZM13.3468 16.8667C13.3468 17.2699 13.0167 17.6 12.6135 17.6C12.3628 17.6 12.1391 17.6 11.9313 17.6C10.6051 17.6 10.1159 17.6 10.0697 17.6C10.0674 17.6 10.0648 17.6 10.0648 17.6C9.85783 17.6 9.63556 17.6 9.38652 17.6C8.98333 17.6 8.65319 17.2699 8.65319 16.8667V16.1333C8.65319 15.7301 8.98333 15.4 9.38652 15.4V11C8.98333 11 8.65319 10.6699 8.65319 10.2667V9.53333C8.65319 9.13015 8.98333 8.8 9.38652 8.8C12.1404 8.8 10.2407 8.8 11.8873 8.8C12.2905 8.8 12.6207 9.13015 12.6207 9.53333L12.6134 15.4C13.0166 15.4 13.3468 15.7301 13.3468 16.1333V16.8667H13.3468Z'
			fill='#6A663E'
		/>
	</svg>
);
