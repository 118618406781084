export function VideoRecordingStart() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_640_678)">
        <path
          d="M7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 10.7143C5.73214 10.7143 4.28571 9.26786 4.28571 7.5C4.28571 5.73214 5.73214 4.28571 7.5 4.28571C9.26786 4.28571 10.7143 5.73214 10.7143 7.5C10.7143 9.26786 9.26786 10.7143 7.5 10.7143Z"
          fill="white"
        />
        <path
          d="M7.5001 4.41211C5.80157 4.41211 4.41187 5.80182 4.41187 7.50034C4.41187 9.19887 5.80157 10.5886 7.5001 10.5886C9.19863 10.5886 10.5883 9.19887 10.5883 7.50034C10.5883 5.80182 9.19863 4.41211 7.5001 4.41211Z"
          fill="#FFCACA"
          stroke="#EF4040"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_640_678">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function VideoRecordingStop() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_421_2543)">
        <path
          d="M7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 10.7143C5.73214 10.7143 4.28571 9.26786 4.28571 7.5C4.28571 5.73214 5.73214 4.28571 7.5 4.28571C9.26786 4.28571 10.7143 5.73214 10.7143 7.5C10.7143 9.26786 9.26786 10.7143 7.5 10.7143Z"
          fill="white"
        />
        <path
          d="M7.50034 4.41211C5.80182 4.41211 4.41211 5.80182 4.41211 7.50034C4.41211 9.19887 5.80182 10.5886 7.50034 10.5886C9.19887 10.5886 10.5886 9.19887 10.5886 7.50034C10.5886 5.80182 9.19887 4.41211 7.50034 4.41211Z"
          fill="white"
          stroke="#373737"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_421_2543">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function UnMuteCall() {
  return (
    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_640_686)">
        <path
          d="M5.74902 13.7712V13.6773L5.65635 13.6627C2.78072 13.2087 0.609799 10.6854 0.609799 7.70687V6.67903C0.609799 6.17436 1.02317 5.76098 1.52784 5.76098C2.03252 5.76098 2.44589 6.17436 2.44589 6.67903V7.70687C2.44589 10.0288 4.34517 11.9281 6.66707 11.9281C8.98897 11.9281 10.8882 10.0288 10.8882 7.70687V6.67903C10.8882 6.17436 11.3016 5.76098 11.8063 5.76098C12.311 5.76098 12.7243 6.17436 12.7243 6.67903V7.70687C12.7243 10.6839 10.5546 13.158 7.67594 13.663L7.58511 13.6789V13.7712V14.9018C7.58511 15.4065 7.17174 15.8198 6.66707 15.8198C6.16239 15.8198 5.74902 15.4065 5.74902 14.9018V13.7712ZM9.6408 7.70687C9.6408 9.34218 8.30237 10.6806 6.66707 10.6806C5.03176 10.6806 3.69333 9.34218 3.69333 7.70687V3.5955C3.69333 1.96019 5.03176 0.621762 6.66707 0.621762C8.30237 0.621762 9.6408 1.96019 9.6408 3.5955V7.70687Z"
          fill="white"
          stroke="#373737"
          strokeWidth="0.219599"
        />
      </g>
      <defs>
        <clipPath id="clip0_640_686">
          <rect width="13" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Views() {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 4.39286C1.5 4.02345 1.64675 3.66917 1.90796 3.40796C2.16917 3.14675 2.52345 3 2.89286 3H5.67857C6.04798 3 6.40226 3.14675 6.66347 3.40796C6.92468 3.66917 7.07143 4.02345 7.07143 4.39286V7.17857C7.07143 7.54798 6.92468 7.90226 6.66347 8.16347C6.40226 8.42468 6.04798 8.57143 5.67857 8.57143H2.89286C2.52345 8.57143 2.16917 8.42468 1.90796 8.16347C1.64675 7.90226 1.5 7.54798 1.5 7.17857V4.39286ZM8.92857 4.39286C8.92857 4.02345 9.07532 3.66917 9.33653 3.40796C9.59774 3.14675 9.95202 3 10.3214 3H13.1071C13.4766 3 13.8308 3.14675 14.092 3.40796C14.3533 3.66917 14.5 4.02345 14.5 4.39286V7.17857C14.5 7.54798 14.3533 7.90226 14.092 8.16347C13.8308 8.42468 13.4766 8.57143 13.1071 8.57143H10.3214C9.95202 8.57143 9.59774 8.42468 9.33653 8.16347C9.07532 7.90226 8.92857 7.54798 8.92857 7.17857V4.39286ZM1.5 11.8214C1.5 11.452 1.64675 11.0977 1.90796 10.8365C2.16917 10.5753 2.52345 10.4286 2.89286 10.4286H5.67857C6.04798 10.4286 6.40226 10.5753 6.66347 10.8365C6.92468 11.0977 7.07143 11.452 7.07143 11.8214V14.6071C7.07143 14.9766 6.92468 15.3308 6.66347 15.592C6.40226 15.8533 6.04798 16 5.67857 16H2.89286C2.52345 16 2.16917 15.8533 1.90796 15.592C1.64675 15.3308 1.5 14.9766 1.5 14.6071V11.8214ZM8.92857 11.8214C8.92857 11.452 9.07532 11.0977 9.33653 10.8365C9.59774 10.5753 9.95202 10.4286 10.3214 10.4286H13.1071C13.4766 10.4286 13.8308 10.5753 14.092 10.8365C14.3533 11.0977 14.5 11.452 14.5 11.8214V14.6071C14.5 14.9766 14.3533 15.3308 14.092 15.592C13.8308 15.8533 13.4766 16 13.1071 16H10.3214C9.95202 16 9.59774 15.8533 9.33653 15.592C9.07532 15.3308 8.92857 14.9766 8.92857 14.6071V11.8214Z"
        fill="white"
      />
    </svg>
  );
}

export function Participants() {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.08824 9.23527C8.05106 9.23527 9.63971 7.64662 9.63971 5.6838C9.63971 3.72097 8.05106 2.13232 6.08824 2.13232C4.12541 2.13232 2.53676 3.72097 2.53676 5.6838C2.53676 7.64662 4.12541 9.23527 6.08824 9.23527ZM8.52353 10.25H8.26034C7.60078 10.5671 6.86829 10.7573 6.08824 10.7573C5.30818 10.7573 4.57886 10.5671 3.91613 10.25H3.65294C1.63621 10.25 0 11.8862 0 13.9029V14.8162C0 15.6565 0.681756 16.3382 1.52206 16.3382H10.6544C11.4947 16.3382 12.1765 15.6565 12.1765 14.8162V13.9029C12.1765 11.8862 10.5403 10.25 8.52353 10.25ZM15.2206 9.23527C16.9012 9.23527 18.2647 7.87176 18.2647 6.19115C18.2647 4.51054 16.9012 3.14703 15.2206 3.14703C13.54 3.14703 12.1765 4.51054 12.1765 6.19115C12.1765 7.87176 13.54 9.23527 15.2206 9.23527ZM16.7426 10.25H16.6222C16.1814 10.4022 15.7153 10.5037 15.2206 10.5037C14.7259 10.5037 14.2598 10.4022 13.819 10.25H13.6985C13.0517 10.25 12.4555 10.4371 11.9323 10.7383C12.706 11.5723 13.1912 12.6789 13.1912 13.9029V15.1206C13.1912 15.1903 13.1753 15.2569 13.1722 15.3235H18.7721C19.6124 15.3235 20.2941 14.6417 20.2941 13.8014C20.2941 11.8386 18.7055 10.25 16.7426 10.25Z"
        fill="white"
      />
    </svg>
  );
}

export function NetworkSignal({ status }) {
  let opacity;
  let unstableOpacity;

  if (status === 'stable') {
    opacity = 1;
  } else if (status === 'unstable') {
    unstableOpacity = 0.3;
  } else {
    opacity = 0.1;
  }

  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="10" width="4" height="6" rx="2" fill="#D9D9D9" opacity={opacity} />
      <rect x="6" y="7" width="4" height="9" rx="2" fill="#D9D9D9" opacity={opacity} />
      <rect
        x="12"
        y="3"
        width="4"
        height="13"
        rx="2"
        fill="#D9D9D9"
        opacity={unstableOpacity || opacity}
      />
    </svg>
  );
}

export function MoreThreeDots() {
  return (
    <svg width="25" height="20" viewBox="0 0 25 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_441_6231)">
        <path
          d="M21.8805 6.28533C20.1697 6.28533 18.7853 4.90093 18.7853 3.19009C18.7853 1.47924 20.1697 0.0948486 21.8805 0.0948486C23.5884 0.0948486 24.9758 1.47924 24.9758 3.19009C24.9758 4.90093 23.5884 6.28533 21.8805 6.28533ZM12.5948 6.28533C10.884 6.28533 9.49958 4.90093 9.49958 3.19009C9.49958 1.47924 10.884 0.0948486 12.5948 0.0948486C14.3057 0.0948486 15.6901 1.47924 15.6901 3.19009C15.6901 4.90093 14.3057 6.28533 12.5948 6.28533ZM3.30911 6.28533C1.59826 6.28533 0.213867 4.90093 0.213867 3.19009C0.213867 1.47924 1.59826 0.0948486 3.30911 0.0948486C5.01995 0.0948486 6.40434 1.47924 6.40434 3.19009C6.40434 4.90093 5.01995 6.28533 3.30911 6.28533Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_441_6231">
          <rect width="25" height="7" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function NetworkSignalStable() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_509_8824)">
        <rect width="22" height="22" rx="11" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0C17.0741 0 22 4.92594 22 11C22 17.0741 17.0741 22 11 22C4.92594 22 0 17.0741 0 11C0 4.92594 4.92594 0 11 0ZM8.71571 14.5729L6.02264 11.8776C5.56384 11.4185 5.56374 10.6699 6.02264 10.2109C6.48164 9.752 7.23359 9.75487 7.68925 10.2109L9.58785 12.111L14.3109 7.38791C14.7699 6.92891 15.5186 6.92891 15.9775 7.38791C16.4365 7.84681 16.4359 8.59617 15.9775 9.05452L10.4198 14.6122C9.96147 15.0706 9.2121 15.0712 8.7532 14.6122C8.7403 14.5993 8.72786 14.5862 8.71571 14.5729Z"
          fill="#26BA26"
        />
      </g>
      <defs>
        <clipPath id="clip0_509_8824">
          <rect width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function NetworkSignalUnStable() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_509_8923)">
        <rect width="22" height="22" rx="11" fill="white" />
        <rect width="22" height="22" rx="11" fill="#FF974D" />
        <rect x="5" y="9.5" width="12" height="3" rx="1.5" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_509_8923">
          <rect width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function NetworkSignalOffline() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_509_9056)">
        <rect width="22" height="22" rx="11" fill="white" />
        <rect width="22" height="22" rx="11" fill="#EF4040" />
        <path
          d="M14 8L8 14"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 8L14 14"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_509_9056">
          <rect width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export function ClosePannel() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 5.5L5.5 16.5"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 5.5L16.5 16.5"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function WarningIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#FFECEC" />
      <g clipPath="url(#clip0_418_377)">
        <path
          d="M40.8901 34.4665L30.3181 15.8407C29.8031 14.9327 28.8406 14.373 27.7975 14.373C26.7534 14.373 25.7909 14.9327 25.2759 15.8407L14.7049 34.4665C14.1954 35.363 14.2019 36.4644 14.7207 37.3565C15.2396 38.2475 16.1944 38.7969 17.226 38.7969H38.3685C39.4007 38.7969 40.3544 38.2475 40.8738 37.3565C41.3932 36.4644 41.3986 35.3624 40.8901 34.4665ZM29.2417 35.3744C28.8875 35.6894 28.4668 35.8491 27.9926 35.8491C27.5125 35.8491 27.088 35.6916 26.7294 35.3809C26.3631 35.0627 26.1768 34.6169 26.1768 34.0555C26.1768 33.5629 26.3522 33.1378 26.6983 32.7923C27.0438 32.4473 27.4722 32.2718 27.9714 32.2718C28.4695 32.2718 28.9011 32.4462 29.2543 32.7912C29.608 33.1373 29.7878 33.5629 29.7878 34.0555C29.7873 34.6087 29.6036 35.0518 29.2417 35.3744ZM29.711 23.381L29.2505 28.6553C29.1987 29.312 29.0875 29.8069 28.9109 30.1666C28.7164 30.5644 28.3768 30.7753 27.9283 30.7753C27.4711 30.7753 27.1343 30.5688 26.9539 30.178C26.7937 29.8309 26.6836 29.3268 26.6166 28.6346L26.2732 23.502C26.2084 22.4878 26.1762 21.7799 26.1762 21.3379C26.1762 20.6992 26.3501 20.1896 26.6929 19.8234C27.0389 19.4539 27.4983 19.2659 28.0564 19.2659C28.7453 19.2659 29.2161 19.516 29.457 20.0087C29.6783 20.4632 29.7862 21.091 29.7862 21.9276C29.7873 22.4017 29.7616 22.8905 29.711 23.381Z"
          fill="#FF2121"
        />
      </g>
      <defs>
        <clipPath id="clip0_418_377">
          <rect width="28" height="25" fill="white" transform="translate(14 14)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function OrangeTick(props) {
  return (
    <svg style={props.style} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4999 3.74994L5.62489 10.6249L2.49989 7.49994"
        stroke="#EE731B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ThreeDots() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="8" viewBox="0 0 25 8" fill="none">
      <g clipPath="url(#clip0_20_1138)">
        <path
          d="M21.8805 6.7852C20.1697 6.7852 18.7853 5.40081 18.7853 3.68996C18.7853 1.97912 20.1697 0.594727 21.8805 0.594727C23.5884 0.594727 24.9758 1.97912 24.9758 3.68996C24.9758 5.40081 23.5884 6.7852 21.8805 6.7852ZM12.5948 6.7852C10.884 6.7852 9.49958 5.40081 9.49958 3.68996C9.49958 1.97912 10.884 0.594727 12.5948 0.594727C14.3057 0.594727 15.6901 1.97912 15.6901 3.68996C15.6901 5.40081 14.3057 6.7852 12.5948 6.7852ZM3.30911 6.7852C1.59826 6.7852 0.213867 5.40081 0.213867 3.68996C0.213867 1.97912 1.59826 0.594727 3.30911 0.594727C5.01995 0.594727 6.40434 1.97912 6.40434 3.68996C6.40434 5.40081 5.01995 6.7852 3.30911 6.7852Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_1138">
          <rect width="25" height="7" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MuteAllIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1708 22.1138V21.9856L14.0442 21.9656C10.1158 21.3453 7.15 17.8982 7.15 13.8292V12.425C7.15 11.7356 7.71472 11.1708 8.40417 11.1708C9.09362 11.1708 9.65833 11.7356 9.65833 12.425V13.8292C9.65833 17.0012 12.253 19.5958 15.425 19.5958C18.597 19.5958 21.1917 17.0012 21.1917 13.8292V12.425C21.1917 11.7356 21.7564 11.1708 22.4458 11.1708C23.1353 11.1708 23.7 11.7356 23.7 12.425V13.8292C23.7 17.8961 20.7359 21.2761 16.8032 21.966L16.6792 21.9878V22.1138V23.6583C16.6792 24.3478 16.1144 24.9125 15.425 24.9125C14.7356 24.9125 14.1708 24.3478 14.1708 23.6583V22.1138ZM19.4875 13.8292C19.4875 16.0632 17.659 17.8917 15.425 17.8917C13.191 17.8917 11.3625 16.0632 11.3625 13.8292V8.2125C11.3625 5.97847 13.191 4.15 15.425 4.15C17.659 4.15 19.4875 5.97847 19.4875 8.2125V13.8292Z"
        fill="white"
        stroke="#EF4040"
        strokeWidth="0.3"
      />
      <rect
        x="2.27208"
        y="23.8994"
        width="32"
        height="4"
        rx="2"
        transform="rotate(-45 2.27208 23.8994)"
        fill="white"
        stroke="#4c4c4c"
        strokeWidth="2"
      />
    </svg>
  );
}

export function ScreenShareIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <g clipPath="url(#clip0_421_2565)">
        <path
          d="M24.375 4.6875H5.625C4.0717 4.6875 2.8125 5.9467 2.8125 7.5V20.625C2.8125 22.1783 4.0717 23.4375 5.625 23.4375H24.375C25.9283 23.4375 27.1875 22.1783 27.1875 20.625V7.5C27.1875 5.9467 25.9283 4.6875 24.375 4.6875Z"
          fill="white"
        />
        <path
          d="M18.75 25.3125H11.25C11.0014 25.3125 10.7629 25.4113 10.5871 25.5871C10.4113 25.7629 10.3125 26.0014 10.3125 26.25C10.3125 26.4986 10.4113 26.7371 10.5871 26.9129C10.7629 27.0887 11.0014 27.1875 11.25 27.1875H18.75C18.9986 27.1875 19.2371 27.0887 19.4129 26.9129C19.5887 26.7371 19.6875 26.4986 19.6875 26.25C19.6875 26.0014 19.5887 25.7629 19.4129 25.5871C19.2371 25.4113 18.9986 25.3125 18.75 25.3125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_421_2565">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MoreMenuIcon() {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="#565656" />
      <circle cx="25" cy="17" r="3" fill="white" />
      <circle cx="25" cy="25" r="3" fill="white" />
      <circle cx="25" cy="33" r="3" fill="white" />
    </svg>
  );
}

export function MuteIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="#EF4040" />
      <g clipPath="url(#clip0_14_464)">
        <path
          d="M7.34639 11.82V11.7602L7.28731 11.7509C5.45402 11.4614 4.07 9.85275 4.07 7.95389V7.29861C4.07 6.97687 4.33353 6.71333 4.65528 6.71333C4.97702 6.71333 5.24056 6.97687 5.24056 7.29861V7.95389C5.24056 9.43416 6.4514 10.645 7.93167 10.645C9.41194 10.645 10.6228 9.43416 10.6228 7.95389V7.29861C10.6228 6.97687 10.8863 6.71333 11.2081 6.71333C11.5298 6.71333 11.7933 6.97687 11.7933 7.29861V7.95389C11.7933 9.8518 10.4101 11.4291 8.57485 11.7511L8.51694 11.7612V11.82V12.5408C8.51694 12.8626 8.25341 13.1261 7.93167 13.1261C7.60992 13.1261 7.34639 12.8626 7.34639 12.5408V11.82ZM9.8275 7.95389C9.8275 8.99644 8.97422 9.84972 7.93167 9.84972C6.88912 9.84972 6.03583 8.99644 6.03583 7.95389V5.33278C6.03583 4.29023 6.88912 3.43694 7.93167 3.43694C8.97422 3.43694 9.8275 4.29023 9.8275 5.33278V7.95389Z"
          fill="white"
          stroke="#EF4040"
          strokeWidth="0.14"
        />
        <rect
          x="3.18913"
          y="10.8962"
          width="11.4298"
          height="2"
          rx="1"
          transform="rotate(-45 3.18913 10.8962)"
          fill="white"
          stroke="#EF4040"
        />
      </g>
      <defs>
        <clipPath id="clip0_14_464">
          <rect width="8" height="11" fill="white" transform="translate(4 2.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function UnmuteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M14.1708 22.1138V21.9856L14.0442 21.9656C10.1158 21.3453 7.15 17.8982 7.15 13.8292V12.425C7.15 11.7356 7.71472 11.1708 8.40417 11.1708C9.09362 11.1708 9.65833 11.7356 9.65833 12.425V13.8292C9.65833 17.0012 12.253 19.5958 15.425 19.5958C18.597 19.5958 21.1917 17.0012 21.1917 13.8292V12.425C21.1917 11.7356 21.7564 11.1708 22.4458 11.1708C23.1353 11.1708 23.7 11.7356 23.7 12.425V13.8292C23.7 17.8961 20.7359 21.2761 16.8032 21.966L16.6792 21.9878V22.1138V23.6583C16.6792 24.3478 16.1144 24.9125 15.425 24.9125C14.7356 24.9125 14.1708 24.3478 14.1708 23.6583V22.1138ZM19.4875 13.8292C19.4875 16.0632 17.659 17.8917 15.425 17.8917C13.191 17.8917 11.3625 16.0632 11.3625 13.8292V8.2125C11.3625 5.97847 13.191 4.15 15.425 4.15C17.659 4.15 19.4875 5.97847 19.4875 8.2125V13.8292Z"
        fill="white"
        stroke="#373737"
        strokeWidth="0.3"
      />
    </svg>
  );
}

export function AdioMuteIcon() {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="#EF4040" />
      <path
        d="M24.1708 32.1138V31.9856L24.0442 31.9656C20.1158 31.3453 17.15 27.8982 17.15 23.8292V22.425C17.15 21.7356 17.7147 21.1708 18.4042 21.1708C19.0936 21.1708 19.6583 21.7356 19.6583 22.425V23.8292C19.6583 27.0012 22.253 29.5958 25.425 29.5958C28.597 29.5958 31.1917 27.0012 31.1917 23.8292V22.425C31.1917 21.7356 31.7564 21.1708 32.4458 21.1708C33.1353 21.1708 33.7 21.7356 33.7 22.425V23.8292C33.7 27.8961 30.7359 31.2761 26.8032 31.966L26.6792 31.9878V32.1138V33.6583C26.6792 34.3478 26.1144 34.9125 25.425 34.9125C24.7356 34.9125 24.1708 34.3478 24.1708 33.6583V32.1138ZM29.4875 23.8292C29.4875 26.0632 27.659 27.8917 25.425 27.8917C23.191 27.8917 21.3625 26.0632 21.3625 23.8292V18.2125C21.3625 15.9785 23.191 14.15 25.425 14.15C27.659 14.15 29.4875 15.9785 29.4875 18.2125V23.8292Z"
        fill="white"
        stroke="#EF4040"
        strokeWidth="0.3"
      />
      <rect
        x="12.2721"
        y="33.8994"
        width="32"
        height="4"
        rx="2"
        transform="rotate(-45 12.2721 33.8994)"
        fill="white"
        stroke="#EF4040"
        strokeWidth="2"
      />
    </svg>
  );
}

export function CameraOffIcon() {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" rx="25" fill="#EF4040" />
      <path
        d="M25.9375 17.5H15.625C14.0312 17.5 12.8125 18.7188 12.8125 20.3125V29.6875C12.8125 31.2812 14.0312 32.5 15.625 32.5H25.9375C27.5312 32.5 28.75 31.2812 28.75 29.6875V20.3125C28.75 18.7188 27.5312 17.5 25.9375 17.5Z"
        fill="white"
      />
      <path
        d="M30 21.8188C30 21.0187 30.4768 20.2957 31.2122 19.9805L34.2122 18.6948C35.5319 18.1292 37 19.0972 37 20.5331V29.4669C37 30.9028 35.5319 31.8708 34.2122 31.3052L31.2122 30.0195C30.4768 29.7043 30 28.9813 30 28.1812V21.8188Z"
        fill="white"
      />
      <rect
        x="12.2721"
        y="33.8994"
        width="32"
        height="4"
        rx="2"
        transform="rotate(-45 12.2721 33.8994)"
        fill="white"
        stroke="#EF4040"
        strokeWidth="2"
      />
    </svg>
  );
}

export function CameraOnIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M15.9375 7.5H5.625C4.03125 7.5 2.8125 8.71875 2.8125 10.3125V19.6875C2.8125 21.2812 4.03125 22.5 5.625 22.5H15.9375C17.5312 22.5 18.75 21.2812 18.75 19.6875V10.3125C18.75 8.71875 17.5312 7.5 15.9375 7.5Z"
        fill="white"
      />
      <path
        d="M20 11.8188C20 11.0187 20.4768 10.2957 21.2122 9.9805L24.2122 8.69479C25.5319 8.12919 27 9.09725 27 10.5331V19.4669C27 20.9028 25.5319 21.8708 24.2122 21.3052L21.2122 20.0195C20.4768 19.7043 20 18.9813 20 18.1812V11.8188Z"
        fill="white"
      />
    </svg>
  );
}

export function EmojiIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14_150)">
        <path
          d="M15 3C8.37262 3 3 8.37262 3 15C3 21.6274 8.37262 27 15 27C21.6274 27 27 21.6274 27 15C27 8.37262 21.6274 3 15 3Z"
          fill="white"
        />
        <path
          d="M18.75 10.4531C19.5784 10.4531 20.25 11.1248 20.25 11.9531C20.25 12.7815 19.5784 13.4531 18.75 13.4531C17.9216 13.4531 17.25 12.7815 17.25 11.9531C17.25 11.1248 17.9216 10.4531 18.75 10.4531ZM11.25 10.4531C12.0784 10.4531 12.75 11.1248 12.75 11.9531C12.75 12.7815 12.0784 13.4531 11.25 13.4531C10.4216 13.4531 9.75 12.7815 9.75 11.9531C9.75 11.1248 10.4216 10.4531 11.25 10.4531ZM21.8115 18.6714C20.4086 21.0968 17.7986 22.6029 15 22.6029C12.2014 22.6029 9.59137 21.0968 8.1885 18.6714C7.92918 18.2229 8.08237 17.6498 8.5305 17.3904C8.979 17.1308 9.55237 17.2849 9.8115 17.7324C10.8802 19.5801 12.8685 20.7279 15 20.7279C17.1315 20.7279 19.1199 19.5803 20.1885 17.7324C20.4478 17.2843 21.0212 17.1311 21.4695 17.3904C21.9178 17.6498 22.0708 18.2229 21.8115 18.6714Z"
          fill="#373737"
        />
      </g>
      <defs>
        <clipPath id="clip0_14_150">
          <rect width="24" height="24" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function EndCallIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M5.87113 18.015C6.97874 18.03 9.58314 17.5285 10.601 16.5107C10.8367 16.275 10.9901 16.0168 11.05 15.7399C11.2296 14.9241 11.3793 14.2805 11.5701 14.0897C11.6749 13.9849 11.8807 13.9662 12.341 13.9325L12.4195 13.9288C13.6993 13.839 13.9986 13.8465 14.5412 13.8577L14.6797 13.8614C14.777 13.8614 14.863 13.8652 14.9454 13.8652C15.4168 13.8727 15.7574 13.8764 17.0259 13.955L17.082 13.9587C17.6882 13.9961 18.0849 14.0186 18.2495 14.1608C18.4104 14.2992 18.5227 14.6734 18.6424 15.4592C18.7846 16.4097 19.4432 17.0982 20.5957 17.5098C21.5087 17.8316 22.5827 17.9402 23.5294 18.0337L23.6117 18.0412C24.4275 18.1235 25.0786 17.9214 25.5538 17.4462C26.0814 16.9186 26.2348 16.1964 26.3171 15.8147C26.3246 15.7848 26.3284 15.7511 26.3359 15.7212C26.5267 14.8718 26.4519 14.041 26.1338 13.4386C25.6548 12.533 24.8989 11.4928 22.0102 10.6471C19.5105 9.91367 17.6246 9.62928 15.0464 9.59561C12.6216 9.56567 11.6936 9.70038 9.64301 10.0297C7.36042 10.3964 5.53809 11.1186 4.64751 12.0092C3.82054 12.8361 3.16944 13.4872 3.36028 15.5341C3.56234 17.682 4.68493 17.9963 5.87113 18.015Z"
        fill="white"
      />
    </svg>
  );
}

export function UnstableNetwork() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#FFEEE1" />
      <path
        d="M28 31.5C27.3078 31.5 26.6311 31.7053 26.0555 32.0899C25.4799 32.4744 25.0313 33.0211 24.7664 33.6606C24.5015 34.3001 24.4322 35.0039 24.5673 35.6828C24.7023 36.3618 25.0356 36.9854 25.5251 37.4749C26.0146 37.9644 26.6383 38.2977 27.3172 38.4327C27.9961 38.5678 28.6999 38.4985 29.3394 38.2336C29.9789 37.9687 30.5256 37.5201 30.9101 36.9445C31.2947 36.3689 31.5 35.6922 31.5 35C31.4994 34.0719 31.1304 33.1821 30.4742 32.5258C29.8179 31.8696 28.9281 31.5006 28 31.5Z"
        fill="#FF7C1F"
      />
      <path
        d="M20.5933 27.5998C20.266 27.9289 20.0829 28.3744 20.0842 28.8385C20.0854 29.3026 20.271 29.7472 20.6001 30.0744C20.9291 30.4017 21.3747 30.5848 21.8388 30.5835C22.3029 30.5823 22.7475 30.3967 23.0747 30.0676C24.3936 28.7854 26.1606 28.0681 28 28.0681C29.8394 28.0681 31.6064 28.7854 32.9253 30.0676C33.0874 30.2318 33.2806 30.3621 33.4937 30.4507C33.7067 30.5393 33.9353 30.5846 34.166 30.5839C34.5116 30.5838 34.8495 30.4814 35.137 30.2895C35.4245 30.0976 35.6488 29.8249 35.7815 29.5058C35.9142 29.1866 35.9494 28.8353 35.8827 28.4962C35.816 28.1571 35.6504 27.8453 35.4067 27.6001C31.435 23.6008 24.5649 23.6008 20.5933 27.5998Z"
        fill="#FF7C1F"
      />
      <path
        d="M40.3287 22.6851C38.7224 21.0436 36.8045 19.7394 34.6874 18.849C32.5704 17.9586 30.2968 17.5 28.0001 17.5C25.7035 17.5 23.4299 17.9586 21.3129 18.849C19.1958 19.7394 17.2778 21.0436 15.6715 22.6851C15.3452 23.015 15.1633 23.4611 15.1659 23.9252C15.1684 24.3893 15.3552 24.8333 15.6852 25.1597C16.0152 25.486 16.4613 25.6679 16.9253 25.6653C17.3894 25.6628 17.8335 25.476 18.1598 25.146C23.444 19.8003 32.5563 19.8003 37.8405 25.146C38.1668 25.476 38.6109 25.6628 39.0749 25.6653C39.539 25.6679 39.9851 25.486 40.3151 25.1597C40.645 24.8333 40.8319 24.3893 40.8344 23.9252C40.837 23.4611 40.6551 23.015 40.3287 22.6851Z"
        fill="#FF7C1F"
      />
    </svg>
  );
}
export function VideocallOptions() {
  return (
    <svg width="49" height="32" viewBox="0 0 49 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="49" height="32" rx="13" fill="black" />
      <g clipPath="url(#clip0_20_1137)">
        <path
          d="M33.8805 18.7852C32.1697 18.7852 30.7853 17.4008 30.7853 15.69C30.7853 13.9791 32.1697 12.5947 33.8805 12.5947C35.5884 12.5947 36.9758 13.9791 36.9758 15.69C36.9758 17.4008 35.5884 18.7852 33.8805 18.7852ZM24.5948 18.7852C22.884 18.7852 21.4996 17.4008 21.4996 15.69C21.4996 13.9791 22.884 12.5947 24.5948 12.5947C26.3057 12.5947 27.6901 13.9791 27.6901 15.69C27.6901 17.4008 26.3057 18.7852 24.5948 18.7852ZM15.3091 18.7852C13.5983 18.7852 12.2139 17.4008 12.2139 15.69C12.2139 13.9791 13.5983 12.5947 15.3091 12.5947C17.0199 12.5947 18.4043 13.9791 18.4043 15.69C18.4043 17.4008 17.0199 18.7852 15.3091 18.7852Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_20_1137">
          <rect width="25" height="7" fill="white" transform="translate(12 12.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function VideocallOptionsMobile() {
  return (
    <svg width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="18" rx="7" fill="black" />
      <g clipPath="url(#clip0_776_4218)">
        <path
          d="M22.629 11.4896C21.407 11.4896 20.4181 10.5008 20.4181 9.27876C20.4181 8.05672 21.407 7.06787 22.629 7.06787C23.8489 7.06787 24.8399 8.05672 24.8399 9.27876C24.8399 10.5008 23.8489 11.4896 22.629 11.4896ZM15.9964 11.4896C14.7743 11.4896 13.7855 10.5008 13.7855 9.27876C13.7855 8.05672 14.7743 7.06787 15.9964 7.06787C17.2184 7.06787 18.2073 8.05672 18.2073 9.27876C18.2073 10.5008 17.2184 11.4896 15.9964 11.4896ZM9.36372 11.4896C8.14168 11.4896 7.15283 10.5008 7.15283 9.27876C7.15283 8.05672 8.14168 7.06787 9.36372 7.06787C10.5857 7.06787 11.5746 8.05672 11.5746 9.27876C11.5746 10.5008 10.5857 11.4896 9.36372 11.4896Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_776_4218">
          <rect width="18" height="5" fill="white" transform="translate(7 6.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function TestIcon() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#FFEEE1" />
      <path
        d="M19.18 22.1192H22.3014C22.7072 23.2571 23.784 24.0792 25.06 24.0792C26.336 24.0792 27.4128 23.2571 27.8186 22.1192H36.82C37.3618 22.1192 37.8 21.6813 37.8 21.1392C37.8 20.5971 37.3618 20.1592 36.82 20.1592H27.8186C27.4128 19.0216 26.336 18.1992 25.06 18.1992C23.784 18.1992 22.7069 19.0216 22.3014 20.1592H19.18C18.6382 20.1592 18.2 20.5974 18.2 21.1392C18.2 21.681 18.6382 22.1192 19.18 22.1192Z"
        fill="#FF7C1F"
      />
      <path
        d="M36.82 27.0186H33.6988C33.2931 25.8807 32.216 25.0586 30.94 25.0586C29.664 25.0586 28.5872 25.8807 28.1814 27.0186H19.18C18.6382 27.0186 18.2 27.4565 18.2 27.9986C18.2 28.5407 18.6382 28.9786 19.18 28.9786H28.1814C28.5872 30.1165 29.6652 30.9386 30.94 30.9386C32.2148 30.9386 33.2931 30.1165 33.6988 28.9786H36.82C37.3618 28.9786 37.8 28.5407 37.8 27.9986C37.8 27.4565 37.3618 27.0186 36.82 27.0186Z"
        fill="#FF7C1F"
      />
      <path
        d="M36.82 33.8799H27.8186C27.4128 32.742 26.3348 31.9199 25.06 31.9199C23.7852 31.9199 22.7069 32.742 22.3014 33.8799H19.18C18.6382 33.8799 18.2 34.3181 18.2 34.8599C18.2 35.4017 18.6382 35.8399 19.18 35.8399H22.3014C22.7072 36.9776 23.784 37.7999 25.06 37.7999C26.336 37.7999 27.4128 36.9776 27.8186 35.8399H36.82C37.3618 35.8399 37.8 35.4017 37.8 34.8599C37.8 34.3181 37.3618 33.8799 36.82 33.8799Z"
        fill="#FF7C1F"
      />
    </svg>
  );
}

export function TickOrange() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#FFEEE1" />
      <path
        d="M37.3332 21L24.4998 33.8333L18.6665 28"
        stroke="#FF7C1F"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function UnPinnedIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="black" />
      <g clipPath="url(#clip0_562_2074)">
        <path
          d="M9.32372 25.4272C8.64526 26.269 9.75305 27.3878 10.5825 26.7008L14.6963 23.2949L12.689 21.262L9.32372 25.4272ZM26.0566 15.7217L20.1537 9.78743C19.6361 9.26753 18.797 9.26721 18.2788 9.78802C17.7623 10.3099 17.7623 11.1545 18.2799 11.6747L19.0423 12.4411L15.7601 15.7237C14.9034 15.6738 12.7419 15.5744 11.9037 15.7733C11.017 15.9858 10.7029 17.0909 11.3488 17.7368L18.2616 24.6496C18.9033 25.293 20.0128 24.9852 20.2249 24.0945C20.2701 23.9056 20.4352 22.9856 20.2747 20.2388L23.5454 16.9685L24.1828 17.609C24.708 18.1342 25.5427 18.1217 26.0577 17.6084C26.573 17.0865 26.573 16.2422 26.0566 15.7217Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_562_2074">
          <rect
            width="18.7826"
            height="18.7826"
            fill="white"
            transform="translate(8.60938 8.6084)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function PinnedIcon() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#ACACAC" />
      <g clipPath="url(#clip0_562_2084)">
        <path
          d="M9.32372 25.4272C8.64526 26.269 9.75305 27.3878 10.5825 26.7008L14.6963 23.2949L12.689 21.262L9.32372 25.4272ZM26.0566 15.7217L20.1537 9.78743C19.6361 9.26753 18.797 9.26721 18.2788 9.78802C17.7623 10.3099 17.7623 11.1545 18.2799 11.6747L19.0423 12.4411L15.7601 15.7237C14.9034 15.6738 12.7419 15.5744 11.9037 15.7733C11.017 15.9858 10.7029 17.0909 11.3488 17.7368L18.2616 24.6496C18.9033 25.293 20.0128 24.9852 20.2249 24.0945C20.2701 23.9056 20.4352 22.9856 20.2747 20.2388L23.5454 16.9685L24.1828 17.609C24.708 18.1342 25.5427 18.1217 26.0577 17.6084C26.573 17.0865 26.573 16.2422 26.0566 15.7217Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_562_2084">
          <rect
            width="18.7826"
            height="18.7826"
            fill="white"
            transform="translate(8.60938 8.6084)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function VideoLogo() {
  return (
    <svg width="37" height="22" viewBox="0 0 37 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9388 13.3076C16.9388 9.05543 13.8093 5.53523 9.72716 4.92253L9.71955 4.93775C9.29332 4.86925 8.85567 4.83119 8.41168 4.82865C3.94262 4.85402 0.293033 8.33616 0 12.7367H6.95921V12.7393C6.96572 12.7393 6.97189 12.7389 6.97791 12.7386C6.98361 12.7383 6.98917 12.738 6.99473 12.738C8.74658 12.738 10.1686 14.1448 10.1965 15.8903H10.2003V17.3365C10.1889 20.7666 7.76854 21.0571 7.76854 21.0571V21.7383H10.2003H10.2866H16.9413V13.5854H16.935C16.9375 13.4928 16.9388 13.4002 16.9388 13.3076ZM26.3819 17.3364V14.689C26.0241 14.7372 25.7095 14.8082 25.4393 14.8983C24.8812 15.0835 24.5082 15.3309 24.2304 15.6468C24.0465 15.8586 23.8981 16.1085 23.7852 16.4256C23.6329 16.8455 23.5454 17.3872 23.5454 18.057V21.7383H26.2943H26.3819H28.8124V21.0571C28.8124 21.0571 26.3933 20.7666 26.3819 17.3364ZM27.6514 12.7076C26.7076 12.7076 25.8691 12.7989 25.1206 13.0069C24.5599 13.163 24.0487 13.3862 23.5997 13.6894C22.9261 14.141 22.4072 14.7791 22.0926 15.5275C21.7755 16.2772 21.6423 17.1233 21.6423 18.057V21.7383H18.8452V13.338H18.8426V13.3076C18.8426 8.43384 15.4658 4.33265 10.9282 3.22268C12.6737 1.2463 15.226 0.000591278 18.0701 0.000591278C20.9471 0.000591278 23.5261 1.27674 25.2741 3.29372C23.9853 3.62227 22.7941 4.19058 21.7476 4.94916C21.7184 4.96946 21.6892 4.98976 21.6613 5.01259C21.6601 5.01386 21.6588 5.01481 21.6575 5.01576C21.6563 5.01671 21.655 5.01766 21.6537 5.01893V5.0202C21.447 5.19399 21.315 5.45531 21.315 5.74708C21.315 6.27352 21.74 6.69975 22.2664 6.69975C22.4402 6.69975 22.6026 6.65281 22.7421 6.57163L22.7447 6.57416C22.7574 6.56528 22.7688 6.55767 22.7802 6.54879C22.8132 6.52723 22.8449 6.50439 22.8741 6.47902C24.2695 5.4718 25.9807 4.87686 27.8328 4.87686C30.2049 4.87686 32.3488 5.85236 33.8875 7.42408C35.2258 8.8144 36.096 10.6601 36.2394 12.7076H27.6514Z"
        fill="#626262"
      />
    </svg>
  );
}

export function GalleryIcon() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0416 1.58398H3.95831C3.32842 1.58398 2.72433 1.83421 2.27893 2.27961C1.83354 2.725 1.58331 3.3291 1.58331 3.95898V15.0423C1.58331 15.6722 1.83354 16.2763 2.27893 16.7217C2.72433 17.1671 3.32842 17.4173 3.95831 17.4173H15.0416C15.6715 17.4173 16.2756 17.1671 16.721 16.7217C17.1664 16.2763 17.4166 15.6722 17.4166 15.0423V3.95898C17.4166 3.3291 17.1664 2.725 16.721 2.27961C16.2756 1.83421 15.6715 1.58398 15.0416 1.58398ZM7.91665 5.54232C8.2298 5.54232 8.53592 5.63518 8.7963 5.80916C9.05668 5.98314 9.25962 6.23042 9.37946 6.51974C9.49929 6.80905 9.53065 7.12741 9.46956 7.43454C9.40846 7.74168 9.25766 8.0238 9.03623 8.24524C8.8148 8.46667 8.53268 8.61747 8.22554 8.67856C7.9184 8.73965 7.60005 8.7083 7.31073 8.58846C7.02141 8.46862 6.77413 8.26568 6.60015 8.0053C6.42617 7.74493 6.33331 7.4388 6.33331 7.12565C6.33331 6.70572 6.50013 6.303 6.79706 6.00606C7.09399 5.70913 7.49672 5.54232 7.91665 5.54232ZM5.92956 15.834H3.95831C3.79075 15.8311 3.62841 15.7752 3.49468 15.6741C3.36095 15.5731 3.26272 15.4323 3.21415 15.2719L5.6604 12.3348C5.73925 12.2466 5.83555 12.1758 5.94321 12.1267C6.05086 12.0777 6.16752 12.0515 6.28581 12.0498C6.40603 12.0522 6.52412 12.082 6.63111 12.1369C6.7381 12.1918 6.83119 12.2703 6.90331 12.3665L7.69498 13.4194L5.92956 15.834ZM15.8333 15.0423C15.8333 15.2523 15.7499 15.4536 15.6014 15.6021C15.453 15.7506 15.2516 15.834 15.0416 15.834H7.91665L12.2471 9.88065C12.3142 9.788 12.4006 9.711 12.5004 9.65497C12.6002 9.59893 12.7109 9.56519 12.825 9.55607C12.9396 9.54659 13.055 9.56222 13.163 9.60187C13.271 9.64152 13.3691 9.70423 13.4504 9.78565L15.8333 12.2002V15.0423Z"
        fill="white"
      />
    </svg>
  );
}

export  const NotificationTick = ({color}) => {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_499_3538)">
                <path d="M15.4759 4.4248L6.92556 12.5761L3.03906 8.87096" stroke={color ? color : "#000"} stroke-width="2.61538" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_499_3538">
                    <rect width="17" height="17" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}