import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useWindowSize = () => {
	const location = useLocation();

	let calculateSize = () => {
		if (import.meta.env.SSR) {
			let device = new URLSearchParams(location.search)?.get('device') || 'desktop';
			return [device];
		}

		if (window.innerWidth < 720) return ['mobile'];
		if (window.innerWidth < 1024) return ['tablet'];

		return ['desktop'];
	};

	const [size, setSize] = useState(() => calculateSize());

	let updateSize = () => {
		setSize(calculateSize());
	};

	let callback = import.meta.env.SSR ? React.useEffect : React.useLayoutEffect;

	callback(() => {
		window?.addEventListener('resize', updateSize);
		return () => window?.removeEventListener('resize', updateSize);
	}, []);

	return size;
};

// 1024<tablet 1024>desktop 720<mobile
