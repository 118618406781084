import React, { Component } from "react";
import "./style.css";

export const ErrorPopup = (props) => {
  return (
    <div className="mem-pop-outer">
      <div className="mem-pop-overlay" />
      <div className="mem-pop-card orange-pop mem-mb-big">
        <button className="mem-pop-close-btn" onClick={props.closePop}>
          <img
            alt="close"
            src="https://images.myyogateacher.com/ic_close.png"
          />
        </button>

        {!!props.title && <h1 className="black-h-pop-i">{props.title}</h1>}
        <h1 className="orange-h-pop-i">{props.message}</h1>
      </div>
    </div>
  );
};
