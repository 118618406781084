import { all, put, takeEvery, call } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { post_api } from '../api_funcs';
import urls from '../../urls';
import { isUserLoggedIn } from '../../new_teacher_design/utils/general_funcs';

export function* getZoomSignature(action) {
  if (!isUserLoggedIn()) {
    window.location.replace('/login');
  }
  try {
    yield put(actions.set_zoom_variable('zoom_signature', ''));
    yield put(actions.set_zoom_variable('zoom_signature_status', 'loading'));
    const url = urls.get_zoom_signature;
    const payload = {
      ...action.payload,
      zak: 1
    };
    const response = yield call(post_api, url, payload, true);
    // yield put(actions.set_zoom_variable('zoom_signature', response.data.signature));
    yield put(actions.set_zoom_variable('zoom_signature', response.data.zak_token));
    yield put(actions.set_zoom_variable('join_url', response.data.join_url));
    yield put(actions.set_zoom_variable('zoom_signature_status', 'success'));
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    yield put(actions.set_zoom_variable('zoom_signature_status', 'fail'));
  }
}

export function* joinSession(action) {
  try {
    const url = urls.join_session;
    const response = yield call(post_api, url, action.payload, true);
    yield put(actions.set_zoom_variable('zoom_session_logs_uuid', response.data.uuid));
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    console.log('start log failed');
  }
}

export function* sessionLogStart(action) {
  try {
    const url = urls.start_teacher_session_logs;
    const response = yield call(post_api, url, action.payload, true);
    yield put(actions.set_zoom_variable('zoom_session_logs_uuid', response.data.uuid));
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    console.log('start log failed');
  }
}

export function* sessionLogEnd(action) {
  try {
    const url = urls.end_teacher_session_logs;
    yield call(post_api, url, action.payload, true);
  } catch (error) {
    try {
      if (error.response.status === 401) {
        yield put(actions.log_out());
      }
    } catch (e) {}
    console.log('end log failed');
  }
}

export default function* zoomSaga() {
  yield all([
    takeEvery(t.get_zoom_signature, getZoomSignature),
    takeEvery(t.session_log_start, sessionLogStart),
    takeEvery(t.session_log_end, sessionLogEnd),
    takeEvery(t.join_session, joinSession)
  ]);
}
