export const get_dashboard_loading = (state) =>
  state.dashboard.dashboard_loading;
export const get_sessions_in_next_24_hours = (state) =>
  state.dashboard.sessions_in_next_24_hours;
export const get_availability_in_next_7_days = (state) =>
  state.dashboard.availability_in_next_7_days;

export const get_sessions = (state) => state.dashboard.sessions;
export const get_cancel_session_status = (state) =>
  state.dashboard.session_cancel_status;

export const get_student_details = (state) => state.dashboard.student_details;
export const student_loading_status = (state) =>
  state.dashboard.student_loading_status;
