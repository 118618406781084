import React, { Component } from 'react';
import './modal.css';
export default class CustomModal extends Component {
	render() {
		if (this.props.show) {
			return (
				<div className="custom-modal-container">
					<div className="custom-modal-inner-container">
						<img
							src="https://images.myyogateacher.com/teacher_web_app/ic_cross@3x.svg"
							alt="c"
							onClick={this.props.close}
						/>
						{this.props.children}
					</div>
				</div>
			);
		}
		return null;
	}
}
