import React, { useState } from 'react';

const UpdateStudentCount = ({ closeUpdateStudentCountPopup, studentCount, incrementDecrement, updateStudentCount, studentCountErr }) => {


    return (
        <>
            <div className='relative inset-0 z-50'>
                <div
                    className='fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex md:items-center zm:items-end md:justify-center zm:justify-end'
                >
                    <div className='z-50 bg-white zm:rounded-tl-3xl zm:rounded-tr-3xl zm-roundex-lb-none zm:rounded-rb-none md:rounded-lg md:p-10 zm:p-30px md:w-492px zm:w-full relative'>
                        <div className='flex justify-end cursor-pointer absolute top-1 right-1' onClick={closeUpdateStudentCountPopup}>
                            <div className='md:mt-1 mr-1'>
                                <svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <rect width='34' height='34' rx='17' fill='none' />
                                    <path
                                        d='M22 12L12 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                    <path
                                        d='M12 12L22 22'
                                        stroke='#BFBFBF'
                                        strokeWidth='1.66667'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className='flex flex-col justify-center items-center'>
                            <div className='text-subText md:text-19px zm:text-17 font-semibold pt-4 leading-tight'>Enter total number of students who joined this session</div>
                            <p className='text-mytgrey-lite text-14.5 font-normal leading-normal mt-3'>Student would be charged extra and you would be paid extra for additional students joining</p>
                            <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-6 mb-5 items-center justify-center">
                                <button data-action="decrement" onClick={() => incrementDecrement(-1)} className="incrementButton	text-gray-600 hover:text-gray-700 hover:bg-gray-400 rounded-full cursor-pointer outline-none">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#264150" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8 12H16" stroke="#264150" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </button>
                                {/* <input type="number" className="outline-none focus:outline-none text-center w-full bg-black-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number" value="0"></input> */}
                                <input type={"number"} min={1} disabled className='w-221px rounded-lg text-15 text-md font-medium shadow-sm text-center formInput border font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center mx-25px' value={studentCount} />
                                <button data-action="increment" onClick={() => incrementDecrement(1)} className="incrementButton	text-gray-600 hover:text-gray-700 hover:bg-gray-400 rounded-full cursor-pointer">
                                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.8457 21C17.3686 21 21.8457 16.5228 21.8457 11C21.8457 5.47715 17.3686 1 11.8457 1C6.32286 1 1.8457 5.47715 1.8457 11C1.8457 16.5228 6.32286 21 11.8457 21Z" stroke="#264150" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.8457 7V15" stroke="#264150" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.8457 11H15.8457" stroke="#264150" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </button>
                            </div>
                            {/* {studentCount > 5  ?
                <> */}
                            {
                                studentCountErr && studentCountErr.message && studentCountErr.message.length &&
                                <div className='flex justify-start text-start w-full'>
                                    <p className='text-mytRedText text-15.5 font-medium mt-5 mb-5'>{studentCountErr.message}</p>
                                </div>
                            }
                            {/* </>
                :
                null
              } */}
                            <div className='flex flex-col w-full'>
                                <button className='btn btn-primary md:py-5 zm:py-3 mt-10px md:text-18.5 zm:text-17 font-semibold' onClick={updateStudentCount}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateStudentCount;