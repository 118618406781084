import * as t from '../action_types';
import { getCookie } from '../../utils';


var initialStata = {
    get_teacher_list_status: 'none',
    get_teacher_list_response: {},

    get_teacher_time_slots_status: 'none',
    get_teacher_time_slots_response: {},

    update_session_time_duration_status: 'none',
    update_session_time_duration_response: {},


    cancel_session_status: 'none',
    cancel_session_response: {},

    replacement_session_status: 'none',
    replacement_session_response: {},

    get_subscription_data_status: 'none',
    get_subscription_data_response: {},

    scheduleSessionDataInfo: {},
    cancel_session_error: '',

}


const set_home_variable = (state, payload) => {
    return {
      ...state,
      [payload.key]: payload.payload,
    };
  };

export default function (state = initialStata, action) {
    switch (action.type) {
      case t.set_home_variable:
        return set_home_variable(state, action.payload);
      default:
        return state;
    }
  }
  