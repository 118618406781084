// eslint-disable-next-line no-unused-vars
import React from 'react';

export const DateIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M15.8333 3.33337H4.16667C3.24619 3.33337 2.5 4.07957 2.5 5.00004V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V5.00004C17.5 4.07957 16.7538 3.33337 15.8333 3.33337Z'
			stroke='#264150'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M13.334 1.66663V4.99996'
			stroke='#264150'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M6.66602 1.66663V4.99996'
			stroke='#264150'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M2.5 8.33337H17.5' stroke='#264150' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

export const TimeIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M10.0013 18.3333C14.6037 18.3333 18.3346 14.6023 18.3346 9.99996C18.3346 5.39759 14.6037 1.66663 10.0013 1.66663C5.39893 1.66663 1.66797 5.39759 1.66797 9.99996C1.66797 14.6023 5.39893 18.3333 10.0013 18.3333Z'
			stroke='#264150'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10 5V10L13.3333 11.6667'
			stroke='#264150'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const DurationIcon = () => (
	<svg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1.92773 2.42822L7.49916 7.99965L13.0706 2.42822'
			stroke='#264150'
			strokeWidth='1.85714'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const DownArrowIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='15' height='10' viewBox='0 0 15 10' fill='none'>
		<path
			d='M1.92822 2.42822L7.49965 7.99965L13.0711 2.42822'
			stroke='#264150'
			strokeWidth='1.85714'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export const LeftCircleArrowIcon = () => (
	<svg width='38' height='38' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14 1.75C10.7511 1.75 7.63526 3.04062 5.33794 5.33794C3.04062 7.63526 1.75 10.7511 1.75 14C1.75 17.2489 3.04062 20.3647 5.33794 22.6621C7.63526 24.9594 10.7511 26.25 14 26.25C17.2489 26.25 20.3647 24.9594 22.6621 22.6621C24.9594 20.3647 26.25 17.2489 26.25 14C26.25 10.7511 24.9594 7.63526 22.6621 5.33794C20.3647 3.04062 17.2489 1.75 14 1.75ZM14 28C10.287 28 6.72601 26.525 4.1005 23.8995C1.47499 21.274 0 17.713 0 14C0 10.287 1.47499 6.72601 4.1005 4.10051C6.72601 1.475 10.287 0 14 0C17.713 0 21.274 1.475 23.8995 4.10051C26.525 6.72601 28 10.287 28 14C28 17.713 26.525 21.274 23.8995 23.8995C21.274 26.525 17.713 28 14 28ZM20.125 14.875C20.3571 14.875 20.5796 14.7828 20.7437 14.6187C20.9078 14.4546 21 14.2321 21 14C21 13.7679 20.9078 13.5454 20.7437 13.3813C20.5796 13.2172 20.3571 13.125 20.125 13.125L9.98725 13.125L13.7445 9.3695C13.9088 9.2052 14.0011 8.98236 14.0011 8.75C14.0011 8.51764 13.9088 8.2948 13.7445 8.1305C13.5802 7.9662 13.3574 7.87389 13.125 7.87389C12.8926 7.87389 12.6698 7.9662 12.5055 8.1305L7.2555 13.3805C7.17402 13.4618 7.10937 13.5583 7.06525 13.6646C7.02114 13.7709 6.99844 13.8849 6.99844 14C6.99844 14.1151 7.02114 14.2291 7.06525 14.3354C7.10937 14.4417 7.17402 14.5382 7.2555 14.6195L12.5055 19.8695C12.6698 20.0338 12.8926 20.1261 13.125 20.1261C13.3574 20.1261 13.5802 20.0338 13.7445 19.8695C13.9088 19.7052 14.0011 19.4824 14.0011 19.25C14.0011 19.0176 13.9088 18.7948 13.7445 18.6305L9.98725 14.875L20.125 14.875Z'
			fill='#AFB0BD'
		/>
	</svg>
);
export const LeftCircleArrowFillIcon = () => (
	<svg width='38' height='38' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14 1.75C10.7511 1.75 7.63526 3.04062 5.33794 5.33794C3.04062 7.63526 1.75 10.7511 1.75 14C1.75 17.2489 3.04062 20.3647 5.33794 22.6621C7.63526 24.9594 10.7511 26.25 14 26.25C17.2489 26.25 20.3647 24.9594 22.6621 22.6621C24.9594 20.3647 26.25 17.2489 26.25 14C26.25 10.7511 24.9594 7.63526 22.6621 5.33794C20.3647 3.04062 17.2489 1.75 14 1.75ZM14 28C10.287 28 6.72601 26.525 4.1005 23.8995C1.47499 21.274 0 17.713 0 14C0 10.287 1.47499 6.72601 4.1005 4.10051C6.72601 1.475 10.287 0 14 0C17.713 0 21.274 1.475 23.8995 4.10051C26.525 6.72601 28 10.287 28 14C28 17.713 26.525 21.274 23.8995 23.8995C21.274 26.525 17.713 28 14 28ZM20.125 14.875C20.3571 14.875 20.5796 14.7828 20.7437 14.6187C20.9078 14.4546 21 14.2321 21 14C21 13.7679 20.9078 13.5454 20.7437 13.3813C20.5796 13.2172 20.3571 13.125 20.125 13.125L9.98725 13.125L13.7445 9.3695C13.9088 9.2052 14.0011 8.98236 14.0011 8.75C14.0011 8.51764 13.9088 8.2948 13.7445 8.1305C13.5802 7.9662 13.3574 7.87389 13.125 7.87389C12.8926 7.87389 12.6698 7.9662 12.5055 8.1305L7.2555 13.3805C7.17402 13.4618 7.10937 13.5583 7.06525 13.6646C7.02114 13.7709 6.99844 13.8849 6.99844 14C6.99844 14.1151 7.02114 14.2291 7.06525 14.3354C7.10937 14.4417 7.17402 14.5382 7.2555 14.6195L12.5055 19.8695C12.6698 20.0338 12.8926 20.1261 13.125 20.1261C13.3574 20.1261 13.5802 20.0338 13.7445 19.8695C13.9088 19.7052 14.0011 19.4824 14.0011 19.25C14.0011 19.0176 13.9088 18.7948 13.7445 18.6305L9.98725 14.875L20.125 14.875Z'
			fill='#EE731B'
		/>
	</svg>
);
export const RightCircleArrowIcon = () => (
	<svg width='38' height='38' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.001 26.25C17.2499 26.25 20.3657 24.9594 22.663 22.6621C24.9604 20.3647 26.251 17.2489 26.251 14C26.251 10.7511 24.9604 7.63526 22.663 5.33794C20.3657 3.04062 17.2499 1.75 14.001 1.75C10.7521 1.75 7.63624 3.04062 5.33892 5.33794C3.0416 7.63526 1.75098 10.7511 1.75098 14C1.75098 17.2489 3.0416 20.3647 5.33892 22.6621C7.63624 24.9594 10.7521 26.25 14.001 26.25ZM14.001 0C17.714 0 21.275 1.47499 23.9005 4.1005C26.526 6.72601 28.001 10.287 28.001 14C28.001 17.713 26.526 21.274 23.9005 23.8995C21.275 26.525 17.714 28 14.001 28C10.2879 28 6.72699 26.525 4.10148 23.8995C1.47597 21.274 0.000976563 17.713 0.000976563 14C0.000976563 10.287 1.47597 6.72601 4.10148 4.1005C6.72699 1.47499 10.2879 0 14.001 0ZM7.87598 13.125C7.64391 13.125 7.42135 13.2172 7.25726 13.3813C7.09316 13.5454 7.00098 13.7679 7.00098 14C7.00098 14.2321 7.09316 14.4546 7.25726 14.6187C7.42135 14.7828 7.64391 14.875 7.87598 14.875L18.0137 14.875L14.2565 18.6305C14.0922 18.7948 13.9999 19.0176 13.9999 19.25C13.9999 19.4824 14.0922 19.7052 14.2565 19.8695C14.4208 20.0338 14.6436 20.1261 14.876 20.1261C15.1083 20.1261 15.3312 20.0338 15.4955 19.8695L20.7455 14.6195C20.827 14.5382 20.8916 14.4417 20.9357 14.3354C20.9798 14.2291 21.0025 14.1151 21.0025 14C21.0025 13.8849 20.9798 13.7709 20.9357 13.6646C20.8916 13.5583 20.827 13.4618 20.7455 13.3805L15.4955 8.1305C15.3312 7.9662 15.1083 7.8739 14.876 7.8739C14.6436 7.8739 14.4208 7.9662 14.2565 8.1305C14.0922 8.2948 13.9999 8.51764 13.9999 8.75C13.9999 8.98236 14.0922 9.2052 14.2565 9.3695L18.0137 13.125L7.87598 13.125Z'
			fill='#AFB0BD'
		/>
	</svg>
);
export const RightCircleArrowFillIcon = () => (
	<svg width='38' height='38' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.001 26.25C17.2499 26.25 20.3657 24.9594 22.663 22.6621C24.9604 20.3647 26.251 17.2489 26.251 14C26.251 10.7511 24.9604 7.63526 22.663 5.33794C20.3657 3.04062 17.2499 1.75 14.001 1.75C10.7521 1.75 7.63624 3.04062 5.33892 5.33794C3.0416 7.63526 1.75098 10.7511 1.75098 14C1.75098 17.2489 3.0416 20.3647 5.33892 22.6621C7.63624 24.9594 10.7521 26.25 14.001 26.25ZM14.001 0C17.714 0 21.275 1.47499 23.9005 4.1005C26.526 6.72601 28.001 10.287 28.001 14C28.001 17.713 26.526 21.274 23.9005 23.8995C21.275 26.525 17.714 28 14.001 28C10.2879 28 6.72699 26.525 4.10148 23.8995C1.47597 21.274 0.000976563 17.713 0.000976563 14C0.000976563 10.287 1.47597 6.72601 4.10148 4.1005C6.72699 1.47499 10.2879 0 14.001 0ZM7.87598 13.125C7.64391 13.125 7.42135 13.2172 7.25726 13.3813C7.09316 13.5454 7.00098 13.7679 7.00098 14C7.00098 14.2321 7.09316 14.4546 7.25726 14.6187C7.42135 14.7828 7.64391 14.875 7.87598 14.875L18.0137 14.875L14.2565 18.6305C14.0922 18.7948 13.9999 19.0176 13.9999 19.25C13.9999 19.4824 14.0922 19.7052 14.2565 19.8695C14.4208 20.0338 14.6436 20.1261 14.876 20.1261C15.1083 20.1261 15.3312 20.0338 15.4955 19.8695L20.7455 14.6195C20.827 14.5382 20.8916 14.4417 20.9357 14.3354C20.9798 14.2291 21.0025 14.1151 21.0025 14C21.0025 13.8849 20.9798 13.7709 20.9357 13.6646C20.8916 13.5583 20.827 13.4618 20.7455 13.3805L15.4955 8.1305C15.3312 7.9662 15.1083 7.8739 14.876 7.8739C14.6436 7.8739 14.4208 7.9662 14.2565 8.1305C14.0922 8.2948 13.9999 8.51764 13.9999 8.75C13.9999 8.98236 14.0922 9.2052 14.2565 9.3695L18.0137 13.125L7.87598 13.125Z'
			fill='#EE731B'
		/>
	</svg>
);

export const InfoIcon = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 44 44' fill='none'>
		<g clipPath='url(#clip0_1345_19140)'>
			<path
				d='M22 0C9.84615 0 0 9.84615 0 22C0 34.1538 9.84615 44 22 44C34.1538 44 44 34.1538 44 22C44 9.84615 34.1538 0 22 0ZM25.0256 35.0256C25.0256 35.6923 24.4615 36.2564 23.7949 36.2564H20.2564C19.5897 36.2564 19.0256 35.6923 19.0256 35.0256V31.3333C19.0256 30.6667 19.5897 30.1026 20.2564 30.1026H23.7949C24.4615 30.1026 25.0256 30.6667 25.0256 31.3333V35.0256ZM25.0256 25.9487C25.0256 26.6154 24.4615 27.1795 23.7949 27.1795H20.2564C19.5897 27.1795 19.0256 26.6154 19.0256 25.9487V10.5641C19.0256 9.89744 19.5897 9.33333 20.2564 9.33333H23.7949C24.4615 9.33333 25.0256 9.89744 25.0256 10.5641V25.9487Z'
				fill='#EE731B'
			/>
		</g>
		<defs>
			<clipPath id='clip0_1345_19140'>
				<rect width='44' height='44' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

export const DeleteIcon = () => (
	<svg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M2.25 6.5H3.75H15.75'
			stroke='#321A50'
			strokeWidth='1.7'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M6 6.5V5C6 4.60218 6.15804 4.22064 6.43934 3.93934C6.72064 3.65804 7.10218 3.5 7.5 3.5H10.5C10.8978 3.5 11.2794 3.65804 11.5607 3.93934C11.842 4.22064 12 4.60218 12 5V6.5M14.25 6.5V17C14.25 17.3978 14.092 17.7794 13.8107 18.0607C13.5294 18.342 13.1478 18.5 12.75 18.5H5.25C4.85218 18.5 4.47064 18.342 4.18934 18.0607C3.90804 17.7794 3.75 17.3978 3.75 17V6.5H14.25Z'
			stroke='#321A50'
			strokeWidth='1.7'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M7.5 10.25V14.75' stroke='#321A50' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M10.5 10.25V14.75' stroke='#321A50' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

export const DownloadIcon = () => (
	<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25'
			stroke='#321A50'
			strokeWidth='1.7'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M5.24951 7.49969L8.99951 11.2497L12.7495 7.49969'
			stroke='#321A50'
			strokeWidth='1.7'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M9 11.25V2.25' stroke='#321A50' strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);
export const LoaderIcon = () => (
	<svg className='h-6 w-6 mr-3' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='#EE731B'>
		<g fill='none' fillRule='evenodd'>
			<g transform='translate(1 1)' strokeWidth='2'>
				<circle strokeOpacity='.5' cx='18' cy='18' r='18' />
				<path d='M36 18c0-9.94-8.06-18-18-18'>
					<animateTransform
						attributeName='transform'
						type='rotate'
						from='0 18 18'
						to='360 18 18'
						dur='1s'
						repeatCount='indefinite'
					/>
				</path>
			</g>
		</g>
	</svg>
);

export const LeftRightArrowIcon = () => (
	<svg
		width='16'
		height='13'
		className='ml-1 mb-0.5 inline'
		viewBox='0 0 18 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M5.5 3.62V10.38L2.12 7L5.5 3.62ZM7 0L0 7L7 14V0ZM11 0V14L18 7L11 0Z' fill='#321A50' />
	</svg>
);

export const LeftArrowOIcon = () => (
	<svg
		width='7'
		height='14'
		className='ml-1 mb-0.5 inline'
		viewBox='0 0 7 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M5.5 3.62V10.38L2.12 7L5.5 3.62ZM7 0L0 7L7 14V0Z' fill='#321A50' />
	</svg>
);

export const RightArrowOIcon = () => (
	<svg
		width='7'
		height='14'
		className='ml-1 mb-0.5 inline'
		viewBox='0 0 7 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M1.5 3.62V10.38L4.88 7L1.5 3.62ZM0 0L7 7L0 14V0Z' fill='#321A50' />
	</svg>
);

export const LeftArrowFillIcon = () => (
	<svg
		width='7'
		height='14'
		className='ml-1 mb-0.5 inline'
		viewBox='0 0 7 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M7 0V14L0 7L7 0Z' fill='#321A50' />
	</svg>
);

export const RightArrowFillIcon = () => (
	<svg
		width='7'
		height='14'
		className='ml-1 mb-0.5 inline'
		viewBox='0 0 7 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M0 0V14L7 7L0 0Z' fill='#321A50' />
	</svg>
);

export const CrossIcon = () => (
	<svg width='40' height='40' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect width='34' height='34' rx='17' fill='none' />
		<path d='M22 12L12 22' stroke='#BFBFBF' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M12 12L22 22' stroke='#BFBFBF' strokeWidth='1.66667' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);
