import moment from 'moment-timezone';
import React, { useEffect, useRef, useState, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './DatePicker.css';
import { DateIcon } from '../../assets/svg/popup_icons';
import { getCookie } from '../../utils/cookie_funcs';

const DateFilter = (props: any) => {

    const [inputTypeLabel, setInputTypeLabel] = useState(null);
    const [startDate, setStartDate] = useState(new Date(new Date(props?.data)?.toLocaleString('en', { timeZone: !!getCookie('time_zone') ? getCookie('time_zone') : "Asia/Kolkata" })));
    const isFirstRender = useRef(true)

    useEffect(() => {
        setInputTypeLabel(props.inputTypeLabel);
    }, [props.data])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false // toggle flag after first render/mounting
            return;
        }
        props.onClickDateValue(startDate)
    }, [startDate])

    return (
        <>
            <div>
                <div className="lg:m-0 zm:mr-2">
                    <p className='font-medium text-mytLabel text-13.5'>{inputTypeLabel}</p>
                    <div className='relative cursor-pointer mt-2 w-fit'>
                        <div className=''>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MMM d, yyyy"
                                className="formInput w-full zm:w-150px xs:w-195px input-date rounded-lg text-15 font-medium shadow-sm flex items-center border"
                            />
                            <div className='pointer-events-none absolute top-10px right-3'>{<DateIcon />}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DateFilter;