import { Room, VideoPresets, setLogExtension, LogLevel, setLogLevel } from 'livekit-client';
import React, { useEffect, useState } from 'react';
import LivekitVideoContainer from './LivekitVideoContainer';
import urls from '../../../urls';
import LivekitErrorModal from './modals/LivekitErrorModal';
import SessionEndedModal from './modals/SessionEndedModal';
import { CUSTOM_MODAL } from './menu';
import { callJsonPostApi } from './utils';

import Loader from '../../new_teacher_wesite/components/loader';
import useSearchParams from '../../new_teacher_wesite/hooks/useSearchParams';

import { JoinIdentity } from './constants';
import { setCookie } from '../../../util_functions';

function LivekitWrapper() {
  const ERROR_MODAL = {
    show: false,
    errorCode: null
  };

  const searchParams = useSearchParams();

  const [meetingArgs, setMeetingArgs] = useState(null);

  const [errorModal, setErrorModal] = useState({
    ...ERROR_MODAL,
    onCancel: () => setErrorModal({ ...ERROR_MODAL })
  });

  const [customModal, setCustomModal] = useState({ ...CUSTOM_MODAL });

  useEffect(() => {
    let wakeLock = null;

    const aquireWakeLock = async () => {
      try {
        wakeLock = await navigator?.wakeLock?.request('screen');

        !!wakeLock && console.log('wake lock is acquired');

        wakeLock?.addEventListener('release', () => {
          wakeLock = null;
          console.log('wake lock is released');
        });
      } catch (e) {
        console.log('livekit.useEffect.wakeLock', e);
      }
    };

    aquireWakeLock();

    return () => {
      wakeLock?.release();
      console.log('wake lock is released');
    };
  }, []);

  // async request to get the SDK job from the backend
  const getToken = async () => {
    try {
      const body = {};
      // mobile app
      if (window.ReactNativeWebView !== undefined) {
        const {token} = searchParams;
        const {session_uuid} = searchParams;

        if (token !== null && token !== undefined && token !== '') {
          setCookie('teacher_token', token);
        } else {
          window.ReactNativeWebView &&
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                status: 'failure'
              })
            );
        }

        if (session_uuid !== null && session_uuid !== undefined && session_uuid !== '') {
          body.session_uuid = session_uuid;
        }
      } else if (searchParams.session_uuid) {
          body.session_uuid = searchParams.session_uuid;
        }

      const res = await callJsonPostApi(urls.livekit_join, body);
      const meetingArgs = res.data.data;

      meetingArgs.livekit.join_info.token = meetingArgs.livekit.join_info[JoinIdentity.Main];

      if (JoinIdentity.Aux in meetingArgs.livekit.join_info) {
        setCustomModal({
          show: true,
          title: 'Choose session join mode',
          body: 'You can choose to join as primary or companion device. In companion device mic/speaker/audio is disabled.',
          onCancel: () => {
            meetingArgs.livekit.join_info.token = meetingArgs.livekit.join_info[JoinIdentity.Aux];
            setCustomModal({ ...CUSTOM_MODAL });
            setMeetingArgs(meetingArgs);
          },
          onConfirm: () => {
            setCustomModal({ ...CUSTOM_MODAL });
            setMeetingArgs(meetingArgs);
          },
          confirmText: 'Join as primary',
          cancelText: 'Join as companion',
          showErrorSvg: false
        });
      } else {
        setMeetingArgs(meetingArgs);
      }
    } catch (error) {
      const {
        data: { reason },
        status
      } = error.response;
      //   status 424 -> user banned in the room
      if (status == 424 && reason === 'user is banned in this room') {
        setCustomModal({
          show: true,
          title: 'User is banned',
          body: 'You have been banned from this meeting as per our platform usage guidelines. Please contact teacher or care/ops team to be able to join',
          onCancel: () => window.close(),
          onConfirm: () => window.location.replace('/'),
          confirmText: 'Go to dashboard',
          cancelText: '',
          showErrorSvg: true
        });
      } else if (status == 424 && reason === 'room is locked') {
        setCustomModal({
          show: true,
          title: 'Meeting Locked',
          body: 'This room has been locked by admin. Please contact teacher or care/ops team to be able to join',
          onCancel: () => window.close(),
          onConfirm: () => window.location.replace('/'),
          confirmText: 'Go to dashboard',
          cancelText: '',
          showErrorSvg: true
        });
      } else {
        setErrorModal((prevVal) => ({
          ...prevVal,
          show: true,
          errorCode: status,
          onRetry: () => window.location.reload(),
          cancelText: '',
          reason
        }));
      }
    }
  };

  // creates a new room with options
  const room = new Room({
    // automatically manage subscribed video quality
    adaptiveStream: true,
    audioCaptureDefaults: {
      autoGainControl: true,
      echoCancellation: true,
      noiseSuppression: true
    },
    videoCaptureDefaults: {
      facingMode: 'user',
      resolution: VideoPresets.h720.resolution
    },
    // optimize publishing bandwidth and CPU for published tracks
    dynacast: true,
    disconnectOnPageLeave: true,
    publishDefaults: {
      simulcast: true,
      videoSimulcastLayers: [VideoPresets.h360, VideoPresets.h720],
      videoCodec: 'vp9'
    },
    reconnectPolicy: {
      nextRetryDelayInMs: (context) => {
        if (context.retryCount > 10) {
          setCustomModal({
            show: true,
            title: 'Session Join Failed',
            body: 'Please contact care/ops team',
            onCancel: () => window.close(),
            onConfirm: () => window.location.replace('/'),
            confirmText: 'Go to dashboard',
            cancelText: '',
            showErrorSvg: true
          });

          return null;
        }
        console.log(
          `retrying connection: retries: ${context.retryCount} sec: ${context.elapsedMs / 1000} `
        );
        return 3000; // retry every 3 seconds
      }
    }
  });

  // onMount -> call the join api
  useEffect(() => {
    // call the get token api

    // determine the log levels here
    setLogLevel(LogLevel.debug);
    // logger function
    setLogExtension((level, msg, context) => {
      const enhancedContext = { ...context, timeStamp: Date.now() };
      if (level >= LogLevel.debug) {
        console.log(level, msg, enhancedContext);
      }
    });

    getToken();
  }, []);

  return (
    <>
      <LivekitErrorModal {...errorModal} />
      <SessionEndedModal {...customModal} />
      {meetingArgs && (
        <LivekitVideoContainer
          meetingArgs={meetingArgs}
          room={room}
          teacher={meetingArgs.teacher}
        />
      )}
      {!meetingArgs && !errorModal.show && !customModal.show && <Loader />}
    </>
  );
}

export default LivekitWrapper;
