import React, { useEffect, useState } from 'react'
import Button from './CommonComponents/Button'
import WhiteBox, { Boxcontent } from './CommonComponents/WhiteBox'
import EditSessionNotesPopUp from './EditSessionNotesPopUp'
import { HeadText } from './StudentProfileDetails'
import { OrangeEmptyStar, OrangeFullStar } from './svg'
import ReactVideoPlayer from 'react-player';
import Modal from './CommonComponents/Modal';
import moment from 'moment-timezone'
import { backpian_client_session_status } from '../../constants'
const TherapyJourney = (props) => {
    const [sessionSelected, setSessionSelected] = useState({});
    const [videoPopup, setVideoPopup] = useState(false);
    useEffect(() => {


    }, [])
    if (videoPopup && sessionSelected.hasOwnProperty('recording_url') && sessionSelected.recording_url !== '') {
        return (
            <Modal onClose={() => setVideoPopup(false)}>
                <div>
                    <ReactVideoPlayer width={'492px'} height={"300px"} url={sessionSelected.recording_url} controls={true} />
                </div>
            </Modal>
        )
    }

    const consult_sessions = props?.data?.past_sessions?.filter((session) => session.session_type === 'BACK_PAIN_CONSULT') || [];
    const coaching_sessions = props?.data?.past_sessions?.filter((session) => session.session_type === 'BACK_PAIN_THERAPY') || [];
    if (consult_sessions.length > 0 || coaching_sessions.length > 0) {
        <></>
    }
    return (
        props?.data?.past_sessions?.length > 0 &&
        <div>
            <HeadText
                text="Journey"
                classnames="mb-31px" />
             {props?.data?.past_sessions?.map((session, index) => {
                 let preMoodLevel = session?.pre_mood_level_v1?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ') || '';
                 let postMoodLevel = session?.post_mood_level_v1?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ') || '';
                return (
                    <>
                        <WhiteBox classnames="mb-10">
                            <div className='flex items-start justify-between'>
                                <div>
                                    {session.session_type === 'BACK_PAIN_THERAPY' ? 
                                    <Boxcontent
                                        classnames="mt-0px"
                                        textstyle="text-18px leading-5"
                                        parastyle="text-12px leading-18px text-mytgrey-lite"
                                        subhead={`Session ${session.session_no} by ${session.teacher_name}`}
                                        para={moment(session?.start_time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('D MMMM YYYY; h A z') || ''} /> :

                                    <>
                                    <Boxcontent
                                        classnames="mt-0px"
                                        textstyle="text-18px leading-5"
                                        parastyle="text-12px leading-18px text-mytgrey-lite"
                                        subhead={`Consultation session by ${session?.teacher_name || ''}`}
                                        para={moment(session?.start_time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('D MMMM YYYY; h A z') || ''} />
                                    <div className='bg-mytgrey-phoneCallPopupText text-white w-fit font-bold text-10px px-1 py-3px rounded-4px mt-5'>
                                        {session?.session_status}
                                    </div>
                                    
                                    {session.consultation_questions?.map((ques, index) => {
                                        return (<Boxcontent
                                            key={ques?.uuid || index}
                                            parastyle="whitespace-pre-line"
                                            classnames="mt-20px"
                                            videoLink={ques?.recording_url || ''}
                                            subhead={ques?.question || ''}
                                            para={ques?.answer || ''}
                                             />)
                                     })}
                                    </>
                                         }
                                    
                                    {session.session_type === 'BACK_PAIN_CONSULT' ? <></> :<div className='bg-mytgrey-phoneCallPopupText text-white w-fit font-bold text-10px px-1 py-3px rounded-4px mt-5'>
                                        {session?.session_status}
                                    </div>}
                                    <div className='flex items-center mt-5'>
                                        {new Array(session?.rating || 0).fill(0).map((item) => {
                                            return (
                                                <OrangeFullStar />
                                            )
                                        })}
                                        {session?.rating === 0 ? <></> : new Array(5 - (session?.rating || 0)).fill(0).map((item) => {
                                            return (
                                                <OrangeEmptyStar />
                                            )
                                        })}

                                    </div>
                                    <div className='md:hidden zm:block mt-30px'>
                                        <TherapyVid onClick={() => {

                                            setSessionSelected(session)
                                            setVideoPopup(true)
                                        }} />
                                    </div>
                                    {session?.pre_pain_level === 0 && session?.post_pain_level === 0 ? <></> : <Boxcontent
                                        classnames="mt-20px"
                                        subhead="Pain level"
                                    />}
                                    <div className='flex md:items-center zm:flex-col md:flex-row zm:gap-5px md:gap-0'>
                                        {session?.pre_pain_level === 0 ? <></> : <TherapyContent
                                            text={`${session?.pre_pain_level || 0}/10`}
                                            spantext="(Before the session)" />}
                                        {session?.post_pain_level === 0 ? <></> : <TherapyContent
                                            text={`${session?.post_pain_level || 0}/10`}
                                            spantext="(After the session)" />}
                                    </div>
                                    {session?.pre_mood_level === '' && session?.post_mood_level === '' ? <></> : <Boxcontent
                                        subhead="Mood and energy levels"
                                    />}
                                    <div className='flex md:items-center zm:flex-col md:flex-row zm:gap-5px flex-wrap md:gap-1'>
                                        {session?.pre_mood_level === '' ? <></> : <TherapyContent
                                            text={preMoodLevel === 'Other' ? session?.pre_energy_level_other : preMoodLevel}
                                            spantext="(Before the session)" />}
                                        {session?.post_mood_level === '' ? <></> : <TherapyContent
                                            text={postMoodLevel === 'Other' ? session?.post_energy_level_other : postMoodLevel}
                                            spantext="(After the session)" />}
                                    </div>
                                    {session.feedback === '' ? <></> : <Boxcontent
                                        parastyle="text-greyText"
                                        subhead="Client Feedback"
                                        para={session.feedback || ''} />}
                                </div>
                                <div className='md:block zm:hidden'>
                                    {backpian_client_session_status.includes(session?.session_status)  ? <></> : 
                                    <TherapyVid onClick={() => {

                                        setSessionSelected(session)
                                        setVideoPopup(true)
                                    }} />}
                                </div>
                            </div>
                            {session?.teacher_notes === null ? <></> : <Boxcontent
                                parastyle="whitespace-pre-line"
                                subhead="Teacher notes"
                                para={session?.teacher_notes || ''} />}
                        </WhiteBox>
                    </>

                )
            })}
        </div>
    )
}
export default TherapyJourney

export function TherapyVid(props) {

    return (
        <div className='sw_ct_video grpc-record-v therapy-vid cursor-pointer' onClick={() => {
            props?.onClick();
        }}>
            <img
                className='sw_ct_part_vid'
                src='https://res.cloudinary.com/dgerdfai4/image/upload/v1687000008/Lv-landingpage/Video_2x.png'
                alt='video'
            />
            <div className='sw_ct_part_vid_cont'>
            </div>
        </div>
    )
}

export function TherapyContent(props) {
    return (
        <div className='flex mr-30px flex-wrap'>
            <div className='text-13.5px leading-4 text-greyText mr-5px font-semibold'>{props.text}</div>
            <div className='font-normal text-12px leading-15px text-mytgrey-lite'>{props.spantext}</div>
        </div>
    )
}
