// eslint-disable-next-line no-unused-vars
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const shouldEnableSentry = () => {
  if (
    import.meta.env.VITE_APP_STAGE !== 'production' ||
    import.meta.env.SSR ||
    window.location.hostname.includes('local')
  ) {
    return false;
  }

  return true;
};

Sentry.init({
  dsn: 'https://9dd0e934cb5c6b07fa6a78d905400e12@o4507713531936768.ingest.us.sentry.io/4507865823051776',
  integrations: [],
  environment: import.meta.env.VITE_APP_STAGE,
  enabled: shouldEnableSentry(),
  autoSessionTracking: true
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
