import React from 'react';
import HtmlReactParser from 'html-react-parser';

function CancelledSession(props) {
  const { student_name, session_type, participant_type, cancellation_text } =
    props?.cancelledSessionPopupData;
  const getCancellationText = HtmlReactParser(cancellation_text);

  const modal = React.useRef(null);

  const on_close_modal = (e) => {
    if (modal && modal.current && !modal.current.contains(e.target)) {
      props.cancelledSessionPopupclose();
    }
  };

  return (
    <>
      {/* {isLoading ?
        <Loader /> : */}
      <div className="relative inset-0 z-index-max">
        <div
          onClick={(e) => on_close_modal(e)}
          className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center"
        >
          <div ref={modal} className="z-50 bg-white rounded-lg p-10 zm:w-492px relative">
            <div className="mb-0">
              <div className="flex justify-center flex-col items-center">
                <div className="w-full">
                  <div className="text-center">
                    <div className="text-2xl font-medium">
                      {student_name}{' '}
                      {session_type === 'YOGA' ? (
                        <span className="capitalize">({participant_type})</span>
                      ) : null}
                    </div>
                    <div className="text-mytLabelRed text-13.5 font-medium mt-5px leading-5">
                      {getCancellationText}
                    </div>
                    <button
                      className="btn btn-primary h-38px text-13.5 w-150px mt-25px"
                      onClick={props.cancelledSessionPopupclose}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </>
  );
}

export default CancelledSession;
