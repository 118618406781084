import React, { Component } from 'react'

interface IState {
}
interface IMapStateToProps {
  sessionScheduleCount: number;
}

interface IMapDispatchToProps {
}

type Next24CardProps = IMapStateToProps & IMapDispatchToProps;
export class Next24Card extends React.Component<Next24CardProps, IState> {

  render() {
    return (
      <div style={{ backgroundColor: '#FFF1E6', borderColor: '#F2DBC7' }} className='dashboardContainerWidth rounded-lg  md:py-15px md:px-20px zm:px-10px zm:py-7px flex flex-row justify-start md:items-center mb-30px border'>
        <div className='flex justify-center items-center zm:w-38px zm:h-38px md:w-42px md:h-42px rounded-full bg-mytorange-lite2 bg-opacity-30'>
          <div style={{ color: '#EE731B',}} className='font-bold lg:text-24px zm:text-20px'>{this.props?.sessionScheduleCount}</div>
        </div>
        <div className='zm:text-black lg:text-notifyTextColor zm:text-13px md:text-16px leading-snug font-medium zm:ml-8px md:ml-15px flex items-center zm:leading-16px lg:leading-20px'>
          Sessions scheduled in the next 24 hours
        </div>
      </div>
    )
  }
}

export default Next24Card