import React from 'react';
import './style.css';

const CheckBox = ({ isChecked, ...props }) => {
    return (
        <>
            <div>
                <label className='flex items-center cursor-pointer checkBoxHoverState'>
                    <div className='checkbox1_on_1_page'>
                        <input
                            type='checkbox'
                            id={props?.id}
                            value={props?.value}
                            name={props?.name}
                            checked={isChecked}
                            onChange={props.onChange}
                            readOnly
                            {...props}
                        />
                        <label htmlFor={props?.forCheck}></label>
                    </div>
                    <label
                        htmlFor={props?.id}
                        className={`whitespace-nowrap pointer-events-none font-inter md:text-13.5 zm:text-sm font-medium cursor-pointer ${
                            props?.className
                        } ${isChecked === true ? 'text-primary' : 'text-mytLabel'}`}
                    >
                        {props?.name}
                    </label>
                </label>
            </div>
        </>
    );
};

export default CheckBox;
