import React from 'react';
import { address_proof_list } from '../../../constants';
import CustomDropDown from '../../util_components/custom_dropdown';
import CustomFileUpload from '../../util_components/custom_file_upload';

export const render_documents = (details) => {
	return (
		<div>
			<div className="profile-basic-detail-values">
				<div className="profile-basic-detail-index">Resume</div>
				<div className="profile-basic-detail-value">
					{details.resume_document_url === '' || typeof details.resume_document_url === 'undefined' ? (
						details.teacher_edit_auditing.resume_document_url === '' ||
						typeof details.teacher_edit_auditing.resume_document_url === 'undefined' ? (
							'- -'
						) : (
              <p className="custom-file-review">Your file is under review</p>
						)
					) : (
						<a target="_blank" rel="noopener noreferrer" href={details.resume_document_url}>
							View Resume
						</a>
					)}
				</div>
			</div>
			<div className="profile-basic-detail-values">
				<div className="profile-basic-detail-index">Intro Video</div>
				<div className="profile-basic-detail-value">
					{details.video_url === '' || typeof details.video_url === 'undefined' ? (
						details.teacher_edit_auditing.video_url === '' ||
						typeof details.teacher_edit_auditing.video_url === 'undefined' ? (
							'- -'
						) : (
              <p className="custom-file-review">Your file is under review</p>
						)
					) : (
						<a target="_blank" href={details.video_url} rel="noopener noreferrer">
							View intro
						</a>
					)}
				</div>
			</div>
		</div>
	);
};

export const EditDocuments = (props) => {
	if (props.dropdown) {
		return (
			<div className="profile-basic-detail-values">
				<div className="profile-basic-detail-index other-detail-index">{props.label}</div>
				<div className="profile-basic-detail-value">
					<CustomDropDown
						value={props.details[props.component]}
						handleChange={(val) => props.handleFileChange('dropdown', props.component, val)}
						drop_down_list={address_proof_list}
						component={props.component}
					/>
				</div>
			</div>
		);
	}
	return (
		<div className="profile-basic-detail-values">
			<div className="profile-basic-detail-index other-detail-index">{props.label}:</div>
			<div className="profile-basic-detail-value">
				<CustomFileUpload
					type="video"
					handleFileChange={(val) => props.handleFileChange(props.component, props.upload_url, val)}
					component={props.component}
          file_url={props.file}
          teacher_edit_auditing={props.details.teacher_edit_auditing}
				/>
			</div>
		</div>
	);
};
