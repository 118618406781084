import axios from "axios";
import urls from "../../urls";

export const verify_email_api = payload =>
  axios({
    method: "post",
    url: urls.verify_email_account,
    data: {
      verification_token: payload
    }
  })
    .then(response => {
        return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });

export const check_email_verification = payload =>
  axios({
    method: "post",
    url: urls.check_email_verification,
    data: {
      signed_up_id: payload
    }
  })
    .then(response => {
        return Promise.resolve(response);
    })
    .catch(error => {
        return Promise.reject(error);
    });
