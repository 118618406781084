import * as t from '../action_types';

export const get_my_availability = () => ({
	type: t.get_my_availability,
	payload: null
});

export const set_availability_variable = (key, payload) => ({
	type: t.set_availability_variable,
	payload: {
		key,
		payload
	}
});

export const add_availability = payload => ({
	type: t.add_availability,
	payload: payload
});

export const edit_availability = payload => ({
	type: t.edit_availability,
	payload: payload
});

export const delete_availability = payload => ({
	type: t.delete_availability,
	payload: payload
});

export const getMySessionV1 = payload => {
  return {
    type: t.my_session_v1,
    payload,
  };
};

export const getMySessionData = payload => {
  return {
    type: t.my_session_data_v1,
    payload,
  };
};



export const handle_error_from_api = (payload) => {
  return {
    type: t.handle_error_from_api,
    payload,
  };
};
