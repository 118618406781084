import moment from 'moment-timezone';
import React, { useEffect } from 'react'
import Modal from './CommonComponents/Modal'
import { CrossIcon, OrangeTick } from './svg'

function ConsultBookedPopUp(props) {
    function getOrdinalIndicator(day) {
        if (day >= 11 && day <= 13) {
          return 'th';
        }
        const lastDigit = day % 10;
        switch (lastDigit) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      }
      
      const epochToSpecificDateTime = (epochTime, targetTimeZone) => {
        const formattedDate = moment(epochTime).tz(targetTimeZone).format('dddd DD');
        const ordinalIndicator = getOrdinalIndicator(parseInt(formattedDate.split(' ')[1]));
        return moment(epochTime).tz(targetTimeZone).format(`[${formattedDate}${ordinalIndicator}] MMM [at] hh:mm A z`);
      };
      
      // Example usage
      const epochTime = props?.data?.epoch_start_time; // Replace this with your epoch time value
      const targetTimeZone = 'America/Los_Angeles'; // PST/PDT time zone
      
      // Convert epoch time to the target date and time in the specified time zone
      const formattedDateTime = epochToSpecificDateTime(epochTime, targetTimeZone);
    
    return (
        <Modal onClose={props.onClose}>
            <div className='flex flex-col justify-center items-center zm:px-25px md:px-10 pb-10 pt-1.5'>
                <OrangeTick />
                <div className='font-semibold text-19px leading-22px text-greyText mt-5 text-center'>You have booked consult session</div>
                <div className='font-normal text-14.5px leading-26px text-mytgrey-lite mt-5px'>for {props?.data?.student_name || ''} with you on</div>
                <div className='font-medium text-14.5px leading-26px text-blackmt-5px '>{formattedDateTime || ''}</div>
                <div className='font-normal text-14.5px leading-26px text-mytgrey-lite mt-5 text-center'> We just sent an email to {props?.data?.student_name || ''} with links to join the consult.</div>
            </div>
        </Modal>
    )
}

export default ConsultBookedPopUp