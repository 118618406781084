export const handle_error_from_api = 'HANDLE_ERROR_FROM_API';
export const getTeacherList = 'get_teacher_list';
export const set_home_variable = 'SET_HOME_VARIABLE';
export const get_notifications = 'GET_NOTIFICATIONS';
export const get_teacher_time_slots = 'GET_TEACHER_TIME_SLOTS';
export const update_notification_read_status = 'UPDATE_NOTIFICATION_READ_STATUS';
export const update_session_time_duration = 'UPDATE_SESSION_TIME_DURATION';
export const cancel_sessions = 'CANCEL_SESSIONS';
export const replacement_session = 'REPLACEMENT_SESSION';
export const get_subscription_data = 'GET_SUBSCRIPTION_DATA';

//action typed for loading reducer
export const set_loading_variable = 'SET_LOADING_VARIABLE';
