import React, { Component } from 'react';
import { virbant_community_images } from '../../../constants';
import { analytics_track, get_time_taken } from '../../../util_functions';
import PropTypes from 'prop-types';
import './popup_image_viewer.css';

export default class PopupImgViewer extends Component {
	state = {
		image_index: 0
	};

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyPress, false);
		this.setState({ image_index: this.props.image_index });
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyPress, false);
	}

	track_fn = name => {
		const options = {
			referrer_component: 'home',
			plan: 'guest',
			time_since_page_load: get_time_taken(this.props.start_time)
		};
		analytics_track(name, options);
	};

	handleKeyPress = e => {
		const length = virbant_community_images.length;
		if (e.keyCode === 39) {
			if (this.state.image_index < length - 1) {
				this.setState({ image_index: this.state.image_index + 1 });
				this.track_fn('next image click');
			}
		} else if (e.keyCode === 37) {
			if (this.state.image_index !== 0) {
				this.setState({ image_index: this.state.image_index - 1 });
				this.track_fn('previous image click');
			}
		} else if (e.keyCode === 27) {
			this.props.popup_close();
			this.track_fn('close image viewer');
		}
	};

	popup_ref = React.createRef();
	stop_propagation = e => {
		e.stopPropagation();
	};

	close_popup = () => {
		this.props.popup_close();
		this.track_fn('close image viewer');
	};

	render() {
		return (
			<div className="testimonial-popup-blur-container" onClick={this.props.popup_close}>
				<div className="testimonial-popup-container" onClick={this.stop_propagation} ref={this.popup_ref}>
					<div className="testimonial-popup-image">
						<div className="imginside">
							<img width="100%" src={virbant_community_images[this.state.image_index].imageUrlOriginal} alt="pic" />
							<div className="testimonial-popup-close" onClick={this.close_popup}>
								<div className="csin">
									<div className="circlein" />
									<img src="https://images.myyogateacher.com/Teacher-Page/ic_close.png" alt="close" />
								</div>
							</div>
						</div>

						{this.state.image_index !== 0 ? (
							<button
								className="w3-button w3-display-left"
								onClick={() => {
									this.setState({ image_index: this.state.image_index - 1 });
									this.track_fn('previous image click');
								}}
							>
								<div className="circlein" />
								<img src="https://images.myyogateacher.com/Teacher-Page/Arrow-Image-Popup.png" />
							</button>
						) : null}
						{this.state.image_index !== virbant_community_images.length - 1 ? (
							<button
								className="w3-button w3-display-right"
								onClick={() => {
									this.setState({ image_index: this.state.image_index + 1 });
									this.track_fn('next image click');
								}}
							>
								<div className="circlein" />
								<img src="https://images.myyogateacher.com/Teacher-Page/Arrow-Image-Popup.png" />
							</button>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

PopupImgViewer.propTypes = {
	vibrantImages: PropTypes.object,
	popup_close: PropTypes.func
};

PopupImgViewer.defaultProps = {
	vibrantImages: virbant_community_images[0],
	popup_close: () => null
};
