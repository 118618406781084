import React from 'react';
import '../../assets/style/style.css';
import { connect } from 'react-redux';
import { withLayout } from '../../components/componentWrapper';

import Footer from '../../components/footer';
import Header from '../../components/header';
import * as actions from '../../../../redux/action_creators';
import { withRouter } from '../../../../utils/router';
import { sendEventToClarity } from '../../../../util_functions';

class Home extends React.Component {
  state = {
    teacher_video_one: false,
    teacher_video_two: false,
    teacher_video_three: false
  };

  handleSignup = () => {
    this.props.router.navigate('/signup');
  };

  componentDidMount() {
    sendEventToClarity();
    const urlString = window.location.href;
    const url = new URL(urlString);
    // TODO: migrate post ses-notification repo is migrated
    // try {
    // 	if (!getCookie('cms_user_confirmation') || getCookie('cms_user_confirmation') !== 'true') {
    // 		const aws_confirmation_uuid = url.searchParams.get('aws_confirmation_uuid');
    // 		const aws_response_url = `${urls.email_primary_response}?id=${aws_confirmation_uuid}`;
    // 		get_api(aws_response_url);
    // 	}
    // } catch {}

    const token = url.searchParams.get('verification_token');
    !token ? '' : this.props.verify_email_token(token);
  }

  componentDidUpdate() {
    // for first video
    if (
      this.state.teacher_video_one == true ||
      this.state.teacher_video_two == true ||
      this.state.teacher_video_three == true
    ) {
      // window.onscroll = function () {
      //   window.scrollTo(0, 0);
      // };
      const x = window.scrollX;
      const y = window.scrollY;
      window.onscroll = function () {
        window.scrollTo(x, y);
      };
    } else if (
      this.state.teacher_video_one == false ||
      this.state.teacher_video_two == false ||
      this.state.teacher_video_three == false
    ) {
      window.onscroll = function () {};
    }
  }

  render() {
    return (
      <>
        <Header />

        {this.state.teacher_video_one ? (
          <div className="relative inset-0 z-50">
              <div
                className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center"
                onClick={() => {
                  this.setState({
                    teacher_video_one: false
                  });
                }}
              >
                <div className="w-full h-full">
                  <div className="w-full h-full">
                    <div className="w-full h-full flex justify-center items-center focus:outline-none">
                      <div className="relative m-auto flex justify-center items-center">
                        <iframe
                          src="https://player.vimeo.com/video/565971543?&autoplay=1"
                          frameBorder="0"
                          allow="fullscreen"
                          allowfullscreen
                          className="videoPopUp focus:outline-none"
                         />
                        <div
                          className="absolute -top-6 lg:-right-6 md:-right-5 z-50 cursor-pointer hover:opacity-70 zm:right-0"
                          onClick={() => {
                            this.setState({
                              teacher_video_one: false
                            });
                          }}
                        >
                          <svg
                            width="52"
                            height="52"
                            viewBox="0 0 52 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_d)">
                              <rect
                                opacity="0.95"
                                x="4"
                                width="44"
                                height="44"
                                rx="22"
                                fill="#000000"
                                // fill-opacity="0.8"
                              />
                              <path
                                d="M31.5 16.5L20.5 27.5"
                                stroke="white"
                                strokeWidth="2.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.5 16.5L31.5 27.5"
                                stroke="white"
                                strokeWidth="2.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <filter
                                id="filter0_d"
                                x="0.333333"
                                y="0"
                                width="51.3333"
                                height="51.3333"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                />
                                <feOffset dy="3.66667" />
                                <feGaussianBlur stdDeviation="1.83333" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ) : null}

        {this.state.teacher_video_two ? (
          <div className="relative inset-0 z-50">
              <div
                className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center"
                onClick={() => {
                  this.setState({
                    teacher_video_two: false
                  });
                }}
              >
                <div className="w-full h-full">
                  <div className="w-full h-full">
                    <div className="w-full h-full flex justify-center items-center focus:outline-none">
                      <div className="relative m-auto flex justify-center items-center">
                        <iframe
                          src="https://player.vimeo.com/video/565971691?&autoplay=1"
                          frameBorder="0"
                          allow="fullscreen"
                          allowfullscreen
                          className="videoPopUp focus:outline-none"
                         />
                        <div
                          className="absolute -top-6 lg:-right-6 md:-right-5 z-50 cursor-pointer hover:opacity-70 zm:right-0"
                          onClick={() => {
                            this.setState({
                              teacher_video_two: false
                            });
                          }}
                        >
                          <svg
                            width="52"
                            height="52"
                            viewBox="0 0 52 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_d)">
                              <rect
                                opacity="0.95"
                                x="4"
                                width="44"
                                height="44"
                                rx="22"
                                fill="#000000"
                                // fill-opacity="0.8"
                              />
                              <path
                                d="M31.5 16.5L20.5 27.5"
                                stroke="white"
                                strokeWidth="2.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.5 16.5L31.5 27.5"
                                stroke="white"
                                strokeWidth="2.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <filter
                                id="filter0_d"
                                x="0.333333"
                                y="0"
                                width="51.3333"
                                height="51.3333"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                />
                                <feOffset dy="3.66667" />
                                <feGaussianBlur stdDeviation="1.83333" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ) : null}
        {this.state.teacher_video_three ? (
          <div className="relative inset-0 z-50">
              <div
                className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 flex items-center justify-center"
                onClick={() => {
                  this.setState({
                    teacher_video_three: false
                  });
                }}
              >
                <div className="w-full h-full">
                  <div className="w-full h-full">
                    <div className="w-full h-full flex justify-center items-center focus:outline-none">
                      <div className="relative m-auto flex justify-center items-center">
                        <iframe
                          src="https://player.vimeo.com/video/564105825?&autoplay=1"
                          frameBorder="0"
                          allow="fullscreen"
                          allowfullscreen
                          className="videoPopUp focus:outline-none"
                         />
                        <div
                          className="absolute -top-6 lg:-right-6 md:-right-5 z-50 cursor-pointer hover:opacity-70 zm:right-0"
                          onClick={() => {
                            this.setState({
                              teacher_video_three: false
                            });
                          }}
                        >
                          <svg
                            width="52"
                            height="52"
                            viewBox="0 0 52 52"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_d)">
                              <rect
                                opacity="0.95"
                                x="4"
                                width="44"
                                height="44"
                                rx="22"
                                fill="#000000"
                                // fill-opacity="0.8"
                              />
                              <path
                                d="M31.5 16.5L20.5 27.5"
                                stroke="white"
                                strokeWidth="2.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M20.5 16.5L31.5 27.5"
                                stroke="white"
                                strokeWidth="2.75"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <filter
                                id="filter0_d"
                                x="0.333333"
                                y="0"
                                width="51.3333"
                                height="51.3333"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                />
                                <feOffset dy="3.66667" />
                                <feGaussianBlur stdDeviation="1.83333" />
                                <feColorMatrix
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                />
                                <feBlend
                                  mode="normal"
                                  in2="BackgroundImageFix"
                                  result="effect1_dropShadow"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_dropShadow"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ) : null}

        {/* --------------------- */}

        <div>
          <div className="topContainer topContentContainerMarginHome xl:mb-32">
            <div className="baseContainer zm:pr-0 lg:pr-6">
              <div className="sm:max-w-xl lg:w-full xl:pt-32 xl:pb-24 lg:mb-12 lg:pt-12 md:mb-6 md:pt-6 zm:pr-6 lg:pr-0">
                <h1 className="mainHeadingHome">
                  Teach Yoga & <br className="" />{' '}
                  {/* <span className="border-b-4 border-mytorange-lite"> */}
                  <span className="borderBottom">Earn More</span> Than
                  <br className="" />
                  <span className="whitespace-nowrap">IT Professionals!</span>
                </h1>
                <p className="contentDescription lg:w-full sm:w-3/6 sm:leading-relaxed lg:leading-none">
                  Reach thousands of students without leaving your home
                </p>
                <a
                  className="btn btn-mytPurple py-4 px-6 mt-5"
                  href="https://teacher.myyogateacher.com/signup"
                >
                  Find out more{' '}
                </a>

                <div className="mt-8 sm:mb-0 zm:mb-8">
                  <div className="inline-flex items-center justify-start px-5 py-2 border-transparent lg:text-15 zm:text-14.5 font-black font-avenir md:rounded-full md:shadow-sm md:bg-mytTeacherBgGreen zm:bg-white zm:rounded-none zm:shadow-none">
                    {' '}
                    <svg
                      width="30"
                      height="26"
                      viewBox="0 0 30 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.68 6.79745C24.4872 2.94542 19.0672 0.700253 13.3787 0.459044C13.3512 0.457858 13.0633 0.457031 13.0633 0.457031H6.72989C6.63458 0.457031 6.54317 0.494894 6.47578 0.56229C6.40838 0.629686 6.37052 0.721094 6.37052 0.816406C6.37052 0.911718 6.40838 1.00313 6.47578 1.07052C6.54317 1.13792 6.63458 1.17578 6.72989 1.17578H11.2129C11.3082 1.17578 11.3996 1.21364 11.467 1.28104C11.5344 1.34844 11.5723 1.43984 11.5723 1.53516C11.5723 1.63047 11.5344 1.72188 11.467 1.78927C11.3996 1.85667 11.3082 1.89453 11.2129 1.89453H4.78708C4.69177 1.89453 4.60036 1.93239 4.53296 1.99979C4.46556 2.06719 4.4277 2.15859 4.4277 2.25391C4.4277 2.34922 4.46556 2.44063 4.53296 2.50802C4.60036 2.57542 4.69177 2.61328 4.78708 2.61328H10.4087C10.4678 2.61328 10.526 2.62785 10.5781 2.65571C10.6302 2.68357 10.6747 2.72386 10.7075 2.773C10.7404 2.82214 10.7606 2.87862 10.7664 2.93743C10.7722 2.99625 10.7634 3.05559 10.7407 3.11019L7.44498 11.0668C6.08478 14.3505 8.49949 19.7666 10.2643 23.0186C10.2852 23.0569 10.2958 23.1 10.295 23.1436C10.2942 23.1872 10.282 23.2298 10.2597 23.2673C10.2374 23.3048 10.2057 23.3358 10.1678 23.3573C10.1299 23.3789 10.087 23.3901 10.0434 23.39H4.42792C4.33261 23.39 4.2412 23.4279 4.1738 23.4953C4.1064 23.5627 4.06854 23.6541 4.06854 23.7494C4.06854 23.8447 4.1064 23.9361 4.1738 24.0035C4.2412 24.0709 4.33261 24.1088 4.42792 24.1088H10.7994C10.8947 24.1088 10.9861 24.1466 11.0535 24.214C11.1209 24.2814 11.1588 24.3728 11.1588 24.4681C11.1588 24.5634 11.1209 24.6549 11.0535 24.7222C10.9861 24.7896 10.8947 24.8275 10.7994 24.8275H6.02537C5.93006 24.8275 5.83865 24.8654 5.77126 24.9328C5.70386 25.0002 5.666 25.0916 5.666 25.1869C5.666 25.2822 5.70386 25.3736 5.77126 25.441C5.83865 25.5084 5.93006 25.5463 6.02537 25.5463H12.5347C12.6521 25.5439 12.769 25.53 12.8838 25.5049C15.7304 24.8081 23.7032 22.5264 25.3738 18.4932L29.2171 9.21489C29.3865 8.80553 29.4258 8.35396 29.3298 7.92148C29.2337 7.489 29.0068 7.09656 28.68 6.79745Z"
                        fill="#30CA77"
                      />
                      <path
                        d="M27.7165 7.86445C25.7723 6.0754 23.5301 4.64023 21.0914 3.62382L21.0387 3.60201C18.5808 2.58309 15.9626 2.00524 13.3042 1.89498C13.1579 1.8898 13.0135 1.92959 12.8905 2.00899C12.7674 2.08839 12.6717 2.20359 12.6162 2.33906L8.93258 11.2317C8.89177 11.3301 8.82269 11.4143 8.73408 11.4735C8.64546 11.5327 8.54128 11.5643 8.43471 11.5643H3.4933C3.39799 11.5643 3.30658 11.6022 3.23918 11.6696C3.17179 11.737 3.13392 11.8284 3.13392 11.9237C3.13392 12.019 3.17179 12.1104 3.23918 12.1778C3.30658 12.2452 3.39799 12.2831 3.4933 12.2831H8.28715C8.38246 12.2831 8.47387 12.3209 8.54126 12.3883C8.60866 12.4557 8.64652 12.5471 8.64652 12.6424C8.64652 12.7378 8.60866 12.8292 8.54126 12.8966C8.47387 12.964 8.38246 13.0018 8.28715 13.0018H2.77466C2.67935 13.0018 2.58794 13.0397 2.52054 13.1071C2.45315 13.1745 2.41528 13.2659 2.41528 13.3612C2.41528 13.4565 2.45315 13.5479 2.52054 13.6153C2.58794 13.6827 2.67935 13.7206 2.77466 13.7206H8.16259C8.25287 13.721 8.33968 13.7554 8.40588 13.8168C8.47207 13.8781 8.51281 13.9621 8.52006 14.0521C8.77838 16.8293 10.4313 20.5294 12.2714 23.6495C12.3501 23.7827 12.4699 23.8868 12.6129 23.946C12.7558 24.0052 12.9141 24.0164 13.064 23.9778C17.7502 22.7689 22.9484 20.593 24.046 17.9432L27.8891 8.66514C27.9456 8.53004 27.9594 8.38085 27.9285 8.23769C27.8976 8.09452 27.8236 7.96426 27.7165 7.86445Z"
                        fill="#6AE3A2"
                      />
                      <path
                        d="M23.038 11.3408C22.89 11.1333 22.6752 10.9831 22.4295 10.9152C22.1839 10.8473 21.9224 10.8659 21.6888 10.9678L16.7521 13.0127L15.7894 10.6885C15.7087 10.4956 15.5738 10.3303 15.401 10.2125C15.2282 10.0948 15.0249 10.0297 14.8158 10.0251L10.5707 10.0234C10.4754 10.0234 10.384 10.0613 10.3166 10.1287C10.2492 10.1961 10.2113 10.2875 10.2113 10.3828C10.2113 10.4781 10.2492 10.5695 10.3166 10.6369C10.384 10.7043 10.4754 10.7422 10.5707 10.7422H13.3609C13.4078 10.7418 13.4542 10.7507 13.4976 10.7684C13.5409 10.7861 13.5804 10.8123 13.6135 10.8453C13.6467 10.8784 13.673 10.9177 13.6909 10.961C13.7088 11.0043 13.7179 11.0507 13.7177 11.0975C13.7156 11.2402 13.7427 11.3819 13.7973 11.5137L15.1725 14.8339C15.2826 15.0975 15.4926 15.3069 15.7565 15.4162C16.0205 15.5255 16.317 15.526 16.5813 15.4174L22.5577 12.9419C22.7052 12.8803 22.8372 12.7867 22.9443 12.668C23.0513 12.5492 23.1306 12.4081 23.1766 12.255C23.2225 12.1018 23.2339 11.9404 23.21 11.7823C23.186 11.6242 23.1273 11.4734 23.038 11.3408Z"
                        fill="white"
                      />
                      <path
                        d="M2.05597 11.5625H0.977844C0.779367 11.5625 0.618469 11.7234 0.618469 11.9219C0.618469 12.1204 0.779367 12.2812 0.977844 12.2812H2.05597C2.25445 12.2812 2.41534 12.1204 2.41534 11.9219C2.41534 11.7234 2.25445 11.5625 2.05597 11.5625Z"
                        fill="#6AE3A2"
                      />
                      <path
                        d="M3.34956 2.61328C3.54804 2.61328 3.70894 2.45238 3.70894 2.25391C3.70894 2.05543 3.54804 1.89453 3.34956 1.89453C3.15109 1.89453 2.99019 2.05543 2.99019 2.25391C2.99019 2.45238 3.15109 2.61328 3.34956 2.61328Z"
                        fill="#30CA77"
                      />
                    </svg>
                    <span className="ml-3 text-mytTeacherTextGreen">
                      Join more than 150 Happy Teachers
                    </span>
                    &nbsp;
                  </div>
                </div>
              </div>
              <div className="sm:absolute sm:inset-y-0 sm:right-0 md:w-5/12 sm:w-3/6 hero-img">
                <img
                  className="2xl:h-5/6 2xl:sticky 2xl:right-0"
                  src="https://images.myyogateacher.com/web_images/hero-image-home.jpg"
                  alt="header-img"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="topContainer zm:pt-0 md:pt-8 lg:pt-0 lg:pb-32 zm:pb-10">
            <div className="baseContainer zm:pr-0 lg:pr-6">
              <div className="flex justify-between items-center flex-wrap">
                <div className="sm:max-w-lg lg:w-full zm:pr-6 md:pr-52 lg:pr-24 xl:pr-0 lg:pt-16">
                  <h2 className="contentHeading">Benefits</h2>
                  <p className="mt-4 contentDescriptionBottom leading-normal">
                    Why Join MyYogaTeacher’s Teacher Community?
                  </p>
                  <p className="mt-4 contentDescriptionBottom leading-normal">
                    Forget about business, marketing, studio-rent, or getting new clients — we take
                    care of that for you. So you can focus on what you love, helping people live
                    happier and healthier lives while teaching yoga!
                  </p>
                  <p className="mt-4 contentDescriptionBottom leading-normal">
                    You get to teach group classes online, 1-on-1 sessions, and workshops from the
                    comfort of your own home.
                  </p>

                  <h4 className="listHeading zm:text-xl text-left mt-4">
                    Build Your Yoga Following
                  </h4>
                  <ul className="list-disc pl-7 contentDescriptionBottomList">
                    <li className="my-2">
                      Meet a community of passionate and experienced yoga teachers
                    </li>
                    <li className="my-2">Learn new skills and make yoga your career</li>
                    <li className="my-2">Earn more than your IT friends!</li>
                    <li className="my-2">
                      Top MyYogaTeacher teachers make more than 1 lakh per month
                    </li>
                  </ul>
                </div>
                {/* <div className='sm:absolute sm:inset-y-0 sm:right-0 md:w-6/12 sm:w-3/6 lg:p-12'> */}
                <div className="md:absolute sm:inset-y-0 sm:right-0 md:w-6/12 sm:w-9/12 zm:w-full p-5 zm:pr-8 md:pr-6 sm:pr-0">
                  <img
                    alt="yogi"
                    className="2xl:h-5/6 xl:h-3/4 2xl:absolute left-0 2xl:right-2/4 2xl:top-20"
                    src="https://images.myyogateacher.com/web_images/benefites.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* video modal */}

        <div className="topContainer zm:pt-20 md:pt-8 lg:pt-24 lg:pb-64 md:pb-40 zm:pb-10">
          <div className="baseContainer">
            <div className="w-full text-center">
              <h2 className="contentHeading mb-2">What our teachers</h2>
              <h2 className="contentHeading">are saying</h2>
            </div>
            <div className="pt-10">
              <div className="grid lg:grid-cols-3 lg:grid-cols-1 gap-8">
                <div
                  className="w-full lg:h-48 sm:h-72 zm:h-60 bg-center bg-cover relative rounded-md cursor-pointer"
                  style={{
                    backgroundImage: `url(
                        "https://images.myyogateacher.com/web_images/teacher-thumbnail-1.jpg"
                      )`
                  }}
                  onClick={() => {
                    this.setState({
                      teacher_video_one: true
                    });
                  }}
                >
                  <div className="w-full h-full flex justify-center items-center absolute bottom-3 px-8">
                    {/* <div className="w-12 h-12 border-2 border-primary_opacity rounded-full bg-primary inline-flex flex-wrap justify-center items-center"> */}
                    <svg
                      width="54"
                      height="54"
                      viewBox="0 0 54 54"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="6" y="6" width="42" height="42" rx="21" fill="#F86624" />
                      <rect
                        x="3.18883"
                        y="3.18883"
                        width="47.6223"
                        height="47.6223"
                        rx="23.8112"
                        stroke="#F86624"
                        strokeOpacity="0.15"
                        strokeWidth="5.62233"
                      />
                      <path
                        d="M35.6477 26.9998C35.6471 27.2279 35.5832 27.4514 35.4633 27.6455C35.3434 27.8395 35.1721 27.9966 34.9683 28.0992L22.6154 34.2757C22.4433 34.3639 22.2529 34.4104 22.0595 34.4116C21.8282 34.4123 21.6014 34.3481 21.4048 34.2263C21.2268 34.115 21.0801 33.9602 20.9785 33.7765C20.8769 33.5928 20.8238 33.3862 20.8242 33.1763V20.8234C20.8249 20.6128 20.8793 20.4059 20.9824 20.2223C21.0855 20.0387 21.2338 19.8844 21.4133 19.7743C21.5928 19.6641 21.7974 19.6017 22.0078 19.5929C22.2182 19.5841 22.4273 19.6292 22.6154 19.7239L34.9683 25.9004C35.1721 26.0031 35.3434 26.1601 35.4633 26.3542C35.5832 26.5482 35.6471 26.7717 35.6477 26.9998Z"
                        fill="white"
                      />
                    </svg>
                    {/* </div> */}
                  </div>
                  <div className="lg:text-base zm:text-lg text-white zm:font-black lg:font-bold absolute bottom-2 left-3 zm:font-avenir lg:font-inter">
                    Abhishek Sharma
                  </div>
                </div>
                <div
                  className="w-full lg:h-48 sm:h-72 zm:h-60 bg-center bg-cover relative rounded-md cursor-pointer"
                  style={{
                    backgroundImage: `url(
                        "https://images.myyogateacher.com/web_images/teacher-thumbnail-3.jpg"
                      )`
                  }}
                  onClick={() => {
                    this.setState({
                      teacher_video_two: true
                    });
                  }}
                >
                  <div className="w-full h-full flex justify-center items-center absolute bottom-3 px-8">
                    {/* <div className="w-12 h-12 border-2 border-primary_opacity rounded-full bg-primary inline-flex flex-wrap justify-center items-center"> */}
                    <svg
                      width="54"
                      height="54"
                      viewBox="0 0 54 54"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="6" y="6" width="42" height="42" rx="21" fill="#F86624" />
                      <rect
                        x="3.18883"
                        y="3.18883"
                        width="47.6223"
                        height="47.6223"
                        rx="23.8112"
                        stroke="#F86624"
                        strokeOpacity="0.15"
                        strokeWidth="5.62233"
                      />
                      <path
                        d="M35.6477 26.9998C35.6471 27.2279 35.5832 27.4514 35.4633 27.6455C35.3434 27.8395 35.1721 27.9966 34.9683 28.0992L22.6154 34.2757C22.4433 34.3639 22.2529 34.4104 22.0595 34.4116C21.8282 34.4123 21.6014 34.3481 21.4048 34.2263C21.2268 34.115 21.0801 33.9602 20.9785 33.7765C20.8769 33.5928 20.8238 33.3862 20.8242 33.1763V20.8234C20.8249 20.6128 20.8793 20.4059 20.9824 20.2223C21.0855 20.0387 21.2338 19.8844 21.4133 19.7743C21.5928 19.6641 21.7974 19.6017 22.0078 19.5929C22.2182 19.5841 22.4273 19.6292 22.6154 19.7239L34.9683 25.9004C35.1721 26.0031 35.3434 26.1601 35.4633 26.3542C35.5832 26.5482 35.6471 26.7717 35.6477 26.9998Z"
                        fill="white"
                      />
                    </svg>
                    {/* </div> */}
                  </div>
                  <div className="lg:text-base zm:text-lg text-white zm:font-black lg:font-bold absolute bottom-2 left-3 zm:font-avenir lg:font-inter">
                    Priyanka Nair
                  </div>
                </div>
                <div
                  className="w-full lg:h-48 sm:h-72 zm:h-60 bg-center bg-cover relative rounded-md cursor-pointer"
                  style={{
                    backgroundImage: `url(
                        "https://images.myyogateacher.com/web_images/teacher-thumbnail-2.jpg"
                      )`
                  }}
                  onClick={() => {
                    this.setState({
                      teacher_video_three: true
                    });
                  }}
                >
                  <div className="w-full h-full flex justify-center items-center absolute bottom-3 px-8">
                    {/* <div className="w-12 h-12 border-2 border-primary_opacity rounded-full bg-primary inline-flex flex-wrap justify-center items-center"> */}
                    <svg
                      width="54"
                      height="54"
                      viewBox="0 0 54 54"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="6" y="6" width="42" height="42" rx="21" fill="#F86624" />
                      <rect
                        x="3.18883"
                        y="3.18883"
                        width="47.6223"
                        height="47.6223"
                        rx="23.8112"
                        stroke="#F86624"
                        strokeOpacity="0.15"
                        strokeWidth="5.62233"
                      />
                      <path
                        d="M35.6477 26.9998C35.6471 27.2279 35.5832 27.4514 35.4633 27.6455C35.3434 27.8395 35.1721 27.9966 34.9683 28.0992L22.6154 34.2757C22.4433 34.3639 22.2529 34.4104 22.0595 34.4116C21.8282 34.4123 21.6014 34.3481 21.4048 34.2263C21.2268 34.115 21.0801 33.9602 20.9785 33.7765C20.8769 33.5928 20.8238 33.3862 20.8242 33.1763V20.8234C20.8249 20.6128 20.8793 20.4059 20.9824 20.2223C21.0855 20.0387 21.2338 19.8844 21.4133 19.7743C21.5928 19.6641 21.7974 19.6017 22.0078 19.5929C22.2182 19.5841 22.4273 19.6292 22.6154 19.7239L34.9683 25.9004C35.1721 26.0031 35.3434 26.1601 35.4633 26.3542C35.5832 26.5482 35.6471 26.7717 35.6477 26.9998Z"
                        fill="white"
                      />
                    </svg>
                    {/* </div> */}
                  </div>
                  <div className="lg:text-base zm:text-lg text-white zm:font-black lg:font-bold absolute bottom-2 left-3 zm:font-avenir lg:font-inter">
                    Bharath Ram
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----------- */}

        <div className="topContainer zm:pt-20 md:pt-8 lg:pt-24 lg:pb-32 zm:pb-10">
          <div className="baseContainer">
            <div className="flex md:flex-row-reverse justify-between items-center flex-wrap">
              <div className="sm:max-w-lg lg:w-full md:pl-60 lg:pl-24 xl:pl-0 xl:pt-32 2xl:pt-44">
                <h2 className="contentHeading">Our Mission</h2>
                <p className="mt-4 contentDescriptionBottom leading-snug">
                  We are on a mission to improve the physical and mental well-being of the entire
                  planet and empower yoga teachers to make a sustainable living while doing what
                  they love.{' '}
                </p>
              </div>
              <div className="md:absolute sm:inset-y-0 sm:left-0 md:w-6/12 sm:w-10/12 zm:w-full p-5 zm:pl-8 md:pl-6 sm:pl-0">
                <img
                  alt="yogi"
                  className="2xl:h-5/6 2xl:absolute 2xl:right-0 2xl:top-20"
                  src="https://images.myyogateacher.com/web_images/our_mission.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="xl:bg-mytTeacherBgImg bg-contain bg-no-repeat bg-right " id="whatyouneed">
          <div className="topContainer xl:mt-60 zm:mt-0 xl:py-0 zm:py-20">
            <div className="baseContainer">
              <div className="xl:pr-40">
                <div className="py-4">
                  <div className="">
                    <div className="pt-5">
                      <h2 className="contentHeading font-quincy">What You'll Need</h2>
                      <div className="mt-7 mb-5">
                        <div className="contentDescriptionBottomSection font-inter leading-none">
                          Super-fast, reliable internet
                          <div className="leading-none">
                            <br />
                          </div>
                          Soothing environment (clean, quiet, bright, and well decorated) to teach
                          from
                          <div className="leading-none">
                            <br />
                          </div>
                          Laptop, tablet or computer with high quality webcam
                          <div className="leading-none">
                            <br />
                          </div>
                          Great English, easy for Americans to understand
                          <div className="leading-none">
                            <br />
                          </div>
                          500+ hours of certified training & 1 year of teacher experience minimum
                        </div>
                      </div>
                      <svg
                        width="40"
                        height="6"
                        viewBox="0 0 50 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 3.50136C3.32034 2.49824 4.94042 1.96976 6.5983 2.00136C11.1983 2.00136 11.1983 5.00136 15.7983 5.00136C20.3983 5.00136 20.3983 2.00136 24.9983 2.00136C29.5983 2.00136 29.5983 5.00136 34.1989 5.00136C38.7995 5.00136 38.8004 2.00136 43.4019 2.00136C45.0596 1.96949 46.6796 2.49758 48 3.50026"
                          stroke="#321A50"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="mt-8 md:pb-20 zm:mt-16">
                    <div className="pt-5">
                      <h2 className="contentHeading font-quincy">What to Expect</h2>
                      <div className="mt-7 mb-5 ">
                        <div className="contentDescriptionBottomSection font-inter leading-relaxed xl:pr-48">
                          Please read this entirely, and then apply below.
                          <div className="leading-none">
                            <br />
                          </div>
                          With MyYogaTeacher you will have the opportunity to work with many
                          American clients and earn an income that can support you and your family.
                          <div className="leading-none">
                            <br />
                          </div>
                          After you finish the application (link at the bottom of the page), our
                          team will take up to 7-days to review. Then, if we think you might be a
                          good candidate, we will begin the interview and onboarding process
                          (usually takes up to 2-weeks).
                          <div className="leading-none">
                            <br />
                          </div>
                          In the onboarding process we will evaluate your current skill level, train
                          you for leading online classes, and teach you how to use our platform. We
                          will also verify that you have a great space to teach yoga from, English
                          skills suitable for American clients, and high-speed internet.
                          <div className="leading-none">
                            <br />
                          </div>
                          If you are selected to join the MyYogaTeacher team, you will be added to
                          the platform and clients will be able to book 1-on-1 sessions with you
                          right away.
                          <div className="leading-none">
                            <br />
                          </div>
                          But don’t worry, we won’t add you to the platform before you’re ready. And
                          we’ll help you get started with this next phase of your teaching career!
                        </div>
                        <button className="trialCTABody" onClick={this.handleSignup}>
                          Apply Now{' '}
                          <svg
                            className="ml-4"
                            width="20"
                            height="18"
                            viewBox="0 0 24 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.00003 9H22.7499"
                              stroke="white"
                              strokeWidth="2.1"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.4 1.6499L22.75 8.9999L15.4 16.3499"
                              stroke="white"
                              strokeWidth="2.1"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                      <svg
                        width="44"
                        height="8"
                        viewBox="0 0 50 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 3.50136C3.32034 2.49824 4.94042 1.96976 6.5983 2.00136C11.1983 2.00136 11.1983 5.00136 15.7983 5.00136C20.3983 5.00136 20.3983 2.00136 24.9983 2.00136C29.5983 2.00136 29.5983 5.00136 34.1989 5.00136C38.7995 5.00136 38.8004 2.00136 43.4019 2.00136C45.0596 1.96949 46.6796 2.49758 48 3.50026"
                          stroke="#321A50"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="topContainer mb-0">
          <div className="w-full min-h-400 bg-footer xl:py-36 lg:py-36 md:py-28 zm:py-20 text-center">
            <h2 className="text-white zm:leading-9 contentHeading zm:text-4xl lg:text-66">
              Apply to Teach <br className="sm:hidden" />
              with
              <br />
              MyYogaTeacher
            </h2>
            <div className="flex justify-center">
              <p className="my-5 contentDescriptionBottomSection font-normal font-inter text-mytgrey-subtitle text-center lg:px-13 lg:w-6/12 zm:w-4/5">
                Becoming a MyYogaTeacher Teacher isn’t easy, but it’s extremely rewarding. If you
                think you have the skills and work ethic to be a top yoga teacher and work with
                hundreds of American clients, tap the button below to apply now!
              </p>
            </div>
            <button className="trialCTABody" onClick={this.handleSignup}>
              Apply Now{' '}
              <svg
                className="ml-4"
                width="20"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.00003 9H22.7499"
                  stroke="white"
                  strokeWidth="2.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.4 1.6499L22.75 8.9999L15.4 16.3499"
                  stroke="white"
                  strokeWidth="2.1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
    set_password: (payload) => {
      dispatch(actions.set_password(payload));
    },
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    },
    verify_email_token: (payload) => {
      dispatch(actions.verify_email_token(payload));
    },
    set_welcome_variable: (key, payload) => {
      dispatch(actions.set_welcome_variable(key, payload));
    }
  });

const HomeWithLayout = withRouter(withLayout(Home));
const HomeWithRedux = connect(null, mapDispatchToProps)(HomeWithLayout);

export default HomeWithRedux;
