import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from './redux/action_creators';
import * as selectors from './redux/selectors';
import { withRouter } from './utils/router';

const AuthLayer = ({
    teacher_token,
    children,
    get_config,
    load_application,
    load_short_dashboard_sessions,
    get_my_availability,
    router,
}) => {
    useEffect(() => {
        const auth_req_paths = [
            'home',
            'test',
            'dashboard',
            'profile',
            'availability',
            'availability_old',
            'mysessions',
            'student-profile',
            'yoga-journey',
            'announcements',
        ];

        const checkAuthentication = (first_load) => {
            const pathname = window.location.pathname.slice(1).split('/')[0];
            const auth_index = auth_req_paths.indexOf(pathname);

            if (first_load) {
                get_config();
            }

            if ((pathname === '' || pathname === 'welcome') && teacher_token) {
                if (first_load) {
                    load_application();
                    load_short_dashboard_sessions();
                    get_my_availability();
                }
                router.navigate('/home');
            } else if (pathname && auth_index !== -1 && !teacher_token) {
                router.navigate('/');
            } else if (pathname && auth_index !== -1 && teacher_token && first_load) {
                load_application();
                load_short_dashboard_sessions();
                get_my_availability();
            }
        };

        checkAuthentication(true);
    }, [teacher_token, get_config, load_application, load_short_dashboard_sessions, get_my_availability, router]);

    return children;
};

const mapStateToProps = (state) => ({
    teacher_token: selectors.get_teacher_token(state),
});

const mapDispatchToProps = (dispatch) => ({
    load_short_dashboard_sessions: () => dispatch(actions.load_short_dashboard_sessions()),
    get_my_availability: () => dispatch(actions.get_my_availability()),
    load_application: () => dispatch(actions.load_application()),
    get_config: () => dispatch(actions.get_config()),
});

const AuthLayerWithRedux = connect(mapStateToProps, mapDispatchToProps)(AuthLayer);
const AuthLayerWithRouter = withRouter(AuthLayerWithRedux);

export default AuthLayerWithRouter;
