import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../util_components/spinner';
import Confirm from './Confirm';
import * as actions from '../../../redux/action_creators';
import * as selectors from '../../../redux/selectors';
import SelDateTime from './SelDateTime';
import './profile.css';
import { withRouter } from '../../../utils/router';

class StudentProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			open_sch_sess_with_student: false,
			confirm_sch_session: false,
			open_student_yoga_journey: false,
		};
	}

	open_student_yoga_journey = () => {
		this.props.router.navigate(`/yoga-journey/${this.props.router.params.studentSlug}`, {
			state: { item: this.props.student_details },
		});
	};

	renderBlock = (text, index) => {
		return (
			<div className='vs-text-block' key={index + text}>
				{text.toUpperCase()}
			</div>
		);
	};

	UNSAFE_componentWillMount() {
		if (this.props.student_details !== '' && this.props.student_details.uuid === this.props.student_uuid) {
			this.setState({ loading: false });
		} else {
			const payload = {
				student_slug: this.props.router.params.studentSlug,
			};
			this.props.load_student_details_slug(payload);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.student_loading_status === 'loading' && this.props.student_loading_status === 'success') {
			this.setState({ loading: false });
		}
	}

	open_sch_sess_w_stu = () => {
		this.setState({
			open_sch_sess_with_student: true,
		});
	};
	close_sch_sess_w_stu = () => {
		this.setState({
			open_sch_sess_with_student: false,
		});
	};

	sch_confirm = () => {
		this.setState({
			open_sch_sess_with_student: false,
			confirm_sch_session: true,
		});
	};

	goToHome = () => {
		this.props.router.navigate('/home');
	};

	render_student_details = () => {
		const stu_dp = {
			backgroundImage: `url(${
				this.props.student_details.profile_photo === ''
					? 'https://images.myyogateacher.com/profile-pic.png'
					: this.props.student_details.profile_photo
			})`,
		};
		return (
			<>
				<div className='vs-container'>
					<div className='vs-header'>
						<div className='vs-bg' />
						<div className='vs-name-details'>
							<h4 className='vs-name'>{this.props.student_details.first_name}</h4>
							<div className='vs-details'>
								<span>{this.props.student_details.yoga_level.toUpperCase()}</span>

								<span>{this.props.student_details.number_of_sessions_finished} SESSIONS DONE</span>
								<span>{this.props.student_details.membership_plan.toUpperCase()}</span>
							</div>
						</div>

						<div className='vs-profile-pic' style={stu_dp} />
					</div>

					<div className='vs-basic-details'>
						<div className='vs-basic-container'>
							<div className='vs-basic-icon'>
								<img src='https://images.myyogateacher.com/teacher_web_app/ic_yoga@2x.png' alt='yoga' />
							</div>
							<div className='vs-basic-desc'>
								<h4 className='vs-basic-desc-title'>Experience with Yoga</h4>
								<p className='vs-basic-desc-text'>
									<span className='exp-p'>{this.props.student_details.yoga_level}</span>,{' '}
									{this.props.student_details.years_of_experience}
								</p>
							</div>
						</div>

						{this.props.student_details.yoga_types_practiced.trim() !== '' ? (
							<div className='vs-basic-container'>
								<div className='vs-basic-icon'>
									<img
										className='mat'
										src='https://images.myyogateacher.com/teacher_web_app/ic_yoga_practiced@2x.png'
										alt='yoga'
									/>
								</div>
								<div className='vs-basic-desc'>
									<h4 className='vs-basic-desc-title'>Types of Yoga practiced</h4>
									<p className='vs-basic-desc-text'>
										{this.props.student_details.yoga_types_practiced
											.split(',')
											.map((item, index) => {
												return this.renderBlock(item, index);
											})}
									</p>
								</div>
							</div>
						) : null}

						<div className='vs-basic-container'>
							<div className='vs-basic-icon'>
								<img
									src='https://images.myyogateacher.com/teacher_web_app/ic_goals@2x.png'
									alt='yoga'
								/>
							</div>
							<div className='vs-basic-desc'>
								<h4 className='vs-basic-desc-title'>Goals</h4>
								<p className='vs-basic-desc-text'>
									{this.props.student_details.goals.split(',').map((item, index) => {
										return this.renderBlock(item, index);
									})}
								</p>
							</div>
						</div>

						{this.props.student_details.medical_history_details.length !== 0 ? (
							<div className='vs-basic-container'>
								<div className='vs-basic-icon'>
									<img
										src='https://images.myyogateacher.com/teacher_web_app/ic_medical_history@2x.png'
										alt='yoga'
									/>
								</div>
								<div className='vs-basic-desc'>
									<h4 className='vs-basic-desc-title'>Medical history</h4>
									<p className='vs-basic-desc-text'>
										{this.props.student_details.medical_history_details}
									</p>
								</div>
							</div>
						) : (
							<div className='vs-basic-container'>
								<div className='vs-basic-icon'>
									<img
										src='https://images.myyogateacher.com/teacher_web_app/ic_medical_history@2x.png'
										alt='yoga'
									/>
								</div>
								<div className='vs-basic-desc'>
									<h4 className='vs-basic-desc-title'>Medical history</h4>
									<p className='vs-basic-desc-text'>No medical history details</p>
								</div>
							</div>
						)}

						<div className='vs-basic-container' onClick={this.open_student_yoga_journey}>
							<button className='btn btn-pri'>Yoga Journey</button>
						</div>
					</div>
				</div>
				<div className='main-c-footer'>
					<div className='main-c-footer-cont'>
						<button className='btn btn-back' onClick={this.goToHome}>
							<img src='https://images.myyogateacher.com/back_arrow_btm.png' alt='back arrow' />
							Back
						</button>
						<button className='btn btn-pri ' onClick={this.open_sch_sess_w_stu}>
							Book a session with {this.props.student_details.first_name}
						</button>
					</div>
				</div>
				{this.state.open_sch_sess_with_student ? (
					<SelDateTime
						student_timezone={this.props.student_details.iana_timezone}
						student_uuid={this.props.student_details.uuid}
						close_sch_sess_w_stu={this.close_sch_sess_w_stu}
						sch_confirm={this.sch_confirm}
					/>
				) : null}
				{this.state.confirm_sch_session ? <Confirm /> : null}
			</>
		);
	};

	render_loading = () => {
		return (
			<div className='vs-loading'>
				<Spinner />
			</div>
		);
	};

	render() {
		return (
			<div className='main-card-body'>
				{this.state.loading ? this.render_loading() : this.render_student_details()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		student_details: selectors.get_student_details(state),
		student_loading_status: selectors.student_loading_status(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		load_student_details: (payload) => {
			dispatch(actions.load_student_details(payload));
		},
		load_student_details_slug: (payload) => {
			dispatch(actions.load_student_details_slug(payload));
		},
	};
};

const StudentProfileWithRedux = connect(mapStateToProps, mapDispatchToProps)(StudentProfile);
const StudentProfileWithRouter = withRouter(StudentProfileWithRedux);

export default StudentProfileWithRouter;
