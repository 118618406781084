import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CreateGroupsession from './create_group_session';
import Spinner from '../../util_components/spinner';
import TestYourSetup from '../../util_components/test_your_setup';
import { analytics_track, getCookie, sentenceCase } from '../../../util_functions';
import RenderSessions from './dashboard_funcs';
import * as selectors from '../../../redux/selectors';
import * as actions from '../../../redux/action_creators';

import './dashboard.css';
import './dashboard_funcs.css';
import './chats.css';
import { SMSList } from './sms_list';
import { InfoIcon } from '../../../new_teacher_design/assets/svg/dashboard_icons';
import Next24Card from './next_24_card';

class Dashboard extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			test_setup_open: false,
		};
	}

	track_fn = (name) => {
		const options = {
			referrer_component: 'dashboard',
		};
		analytics_track(`${name} click`, options);
	};

	create_group_session = () => this.props.set_home_variable('create_group_session', true);
	close_group_session = () => this.props.set_home_variable('create_group_session', false);
	close_test_setup = () => this.setState({ test_setup_open: false });
	open_test_setup = () => {
		this.setState({ test_setup_open: true });
		this.track_fn('test setup');
	};
	open_add_availability = () => {
		this.track_fn('add availability');
		window.location.replace('/availability');
	};

	renderAddSessionScreen = () => {
		if (this.props.sessions.length !== 0) {
			const text_style =
				this.props.availability_in_next_7_days >= 20 ? { color: '#57C303' } : { color: '#ED4D4D' };
			return (
				<div className='w-full dashboard-add-sessions flex flex-col lg:mb-20px border border-mytgrey-litev1 shadow-mytboxshadow rounded-8px py-25px px-20px'>
					<div className='dashboard-add-sessions-text flex items-center justify-center'>
						<h1 className='font-bold lg:text-15px font-inter w-195px text-center zm:text-13px w-full leading-5'>
							Availability for the next 7 days is
							<span
								className='font-semibold'
								style={text_style}
							>{` ${this.props.availability_in_next_7_days} hours`}</span>
						</h1>
						<p
							className='zm"w-full lg:w-195px text-center font-inter font-medium leading-16px'
							style={{ fontSize: '11px' }}
						>
							Increase the available time to get max bookings
						</p>
					</div>
					<div className='dashboard-add-sessions-button zm:p-0'>
						<div className='add-availability-dashboard add-avail zm:mt-5px lg:mt-10px'>
							<div
								className='btn btn-primary cursor-pointer w-full text-13.5px h-38px'
								onClick={this.open_add_availability}
							>
								<span>Add Availability</span>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};

	render_other_status_dashboard = () => {
		if (this.props.sessions === undefined || this.props.sessions === null) {
			return;
		}
		const data = this.props?.replacement_sessions_list?.[0] ?? [];
		const dataList = data?.data ?? {};
		console.log('data: ', data);
		return (
			<div className='dashboard-container zm:mt-70px lg:mt-0'>
				{this.props.create_group_session ? (
					<CreateGroupsession close_group_session={this.close_group_session} />
				) : null}
				<div className='zm:my-20px lg:my-38px px-25px gap-25px flex zm:flex-col lg:flex-row-reverse justify-center'>
					<div className='zm:w-full flex flex-col lg:w-275px'>
						<div className='w-full'>{this.renderAddSessionScreen()}</div>
						<div className='zm:hidden lg:block'>
							{!!getCookie('teacher_token_1') && <SMSList />}
							<TestYourSetup
								test_setup_open={this.state.test_setup_open}
								close_test_setup={this.close_test_setup}
							/>
						</div>
					</div>
					<div className='w-full lg:max-w-800px'>
						{!!this.props?.replacement_sessions_list &&
							Object.keys(dataList).length > 0 &&
							this.renderRequestsSessionHeader()}
						{<Next24Card sessionScheduleCount={this.props.sessions_in_next_24_hours}></Next24Card>}
						{this.props.sessions.length === 0 ? null : <RenderSessions />}
					</div>
				</div>
			</div>
		);
	};
	close_act_created_pop = () => this.props.set_home_variable('account_created', 0);
	render_account_created = () => {
		if (this.props.account_created === 1) {
			return (
				<div className='act-created-popup'>
					<div className='act-cr-overlay' onClick={this.close_act_created_pop} />
					<div className='act-cr-body'>
						<img
							onClick={this.close_act_created_pop}
							className='act-cr-p-close'
							alt='close'
							src='https://images.myyogateacher.com/icons/ic_white_cross.png'
						/>
						<div className='yel-ok-act-cr'>
							<img alt='right' src='https://images.myyogateacher.com/icons/right-black.png' />
						</div>
						<h4>Account Created</h4>
					</div>
				</div>
			);
		}
		return null;
	};
	render_other_no_sessions = () => {
		return (
			<div className='no-sess-create'>
				<div className='add-availability-dashboard'>
					<div>{sentenceCase(this.props.account_status_message)}</div>
				</div>
			</div>
		);
	};
	render_interst_dashboard = () => {
		return (
			<div className='dashboard'>
				<div className='setup-test-chat-container'>
					<TestYourSetup
						test_setup_open={this.state.test_setup_open}
						close_test_setup={this.close_test_setup}
					/>
					<div className='setup-test'>
						<div className='setup-test-heading'>Test your setup</div>
						<div className='setup-test-description'>Highly recommended before any session</div>
						<div className='setup-test-image' />
						<div className='setup-test-button' onClick={this.open_test_setup}>
							Test your setup
						</div>
					</div>
				</div>

				<div className='sessions'>
					<RenderSessions />
				</div>
			</div>
		);
	};

	renderRequestsSessionHeader = () => {
		const iNewMessage = this.props.replacement_sessions_list?.filter((rr) => rr.unread).length > 0;

		return (
			<div className='dashboardContainerWidth justify-between bg-mytBgRequestCard rounded-lg px-20px py-14px flex mb-25px border border-mytRequestBorderColor'>
				<div className='float-left flex'>
					<div className='h-22px font-light text-mytRequestText'>
						<InfoIcon />
					</div>
					<div className='text-13px text-mytRequestText font-medium leading-22px ml-10px flex items-center'>
						You have {this.props.replacement_sessions_list.length} new class{' '}
						{this.props.replacement_sessions_list.length > 1 ? 'requests' : 'request'}
					</div>
				</div>
				<button
					className='text-13px text-light_golden font-semibold focusState'
					onClick={() => {
						this.props.set_home_variable('show_request_sessions_popups', true);
						if (iNewMessage) {
							if (this.props.markRelayRead) {
								this.props.markRelayRead();
							}
						} else {
							this.setState({ showRequestSessionPopups: true });
						}
					}}
				>
					{window.innerWidth > 768
						? this.props.replacement_sessions_list.length > 1
							? 'View Requests'
							: 'View Request'
						: 'View'}
				</button>
			</div>
		);
	};

	render_interested_to_join = () => {
		const sessions = this.props.sessions;
		return (
			<div className={`dashboard-container ${sessions.length === 0 ? 'no-sess-cont' : ''}`}>
				{this.render_account_created()}
				{this.props.create_group_session ? (
					<CreateGroupsession close_group_session={this.close_group_session} />
				) : null}
				{sessions.length === 0 ? this.render_other_no_sessions() : this.render_interst_dashboard()}
			</div>
		);
	};
	render() {
		if (this.props.dashboard_loading) {
			return (
				<div className='dashboard-container'>
					<div className='container-loading'>
						<Spinner /> <p>Loading...</p>
					</div>
				</div>
			);
		} else if (this.props.teacher_details.status !== 'APPROVED') {
			return this.render_interested_to_join();
		} else return this.render_other_status_dashboard();
	}
}

const mapStateToProps = (state) => {
	return {
		dashboard_loading: selectors.get_dashboard_loading(state),
		sessions_in_next_24_hours: selectors.get_sessions_in_next_24_hours(state),
		availability_in_next_7_days: selectors.get_availability_in_next_7_days(state),
		sessions: selectors.get_sessions(state),
		teacher_token: selectors.get_teacher_token(state),
		profile_basic_details: selectors.basic_details_selector(state),
		teacher_details: state.profile.teacher_details,
		account_created: state.home.account_created,
		create_group_session: state.home.create_group_session,
		replacement_sessions_list: state.req_replacement.replacement_sessions_list,
		account_status_message: state.dashboard.account_status_message,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		cancel_session: (payload) => {
			dispatch(actions.cancel_session(payload));
		},
		get_zoom_token: (payload) => {
			dispatch(actions.get_zoom_signature(payload));
		},
		load_my_sessions: () => {
			dispatch(actions.load_my_sessions());
		},
		set_home_variable: (key, payload) => {
			dispatch(actions.set_home_variable(key, payload));
		},
	};
};

const DashboardWithRedux = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardWithRedux;
