import * as t from '../action_types';

// different status
// 1. none
// 2. inprogress
// 3. success
// 4. fail


var initialState = {
  verifiation_status: 'none',
  verification_error_message: 'Oops something happend please try again'
};

const set_welcome_variable = (state, payload) => {
  return {
    ...state,
    [payload.key]: payload.payload
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
      case t.set_welcome_variable:
      return set_welcome_variable(state, action.payload);
    default:
      return state;
  }
}