
import React from 'react';
import PopupWrapper from './PopupWrapper';
import SwitchButton from '../FixedComponent/SwitchButton';
import { connect } from 'react-redux';
import * as actions from '../../Redux/action_creators';
import Loader from '../FixedComponent/Loader';
import { InfoIcon } from '../../assets/svg/popup_icons';
import moment from 'moment';

interface IState {
    loading: Boolean,
    editClassData: any,
    studentSubscritionData: Object;
    durationTimingDt: Number,
}
interface IMapStateToProps {
    get_subscription_data_status: string,
    get_subscription_data_response: {},
    subscriptionPopupData: string,
    schedule_class_status: string,
    subscritiopnPopupDataDT: {},
    schedule_class_response: {},
}

interface IMapDispatchToProps {
    scheduleClass: (payload?: any) => void;
    get_subscription_data: (payload?: any) => void;
    closePopup: () => void;
    closeSchedulePopup: () => void;
    closesubscriptionPopup: () => void;
    closesubscriptionPopupFail: () => void;
    set_home_variable: (key?: any, payload?: any) => void;
}

type CreditAllProps = IMapStateToProps & IMapDispatchToProps;
class SubscritionPopup extends React.Component<CreditAllProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            editClassData: {},
            studentSubscritionData: {},
            durationTimingDt: 0,
        }
    }

    componentDidMount(): void {
        if (!!Object.keys(this.props.subscritiopnPopupDataDT)) {
            this.setState({
                studentSubscritionData: this.props.subscritiopnPopupDataDT,
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<CreditAllProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.schedule_class_status === 'loading' && this.props.schedule_class_status === 'success') {
            this.setState({ loading: false });
            this.props?.closesubscriptionPopup?.();
        }
        if (prevProps.schedule_class_status === 'loading' && this.props.schedule_class_status === 'fail') {
            this.setState({ loading: false });
            this.props?.closesubscriptionPopupFail?.();
        }
    }

    subscribeDate = () => {
        this.setState({ loading: true });
        this.props?.scheduleClass?.(this.state.studentSubscritionData['subscriptionPayload']);
    }

    closePopupDt = () => {
        this.props?.closePopup();
    }

    render() {
        return (
            <>
                {
                    this.state.loading === true ?
                        <Loader />
                        :
                        <>
                            {Object.keys(this.state.studentSubscritionData).length &&
                                <PopupWrapper closePopup={() => this.closePopupDt()}>
                                    <div className='mb-0'>
                                        <div className='flex justify-center items-center mb-5'>
                                            {<InfoIcon />}
                                        </div>
                                        {this.state.studentSubscritionData['subscriptionDataDt']['subscribed_data']['sessions_per_week'] >= 1 ?
                                            <div className='mb-3 text-center font-semibold text-subText md:text-19 zm:text-17'>{this.state.studentSubscritionData['studentDetails']['student_name']} does not have enough credits and will be charged ${this.state.studentSubscritionData['durationPrice']} for each class</div> : null
                                        }
                                        {this.state.studentSubscritionData['subscriptionDataDt']['subscribed_data']['sessions_per_week'] < 1 ?
                                            <div className='mb-3 text-center font-semibold text-subText md:text-19 zm:text-17'>{this.state.studentSubscritionData['studentDetails']['student_name']} is not on a 1-on-1 plan and will be charged ${this.state.studentSubscritionData['durationPrice']} for each class</div>
                                            : null}
                                        {/* <p className='mb-3 text-14.5 font-normal text=mytgrey-lite leading-tight text-center'>We recommend {this.state.studentSubscritionData['studentDetails']['student_name']} to upgrade their plan before proceeding</p> */}
                                        <p className='mb-3 text-14.5 font-normal text=mytgrey-lite leading-tight text-center'>Please ensure this extra charge is discussed with the student. Student could also upgrade membership plan</p>
                                        <button onClick={() => this.subscribeDate()} className={`btn-white-default w-full md:text-18.5 zm:text-17 md:py-5 zm:py-3 font-inter font-semibold md:mb-3 zm:mb-6`} >
                                            Proceed
                                        </button>
                                        <button onClick={() => this.closePopupDt()} className={`btn btn-primary w-full md:text-18.5 zm:text-17 md:py-5 zm:py-3 font-inter font-semibold md:mb-0 zm:mb-6`} >
                                            Schedule later
                                        </button>
                                    </div>
                                </PopupWrapper>
                            }
                        </>
                }
            </>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        scheduleSessionDataInfo: state.dashboardv1.scheduleSessionDataInfo,
        schedule_class_status: state.classdetails.schedule_class_status,
        schedule_class_response: state.classdetails.schedule_class_response,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        set_home_variable: (key: any, payload: any) => {
            dispatch(actions.set_home_variable(key, payload))
        },
        get_subscription_data: (payload: any) => {
            dispatch(actions.get_subscription_data(payload))
        },
        scheduleClass: (payload: any) => {
            dispatch(actions.scheduleClass(payload))
        },
    };
};

const SubscritionPopupWithRedux = connect(mapStateToProps, mapDispatchToProps)(SubscritionPopup)

export default SubscritionPopupWithRedux;