import React, { Component } from 'react';
import './footer.css';

export default class Footer extends Component {
	render() {
		return (
			<div className="bg-light footer-home">
				<div className="container">
					<div className="row-footer-container">
						<div className="row-footer">
							<ul className="footer-list-left">
								<li>
									<a href="https://blog.myyogateacher.com/" target="_blank" rel="noopener noreferrer">
										Blog
									</a>
								</li>
								<li>
									<a href="https://myyogateacher.com/aboutus">About Us</a>
								</li>
								<li>
									<a href="https://myyogateacher.com/contactus">Contact</a>
								</li>
							</ul>

							<ul className="footer-list-right">
								<li>
									<a href="mailto:care@myyogateacher.com">Support</a>
								</li>
								<li>
									<a href="https://myyogateacher.com/privacypolicy">Privacy Policy</a>
								</li>
								<li>
									<a href="https://myyogateacher.com/termsofuse">Terms of use</a>
								</li>
							</ul>

							<p className="copy-right">&copy; 2018 myYogaTeacher Inc </p>
						</div>
					</div>
					<div className="footer-mobile">
						<img
							className="footer-design"
							src="https://images.myyogateacher.com/home-page/Footer-Mandala-and-Divider.png"
							alt="footer"
						/>
						<ul className="footer-list-mobile">
							<li>
								<a href="https://blog.myyogateacher.com/" target="_blank" rel="noopener noreferrer">
									Blog
								</a>
							</li>
							<li>
								<a href="https://myyogateacher.com/aboutus">About Us</a>
							</li>
							<li>
								<a href="https://myyogateacher.com/contactus">Contact</a>
							</li>
							<li>
								<a href="mailto:care@myyogateacher.com">Support</a>
							</li>
							<li>
								<a href="https://myyogateacher.com/privacypolicy">Privacy Policy</a>
							</li>
							<li>
								<a href="https://myyogateacher.com/termsofuse">Terms of use</a>
							</li>
						</ul>
						<p className="copy-right">&copy; 2018 myYogaTeacher Inc </p>
					</div>
				</div>
			</div>
		);
	}
}
