import React, { useEffect, useState } from 'react';
import momentTz from 'moment-timezone';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Button from './CommonComponents/Button';
import RadioButton from './CommonComponents/RadioButton';
import WhiteBox from './CommonComponents/WhiteBox';
import { SubHeadText } from './StudentProfileDetails';
import { Chevronright, GreenTick } from './svg';
import { DatePicker } from './CommonComponents/DatePicker';
import urls from '../../urls';
import { post_api } from '../../redux/api_funcs';
import LoadingScreen from '../../components/util_components/loading_screen';
import ConsultBookedPopUp from './ConsultBookedPopUp';
import Header from '../../components/util_components/header';
import MytToolTip from './CommonComponents/MytToolTip/MytToolTip';
import { sendEventToClarity } from '../../util_functions';

function FollowUpConsult() {
  const location = useLocation();
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [includeDates, setincludeDates] = useState([]);
  const [isCustomDateSelected, setisCustomDateSelected] = useState(false);
  const [selectedDuration, setselectedDuration] = useState(30);
  const [scheduledInfo, setScheduledInfo] = useState({});
  const [consultBooked, setConsultBooked] = useState(false);
  const [daySelected, setDaySelected] = useState({
    index: 0
  });
  const [slotSelected, setSlotSelected] = useState({
    time: '',
    epoch_time: ''
  });
  const [loading, setloading] = useState(false);
  const [error, setError] = useState(null);
  const [timeZone, setTimeZone] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    loadSlots();
    sendEventToClarity();
  }, []);

  const selectADate = (date) => {
    setDaySelected(date);
    setisCustomDateSelected(false);
    setSlotSelected({
      time: '',
      epoch_time: ''
    });
    setError(null);
  };

  const selectASlot = (slot) => {
    setSlotSelected(slot);
    setError(null);
  };

  const includeDatesFunc = (days) => {
    const dates = [];
    days.map((each) => dates.push(momentTz(each.date_v1).toDate()));
    return dates;
  };

  const scheduleConsultation = async () => {
    if (slotSelected.time === '') {
      setError('Please select a timeslot to Book your free consultation');
      return;
    }
    setloading(true);
    const url = urls.schedule_yoga_consultation;
    const payload = {
      start_time: slotSelected.epoch_time,
      consult_type: 'BACK_PAIN_CONSULT',
      duration: selectedDuration === 45 ? 60 : 30,
      student_uuid: location?.state?.student_uuid || ''
    };
    try {
      const result = await post_api(url, payload, true);
      const scheduledInformation =
        result.data &&
        result.data.schedule_consultation &&
        result.data.schedule_consultation.length > 0
          ? result.data.schedule_consultation[0]
          : {};
      setScheduledInfo(scheduledInformation);
      setConsultBooked(true);
      setloading(false);
    } catch (err) {
      setloading(false);
      setError('Some error occured while booking consult');
      console.log(err);
    }
  };

  const loadSlots = async (duration = 30) => {
    setloading(true);
    const url = urls.yoga_consult_slots;
    let result;

    try {
      result = await post_api(
        url,
        {
          duration,
          student_uuid: location?.state?.student_uuid || '',
          short_term: 0
        },
        true
      );

      const slots = result.data?.available_slots?.filter(
        (slot, index) => slot?.slots?.length !== 0
      );
      const timezone = result?.data?.student_timezone;
      setTimeZone(timezone);
      const days = slots.map((slot) => ({ date: slot.date, name: slot.day_name, date_v1: slot.date_v1 }));

      if (slots.length > 0) {
        setincludeDates(includeDatesFunc(days));
      }
      const filteredSlots = slots.map((slot) => {
        const shift_values = [];
        const morning_slots = slot.slots.filter((time_slot) => {
          const format = 'h:mm A';
          const time = momentTz(time_slot.time, format).tz(momentTz.tz.guess(true));
          const beforeTime = momentTz('00:00 AM', format);
          const afterTime = momentTz('11:59 AM', format);
          return time.isBetween(beforeTime, afterTime);
        });
        const afternoon_slots = slot.slots.filter((time_slot) => {
          const format = 'h:mm A';
          const time = momentTz(time_slot.time, format).tz(momentTz.tz.guess(true));
          const beforeTime = momentTz('11:59 AM', format);
          const afterTime = momentTz('05:59 PM', format);
          return time.isBetween(beforeTime, afterTime);
        });
        const evening_slots = slot.slots.filter((time_slot) => {
          const format = 'h:mm A';
          const time = momentTz(time_slot.time, format).tz(momentTz.tz.guess(true));
          const beforeTime = momentTz('05:59 PM', format);
          const afterTime = momentTz('11:59 PM', format);
          return time.isBetween(beforeTime, afterTime);
        });
        if (morning_slots.length !== 0) shift_values.push('Morning');
        if (afternoon_slots.length !== 0) shift_values.push('Afternoon');
        if (evening_slots.length !== 0) shift_values.push('Evening');
        return {
          date: slot.date,
          day_name: slot.day_name,
          day_full_name: slot.day_full_name,
          morning_slots,
          afternoon_slots,
          evening_slots,
          shift_values: [...shift_values],
          dayStr:
            slot.date === momentTz().format('ddd Do MMM')
              ? 'Today'
              : slot.date === momentTz().add(1, 'days').format('ddd Do MMM')
                ? 'Tomorrow'
                : slot.date
        };
      });
      if (
        filteredSlots[0].date === momentTz().format('ddd Do MMM') &&
        momentTz().diff(momentTz().set('hour', 9).set('minute', 30), 'minutes') > 0
      ) {
        filteredSlots.shift();
        const include_dates = includeDatesFunc(days);
        include_dates.shift();
        setincludeDates(include_dates);
      }
      setFilteredSlots(filteredSlots);
      setDaySelected({ ...filteredSlots[0], index: 0 });
      setloading(false);
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  const changeDuration = (value) => {
    setselectedDuration(value);
    loadSlots(value);
    setisCustomDateSelected(false);
    setSlotSelected({
      time: '',
      epoch_time: ''
    });
    setError(null);
  };

  const RadioBtn = [
    {
      label: '30 Mins',
      id: 1,
      value: 30
    },
    {
      label: '45 Mins',
      id: 2,
      value: 45
    }
  ];

  return Object.keys(scheduledInfo).length > 0 ? (
    <ConsultBookedPopUp
      data={scheduledInfo}
      onClose={() => {
        setScheduledInfo({});
        navigate(-1);
      }}
    />
  ) : (
    <>
      <Header />
      <div className="flex items-center justify-center mt-70px  mb-61px">
        <div className="w-fit px-25px zm:py-40px md:py-0">
          <div className="flex items-center gap-2.5">
            <div className="font-semibold text-sm leading-18px text-mytpurple">
              {location?.state?.student_name || ''}
            </div>
            <Chevronright />
            <div className="font-semibold text-sm leading-18px text-greycolor">Book a consult</div>
          </div>
          <div className="font-extrabold zm:text-19px zm:mb-7px zm:mt-20px md:text-38px zm:leading-normal md:leading-44px md:mb-15px font-avenir text-mytTeacherTextGray md:mt-5">
            Book a follow up consult with {location?.state?.student_name || ''}
          </div>
          <div className="zm:text-13px md:text-15px leading-18px font-normal text-mytgrey-contactHeading zm:mb-40px md:mb-50px">
            This option should be mindfully used in agreement with the student
          </div>
          {loading ? (
            <LoadingScreen />
          ) : (
            <WhiteBox>
              <SubHeadText text="Select date" />
              <div className="flex items-center mt-15px gap-2.5 flex-wrap">
                <div className="flex items-center gap-10px">
                  {[...filteredSlots].slice(0, 2).map((day, index) => {
                    const indexOfDay = index;
                    const isSelected =
                      daySelected.day_name === day.day_name || daySelected.day_name === day.date;

                    return (
                      <div className=" relative cursor-pointer" key={day.day_name}>
                        <div
                          onClick={() => selectADate({ index: indexOfDay, ...day })}
                          className={`
                                        ${
                                          isSelected
                                            ? 'bg-white shadow-dateshadow border border-solid border-mytgrey-subBorder'
                                            : 'bg-mytGrayLightV3'
                                        }  font-semibold text-sm leading-22px text-mytpurple rounded-7px py-9.5px px-3 w-fit cursor-pointer`}
                        >
                          {day.day_name}
                        </div>
                        {isSelected ? (
                          <div className="absolute -top-2 -right-1.5">
                            <GreenTick />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </div>
                <DatePicker
                  isSelected={isCustomDateSelected}
                  text={isCustomDateSelected ? daySelected.day_name : 'Choose a Date'}
                  available_slots={[...includeDates]}
                  onDateChange={(date) => {
                    const formattedDate = momentTz(date).format('ddd, MMM Do');
                    filteredSlots.forEach((slot, index) => {
                      const indexOfSlot = index;

                      if (slot.date === formattedDate) {
                        setDaySelected({
                          index: indexOfSlot,
                          day_name: formattedDate,
                          ...slot
                        });
                        setSlotSelected({
                          time: '',
                          epoch_time: ''
                        });
                        if (index > 1) {
                          setisCustomDateSelected(true);
                        } else {
                          setisCustomDateSelected(false);
                        }
                      }
                    });
                  }}
                />
              </div>
              <SubHeadText text="Session duration" classnames="zm:mt-35px md:mt-50px" />
              <div className="flex items-center mt-15px">
                {RadioBtn.map((item) => {
                  const isSelected = selectedDuration === item.value;

                  return (
                    <div className="radiobtnstyle cursor-pointer">
                      <RadioButton
                        color="radioBtn"
                        id={item.id}
                        label={item.label}
                        forCheck={item.value}
                        onClick={() => changeDuration(item.value)}
                        name="radio"
                        isChecked={isSelected}
                        value={item.value}
                      />
                    </div>
                  );
                })}
              </div>
              <SubHeadText
                text="Select time slot"
                showTimeZone
                timezone={timeZone}
                classnames="zm:mt-35px md:mt-50px"
              />

              {daySelected.hasOwnProperty('index') &&
              filteredSlots.length >= daySelected.index &&
              filteredSlots[daySelected.index]?.morning_slots.length > 0 ? (
                <>
                  <div className="font-medium text-13.5px leading-4 text-mytLabel mt-15px">
                    Morning
                  </div>
                  <div className="flex items-center mt-2.5 flex-wrap md:w-9/12 gap-2.5 zm:w-full">
                    {Object.prototype.hasOwnProperty.call(filteredSlots, daySelected.index) &&
                      filteredSlots[daySelected.index].morning_slots.map((item, index) => {
                        const indexOfItem = index;
                        const isSelected = slotSelected.epoch_time === item.epoch_time;
                        const date = moment(item.epoch_time);
                        const formattedDate = moment(date)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format('ddd, MMM Do, h:mm a z');

                        return (
                          <div key={index}>
                            <div className="relative cursor-pointer" id={item.time} key={item.time}>
                              <div
                                data-for={item.time}
                                onClick={() =>
                                  selectASlot({
                                    index: indexOfItem,
                                    ...item
                                  })
                                }
                                className={`
                                        ${
                                          isSelected
                                            ? 'bg-white shadow-dateshadow border border-solid border-mytgrey-subBorder'
                                            : 'bg-mytGrayLightV3'
                                        }  font-semibold text-sm leading-22px text-mytpurple rounded-7px py-9.5px px-3 w-fit cursor-pointer`}
                              >
                                {item.time}
                              </div>

                              {isSelected ? (
                                <div className="absolute -top-2 -right-1.5">
                                  <GreenTick />
                                </div>
                              ) : (
                                ''
                              )}
                              <MytToolTip
                                id={item.time}
                                content={`Your local time is ${formattedDate}`}
                                position="top"
                                customClass="followUpTooltip"
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : null}

              {daySelected.hasOwnProperty('index') &&
              filteredSlots.length >= daySelected.index &&
              filteredSlots[daySelected.index]?.afternoon_slots.length > 0 ? (
                <>
                  <div className="font-medium text-13.5px leading-4 text-mytLabel mt-10">
                    Afternoon
                  </div>
                  <div className="flex items-center mt-2.5 flex-wrap w-9/12 gap-2.5">
                    {Object.prototype.hasOwnProperty.call(filteredSlots, daySelected.index) &&
                      filteredSlots[daySelected.index].afternoon_slots.map((item, index) => {
                        const indexOfItem = index;
                        const isSelected = slotSelected.epoch_time === item.epoch_time;
                        const date = moment(item.epoch_time);
                        const formattedDate = moment(date)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format('ddd, MMM Do, h:mm a z');
                        return (
                          <div className="relative" id={item.time} key={item.time}>
                            <div
                              data-for={item.time}
                              onClick={() =>
                                selectASlot({
                                  index: indexOfItem,
                                  ...item
                                })
                              }
                              className={`
                                        ${
                                          isSelected
                                            ? 'bg-white shadow-dateshadow border border-solid border-mytgrey-subBorder'
                                            : 'bg-mytGrayLightV3'
                                        }  font-semibold text-sm leading-22px text-mytpurple rounded-7px py-9.5px px-3 w-fit cursor-pointer`}
                            >
                              {item.time}
                            </div>
                            {isSelected ? (
                              <div className="absolute -top-2 -right-1.5">
                                <GreenTick />
                              </div>
                            ) : (
                              ''
                            )}
                            <MytToolTip
                              id={item.time}
                              content={`Your local time is ${formattedDate}`}
                              position="top"
                              customClass="followUpTooltip"
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : null}
              {daySelected.hasOwnProperty('index') &&
              filteredSlots.length >= daySelected.index &&
              filteredSlots[daySelected.index]?.evening_slots.length > 0 ? (
                <>
                  <div className="font-medium text-13.5px leading-4 text-mytLabel mt-10">
                    Evening
                  </div>
                  <div className="flex items-center mt-2.5 flex-wrap w-9/12 gap-2.5">
                    {Object.prototype.hasOwnProperty.call(filteredSlots, daySelected.index) &&
                      filteredSlots[daySelected.index].evening_slots.map((item, index) => {
                        const indexOfItem = index;
                        const isSelected = slotSelected.epoch_time === item.epoch_time;
                        const date = moment(item.epoch_time);
                        const formattedDate = moment(date)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format('ddd, MMM Do, h:mm a z');
                        return (
                          <div className="relative cursor-pointer" id={item.time} key={item.time}>
                            <div
                              data-for={item.time}
                              onClick={() =>
                                selectASlot({
                                  index: indexOfItem,
                                  ...item
                                })
                              }
                              className={`
                                        ${
                                          isSelected
                                            ? 'bg-white shadow-dateshadow border border-solid border-mytgrey-subBorder'
                                            : 'bg-mytGrayLightV3'
                                        }  font-semibold text-sm leading-22px text-mytpurple rounded-7px py-9.5px px-3 w-fit cursor-pointer`}
                            >
                              {item.time}
                            </div>
                            {isSelected ? (
                              <div className="absolute -top-2 -right-1.5">
                                <GreenTick />
                              </div>
                            ) : (
                              ''
                            )}
                            <MytToolTip
                              id={item.time}
                              content={`Your local time is ${formattedDate}`}
                              position="top"
                              customClass="followUpTooltip"
                            />
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : null}
              {slotSelected.time !== '' && slotSelected.epoch_time !== '' && (
                <div>
                  <p className="px-20px py-15px zm:mt-25px md:mt-20px text-mytgreen md:text-14px leading-22px font-semibold zm:text-13px bg-lightGreenTextColor rounded-10px">
                    {`You've selected to book a ${selectedDuration || 30}-minute consultation for ${
                      daySelected.day_name === 'Today'
                        ? 'Today at'
                        : daySelected.day_name === 'Tomorrow'
                          ? 'Tomorrow at'
                          : daySelected.day_full_name
                    } ${slotSelected.time} ${moment().tz(timeZone).format('z')}. Please click the
                            button below to reserve this slot.`}
                  </p>
                </div>
              )}
              {error && (
                <p className="text-mytRedText2 text-15.5px font-medium font-inter mt-10px">
                  {error}
                </p>
              )}
              {slotSelected.epoch_time ? (
                <div className="text-startTimeTextColor text-xs font-medium mt-25px">
                  Your local time is{' '}
                  {moment(slotSelected.epoch_time)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('ddd, MMM Do, h:mm a z')}
                </div>
              ) : null}
              <Button
                classNames={`zm:w-full md:w-auto ${slotSelected.epoch_time ? 'md:mt-0' : 'md:mt-45px'}`}
                btnclassNames="zm:mt-25px md:mt-10px"
                btntext="Book Consult"
                disabled={slotSelected.epoch_time === ''}
                onClick={() => scheduleConsultation()}
              />
            </WhiteBox>
          )}
        </div>
      </div>
    </>
  );
}

export default FollowUpConsult;
