import React from "react";
import DrawerToggleButton from "../DrawerToggleButton";

const toolbar = props => (
  <header className="toolbar">
    <DrawerToggleButton click={props.drawerClickHandler} />
  </header>
);

export default toolbar;
