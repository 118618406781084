import React, { useEffect, useRef, useState } from 'react';
import './Modals.css';
import AudioAnalyser from '../audio/AudioAnalyser';
import { isFirefox, isSafari } from 'react-device-detect';
import { TestIcon, TickOrange } from '../livekit_assets/svg';

const SettingsModal = ({ show, onConfirm, triggerInfoModal }) => {
    const [selectedSettingsPanel, setSelectedSettingsPanel] = useState('Audio');
    const SETTINGS_MENU = ['Audio', 'Video'];
    const [selectedMic, setSelectedMic] = useState('');
    const [mediaDevices, setMediaDevices] = useState({
        audio: [],
        video: [],
        speaker: [],
    });
    const [audioStream, setAudioStream] = useState(null);
    const [selectedSpeaker, setSelectedSpeaker] = useState('');
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [step, setStep] = useState(0);

    const videoRef = useRef(null);
    const audioRef = useRef(null);

    const handleMicChange = (e) => {
        setSelectedMic(e.target.value);
    };

    const handleSpeakerChange = (e) => {
        setSelectedSpeaker(e.target.value);
    };

    const handleCameraChange = (e) => {
        setSelectedCamera(e.target.value);
    };

    const listDevices = async () => {
        const devices = await navigator.mediaDevices?.enumerateDevices?.();
        console.log(devices);
        if (devices) {
            const video = [];
            const audio = [];
            const speaker = [];
            for (const device of devices) {
                switch (device.kind) {
                    case 'videoinput':
                        video.push(device);
                        break;
                    case 'audioinput':
                        audio.push(device);
                        break;
                    case 'audiooutput':
                        speaker.push(device);
                        break;
                }
            }
            setMediaDevices({ audio: audio, video: video, speaker: speaker });
            //   update the selected default mic state
            setSelectedMic(audio[0].deviceId);
        } else {
            throw new Error('Media Devices API is not supported.');
        }
    };

    // get the device stream
    const getStream = async (deviceId, type) => {
        if (type === 'audio') {
            try {
                console.log('get audio stream');
                const audio = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: {
                            exact: deviceId,
                        },
                    },
                });

                setAudioStream(audio);
            } catch (error) {
                console.error('error while getting the audio stream', error);
            }
        } else if (type === 'video') {
            try {
                console.log('get video stream');
                const videoStream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        deviceId: {
                            exact: deviceId,
                        },
                    },
                });
                let video = videoRef.current;
                video.srcObject = videoStream;
                video.play();
            } catch (error) {
                console.log('error while setting the video stream', error);
            }
        } else if (type === 'audioOut') {
            try {
                if (!audioRef) return;
                console.log('hook audio out');
                audioRef.current.setSinkId(deviceId);
                console.log(
                    'audio is being played on',
                    mediaDevices.speaker.find((device) => device.deviceId === deviceId)
                );
            } catch (error) {
                console.log('error while setting the audio out stream', error);
            }
        }
    };

    //   stops the micrphone
    const stopMicrophone = () => {
        if (!audioStream) return;
        audioStream.getTracks().forEach((track) => track.stop());
        setAudioStream(null);
        setSelectedMic(null);
    };

    //   stops the camera
    const stopCamera = () => {
        if (!videoRef?.current?.srcObject || !videoRef?.current) return;
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        setSelectedCamera(null);
    };

    //   play audio to the selected speaker
    const handlePlayAudio = () => {
        // play audio on the selected speaker
        audioRef?.current?.play();
    };

    const handleSettingsPanelChange = (setting) => {
        if (setting === 'Video') {
            // cleanup mic
            stopMicrophone();
            setSelectedCamera(mediaDevices?.video[0]?.deviceId);
        } else if (setting === 'Audio') {
            // cleanup camera
            stopCamera();
            setSelectedMic(mediaDevices?.audio[0]?.deviceId);
        }
        setSelectedSettingsPanel(setting);
    };

    // function that triggers the info modal for firefox and safari browsers regarding the output device selection
    const handleTriggerInfoModal = () => {
        onConfirm();
        triggerInfoModal();
    };

    //   onMount -> get the audio video devices list
    useEffect(() => {
        const getDevices = async () => {
            try {
                listDevices();
            } catch (error) {
                console.error(error);
            }
        };
        getDevices();

        return () => {
            stopCamera();
            stopMicrophone();
        };
    }, []);

    //   side effects on selected mic change
    useEffect(() => {
        if (!selectedMic) return;
        getStream(selectedMic, 'audio');
    }, [selectedMic]);

    useEffect(() => {
        if (!selectedCamera) return;
        getStream(selectedCamera, 'video');
    }, [selectedCamera]);

    useEffect(() => {
        if (!selectedSpeaker) return;
        getStream(selectedSpeaker, 'audioOut');
    }, [selectedSpeaker]);


    const startCameraTest = () => {
        setStep(1);
        setSelectedCamera(mediaDevices?.video[0]?.deviceId);
    };

    const continueToAudioTest = () => {
        setStep(2);
        stopCamera();
    };

    const continueToMicTest = () => {
        setStep(3);
        setSelectedMic(mediaDevices?.audio[0]?.deviceId);
    };

    const endTest = () => {
        setStep(4);
    };

    useEffect(() => {
        if (step === 2 || step === 3) {
            handlePlayAudio();
        }
    }, [step, selectedSpeaker, selectedMic])

    const modalRef = useRef(null);

    // Add event listener for outside clicks
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
                onConfirm();
                stopCamera();
            }
        };

        if (show) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [show, onConfirm]);


    return (
        <>
            {/* {show && (
				<div className='relative z-10' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
					<div className='fixed inset-0 bg-gray-900 bg-opacity-90 transition-opacity blur-xl'></div>

					<div className='fixed inset-0 z-10 overflow-y-auto overflow-x-auto'>
						<div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
							<div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl'>
								<div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
									<div className='sm:flex sm:items-start'>
										<div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
											<div className='mt-2 sm-body-container'>
												<div>
													<h2 className=' text-gray-200 mb-4'>Settings</h2>
													<div className='side-panel'>
														{SETTINGS_MENU.map((setting, index) => {
															return (
																<div
																	className={`settings-item ${
																		selectedSettingsPanel === setting &&
																		'selected shadow-sm'
																	}`}
																	key={index}
																	onClick={() => handleSettingsPanelChange(setting)}
																>
																	{setting}
																</div>
															);
														})}
													</div>
												</div>
												<div className='sm-main-container'>
													{selectedSettingsPanel === 'Audio' ? (
														<div className='audio-container'>
															<div className='mic-container'>
																<p>Microphone</p>
																<div className='media-select-container'>
																	<label htmlFor='testMic'></label>
																	<select
																		id='testMic'
																		className='sm-custom-select'
																		onChange={handleMicChange}
																	>
																		{mediaDevices?.audio?.map((device, index) => {
																			return (
																				<option
																					value={device.deviceId}
																					key={index}
																				>
																					{device.label}
																				</option>
																			);
																		})}
																	</select>
																</div>
																<div className='visual-container'>
																	{audioStream && (
																		<AudioAnalyser audio={audioStream} />
																	)}
																</div>
															</div>
															<div className='speaker-container mt-4'>
																<p>Speaker</p>
																{mediaDevices.speaker.length > 0 ? (
																	<>
																		<label htmlFor='testSpeaker'></label>
																		<select
																			id='testSpeaker'
																			className='sm-custom-select'
																			onChange={handleSpeakerChange}
																		>
																			{mediaDevices?.speaker?.map(
																				(device, index) => {
																					return (
																						<option
																							key={index}
																							value={device.deviceId}
																						>
																							{device.label}
																						</option>
																					);
																				}
																			)}
																		</select>
																	</>
																) : isSafari || isFirefox ? (
																	<p>
																		To enable speaker selection on safari/firefox{' '}
																		<u
																			className='cursor-pointer'
																			onClick={handleTriggerInfoModal}
																		>
																			click here
																		</u>
																	</p>
																) : (
																	<p>No supported output devices found</p>
																)}
																<audio
																	ref={audioRef}
																	src={
																		'https://ssl.gstatic.com/chat/sounds/speaker_test_d00f43ce701c3b3f084f723f0f61d406.mp3'
																	}
																></audio>
																<button
																	className='cursor-pointer inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto'
																	onClick={handlePlayAudio}
																>
																	Play Sound
																</button>
															</div>
														</div>
													) : (
														<div className='video-container'>
															<p>Camera</p>
															<label htmlFor='testCamera'></label>
															<select
																id='testCamera'
																className='sm-custom-select'
																onChange={handleCameraChange}
															>
																{mediaDevices?.video?.map((device, index) => {
																	return (
																		<option key={index} value={device.deviceId}>
																			{device.label}
																		</option>
																	);
																})}
															</select>
															<div className='test-video-container mt-2'>
																<video ref={videoRef} />
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6'>
									<button
										type='button'
										onClick={onConfirm}
										className='inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto'
									>
										Confirm
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)} */}


            {/*-----NewCode-----*/}

            {show && (
                <div className='relative z-10' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
                    <div className='fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity blur-xl'></div>

                    <div className='fixed inset-0 z-10 overflow-y-auto overflow-x-auto'>
                        <div className='flex min-h-full items-end justify-center md:p-4 text-center sm:items-center sm:p-0'>
                            <div className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 zm:w-full sm:max-w-[400px]'>
                                <div ref={modalRef} className='bg-white sm:px-30px sm:pt-30px zm:pt-10 zm:px-30px'>
                                    {step === 0 && (
                                        <>
                                            <div className='display-style'>  <TestIcon /> </div>
                                            <div className='test-head-text'>Let’s start your test</div>
                                            <div className='test-sub-text'>Follow the steps below to test your audio and video settings.</div>
                                            <div className='w-full px-30px pb-30px'>
                                                <button onClick={startCameraTest} className='btn-white-default btn w-full'>Start Test</button>
                                            </div>
                                        </>
                                    )}
                                    {step === 1 && (
                                        <>
                                            <div className='test-header-badge'>
                                                Step 1/3
                                            </div>
                                            <div className='test-head-text'>
                                                Do you see yourself?
                                            </div>
                                            <div className='test-sub-text'>
                                                You should see yourself in the area below.
                                            </div>
                                            <div className='video-container'>
                                                {mediaDevices?.video?.length !== 0 ?
                                                    <div className='test-video-container mt-2 mb-5'>
                                                        <video ref={videoRef} />
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                <select
                                                    id='testCamera'
                                                    className='test-options'
                                                    onChange={handleCameraChange}
                                                >
                                                    {mediaDevices?.video?.map((device, index) => {
                                                        return (
                                                            <option key={index} value={device.deviceId}>
                                                                {device.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>

                                                <div className='w-full pb-30px mt-5'>
                                                    <button onClick={continueToAudioTest} className='btn-white-default btn w-full'>Continue</button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {step === 2 && (
                                        <>
                                            <audio
                                                ref={audioRef}
                                                src={
                                                    'https://ssl.gstatic.com/chat/sounds/speaker_test_d00f43ce701c3b3f084f723f0f61d406.mp3'
                                                }
                                            ></audio>
                                            <div className='test-header-badge'>
                                                Step 2/3
                                            </div>
                                            <div className='test-head-text'>
                                                Do you hear a ringtone?
                                            </div>
                                            <div className='test-sub-text'>
                                                You should hear a ringtone playing, if you don’t try selecting other speakers below.
                                            </div>
                                            <div className='speaker-container mt-4' >
                                                {mediaDevices.speaker.length > 0 ? (
                                                    <>
                                                        <label htmlFor='testSpeaker'></label>
                                                        <select
                                                            id='testSpeaker'
                                                            className='test-options'
                                                            onChange={handleSpeakerChange}
                                                        >
                                                            {mediaDevices?.speaker?.map(
                                                                (device, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={device.deviceId}
                                                                        >
                                                                            {device.label}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </select>
                                                    </>
                                                ) : isSafari || isFirefox ? (
                                                    <p>
                                                        To enable speaker selection on safari/firefox{' '}
                                                        <u
                                                            className='cursor-pointer'
                                                            onClick={handleTriggerInfoModal}
                                                        >
                                                            click here
                                                        </u>
                                                    </p>
                                                ) : (
                                                    <p>No supported output devices found</p>
                                                )}

                                            </div>
                                            <div className='w-full mt-5 pb-30px'>
                                                <button onClick={continueToMicTest} className='btn-white-default btn w-full'>Continue</button>
                                            </div>
                                        </>
                                    )}
                                    {step === 3 && (
                                        <>
                                            {/* <audio
                                                ref={audioRef}
                                                src={
                                                    'https://ssl.gstatic.com/chat/sounds/speaker_test_d00f43ce701c3b3f084f723f0f61d406.mp3'
                                                }
                                            ></audio> */}
                                            <div className='test-header-badge'>
                                                Step 3/3
                                            </div>
                                            <div className='test-head-text'>
                                                When speaking do you see it below?
                                            </div>
                                            <div className='test-sub-text'>
                                                You should see your voice in the wave below
                                            </div>
                                            <div className='visual-container bg-[#D9D9D9] mb-5 h-[204px] rounded-[15px]'>
                                                {audioStream && (
                                                    <AudioAnalyser audio={audioStream} />
                                                )}
                                            </div>
                                            <div className='media-select-container'>
                                                <label htmlFor='testMic'></label>
                                                <select
                                                    id='testMic'
                                                    className='test-options'
                                                    onChange={handleMicChange}
                                                >
                                                    {mediaDevices?.audio?.map((device, index) => {
                                                        return (
                                                            <option
                                                                value={device.deviceId}
                                                                key={index}
                                                            >
                                                                {device.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                            <div className='w-full mt-5 pb-30px'>
                                                <button onClick={endTest} className='btn-white-default btn w-full'>Continue</button>
                                            </div>

                                        </>
                                    )}
                                    {step === 4 && (
                                        <>
                                            <div className='display-style'>  <TickOrange /> </div>
                                            <div className='test-head-text'>
                                                Your device is working!
                                            </div>
                                            <div className='test-sub-text'>
                                                Nice work, your device is up and running and ready for you to get started.
                                            </div>
                                            <div className='w-full mt-5 pb-30px'>
                                                <button onClick={onConfirm} className='btn-white-default btn w-full'>End Test</button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SettingsModal;
