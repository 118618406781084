import * as t from '../action_types';

export const set_class_details_variable = (key, payload) => {
  return {
    type: t.set_class_details_variable,
    payload: {
      key,
      payload,
    },
  };
};

export const scheduleClass = payload => {
  return {
    type: t.schedule_class,
    payload,
  };
};

export const classDetails = payload => {
  return {
    type: t.class_details,
    payload,
  };
};

export const updateStudentCount = payload => {
  return {
    type: t.update_student_count,
    payload,
  };
};

export const getStudentProfile = payload => {
  return {
    type: t.student_profile,
    payload,
  };
};

export const updateClassDuration = payload => {
  return {
    type: t.update_class_duration,
    payload,
  };
};