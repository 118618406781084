import React, { useEffect, useState, forwardRef } from 'react';

import moment from 'moment';
import DatePicker from 'react-datepicker';

import './index.css';
import 'react-datepicker/dist/react-datepicker.css';

import { add_avalability_slots_data } from '../../../redux/api_functions/availability_api';

import Loader from '../../new_teacher_wesite/components/loader';

import * as actions from '../../../redux/action_creators';
import { connect } from 'react-redux';
import { removeCookie, analytics_reset } from '../../../util_functions';
import { DateIcon, TimeIcon } from '../../../new_teacher_design/assets/svg/popup_icons';
import { useNavigate } from 'react-router-dom';

const AddAvailability = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        props.get_teacher_details();
    }, []);

    let currentMonth = new Date(props.currentDateAddAvailibility);
    currentMonth.setMonth(currentMonth.getMonth() + 1);

    const [getRepeatSession, setRepeatSession] = useState(false);

    const [getTrialSession, setTrialSession] = useState(false);

    const [startDate, setStartDate] = useState(
        props.currentDateAddAvailibility === moment(new Date()).format('YYYY-MM-DD')
            ? new Date(moment(new Date()).startOf('day'))
            : new Date(moment(props.currentDateAddAvailibility).startOf('day'))
    );

    const [endDate, setEndDate] = useState(
        props.currentDateAddAvailibility === new Date(moment(new Date()).startOf('day'))
            ? new Date()
            : new Date(moment(props.currentDateAddAvailibility).startOf('day'))
    );

    const [selectedCalenderDate, setSelectedCalenderDate] = useState(
        props.currentDateAddAvailibility === moment(new Date()).format('YYYY-MM-DD')
            ? new Date()
            : new Date(props.currentDateAddAvailibility)
    );

    const [endSelectedCalenderDate, setendSelectedCalenderDate] = useState(currentMonth);

    const [startAvailabilityDate, setStartAvailabilityDate] = useState(
        props.currentDateAddAvailibility === moment(new Date()).format('YYYY-MM-DD')
            ? moment(new Date()).format('ddd, DD-MMM-YY')
            : moment(new Date(props.currentDateAddAvailibility)).format('ddd, DD-MMM-YY')
    );
    const [endAvailabilityDate, setEndAvailabilityDate] = useState(moment(currentMonth).format('ddd, DD-MMM-YY'));

    const [calenderValue, setCalenderValue] = useState({
        Sunday: { isSelected: false },
        Monday: { isSelected: false },
        Tuesday: { isSelected: false },
        Wednesday: { isSelected: false },
        Thursday: { isSelected: false },
        Friday: { isSelected: false },
        Saturday: { isSelected: false },
    });

    const [isLoading, setIsloading] = useState(false);


    const [getErrorMsg, setErrorMsg] = useState('');

    const [StartTimeActive, setStartTimeActive] = useState(true);

    const [EndTimeActive, setEndTimeActive] = useState(true);

    const modal = React.useRef(null);

    const checkingSelectedDay = (day) => {
        const newData = { [day]: { isSelected: !calenderValue[day].isSelected } };
        const destructuringValue = { ...calenderValue, ...newData };
        setCalenderValue(destructuringValue);
    };

    const calculateMinTime = (date) => {
        if (props.currentDateAddAvailibility === moment(new Date()).format('YYYY-MM-DD')) {
            let isToday = moment(date).isSame(moment(), 'day');
            if (isToday) {
                let nowAddOneHour = moment(new Date()).add({ hours: 0 }).toDate();
                return nowAddOneHour;
            }
            return moment().startOf('day').toDate();
        } else {
            return new Date(moment(props.currentDateAddAvailibility).startOf('day'));
        }
    };

    const [minTimeSelect, setMinTimeSelect] = useState(calculateMinTime(new Date()));

    // eslint-disable-next-line react/display-name
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            className='text-base text-mytTeacherTextGray customTimePickerTeacherAvailability'
            onClick={onClick}
            ref={ref}
        >
            <div className='formInput w-max xs:w-195px input-date rounded-lg text-15 font-medium shadow-sm flex gap-20px items-center border react-datepicker-ignore-onclickoutside justify-between items-center cursor-pointer'>
                <div className='text-base text-mytTeacherTextGray'>{startAvailabilityDate}</div>
                <button className='example-custom-input' onClick={onClick} ref={ref}>
                    <DateIcon />
                </button>
            </div>
        </div>
    ));

    // eslint-disable-next-line react/display-name
    const EndCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            className='text-base text-mytTeacherTextGray customTimePickerTeacherAvailability'
            onClick={onClick}
            ref={ref}
        >
            <div className='flex justify-between items-center w-full cursor-pointer'>
                <div className='text-base text-mytTeacherTextGray'>{endAvailabilityDate}</div>
                <button className='example-custom-input' onClick={onClick} ref={ref}>
                    <img src='https://images.myyogateacher.com/web_images/calender_icon_teacher.svg' alt='' />
                </button>
            </div>
        </div>
    ));

    function selectedStartCalenderDateEvent(date) {
        setStartAvailabilityDate(moment(new Date(date)).format('ddd, DD-MMM-YY'));
        setMinTimeSelect(calculateMinTime(date));
    }
    function selectedEndCalenderDateEvent(date) {
        setEndAvailabilityDate(moment(new Date(date)).format('ddd, DD-MMM-YY'));
    }

    const repeatSessionTime = () => {
        setRepeatSession((data) => !data);
    };

    const addAvailabilityForTrialUser = (event) => {
        setTrialSession(event.target.checked);
    };

    const submitData = () => {
        // getRepeatSession
        let sunday = calenderValue['Sunday']['isSelected'] ? 1 : 0;
        let monday = calenderValue['Monday']['isSelected'] ? 1 : 0;
        let tuesday = calenderValue['Tuesday']['isSelected'] ? 1 : 0;
        let wednesday = calenderValue['Wednesday']['isSelected'] ? 1 : 0;
        let thusday = calenderValue['Thursday']['isSelected'] ? 1 : 0;
        let friday = calenderValue['Friday']['isSelected'] ? 1 : 0;
        let saturday = calenderValue['Saturday']['isSelected'] ? 1 : 0;
        let customEndDat =
            getRepeatSession === false
                ? moment(startAvailabilityDate).format('YYYY-MM-DD')
                : moment(endAvailabilityDate).format('YYYY-MM-DD');

        let payload = {
            start_date: moment(startAvailabilityDate).format('YYYY-MM-DD'),
            end_date: customEndDat,
            start_time: moment(startDate).format('HH:mm:ss'),
            end_time: moment(endDate).format('HH:mm:ss'),
            repeat_schedule: getRepeatSession === false ? 0 : 1,
            repeat_forever: 0,
            available_sunday: getRepeatSession === false ? 0 : sunday,
            available_monday: getRepeatSession === false ? 0 : monday,
            available_tuesday: getRepeatSession === false ? 0 : tuesday,
            available_wednesday: getRepeatSession === false ? 0 : wednesday,
            available_thursday: getRepeatSession === false ? 0 : thusday,
            available_friday: getRepeatSession === false ? 0 : friday,
            available_saturday: getRepeatSession === false ? 0 : saturday,
            trial_only_availability: getTrialSession === true ? 1 : 0,
        };
        setIsloading(true);
        add_avalability_slots_data(payload)
            .then((response) => {
                if (response?.status === 200) {
                    props.addDataEditSlots(true);
                    setIsloading(false);
                }
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    removeCookie('teacher_token_1');
                    localStorage.removeItem('teacherLocalChannels');
                    analytics_reset();
                    navigate('/login');
                    props.addDataEditSlots(true);
                    setIsloading(false);
                } else if (e.response.status === 400) {
                    setIsloading(false);
                    setErrorMsg(JSON.stringify(e.response.data.message));
                }
            });
    };

    // eslint-disable-next-line react/display-name
    const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => {
        return (
            <>
                <div
                    className='text-base text-mytTeacherTextGray customTimePickerTeacherAvailability'
                    onClick={onClick}
                    ref={ref}
                >
                    <div className='formInput zm:w-150px xs:w-195px input-date rounded-lg text-15 font-medium shadow-sm flex items-center border react-datepicker-ignore-onclickoutside justify-between cursor-pointer'>
                        <div className='text-secondary text-15px font-medium'>{StartTimeActive ? '--' : value}</div>
                        <div>
                            <button className='example-custom-input'>{<TimeIcon />}</button>
                        </div>
                    </div>
                </div>
            </>
        );
    });

    // eslint-disable-next-line react/display-name
    const ExampleCustomInput3 = forwardRef(({ value, onClick }, ref) => {
        return (
            <>
                <div
                    className='text-base text-mytTeacherTextGray customTimePickerTeacherAvailability'
                    onClick={onClick}
                    ref={ref}
                >
                    <div className='formInput zm:ml-15px xs:ml-22px zm:w-150px xs:w-195px input-date rounded-lg text-15 font-medium shadow-sm flex items-center border react-datepicker-ignore-onclickoutside justify-between cursor-pointer'>
                        <div className='text-secondary text-15px font-medium'>{EndTimeActive ? '--' : value}</div>
                        <div>
                            <button className='example-custom-input'>{<TimeIcon />}</button>
                        </div>
                    </div>
                </div>
            </>
        );
    });

    const on_close_modal = (e) => {
        if (modal && modal.current && !modal.current.contains(e.target)) {
            props.closeAddAvailablityPopup();
        }
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className='relative inset-0 z-index-max'>
                    <div
                        onClick={(e) => on_close_modal(e)}
                        className='flex zm:items-end xs:items-center fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-10 justify-center'
                    >
                        <div
                            ref={modal}
                            className='z-50 bg-white zm:rounded-tl-3xl xs:rounded-lg px-30px py-40px xs:w-492px zm:w-full relative'
                        >
                            <div className='flex justify-end cursor-pointer absolute top-1 right-1'>
                                <div onClick={props.closeAddAvailablityPopup}>
                                    <svg
                                        width='40'
                                        height='40'
                                        viewBox='0 0 34 34'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <rect width='34' height='34' rx='17' fill='none' />
                                        <path
                                            d='M22 12L12 22'
                                            stroke='#BFBFBF'
                                            strokeWidth='1.66667'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                        <path
                                            d='M12 12L22 22'
                                            stroke='#BFBFBF'
                                            strokeWidth='1.66667'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className='mb-0'>
                                <div className='flex justify-start flex-col items-start'>
                                    <div className='w-full'>
                                        <div className='flex items-center'>
                                            <div className='text-17 font-semibold'>Add availability</div>
                                        </div>
                                        <div className='mt-5'>
                                            <div className='font-medium text-mytLabel text-13.5'>Start Date</div>
                                            <div className='flex justify-between items-center mt-3 mb-3 customDatepikerItem2 zm:w-150px md:w-195px'>
                                                <DatePicker
                                                    selected={selectedCalenderDate}
                                                    onChange={(date) => selectedStartCalenderDateEvent(date)}
                                                    customInput={<ExampleCustomInput />}
                                                    minDate={new Date()}
                                                    showDisabledMonthNavigation
                                                    disabledKeyboardNavigation
                                                />
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            <div className='flex items-center justify-between'>
                                                <div className='flex flex-col mt-3 pb-2 customDatepikerItem relative'>
                                                    <div className='font-medium text-mytLabel text-13.5 mb-8px'>
                                                        Start time
                                                    </div>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => (
                                                            setStartDate(date), setStartTimeActive(false)
                                                        )}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption='Time'
                                                        dateFormat='hh:mm aa'
                                                        minDate={new Date()}
                                                        minTime={minTimeSelect}
                                                        maxTime={moment().endOf('day').toDate()}
                                                        customInput={<ExampleCustomInput2 />}
                                                    />
                                                </div>
                                                <div className='flex flex-col mt-2 customDatepikerItem relative'>
                                                    <div className='font-medium text-mytLabel text-13.5 mb-8px zm:ml-15px xs:ml-22px'>
                                                        End time
                                                    </div>
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={(date) => (setEndDate(date), setEndTimeActive(false))}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={30}
                                                        timeCaption='Time'
                                                        dateFormat='hh:mm aa'
                                                        minTime={minTimeSelect}
                                                        maxTime={moment().endOf('day').toDate()}
                                                        customInput={<ExampleCustomInput3 />}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-5 bg-mytTrailCard py-15px px-5 rounded-lg'>
                                            <label className='flex justify-start items-center'>
                                                <div className='checkbox'>
                                                    <input
                                                        type='checkbox'
                                                        id='1'
                                                        name=''
                                                        value=''
                                                        checked={getRepeatSession}
                                                        onClick={repeatSessionTime}
                                                    />
                                                    <label htmlFor='1'></label>
                                                </div>
                                                <label
                                                    htmlFor='1'
                                                    className={`text-base cursor-pointer ${getRepeatSession === true
                                                        ? 'text-primary'
                                                        : 'text-mytTeacherTextGray'
                                                        }`}
                                                >
                                                    Repeat availability
                                                </label>
                                            </label>
                                        </div>
                                        {getRepeatSession && (
                                            <>
                                                <div className='daysAbailabilitySlots border-b pb-5 mt-5'>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Sunday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Sunday')}
                                                    >
                                                        S
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Monday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Monday')}
                                                    >
                                                        M
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Tuesday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Tuesday')}
                                                    >
                                                        T
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Wednesday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Wednesday')}
                                                    >
                                                        W
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Thursday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Thursday')}
                                                    >
                                                        T
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Friday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Friday')}
                                                    >
                                                        F
                                                    </div>
                                                    <div
                                                        className={`w-34px h-34px text-base cursor-pointer font-semibold  rounded-full  flex justify-center items-center ${calenderValue.Saturday.isSelected === true
                                                            ? 'bg-mytTeacherTextGray3 text-white'
                                                            : 'bg-mytTeacherTextGray2 text-mytTeacherTextGray4'
                                                            }`}
                                                        onClick={() => checkingSelectedDay('Saturday')}
                                                    >
                                                        S
                                                    </div>
                                                </div>
                                                <div className='mt-5'>
                                                    <div className='mt-3 mb-3'>
                                                        <div className='text-mytgrey-default text-sm font-normal'>
                                                            End Date
                                                        </div>
                                                        <div className='flex justify-between items-center mt-3 mb-3 customDatepikerItem2'>
                                                            <DatePicker
                                                                selected={endSelectedCalenderDate}
                                                                onChange={(date) => selectedEndCalenderDateEvent(date)}
                                                                customInput={<EndCustomInput />}
                                                                minDate={new Date()}
                                                                showDisabledMonthNavigation
                                                                disabledKeyboardNavigation
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {props.teacher_details?.allow_trial_only_availability !== 0 ? (
                                            <>
                                                <div className='mt-5 bg-mytTrailCard py-15px px-5 rounded-lg'>
                                                    <label className='flex justify-start items-start'>
                                                        <div className='checkbox'>
                                                            <input
                                                                type='checkbox'
                                                                id='2'
                                                                name=''
                                                                value=''
                                                                checked={getTrialSession}
                                                                onClick={addAvailabilityForTrialUser}
                                                            />
                                                            <label htmlFor='2'></label>
                                                        </div>
                                                        <label
                                                            htmlFor='2'
                                                            className={`text-base cursor-pointer  ${getTrialSession === true
                                                                ? 'text-primary'
                                                                : 'text-mytTeacherTextGray'
                                                                }`}
                                                        >
                                                            <div
                                                                className={`text-base cursor-pointer ${getTrialSession === true
                                                                    ? 'text-primary'
                                                                    : 'text-mytTeacherTextGray'
                                                                    }`}
                                                            >
                                                                Availability only for trial students
                                                            </div>
                                                            <div
                                                                className={`text-xs font-normal ${getTrialSession === true
                                                                    ? 'text-primary'
                                                                    : 'text-mytTeacherTextGray'
                                                                    }`}
                                                            >
                                                                Availability won't be visible to regular students
                                                            </div>
                                                        </label>
                                                    </label>
                                                </div>
                                            </>
                                        ) : null}

                                        <div className='mt-8'>
                                            {StartTimeActive && !EndTimeActive ? (
                                                <div className='loginSignupErrorMsg mb-3'>Please Select Start Time</div>
                                            ) : (
                                                ''
                                            )}
                                            {getErrorMsg.length ? (
                                                <div className='loginSignupErrorMsg mb-3'>
                                                    {JSON.parse(getErrorMsg)}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <button
                                                className={`btn btn-primary mr-5 ${StartTimeActive === false && EndTimeActive === false
                                                    ? ''
                                                    : 'opacity-50 pointer-events-none'
                                                    }`}
                                                onClick={submitData}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        teacher_details: state.profile.teacher_details,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_teacher_details: (key, payload) => {
            dispatch(actions.get_teacher_details(key, payload));
        },
    };
};

const AddAvailabilityWithRedux = connect(mapStateToProps, mapDispatchToProps)(AddAvailability);
export default AddAvailabilityWithRedux;
